import { getStatusError } from '../../../utils/helpres';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastAction from '../../../components/ToastAction';
import React from 'react';
import { apiCall } from '../../../js/actions';

const submitJurisprudenceForm = (values) => async (dispatch) => {
  dispatch({ type: 'UPDATE_JURISPRUDENCE_REQUESTING' });
  const { file } = values;
  try {
    let formData = new FormData();
    formData.append('file', file);
    const { data } = await apiCall({
      method: values.id ? 'patch' : 'post',
      url: `/api/jurisprudence/${values.id ? `update/${values.id}` : 'create'}`,
      data: formData,
    });
    if (data.type === 'success') {
      toast(({ closeToast }) => <ToastAction type="success" message={data.message} />, {
        hideProgressBar: true,
      });

      dispatch({
        type: 'UPDATE_JURISPRUDENCE_SUCCESS',
        addJurisprudence: data.data,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      dispatch({
        type: 'UPDATE_JURISPRUDENCE_FAILURE',
        ErrorMessage: data.message,
        addJurisprudence: values,
        status: true,
      });
    }
  } catch (e) {
    const { response } = e;
    if (response) {
      const { data } = response;
      if (data.message) {
        toast(
          ({ closeToast }) => (
            <ToastAction type="error" message={response.data.message} />
          ),
          { hideProgressBar: true },
        );
      } else
        toast(
          ({ closeToast }) => (
            <ToastAction
              type="error"
              message="عفوًا ، لم يتم إنشاء التقرير، يرجى إعادة المحاولة."
            />
          ),
          { hideProgressBar: true },
        );
      dispatch({
        type: 'UPDATE_JURISPRUDENCE_FAILURE',
        msg: 'error server',
        err: data.errors || data.message,
        addJurisprudence: values,
        status: getStatusError(e),
      });
    } else {
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="error"
            message="عفوًا ، لم يتم إنشاء التقرير، يرجى إعادة المحاولة."
          />
        ),
        { hideProgressBar: true },
      );
      dispatch({
        type: 'UPDATE_JURISPRUDENCE_FAILURE',
        msg: 'error server',
        err: data.errors || data.message,
        addJurisprudence: values,
        status: getStatusError(e),
      });
    }
  }
};

const deleteJurisprudence = (id) => async (dispatch) => {
  dispatch({ type: 'ACTION_DELETE_JURISPRUDENCE_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'delete',
      url: `/api/jurisprudence/delete/${id}`,
    });
    if (data.type === 'success') {
      toast(({ closeToast }) => <ToastAction type="success" message={data.message} />, {
        hideProgressBar: true,
      });
      dispatch({
        type: 'ACTION_DELETE_JURISPRUDENCE_SUCCESS',
        deleteJurisprudence: data,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      dispatch({
        type: 'ACTION_DELETE_JURISPRUDENCE_FAILURE',
        ErrorMessage: data.message,
        data: false,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    toast(({ closeToast }) => <ToastAction type="error" message="لم يتم حذف المجلة" />, {
      hideProgressBar: true,
    });
    dispatch({
      type: 'ACTION_DELETE_JURISPRUDENCE_FAILURE',
      msg: 'error server',
      err: data.errors || data.message,
      data: false,
    });
  }
};
const changeSearchedStringJurisprudence = (paramsJurisprudence) => ({
  type: 'UPDATE_SEARCH_JURISPRUDENCE_PARAMS',
  paramsJurisprudence,
});
const getListJurisprudence = (paramsJurisprudence) => async (dispatch) => {
  dispatch({
    type: 'GET_JURISPRUDENCE_REQUESTING',
    paramsJurisprudence,
  });
  try {
    const { currentPage } = paramsJurisprudence || {
      currentPage: 1,
    };
    const { limit } = paramsJurisprudence || {
      limit: '',
    };
    let url = `/api/jurisprudence?limit=${limit}&current_page=${currentPage}`;

    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_JURISPRUDENCE_SUCCESS',
      Jurisprudences: data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_JURISPRUDENCE_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};

export default {
  submitJurisprudenceForm,
  deleteJurisprudence,
  getListJurisprudence,
  changeSearchedStringJurisprudence,
};
