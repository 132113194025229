import React, { Component } from 'react';
import actions from '../../js/actions';
import { connect } from 'react-redux';
import Calendar from '../dashboard/components/calendar';
import moment from 'moment';
import { Link } from 'react-router-dom';

const mapStateToProps = (state) => ({
  paramsNotification: state.AppReducer.paramsNotification,
  isLoadingNotifications: state.AppReducer.isLoadingNotifications,
  notifications: state.AppReducer.notifications,
});
const mapDispatchToProps = (dispatch) => ({
  getListNotifications: async (paramsNotification) =>
    await dispatch(actions.getListNotifications(paramsNotification)),
  changeSearchedStringNotification: async (paramsNotification) => {
    await dispatch(actions.changeSearchedStringNotification(paramsNotification));
    dispatch(actions.getListNotifications(paramsNotification));
  },
});

class Notification extends Component {
  constructor(props) {
    super(props);
    props.changeActiveItemSideBar(0, 'الإشعارات');
    document.title = 'الإشعارات';
  }
  componentDidMount() {
    this.props.changeSearchedStringNotification({
      date: '',
      currentPage: 1,
      limit: 5,
    });
  }
  render() {
    const { notifications, paramsNotification } = this.props;
    return (
      <div className="alln_main">
        <div className="alln_middle" style={{ position: 'relative' }}>
          <div className="alln_middle__col-right">
            <div className="alln__judgements_table">
              <div className="alln_table">
                <table>
                  <tbody>
                    {notifications &&
                    notifications.data &&
                    notifications.data.length > 0 ? (
                      notifications.data.map((notif) => {
                        if (notif.type === 'rdv')
                          return (
                            <tr
                              style={{ cursor: 'pointer' }}
                              onClick={() =>
                                this.props.history.push({
                                  pathname: '/appointment',
                                  state: { date: new Date(notif.date) },
                                })
                              }
                              key={notif.id}
                            >
                              <td>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="44"
                                  height="44"
                                  viewBox="0 0 44 44"
                                >
                                  <circle
                                    id="Ellipse_36"
                                    data-name="Ellipse 36"
                                    cx="22"
                                    cy="22"
                                    r="22"
                                    fill="#ca2946"
                                  />
                                  <g id="calendar" transform="translate(7.089 6.5)">
                                    <g id="Group_11" data-name="Group 11">
                                      <g id="Group_10" data-name="Group 10">
                                        <circle
                                          id="Ellipse_2"
                                          data-name="Ellipse 2"
                                          cx="1"
                                          cy="1"
                                          r="1"
                                          transform="translate(21.911 11.5)"
                                          fill="#fff"
                                        />
                                        <path
                                          id="Path_43"
                                          data-name="Path 43"
                                          d="M25.312,2.344H23.789V1.172a1.172,1.172,0,0,0-2.344,0V2.344H16.113V1.172a1.172,1.172,0,0,0-2.344,0V2.344H8.5V1.172a1.172,1.172,0,1,0-2.344,0V2.344H4.687A4.693,4.693,0,0,0,0,7.031V25.312A4.693,4.693,0,0,0,4.687,30h8.965a1.172,1.172,0,0,0,0-2.344H4.687a2.346,2.346,0,0,1-2.344-2.344V7.031A2.346,2.346,0,0,1,4.687,4.687H6.152V5.859a1.172,1.172,0,1,0,2.344,0V4.687H13.77V5.859a1.172,1.172,0,0,0,2.344,0V4.687h5.332V5.859a1.172,1.172,0,0,0,2.344,0V4.687h1.523a2.346,2.346,0,0,1,2.344,2.344v6.68a1.172,1.172,0,0,0,2.344,0V7.031A4.693,4.693,0,0,0,25.312,2.344Z"
                                          fill="#fff"
                                        />
                                        <path
                                          id="Path_44"
                                          data-name="Path 44"
                                          d="M277.09,270a7.09,7.09,0,1,0,7.09,7.09A7.1,7.1,0,0,0,277.09,270Zm0,11.836a4.746,4.746,0,1,1,4.746-4.746A4.751,4.751,0,0,1,277.09,281.836Z"
                                          transform="translate(-254.18 -254.18)"
                                          fill="#fff"
                                        />
                                        <path
                                          id="Path_45"
                                          data-name="Path 45"
                                          d="M373.871,332.4h-.527v-1.23a1.172,1.172,0,0,0-2.344,0v2.4a1.172,1.172,0,0,0,1.172,1.172h1.7a1.172,1.172,0,1,0,0-2.344Z"
                                          transform="translate(-349.262 -310.664)"
                                          fill="#fff"
                                        />
                                        <ellipse
                                          id="Ellipse_3"
                                          data-name="Ellipse 3"
                                          cx="1.5"
                                          cy="1"
                                          rx="1.5"
                                          ry="1"
                                          transform="translate(15.911 11.5)"
                                          fill="#fff"
                                        />
                                        <ellipse
                                          id="Ellipse_4"
                                          data-name="Ellipse 4"
                                          cx="1"
                                          cy="1.5"
                                          rx="1"
                                          ry="1.5"
                                          transform="translate(11.911 15.5)"
                                          fill="#fff"
                                        />
                                        <ellipse
                                          id="Ellipse_5"
                                          data-name="Ellipse 5"
                                          cx="1.5"
                                          cy="1"
                                          rx="1.5"
                                          ry="1"
                                          transform="translate(5.911 11.5)"
                                          fill="#fff"
                                        />
                                        <circle
                                          id="Ellipse_6"
                                          data-name="Ellipse 6"
                                          cx="1.5"
                                          cy="1.5"
                                          r="1.5"
                                          transform="translate(5.911 15.5)"
                                          fill="#fff"
                                        />
                                        <circle
                                          id="Ellipse_7"
                                          data-name="Ellipse 7"
                                          cx="1.5"
                                          cy="1.5"
                                          r="1.5"
                                          transform="translate(5.911 20.5)"
                                          fill="#fff"
                                        />
                                        <ellipse
                                          id="Ellipse_8"
                                          data-name="Ellipse 8"
                                          cx="1"
                                          cy="1.5"
                                          rx="1"
                                          ry="1.5"
                                          transform="translate(11.911 20.5)"
                                          fill="#fff"
                                        />
                                        <circle
                                          id="Ellipse_9"
                                          data-name="Ellipse 9"
                                          cx="1"
                                          cy="1"
                                          r="1"
                                          transform="translate(11.911 11.5)"
                                          fill="#fff"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </svg>
                                موعد
                              </td>
                              <td>
                                <span className="alln_td_span_time">
                                  {moment(notif.date).format('HH:mm')}
                                </span>
                              </td>
                              <td>
                                <span className="alln_td_span_date">
                                  {moment(notif.date).format('YYYY-MM-DD')}
                                </span>
                              </td>
                            </tr>
                          );
                        if (notif.type === 'report')
                          return (
                            <tr
                              style={{ cursor: 'pointer' }}
                              onClick={() =>
                                this.props.history.push({
                                  pathname: '/reports',
                                  state: { date: new Date(notif.date) },
                                })
                              }
                              key={notif.id}
                            >
                              <td>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="44"
                                  height="44"
                                  viewBox="0 0 44 44"
                                >
                                  <circle
                                    id="Ellipse_37"
                                    data-name="Ellipse 37"
                                    cx="22"
                                    cy="22"
                                    r="22"
                                    fill="#ca2946"
                                  />
                                  <g id="gavel" transform="translate(5.568 5)">
                                    <path
                                      id="Path_41"
                                      data-name="Path 41"
                                      d="M240.99,422.673v3.707a.923.923,0,0,0,.923.923H256.74a.923.923,0,0,0,.923-.923v-3.707a.923.923,0,0,0-.923-.923H241.913A.923.923,0,0,0,240.99,422.673Zm1.846.923h12.982v1.861H242.836Z"
                                      transform="translate(-226.164 -395.802)"
                                      fill="#fff"
                                    />
                                    <path
                                      id="Path_42"
                                      data-name="Path 42"
                                      d="M.822,27.786a2.779,2.779,0,0,0,3.926,0L15.891,16.644l1.316,1.316-.658.658a.923.923,0,0,0,0,1.305l2.621,2.621a.923.923,0,0,0,1.305,0l7.863-7.863a.923.923,0,0,0,0-1.305l-2.621-2.621a.923.923,0,0,0-1.305,0l-.658.658L17.2,4.854l.658-.658a.923.923,0,0,0,0-1.305L15.233.27a.923.923,0,0,0-1.305,0L6.065,8.134a.923.923,0,0,0,0,1.305L8.686,12.06a.923.923,0,0,0,1.305,0l.658-.658,1.316,1.316L.822,23.86a2.779,2.779,0,0,0,0,3.926ZM15.9,3.544,9.338,10.1,8.022,8.786,14.58,2.228Zm2.61,15.727,6.558-6.558,1.316,1.316-6.558,6.558Zm.005-2.616L11.954,10.1,15.891,6.16l6.558,6.558ZM2.127,25.165,13.27,14.023l1.316,1.316L3.443,26.481a.931.931,0,1,1-1.316-1.316Z"
                                      transform="translate(-0.011 0)"
                                      fill="#fff"
                                    />
                                  </g>
                                </svg>
                                {notif.description}
                              </td>
                              {/*<td>
                                <span className="alln_td_span_time">
                                  {moment(notif.date).format('HH:mm')}
                                </span>
                              </td>*/}
                              <td>
                                <span className="alln_td_span_date">
                                  {moment(notif.date).format('YYYY-MM-DD')}
                                </span>
                              </td>
                            </tr>
                          );
                        if (notif.type === 'session')
                          return (
                            <tr
                              style={{ cursor: 'pointer' }}
                              onClick={() =>
                                this.props.history.push({
                                  pathname: '/session',
                                  state: { date: new Date(notif.date) },
                                })
                              }
                              key={notif.id}
                            >
                              <td>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="44"
                                  height="44"
                                  viewBox="0 0 44 44"
                                >
                                  <circle
                                    id="Ellipse_38"
                                    data-name="Ellipse 38"
                                    cx="22"
                                    cy="22"
                                    r="22"
                                    fill="#ca2946"
                                  />
                                  <g id="paper" transform="translate(8.688 8.5)">
                                    <path
                                      id="Path_122"
                                      data-name="Path 122"
                                      d="M12.648,16.727H2.388A.388.388,0,0,1,2,16.34V2.388A.388.388,0,0,1,2.388,2H9.366a.388.388,0,0,1,.274.113l3.283,3.281a.388.388,0,0,1,.114.274V16.34A.388.388,0,0,1,12.648,16.727Zm-9.873-.775h9.486V5.829L9.205,2.775H2.775Z"
                                      transform="translate(-2 -2)"
                                      fill="#fff"
                                      stroke="#fff"
                                      strokeWidth="1"
                                    />
                                    <path
                                      id="Path_123"
                                      data-name="Path 123"
                                      d="M23.676,6.06H20.394a.388.388,0,0,1-.388-.388V2.388a.388.388,0,1,1,.775,0v2.9h2.9a.388.388,0,0,1,0,.775Z"
                                      transform="translate(-13.028 -2)"
                                      fill="#fff"
                                      stroke="#fff"
                                      strokeWidth="1"
                                    />
                                    <path
                                      id="Path_124"
                                      data-name="Path 124"
                                      d="M13.6,31.2H6.628a.388.388,0,0,1,0-.775H13.6a.388.388,0,0,1,0,.775Z"
                                      transform="translate(-4.597 -19.406)"
                                      fill="#fff"
                                      stroke="#fff"
                                      strokeWidth="1"
                                    />
                                    <path
                                      id="Path_125"
                                      data-name="Path 125"
                                      d="M13.6,24.249H6.628a.388.388,0,0,1,0-.775H13.6a.388.388,0,0,1,0,.775Z"
                                      transform="translate(-4.597 -15.152)"
                                      fill="#fff"
                                      stroke="#fff"
                                      strokeWidth="1"
                                    />
                                    <path
                                      id="Path_126"
                                      data-name="Path 126"
                                      d="M13.6,17.3H6.628a.388.388,0,1,1,0-.775H13.6a.388.388,0,1,1,0,.775Z"
                                      transform="translate(-4.597 -10.896)"
                                      fill="#fff"
                                      stroke="#fff"
                                      strokeWidth="1"
                                    />
                                    <path
                                      id="Path_127"
                                      data-name="Path 127"
                                      d="M10.321,10.354H6.628a.388.388,0,0,1,0-.775h3.694a.388.388,0,1,1,0,.775Z"
                                      transform="translate(-4.597 -6.642)"
                                      fill="#fff"
                                      stroke="#fff"
                                      strokeWidth="1"
                                    />
                                    <path
                                      id="Path_128"
                                      data-name="Path 128"
                                      d="M54.172,48.727H43.912a.388.388,0,0,1-.388-.388V34.388A.388.388,0,0,1,43.912,34h6.978a.388.388,0,0,1,.274.113l3.283,3.281a.388.388,0,0,1,.114.274V48.34A.388.388,0,0,1,54.172,48.727ZM44.3,47.952h9.486V37.829l-3.056-3.054H44.3Z"
                                      transform="translate(-27.431 -21.598)"
                                      fill="#fff"
                                      stroke="#fff"
                                      strokeWidth="1"
                                    />
                                    <path
                                      id="Path_129"
                                      data-name="Path 129"
                                      d="M65.2,38.06H61.917a.388.388,0,0,1-.388-.388V34.388a.388.388,0,0,1,.775,0v2.9h2.9a.388.388,0,1,1,0,.775Z"
                                      transform="translate(-38.458 -21.598)"
                                      fill="#fff"
                                      stroke="#fff"
                                      strokeWidth="1"
                                    />
                                    <path
                                      id="Path_130"
                                      data-name="Path 130"
                                      d="M55.124,63.2H48.148a.388.388,0,1,1,0-.775h6.976a.388.388,0,1,1,0,.775Z"
                                      transform="translate(-30.025 -39.004)"
                                      fill="#fff"
                                      stroke="#fff"
                                      strokeWidth="1"
                                    />
                                    <path
                                      id="Path_131"
                                      data-name="Path 131"
                                      d="M55.124,56.249H48.148a.388.388,0,1,1,0-.775h6.976a.388.388,0,1,1,0,.775Z"
                                      transform="translate(-30.025 -34.75)"
                                      fill="#fff"
                                      stroke="#fff"
                                      strokeWidth="1"
                                    />
                                    <path
                                      id="Path_132"
                                      data-name="Path 132"
                                      d="M55.124,49.3H48.148a.388.388,0,1,1,0-.775h6.976a.388.388,0,1,1,0,.775Z"
                                      transform="translate(-30.025 -30.495)"
                                      fill="#fff"
                                      stroke="#fff"
                                      strokeWidth="1"
                                    />
                                    <path
                                      id="Path_133"
                                      data-name="Path 133"
                                      d="M51.851,42.354H48.158a.388.388,0,0,1,0-.775h3.693a.388.388,0,0,1,0,.775Z"
                                      transform="translate(-30.032 -26.24)"
                                      fill="#fff"
                                      stroke="#fff"
                                      strokeWidth="1"
                                    />
                                    <path
                                      id="Path_134"
                                      data-name="Path 134"
                                      d="M50.451,16.4a.388.388,0,0,1-.388-.388V10.925H44.638a.388.388,0,1,1,0-.775h5.813a.388.388,0,0,1,.388.388v5.472A.388.388,0,0,1,50.451,16.4Z"
                                      transform="translate(-27.876 -6.991)"
                                      fill="#fff"
                                      stroke="#fff"
                                      strokeWidth="1"
                                    />
                                    <path
                                      id="Path_135"
                                      data-name="Path 135"
                                      d="M45.906,10.189a.388.388,0,0,1-.274-.113L44.364,8.808a.388.388,0,0,1,0-.548l1.268-1.268a.388.388,0,1,1,.548.548l-.994.994.994.994a.388.388,0,0,1-.274.662Z"
                                      transform="translate(-27.876 -4.988)"
                                      fill="#fff"
                                      stroke="#fff"
                                      strokeWidth="1"
                                    />
                                    <path
                                      id="Path_136"
                                      data-name="Path 136"
                                      d="M18.951,53.979H13.138a.388.388,0,0,1-.388-.388V48.12a.388.388,0,1,1,.775,0V53.2h5.426a.388.388,0,0,1,0,.775Z"
                                      transform="translate(-8.584 -30.008)"
                                      fill="#fff"
                                      stroke="#fff"
                                      strokeWidth="1"
                                    />
                                    <path
                                      id="Path_137"
                                      data-name="Path 137"
                                      d="M24.866,61.889a.388.388,0,0,1-.274-.662l.994-.994-.994-.994a.388.388,0,1,1,.548-.548l1.268,1.268a.388.388,0,0,1,0,.548L25.14,61.776A.388.388,0,0,1,24.866,61.889Z"
                                      transform="translate(-15.767 -36.651)"
                                      fill="#fff"
                                      stroke="#fff"
                                      strokeWidth="1"
                                    />
                                  </g>
                                </svg>
                                {notif.description}
                              </td>
                              <td>
                                <span className="alln_td_span_time">
                                  {moment(notif.date).format('HH:mm')}
                                </span>
                              </td>
                              <td>
                                <span className="alln_td_span_date">
                                  {moment(notif.date).format('YYYY-MM-DD')}
                                </span>
                              </td>
                            </tr>
                          );
                      })
                    ) : (
                      <tr>
                        <td colSpan={3}>لا يوجد سجل متاح</td>
                      </tr>
                    )}
                  </tbody>
                  {notifications &&
                  notifications.total > 5 &&
                  notifications.data.length < notifications.total ? (
                    <tfoot>
                      <tr style={{ height: '4.2rem' }}>
                        <td style={{ textAlign: 'center' }} colSpan={3}>
                          <div className="home_table__footer">
                            <div
                              onClick={() =>
                                this.props.changeSearchedStringNotification({
                                  ...paramsNotification,
                                  currentPage: 1,
                                  limit: paramsNotification.limit + 5,
                                })
                              }
                            >
                              المزيد
                              <svg
                                className=""
                                xmlns="http://www.w3.org/2000/svg"
                                width="15.225"
                                height="5.492"
                                viewBox="0 0 15.225 5.492"
                              >
                                <path
                                  id="Path_53"
                                  data-name="Path 53"
                                  d="M3020.1,1976.5l7.3,4.478,7.4-4.478"
                                  transform="translate(-3019.841 -1976.072)"
                                  fill="none"
                                  stroke="var(--color-primary-red)"
                                  strokeWidth="1"
                                />
                              </svg>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tfoot>
                  ) : notifications &&
                    notifications.total > 5 &&
                    notifications.data.length === notifications.total ? (
                    <tfoot>
                      <tr style={{ height: '4.2rem' }}>
                        <td style={{ textAlign: 'center' }} colSpan={3}>
                          <div className="home_table__footer">
                            <div
                              onClick={() =>
                                this.props.changeSearchedStringNotification({
                                  ...paramsNotification,
                                  currentPage: 1,
                                  limit: paramsNotification.limit - 5,
                                })
                              }
                            >
                              اقل
                              <svg
                                className=""
                                xmlns="http://www.w3.org/2000/svg"
                                width="15.225"
                                height="5.492"
                                viewBox="0 0 15.225 5.492"
                              >
                                <path
                                  id="Path_53"
                                  data-name="Path 53"
                                  d="M3020.1,1976.5l7.3,4.478,7.4-4.478"
                                  transform="translate(-3019.841 -1976.072)"
                                  fill="none"
                                  stroke="var(--color-primary-red)"
                                  strokeWidth="1"
                                />
                              </svg>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tfoot>
                  ) : (
                    ''
                  )}
                </table>
              </div>
            </div>
          </div>

          <div className="alln_middle__col-left">
            <Calendar
              filter={true}
              changeSearchedStringNotification={
                this.props.changeSearchedStringNotification
              }
            />
          </div>
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Notification);
