import { getStatusError } from '../../../utils/helpres';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastAction from '../../../components/ToastAction';
import React from 'react';
import { apiCall } from '../../../js/actions';
import moment from 'moment-timezone';

const getByCustomerId = (id) => async (dispatch) => {
  dispatch({ type: 'GET_RECEIVABLE_BY_CUSTOMER_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'get',
      url: `/api/contract/${id}`,
    });
    dispatch({ type: 'GET_RECEIVABLE_BY_CUSTOMER_SUCCESS', contracts: data.data });
  } catch (err) {
    const { response } = err;
    if (response)
      dispatch({
        type: 'GET_RECEIVABLE_BY_CUSTOMER_FAILURE',
        err: err.message,
        status: getStatusError(err),
      });
  }
};
const getReceivableById = (id) => async (dispatch) => {
  dispatch({ type: 'GET_RECEIVABLE_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'get',
      url: `/api/receivable/get-one/${id}`,
    });
    dispatch({ type: 'GET_RECEIVABLE_SUCCESS', receivable: data.data });
  } catch (err) {
    const { response } = err;
    if (response)
      dispatch({
        type: 'GET_RECEIVABLE_FAILURE',
        err: err.message,
        status: getStatusError(err),
      });
  }
};
const submitReceivableForm = (values) => async (dispatch) => {
  dispatch({ type: 'UPDATE_RECEIVABLE_REQUESTING' });
  const {
    contract_id,
    amount,
    type1,
    customer_id,
    type,
    folder_id,
    date,
    content,
    lawsuit_id,
    payment_type,
  } = values;
  let request = {
    customer_id,
    type,
    folder_id,
    content,
    date: date ? moment(date).tz('UTC').utc().format('YYYY-MM-DD HH:mm:ss') : date,
    lawsuit_id,
    contract_id,
    type1,
    amount,
    payment_type,
  };
  console.log('+++++++++request', request);
  try {
    const { data } = await apiCall({
      method: values.id ? 'patch' : 'post',
      url: `/api/receivable/${values.id ? `update/${values.id}` : 'create'}`,
      data: request,
    });
    if (data.type === 'success') {
      toast(({ closeToast }) => <ToastAction type="success" message={data.message} />, {
        hideProgressBar: true,
      });

      dispatch({
        type: 'UPDATE_RECEIVABLE_SUCCESS',
        receivable: data.data,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      // toast.warn(data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_RECEIVABLE_FAILURE',
        ErrorMessage: data.message,
        receivable: values,
        status: true,
      });
    }
  } catch (e) {
    const { response } = e;
    if (response) {
      const { data } = response;
      if (data.message) {
        toast(
          ({ closeToast }) => (
            <ToastAction type="error" message={response.data.message} />
          ),
          { hideProgressBar: true },
        );
      } else
        toast(
          ({ closeToast }) => (
            <ToastAction
              type="error"
              message="عفوًا ، لم يتم إنشاء الجلسة ، يرجى إعادة المحاولة."
            />
          ),
          { hideProgressBar: true },
        );
      // toast.error(`Profil non ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_RECEIVABLE_FAILURE',
        msg: 'error server',
        err: data.errors || data.message,
        receivable: values,
        status: getStatusError(e),
      });
    } else {
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="error"
            message="عفوًا ، لم يتم إنشاء الجلسة ، يرجى إعادة المحاولة."
          />
        ),
        { hideProgressBar: true },
      );
      // toast.error(`Profil non ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_RECEIVABLE_FAILURE',
        msg: 'error server',
        receivable: values,
        status: getStatusError(e),
      });
    }
  }
};
const deleteReceivable = (id, type) => async (dispatch) => {
  dispatch({ type: 'ACTION_DELETE_RECEIVABLE_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'delete',
      url: `/api/receivable/delete/${id}?type=${type}`,
    });
    if (data.type === 'success') {
      toast(({ closeToast }) => <ToastAction type="success" message={data.message} />, {
        hideProgressBar: true,
      });
      dispatch({
        type: 'ACTION_DELETE_RECEIVABLE_SUCCESS',
        destroySession: data,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      dispatch({
        type: 'ACTION_DELETE_RECEIVABLE_FAILURE',
        ErrorMessage: data.message,
        data: false,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    toast(({ closeToast }) => <ToastAction type="error" message="لم يتم حذف الجلسة" />, {
      hideProgressBar: true,
    });
    dispatch({
      type: 'ACTION_DELETE_JURISPRUDENCE_FAILURE',
      msg: 'error server',
      err: data.errors || data.message,
      data: false,
    });
  }
};

export default {
  getReceivableById,
  submitReceivableForm,
  deleteReceivable,
  getByCustomerId,
};
