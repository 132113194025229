import React, { Component } from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import actions from '../store/actions';
import { connect } from 'react-redux';
import { courts, DaysArabic, host } from '../../../utils/constants';
import moment from 'moment';
import { CircularProgress } from '@material-ui/core';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import axios from 'axios';

const mapStateToProps = (state) => ({
  appeal: state.AppealReducer.appeal,
  isLoadingAppeal: state.AppealReducer.isLoadingAppeal,
});

const mapDispatchToProps = (dispatch) => ({
  getAppealById: (id) => dispatch(actions.getAppealById(id)),
});

class Show extends Component {
  constructor(props) {
    super(props);
    props.getAppealById(this.props.selected_id);
    this.state = {
      isOpenModal: false,
      urlImg: null,
    };
  }
  openModal() {
    this.setState({
      isOpenModal: !this.state.isOpenModal,
    });
  }
  render() {
    const { left, anchor, appeal, isLoadingAppeal } = this.props;
    const { isOpenModal, urlImg } = this.state;
    return (
      <SwipeableDrawer
        style={{ width: '40rem' }}
        anchor="left"
        open={left}
        onClose={this.props.toggleDrawer(anchor, false)}
        onOpen={this.props.toggleDrawer(anchor, true)}
      >
        {isLoadingAppeal ? (
          <div
            style={{
              position: 'initial',
              width: '70rem',
              display: 'flex',
            }}
            className="rv_newDate"
          >
            <div className="clients_details_section">
              <CircularProgress
                style={{
                  textAlign: 'center',
                  width: '30px',
                  height: '30px',
                  marginTop: '39%',
                  marginRight: '42%',
                }}
              />
            </div>
          </div>
        ) : (
          <div
            style={{
              position: 'initial',
              width: '70rem',
              display: 'flex',
            }}
            className="rv_newDate"
          >
            <div className="clients_details_section">
              <div className="clients_details_section__body">
                <div className="clients_details_section__field">
                  <div className="clients_field_title item-name">المحكمة</div>
                  <div className="clients_data_addr">{appeal.court}</div>
                </div>
                <div className="clients_details_section__field">
                  <div className="clients_field_title item-name">تاريخ الجلسة</div>
                  <div className="clients_data_addr">
                    {' '}
                    {DaysArabic[moment(appeal.date_court).format('dddd')]}{' '}
                    {moment(appeal.date_court).format('DD/MM/YYYY')}{' '}
                    <span style={{ color: '#ca2946' }}>
                      {moment(appeal.date_court).format('HH:mm')}h
                    </span>
                  </div>
                </div>
                <div className="clients_details_section__field">
                  <div className="clients_field_title item-name">تاريخ الحكم</div>
                  <div className="clients_data_addr">
                    {' '}
                    {DaysArabic[moment(appeal.date_judgment).format('dddd')]}{' '}
                    {moment(appeal.date_judgment).format('DD/MM/YYYY')}{' '}
                    <span style={{ color: '#ca2946' }}>
                      {moment(appeal.date_judgment).format('HH:mm')}h
                    </span>
                  </div>
                </div>
                <div className="clients_details_section__field">
                  <div className="clients_field_title item-name">تاريخ الطعن</div>
                  <div className="clients_data_addr">
                    {' '}
                    {DaysArabic[moment(appeal.date_appeal).format('dddd')]}{' '}
                    {moment(appeal.date_appeal).format('DD/MM/YYYY')}{' '}
                    <span style={{ color: '#ca2946' }}>
                      {moment(appeal.date_appeal).format('HH:mm')}h
                    </span>
                  </div>
                </div>
                <div className="clients_details_section__field">
                  <div className="clients_field_title item-name">نوع الطعن</div>
                  <div className="clients_data_addr">{appeal.judgment.name}</div>
                </div>
                {appeal.file ? (
                  <div className="clients_details_section__field">
                    <div className="clients_field_title item-name">الملف</div>
                    <div className="clients_data_addr">
                      <div
                        onClick={() => {
                          axios(`${host}${appeal.file}`, {
                            method: 'GET',
                            headers: {
                              Authorization: `JWT ${window.localStorage.getItem(
                                'token',
                              )}`,
                            },
                            responseType: 'blob', //Force to receive data in a Blob Format
                          })
                            .then((response) => {
                              let url;
                              if (appeal.file.split('.')[1] === 'pdf') {
                                const fileP = new Blob([response.data], {
                                  type: 'application/pdf',
                                });
                                url = URL.createObjectURL(fileP);

                                window.open(url, '_blank');
                              } else {
                                this.setState({
                                  urlImg: URL.createObjectURL(new Blob([response.data])),
                                  isOpenModal: true,
                                });
                              }
                            })
                            .catch((error) => {
                              console.log(error);
                            });
                        }}
                        style={{ cursor: 'pointer', width: '30rem' }}
                        className="uploaded_file__name"
                      >
                        <PictureAsPdfIcon /> {appeal.file_name}
                        {/*<a target="_blank" href={`${host}${appeal.file}`}>

                        </a>*/}
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <div className="clients_details_section__field">
                  <div className="clients_field_title item-name">الحكم</div>
                  <div className="clients_data_city">{appeal.judgment_}</div>
                </div>
                <div className="clients_details_section__field">
                  <div className="clients_field_title item-name">العريضة</div>
                  <div className="clients_data_city">{appeal.requests}</div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className={isOpenModal ? 'popup active' : 'popup'}>
          <div className="popup__title">{appeal?.file_name}</div>
          <div className="popup__item">
            <div style={{ width: '100%' }} className="popup__text">
              <div
                style={{ display: 'block', flexDirection: 'row' }}
                className="lawsuit_form__sections"
              >
                <img style={{ width: '100%', height: '500px' }} src={urlImg} />
              </div>
            </div>
          </div>
          <div className="popup__btn_grp">
            <button
              type="button"
              onClick={() => this.openModal()}
              className="cancel_popup"
            >
              <span>الغاء</span>
              <span>&lt;</span>
            </button>
          </div>
        </div>
        <div id="overlay" className={isOpenModal ? 'active' : ''}></div>
      </SwipeableDrawer>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Show);
