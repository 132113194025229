import React from 'react';
import { apiCall } from '../../../js/actions';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import { getStatusError } from '../../../utils/helpres';
import ToastAction from '../../../components/ToastAction';

const getAllRdvByDay = (params) => async (dispatch) => {
  dispatch({ type: 'GET_RDV_ALL_LAWYER_REQUESTING' });
  try {
    const { date } = params || {
      date: '',
    };
    const { status } = params || {
      status: '',
    };

    const { data } = await apiCall({
      method: 'get',
      url: `/api/appointment?date=${date}`,
    });
    dispatch({
      type: 'GET_RDV_ALL_LAWYER_SUCCESS',
      data: data,
    });
  } catch (e) {
    const { response } = e;
    dispatch({
      type: 'GET_RDV_ALL_LAWYER_FAILURE',
      msg: 'error server',
    });
  }
};

const getAllUnavailability = (params) => async (dispatch) => {
  dispatch({ type: 'GET_ALL_UNAVAILABILITY_DOCTOR_REQUESTING' });
  try {
    const { date } = params || {
      date: '',
    };
    const { data } = await apiCall({
      method: 'get',
      url: `/api/unavailability?date=${moment(date).format(
        'YYYY-MM-DD',
      )}&hours08=${moment(`${moment().format('YYYY/MM/DD')} 08:00:00`)
        .tz('UTC')
        .utc()
        .format('HH')}&hours20=${moment(`${moment().format('YYYY/MM/DD')} 20:00:00`)
        .tz('UTC')
        .utc()
        .format('HH')}&hours8=${moment(`${moment().format('YYYY/MM/DD')} 08:00:00`)
        .tz('UTC')
        .utc()
        .format('HH')}`,
    });
    dispatch({
      type: 'GET_ALL_UNAVAILABILITY_DOCTOR_SUCCESS',
      data: data,
    });
  } catch (e) {
    const { response } = e;
    dispatch({
      type: 'GET_ALL_UNAVAILABILITY_DOCTOR_FAILURE',
      msg: 'error server',
      err: response.type || response.message,
    });
  }
};

const submitRDVForm = (values) => async (dispatch) => {
  dispatch({ type: 'CREATE_NEW_RDV_REQUESTING' });
  const { place, display_name, description, city, date, phone_number } = values;
  let valNew = {
    display_name,
    description,
    date_from: moment(date).tz('UTC').utc().format('YYYY-MM-DD HH:mm:ss'),
    date_to: moment(date)
      .tz('UTC')
      .utc()
      .add(30, 'minutes')
      .format('YYYY-MM-DD HH:mm:ss'),
    place,
    city,
    phone_number,
  };
  try {
    const { data } = await apiCall({
      method: values.id ? 'patch' : 'post',
      url: `/api/appointment/${values.id ? `update/${values.id}` : 'create'}`,
      data: values.status === 'canceled' ? { status: 'canceled' } : valNew,
    });
    const response = data;
    if (response.type === 'success') {
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="success"
            message={`${values.id ? `تم تعديل الموعد بنجاح` : `تم إنشاء الموعد بنجاح`}`}
          />
        ),
        { hideProgressBar: true },
      );
      if (values.status !== 'canceled')
        dispatch({
          type: 'CREATE_NEW_RDV_SUCCESS',
          msg: response.message,
          data: response.data,
        });
      else
        dispatch({
          type: 'UPDATE_STATUS_RDV_SUCCESS',
          msg: response.message,
          data: response.data,
        });
    } else {
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="error"
            message={`${values.id ? `فشل في تعديل الموعد` : `فشل في إنشاء الموعد`}`}
          />
        ),
        { hideProgressBar: true },
      );
      //toast.error(`${values.id ? `Rdv n'est pas modifiéé` : `Rdv n'est pas ajouté`}`, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'CREATE_NEW_RDV_FAILURE',
        msg: response.data,
        ErrorMessage: response.message,
        site: values,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    toast(
      ({ closeToast }) => (
        <ToastAction
          type="error"
          message={`${
            data.type === 'error'
              ? data.message
              : values.id
              ? `فشل في تعديل الموعد`
              : `فشل في إنشاء الموعد`
          }`}
        />
      ),
      { hideProgressBar: true },
    );
    //toast.error(`${data.type === 'error' ? data.message : values.id ? `Rdv n'est pas modifiéé` : `Rdv n'est pas ajouté`}`, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'CREATE_NEW_RDV_FAILURE',
      msg: 'error server',
      err: data.type || data.message,
      data: values,
    });
  }
};

const deleteUnavailability = (item) => async (dispatch) => {
  dispatch({ type: 'GET_DELETE_UNAVAILABILITY_REQUESTING' });
  try {
    const url = `/api/unavailability/${item.id}`;
    const { data } = await apiCall({
      method: 'delete',
      url,
      data: {
        date_from: moment(item.date_from).tz('UTC').utc().format('YYYY-MM-DD HH:mm:ss'),
        hours08: moment(`${moment().format('YYYY/MM/DD')} 08:00:00`)
          .tz('UTC')
          .utc()
          .format('HH'),
        hours20: moment(`${moment().format('YYYY/MM/DD')} 20:00:00`)
          .tz('UTC')
          .utc()
          .format('HH'),
      },
    });
    if (data.type === 'success')
      toast(({ closeToast }) => <ToastAction type="success" message={data.message} />, {
        hideProgressBar: true,
      });
    else
      toast(({ closeToast }) => <ToastAction type="error" message={data.message} />, {
        hideProgressBar: true,
      });
    dispatch({ type: 'GET_DELETE_UNAVAILABILITY_SUCCESS', data });
  } catch (err) {
    const { response } = err;
    toast(
      ({ closeToast }) => (
        <ToastAction type="error" message="Echec de suppression de l'indisponibilité" />
      ),
      {
        hideProgressBar: true,
      },
    );
    dispatch({
      type: 'GET_DELETE_UNAVAILABILITY_FAILURE',
      err: err.message,
      status: getStatusError(err),
    });
  }
};

const submitUnavailabilityForm = (values) => async (dispatch) => {
  const { startDate, endDate, startHours, endHours, note } = values;
  let val = {
    startDate: moment(
      `${moment(startDate).format('YYYY/MM/DD')} ${moment(startHours).format('HH:mm')}`,
    )
      .tz('UTC')
      .utc()
      .format('YYYY-MM-DD HH:mm:ss'),
    startDate08: moment(`${moment(startDate).format('YYYY/MM/DD')} 08:00:00`)
      .tz('UTC')
      .utc()
      .format('YYYY-MM-DD HH:mm:ss'),
    startDate2359: moment(`${moment(startDate).format('YYYY/MM/DD')} 23:59:00`)
      .tz('UTC')
      .utc()
      .format('YYYY-MM-DD HH:mm:ss'),
    startDate00: moment(`${moment(startDate).format('YYYY/MM/DD')} 00:00:00`)
      .tz('UTC')
      .utc()
      .format('YYYY-MM-DD HH:mm:ss'),
    startDate1930: moment(`${moment(startDate).format('YYYY/MM/DD')} 19:30:00`)
      .tz('UTC')
      .utc()
      .format('YYYY-MM-DD HH:mm:ss'),
    hours08: moment(`${moment(startDate).format('YYYY/MM/DD')} 08:00:00`)
      .tz('UTC')
      .utc()
      .format('HH'),
    hours20: moment(`${moment(startDate).format('YYYY/MM/DD')} 20:00:00`)
      .tz('UTC')
      .utc()
      .format('HH'),
    startDate20: moment(`${moment(startDate).format('YYYY/MM/DD')} 20:00:00`)
      .tz('UTC')
      .utc()
      .format('YYYY-MM-DD HH:mm:ss'),
    endDate: moment(
      `${moment(endDate).format('YYYY/MM/DD')}  ${moment(endHours).format('HH:mm')}`,
    )
      .tz('UTC')
      .utc()
      .format('YYYY-MM-DD HH:mm:ss'),
    note: note ? note : 'غير متوفره',
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  try {
    const { data } = await apiCall({
      method: values.id ? 'patch' : 'post',
      url: `/api/unavailability/${values.id ? `update/${values.id}` : 'create'}`,
      data: val,
    });
    const response = data;
    if (response.type === 'success') {
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="success"
            message={
              values.id ? 'تم تغيير عدم التوفر بنجاح' : 'تم تسجيل عدم التوفر بنجاح'
            }
          />
        ),
        { hideProgressBar: true },
      );
      // toast.success(`Date a été ajouté`, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'CREATE_NEW_DISPO_SUCCESS',
        msg: response.message,
        data: response.data,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="error" message={response.message} />, {
        hideProgressBar: true,
      });
      // toast.error(response.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'CREATE_NEW_DISPO_FAILURE',
        msg: response.data,
        ErrorMessage: response.message,
        site: values,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    toast(({ closeToast }) => <ToastAction type="error" message={data.message} />, {
      hideProgressBar: true,
    });
    //  toast.error(data.message, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'CREATE_NEW_DISPO_FAILURE',
      msg: 'error server',
      err: data.type || data.message,
      data: values,
    });
  }
};

const deleteRdv = (id) => async (dispatch) => {
  dispatch({ type: 'GET_DELETE_RDV_REQUESTING' });
  try {
    const url = `/api/appointment/delete/${id}`;
    const { data } = await apiCall({
      method: 'delete',
      url,
    });
    if (data.type === 'success')
      toast(({ closeToast }) => <ToastAction type="success" message={data.message} />, {
        hideProgressBar: true,
      });
    else
      toast(({ closeToast }) => <ToastAction type="error" message={data.message} />, {
        hideProgressBar: true,
      });
    dispatch({ type: 'GET_DELETE_RDV_SUCCESS', data });
  } catch (err) {
    const { response } = err;
    toast(
      ({ closeToast }) => (
        <ToastAction type="error" message="Echec de suppression du rendez-vous" />
      ),
      {
        hideProgressBar: true,
      },
    );
    dispatch({
      type: 'GET_DELETE_RDV_FAILURE',
      err: err.message,
      status: getStatusError(err),
    });
  }
};

const changeParams = (params) => ({
  type: 'UPDATE_DATE_UNAVAILABILITY_PARAMS',
  params: params,
});

export default {
  deleteRdv,
  changeParams,
  getAllUnavailability,
  getAllRdvByDay,
  submitUnavailabilityForm,
  submitRDVForm,
  deleteUnavailability,
};
