import React, { Component } from 'react';
import Form from './containers/form';
import actions from './store/actions';
import actionsApp from '../../js/actions';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';

const mapStateToProps = (state) => ({
  receivable: state.ReceivableReducer.receivable,
  isLoadingReceivable: state.ReceivableReducer.isLoadingReceivable,
  lawsuitsSessions: state.AppReducer.lawsuitsSessions,
  isLoadingLawsuitsReceivables: state.AppReducer.isLoadingLawsuitsReceivables,
  isLoadingLawyerTraineeShip: state.SettingsReducer.isLoadingLawyerTraineeShip,
  lawyerTraineeShip: state.SettingsReducer.lawyerTraineeShip,
  customersList: state.AppReducer.customersList,
  isLoadingCustomersLis: state.AppReducer.isLoadingCustomersLis,
});

const mapDispatchToProps = (dispatch) => ({
  getReceivableById: (id) => dispatch(actions.getReceivableById(id)),
  getListLawsuitsWithOutSession: () =>
    dispatch(actionsApp.getListLawsuitsWithOutSession()),
  getListCustomerByLawyer: () => dispatch(actionsApp.getListCustomerByLawyer()),
});

class FormReceivable extends Component {
  constructor(props) {
    super(props);
    props.getListCustomerByLawyer();
    props.getListLawsuitsWithOutSession();
    props.getReceivableById(props && props.id);
    this.goBack = this.goBack.bind(this);
  }
  goBack() {
    this.props.history.goBack();
  }
  render() {
    const {
      receivable,
      lawyerTraineeShip,
      lawsuitsSessions,
      isLoadingSession,
      isLoadingLawsuitsSessions,
      customersList,
      isLoadingCustomersLis,
      isLoadingReceivable,
      paramsReceivables,
    } = this.props;
    console.log('++++++++++++++receivable', receivable);

    if (isLoadingReceivable) {
      return (
        <CircularProgress
          style={{
            textAlign: 'center',
            width: '30px',
            height: '30px',
            marginTop: '20%',
            marginRight: '50%',
          }}
        />
      );
    }
    if (isLoadingCustomersLis) {
      return (
        <CircularProgress
          style={{
            textAlign: 'center',
            width: '30px',
            height: '30px',
            marginTop: '20%',
            marginRight: '50%',
          }}
        />
      );
    }
    if (isLoadingSession) {
      return (
        <CircularProgress
          style={{
            textAlign: 'center',
            width: '30px',
            height: '30px',
            marginTop: '20%',
            marginRight: '50%',
          }}
        />
      );
    }
    if (isLoadingLawsuitsSessions) {
      return (
        <CircularProgress
          style={{
            textAlign: 'center',
            width: '30px',
            height: '30px',
            marginTop: '20%',
            marginRight: '50%',
          }}
        />
      );
    }

    return (
      <div className="jalaset_main">
        <div className="jalaset_newSession" style={{ display: 'flex' }}>
          <Form
            goBack={this.props.closeModal}
            lawyerTraineeShip={lawyerTraineeShip}
            receivable={receivable}
            customers={customersList}
            lawsuitsSessions={lawsuitsSessions}
            paramsReceivables={paramsReceivables}
            isLoadingLawsuitsSessions={isLoadingLawsuitsSessions}
          />
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(FormReceivable);
