import React, { Component } from 'react';
import {
  Navbar,
  Nav,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Container,
  Collapse,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import LogoSmall from '../../../../assets/img/logo.png';
import ScrollspyNav from './Scrollspy';
import { isMobile } from 'react-device-detect';

class NavbarPage extends Component {
  // render() {
  constructor(props) {
    super(props);
    this.state = {
      isOpenMenu: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle = () => {
    this.setState({ isOpenMenu: !this.state.isOpenMenu });
  };

  render() {
    let targetId = this.props.navItems.map((item) => {
      return item.idnm;
    });
    return (
      <React.Fragment>
        <Navbar
          expand="lg"
          fixed={this.props.top === true ? 'top' : ''}
          className={this.props.navClass + ' navbar-custom sticky sticky-dark'}
          id="navbar"
        >
          <Container>
            {/* LOGO */}
            <NavbarBrand className="navbar-brand logo text-uppercase" href="/">
              {this.props.imglight === true ? (
                <img src={LogoSmall} alt="" height="50" />
              ) : (
                <img src={LogoSmall} alt="" height="50" />
              )}
            </NavbarBrand>
            <NavbarToggler onClick={this.toggle}>
              <i className="mdi mdi-menu"></i>
            </NavbarToggler>
            <Collapse
              id="navbarCollapse"
              isOpen={this.state.isOpenMenu}
              className=" navbar-collapse"
            >
              <ScrollspyNav
                scrollTargetIds={targetId}
                scrollDuration="800"
                headerBackground="true"
                activeNavClass="active"
                className="navbar-collapse"
              >
                <Nav className="navbar-nav ml-auto navbar-center" id="navbar-navlist">
                  {this.props.navItems.map((item, key) => (
                    <NavItem
                      key={key}
                      className={item.navheading === 'الصفحة الرئيسية' ? 'active' : ''}
                    >
                      <NavLink
                        className={item.navheading === 'الصفحة الرئيسية' ? 'active' : ''}
                        href={'#' + item.idnm}
                      >
                        {item.navheading}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
              </ScrollspyNav>
              <ul className="navbar-nav navbar-center">
                {isMobile ? (
                  ''
                ) : (
                  <li className="nav-item">
                    <Link to="/login" className="nav-link">
                      تسجيل دخول
                    </Link>
                  </li>
                )}

                <li className="nav-item d-inline-block d-lg-none">
                  <Link to="/register" className="nav-link">
                    اشتراك
                  </Link>
                </li>
              </ul>
              <div className="navbar-button d-none d-lg-inline-block">
                <Link to="/register" className="btn btn-sm btn-soft-primary btn-round">
                  اشتراك
                </Link>
              </div>
            </Collapse>
          </Container>
        </Navbar>
      </React.Fragment>
    );
  }
}
// }
export default NavbarPage;
