import moment from 'moment';

const initialSettingsState = {
  error: undefined,
  changePassword: false,
  isLoadingChangePassword: false,
  addSuitType: false,
  isLoadingAddSuitType: false,
  isLoadingDeleteSuitType: false,
  deleteSuitType: false,
  addJudgment: false,
  isLoadingAddJudgment: false,
  isLoadingDeleteJudgment: false,
  deleteJudgment: false,
  addContract: false,
  isLoadingAddContract: false,
  isLoadingDeleteContract: false,
  deleteContract: false,
  isLoadingSecretaries: false,
  secretaries: [],
  isLoadingDestroySecretary: false,
  destroySecretary: null,
  isLoadingGroupPermission: false,
  groupPermission: [],
  isLoadingPayments: false,
  payments: null,
  active: false,
  isLoadingActive: false,
  updatePayment: false,
  isLoadingUpdatePayment: false,
  isLoadingActivityRegister: false,
  activityRegister: [],
  isLoadingLawyerTraineeShip: false,
  lawyerTraineeShip: [],
  isLoadingDestroyLawyerTraineeShip: false,
  destroyLawyerTraineeShip: false,
  paramsActivityRegister: {
    date: moment().format('YYYY-MM-DD'),
    order: 'DESC',
  },
};

const SettingsReducer = (state = initialSettingsState, action) => {
  switch (action.type) {
    case 'ACTION_DELETE_LAWYER_TRAINEE_SHIP_REQUESTING': {
      return {
        ...state,
        isLoadingDestroyLawyerTraineeShip: true,
      };
    }
    case 'ACTION_DELETE_LAWYER_TRAINEE_SHIP_SUCCESS': {
      return {
        ...state,
        isLoadingDestroyLawyerTraineeShip: false,
        destroyLawyerTraineeShip: true,
      };
    }
    case 'ACTION_DELETE_LAWYER_TRAINEE_SHIP_FAILURE': {
      return {
        ...state,
        isLoadingDestroyLawyerTraineeShip: false,
        destroyLawyerTraineeShip: [],
      };
    }

    case 'GET_LAWYER_TRAINEE_SHIP_REQUESTING': {
      return {
        ...state,
        isLoadingLawyerTraineeShip: true,
      };
    }
    case 'GET_LAWYER_TRAINEE_SHIP_SUCCESS': {
      return {
        ...state,
        isLoadingLawyerTraineeShip: false,
        lawyerTraineeShip: action.lawyerTraineeShip,
      };
    }
    case 'GET_LAWYER_TRAINEE_SHIP_FAILURE': {
      return {
        ...state,
        isLoadingLawyerTraineeShip: false,
        lawyerTraineeShip: [],
      };
    }

    case 'GET_ACTIVITY_REGISTER_REQUESTING': {
      return {
        ...state,
        isLoadingActivityRegister: true,
      };
    }
    case 'GET_ACTIVITY_REGISTER_SUCCESS': {
      return {
        ...state,
        isLoadingActivityRegister: false,
        activityRegister: action.activityRegister,
      };
    }
    case 'GET_ACTIVITY_REGISTER_FAILURE': {
      return {
        ...state,
        isLoadingActivityRegister: false,
        activityRegister: [],
      };
    }

    case 'UPDATE_PAYMENT_REQUESTING': {
      return {
        ...state,
        isLoadingUpdatePayment: true,
      };
    }
    case 'UPDATE_PAYMENT_SUCCESS': {
      return {
        ...state,
        isLoadingUpdatePayment: false,
        updatePayment: true,
      };
    }
    case 'UPDATE_PAYMENT_FAILURE': {
      return {
        ...state,
        isLoadingUpdatePayment: false,
        updatePayment: false,
      };
    }

    case 'ADD_ACTIVE_USER_REQUESTING': {
      return {
        ...state,
        isLoadingActive: true,
      };
    }
    case 'ADD_ACTIVE_USER_SUCCESS': {
      return {
        ...state,
        isLoadingActive: false,
        active: true,
      };
    }
    case 'ADD_ACTIVE_USER_FAILURE': {
      return {
        ...state,
        isLoadingActive: false,
        active: false,
      };
    }
    case 'GET_PAYMENTS_REQUESTING':
      return {
        ...state,
        isLoadingPayments: true,
      };
    case 'GET_PAYMENTS_SUCCESS':
      return {
        ...state,
        isLoadingPayments: false,
        payments: action.data,
      };
    case 'GET_PAYMENTS_FAILURE':
      return {
        ...state,
        isLoadingPayments: false,
        payments: [],
      };

    case 'GET_GROUP_PERMISSIONS_REQUESTING':
      return {
        ...state,
        isLoadingGroupPermission: true,
      };
    case 'GET_GROUP_PERMISSIONS_SUCCESS':
      return {
        ...state,
        isLoadingGroupPermission: false,
        groupPermission: action.data,
      };
    case 'GET_GROUP_PERMISSIONS_FAILURE':
      return {
        ...state,
        isLoadingGroupPermission: false,
        groupPermission: [],
      };

    case 'ACTION_DELETE_SECRETARY_REQUESTING':
      return {
        ...state,
        isLoadingDestroySecretary: true,
      };
    case 'ACTION_DELETE_SECRETARY_SUCCESS':
      return {
        ...state,
        isLoadingDestroySecretary: false,
        destroySecretary: true,
      };
    case 'ACTION_DELETE_SECRETARY_FAILURE':
      return {
        ...state,
        isLoadingDestroySecretary: false,
        destroySecretary: null,
      };

    case 'GET_SECRETARIES_REQUESTING':
      return {
        ...state,
        isLoadingSecretaries: true,
        active: false,
      };
    case 'GET_SECRETARIES_SUCCESS':
      return {
        ...state,
        isLoadingSecretaries: false,
        secretaries: action.data.data,
      };
    case 'GET_SECRETARIES_FAILURE':
      return {
        ...state,
        isLoadingSecretaries: false,
        secretaries: [],
      };

    case 'ACTION_DELETE_CONTRACT_REQUESTING':
      return {
        ...state,
        isLoadingDeleteContract: true,
        deleteContract: false,
      };
    case 'ACTION_DELETE_CONTRACT_SUCCESS':
      return {
        ...state,
        isLoadingDeleteContract: false,
        deleteContract: true,
      };
    case 'ACTION_DELETE_CONTRACT_FAILURE':
      return {
        ...state,
        isLoadingDeleteContract: false,
        deleteContract: false,
      };

    case 'UPDATE_CONTRACT_REQUESTING':
      return {
        ...state,
        isLoadingAddContract: true,
        addContract: false,
      };
    case 'UPDATE_CONTRACT_SUCCESS':
      return {
        ...state,
        isLoadingAddContract: false,
        addContract: true,
      };
    case 'UPDATE_CONTRACT_FAILURE':
      return {
        ...state,
        isLoadingAddContract: false,
        addContract: false,
      };

    case 'ACTION_DELETE_JUDGMENT_REQUESTING':
      return {
        ...state,
        isLoadingDeleteJudgment: true,
        deleteJudgment: false,
      };
    case 'ACTION_DELETE_JUDGMENT_SUCCESS':
      return {
        ...state,
        isLoadingDeleteJudgment: false,
        deleteJudgment: true,
      };
    case 'ACTION_DELETE_JUDGMENT_FAILURE':
      return {
        ...state,
        isLoadingDeleteJudgment: false,
        deleteJudgment: false,
      };

    case 'UPDATE_JUDGMENT_REQUESTING':
      return {
        ...state,
        isLoadingAddJudgment: true,
        addJudgment: false,
      };
    case 'UPDATE_JUDGMENT_SUCCESS':
      return {
        ...state,
        isLoadingAddJudgment: false,
        addJudgment: true,
      };
    case 'UPDATE_JUDGMENT_FAILURE':
      return {
        ...state,
        isLoadingAddJudgment: false,
        addJudgment: false,
      };

    case 'ACTION_DELETE_SUIT_TYPE_REQUESTING':
      return {
        ...state,
        isLoadingDeleteSuitType: true,
        deleteSuitType: false,
      };
    case 'ACTION_DELETE_SUIT_TYPE_SUCCESS':
      return {
        ...state,
        isLoadingDeleteSuitType: false,
        deleteSuitType: true,
      };
    case 'ACTION_DELETE_SUIT_TYPE_FAILURE':
      return {
        ...state,
        isLoadingDeleteSuitType: false,
        deleteSuitType: false,
      };

    case 'UPDATE_SUIT_TYPE_REQUESTING':
      return {
        ...state,
        isLoadingAddSuitType: true,
        addSuitType: false,
      };
    case 'UPDATE_SUIT_TYPE_SUCCESS':
      return {
        ...state,
        isLoadingAddSuitType: false,
        addSuitType: true,
      };
    case 'UPDATE_SUIT_TYPE_FAILURE':
      return {
        ...state,
        addSuitType: false,
        isLoadingAddSuitType: false,
      };

    case 'UPDATE_PASSWORD_REQUESTING':
      return {
        ...state,
        isLoadingChangePassword: true,
        changePassword: false,
      };
    case 'UPDATE_PASSWORD_SUCCESS':
      return {
        ...state,
        isLoadingChangePassword: false,
        changePassword: true,
      };
    case 'UPDATE_PASSWORD_FAILURE':
      return {
        ...state,
        isAuthorized: false,
        changePassword: false,
        isLoadingChangePassword: false,
        message: action.err,
        //error: getAppErrorCode(action.status),
      };
    case 'UPDATE_PARAMS_ACTIVITY_REGISTER':
      return {
        ...state,
        paramsActivityRegister: action.paramsActivityRegister,
      };
    default:
      return state;
  }
};

export default SettingsReducer;
