import React, { Component } from 'react';
import actions from '../../js/actions';
import actionsAppeal from './store/actions';
import { connect } from 'react-redux';
import Table from '../../components/Table';
import Filter from './components/filter';
import { Link } from 'react-router-dom';
import Show from './containers/show';
import { isHasPermission } from '../../utils/helpres';

const mapStateToProps = (state) => ({
  account: state.AppReducer.account,
  destroyAppeal: state.AppealReducer.destroyAppeal,
  appeals: state.AppReducer.appeals,
  isLoadingAppeals: state.AppReducer.isLoadingAppeals,
  paramsAppeals: state.AppReducer.paramsAppeals,
});

const mapDispatchToProps = (dispatch) => ({
  getListAppeals: (paramsAppeals) => dispatch(actions.getListAppeals(paramsAppeals)),
  changeSearchedStringAppeals: async (paramsAppeals) => {
    await dispatch(actions.changeSearchedStringAppeals(paramsAppeals));
    dispatch(actions.getListAppeals(paramsAppeals));
  },
  deleteAppeal: async (paramsAppeals, id) => {
    await dispatch(actionsAppeal.deleteAppeal(id));
    dispatch(actions.getListAppeals(paramsAppeals));
  },
  getAppealById: (id) => dispatch(actionsAppeal.getAppealById(id)),
});

class Appeals extends Component {
  constructor(props) {
    super(props);
    props.changeActiveItemSideBar(5, 'الطعون');
    document.title = 'الطعون';
    this.state = {
      anchor: false,
      left: false,
      selected_id: null,
    };
    this.toggleDrawer = this.toggleDrawer.bind(this);
  }
  toggleDrawer = (anchor, open, id) => (event) => {
    this.props.getAppealById(id);
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    this.setState({
      left: open,
      anchor: 'left',
      selected_id: id,
      hasError: false,
    });
  };
  componentDidMount() {
    this.props.changeSearchedStringAppeals({
      search: '',
      date: '',
      currentPage: 1,
      limit: 5,
      order: 'DESC',
    });
  }
  render() {
    const { account, appeals, destroyAppeal, isLoadingAppeals, paramsAppeals } =
      this.props;
    const { anchor, left, selected_id } = this.state;
    let isPermissionEdit = isHasPermission(
      account.login_access.permissions,
      'تعديل-الطعون',
    );
    let isPermissionDelete = isHasPermission(
      account.login_access.permissions,
      'حذف-الطعون',
    );
    let isPermissionAdd = isHasPermission(
      account.login_access.permissions,
      'إضافة-الطعون',
    );
    return (
      <div className="jalaset_main">
        {left && selected_id ? (
          <Show
            left={left}
            anchor={anchor}
            selected_id={selected_id}
            toggleDrawer={this.toggleDrawer}
          />
        ) : (
          ''
        )}
        <div className="jalaset_middle">
          <div className="table_controls">
            {isPermissionAdd ? (
              <Link to="/add-appeal" className="jalaset_add_button">
                <svg
                  height="10"
                  viewBox="0 0 448 448"
                  width="10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m408 184h-136c-4.417969 0-8-3.582031-8-8v-136c0-22.089844-17.910156-40-40-40s-40 17.910156-40 40v136c0 4.417969-3.582031 8-8 8h-136c-22.089844 0-40 17.910156-40 40s17.910156 40 40 40h136c4.417969 0 8 3.582031 8 8v136c0 22.089844 17.910156 40 40 40s40-17.910156 40-40v-136c0-4.417969 3.582031-8 8-8h136c22.089844 0 40-17.910156 40-40s-17.910156-40-40-40zm0 0" />
                </svg>
                اضافة طعن
              </Link>
            ) : (
              ''
            )}
            <div className="sort_grp">
              <div className="sort_grp__txt">ترتيب حسب</div>
              <Filter
                hideInput={true}
                changeSearchedStringSession={this.props.changeSearchedStringAppeals}
                paramsSessions={paramsAppeals}
              />
            </div>
          </div>
          <Table
            colSpan={6}
            class1="appeals__judgements_table"
            class2="appeals_table"
            dataTable="appeals"
            messageDelete="أنت بصدد حذف الطعن"
            withTitle={false}
            action={true}
            toggleDrawer={this.toggleDrawer}
            show={true}
            destroy={destroyAppeal}
            isLoading={isLoadingAppeals}
            params={paramsAppeals}
            changeSearchedString={this.props.changeSearchedStringAppeals}
            data={appeals}
            //sessions={sessions}
            title="الجلسات"
            editPath="edit-appeal"
            deletePath={this.props.deleteAppeal}
            destroyYes={true}
            isPermissionEdit={isPermissionEdit}
            isPermissionDelete={isPermissionDelete}
            isPermissionAdd={isPermissionAdd}
            headerItems={[
              'تاريخ الحكم',
              'تاريخ الجلسة',
              'تاريخ الطعن',
              'نوع الطعن',
              'المحكمة',
              'الإعدادت',
            ]}
          />
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Appeals);
