import { apiCall } from '../../../js/actions';
import { getStatusError } from '../../../utils/helpres';
import axios from 'axios';

const getConsultation = () => async (dispatch) => {
  dispatch({ type: 'GET_LAWSUITT_REQUESTING' });
  try {
    const data = await axios.get(
      `http://services.e-justice.tn/consultation/tdossierpalierlist.php?cmd=search`,
    );
    dispatch({ type: 'GET_LAWSUITT_SUCCESS', session: data.data });
  } catch (err) {
    const { response } = err;
    if (response)
      dispatch({
        type: 'GET_LAWSUITT_FAILURE',
        err: err.message,
        status: getStatusError(err),
      });
  }
};
export default {
  getConsultation,
};
