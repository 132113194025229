import React, { useState } from 'react';
import SearchIcon from '../../../assets/img/search-interface-symbol_sm.svg';

const Conversation = ({
  conversations,
  joinConversation,
  account,
  lawyers,
  searchExpert,
  search,
  idItem1,
  setSearch,
  destroyConversation,
}) => {
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [selectedConversation, setSelectedConversation] = useState(null);
  return (
    <>
      <div className="msng_right_search">
        <img src={SearchIcon} alt="search icon" />
        <input
          onChange={(e) => {
            setSearch(e.target.value);
            searchExpert();
          }}
          type="text"
          value={search || search}
          placeholder="الاسم,الهاتف"
        />
      </div>
      {conversations && conversations.length > 0
        ? conversations.map((conversation) => {
            return (
              <div
                key={conversation.id}
                onClick={() => joinConversation(conversation, null)}
                className="msng_cards"
              >
                <div
                  className={
                    idItem1 === conversation.id ? 'selectedCard msng_card' : 'msng_card'
                  }
                >
                  <div className="msng_card__name">
                    <div className="msng_card__pic">
                      <div>
                        {conversation.to_.id === account.id
                          ? conversation.from_.first_name.charAt(0)
                          : conversation.to_.first_name.charAt(0)}
                      </div>
                    </div>
                    <div className="msng_card__name-txt">
                      <div>
                        {conversation.to_.id === account.id
                          ? `${conversation.from_.first_name} ${conversation.from_.last_name}`
                          : `${conversation.to_.first_name} ${conversation.to_.last_name}`}
                      </div>
                      <div className="msg_snippet">{conversation.short_message}</div>
                    </div>
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20.906"
                    height="25.74"
                    viewBox="0 0 20.906 25.74"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setSelectedConversation(conversation.id);
                      setOpenDeletePopup(true);
                    }}
                  >
                    <g id="delete" transform="translate(0 0)">
                      <path
                        id="Path_82"
                        data-name="Path 82"
                        d="M223,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,223,154.7Zm0,0"
                        transform="translate(-208.991 -145.377)"
                        fill="#868686"
                      ></path>
                      <path
                        id="Path_83"
                        data-name="Path 83"
                        d="M105,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,105,154.7Zm0,0"
                        transform="translate(-98.105 -145.377)"
                        fill="#868686"
                      ></path>
                      <path
                        id="Path_84"
                        data-name="Path 84"
                        d="M1.709,7.662V22.514a3.326,3.326,0,0,0,.884,2.294,2.969,2.969,0,0,0,2.154.931H16.152a2.968,2.968,0,0,0,2.154-.931,3.326,3.326,0,0,0,.884-2.294V7.662A2.3,2.3,0,0,0,18.6,3.133H15.513V2.38A2.368,2.368,0,0,0,13.126,0H7.773A2.368,2.368,0,0,0,5.386,2.38v.754H2.3a2.3,2.3,0,0,0-.591,4.528ZM16.152,24.533H4.747a1.909,1.909,0,0,1-1.832-2.019V7.715h15.07v14.8a1.909,1.909,0,0,1-1.832,2.019ZM6.592,2.38A1.161,1.161,0,0,1,7.773,1.2h5.353A1.161,1.161,0,0,1,14.308,2.38v.754H6.592ZM2.3,4.339H18.6a1.085,1.085,0,0,1,0,2.17H2.3a1.085,1.085,0,0,1,0-2.17Zm0,0"
                        transform="translate(0.003 0.002)"
                        fill="#868686"
                      ></path>
                      <path
                        id="Path_85"
                        data-name="Path 85"
                        d="M164,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,164,154.7Zm0,0"
                        transform="translate(-153.548 -145.377)"
                        fill="#868686"
                      ></path>
                    </g>
                  </svg>
                </div>
              </div>
            );
          })
        : ''}
      {conversations.length === 0 &&
        lawyers &&
        lawyers.data &&
        lawyers.data.length > 0 &&
        lawyers.data.map((lawyer) => {
          return (
            <div
              key={lawyer.id}
              onClick={() => joinConversation(null, lawyer)}
              className="msng_cards"
            >
              <div
                className={idItem1 === lawyer.id ? 'selectedCard msng_card' : 'msng_card'}
              >
                <div className="msng_card__name">
                  <div className="msng_card__pic">
                    <div>{lawyer.first_name.charAt(0)}</div>
                  </div>
                  <div className="msng_card__name-txt">
                    <div>{`${lawyer.first_name} ${lawyer.last_name}`}</div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      <div className={openDeletePopup ? 'popup active' : 'popup'}>
        <div className="popup__title">أنت بصدد حذف المناقشة</div>
        <div className="popup__btn_grp">
          <button onClick={() => setOpenDeletePopup(false)} className="cancel_popup">
            <span>الغاء</span>
            <span>&lt;</span>
          </button>
          <button
            onClick={() => {
              if (selectedConversation) {
                destroyConversation(selectedConversation);
                setOpenDeletePopup(false);
              }
            }}
          >
            <span>تأكيد</span>
            <span className="trashSpan">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20.906"
                height="25.74"
                viewBox="0 0 20.906 25.74"
              >
                <g id="delete" transform="translate(0.003 0.002)">
                  <path
                    id="Path_82"
                    data-name="Path 82"
                    d="M223,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,223,154.7Zm0,0"
                    transform="translate(-208.995 -145.379)"
                    fill="#fff"
                  />
                  <path
                    id="Path_83"
                    data-name="Path 83"
                    d="M105,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,105,154.7Zm0,0"
                    transform="translate(-98.108 -145.379)"
                    fill="#fff"
                  />
                  <path
                    id="Path_84"
                    data-name="Path 84"
                    d="M1.709,7.662V22.514a3.326,3.326,0,0,0,.884,2.294,2.969,2.969,0,0,0,2.154.931H16.152a2.968,2.968,0,0,0,2.154-.931,3.326,3.326,0,0,0,.884-2.294V7.662A2.3,2.3,0,0,0,18.6,3.133H15.513V2.38A2.368,2.368,0,0,0,13.126,0H7.773A2.368,2.368,0,0,0,5.386,2.38v.754H2.3a2.3,2.3,0,0,0-.591,4.528ZM16.152,24.533H4.747a1.909,1.909,0,0,1-1.832-2.019V7.715h15.07v14.8a1.909,1.909,0,0,1-1.832,2.019ZM6.592,2.38A1.161,1.161,0,0,1,7.773,1.2h5.353A1.161,1.161,0,0,1,14.308,2.38v.754H6.592ZM2.3,4.339H18.6a1.085,1.085,0,0,1,0,2.17H2.3a1.085,1.085,0,0,1,0-2.17Zm0,0"
                    transform="translate(0 0)"
                    fill="#fff"
                  />
                  <path
                    id="Path_85"
                    data-name="Path 85"
                    d="M164,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,164,154.7Zm0,0"
                    transform="translate(-153.551 -145.379)"
                    fill="#fff"
                  />
                </g>
              </svg>
            </span>
          </button>
        </div>
      </div>
      <div id="overlay" className={openDeletePopup ? 'active' : ''}></div>
    </>
  );
};

export default Conversation;
