import { apiCall } from '../../../js/actions';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastAction from '../../../components/ToastAction';
import React from 'react';
import { getStatusError } from '../../../utils/helpres';
import moment from 'moment';

const updatePassword = (values) => async (dispatch) => {
  dispatch({ type: 'UPDATE_PASSWORD_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'post',
      url: `/api/lawyer/change/password`,
      data: { old_password: values.old_password, new_password: values.new_password },
    });
    if (data.type !== 'error') {
      toast(
        ({ closeToast }) => (
          <ToastAction type="success" message="تم تغيير كلمة المرور بنجاح" />
        ),
        { hideProgressBar: true },
      );
      //toast.success(`Mot de passe a été modifié`, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_PASSWORD_SUCCESS',
        data: data,
      });
    } else {
      toast(
        ({ closeToast }) => <ToastAction type="warning" message={data.data.message} />,
        { hideProgressBar: true },
      );
      //toast.warn(data.data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_PASSWORD_FAILURE',
        ErrorMessage: data.message,
        data: false,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    toast(
      ({ closeToast }) => (
        <ToastAction
          type="error"
          message={data.message ? data.message : `فشل في تغيير كلمة المرور`}
        />
      ),
      { hideProgressBar: true },
    );
    // toast.error(data.message ? data.message : `Mot de passe n'est pas modifié`, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'UPDATE_PASSWORD_FAILURE',
      msg: 'error server',
      err: data.errors || data.message,
      data: false,
    });
  }
};
/**SuitType*/
const submitSuitTypeForm = (values) => async (dispatch) => {
  dispatch({ type: 'UPDATE_SUIT_TYPE_REQUESTING' });
  const { name } = values;
  let request = {
    name,
  };
  try {
    const { data } = await apiCall({
      method: `${values.id ? `patch` : 'post'}`,
      url: `/api/suit-type/${values.id ? `update/${values.id}` : 'create'}`,
      data: request,
    });
    if (data.type === 'success') {
      toast(({ closeToast }) => <ToastAction type="success" message={data.message} />, {
        hideProgressBar: true,
      });
      dispatch({
        type: 'UPDATE_SUIT_TYPE_SUCCESS',
        addSuitType: data.data,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      // toast.warn(data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_SUIT_TYPE_FAILURE',
        ErrorMessage: data.message,
        addSuitType: values,
        status: true,
      });
    }
  } catch (e) {
    const { response } = e;
    if (response) {
      const { data } = response;
      if (data.message) {
        toast(
          ({ closeToast }) => (
            <ToastAction type="error" message={response.data.message} />
          ),
          { hideProgressBar: true },
        );
      } else
        toast(
          ({ closeToast }) => (
            <ToastAction
              type="error"
              message="عفوًا ، لم يتم إنشاء نوع القضية ، يرجى إعادة المحاولة."
            />
          ),
          { hideProgressBar: true },
        );
      // toast.error(`Profil non ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_SUIT_TYPE_FAILURE',
        msg: 'error server',
        err: data.errors || data.message,
        addSuitType: values,
        status: getStatusError(e),
      });
    } else {
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="error"
            message="عفوًا ، لم يتم إنشاء نوع القضية ، يرجى إعادة المحاولة."
          />
        ),
        { hideProgressBar: true },
      );
      dispatch({
        type: 'UPDATE_SUIT_TYPE_FAILURE',
        msg: 'error server',
        err: data.errors || data.message,
        addSuitType: values,
        status: getStatusError(e),
      });
    }
  }
};
const deleteSuitType = (id) => async (dispatch) => {
  dispatch({ type: 'ACTION_DELETE_SUIT_TYPE_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'delete',
      url: `/api/suit-type/delete/${id}`,
    });
    if (data.type === 'success') {
      toast(({ closeToast }) => <ToastAction type="success" message={data.message} />, {
        hideProgressBar: true,
      });
      //toast.success(`Formation supprimé`, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'ACTION_DELETE_SUIT_TYPE_SUCCESS',
        data: data,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      //toast.warn(data.data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'ACTION_DELETE_SUIT_TYPE_FAILURE',
        ErrorMessage: data.message,
        data: false,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    toast(
      ({ closeToast }) => <ToastAction type="error" message="لم يتم حذف نوع القضية" />,
      { hideProgressBar: true },
    );
    // toast.error(`Formation n'est pas supprimé`, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'ACTION_DELETE_SUIT_TYPE_FAILURE',
      msg: 'error server',
      err: data.errors || data.message,
      data: false,
    });
  }
};
/**Judgment*/
const submitJudgment = (values) => async (dispatch) => {
  dispatch({ type: 'UPDATE_JUDGMENT_REQUESTING' });
  const { name } = values;
  let request = {
    name,
  };
  try {
    const { data } = await apiCall({
      method: `${values.id ? `patch` : 'post'}`,
      url: `/api/judgment/${values.id ? `update/${values.id}` : 'create'}`,
      data: request,
    });
    if (data.type === 'success') {
      toast(({ closeToast }) => <ToastAction type="success" message={data.message} />, {
        hideProgressBar: true,
      });
      dispatch({
        type: 'UPDATE_JUDGMENT_SUCCESS',
        addSuitType: data.data,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      // toast.warn(data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_JUDGMENT_FAILURE',
        ErrorMessage: data.message,
        addSuitType: values,
        status: true,
      });
    }
  } catch (e) {
    const { response } = e;
    if (response) {
      const { data } = response;
      if (data.message) {
        toast(
          ({ closeToast }) => (
            <ToastAction type="error" message={response.data.message} />
          ),
          { hideProgressBar: true },
        );
      } else
        toast(
          ({ closeToast }) => (
            <ToastAction
              type="error"
              message="عفوًا ، لم يتم إنشاء نوع الحكم ، يرجى إعادة المحاولة."
            />
          ),
          { hideProgressBar: true },
        );
      // toast.error(`Profil non ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_JUDGMENT_FAILURE',
        msg: 'error server',
        err: data.errors || data.message,
        addSuitType: values,
        status: getStatusError(e),
      });
    } else {
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="error"
            message="عفوًا ، لم يتم إنشاء نوع الحكم ، يرجى إعادة المحاولة."
          />
        ),
        { hideProgressBar: true },
      );
      dispatch({
        type: 'UPDATE_JUDGMENT_FAILURE',
        msg: 'error server',
        err: data.errors || data.message,
        addSuitType: values,
        status: getStatusError(e),
      });
    }
  }
};
const deleteJudgment = (id) => async (dispatch) => {
  dispatch({ type: 'ACTION_DELETE_JUDGMENT_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'delete',
      url: `/api/judgment/delete/${id}`,
    });
    if (data.type === 'success') {
      toast(({ closeToast }) => <ToastAction type="success" message={data.message} />, {
        hideProgressBar: true,
      });
      //toast.success(`Formation supprimé`, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'ACTION_DELETE_JUDGMENT_SUCCESS',
        data: data,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      //toast.warn(data.data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'ACTION_DELETE_JUDGMENT_FAILURE',
        ErrorMessage: data.message,
        data: false,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    toast(
      ({ closeToast }) => <ToastAction type="error" message="لم يتم حذف نوع الحكم" />,
      { hideProgressBar: true },
    );
    // toast.error(`Formation n'est pas supprimé`, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'ACTION_DELETE_JUDGMENT_FAILURE',
      msg: 'error server',
      err: data.errors || data.message,
      data: false,
    });
  }
};
/**Contract*/
const submitContract = (values) => async (dispatch) => {
  dispatch({ type: 'UPDATE_CONTRACT_REQUESTING' });
  const { name } = values;
  let request = {
    name,
  };
  try {
    const { data } = await apiCall({
      method: `${values.id ? `patch` : 'post'}`,
      url: `/api/type-contract/${values.id ? `update/${values.id}` : 'create'}`,
      data: request,
    });
    if (data.type === 'success') {
      toast(({ closeToast }) => <ToastAction type="success" message={data.message} />, {
        hideProgressBar: true,
      });
      dispatch({
        type: 'UPDATE_CONTRACT_SUCCESS',
        addSuitType: data.data,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      // toast.warn(data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_CONTRACT_FAILURE',
        ErrorMessage: data.message,
        addSuitType: values,
        status: true,
      });
    }
  } catch (e) {
    const { response } = e;
    if (response) {
      const { data } = response;
      if (data.message) {
        toast(
          ({ closeToast }) => (
            <ToastAction type="error" message={response.data.message} />
          ),
          { hideProgressBar: true },
        );
      } else
        toast(
          ({ closeToast }) => (
            <ToastAction
              type="error"
              message="عفوًا ، لم يتم إنشاء نوع العقد ، يرجى إعادة المحاولة."
            />
          ),
          { hideProgressBar: true },
        );
      // toast.error(`Profil non ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_CONTRACT_FAILURE',
        msg: 'error server',
        err: data.errors || data.message,
        addSuitType: values,
        status: getStatusError(e),
      });
    } else {
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="error"
            message="عفوًا ، لم يتم إنشاء نوع العقد ، يرجى إعادة المحاولة."
          />
        ),
        { hideProgressBar: true },
      );
      dispatch({
        type: 'UPDATE_CONTRACT_FAILURE',
        msg: 'error server',
        //err: data.errors || data.message,
        addSuitType: values,
        status: getStatusError(e),
      });
    }
  }
};
const deleteContract = (id) => async (dispatch) => {
  dispatch({ type: 'ACTION_DELETE_CONTRACT_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'delete',
      url: `/api/type-contract/delete/${id}`,
    });
    if (data.type === 'success') {
      toast(({ closeToast }) => <ToastAction type="success" message={data.message} />, {
        hideProgressBar: true,
      });
      //toast.success(`Formation supprimé`, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'ACTION_DELETE_CONTRACT_SUCCESS',
        data: data,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      //toast.warn(data.data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'ACTION_DELETE_CONTRACT_FAILURE',
        ErrorMessage: data.message,
        data: false,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    toast(
      ({ closeToast }) => <ToastAction type="error" message="لم يتم حذف نوع العقد" />,
      { hideProgressBar: true },
    );
    // toast.error(`Formation n'est pas supprimé`, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'ACTION_DELETE_CONTRACT_FAILURE',
      msg: 'error server',
      err: data.errors || data.message,
      data: false,
    });
  }
};

const getActivityRegister = (paramsActivityRegister) => async (dispatch) => {
  dispatch({ type: 'GET_ACTIVITY_REGISTER_REQUESTING' });
  try {
    const { date } = paramsActivityRegister || {
      date: '',
    };
    const { order } = paramsActivityRegister || {
      order: '',
    };
    let url = `/api/activity-register?`;
    if (date !== '') {
      url += `&date=${moment(date).add(1, 'days')}`;
    }
    if (order !== '') {
      url += `&order=${order}`;
    }
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_ACTIVITY_REGISTER_SUCCESS', activityRegister: data.data });
  } catch (err) {
    const { response } = err;
    if (response && response.data && response.data.type === 'error')
      dispatch({
        type: 'GET_ACTIVITY_REGISTER_FAILURE',
        err: err.message,
        status: getStatusError(err),
      });
  }
};

const getSecretaries = () => async (dispatch) => {
  dispatch({ type: 'GET_SECRETARIES_REQUESTING' });
  try {
    const url = `/api/secretary`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_SECRETARIES_SUCCESS', data: data.data });
  } catch (err) {
    const { response } = err;
    if (response && response.data && response.data.type === 'error')
      dispatch({
        type: 'GET_SECRETARIES_FAILURE',
        err: err.message,
        status: getStatusError(err),
      });
  }
};

const deleteSecretary = (id) => async (dispatch) => {
  dispatch({ type: 'ACTION_DELETE_SECRETARY_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'delete',
      url: `/api/secretary/delete/${id}`,
    });
    if (data.type === 'success') {
      toast(({ closeToast }) => <ToastAction type="success" message={data.message} />, {
        hideProgressBar: true,
      });
      //toast.success(`Formation supprimé`, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'ACTION_DELETE_SECRETARY_SUCCESS',
        data: data,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      //toast.warn(data.data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'ACTION_DELETE_SECRETARY_FAILURE',
        ErrorMessage: data.message,
        data: false,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    toast(
      ({ closeToast }) => <ToastAction type="error" message="لم يتم حذف المستخدم" />,
      { hideProgressBar: true },
    );
    // toast.error(`Formation n'est pas supprimé`, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'ACTION_DELETE_SECRETARY_FAILURE',
      msg: 'error server',
      err: data.errors || data.message,
      data: false,
    });
  }
};

const getPermissions = () => async (dispatch) => {
  dispatch({ type: 'GET_PERMISSIONS_REQUESTING' });
  try {
    const url = `/api/permission`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_PERMISSIONS_SUCCESS', data: data.data });
  } catch (err) {
    const { response } = err;
    if (response && response.data && response.data.type === 'error')
      dispatch({
        type: 'GET_PERMISSIONS_FAILURE',
        err: err.message,
        status: getStatusError(err),
      });
  }
};

const submitPermissionForm = (values) => async (dispatch) => {
  dispatch({ type: 'UPDATE_PERMISSION_REQUESTING' });
  const { label, code } = values;
  let request = {
    label,
    code,
  };
  try {
    const { data } = await apiCall({
      method: values.id ? 'patch' : 'post',
      url: `/api/permission/${values.id ? 'update' : 'create'}/${values.id || ''}`,
      data: request,
    });
    const response = data.data;
    if (!data.error) {
      toast(
        ({ closeToast }) => (
          <ToastAction type="success" message="تم تغيير الملف الشخصي بنجاح" />
        ),
        { hideProgressBar: true },
      );
      dispatch({
        type: 'UPDATE_PERMISSION_SUCCESS',
        data: response,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      // toast.warn(data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_PERMISSION_FAILURE',
        ErrorMessage: data.message,
        updateAccount: values,
        status: true,
      });
    }
  } catch (e) {
    const { response } = e;
    if (response) {
      const { data } = response;
      if (data.message) {
        toast(
          ({ closeToast }) => (
            <ToastAction type="error" message={response.data.message} />
          ),
          { hideProgressBar: true },
        );
      } else
        toast(({ closeToast }) => <ToastAction type="error" message={data.message} />, {
          hideProgressBar: true,
        });
      dispatch({
        type: 'UPDATE_PERMISSION_FAILURE',
        msg: 'error server',
        err: data.errors || data.message,
        updateAccount: values,
        status: getStatusError(e),
      });
    }
  }
};

const deletePermission = (id) => async (dispatch) => {
  dispatch({ type: 'ACTION_DELETE_PERMISSION_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'delete',
      url: `/api/permission/delete/${id}`,
    });
    if (data.type === 'success') {
      toast(({ closeToast }) => <ToastAction type="success" message={data.message} />, {
        hideProgressBar: true,
      });
      dispatch({
        type: 'ACTION_DELETE_PERMISSION_SUCCESS',
        data: data,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      dispatch({
        type: 'ACTION_DELETE_PERMISSION_FAILURE',
        ErrorMessage: data.message,
        data: false,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    toast(
      ({ closeToast }) => <ToastAction type="error" message="لم يتم حذف المستخدم" />,
      { hideProgressBar: true },
    );
    // toast.error(`Formation n'est pas supprimé`, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'ACTION_DELETE_SECRETARY_FAILURE',
      msg: 'error server',
      err: data.errors || data.message,
      data: false,
    });
  }
};

const getGroupPermissions = () => async (dispatch) => {
  dispatch({ type: 'GET_GROUP_PERMISSIONS_REQUESTING' });
  try {
    const url = `/api/group-permission`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_GROUP_PERMISSIONS_SUCCESS', data: data.data });
  } catch (err) {
    const { response } = err;
    if (response && response.data && response.data.type === 'error')
      dispatch({
        type: 'GET_GROUP_PERMISSIONS_FAILURE',
        err: err.message,
        status: getStatusError(err),
      });
  }
};
const getPayments = () => async (dispatch) => {
  dispatch({ type: 'GET_PAYMENTS_REQUESTING' });
  try {
    const url = `/api/payment/lawyer`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_PAYMENTS_SUCCESS', data: data.data });
  } catch (err) {
    const { response } = err;
    if (response && response.data && response.data.type === 'error')
      dispatch({
        type: 'GET_PAYMENTS_FAILURE',
        err: err.message,
        status: getStatusError(err),
      });
  }
};
const ActivitedDeactivetedAccount = (values) => async (dispatch) => {
  dispatch({ type: 'ADD_ACTIVE_USER_REQUESTING' });
  try {
    const url = `/api/update/status/${values.id}`;
    const { data } = await apiCall({
      method: 'post',
      url,
      data: values,
    });
    toast(
      ({ closeToast }) => (
        <ToastAction
          type="success"
          message={
            values.access_status === 'accepted' ? 'تم قبول الحساب' : 'تم ايقاف الحساب'
          }
        />
      ),
      {
        hideProgressBar: true,
      },
    );
    dispatch({ type: 'ADD_ACTIVE_USER_SUCCESS', data: data.data });
  } catch (err) {
    toast(
      ({ closeToast }) => (
        <ToastAction
          type="error"
          message={
            values.access_status === 'accepted'
              ? 'لم يتم قبول الحساب'
              : 'لم يتم ايقاف الحساب'
          }
        />
      ),
      {
        hideProgressBar: true,
      },
    );
    const { response } = err;
    if (response && response.data && response.data.type === 'error')
      dispatch({
        type: 'ADD_ACTIVE_USER_FAILURE',
        err: err.message,
        status: getStatusError(err),
      });
  }
};
const submitPaymentForm = (values) => async (dispatch) => {
  dispatch({ type: 'UPDATE_PAYMENT_REQUESTING' });
  const { mode, price } = values;
  let request = {
    mode,
    price,
    status: 'in_progress',
  };
  try {
    const { data } = await apiCall({
      method: 'patch',
      url: `/api/payment/update/${values.id}`,
      data: request,
    });
    const response = data.data;
    if (!data.error) {
      if (values.file && values.file.name) {
        const formData = new FormData();
        formData.append('file', values.file);
        formData.append('type', 'payment');
        formData.append('id', values.id);
        formData.append('type_doc', 'images');
        await apiCall({
          method: 'post',
          url: `/api/upload`,
          data: formData,
        });
      }
      toast(
        ({ closeToast }) => (
          <ToastAction type="success" message="تم دفع الفاتورة بنجاح" />
        ),
        { hideProgressBar: true },
      );
      dispatch({
        type: 'UPDATE_PAYMENT_SUCCESS',
        data: response,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      // toast.warn(data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_PAYMENT_FAILURE',
        ErrorMessage: data.message,
        updateAccount: values,
        status: true,
      });
    }
  } catch (e) {
    const { response } = e;
    if (response) {
      const { data } = response;
      if (data.message) {
        toast(
          ({ closeToast }) => (
            <ToastAction type="error" message={response.data.message} />
          ),
          { hideProgressBar: true },
        );
      } else
        toast(({ closeToast }) => <ToastAction type="error" message={data.message} />, {
          hideProgressBar: true,
        });
      dispatch({
        type: 'UPDATE_PAYMENT_FAILURE',
        msg: 'error server',
        err: data.errors || data.message,
        updateAccount: values,
        status: getStatusError(e),
      });
    }
  }
};

const getLawyerTraineeShip = (type) => async (dispatch) => {
  dispatch({ type: 'GET_LAWYER_TRAINEE_SHIP_REQUESTING' });
  try {
    const url = `/api/lawyer-traineeship?type=${type}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_LAWYER_TRAINEE_SHIP_SUCCESS', lawyerTraineeShip: data.data });
  } catch (err) {
    const { response } = err;
    if (response && response.data && response.data.type === 'error')
      dispatch({
        type: 'GET_LAWYER_TRAINEE_SHIP_FAILURE',
        err: err.message,
        status: getStatusError(err),
      });
  }
};

const deleteLawyerTraineeShip = (id) => async (dispatch) => {
  dispatch({ type: 'ACTION_DELETE_LAWYER_TRAINEE_SHIP_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'delete',
      url: `/api/lawyer-traineeship/delete/${id}`,
    });
    if (data.type === 'success') {
      toast(({ closeToast }) => <ToastAction type="success" message={data.message} />, {
        hideProgressBar: true,
      });
      //toast.success(`Formation supprimé`, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'ACTION_DELETE_LAWYER_TRAINEE_SHIP_SUCCESS',
        data: data,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      //toast.warn(data.data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'ACTION_DELETE_LAWYER_TRAINEE_SHIP_FAILURE',
        ErrorMessage: data.message,
        data: false,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    toast(
      ({ closeToast }) => <ToastAction type="error" message="لم يتم حذف المستخدم" />,
      { hideProgressBar: true },
    );
    // toast.error(`Formation n'est pas supprimé`, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'ACTION_DELETE_LAWYER_TRAINEE_SHIP_FAILURE',
      msg: 'error server',
      err: data.errors || data.message,
      data: false,
    });
  }
};
const changeSearchedStringActivityRegister = (paramsActivityRegister) => ({
  type: 'UPDATE_PARAMS_ACTIVITY_REGISTER',
  paramsActivityRegister,
});
export default {
  changeSearchedStringActivityRegister,
  getLawyerTraineeShip,
  deleteLawyerTraineeShip,
  submitPaymentForm,
  ActivitedDeactivetedAccount,
  getPayments,
  getGroupPermissions,
  getPermissions,
  deletePermission,
  submitPermissionForm,
  deleteSecretary,
  updatePassword,
  submitSuitTypeForm,
  deleteSuitType,
  submitJudgment,
  deleteJudgment,
  deleteContract,
  submitContract,
  getSecretaries,
  getActivityRegister,
};
