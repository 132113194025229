import React, { Component, useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ar from 'date-fns/locale/ar-DZ';
import moment from 'moment';
import SearchIcon from '../../../assets/img/search-interface-symbol.svg';
import InputField from '../../../components/inputField'; // the locale you want
registerLocale('ar', ar);

export function Filter(props) {
  var now = moment();
  var monday = now.clone().weekday(1);
  var friday = now.clone().weekday(6);

  const { paramsSessions, data, selectedItem } = props;
  const [date_from, setDateFrom] = useState(paramsSessions?.date_from || monday);
  const [date_to, setDateTo] = useState(paramsSessions?.date_to || friday);
  useEffect(() => {
    props.changeSearchedStringLawsuits({
      ...paramsSessions,
    });
  }, []);
  return (
    <div className="sort_grp__buttons">
      <div className="login_form_field">
        <input
          onChange={(e) =>
            props.changeSearchedStringLawsuits({
              ...paramsSessions,
              customer_id: e.target.value !== 'الحريف' ? e.target.value : null,
            })
          }
          placeholder="الملف"
          style={{
            height: '3rem',
            borderRadius: '10px',
            boxShadow: '0 0.0rem 0.0rem rgb(79 79 79 / 19%)',
          }}
        />
      </div>
      <div className="login_form_field">
        <img className="login_login_form__icon" src={SearchIcon} alt="user" />
        <input
          onChange={(e) =>
            props.changeSearchedStringLawsuits({
              ...paramsSessions,
              search: e.target.value,
            })
          }
          placeholder="المحكمة,القضية"
          style={{
            height: '3rem',
            borderRadius: '10px',
            boxShadow: '0 0.0rem 0.0rem rgb(79 79 79 / 19%)',
          }}
        />
      </div>
      <div style={{ width: '80%' }} className="sort_grp__buttons">
        <div style={{ marginLeft: '5%', display: 'flex' }}>
          <span style={{ fontSize: '13px' }}>من</span>
          <DatePicker
            selected={new Date()}
            locale="ar"
            onChange={async (date) => {
              await setDateFrom(moment(date).format('YYYY-MM-DD'));
              props.changeSearchedStringLawsuits({
                ...paramsSessions,
                date_from: moment(date).format('YYYY-MM-DD'),
              });
            }}
            customInput={
              <svg
                id="calendar"
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 30 30"
              >
                <g id="Group_28" data-name="Group 28">
                  <g id="Group_27" data-name="Group 27">
                    <ellipse
                      id="Ellipse_20"
                      data-name="Ellipse 20"
                      cx="1"
                      cy="1.5"
                      rx="1"
                      ry="1.5"
                      transform="translate(22 11)"
                      fill="#ca2946"
                    />
                    <path
                      id="Path_89"
                      data-name="Path 89"
                      d="M25.312,2.344H23.789V1.172a1.172,1.172,0,0,0-2.344,0V2.344H16.113V1.172a1.172,1.172,0,0,0-2.344,0V2.344H8.5V1.172a1.172,1.172,0,1,0-2.344,0V2.344H4.688A4.693,4.693,0,0,0,0,7.031V25.312A4.693,4.693,0,0,0,4.688,30h8.965a1.172,1.172,0,0,0,0-2.344H4.688a2.346,2.346,0,0,1-2.344-2.344V7.031A2.346,2.346,0,0,1,4.688,4.688H6.152V5.859a1.172,1.172,0,0,0,2.344,0V4.688H13.77V5.859a1.172,1.172,0,0,0,2.344,0V4.688h5.332V5.859a1.172,1.172,0,0,0,2.344,0V4.688h1.523a2.346,2.346,0,0,1,2.344,2.344v6.68a1.172,1.172,0,0,0,2.344,0V7.031A4.693,4.693,0,0,0,25.312,2.344Z"
                      fill="#ca2946"
                    />
                    <path
                      id="Path_90"
                      data-name="Path 90"
                      d="M277.09,270a7.09,7.09,0,1,0,7.09,7.09A7.1,7.1,0,0,0,277.09,270Zm0,11.836a4.746,4.746,0,1,1,4.746-4.746A4.751,4.751,0,0,1,277.09,281.836Z"
                      transform="translate(-254.18 -254.18)"
                      fill="#ca2946"
                    />
                    <path
                      id="Path_91"
                      data-name="Path 91"
                      d="M373.871,332.4h-.527v-1.23a1.172,1.172,0,0,0-2.344,0v2.4a1.172,1.172,0,0,0,1.172,1.172h1.7a1.172,1.172,0,0,0,0-2.344Z"
                      transform="translate(-349.262 -310.664)"
                      fill="#ca2946"
                    />
                    <circle
                      id="Ellipse_21"
                      data-name="Ellipse 21"
                      cx="1.5"
                      cy="1.5"
                      r="1.5"
                      transform="translate(16 11)"
                      fill="#ca2946"
                    />
                    <circle
                      id="Ellipse_22"
                      data-name="Ellipse 22"
                      cx="1.5"
                      cy="1.5"
                      r="1.5"
                      transform="translate(11 16)"
                      fill="#ca2946"
                    />
                    <ellipse
                      id="Ellipse_23"
                      data-name="Ellipse 23"
                      cx="1"
                      cy="1.5"
                      rx="1"
                      ry="1.5"
                      transform="translate(6 11)"
                      fill="#ca2946"
                    />
                    <ellipse
                      id="Ellipse_24"
                      data-name="Ellipse 24"
                      cx="1"
                      cy="1.5"
                      rx="1"
                      ry="1.5"
                      transform="translate(6 16)"
                      fill="#ca2946"
                    />
                    <circle
                      id="Ellipse_25"
                      data-name="Ellipse 25"
                      cx="1"
                      cy="1"
                      r="1"
                      transform="translate(6 22)"
                      fill="#ca2946"
                    />
                    <ellipse
                      id="Ellipse_26"
                      data-name="Ellipse 26"
                      cx="1.5"
                      cy="1"
                      rx="1.5"
                      ry="1"
                      transform="translate(11 22)"
                      fill="#ca2946"
                    />
                    <circle
                      id="Ellipse_27"
                      data-name="Ellipse 27"
                      cx="1.5"
                      cy="1.5"
                      r="1.5"
                      transform="translate(11 11)"
                      fill="#ca2946"
                    />
                  </g>
                </g>
              </svg>
            }
          />
          <span style={{ fontSize: '15px', marginRight: '28%' }}>
            {moment(date_from).format('YYYY/MM/DD')}
          </span>
        </div>
        <div style={{ display: 'flex' }}>
          <span style={{ fontSize: '13px' }}>إلى</span>
          <DatePicker
            selected={new Date()}
            locale="ar"
            onChange={async (date) => {
              await setDateTo(moment(date).format('YYYY-MM-DD'));
              props.changeSearchedStringLawsuits({
                ...paramsSessions,
                date_to: moment(date).format('YYYY-MM-DD'),
              });
            }}
            customInput={
              <svg
                id="calendar"
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 30 30"
              >
                <g id="Group_28" data-name="Group 28">
                  <g id="Group_27" data-name="Group 27">
                    <ellipse
                      id="Ellipse_20"
                      data-name="Ellipse 20"
                      cx="1"
                      cy="1.5"
                      rx="1"
                      ry="1.5"
                      transform="translate(22 11)"
                      fill="#ca2946"
                    />
                    <path
                      id="Path_89"
                      data-name="Path 89"
                      d="M25.312,2.344H23.789V1.172a1.172,1.172,0,0,0-2.344,0V2.344H16.113V1.172a1.172,1.172,0,0,0-2.344,0V2.344H8.5V1.172a1.172,1.172,0,1,0-2.344,0V2.344H4.688A4.693,4.693,0,0,0,0,7.031V25.312A4.693,4.693,0,0,0,4.688,30h8.965a1.172,1.172,0,0,0,0-2.344H4.688a2.346,2.346,0,0,1-2.344-2.344V7.031A2.346,2.346,0,0,1,4.688,4.688H6.152V5.859a1.172,1.172,0,0,0,2.344,0V4.688H13.77V5.859a1.172,1.172,0,0,0,2.344,0V4.688h5.332V5.859a1.172,1.172,0,0,0,2.344,0V4.688h1.523a2.346,2.346,0,0,1,2.344,2.344v6.68a1.172,1.172,0,0,0,2.344,0V7.031A4.693,4.693,0,0,0,25.312,2.344Z"
                      fill="#ca2946"
                    />
                    <path
                      id="Path_90"
                      data-name="Path 90"
                      d="M277.09,270a7.09,7.09,0,1,0,7.09,7.09A7.1,7.1,0,0,0,277.09,270Zm0,11.836a4.746,4.746,0,1,1,4.746-4.746A4.751,4.751,0,0,1,277.09,281.836Z"
                      transform="translate(-254.18 -254.18)"
                      fill="#ca2946"
                    />
                    <path
                      id="Path_91"
                      data-name="Path 91"
                      d="M373.871,332.4h-.527v-1.23a1.172,1.172,0,0,0-2.344,0v2.4a1.172,1.172,0,0,0,1.172,1.172h1.7a1.172,1.172,0,0,0,0-2.344Z"
                      transform="translate(-349.262 -310.664)"
                      fill="#ca2946"
                    />
                    <circle
                      id="Ellipse_21"
                      data-name="Ellipse 21"
                      cx="1.5"
                      cy="1.5"
                      r="1.5"
                      transform="translate(16 11)"
                      fill="#ca2946"
                    />
                    <circle
                      id="Ellipse_22"
                      data-name="Ellipse 22"
                      cx="1.5"
                      cy="1.5"
                      r="1.5"
                      transform="translate(11 16)"
                      fill="#ca2946"
                    />
                    <ellipse
                      id="Ellipse_23"
                      data-name="Ellipse 23"
                      cx="1"
                      cy="1.5"
                      rx="1"
                      ry="1.5"
                      transform="translate(6 11)"
                      fill="#ca2946"
                    />
                    <ellipse
                      id="Ellipse_24"
                      data-name="Ellipse 24"
                      cx="1"
                      cy="1.5"
                      rx="1"
                      ry="1.5"
                      transform="translate(6 16)"
                      fill="#ca2946"
                    />
                    <circle
                      id="Ellipse_25"
                      data-name="Ellipse 25"
                      cx="1"
                      cy="1"
                      r="1"
                      transform="translate(6 22)"
                      fill="#ca2946"
                    />
                    <ellipse
                      id="Ellipse_26"
                      data-name="Ellipse 26"
                      cx="1.5"
                      cy="1"
                      rx="1.5"
                      ry="1"
                      transform="translate(11 22)"
                      fill="#ca2946"
                    />
                    <circle
                      id="Ellipse_27"
                      data-name="Ellipse 27"
                      cx="1.5"
                      cy="1.5"
                      r="1.5"
                      transform="translate(11 11)"
                      fill="#ca2946"
                    />
                  </g>
                </g>
              </svg>
            }
          />
          <span style={{ fontSize: '15px', marginRight: '28%' }}>
            {moment(date_to).format('YYYY/MM/DD')}
          </span>
        </div>
      </div>
    </div>
  );
}
