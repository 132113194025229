import React, { Component } from 'react';
import Form from './containers/form';
import actions from './store/actions';
import actionsApp from '../../js/actions';
import actionsSettings from '../settings/store/actions';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';

const mapStateToProps = (state) => ({
  suitTypes: state.AppReducer.suitTypes,
  lawsuit: state.LawsuitReducer.lawsuit,
  isLoadingLawsuit: state.LawsuitReducer.isLoadingLawsuit,
  customersList: state.AppReducer.customersList,
  isLoadingCustomersLis: state.AppReducer.isLoadingCustomersLis,
  lastBill: state.AppReducer.lastBill,
  isLoadingLastBill: state.AppReducer.isLoadingLastBill,
  isLoadingLawyerTraineeShip: state.SettingsReducer.isLoadingLawyerTraineeShip,
  lawyerTraineeShip: state.SettingsReducer.lawyerTraineeShip,
  folders: state.AppReducer.folders,
  isLoadingFolders: state.AppReducer.isLoadingFolders,
});

const mapDispatchToProps = (dispatch) => ({
  getAllFolders: (id, date) => dispatch(actionsApp.getAllFolders(id, date)),
  getLastBill: () => dispatch(actionsApp.getLastBill()),
  getLawsuitById: (id) => dispatch(actions.getLawsuitById(id)),
  getListCustomerByLawyer: () => dispatch(actionsApp.getListCustomerByLawyer()),
  getListSuitType: () => dispatch(actionsApp.getListSuitType()),
  getLawyerTraineeShip: () => {
    dispatch(actionsSettings.getLawyerTraineeShip(true));
  },
});

class FormLawsuit extends Component {
  constructor(props) {
    super(props);
    if (props.match.params && props.match.params.id) {
      props.changeActiveItemSideBar(3, 'تحديث قضية');
      document.title = 'تحديث قضية';
    } else {
      props.changeActiveItemSideBar(3, 'اضافة قضية');
      document.title = 'اضافة قضية';
    }
    props.getLastBill();
    props.getLawyerTraineeShip();
    props.getListSuitType();
    props.getListCustomerByLawyer();
    props.getLawsuitById(props.match.params && props.match.params.id);
    this.goBack = this.goBack.bind(this);
  }
  goBack() {
    this.props.history.goBack();
  }
  render() {
    const {
      lawsuit,
      lawyerTraineeShip,
      customersList,
      lastBill,
      suitTypes,
      isLoadingLawsuit,
      folders,
      isLoadingLastBill,
      isLoadingFolders,
    } = this.props;
    if (isLoadingLawsuit || isLoadingLastBill || isLoadingFolders) {
      return (
        <CircularProgress
          style={{
            textAlign: 'center',
            width: '30px',
            height: '30px',
            marginTop: '20%',
            marginRight: '50%',
          }}
        />
      );
    }

    return (
      <div style={{ display: 'flex', marginBottom: '5%' }} className="lawsuit_newLawsuit">
        <Form
          goBack={this.goBack}
          suitTypes={suitTypes}
          folders={folders}
          lawyerTraineeShip={lawyerTraineeShip}
          lawsuit={lawsuit}
          lastBill={lastBill}
          customersList={customersList}
          getAllFolders={this.props.getAllFolders}
        />
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(FormLawsuit);
