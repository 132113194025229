import React, { Component } from 'react';
import actions from '../../../js/actions';
import Filter from '../../contract/components/filter';
import Table from '../../../components/Table';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  customerSelected: state.AppReducer.customerSelected,
  bills: state.AppReducer.bills,
  isLoadingCustomers: state.AppReducer.isLoadingCustomers,
  bill: state.BillReducer.bill,
  destroyBill: state.BillReducer.destroyBill,
  isLoadingBill: state.BillReducer.isLoadingBill,
  paramsBill: state.AppReducer.paramsBill,
});

const mapDispatchToProps = (dispatch) => ({
  getListBills: (paramsBill) => dispatch(actions.getListBills(paramsBill, 1)),
  changeSearchedStringBill: async (paramsBill) => {
    await dispatch(actions.changeSearchedStringBill(paramsBill));
    dispatch(actions.getListBills(paramsBill, 1));
  },
});

class BillsArchive extends Component {
  constructor(props) {
    super(props);
    props.getListBills(props.paramsBill);
    props.changeActiveItemSideBar(6, 'أرشيف المحاسبة');
    document.title = 'أرشيف المحاسبة';
  }

  componentDidMount() {
    this.props.changeSearchedStringBill({
      search: '',
      date: '',
      currentPage: 1,
      limit: 5,
    });
  }

  render() {
    const { isLoadingBill, bills, paramsBill } = this.props;
    return (
      <div className="jalaset_main">
        <div className="jalaset_middle">
          <div className="table_controls">
            <div className="sort_grp">
              <div className="sort_grp__txt">ترتيب حسب</div>
              <Filter
                nameInput="الحريف"
                changeSearchedStringLawsuits={this.props.changeSearchedStringBill}
                paramsSessions={paramsBill}
              />
            </div>
          </div>
          <Table
            class1="lawsuit__judgements_table"
            class2="lawsuit_table"
            withTitle={false}
            colSpan={4}
            dataTable="bills"
            messageDelete="أنت بصدد حذف العقد"
            action={false}
            isLoading={isLoadingBill}
            params={paramsBill}
            changeSearchedString={this.props.changeSearchedStringBill}
            toggleDrawer={this.toggleDrawer}
            show={false}
            data={bills}
            //sessions={sessions}
            title="الجلسات"
            editPath="edit-contract"
            destroyYes={false}
            headerItems={['الحريف', 'النوع', 'لاتعاب', 'تاريخ']}
          />
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(BillsArchive);
