import React, { Component } from 'react';
import { registerLocale } from 'react-datepicker';
import ar from 'date-fns/locale/ar-DZ';
import { connect } from 'react-redux';
import actions from '../store/actions';
import InputField from '../../../components/inputField';
import {
  firstNumberCin,
  firstNumberPhone,
  isNumeric,
  lengthPhone,
} from '../../../utils/helpres';

registerLocale('ar', ar);

const mapStateToProps = (state, ownProps) => {
  const { customer } = ownProps;
  let id;
  let phone;
  let address_country;
  let address_city;
  let address_street;
  let name;
  let nationality;
  let legal_agent;
  let cin;
  let post;
  if (customer) {
    legal_agent = customer && customer.legal_agent ? customer.legal_agent : '';
    name = customer && customer.name ? customer.name : '';
    phone = customer && customer.phone ? customer.phone : '';
    post = customer && customer.post ? customer.post : '';
    nationality = customer && customer.nationality ? customer.nationality : '';
    cin = customer && customer.cin ? customer.cin : '';
    id = customer && customer.id ? customer.id : null;
    address_country =
      customer && customer.address_country ? customer.address_country : '';
    address_city = customer && customer.address_city ? customer.address_city : '';
    address_street = customer && customer.address_street ? customer.address_street : '';
  }
  return {
    addCustomer: state.CustomerReducer.addCustomer,
    isLoadingAddCustomer: state.CustomerReducer.isLoadingAddCustomer,
    account: state.AppReducer.account,
    initialValues: {
      id: id || '',
      address_street: address_street || '',
      legal_agent: legal_agent || '',
      address_city: address_city || '',
      address_country: address_country || '',
      phone: phone || '',
      name: name || '',
      nationality: nationality || '',
      cin: cin || '',
      post: post || '',
    },
  };
};
const mapDispatchToProps = (dispatch) => ({
  submitCustomerForm: (values) => dispatch(actions.submitCustomerForm(values)),
});

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      initialValues: {
        ...props.initialValues,
      },
    };
    this.submitForm = this.submitForm.bind(this);
  }
  submitForm(initialState) {
    if (
      !initialState.name ||
      (initialState.phone && !firstNumberPhone(initialState.phone)) ||
      // (initialState.cin && !firstNumberCin(initialState.cin)) ||
      (initialState.phone && !lengthPhone(initialState.phone)) ||
      //(initialState.cin && !lengthPhone(initialState.cin)) ||
      //(initialState.cin && !isNumeric(initialState.cin)) ||
      (initialState.phone && !isNumeric(initialState.phone))
    ) {
      this.setState({
        ...initialState,
        hasError: true,
      });
    } else {
      this.props.submitCustomerForm(initialState);
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { addCustomer } = this.props;
    if (addCustomer !== prevProps.addCustomer && addCustomer && addCustomer.id) {
      this.props.goBack();
    }
  }
  render() {
    const { initialValues, hasError } = this.state;
    return (
      <form action="">
        <div className="clients_form__sections">
          <div className="clients_form__sections-right">
            <div>
              <label htmlFor="name">الإسم</label>
              <InputField
                //onKeyPress={() => this.submitForm(initialValues)}
                value={initialValues.name}
                name="name"
                placeholder="الإسم"
                type="text"
                hasError={hasError}
                validInput="text"
                onChange={(event) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      name: event.target.value,
                    },
                  });
                }}
              />
            </div>
            <div>
              <label htmlFor="name">الوكيل القانوني</label>
              <InputField
                //onKeyPress={() => this.submitForm(initialValues)}
                value={initialValues.legal_agent}
                name="legal_agent"
                placeholder="الوكيل القانوني"
                type="text"
                validInput="text"
                onChange={(event) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      legal_agent: event.target.value,
                    },
                  });
                }}
              />
            </div>
            <div>
              <label htmlFor="city">ب ت</label>
              <InputField
                //onKeyPress={() => this.submitForm(initialValues)}
                value={initialValues.cin}
                name="cin"
                placeholder="بطاقة التعريف الوطنية/ الرقم الضريبي"
                type="text"
                hasError={hasError && initialValues.cin}
                validInput="cin"
                onChange={(event) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      cin: event.target.value,
                    },
                  });
                }}
              />
            </div>
            <div>
              <label htmlFor="phone">الهاتف</label>
              <InputField
                // onKeyPress={() => this.submitForm(initialValues)}
                value={initialValues.phone}
                name="phone"
                placeholder="الهاتف"
                type="text"
                hasError={hasError && initialValues.phone}
                validInput="tel"
                onChange={(event) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      phone: event.target.value,
                    },
                  });
                }}
              />
            </div>
            <div>
              <label htmlFor="address">الجنسية</label>
              <InputField
                // onKeyPress={() => this.submitForm(initialValues)}
                value={initialValues.nationality}
                name="nationality"
                placeholder="الجنسية"
                type="text"
                validInput="text"
                onChange={(event) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      nationality: event.target.value,
                    },
                  });
                }}
              />
            </div>
          </div>

          <div style={{ display: 'block' }} className="clients_form__sections-left">
            <div>
              <label htmlFor="phone">المهنة</label>
              <InputField
                // onKeyPress={() => this.submitForm(initialValues)}
                value={initialValues.post}
                name="post"
                placeholder="المهنة"
                type="text"
                validInput="text"
                onChange={(event) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      post: event.target.value,
                    },
                  });
                }}
              />
            </div>
            <div>
              <label htmlFor="city">المدينة</label>
              <InputField
                //onKeyPress={() => this.submitForm(initialValues)}
                value={initialValues.address_city}
                name="address_city"
                placeholder="المدينة"
                type="text"
                validInput="text"
                onChange={(event) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      address_city: event.target.value,
                    },
                  });
                }}
              />
            </div>
            <div>
              <label htmlFor="address">العنوان</label>
              <textarea
                onChange={(event) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      address_street: event.target.value,
                    },
                  });
                }}
                value={initialValues.address_street}
                className={hasError && !initialValues.address_street ? 'input-error' : ''}
                name="address_street"
                id="lawsuit_requests"
              >
                {initialValues.address_street}
              </textarea>
            </div>
          </div>
        </div>
        <div className="clients_form__btn_grp">
          <button
            type="button"
            onClick={() => this.props.goBack()}
            className="clients_cancel_session"
          >
            <svg
              className="clients_ltSpan"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              xmlnsSvgjs="http://svgjs.com/svgjs"
              version="1.1"
              width="12"
              height="12"
              x="0"
              y="0"
              viewBox="0 0 492.004 492.004"
              style={{ enableBackground: 'new 0 0 512 512' }}
              xmlSpace="preserve"
            >
              <g>
                <g xmlns="http://www.w3.org/2000/svg">
                  <g>
                    <path
                      d="M382.678,226.804L163.73,7.86C158.666,2.792,151.906,0,144.698,0s-13.968,2.792-19.032,7.86l-16.124,16.12    c-10.492,10.504-10.492,27.576,0,38.064L293.398,245.9l-184.06,184.06c-5.064,5.068-7.86,11.824-7.86,19.028    c0,7.212,2.796,13.968,7.86,19.04l16.124,16.116c5.068,5.068,11.824,7.86,19.032,7.86s13.968-2.792,19.032-7.86L382.678,265    c5.076-5.084,7.864-11.872,7.848-19.088C390.542,238.668,387.754,231.884,382.678,226.804z"
                      fill="#2d2d2d"
                      data-original="#000000"
                      className=""
                    />
                  </g>
                </g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
              </g>
            </svg>
            الغاء
          </button>
          <button type="button" onClick={() => this.submitForm(initialValues)}>
            <svg
              className="tickSpan"
              xmlns="http://www.w3.org/2000/svg"
              width="20.125"
              height="17.774"
              viewBox="0 0 29.125 23.774"
            >
              <path
                id="Path_92"
                data-name="Path 92"
                d="M1325.354,167.5l8.34,9.625,17.8-19.417"
                transform="translate(-1323.842 -156.357)"
                fill="none"
                stroke="#f9f9f9"
                strokeWidth="4"
              />
            </svg>
            تسجيل
          </button>
        </div>
      </form>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Form);
