import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import ErrorImage from './assets/img/error_image.svg';
///import { Offline, Online } from 'react-detect-offline';
import { isMobile } from 'react-device-detect';

import { ERROR_AUTH, isHasPermission, TOAST_CLOSE_DELAY } from './utils/helpres';
//import './App.css';

import './assets/css/main.css';
/* Actions */
import actions from './js/actions';
import actionsMessenger from './pages/messenger/store/actions';
/* Pages */
import Home from './pages/home.js';
import Login from './pages/auth/login';
import Register from './pages/auth/register';
import Sidebar from './layouts/sidebar';
import Headers from './layouts/headers';
import Dashboard from './pages/dashboard';
import Session from './pages/session';
import Lawsuits from './pages/lawsuit';
import LawsuitsRemotely from './pages/lawsuit-remotely';
import Customers from './pages/customer';
import AddSession from './pages/session/form-session';
import AddLawsuit from './pages/lawsuit/form-lawsuit';
import GeneratePdfLawsuit from './pages/lawsuit/generate-pdf';
import GeneratePdfBill from './pages/bill/generate-pdf';
import FormCustomer from './pages/customer/form-customer';
import Reports from './pages/reports';
import FormReport from './pages/reports/form-report';
import Appointment from './pages/appointment';
import Settings from './pages/settings';
import Appeals from './pages/appeal';
import FormAppeal from './pages/appeal/form-appeal';
import LegalJournals from './pages/legal-journal';
import Jurisprudence from './pages/jurisprudence';
import Recover from './pages/auth/recover-password';
import Messenger from './pages/msg';
import Notification from './pages/notification';
import Archives from './pages/archives';
import LawsuitArchive from './pages/archives/pages/lawsuits';
import ContractsArchive from './pages/archives/pages/contract';
import SessionArchive from './pages/archives/pages/session';
import Contracts from './pages/contract';
import FormContract from './pages/contract/form-contract';
import Bills from './pages/bill';
import GeneratePdfPayment from './pages/settings/pages/payment-pdf';
import FormBill from './pages/bill/form-bill';
import Questions from './pages/question';
import Question from './pages/question/containers/question';
import Tasks from './pages/tasks';
import { CircularProgress } from '@material-ui/core';
import Pusher from 'pusher-js';
import moment from 'moment';
import routes from './routes';
import BillsArchive from './pages/archives/pages/bill';
import Layout1 from './pages/home/pages/Layout1/Layout1';
import NavbarAuth from './pages/auth/nav-bar-auth';
import CustomerArchive from './pages/archives/pages/customers';
import PdfDocument from './pages/lawsuit/pdf';
import PdfAllDocument from './pages/lawsuit/all-lawsuits-pdf';
import Receivables from './pages/receivable';
import FormReceivable from './pages/receivable/form-receivable';

const mapStateToProps = (state) => ({
  isLoading: state.AppReducer.isLoading,
  isAuthorized: state.AppReducer.isAuthorized,
  account: state.AppReducer.account,
  error: state.AppReducer.error,
  activeItemSideBar: state.AppReducer.activeItemSideBar,
  params: state.AppReducer.params,
  searchExperts: state.AppReducer.searchExperts,
  isLoadingSearchExperts: state.AppReducer.isLoadingSearchExperts,
  namePage: state.AppReducer.namePage,
  sandwich_menu_btn: state.AppReducer.sandwich_menu_btn,
  paramsNotification: state.AppReducer.paramsNotification,
  listNotifications: state.AppReducer.notifications,
  isLoadingNotifications: state.AppReducer.isLoadingNotifications,
  countNotification: state.AppReducer.countNotification,
  countMessagesIsSeens: state.MessengerReducer.countMessagesIsSeen,
  countMessageIsSeen: state.MessengerReducer.countMessageIsSeen,
});

const mapDispatchToProps = (dispatch) => ({
  getMe: () => dispatch(actions.getMe()),
  changeActiveItemSideBar: (item, nameOfpage) =>
    dispatch(actions.changeActiveItemSideBar(item, nameOfpage)),
  getListNotifications: () => dispatch(actions.getListNotifications()),
  getNotificationIsSeen: () => dispatch(actions.getNotificationIsSeen()),
  getCountNotificationSeen: () => dispatch(actions.getCountNotificationSeen()),
  countMessagesIsSeen: () => dispatch(actionsMessenger.countMessagesIsSeen()),
  logout: (id) => dispatch(actions.logout(id)),
  changeCountMessage: (countMessage) =>
    dispatch(actions.changeCountMessage(countMessage)),
  changeSandwichMenuBtn: () => dispatch(actions.changeSandwichMenuBtn()),
});

const pusher = new Pusher('b3eca3c77b7308af4444', {
  cluster: 'ap2',
  encrypted: true,
});

class App extends Component {
  constructor(props) {
    super(props);
    props.getMe();
    props.countMessagesIsSeen();
    this.state = {
      hideDropDownNotif: false,
      hideDropDownMenu: false,
      notification: 0,
      notifications: props.notifications,
      navItems: [{ id: 1, idnm: 'home', navheading: 'الصفحة الرئيسية' }],
      pos: document.documentElement.scrollTop,
      imglight: false,
      navClass: '',
      fixTop: true,
    };
    this.handleChangeMenuBtn = this.handleChangeMenuBtn.bind(this);
  }
  handleChangeDropDownNotif() {
    this.props.getNotificationIsSeen();
    this.setState({
      hideDropDownNotif: !this.state.hideDropDownNotif,
      hideDropDown: false,
    });
  }
  handleChangeMenuBtn() {
    this.props.changeSandwichMenuBtn();
  }
  hideDropDownMenu() {
    this.setState({
      hideDropDownMenu: !this.state.hideDropDownMenu,
    });
  }

  render() {
    const {
      account,
      countNotification,
      isLoading,
      namePage,
      activeItemSideBar,
      isAuthorized,
      listNotifications,
      error,
      countMessageIsSeen,
      countMessagesIsSeens,
      sandwich_menu_btn,
    } = this.props;
    const { hideDropDownMenu, hideDropDownNotif } = this.state;
    let app;
    let i = 0;
    if (isMobile) {
      app = (
        <>
          <ToastContainer
            closeOnClick={false}
            autoClose={TOAST_CLOSE_DELAY}
            position="top-center"
            pauseOnHover
            //toastClassName="toast"
            bodyClassName="toast-body"
            progressClassName="toast-progress"
            className="toast-container"
          />
          <Switch>
            <Route exact path="/" render={(props) => <Layout1 {...props} />} key="Home" />
            <div style={{ backgroundColor: '#efefef' }}>
              <NavbarAuth
                navItems={this.state.navItems}
                navClass={this.state.navClass}
                imglight={this.state.imglight}
                top={this.state.fixTop}
              />
              <Route
                exact
                path="/register"
                render={(props) => <Register {...props} />}
                key="Register"
              />
            </div>
            <Redirect to="/" />
          </Switch>
        </>
        // <div className="home_container">
        //   <div
        //     style={{ padding: '10%', display: 'grid', justifyContent: 'center' }}
        //     className="home_main"
        //   >
        //     <img src={ErrorImage} />
        //     <span
        //       className="clients_data_name"
        //       style={{
        //         marginTop: '4%',
        //         fontSize: '15px',
        //         textAlign: 'center',
        //       }}
        //       id="no_consultation"
        //     >
        //       عفوًا !
        //     </span>
        //     <span
        //       style={{ fontSize: '15px', textAlign: 'center' }}
        //       id="no_consultation_desc"
        //     >
        //       هذا الإصدار متاح لأجهزة الكمبيوتر فقط.
        //     </span>
        //   </div>
        // </div>
      );
    } else if (error && error !== ERROR_AUTH && error !== 400 && error !== 600) {
      app = (
        <div className="home_container">
          <div
            style={{ padding: '10%', display: 'grid', justifyContent: 'center' }}
            className="home_main"
          >
            <img src={ErrorImage} />
            <span
              className="clients_data_name"
              style={{
                marginTop: '4%',
                fontSize: '15px',
                textAlign: 'center',
              }}
              id="no_consultation"
            >
              عفوًا !
            </span>
            <span
              style={{ fontSize: '15px', textAlign: 'center' }}
              id="no_consultation_desc"
            >
              هناك خطأ ما. الرجاء الضغط على الزر لتحديث الصفحة.
            </span>
            <button className="rv_add_button" onClick={() => document.location.reload()}>
              حاول مرة أخرى
            </button>
          </div>
        </div>
      );
    } else if (isLoading) {
      app = (
        <div className="home_container">
          <div className="home_main">
            <CircularProgress
              style={{
                textAlign: 'center',
                width: '30px',
                height: '30px',
                marginTop: '20%',
                marginRight: '50%',
              }}
            />
          </div>
        </div>
      );
    } else if (!isAuthorized) {
      app = (
        <>
          <ToastContainer
            closeOnClick={false}
            autoClose={TOAST_CLOSE_DELAY}
            position="top-center"
            pauseOnHover
            //toastClassName="toast"
            bodyClassName="toast-body"
            progressClassName="toast-progress"
            className="toast-container"
          />
          <Switch>
            <Route exact path="/" render={(props) => <Layout1 {...props} />} key="Home" />
            <div style={{ backgroundColor: '#efefef' }}>
              <NavbarAuth
                navItems={this.state.navItems}
                navClass={this.state.navClass}
                imglight={this.state.imglight}
                top={this.state.fixTop}
              />
              <Route
                exact
                path="/login"
                render={(props) => <Login {...props} />}
                key="Login"
              />
              <Route
                exact
                path="/register"
                render={(props) => <Register {...props} />}
                key="Register"
              />
              <Route
                exact
                path="/recover-password"
                render={(props) => <Recover {...props} />}
                key="Recover"
              />
            </div>
            <Redirect to="/" />
          </Switch>
        </>
      );
    } else if (
      account &&
      account.login_access &&
      account.login_access.access_status !== 'accepted'
    )
      app = (
        <div className="home_container">
          <div
            style={{ padding: '10%', display: 'grid', justifyContent: 'center' }}
            className="home_main"
          >
            <img src={ErrorImage} />
            <span
              className="clients_data_name"
              style={{
                marginTop: '4%',
                fontSize: '15px',
                textAlign: 'center',
              }}
              id="no_consultation"
            >
              عفوًا!
            </span>
            <span
              style={{ fontSize: '15px', textAlign: 'center' }}
              id="no_consultation_desc"
            >
              تم إقاف حسابك
            </span>
            <span
              style={{ fontSize: '15px', textAlign: 'center' }}
              id="no_consultation_desc"
            >
              إذا كنت بحاجة إلى دعم ، فيرجى إخبارنا عن طريق إرسال بريد إلكتروني إلى
            </span>
            <span
              style={{ color: '#A8192B', fontSize: '18px', textAlign: 'center' }}
              id="no_consultation_desc"
            >
              lavocato.app@gmail.com
            </span>
          </div>
        </div>
      );
    else {
      app = (
        <div
          style={{
            overflow: 'hidden',
            backgroundColor: '#efefef',
          }}
          className="hosme_container"
        >
          <div className="side__mobile_menu">
            <svg
              className="side__mobile_menu-open"
              //id="side__mobile_menu-open"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z" />
            </svg>
            <svg
              className="side__mobile_menu-close hide"
              id="side__mobile_menu-close"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" />
            </svg>
          </div>
          <div
            style={{ display: hideDropDownMenu ? 'flex' : 'none' }}
            className="side show_flex"
          >
            <div className="side__menu">
              <ul>
                {routes.map((route) => {
                  return (
                    <li
                      onClick={() => {
                        this.props.changeActiveItemSideBar(route.id);
                        this.hideDropDownMenu();
                      }}
                      key={route.id}
                    >
                      <Link
                        className={activeItemSideBar === route.id ? 'active_a' : ''}
                        to={route.path}
                      >
                        {route.name}
                        {activeItemSideBar === route.id ? route.imgActive : route.img}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div
            style={{ display: hideDropDownNotif ? 'flex' : 'none' }}
            className="noti_dropdown__content_mobile"
          >
            {listNotifications &&
            listNotifications.data &&
            listNotifications.data.length > 0
              ? listNotifications.data.map((notif) => {
                  i++;
                  if (i <= 4) {
                    if (notif.type === 'rdv')
                      return (
                        <a key={notif.id} href="#">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="44"
                            height="44"
                            viewBox="0 0 44 44"
                          >
                            <circle
                              id="Ellipse_36"
                              data-name="Ellipse 36"
                              cx="22"
                              cy="22"
                              r="22"
                              fill="#ca2946"
                            />
                            <g id="calendar" transform="translate(7.089 6.5)">
                              <g id="Group_11" data-name="Group 11">
                                <g id="Group_10" data-name="Group 10">
                                  <circle
                                    id="Ellipse_2"
                                    data-name="Ellipse 2"
                                    cx="1"
                                    cy="1"
                                    r="1"
                                    transform="translate(21.911 11.5)"
                                    fill="#fff"
                                  />
                                  <path
                                    id="Path_43"
                                    data-name="Path 43"
                                    d="M25.312,2.344H23.789V1.172a1.172,1.172,0,0,0-2.344,0V2.344H16.113V1.172a1.172,1.172,0,0,0-2.344,0V2.344H8.5V1.172a1.172,1.172,0,1,0-2.344,0V2.344H4.687A4.693,4.693,0,0,0,0,7.031V25.312A4.693,4.693,0,0,0,4.687,30h8.965a1.172,1.172,0,0,0,0-2.344H4.687a2.346,2.346,0,0,1-2.344-2.344V7.031A2.346,2.346,0,0,1,4.687,4.687H6.152V5.859a1.172,1.172,0,1,0,2.344,0V4.687H13.77V5.859a1.172,1.172,0,0,0,2.344,0V4.687h5.332V5.859a1.172,1.172,0,0,0,2.344,0V4.687h1.523a2.346,2.346,0,0,1,2.344,2.344v6.68a1.172,1.172,0,0,0,2.344,0V7.031A4.693,4.693,0,0,0,25.312,2.344Z"
                                    fill="#fff"
                                  />
                                  <path
                                    id="Path_44"
                                    data-name="Path 44"
                                    d="M277.09,270a7.09,7.09,0,1,0,7.09,7.09A7.1,7.1,0,0,0,277.09,270Zm0,11.836a4.746,4.746,0,1,1,4.746-4.746A4.751,4.751,0,0,1,277.09,281.836Z"
                                    transform="translate(-254.18 -254.18)"
                                    fill="#fff"
                                  />
                                  <path
                                    id="Path_45"
                                    data-name="Path 45"
                                    d="M373.871,332.4h-.527v-1.23a1.172,1.172,0,0,0-2.344,0v2.4a1.172,1.172,0,0,0,1.172,1.172h1.7a1.172,1.172,0,1,0,0-2.344Z"
                                    transform="translate(-349.262 -310.664)"
                                    fill="#fff"
                                  />
                                  <ellipse
                                    id="Ellipse_3"
                                    data-name="Ellipse 3"
                                    cx="1.5"
                                    cy="1"
                                    rx="1.5"
                                    ry="1"
                                    transform="translate(15.911 11.5)"
                                    fill="#fff"
                                  />
                                  <ellipse
                                    id="Ellipse_4"
                                    data-name="Ellipse 4"
                                    cx="1"
                                    cy="1.5"
                                    rx="1"
                                    ry="1.5"
                                    transform="translate(11.911 15.5)"
                                    fill="#fff"
                                  />
                                  <ellipse
                                    id="Ellipse_5"
                                    data-name="Ellipse 5"
                                    cx="1.5"
                                    cy="1"
                                    rx="1.5"
                                    ry="1"
                                    transform="translate(5.911 11.5)"
                                    fill="#fff"
                                  />
                                  <circle
                                    id="Ellipse_6"
                                    data-name="Ellipse 6"
                                    cx="1.5"
                                    cy="1.5"
                                    r="1.5"
                                    transform="translate(5.911 15.5)"
                                    fill="#fff"
                                  />
                                  <circle
                                    id="Ellipse_7"
                                    data-name="Ellipse 7"
                                    cx="1.5"
                                    cy="1.5"
                                    r="1.5"
                                    transform="translate(5.911 20.5)"
                                    fill="#fff"
                                  />
                                  <ellipse
                                    id="Ellipse_8"
                                    data-name="Ellipse 8"
                                    cx="1"
                                    cy="1.5"
                                    rx="1"
                                    ry="1.5"
                                    transform="translate(11.911 20.5)"
                                    fill="#fff"
                                  />
                                  <circle
                                    id="Ellipse_9"
                                    data-name="Ellipse 9"
                                    cx="1"
                                    cy="1"
                                    r="1"
                                    transform="translate(11.911 11.5)"
                                    fill="#fff"
                                  />
                                </g>
                              </g>
                            </g>
                          </svg>
                          <div className="single_noti_content">
                            <div className="noti_category">موعد</div>
                            <div className="noti_text">
                              <div className="noti_mobile_date">
                                <span className="span_time">
                                  {moment(notif.name).format('HH')}h
                                  {moment(notif.name).format('mm')}
                                </span>
                                <span className="span_date">
                                  {moment(notif.name).format('DD/MM/YYYY')}
                                </span>
                              </div>
                            </div>
                          </div>
                        </a>
                      );
                    if (notif.type === 'report')
                      return (
                        <a key={notif.id} href="#">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="44"
                            height="44"
                            viewBox="0 0 44 44"
                          >
                            <circle
                              id="Ellipse_36"
                              data-name="Ellipse 36"
                              cx="22"
                              cy="22"
                              r="22"
                              fill="#ca2946"
                            />
                            <g id="calendar" transform="translate(7.089 6.5)">
                              <g id="Group_11" data-name="Group 11">
                                <g id="Group_10" data-name="Group 10">
                                  <circle
                                    id="Ellipse_2"
                                    data-name="Ellipse 2"
                                    cx="1"
                                    cy="1"
                                    r="1"
                                    transform="translate(21.911 11.5)"
                                    fill="#fff"
                                  />
                                  <path
                                    id="Path_43"
                                    data-name="Path 43"
                                    d="M25.312,2.344H23.789V1.172a1.172,1.172,0,0,0-2.344,0V2.344H16.113V1.172a1.172,1.172,0,0,0-2.344,0V2.344H8.5V1.172a1.172,1.172,0,1,0-2.344,0V2.344H4.687A4.693,4.693,0,0,0,0,7.031V25.312A4.693,4.693,0,0,0,4.687,30h8.965a1.172,1.172,0,0,0,0-2.344H4.687a2.346,2.346,0,0,1-2.344-2.344V7.031A2.346,2.346,0,0,1,4.687,4.687H6.152V5.859a1.172,1.172,0,1,0,2.344,0V4.687H13.77V5.859a1.172,1.172,0,0,0,2.344,0V4.687h5.332V5.859a1.172,1.172,0,0,0,2.344,0V4.687h1.523a2.346,2.346,0,0,1,2.344,2.344v6.68a1.172,1.172,0,0,0,2.344,0V7.031A4.693,4.693,0,0,0,25.312,2.344Z"
                                    fill="#fff"
                                  />
                                  <path
                                    id="Path_44"
                                    data-name="Path 44"
                                    d="M277.09,270a7.09,7.09,0,1,0,7.09,7.09A7.1,7.1,0,0,0,277.09,270Zm0,11.836a4.746,4.746,0,1,1,4.746-4.746A4.751,4.751,0,0,1,277.09,281.836Z"
                                    transform="translate(-254.18 -254.18)"
                                    fill="#fff"
                                  />
                                  <path
                                    id="Path_45"
                                    data-name="Path 45"
                                    d="M373.871,332.4h-.527v-1.23a1.172,1.172,0,0,0-2.344,0v2.4a1.172,1.172,0,0,0,1.172,1.172h1.7a1.172,1.172,0,1,0,0-2.344Z"
                                    transform="translate(-349.262 -310.664)"
                                    fill="#fff"
                                  />
                                  <ellipse
                                    id="Ellipse_3"
                                    data-name="Ellipse 3"
                                    cx="1.5"
                                    cy="1"
                                    rx="1.5"
                                    ry="1"
                                    transform="translate(15.911 11.5)"
                                    fill="#fff"
                                  />
                                  <ellipse
                                    id="Ellipse_4"
                                    data-name="Ellipse 4"
                                    cx="1"
                                    cy="1.5"
                                    rx="1"
                                    ry="1.5"
                                    transform="translate(11.911 15.5)"
                                    fill="#fff"
                                  />
                                  <ellipse
                                    id="Ellipse_5"
                                    data-name="Ellipse 5"
                                    cx="1.5"
                                    cy="1"
                                    rx="1.5"
                                    ry="1"
                                    transform="translate(5.911 11.5)"
                                    fill="#fff"
                                  />
                                  <circle
                                    id="Ellipse_6"
                                    data-name="Ellipse 6"
                                    cx="1.5"
                                    cy="1.5"
                                    r="1.5"
                                    transform="translate(5.911 15.5)"
                                    fill="#fff"
                                  />
                                  <circle
                                    id="Ellipse_7"
                                    data-name="Ellipse 7"
                                    cx="1.5"
                                    cy="1.5"
                                    r="1.5"
                                    transform="translate(5.911 20.5)"
                                    fill="#fff"
                                  />
                                  <ellipse
                                    id="Ellipse_8"
                                    data-name="Ellipse 8"
                                    cx="1"
                                    cy="1.5"
                                    rx="1"
                                    ry="1.5"
                                    transform="translate(11.911 20.5)"
                                    fill="#fff"
                                  />
                                  <circle
                                    id="Ellipse_9"
                                    data-name="Ellipse 9"
                                    cx="1"
                                    cy="1"
                                    r="1"
                                    transform="translate(11.911 11.5)"
                                    fill="#fff"
                                  />
                                </g>
                              </g>
                            </g>
                          </svg>
                          <div className="single_noti_content">
                            <div className="noti_category">تقرير</div>
                            <div className="noti_text">
                              <div className="noti_mobile_date">
                                <span className="span_name">{`${notif.lawyer.first_name} ${notif.lawyer.last_name}`}</span>
                              </div>
                            </div>
                          </div>
                        </a>
                      );
                    if (notif.type === 'session')
                      return (
                        <a key={notif.id} href="#">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="44"
                            height="44"
                            viewBox="0 0 44 44"
                          >
                            <circle
                              id="Ellipse_37"
                              data-name="Ellipse 37"
                              cx="22"
                              cy="22"
                              r="22"
                              fill="#ca2946"
                            />
                            <g id="gavel" transform="translate(5.568 5)">
                              <path
                                id="Path_41"
                                data-name="Path 41"
                                d="M240.99,422.673v3.707a.923.923,0,0,0,.923.923H256.74a.923.923,0,0,0,.923-.923v-3.707a.923.923,0,0,0-.923-.923H241.913A.923.923,0,0,0,240.99,422.673Zm1.846.923h12.982v1.861H242.836Z"
                                transform="translate(-226.164 -395.802)"
                                fill="#fff"
                              />
                              <path
                                id="Path_42"
                                data-name="Path 42"
                                d="M.822,27.786a2.779,2.779,0,0,0,3.926,0L15.891,16.644l1.316,1.316-.658.658a.923.923,0,0,0,0,1.305l2.621,2.621a.923.923,0,0,0,1.305,0l7.863-7.863a.923.923,0,0,0,0-1.305l-2.621-2.621a.923.923,0,0,0-1.305,0l-.658.658L17.2,4.854l.658-.658a.923.923,0,0,0,0-1.305L15.233.27a.923.923,0,0,0-1.305,0L6.065,8.134a.923.923,0,0,0,0,1.305L8.686,12.06a.923.923,0,0,0,1.305,0l.658-.658,1.316,1.316L.822,23.86a2.779,2.779,0,0,0,0,3.926ZM15.9,3.544,9.338,10.1,8.022,8.786,14.58,2.228Zm2.61,15.727,6.558-6.558,1.316,1.316-6.558,6.558Zm.005-2.616L11.954,10.1,15.891,6.16l6.558,6.558ZM2.127,25.165,13.27,14.023l1.316,1.316L3.443,26.481a.931.931,0,1,1-1.316-1.316Z"
                                transform="translate(-0.011 0)"
                                fill="#fff"
                              />
                            </g>
                          </svg>

                          <div className="single_noti_content">
                            <div className="noti_category">جلسة</div>
                            <div className="noti_text">
                              <div className="noti_mobile_date">
                                <span className="span_time">
                                  {moment(notif.name).format('HH')}h
                                  {moment(notif.name).format('mm')}
                                </span>
                                <span className="span_date">
                                  {moment(notif.name).format('DD/MM/YYYY')}
                                </span>
                              </div>
                            </div>
                          </div>
                        </a>
                      );
                  }
                })
              : ''}
            {listNotifications && listNotifications.length > 0 ? (
              <div className="noti_more">
                المزيد
                <svg
                  className=""
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.225"
                  height="5.492"
                  viewBox="0 0 15.225 5.492"
                >
                  <path
                    id="Path_53"
                    data-name="Path 53"
                    d="M3020.1,1976.5l7.3,4.478,7.4-4.478"
                    transform="translate(-3019.841 -1976.072)"
                    fill="none"
                    stroke="var(--color-primary-red)"
                    strokeWidth="1"
                  />
                </svg>
              </div>
            ) : (
              <div className="noti_more">لا يجد اشعارات جديدة</div>
            )}
          </div>
          <div className="home_fixed">
            <div className="home_fixed_btn">
              <a href="home.html">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="38.108"
                  height="36.546"
                  viewBox="0 0 38.108 36.546"
                >
                  <g id="home" transform="translate(0 -10.492)">
                    <g id="Group_12" data-name="Group 12" transform="translate(0 10.492)">
                      <path
                        id="Path_48"
                        data-name="Path 48"
                        d="M37.676,27.307,22.165,11.695c-.02-.02-.04-.04-.061-.059a4.462,4.462,0,0,0-5.953-.013q-.032.028-.062.058L.437,27.3a1.489,1.489,0,1,0,2.1,2.107l1.181-1.179V41.084a5.961,5.961,0,0,0,5.954,5.954h5.359a1.489,1.489,0,0,0,1.489-1.489V33.418h5.21V45.55a1.489,1.489,0,0,0,1.489,1.489h5.21a5.961,5.961,0,0,0,5.954-5.954,1.489,1.489,0,0,0-2.977,0,2.981,2.981,0,0,1-2.977,2.977H24.711V31.929a1.489,1.489,0,0,0-1.489-1.489H15.035a1.489,1.489,0,0,0-1.489,1.489V44.061H9.676A2.981,2.981,0,0,1,6.7,41.084V25.3q0-.022,0-.044L18.157,13.824a1.487,1.487,0,0,1,1.931,0l11.321,11.4v8.417a1.489,1.489,0,0,0,2.977,0v-5.42l1.177,1.185a1.489,1.489,0,1,0,2.112-2.1Z"
                        transform="translate(0 -10.492)"
                        fill="#fff"
                      />
                    </g>
                  </g>
                </svg>
              </a>
            </div>
          </div>
          <div className="side__mobile_menu">
            <div className="side__mobile_menu_nav">
              <svg
                onClick={() => this.hideDropDownMenu()}
                className="side__mobile_menu-open"
                id="side__mobile_menu-open"
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="25"
                viewBox="0 0 37 25"
              >
                <g id="Group_107" data-name="Group 107" transform="translate(-365 -24)">
                  <rect
                    id="Rectangle_8"
                    data-name="Rectangle 8"
                    width="37"
                    height="5"
                    rx="2.5"
                    transform="translate(365 24)"
                    fill="#961829"
                  />
                  <rect
                    id="Rectangle_9"
                    data-name="Rectangle 9"
                    width="27"
                    height="5"
                    rx="2.5"
                    transform="translate(365 34)"
                    fill="#961829"
                  />
                  <rect
                    id="Rectangle_10"
                    data-name="Rectangle 10"
                    width="32"
                    height="5"
                    rx="2.5"
                    transform="translate(365 44)"
                    fill="#961829"
                  />
                </g>
              </svg>
              <svg
                className="side__mobile_menu-close hide"
                id="side__mobile_menu-close"
                xmlns="http://www.w3.org/2000/svg"
                width="29.698"
                height="29.698"
                viewBox="0 0 29.698 29.698"
              >
                <g
                  id="Group_108"
                  data-name="Group 108"
                  transform="translate(-22.651 -31.651)"
                >
                  <rect
                    id="Rectangle_10"
                    data-name="Rectangle 10"
                    width="37"
                    height="5"
                    rx="2.5"
                    transform="translate(26.186 31.651) rotate(45)"
                    fill="#961829"
                  />
                  <rect
                    id="Rectangle_23"
                    data-name="Rectangle 23"
                    width="37"
                    height="5"
                    rx="2.5"
                    transform="translate(22.651 57.814) rotate(-45)"
                    fill="#961829"
                  />
                </g>
              </svg>
            </div>
            <div className="side__mobile_menu_rest">
              <div
                onClick={() => this.handleChangeDropDownNotif()}
                className="top_bar_notifications"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <svg
                  className="noti_dropdown"
                  version="1.2"
                  baseProfile="tiny-ps"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 29 31"
                  width="25"
                  height="31"
                >
                  <g id="">
                    <g id="Groupe 116">
                      <g id="notification">
                        <path
                          id="Tracé 156"
                          className="shp0"
                          d="M12.82 31C10.17 31 8.01 28.84 8.01 26.19C8.01 25.66 8.44 25.23 8.97 25.23C9.5 25.23 9.93 25.66 9.93 26.19C9.93 27.78 11.23 29.08 12.82 29.08C14.41 29.08 15.7 27.78 15.7 26.19C15.7 25.66 16.13 25.23 16.66 25.23C17.19 25.23 17.62 25.66 17.62 26.19C17.62 28.84 15.47 31 12.82 31Z"
                        />
                        <path
                          id="Tracé 157"
                          className="shp0"
                          d="M23.39 27.15L2.24 27.15C1.01 27.15 0 26.15 0 24.91C0 24.26 0.29 23.63 0.78 23.21C0.82 23.18 0.85 23.15 0.89 23.13C2.77 21.49 3.84 19.13 3.84 16.63L3.84 13.06C3.84 8.11 7.87 4.09 12.82 4.09C13.02 4.09 13.24 4.09 13.45 4.12C13.97 4.21 14.33 4.71 14.24 5.23C14.15 5.75 13.65 6.11 13.13 6.02C13.03 6 12.92 6.01 12.82 6.01C8.93 6.01 5.77 9.17 5.77 13.06L5.77 16.63C5.77 19.73 4.41 22.66 2.05 24.67C2.03 24.69 2.01 24.7 1.99 24.71C1.96 24.76 1.92 24.82 1.92 24.91C1.92 25.09 2.07 25.23 2.24 25.23L23.39 25.23C23.56 25.23 23.71 25.09 23.71 24.91C23.71 24.82 23.68 24.76 23.64 24.71C23.62 24.7 23.61 24.69 23.59 24.67C21.22 22.66 19.87 19.73 19.87 16.63L19.87 15.24C19.87 14.71 20.3 14.27 20.83 14.27C21.36 14.27 21.79 14.71 21.79 15.24L21.79 16.63C21.79 19.13 22.87 21.49 24.75 23.13C24.78 23.16 24.82 23.18 24.85 23.21C25.35 23.63 25.63 24.26 25.63 24.91C25.63 26.15 24.63 27.15 23.39 27.15L23.39 27.15Z"
                        />
                        <path
                          id="Tracé 157 copy"
                          className="shp0"
                          d="M23.39 27.15L2.24 27.15C1.01 27.15 0 26.15 0 24.91C0 24.26 0.29 23.63 0.78 23.21C0.82 23.18 0.85 23.15 0.89 23.13C2.77 21.49 3.84 19.13 3.84 16.63L3.84 13.06C3.84 8.11 7.87 4.09 12.82 4.09C13.02 4.09 13.24 4.09 13.45 4.12C13.97 4.21 14.33 4.71 14.24 5.23C14.15 5.75 13.65 6.11 13.13 6.02C13.03 6 12.92 6.01 12.82 6.01C8.93 6.01 5.77 9.17 5.77 13.06L5.77 16.63C5.77 19.73 4.41 22.66 2.05 24.67C2.03 24.69 2.01 24.7 1.99 24.71C1.96 24.76 1.92 24.82 1.92 24.91C1.92 25.09 2.07 25.23 2.24 25.23L23.39 25.23C23.56 25.23 23.71 25.09 23.71 24.91C23.71 24.82 23.68 24.76 23.64 24.71C23.62 24.7 23.61 24.69 23.59 24.67C21.22 22.66 19.87 19.73 19.87 16.63L19.87 15.24C19.87 14.71 20.3 14.27 20.83 14.27C21.36 14.27 21.79 14.71 21.79 15.24L21.79 16.63C21.79 19.13 22.87 21.49 24.75 23.13C24.78 23.16 24.82 23.18 24.85 23.21C25.35 23.63 25.63 24.26 25.63 24.91C25.63 26.15 24.63 27.15 23.39 27.15L23.39 27.15Z"
                        />
                        <path
                          id="Tracé 157 copy 2"
                          className="shp0"
                          d="M23.39 27.15L2.24 27.15C1.01 27.15 0 26.15 0 24.91C0 24.26 0.29 23.63 0.78 23.21C0.82 23.18 0.85 23.15 0.89 23.13C2.77 21.49 3.84 19.13 3.84 16.63L3.84 13.06C3.84 8.11 7.87 4.09 12.82 4.09C13.02 4.09 13.24 4.09 13.45 4.12C13.97 4.21 14.33 4.71 14.24 5.23C14.15 5.75 13.65 6.11 13.13 6.02C13.03 6 12.92 6.01 12.82 6.01C8.93 6.01 5.77 9.17 5.77 13.06L5.77 16.63C5.77 19.73 4.41 22.66 2.05 24.67C2.03 24.69 2.01 24.7 1.99 24.71C1.96 24.76 1.92 24.82 1.92 24.91C1.92 25.09 2.07 25.23 2.24 25.23L23.39 25.23C23.56 25.23 23.71 25.09 23.71 24.91C23.71 24.82 23.68 24.76 23.64 24.71C23.62 24.7 23.61 24.69 23.59 24.67C21.22 22.66 19.87 19.73 19.87 16.63L19.87 15.24C19.87 14.71 20.3 14.27 20.83 14.27C21.36 14.27 21.79 14.71 21.79 15.24L21.79 16.63C21.79 19.13 22.87 21.49 24.75 23.13C24.78 23.16 24.82 23.18 24.85 23.21C25.35 23.63 25.63 24.26 25.63 24.91C25.63 26.15 24.63 27.15 23.39 27.15L23.39 27.15Z"
                        />
                        {countNotification > 0 ? (
                          <path
                            id="Tracé 158"
                            className="shp1"
                            d="M21.79 13.06C18.25 13.06 15.38 10.18 15.38 6.65C15.38 3.11 18.25 0.24 21.79 0.24C25.32 0.24 28.2 3.11 28.2 6.65C28.2 10.18 25.32 13.06 21.79 13.06Z"
                          />
                        ) : (
                          ''
                        )}
                      </g>
                      <text id="3 " style={{ transform: 'matrix(1,0,0,1,24,9)' }}>
                        <tspan id="numberOfNotifications" x="0" y="0" className="txt2">
                          {countNotification}
                        </tspan>
                      </text>
                    </g>
                  </g>
                </svg>
              </div>
              <div className="top_bar__pic">
                <div className="top_bar__user_initial" id="top_bar__user_initial">
                  {account.first_name.charAt(0)}
                </div>
              </div>
            </div>
            <div className="home_main__date main__date" id="home_main__date">
              {`${moment().format('dddd')} ${moment().format('DD/MM/YYYY')}`}
            </div>
          </div>
          <ToastContainer
            closeOnClick={false}
            autoClose={TOAST_CLOSE_DELAY}
            position="top-center"
            pauseOnHover
            //toastClassName="toast"
            bodyClassName="toast-body"
            progressClassName="toast-progress"
            className="toast-container"
          />
          <Sidebar
            countMessageIsSeen={countMessageIsSeen}
            sandwich_menu_btn={sandwich_menu_btn}
            pusher={pusher}
            account={account}
            countMessagesIsSeen={countMessagesIsSeens}
            activeItemSideBar={activeItemSideBar}
            changeActiveItemSideBar={this.props.changeActiveItemSideBar}
            changeCountMessage={this.props.changeCountMessage}
          />
          <div
            id="rightSideWrapper"
            style={{ width: !sandwich_menu_btn ? '95%' : '88%' }}
          >
            <Headers
              sandwich_menu_btn={sandwich_menu_btn}
              pusher={pusher}
              listNotifications={listNotifications}
              handleChangeMenuBtn={this.handleChangeMenuBtn}
              logout={this.props.logout}
              getListNotifications={this.props.getListNotifications}
              getNotificationIsSeen={this.props.getNotificationIsSeen}
              getCountNotificationSeen={this.props.getCountNotificationSeen}
              countNotification={countNotification}
              account={account}
              namePage={namePage}
            />
            <div className="ContentBox">
              <Switch>
                <Route
                  exact
                  path="/dashboard"
                  render={(props) => (
                    <Dashboard
                      {...props}
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                    />
                  )}
                  key="Home"
                />
                {isHasPermission(account.login_access.permissions, 'قراءة-الجلسات') ? (
                  <Route
                    exact
                    path="/session"
                    render={(props) => (
                      <Session
                        {...props}
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      />
                    )}
                    key="Session"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(account.login_access.permissions, 'إضافة-الجلسات') ? (
                  <Route
                    exact
                    path="/add-session"
                    render={(props) => (
                      <AddSession
                        {...props}
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      />
                    )}
                    key="AddSession"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(account.login_access.permissions, 'تعديل-الجلسات') ? (
                  <Route
                    exact
                    path="/edit-session/:id"
                    render={(props) => (
                      <AddSession
                        {...props}
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      />
                    )}
                    key="EditSession"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(account.login_access.permissions, 'قراءة-القضايا') ? (
                  <Route
                    exact
                    path="/lawsuits"
                    render={(props) => (
                      <Lawsuits
                        {...props}
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      />
                    )}
                    key="Lawsuits"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(account.login_access.permissions, 'قراءة-القضايا') ? (
                  <Route
                    exact
                    path="/lawsuits-remotely"
                    render={(props) => (
                      <LawsuitsRemotely
                        {...props}
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      />
                    )}
                    key="LawsuitsRemotely"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(
                  account.login_access.permissions,
                  'قراءة-ملفات-الحرفاء',
                ) ? (
                  <Route
                    exact
                    path="/customers"
                    render={(props) => (
                      <Customers
                        {...props}
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      />
                    )}
                    key="Customers"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(account.login_access.permissions, 'قراءة-المهام') &&
                account.pack !== 'beginner' ? (
                  <Route
                    exact
                    path="/tasks"
                    render={(props) => (
                      <Tasks
                        {...props}
                        account={account}
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      />
                    )}
                    key="Tasks"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(account.login_access.permissions, 'قراءة-أسئلة') ? (
                  <Route
                    exact
                    path="/questions"
                    render={(props) => (
                      <Questions
                        {...props}
                        account={account}
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      />
                    )}
                    key="Question"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(account.login_access.permissions, 'قراءة-أسئلة') ? (
                  <Route
                    exact
                    path="/question/:id"
                    render={(props) => (
                      <Question
                        {...props}
                        account={account}
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      />
                    )}
                    key="Question"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(
                  account.login_access.permissions,
                  'إضافة-ملفات-الحرفاء',
                ) ? (
                  <Route
                    exact
                    path="/add-customer"
                    render={(props) => (
                      <FormCustomer
                        {...props}
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      />
                    )}
                    key="FormCustomer"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(
                  account.login_access.permissions,
                  'تعديل-ملفات-الحرفاء',
                ) ? (
                  <Route
                    exact
                    path="/edit-customer/:id"
                    render={(props) => (
                      <FormCustomer
                        {...props}
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      />
                    )}
                    key="FormCustomer"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(account.login_access.permissions, 'إضافة-القضايا') ? (
                  <Route
                    exact
                    path="/add-lawsuit"
                    render={(props) => (
                      <AddLawsuit
                        {...props}
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      />
                    )}
                    key="AddLawsuit"
                  />
                ) : (
                  ''
                )}{' '}
                {isHasPermission(account.login_access.permissions, 'إضافة-القضايا') ? (
                  <Route
                    exact
                    path="/generate-pdf-lawsuit"
                    render={(props) => (
                      <PdfDocument
                        {...props}
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      />
                    )}
                    key="AddLawsuit"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(account.login_access.permissions, 'إضافة-القضايا') ? (
                  <Route
                    exact
                    path="/generate-pdf-all-lawsuit"
                    render={(props) => (
                      <PdfAllDocument
                        {...props}
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      />
                    )}
                    key="Pdf"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(account.login_access.permissions, 'إضافة-المحاسبة') ? (
                  <Route
                    exact
                    path="/generate-pdf-bill"
                    render={(props) => (
                      <GeneratePdfBill
                        {...props}
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      />
                    )}
                    key="GeneratePdfBill"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(account.login_access.permissions, 'تعديل-القضايا') ? (
                  <Route
                    exact
                    path="/edit-lawsuit/:id"
                    render={(props) => (
                      <AddLawsuit
                        {...props}
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      />
                    )}
                    key="EditLawsuit"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(
                  account.login_access.permissions,
                  'قراءة-تبادل-التقارير',
                ) ? (
                  <Route
                    exact
                    path="/reports"
                    render={(props) => (
                      <Reports
                        {...props}
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      />
                    )}
                    key="reports"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(
                  account.login_access.permissions,
                  'إضافة-تبادل-التقارير',
                ) ? (
                  <Route
                    exact
                    path="/add-report"
                    render={(props) => (
                      <FormReport
                        {...props}
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      />
                    )}
                    key="FormReport"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(
                  account.login_access.permissions,
                  'تعديل-تبادل-التقارير',
                ) ? (
                  <Route
                    exact
                    path="/edit-report/:id"
                    render={(props) => (
                      <FormReport
                        {...props}
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      />
                    )}
                    key="EditReport"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(account.login_access.permissions, 'قراءة-المواعيد') ? (
                  <Route
                    exact
                    path="/appointment"
                    render={(props) => (
                      <Appointment
                        {...props}
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      />
                    )}
                    key="Appointment"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(account.login_access.permissions, 'قراءة-الإعدادت') ? (
                  <Route
                    exact
                    path="/settings"
                    render={(props) => (
                      <Settings
                        {...props}
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      />
                    )}
                    key="Settings"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(account.login_access.permissions, 'قراءة-الطعون') ? (
                  <Route
                    exact
                    path="/appeals"
                    render={(props) => (
                      <Appeals
                        {...props}
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      />
                    )}
                    key="Appeals"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(account.login_access.permissions, 'إضافة-الطعون') ? (
                  <Route
                    exact
                    path="/add-appeal"
                    render={(props) => (
                      <FormAppeal
                        {...props}
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      />
                    )}
                    key="FormAppeal"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(account.login_access.permissions, 'تعديل-الطعون') ? (
                  <Route
                    exact
                    path="/edit-appeal/:id"
                    render={(props) => (
                      <FormAppeal
                        {...props}
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      />
                    )}
                    key="FormAppeal"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(account.login_access.permissions, 'تعديل-الطعون') ? (
                  <Route
                    exact
                    path="/generate-pdf-payment"
                    render={(props) => (
                      <GeneratePdfPayment
                        {...props}
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      />
                    )}
                    key="GeneratePdfPayment"
                  />
                ) : (
                  ''
                )}
                <Route
                  exact
                  path="/legal-journals"
                  render={(props) => (
                    <LegalJournals
                      {...props}
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                    />
                  )}
                  key="LegalJournals"
                />
                <Route
                  exact
                  path="/jurisprudence"
                  render={(props) => (
                    <Jurisprudence
                      {...props}
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                    />
                  )}
                  key="Jurisprudence"
                />
                {isHasPermission(account.login_access.permissions, 'قراءة-المحادثات') ? (
                  <Route
                    exact
                    path="/messenger"
                    render={(props) => (
                      <Messenger
                        {...props}
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      />
                    )}
                    key="Messenger"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(account.login_access.permissions, 'إضافة-القضايا') ? (
                  <Route
                    exact
                    path="/notifications"
                    render={(props) => (
                      <Notification
                        {...props}
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      />
                    )}
                    key="Notification"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(account.login_access.permissions, 'قراءة-الأرشيف') ? (
                  <Route
                    exact
                    path="/archives"
                    render={(props) => (
                      <Archives
                        {...props}
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      />
                    )}
                    key="Archives"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(account.login_access.permissions, 'قراءة-الأرشيف') ? (
                  <Route
                    exact
                    path="/archives/customers"
                    render={(props) => (
                      <CustomerArchive
                        {...props}
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      />
                    )}
                    key="CustomerArchive"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(account.login_access.permissions, 'قراءة-الأرشيف') ? (
                  <Route
                    exact
                    path="/archives/lawsuits"
                    render={(props) => (
                      <LawsuitArchive
                        {...props}
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      />
                    )}
                    key="LawsuitArchive"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(account.login_access.permissions, 'قراءة-الأرشيف') ? (
                  <Route
                    exact
                    path="/archives/sessions"
                    render={(props) => (
                      <SessionArchive
                        {...props}
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      />
                    )}
                    key="SessionArchive"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(account.login_access.permissions, 'قراءة-الأرشيف') ? (
                  <Route
                    exact
                    path="/archives/contracts"
                    render={(props) => (
                      <ContractsArchive
                        {...props}
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      />
                    )}
                    key="ContractsArchive"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(account.login_access.permissions, 'قراءة-العقود') ? (
                  <Route
                    exact
                    path="/contracts"
                    render={(props) => (
                      <Contracts
                        {...props}
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      />
                    )}
                    key="Contracts"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(account.login_access.permissions, 'إضافة-العقود') ? (
                  <Route
                    exact
                    path="/add-contract"
                    render={(props) => (
                      <FormContract
                        {...props}
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      />
                    )}
                    key="FormContract"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(account.login_access.permissions, 'تعديل-العقود') ? (
                  <Route
                    exact
                    path="/edit-contract/:id"
                    render={(props) => (
                      <FormContract
                        {...props}
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      />
                    )}
                    key="FormContract"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(account.login_access.permissions, 'قراءة-المحاسبة') ? (
                  <Route
                    exact
                    path="/receivables"
                    render={(props) => (
                      <Receivables
                        {...props}
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      />
                    )}
                    key="Receivables"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(account.login_access.permissions, 'إضافة-المحاسبة') ? (
                  <Route
                    exact
                    path="/add-receivable"
                    render={(props) => (
                      <FormReceivable
                        {...props}
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      />
                    )}
                    key="FormReceivable"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(account.login_access.permissions, 'تعديل-المحاسبة') ? (
                  <Route
                    exact
                    path="/edit-receivable/:id"
                    render={(props) => (
                      <FormReceivable
                        {...props}
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      />
                    )}
                    key="FormReceivable"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(account.login_access.permissions, 'قراءة-المحاسبة') ? (
                  <Route
                    exact
                    path="/bills"
                    render={(props) => (
                      <Bills
                        {...props}
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      />
                    )}
                    key="Bills"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(account.login_access.permissions, 'إضافة-المحاسبة') ? (
                  <Route
                    exact
                    path="/add-bill"
                    render={(props) => (
                      <FormBill
                        {...props}
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      />
                    )}
                    key="FormBill"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(account.login_access.permissions, 'تعديل-المحاسبة') ? (
                  <Route
                    exact
                    path="/edit-bill/:id"
                    render={(props) => (
                      <FormBill
                        {...props}
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      />
                    )}
                    key="FormBill"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(account.login_access.permissions, 'قراءة-الأرشيف') ? (
                  <Route
                    exact
                    path="/archives/bill"
                    render={(props) => (
                      <BillsArchive
                        {...props}
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      />
                    )}
                    key="BillsArchive"
                  />
                ) : (
                  ''
                )}
                {error === 401 ? <Redirect to="/login" /> : <Redirect to="/dashboard" />}
              </Switch>
            </div>
          </div>
        </div>
      );
    }
    return <>{app}</>;
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
