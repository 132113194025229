import React, { Component } from 'react';
import Form from './containers/form';
import actions from './store/actions';
import actionsApp from '../../js/actions';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';

const mapStateToProps = (state) => ({
  report: state.ReportsReducer.report,
  isLoadingReport: state.ReportsReducer.isLoadingReport,
  lawyers: state.AppReducer.lawyers,
  isLoadingLawyers: state.AppReducer.isLoadingLawyers,
});

const mapDispatchToProps = (dispatch) => ({
  getReportById: (id) => dispatch(actions.getReportById(id)),
  getListLawyers: () => dispatch(actionsApp.getListLawyers()),
});

class FormReport extends Component {
  constructor(props) {
    super(props);
    if (props.match.params && props.match.params.id) {
      props.changeActiveItemSideBar(4, 'تحديث تقرير');
      document.title = 'تحديث تقرير';
    } else {
      props.changeActiveItemSideBar(4, 'اضافة تقرير');
      document.title = 'اضافة تقرير';
    }
    props.getListLawyers();
    props.getReportById(props.match.params && props.match.params.id);
    this.goBack = this.goBack.bind(this);
    this.goReports = this.goReports.bind(this);
  }

  goBack() {
    this.props.history.goBack();
  }
  goReports() {
    this.props.history.push('/reports');
  }

  render() {
    const { report, isLoadingReport, lawyers } = this.props;
    if (isLoadingReport) {
      return (
        <CircularProgress
          style={{
            textAlign: 'center',
            width: '30px',
            height: '30px',
            marginTop: '20%',
            marginRight: '50%',
          }}
        />
      );
    }
    return (
      <div style={{ display: 'flex', marginBottom: '5%' }} className="lawsuit_newLawsuit">
        <Form
          lawyers={lawyers}
          goBack={this.goBack}
          goReports={this.goReports}
          report={report}
        />
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(FormReport);
