import React, { Component } from 'react';
import actions from '../../js/actions';
import actionsContract from './store/actions';
import { connect } from 'react-redux';
import Table from '../../components/Table';
import Filter from './components/filter';
import { Link } from 'react-router-dom';
import Show from './containers/show';
import { isHasPermission } from '../../utils/helpres';

const mapStateToProps = (state) => ({
  account: state.AppReducer.account,
  contracts: state.AppReducer.contracts,
  isLoadingContracts: state.AppReducer.isLoadingContracts,
  paramsContract: state.AppReducer.paramsContract,
  destroyContract: state.ContractReducer.destroyContract,
  isLoadingDestroyLawsuit: state.ContractReducer.isLoadingDestroyLawsuit,
});

const mapDispatchToProps = (dispatch) => ({
  getListContract: (paramsContract) =>
    dispatch(actions.getListContract(paramsContract, 0)),
  changeSearchedStringContract: async (paramsContract) => {
    await dispatch(actions.changeSearchedStringContract(paramsContract));
    dispatch(actions.getListContract(paramsContract, 0));
  },
  deleteContract: async (paramsContract, id) => {
    await dispatch(actionsContract.deleteContract(id));
    dispatch(actions.getListContract(paramsContract, 0));
  },
  getContractById: (id) => dispatch(actionsContract.getContractById(id)),
});

class Contracts extends Component {
  constructor(props) {
    super(props);
    props.changeActiveItemSideBar(11, 'العقود');
    document.title = 'العقود';
    this.state = {
      anchor: false,
      left: false,
      selected_id: null,
    };
    this.toggleDrawer = this.toggleDrawer.bind(this);
  }
  componentDidMount() {
    this.props.changeSearchedStringContract({
      search: '',
      date: '',
      currentPage: 1,
      limit: 5,
      order: 'DESC',
    });
  }
  toggleDrawer = (anchor, open, id) => (event) => {
    this.props.getContractById(id);
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    this.setState({
      left: open,
      anchor: 'left',
      selected_id: id,
      hasError: false,
    });
  };
  render() {
    const { contracts, account, destroyContract, isLoadingContracts, paramsContract } =
      this.props;
    const { anchor, left, selected_id } = this.state;
    let isPermissionEdit = isHasPermission(
      account.login_access.permissions,
      'تعديل-العقود',
    );
    let isPermissionDelete = isHasPermission(
      account.login_access.permissions,
      'حذف-العقود',
    );
    let isPermissionAdd = isHasPermission(
      account.login_access.permissions,
      'إضافة-العقود',
    );
    return (
      <div className="jalaset_main">
        {left && selected_id ? (
          <Show
            left={left}
            anchor={anchor}
            selected_id={selected_id}
            toggleDrawer={this.toggleDrawer}
          />
        ) : (
          ''
        )}
        <div className="jalaset_middle">
          <div className="table_controls">
            {isPermissionAdd ? (
              <Link to="/add-contract" className="jalaset_add_button">
                <svg
                  height="10"
                  viewBox="0 0 448 448"
                  width="10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m408 184h-136c-4.417969 0-8-3.582031-8-8v-136c0-22.089844-17.910156-40-40-40s-40 17.910156-40 40v136c0 4.417969-3.582031 8-8 8h-136c-22.089844 0-40 17.910156-40 40s17.910156 40 40 40h136c4.417969 0 8 3.582031 8 8v136c0 22.089844 17.910156 40 40 40s40-17.910156 40-40v-136c0-4.417969 3.582031-8 8-8h136c22.089844 0 40-17.910156 40-40s-17.910156-40-40-40zm0 0" />
                </svg>
                اضافة عقد
              </Link>
            ) : (
              ''
            )}

            <div className="sort_grp">
              <div className="sort_grp__txt">ترتيب حسب</div>
              <Filter
                nameInput="الحريف"
                changeSearchedStringLawsuits={this.props.changeSearchedStringContract}
                paramsSessions={paramsContract}
              />
            </div>
          </div>
          <Table
            class1="lawsuit__judgements_table"
            class2="lawsuit_table"
            withTitle={false}
            colSpan={4}
            dataTable="contract"
            messageDelete="أنت بصدد حذف العقد"
            action={true}
            isLoading={isLoadingContracts}
            params={paramsContract}
            isPermissionEdit={isPermissionEdit}
            isPermissionDelete={isPermissionDelete}
            isPermissionAdd={isPermissionAdd}
            changeSearchedString={this.props.changeSearchedStringContract}
            toggleDrawer={this.toggleDrawer}
            show={true}
            data={contracts}
            //sessions={sessions}
            title="الجلسات"
            editPath="edit-contract"
            deletePath={this.props.deleteContract}
            destroy={destroyContract}
            destroyYes={true}
            headerItems={['الحريف', 'نوع العقد', 'الطرف الثاني', 'تاريخ', 'الإعدادت']}
          />
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Contracts);
