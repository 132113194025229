import { getAppErrorCode } from '../../../utils/helpres';
import moment from 'moment';

const initialAppState = {
  error: undefined,
  ErrorMessage: undefined,
  isLoadingGetAllUnavailability: false,
  isLoadingGetAllDayDispo: false,
  isLoadingGetMotifs: false,
  isLoadingGetAllRDV: false,
  isLoadingDeleteUnavailability: false,
  deleteUnavailability: false,
  isLoadingAddDispo: false,
  isLoadingDeleteRDV: false,
  getAllUnavailability: [],
  getAllRDV: [],
  getAllDayDispo: [],
  getLastAvailabilityDate: null,
  isLoadingGetLastAvailability: false,
  canceledRdv: false,
  motifs: [],
  message: '',
  rdv: null,
  isLoadingAddRdv: false,
  updateRdv: false,
  createdNewDispo: false,
  deleteRDV: false,
  params: {
    date: moment().format('YYYY-MM-DD'),
    status: '',
  },
};
const AgendaReducer = (state = initialAppState, action) => {
  switch (action.type) {
    case 'CREATE_NEW_RDV_REQUESTING': {
      return {
        ...state,
        isLoadingAddRdv: false,
        updateRdv: false,
      };
    }
    case 'CREATE_NEW_RDV_SUCCESS': {
      return {
        ...state,
        isLoadingAddRdv: false,
        updateRdv: true,
      };
    }
    case 'CREATE_NEW_RDV_FAILURE': {
      return {
        ...state,
        isLoadingAddRdv: false,
        updateRdv: false,
        rdv: {
          ...action.data,
        },
      };
    }

    case 'CREATE_NEW_DISPO_SUCCESS': {
      return {
        ...state,
        isLoadingAddDispo: false,
        createdNewDispo: true,
      };
    }
    case 'CREATE_NEW_DISPO_FAILURE': {
      return {
        ...state,
        isLoadingAddDispo: false,
        createdNewDispo: false,
      };
    }

    case 'UPDATE_STATUS_RDV_SUCCESS': {
      return {
        ...state,
        isLoadingAddRdv: false,
        canceledRdv: true,
        rdv: {
          ...action.data,
        },
      };
    }
    case 'GET_LAST_AVAILABILITY_REQUESTING':
      return {
        ...state,
        isLoadingGetLastAvailability: true,
      };
    case 'GET_LAST_AVAILABILITY_SUCCESS': {
      return {
        ...state,
        isLoadingGetLastAvailability: false,
        getLastAvailabilityDate: action.data,
      };
    }
    case 'GET_LAST_AVAILABILITY_FAILURE': {
      return {
        ...state,
        isLoadingGetLastAvailability: false,
        error: getAppErrorCode(action.status),
        message: action.msg,
        getLastAvailabilityDate: null,
      };
    }

    case 'GET_DELETE_RDV_REQUESTING':
      return {
        ...state,
        isLoadingDeleteRDV: true,
      };
    case 'GET_DELETE_RDV_SUCCESS': {
      return {
        ...state,
        isLoadingDeleteRDV: false,
        deleteRDV: action.data,
      };
    }
    case 'GET_DELETE_RDV_FAILURE': {
      return {
        ...state,
        isLoadingDeleteRDV: false,
        error: getAppErrorCode(action.status),
        message: action.msg,
        deleteRDV: false,
      };
    }

    case 'GET_DISPONIBILITY_DOCTOR_BY_MOTIF_REQUESTING':
      return {
        ...state,
        isLoadingGetAllDayDispo: true,
        createdNewDispo: false,
      };
    case 'GET_DISPONIBILITY_DOCTOR_BY_MOTIF_SUCCESS': {
      return {
        ...state,
        isLoadingGetAllDayDispo: false,
        createdNewDispo: false,
        getAllDayDispo: action.data,
      };
    }
    case 'GET_DISPONIBILITY_DOCTOR_BY_MOTIF_FAILURE': {
      return {
        ...state,
        isLoadingGetAllDayDispo: false,
        error: getAppErrorCode(action.status),
        message: action.msg,
        getAllDayDispo: [],
      };
    }
    case 'GET_MOTIFS_REQUESTING':
      return {
        ...state,
        isLoadingGetMotifs: true,
      };
    case 'GET_MOTIFS_SUCCESS': {
      return {
        ...state,
        isLoadingGetMotifs: false,
        motifs: action.data.data,
      };
    }
    case 'GET_MOTIFS_FAILURE': {
      return {
        ...state,
        isLoadingGetMotifs: false,
        error: getAppErrorCode(action.status),
        message: action.msg,
        motifs: [],
      };
    }

    case 'GET_ALL_UNAVAILABILITY_DOCTOR_REQUESTING':
      return {
        ...state,
        isLoadingGetAllUnavailability: true,
        createdNewDispo: false,
        deleteUnavailability: false,
      };
    case 'GET_ALL_UNAVAILABILITY_DOCTOR_SUCCESS': {
      return {
        ...state,
        isLoadingGetAllUnavailability: false,
        createdNewDispo: false,
        deleteUnavailability: false,
        getAllUnavailability: action.data.data,
      };
    }
    case 'GET_ALL_UNAVAILABILITY_DOCTOR_FAILURE': {
      return {
        ...state,
        isLoadingGetAllUnavailability: false,
        error: getAppErrorCode(action.status),
        message: action.msg,
        createdNewDispo: false,
        deleteUnavailability: false,
        getAllUnavailability: [],
      };
    }

    case 'GET_DELETE_UNAVAILABILITY_REQUESTING':
      return {
        ...state,
        isLoadingDeleteUnavailability: true,
        createdNewDispo: false,
      };
    case 'GET_DELETE_UNAVAILABILITY_SUCCESS': {
      return {
        ...state,
        isLoadingDeleteUnavailability: false,
        createdNewDispo: false,
        deleteUnavailability: true,
      };
    }
    case 'GET_DELETE_UNAVAILABILITY_FAILURE': {
      return {
        ...state,
        isLoadingDeleteUnavailability: false,
        error: getAppErrorCode(action.status),
        message: action.msg,
        createdNewDispo: false,
        deleteUnavailability: false,
      };
    }

    case 'GET_RDV_ALL_LAWYER_REQUESTING':
      return {
        ...state,
        isLoadingGetAllRDV: true,
        getLastAvailabilityDate: null,
        created: false,
        canceledRdv: false,
      };
    case 'GET_RDV_ALL_LAWYER_SUCCESS': {
      return {
        ...state,
        isLoadingGetAllRDV: false,
        created: false,
        getLastAvailabilityDate: null,
        canceledRdv: false,
        getAllRDV: action.data.data,
      };
    }
    case 'GET_RDV_ALL_LAWYER_FAILURE': {
      return {
        ...state,
        isLoadingGetAllRDV: false,
        created: false,
        error: getAppErrorCode(action.status),
        message: action.msg,
        getAllRDV: [],
      };
    }
    case 'UPDATE_DATE_UNAVAILABILITY_PARAMS': {
      return {
        ...state,
        params: action.params,
      };
    }
    default: {
      return state;
    }
  }
};

export default AgendaReducer;
