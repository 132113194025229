import React, { Component } from 'react';
import actions from './store/actions';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Info from './pages/info';
import Password from './pages/password';
import { CircularProgress } from '@material-ui/core';
import SuitType from './pages/suit-type';
import actionsApp from '../../js/actions';
import Contract from './pages/contract';
import Payments from './pages/payment';
import Secretary from './pages/secretary';
import Contact from './pages/contact';
import { isHasPermission } from '../../utils/helpres';
import Statistic from './pages/statistic';
import ActivityRegister from './pages/activity-register';
import actionsSettings from './store/actions';

const mapStateToProps = (state) => ({
  isLoadingStatistic: state.AppReducer.isLoadingStatistic,
  statisticData: state.AppReducer.statisticData,
  isLoadingUser: state.AppReducer.isLoadingUser,
  account: state.AppReducer.account,
  user: state.AppReducer.user,
  suitTypes: state.AppReducer.suitTypes,
  isLoadingSuitTypes: state.AppReducer.isLoadingSuitTypes,
  judgments: state.AppReducer.judgments,
  isLoadingJudgments: state.AppReducer.isLoadingJudgments,
  typeContracts: state.AppReducer.typeContracts,
  isLoadingTypeContract: state.AppReducer.isLoadingTypeContract,
  register: state.AppReducer.register,
  isLoadingRegister: state.AppReducer.isLoadingRegister,
  isLoadingActivityRegister: state.SettingsReducer.isLoadingActivityRegister,
  activityRegister: state.SettingsReducer.activityRegister,
  lawyerTraineeShip: state.SettingsReducer.lawyerTraineeShip,
  paramsActivityRegister: state.SettingsReducer.paramsActivityRegister,
});
//
const mapDispatchToProps = (dispatch) => ({
  getLawyerTraineeShip: () => {
    dispatch(actionsSettings.getLawyerTraineeShip(true));
  },
  getSecretaries: async () => {
    await dispatch(actions.getSecretaries());
  },
  getActivityRegister: (paramsActivityRegister) => {
    dispatch(actions.getActivityRegister(paramsActivityRegister));
  },
  changeSearchedStringActivityRegister: async (paramsActivityRegister) => {
    await dispatch(actions.changeSearchedStringActivityRegister(paramsActivityRegister));
    dispatch(actions.getActivityRegister(paramsActivityRegister));
  },
  getUser: (id) => dispatch(actionsApp.getUser(id)),
  getListSuitType: () => dispatch(actionsApp.getListSuitType()),
  getListJudgment: () => dispatch(actionsApp.getListJudgment()),
  getListTypeContract: () => dispatch(actionsApp.getListTypeContract()),
  getStatistic: () => dispatch(actionsApp.getStatistic()),
  submitRegisterForm: (values) => dispatch(actionsApp.submitRegisterForm(values)),
});
class Settings extends Component {
  constructor(props) {
    super(props);
    props.getUser(props.account.id);
    props.getListSuitType();
    props.getListTypeContract();
    props.getListJudgment();
    props.getStatistic();
    props.changeActiveItemSideBar(10, 'الإعدادت');
    document.title = 'الإعدادت';
    this.state = {
      item: isHasPermission(props.account.login_access.permissions, 'قراءة-المستخدمين')
        ? 0
        : 1,
      showInfo: isHasPermission(props.account.login_access.permissions, 'إضافة-الإعدادت')
        ? true
        : false,
      showA7kem: isHasPermission(props.account.login_access.permissions, 'إضافة-الإعدادت')
        ? false
        : true,
      //  showA7kem: false,
      showDa3awi: isHasPermission(
        props.account.login_access.permissions,
        'إضافة-الإعدادت',
      )
        ? false
        : true,
      showContact: isHasPermission(
        props.account.login_access.permissions,
        'إضافة-الإعدادت',
      )
        ? false
        : true,
      show3ou9oud: isHasPermission(
        props.account.login_access.permissions,
        'إضافة-الإعدادت',
      )
        ? false
        : true,
      showPassword: isHasPermission(
        props.account.login_access.permissions,
        'إضافة-الإعدادت',
      )
        ? false
        : true,
      showSecretary: isHasPermission(
        props.account.login_access.permissions,
        'قراءة-المستخدمين',
      )
        ? true
        : false,
      showStatistic: isHasPermission(
        props.account.login_access.permissions,
        'إضافة-الإعدادت',
      )
        ? false
        : true,
      showArchive: isHasPermission(
        props.account.login_access.permissions,
        'إضافة-الإعدادت',
      )
        ? false
        : true,
    };
    this.goBack = this.goBack.bind(this);
    this.HandelChangePage = this.HandelChangePage.bind(this);
  }

  HandelChangePage(item) {
    this.setState({
      item: item,
    });
    switch (item) {
      case 8:
        this.props.getActivityRegister(this.props.paramsActivityRegister);
        this.setState({
          showInfo: false,
          showA7kem: false,
          showDa3awi: false,
          show3ou9oud: false,
          showPassword: false,
          showSecretary: false,
          showStatistic: false,
          showContact: false,
          showArchive: true,
        });
        break;
      case 0:
        this.props.getUser(this.props.account.id);
        this.setState({
          showArchive: false,
          showInfo: true,
          showA7kem: false,
          showDa3awi: false,
          show3ou9oud: false,
          showPassword: false,
          showSecretary: false,
          showStatistic: false,
          showContact: false,
        });
        break;
      case 1:
        this.setState({
          showArchive: false,
          showInfo: false,
          showA7kem: true,
          showDa3awi: false,
          show3ou9oud: false,
          showPassword: false,
          showSecretary: false,
          showStatistic: false,
          showContact: false,
        });
        break;
      case 2:
        this.props.getListSuitType();
        this.setState({
          showArchive: false,
          showInfo: false,
          showA7kem: false,
          showDa3awi: true,
          show3ou9oud: false,
          showPassword: false,
          showSecretary: false,
          showStatistic: false,
          showContact: false,
        });
        break;
      case 3:
        this.props.getListTypeContract();
        this.setState({
          showInfo: false,
          showA7kem: false,
          showDa3awi: false,
          show3ou9oud: true,
          showPassword: false,
          showSecretary: false,
          showStatistic: false,
          showContact: false,
          showArchive: false,
        });
        break;
      case 4:
        this.setState({
          showInfo: false,
          showA7kem: false,
          showDa3awi: false,
          show3ou9oud: false,
          showPassword: true,
          showSecretary: false,
          showStatistic: false,
          showContact: false,
          showArchive: false,
        });
        break;
      case 5:
        this.props.getSecretaries();
        this.setState({
          showInfo: false,
          showA7kem: false,
          showDa3awi: false,
          show3ou9oud: false,
          showPassword: false,
          showSecretary: true,
          showStatistic: false,
          showContact: false,
          showArchive: false,
        });
        break;
      case 6:
        this.props.getStatistic();
        this.props.getLawyerTraineeShip();
        this.setState({
          showInfo: false,
          showA7kem: false,
          showDa3awi: false,
          show3ou9oud: false,
          showPassword: false,
          showSecretary: false,
          showStatistic: true,
          showContact: false,
          showArchive: false,
        });
        break;
      case 7:
        this.setState({
          showInfo: false,
          showA7kem: false,
          showDa3awi: false,
          show3ou9oud: false,
          showPassword: false,
          showSecretary: false,
          showStatistic: false,
          showContact: true,
          showArchive: false,
        });
        break;
      default:
        this.setState({
          showInfo: true,
          showA7kem: false,
          showDa3awi: false,
          show3ou9oud: false,
          showPassword: false,
          showSecretary: false,
          showContact: false,
          showArchive: false,
        });
    }
  }
  goBack() {
    this.props.history.goBack();
  }
  render() {
    const {
      user,
      judgments,
      isLoadingJudgments,
      isLoadingUser,
      isLoadingSuitTypes,
      suitTypes,
      typeContracts,
      account,
      statisticData,
      isLoadingStatistic,
      isLoadingRegister,
      isLoadingActivityRegister,
      activityRegister,
      lawyerTraineeShip,
      register,
      paramsActivityRegister,
    } = this.props;
    const {
      item,
      showInfo,
      showSecretary,
      showPassword,
      show3ou9oud,
      showDa3awi,
      showA7kem,
      showStatistic,
      showContact,
      showArchive,
    } = this.state;
    if (isLoadingUser) {
      return (
        <div className="params_main">
          <CircularProgress
            style={{
              textAlign: 'center',
              width: '30px',
              height: '30px',
              marginTop: '20%',
              marginRight: '50%',
            }}
          />
        </div>
      );
    }
    return (
      <div className="params_main">
        <div className="params_middle">
          <div className="params_middle__wrapper">
            <div className="params_col-right">
              <div className="params_cards">
                {isHasPermission(account.login_access.permissions, 'إضافة-الإعدادت') ? (
                  <>
                    <div
                      onClick={() => this.HandelChangePage(0)}
                      className={
                        item === 0
                          ? 'params_card params_card_personal_details params_highlight'
                          : 'params_card params_card_personal_details'
                      }
                    >
                      <div className="params_card__text">معطيات المستخدم</div>
                    </div>
                  </>
                ) : (
                  ''
                )}
                {(account.pack !== 'beginner' ||
                  (account.lawyer && account.lawyer.pack !== 'beginner')) &&
                (account.pack !== 'traineeship' ||
                  (account.lawyer && account.lawyer.pack !== 'traineeship')) &&
                isHasPermission(account.login_access.permissions, 'قراءة-المستخدمين') ? (
                  <div
                    className={
                      item === 5
                        ? 'params_card params_card_ltype params_highlight'
                        : 'params_card params_card_ltype'
                    }
                    onClick={() => this.HandelChangePage(5)}
                  >
                    <div className="params_card__text">المستخدمين</div>
                  </div>
                ) : (
                  ''
                )}
                {isHasPermission(account.login_access.permissions, 'إضافة-الإعدادت') ? (
                  <>
                    <div
                      className={
                        item === 6
                          ? 'params_card params_card_jtype params_highlight'
                          : 'params_card params_card_jtype'
                      }
                      onClick={() => this.HandelChangePage(6)}
                    >
                      <div className="params_card__text">الإحصائيات</div>
                    </div>
                    {account.pack === 'traineeship' ||
                    (account.lawyer && account.lawyer.pack === 'traineeship') ? (
                      ''
                    ) : (
                      <div
                        className={
                          item === 1
                            ? 'params_card params_card_jtype params_highlight'
                            : 'params_card params_card_jtype'
                        }
                        onClick={() => this.HandelChangePage(1)}
                      >
                        <div className="params_card__text">الاشتركات</div>
                      </div>
                    )}

                    <div
                      className={
                        item === 4
                          ? 'params_card params_card_ltype params_highlight'
                          : 'params_card params_card_ltype'
                      }
                      onClick={() => this.HandelChangePage(4)}
                    >
                      <div className="params_card__text">تحديث كلمة المرور</div>
                    </div>
                  </>
                ) : (
                  ''
                )}
                <div
                  className={
                    item === 2
                      ? 'params_card params_card_ltype params_highlight'
                      : 'params_card params_card_ltype'
                  }
                  onClick={() => this.HandelChangePage(2)}
                >
                  <div className="params_card__text">أنواع القضايا</div>
                </div>
                <div
                  className={
                    item === 3
                      ? 'params_card params_card_ctype params_highlight'
                      : 'params_card params_card_ctype'
                  }
                  onClick={() => this.HandelChangePage(3)}
                >
                  <div className="params_card__text">
                    <div>أنواع العقود</div>
                  </div>
                </div>
                <div
                  className={
                    item === 8
                      ? 'params_card params_card_ltype params_highlight'
                      : 'params_card params_card_ltype'
                  }
                  onClick={() => this.HandelChangePage(8)}
                >
                  <div className="params_card__text">سجل النشاط</div>
                </div>
                <div
                  className={
                    item === 7
                      ? 'params_card params_card_ctype params_highlight'
                      : 'params_card params_card_ctype'
                  }
                  onClick={() => this.HandelChangePage(7)}
                >
                  <div className="params_card__text">
                    <div>تواصل معنا</div>
                  </div>
                </div>
              </div>
            </div>
            {showInfo &&
            isHasPermission(account.login_access.permissions, 'إضافة-الإعدادت') ? (
              <div className="params_user_details">
                <Info
                  changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                  goBack={this.goBack}
                  user={user}
                />
              </div>
            ) : showDa3awi ? (
              <SuitType
                changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                isLoadingSuitTypes={isLoadingSuitTypes}
                suitTypes={suitTypes}
              />
            ) : show3ou9oud ? (
              <Contract
                changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                isLoadingSuitTypes={isLoadingSuitTypes}
                typeContracts={typeContracts}
              />
            ) : showStatistic ? (
              <Statistic
                changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                isLoadingStatistic={isLoadingStatistic}
                statisticData={statisticData}
                lawyerTraineeShip={lawyerTraineeShip}
              />
            ) : isHasPermission(account.login_access.permissions, 'إضافة-الإعدادت') &&
              showPassword ? (
              <div className="params_user_details">
                <Password
                  changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                  goBack={this.goBack}
                  user={user}
                />
              </div>
            ) : isHasPermission(account.login_access.permissions, 'إضافة-الإعدادت') &&
              showA7kem ? (
              <div className="params_user_details">
                <Payments
                  account={account}
                  changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                  judgments={judgments}
                  isLoadingJudgments={isLoadingJudgments}
                />
              </div>
            ) : (account.pack !== 'beginner' ||
                (account.lawyer && account.lawyer.pack !== 'beginner')) &&
              (account.pack !== 'traineeship' ||
                (account.lawyer && account.lawyer.pack !== 'traineeship')) &&
              isHasPermission(account.login_access.permissions, 'قراءة-المستخدمين') &&
              showSecretary ? (
              <div style={{ backgroundColor: '#eeeeee' }} className="params_user_details">
                <Secretary
                  changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                  goBack={this.goBack}
                  account={account}
                  user={user}
                />
              </div>
            ) : showContact ? (
              <div style={{ backgroundColor: '#eeeeee' }} className="params_user_details">
                <Contact
                  changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                  submitRegisterForm={this.props.submitRegisterForm}
                  isLoadingRegister={isLoadingRegister}
                  account={account}
                  register={register}
                />
              </div>
            ) : showArchive ? (
              <div style={{ backgroundColor: '#eeeeee' }} className="params_user_details">
                <ActivityRegister
                  changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                  changeSearchedStringActivityRegister={
                    this.props.changeSearchedStringActivityRegister
                  }
                  isLoadingActivityRegister={isLoadingActivityRegister}
                  activityRegister={activityRegister}
                  paramsActivityRegister={paramsActivityRegister}
                />
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Settings));
