import React, { Component } from 'react';
import {
  Navbar,
  Nav,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Container,
  Collapse,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import LogoSmall from '../../assets/img/logo.png';

class NavbarAuth extends Component {
  // render() {
  constructor(props) {
    super(props);
    this.state = {
      isOpenMenu: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle = () => {
    this.setState({ isOpenMenu: !this.state.isOpenMenu });
  };

  render() {
    let targetId = this.props.navItems.map((item) => {
      return item.idnm;
    });
    return (
      <React.Fragment>
        <Navbar
          style={{ top: '1%' }}
          expand="lg"
          fixed={this.props.top === true ? 'top' : ''}
          className={this.props.navClass + ' navbar-custom sticky sticky-dark'}
          id="navbar"
        >
          <Container>
            {/* LOGO */}
            <Link className="navbar-brand logo text-uppercase" to="/">
              {this.props.imglight === true ? (
                <img src={LogoSmall} alt="" height="50" />
              ) : (
                <img src={LogoSmall} alt="" height="50" />
              )}
            </Link>
            <NavbarToggler onClick={this.toggle}>
              <i className="mdi mdi-menu"></i>
            </NavbarToggler>
            <Collapse
              id="navbarCollapse"
              isOpen={this.state.isOpenMenu}
              className=" navbar-collapse"
            >
              <Nav className="navbar-nav ml-auto navbar-center" id="navbar-navlist">
                <NavItem className="">
                  <Link className="" to="/">
                    الصفحة الرئيسية
                  </Link>
                </NavItem>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </React.Fragment>
    );
  }
}
// }
export default NavbarAuth;
