import React, { Component } from 'react';

import { Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';

import HomeUrl from '../assets/images/home-border.png';
import Feature from '../assets/images/features/img-3.png';
import { firstNumberPhone, isNumeric, lengthPhone } from '../../../utils/helpres';
import { CircularProgress } from '@material-ui/core';

export default class Contact extends Component {
  constructor(props) {
    super(props);
    // props.getListAllLegalJournal();
    document.title = 'لافوكاتو';
    this.state = {
      initialValues: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        message: '',
      },
      hasError: false,
      openPopup: false,
    };
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { register } = this.props;
    if (register !== prevProps.register && register) {
      this.closeModal();
    }
  }
  handleSubmit(values) {
    if (
      !values.first_name ||
      !values.last_name ||
      !values.email ||
      !values.message ||
      !values.phone
    ) {
      this.setState({
        hasError: true,
      });
    } else {
      this.setState({
        hasError: false,
      });
      this.props.submitRegisterForm(values);
    }
  }
  render() {
    const { hasError, openPopup, initialValues } = this.state;

    return (
      <React.Fragment>
        <section className="section" id="contact">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="title-box text-center">
                  <h3 className="title-heading mt-4">تواصل معنا</h3>
                  {/*<p className="text-muted f-17 mt-3">
                    Vivamus ac nulla ultrices laoreet neque mollis mi morbi elementum
                    mauris sit amet arcu <br /> fringilla auctor In eleifend maximus nisi
                    sed vulputate.
                  </p>*/}
                  <img src={HomeUrl} height="15px" className="mt-3" alt="" />
                </div>
              </Col>
            </Row>
            <Row className="mt-5 pt-4">
              <Col lg={6}>
                <div className="mt-4 home-img text-center">
                  <div className="animation-2"></div>
                  <div className="animation-3"></div>
                  <img
                    style={{ marginTop: '95px' }}
                    src={Feature}
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className="custom-form mt-4">
                  <div id="message"></div>
                  {/*  <Form method="post" name="contact-form" id="contact-form">
                    <Row>
                      <Col lg={6}>
                        <FormGroup className="mt-3">
                          <Label className="contact-lable">الاسم</Label>
                          <Input
                            className={
                              hasError && !initialValues.first_name
                                ? 'form-control'
                                : 'form-control'
                            }
                            value={initialValues.first_name}
                            id="name"
                            onChange={(e) =>
                              this.setState({
                                hasError: false,
                                initialValues: {
                                  ...initialValues,
                                  first_name: e.target.value,
                                },
                              })
                            }
                            invalid={hasError && !initialValues.first_name}
                            valid={!hasError && initialValues.first_name}
                            type="text"
                            name="first_name"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={6}>
                        <FormGroup className="mt-3">
                          <Label className="contact-lable">اللقب</Label>
                          <Input
                            onChange={(e) =>
                              this.setState({
                                hasError: false,
                                initialValues: {
                                  ...initialValues,
                                  last_name: e.target.value,
                                },
                              })
                            }
                            invalid={hasError && !initialValues.last_name}
                            valid={!hasError && initialValues.last_name}
                            type="text"
                            name="last_name"
                            className={
                              hasError && !initialValues.last_name
                                ? 'form-control'
                                : 'form-control'
                            }
                            value={initialValues.last_name}
                            id="lastname"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <FormGroup className="mt-3">
                          <Label className="contact-lable">البريد الالكتروني</Label>
                          <Input
                            invalid={hasError && !initialValues.email}
                            valid={!hasError && initialValues.email}
                            onChange={(e) =>
                              this.setState({
                                hasError: false,
                                initialValues: {
                                  ...initialValues,
                                  email: e.target.value,
                                },
                              })
                            }
                            type="email"
                            className={
                              hasError && !initialValues.email
                                ? 'form-control'
                                : 'form-control'
                            }
                            value={initialValues.email}
                            name="email"
                            id="email"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={6}>
                        <FormGroup className="mt-3">
                          <Label className="contact-lable">الهاتف</Label>
                          <Input
                            invalid={hasError && !initialValues.phone}
                            valid={!hasError && initialValues.phone}
                            value={initialValues.phone}
                            onChange={(e) =>
                              this.setState({
                                hasError: false,
                                initialValues: {
                                  ...initialValues,
                                  phone: e.target.value,
                                },
                              })
                            }
                            type="text"
                            className={
                              hasError && !initialValues.phone
                                ? 'form-control'
                                : 'form-control'
                            }
                            name="phone"
                            id="email"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <FormGroup className="mt-3">
                          <Label className="contact-lable">رسالة</Label>
                          <Input
                            invalid={hasError && !initialValues.message}
                            valid={!hasError && initialValues.message}
                            onChange={(e) =>
                              this.setState({
                                hasError: false,
                                initialValues: {
                                  ...initialValues,
                                  message: e.target.value,
                                },
                              })
                            }
                            type="textarea"
                            className={
                              hasError && !initialValues.message
                                ? 'form-control'
                                : 'form-control'
                            }
                            name="message"
                            id="comments"
                            rows="5"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12} className="mt-3">
                        <Input
                          onClick={() => this.handleSubmit(initialValues)}
                          id="submit"
                          name="send"
                          color="primary"
                          className="submitBnt btn btn-primary btn-round"
                          value="تسجيل"
                          type="button"
                          style={{ width: 'auto', color: '#fff' }}
                        />{' '}
                        <div id="simple-msg"></div>
                      </Col>
                    </Row>
                  </Form>*/}
                  <div style={{ width: '100%' }}>
                    {/*  <div className="form-row">
                      <div className="form-group col-md-6">
                        <label
                          style={{ color: '#ca2946', fontSize: '15px' }}
                          htmlFor="name"
                        >
                          البريد الالكتروني
                        </label>
                        <label
                          style={{ color: 'black', fontSize: '15px' }}
                          htmlFor="name"
                        >
                          support@lavocato.com
                        </label>
                      </div>
                      <div className="form-group col-md-6">
                        <label
                          style={{ color: '#ca2946', fontSize: '15px' }}
                          htmlFor="name"
                        >
                          الهاتف
                        </label>
                        <label
                          style={{ color: 'black', fontSize: '15px' }}
                          htmlFor="name"
                        >
                          088 579 56 (216+)
                        </label>
                      </div>
                    </div>*/}
                    <form method="post" role="form" className="php-email-form">
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label style={{ fontSize: '15px' }} htmlFor="name">
                            الاسم
                          </label>
                          <input
                            type="text"
                            name="first_name"
                            onChange={(e) =>
                              this.setState({
                                hasError: false,
                                initialValues: {
                                  ...initialValues,
                                  first_name: e.target.value,
                                },
                              })
                            }
                            className={
                              hasError && !initialValues.first_name
                                ? 'form-control error'
                                : 'form-control'
                            }
                            value={initialValues.first_name}
                            id="name"
                            data-rule="minlen:4"
                            data-msg="Please enter at least 4 chars"
                          />
                          <div className="validate"></div>
                        </div>
                        <div className="form-group col-md-6">
                          <label style={{ fontSize: '15px' }} htmlFor="name">
                            اللقب
                          </label>
                          <input
                            type="text"
                            name="last_name"
                            onChange={(e) =>
                              this.setState({
                                hasError: false,
                                initialValues: {
                                  ...initialValues,
                                  last_name: e.target.value,
                                },
                              })
                            }
                            className={
                              hasError && !initialValues.last_name
                                ? 'form-control error'
                                : 'form-control'
                            }
                            value={initialValues.last_name}
                            id="name"
                            data-rule="minlen:4"
                            data-msg="Please enter at least 4 chars"
                          />
                          <div className="validate"></div>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label style={{ fontSize: '15px' }} htmlFor="name">
                            البريد الالكتروني
                          </label>
                          <input
                            type="email"
                            className={
                              hasError && !initialValues.email
                                ? 'form-control error'
                                : 'form-control'
                            }
                            onChange={(e) =>
                              this.setState({
                                hasError: false,
                                initialValues: {
                                  ...initialValues,
                                  email: e.target.value,
                                },
                              })
                            }
                            value={initialValues.email}
                            name="email"
                            id="subject"
                            data-rule="minlen:4"
                            data-msg="Please enter at least 8 chars of subject"
                          />
                          <div className="validate"></div>
                        </div>
                        <div className="form-group col-md-6">
                          <label style={{ fontSize: '15px' }} htmlFor="name">
                            الهاتف
                          </label>
                          <input
                            value={initialValues.phone}
                            type="text"
                            className={
                              (hasError && !initialValues.phone) ||
                              (initialValues.phone &&
                                !firstNumberPhone(initialValues.phone)) ||
                              (initialValues.phone &&
                                !lengthPhone(initialValues.phone)) ||
                              (initialValues.phone && !isNumeric(initialValues.phone))
                                ? 'form-control error'
                                : 'form-control'
                            }
                            onChange={(e) =>
                              this.setState({
                                hasError: false,
                                initialValues: {
                                  ...initialValues,
                                  phone: e.target.value,
                                },
                              })
                            }
                            name="phone"
                            id="email"
                            data-rule="email"
                            data-msg="Please enter a valid email"
                          />
                          <div className="validate"></div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label style={{ fontSize: '15px' }} htmlFor="name">
                          رسالة
                        </label>
                        <textarea
                          onChange={(e) =>
                            this.setState({
                              initialValues: {
                                ...initialValues,
                                message: e.target.value,
                              },
                            })
                          }
                          className={
                            hasError && !initialValues.message
                              ? 'form-control error'
                              : 'form-control'
                          }
                          name="message"
                          rows="10"
                          data-rule="required"
                          data-msg="Please write something for us"
                          value={initialValues.message}
                        ></textarea>
                        <div className="validate"></div>
                      </div>
                    </form>
                  </div>
                  <div style={{ display: 'flex' }} className="popup__btn_grp">
                    <button
                      onClick={() => this.handleSubmit(initialValues)}
                      disabled=""
                      style={{ width: '15%', background: '#63B17D' }}
                      type="button"
                    >
                      <>
                        <svg
                          className="tickSpan"
                          xmlns="http://www.w3.org/2000/svg"
                          width="20.125"
                          height="17.774"
                          viewBox="0 0 29.125 23.774"
                        >
                          <path
                            id="Path_92"
                            data-name="Path 92"
                            d="M1325.354,167.5l8.34,9.625,17.8-19.417"
                            transform="translate(-1323.842 -156.357)"
                            fill="none"
                            stroke="#f9f9f9"
                            strokeWidth="4"
                          ></path>
                        </svg>
                        تسجيل
                      </>
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
