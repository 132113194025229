import React, { useState } from 'react';
import { host } from '../../../utils/constants';
import { diffDate } from '../../../utils/helpres';
import axios from 'axios';

const Message = ({ message, account, destroyMessage }) => {
  const [openModal, setOpenModal] = useState(false);
  let isSentByCurrentUser = false;
  if (account.id === message.sender_id) {
    isSentByCurrentUser = true;
  }
  return (
    <>
      {isSentByCurrentUser && account.id !== message.deleted_from ? (
        <div
          // title={diffDate(message.created_at) ? diffDate(message.created_at) : ''}
          style={{ textAlign: 'start' }}
          className="msg-sent"
        >
          {message.type_message === 'text' ? (
            <div className="msg-content">{message.content}</div>
          ) : message.type_message === 'pdf' ? (
            <div
              onClick={() => {
                axios(`${host}${message.content}`, {
                  method: 'GET',
                  headers: {
                    Authorization: `JWT ${window.localStorage.getItem('token')}`,
                  },
                  responseType: 'blob', //Force to receive data in a Blob Format
                })
                  .then((response) => {
                    //Create a Blob from the PDF Stream
                    const file = new Blob([response.data], {
                      type: 'application/pdf',
                    });
                    //Build a URL from the file
                    const fileURL = URL.createObjectURL(file);
                    //Open the URL on new Window
                    window.open(fileURL, '_blank');
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }}
              /*target="_blank"
              href={`${host}${message.content}`}*/
            >
              {message.content.split('/')[message.content.split('/').length - 1]}
            </div>
          ) : (
            <div
              onClick={() => {
                axios(`${host}${message.content}`, {
                  method: 'GET',
                  headers: {
                    Authorization: `JWT ${window.localStorage.getItem('token')}`,
                  },
                  responseType: 'blob', //Force to receive data in a Blob Format
                })
                  .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                      'download',
                      message.content.split('/')[message.content.split('/').length - 1],
                    ); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }}
              /*target="_blank"
              href={`${host}${message.content}`}*/
            >
              {message.content.split('/')[message.content.split('/').length - 1]}
            </div>
          )}
          {account.id === message.sender_id ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15.906"
              height="25.74"
              viewBox="0 0 20.906 25.74"
              onClick={() => setOpenModal(true)}
              style={{ marginRight: '5%', marginTop: '2%', cursor: 'pointer' }}
            >
              <g id="delete" transform="translate(0 0)">
                <path
                  id="Path_82"
                  data-name="Path 82"
                  d="M223,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,223,154.7Zm0,0"
                  transform="translate(-208.991 -145.377)"
                  fill="#868686"
                ></path>
                <path
                  id="Path_83"
                  data-name="Path 83"
                  d="M105,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,105,154.7Zm0,0"
                  transform="translate(-98.105 -145.377)"
                  fill="#868686"
                ></path>
                <path
                  id="Path_84"
                  data-name="Path 84"
                  d="M1.709,7.662V22.514a3.326,3.326,0,0,0,.884,2.294,2.969,2.969,0,0,0,2.154.931H16.152a2.968,2.968,0,0,0,2.154-.931,3.326,3.326,0,0,0,.884-2.294V7.662A2.3,2.3,0,0,0,18.6,3.133H15.513V2.38A2.368,2.368,0,0,0,13.126,0H7.773A2.368,2.368,0,0,0,5.386,2.38v.754H2.3a2.3,2.3,0,0,0-.591,4.528ZM16.152,24.533H4.747a1.909,1.909,0,0,1-1.832-2.019V7.715h15.07v14.8a1.909,1.909,0,0,1-1.832,2.019ZM6.592,2.38A1.161,1.161,0,0,1,7.773,1.2h5.353A1.161,1.161,0,0,1,14.308,2.38v.754H6.592ZM2.3,4.339H18.6a1.085,1.085,0,0,1,0,2.17H2.3a1.085,1.085,0,0,1,0-2.17Zm0,0"
                  transform="translate(0.003 0.002)"
                  fill="#868686"
                ></path>
                <path
                  id="Path_85"
                  data-name="Path 85"
                  d="M164,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,164,154.7Zm0,0"
                  transform="translate(-153.548 -145.377)"
                  fill="#868686"
                ></path>
              </g>
            </svg>
          ) : (
            ''
          )}
          {diffDate(message.created_at) ? (
            <span
              style={{ marginRight: '5%', marginTop: '2%', cursor: 'pointer' }}
              class="tooltiptext"
            >
              {diffDate(message.created_at)}
            </span>
          ) : (
            ''
          )}
        </div>
      ) : account.id !== message.deleted_from ? (
        <div
          title={diffDate(message.created_at) ? diffDate(message.created_at) : ''}
          style={{ textAlign: 'end' }}
          className="msg-received"
        >
          {message.type_message === 'text' ? (
            <div className="msg-content">{message.content}</div>
          ) : message.type_message === 'pdf' ? (
            <div
              onClick={() => {
                axios(`${host}${message.content}`, {
                  method: 'GET',
                  headers: {
                    Authorization: `JWT ${window.localStorage.getItem('token')}`,
                  },
                  responseType: 'blob', //Force to receive data in a Blob Format
                })
                  .then((response) => {
                    //Create a Blob from the PDF Stream
                    const file = new Blob([response.data], {
                      type: 'application/pdf',
                    });
                    //Build a URL from the file
                    const fileURL = URL.createObjectURL(file);
                    //Open the URL on new Window
                    window.open(fileURL, '_blank');
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }}
              /*target="_blank"
              href={`${host}${message.content}`}*/
            >
              {message.content.split('/')[message.content.split('/').length - 1]}
            </div>
          ) : (
            <div
              onClick={() => {
                axios(`${host}${message.content}`, {
                  method: 'GET',
                  headers: {
                    Authorization: `JWT ${window.localStorage.getItem('token')}`,
                  },
                  responseType: 'blob', //Force to receive data in a Blob Format
                })
                  .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                      'download',
                      message.content.split('/')[message.content.split('/').length - 1],
                    ); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }}
              /*target="_blank"
              href={`${host}${message.content}`}*/
            >
              {message.content.split('/')[message.content.split('/').length - 1]}
            </div>
          )}
          {account.id === message.sender_id ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15.906"
              height="25.74"
              viewBox="0 0 20.906 25.74"
              style={{ marginLeft: '5%', marginTop: '2%', cursor: 'pointer' }}
              onClick={() => setOpenModal(true)}
            >
              <g id="delete" transform="translate(0 0)">
                <path
                  id="Path_82"
                  data-name="Path 82"
                  d="M223,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,223,154.7Zm0,0"
                  transform="translate(-208.991 -145.377)"
                  fill="#868686"
                ></path>
                <path
                  id="Path_83"
                  data-name="Path 83"
                  d="M105,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,105,154.7Zm0,0"
                  transform="translate(-98.105 -145.377)"
                  fill="#868686"
                ></path>
                <path
                  id="Path_84"
                  data-name="Path 84"
                  d="M1.709,7.662V22.514a3.326,3.326,0,0,0,.884,2.294,2.969,2.969,0,0,0,2.154.931H16.152a2.968,2.968,0,0,0,2.154-.931,3.326,3.326,0,0,0,.884-2.294V7.662A2.3,2.3,0,0,0,18.6,3.133H15.513V2.38A2.368,2.368,0,0,0,13.126,0H7.773A2.368,2.368,0,0,0,5.386,2.38v.754H2.3a2.3,2.3,0,0,0-.591,4.528ZM16.152,24.533H4.747a1.909,1.909,0,0,1-1.832-2.019V7.715h15.07v14.8a1.909,1.909,0,0,1-1.832,2.019ZM6.592,2.38A1.161,1.161,0,0,1,7.773,1.2h5.353A1.161,1.161,0,0,1,14.308,2.38v.754H6.592ZM2.3,4.339H18.6a1.085,1.085,0,0,1,0,2.17H2.3a1.085,1.085,0,0,1,0-2.17Zm0,0"
                  transform="translate(0.003 0.002)"
                  fill="#868686"
                ></path>
                <path
                  id="Path_85"
                  data-name="Path 85"
                  d="M164,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,164,154.7Zm0,0"
                  transform="translate(-153.548 -145.377)"
                  fill="#868686"
                ></path>
              </g>
            </svg>
          ) : (
            ''
          )}
          {diffDate(message.created_at) ? (
            <span
              style={{
                marginLeft: '5%',
                marginRight: '5%',
                marginTop: '2%',
                cursor: 'pointer',
              }}
              class="tooltiptext"
            >
              {diffDate(message.created_at)}
            </span>
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}
      <div className={openModal ? 'popup active' : 'popup'}>
        <div className="popup__title">أنت بصدد حذف الرسالة</div>
        <div className="popup__btn_grp">
          <button onClick={() => setOpenModal(false)} className="cancel_popup">
            <span>الغاء</span>
            <span>&lt;</span>
          </button>
          <button
            onClick={() => {
              destroyMessage(message.id);
              setOpenModal(false);
            }}
          >
            <span>تأكيد</span>
            <span className="trashSpan">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20.906"
                height="25.74"
                viewBox="0 0 20.906 25.74"
              >
                <g id="delete" transform="translate(0.003 0.002)">
                  <path
                    id="Path_82"
                    data-name="Path 82"
                    d="M223,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,223,154.7Zm0,0"
                    transform="translate(-208.995 -145.379)"
                    fill="#fff"
                  />
                  <path
                    id="Path_83"
                    data-name="Path 83"
                    d="M105,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,105,154.7Zm0,0"
                    transform="translate(-98.108 -145.379)"
                    fill="#fff"
                  />
                  <path
                    id="Path_84"
                    data-name="Path 84"
                    d="M1.709,7.662V22.514a3.326,3.326,0,0,0,.884,2.294,2.969,2.969,0,0,0,2.154.931H16.152a2.968,2.968,0,0,0,2.154-.931,3.326,3.326,0,0,0,.884-2.294V7.662A2.3,2.3,0,0,0,18.6,3.133H15.513V2.38A2.368,2.368,0,0,0,13.126,0H7.773A2.368,2.368,0,0,0,5.386,2.38v.754H2.3a2.3,2.3,0,0,0-.591,4.528ZM16.152,24.533H4.747a1.909,1.909,0,0,1-1.832-2.019V7.715h15.07v14.8a1.909,1.909,0,0,1-1.832,2.019ZM6.592,2.38A1.161,1.161,0,0,1,7.773,1.2h5.353A1.161,1.161,0,0,1,14.308,2.38v.754H6.592ZM2.3,4.339H18.6a1.085,1.085,0,0,1,0,2.17H2.3a1.085,1.085,0,0,1,0-2.17Zm0,0"
                    transform="translate(0 0)"
                    fill="#fff"
                  />
                  <path
                    id="Path_85"
                    data-name="Path 85"
                    d="M164,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,164,154.7Zm0,0"
                    transform="translate(-153.551 -145.379)"
                    fill="#fff"
                  />
                </g>
              </svg>
            </span>
          </button>
        </div>
      </div>
      <div id="overlay" className={openModal ? 'active' : ''}></div>
    </>
  );
};

export default Message;
