const initialLawsuitState = {
  addLegalJournal: null,
  isLoadingAddLegalJournal: false,
  isLoadingDeleteLegalJournal: false,
  deleteLegalJournal: false,
  legalJournals: [],
  isLoadingLegalJournals: false,
  paramsLegalJournals: {
    currentPage: 1,
    limit: 5,
  },
  allLegalJournals: [],
  isLoadingAllLegalJournals: false,
};

const LegalJournalReducer = (state = initialLawsuitState, action) => {
  switch (action.type) {
    case 'GET_ALL_LEGAL_JOURNALS_REQUESTING':
      return {
        ...state,
        isLoadingAllLegalJournals: true,
      };
    case 'GET_ALL_LEGAL_JOURNALS_SUCCESS':
      return {
        ...state,
        isLoadingAllLegalJournals: false,
        allLegalJournals: action.allLegalJournals,
      };
    case 'GET_ALL_LEGAL_JOURNALS_FAILURE':
      return {
        ...state,
        isLoadingAllLegalJournals: false,
        allLegalJournals: null,
      };

    case 'UPDATE_SEARCH_LEGAL_JOURNALS_PARAMS':
      return {
        ...state,
        paramsLegalJournals: action.paramsLegalJournals,
      };
    case 'GET_LEGAL_JOURNALS_REQUESTING':
      return {
        ...state,
        isLoadingLegalJournals: true,
      };
    case 'GET_LEGAL_JOURNALS_SUCCESS':
      return {
        ...state,
        isLoadingLegalJournals: false,
        legalJournals: action.legalJournals,
      };
    case 'GET_LEGAL_JOURNALS_FAILURE':
      return {
        ...state,
        isLoadingLegalJournals: false,
        legalJournals: null,
      };
    case 'ACTION_DELETE_LEGAL_JOURNAL_REQUESTING':
      return {
        ...state,
        isLoadingDeleteLegalJournal: true,
      };
    case 'ACTION_DELETE_LEGAL_JOURNAL_SUCCESS':
      return {
        ...state,
        isLoadingDeleteLegalJournal: false,
        deleteLegalJournal: action.deleteLegalJournal,
      };
    case 'ACTION_DELETE_LEGAL_JOURNAL_FAILURE':
      return {
        ...state,
        isLoadingDeleteLegalJournal: false,
        deleteLegalJournal: null,
      };
    case 'UPDATE_LEGAL_JOURNAL_REQUESTING':
      return {
        ...state,
        isLoadingAddLegalJournal: true,
      };
    case 'UPDATE_LEGAL_JOURNAL_SUCCESS':
      return {
        ...state,
        isLoadingAddLegalJournal: false,
        addLegalJournal: action.addLegalJournal,
      };
    case 'UPDATE_LEGAL_JOURNAL_FAILURE':
      return {
        ...state,
        isLoadingAddLegalJournal: false,
        addLegalJournal: null,
      };
    default:
      return state;
  }
};

export default LegalJournalReducer;
