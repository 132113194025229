import React, { Component } from 'react';
import actions from '../store/actions';
import { connect } from 'react-redux';
import InputField from '../../../components/inputField';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { host } from '../../../utils/constants';
import SimpleReactValidator from 'simple-react-validator';
import { validPassword } from '../../../utils/helpres';

const mapStateToProps = (state) => {
  return {
    isLoadingChangePassword: state.SettingsReducer.isLoadingChangePassword,
    changePassword: state.SettingsReducer.changePassword,
    account: state.AppReducer.account,
  };
};
const mapDispatchToProps = (dispatch) => ({
  updatePassword: (values) => dispatch(actions.updatePassword(values)),
});
class Password extends Component {
  constructor(props) {
    super(props);
    document.title = 'تحديث كلمة المرور';
    props.changeActiveItemSideBar(10, 'تحديث كلمة المرور');
    this.state = {
      isOpen: false,
      hasError: false,
      hasErrorNewPassword: false,
      showNewPassword: false,
      showRepeatPassword: false,
      showOldPassword: false,
      initialValues: {
        id: props.user.id,
        old_password: '',
        new_password: '',
        confirm_password: '',
      },
    };
    this.submitForm = this.submitForm.bind(this);
    this.validator = new SimpleReactValidator({
      messages: {
        email: 'Adresse e-mail invalide',
        min: 'Saisissez un mot de passe comprenant au moins 6 caractères.',
        max: 'Le mot de passe doit comporter de 6 à 20 caractères. ',
        // OR
        default: 'Champ requise.', // will override all messenger
      },
    });
  }
  handleShowNewPassword() {
    this.setState({
      showNewPassword: !this.state.showNewPassword,
    });
  }

  handleShowOldPassword() {
    this.setState({
      showOldPassword: !this.state.showOldPassword,
    });
  }

  handleShowRepeatPassword() {
    this.setState({
      showRepeatPassword: !this.state.showRepeatPassword,
    });
  }

  submitForm(initialValues) {
    if (!this.validator.allValid()) {
      this.setState({
        ...initialValues,
        hasError: true,
      });
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    } else if (
      !initialValues.old_password ||
      !initialValues.new_password ||
      !initialValues.confirm_password
    ) {
      this.setState({
        ...initialValues,
        hasError: true,
      });
    } else if (
      initialValues.old_password &&
      initialValues.new_password.localeCompare(initialValues.confirm_password)
    ) {
      this.setState({
        ...initialValues,
        hasErrorNewPassword: true,
      });
    } else {
      if (
        initialValues.old_password &&
        initialValues.new_password &&
        initialValues.confirm_password
      )
        this.props.updatePassword(initialValues);
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { changePassword } = this.props;
    if (changePassword !== prevProps.changePassword && changePassword) {
      this.setState({
        isOpen: false,
        hasError: false,
        hasErrorNewPassword: false,
        showNewPassword: false,
        showRepeatPassword: false,
        showOldPassword: false,
        initialValues: {
          old_password: '',
          new_password: '',
          confirm_password: '',
        },
      });
    }
  }
  render() {
    const { initialValues, hasError, hasErrorNewPassword } = this.state;
    return (
      <form action="">
        <div className="params_basic_details">
          <div className="params_basic_feilds_section">
            <div style={{ width: '100%' }} className="param_basic_details__field">
              <label style={{ width: '25%' }} htmlFor="params_username">
                كلمة المرور القديمة
              </label>
              <InputField
                value={initialValues.old_password}
                name="old_password"
                placeholder="كلمة المرور القديمة"
                type="password"
                hasError={hasError}
                validInput="password"
                required={this.validator.message(
                  'old_password',
                  initialValues.old_password,
                  'required|string|min:8|max:20',
                )}
                onChange={(e) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      old_password: e.target.value,
                    },
                  });
                }}
              />
            </div>
            <div style={{ width: '100%' }} className="param_basic_details__field">
              <label style={{ width: '25%' }} htmlFor="params_username">
                كلمة المرور الجديدة
              </label>
              <InputField
                value={initialValues.new_password}
                name="new_password"
                placeholder="كلمة المرور الجديدة"
                type="password"
                hasError={hasError || hasErrorNewPassword}
                validInput="password"
                required={this.validator.message(
                  'new_password',
                  initialValues.new_password,
                  'required|string|min:8|max:20',
                )}
                onChange={(e) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      new_password: e.target.value,
                    },
                  });
                }}
              />
            </div>
            {initialValues.new_password && !validPassword(initialValues.new_password) ? (
              <div style={{ width: '100%' }} className="param_basic_details__field">
                <label style={{ width: '25%' }} htmlFor="params_username"></label>

                <span
                  style={{
                    textAlign: 'right',
                    fontSize: '11px',
                    color: 'rgb(189, 35, 60)',
                  }}
                >
                  {/*كلمة مرور غير صالح
                <br />*/}
                  8 أحرف
                  <br />
                  1 حرف كبير و 1 حرف صغير
                  <br />
                  رقم واحد
                  <br />
                  طابع خاص(@,#,...)
                </span>
              </div>
            ) : (
              ''
            )}
            <div style={{ width: '100%' }} className="param_basic_details__field">
              <label style={{ width: '25%' }} htmlFor="params_username">
                اعادة كلمة المرور الجديدة
              </label>
              <InputField
                value={initialValues.confirm_password}
                name="confirm_password"
                placeholder="اعادة كلمة المرور الجديدة"
                type="password"
                hasError={hasError}
                validInput="password"
                required={this.validator.message(
                  'confirm_password',
                  initialValues.confirm_password,
                  'required|string|min:8|max:20',
                )}
                onChange={(e) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      confirm_password: e.target.value,
                    },
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div style={{ marginBottom: '2rem' }} className="lawsuit_form__btn_grp">
          <button
            onClick={() => this.props.goBack()}
            type="button"
            className="lawsuit_cancel_session"
          >
            <svg
              className="lawsuit_ltSpan"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              xlinkHref="http://svgjs.com/svgjs"
              version="1.1"
              width="12"
              height="12"
              x="0"
              y="0"
              viewBox="0 0 492.004 492.004"
              style={{ enableBackground: 'new 0 0 512 512' }}
              xmlSpace="preserve"
            >
              <g>
                <g xmlns="http://www.w3.org/2000/svg">
                  <g>
                    <path
                      d="M382.678,226.804L163.73,7.86C158.666,2.792,151.906,0,144.698,0s-13.968,2.792-19.032,7.86l-16.124,16.12    c-10.492,10.504-10.492,27.576,0,38.064L293.398,245.9l-184.06,184.06c-5.064,5.068-7.86,11.824-7.86,19.028    c0,7.212,2.796,13.968,7.86,19.04l16.124,16.116c5.068,5.068,11.824,7.86,19.032,7.86s13.968-2.792,19.032-7.86L382.678,265    c5.076-5.084,7.864-11.872,7.848-19.088C390.542,238.668,387.754,231.884,382.678,226.804z"
                      fill="#2d2d2d"
                      data-original="#000000"
                      className=""
                    />
                  </g>
                </g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
              </g>
            </svg>
            الغاء
          </button>
          <button onClick={() => this.submitForm(initialValues)} type="button">
            <svg
              className="tickSpan"
              xmlns="http://www.w3.org/2000/svg"
              width="20.125"
              height="17.774"
              viewBox="0 0 29.125 23.774"
            >
              <path
                id="Path_92"
                data-name="Path 92"
                d="M1325.354,167.5l8.34,9.625,17.8-19.417"
                transform="translate(-1323.842 -156.357)"
                fill="none"
                stroke="#f9f9f9"
                strokeWidth="4"
              />
            </svg>
            تسجيل
          </button>
        </div>
      </form>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Password);
