const initialSessionState = {
  addSession: null,
  isLoadingAddSession: false,
  session: null,
  isLoadingSession: false,
  isLoadingDestroySession: false,
  destroySession: false,
  duplicateSession: null,
  isLoadingDuplicateSession: false,
};

const SessionReducer = (state = initialSessionState, action) => {
  switch (action.type) {
    case 'DUPLICATE_SESSION_REQUESTING':
      return {
        ...state,
        isLoadingDuplicateSession: true,
      };
    case 'DUPLICATE_SESSION_SUCCESS':
      return {
        ...state,
        isLoadingDuplicateSession: false,
        duplicateSession: action.session,
      };
    case 'DUPLICATE_SESSION_FAILURE':
      return {
        ...state,
        isLoadingDuplicateSession: false,
        duplicateSession: null,
      };
    case 'ACTION_DELETE_SESSION_REQUESTING':
      return {
        ...state,
        isLoadingDestroySession: true,
      };
    case 'ACTION_DELETE_SESSION_SUCCESS':
      return {
        ...state,
        isLoadingDestroySession: false,
        destroySession: true,
      };
    case 'ACTION_DELETE_SESSION_FAILURE':
      return {
        ...state,
        isLoadingDestroySession: false,
        destroySession: false,
      };
    case 'UPDATE_SESSION_REQUESTING':
      return {
        ...state,
        isLoadingAddSession: true,
      };
    case 'UPDATE_SESSION_SUCCESS':
      return {
        ...state,
        isLoadingAddSession: false,
        addSession: action.session,
      };
    case 'UPDATE_SESSION_FAILURE':
      return {
        ...state,
        isLoadingAddSession: false,
        addSession: null,
      };
    case 'GET_SESSION_REQUESTING':
      return {
        ...state,
        isLoadingSession: true,
      };
    case 'GET_SESSION_SUCCESS':
      return {
        ...state,
        isLoadingSession: false,
        session: action.session,
      };
    case 'GET_SESSION_FAILURE':
      return {
        ...state,
        isLoadingSession: false,
        session: null,
      };

    default:
      return state;
  }
};

export default SessionReducer;
