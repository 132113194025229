import React, { Component } from 'react';
import { CircularProgress } from '@material-ui/core';
import InputField from '../../../components/inputField';
import actions from '../store/actions';
import actionsApp from '../../../js/actions';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';

const mapStateToProps = (state) => ({
  isLoadingAddSuitType: state.SettingsReducer.isLoadingAddSuitType,
  addSuitType: state.SettingsReducer.addSuitType,
  isLoadingDeleteSuitType: state.SettingsReducer.isLoadingDeleteSuitType,
  destroySuitType: state.SettingsReducer.deleteSuitType,
});

const mapDispatchToProps = (dispatch) => ({
  submitSuitTypeForm: async (values) => {
    await dispatch(actions.submitSuitTypeForm(values));
    dispatch(actionsApp.getListSuitType());
  },
  deleteSuitType: async (id) => {
    await dispatch(actions.deleteSuitType(id));
    dispatch(actionsApp.getListSuitType());
  },
});
class SuitType extends Component {
  constructor(props) {
    super(props);
    document.title = 'أنواع القضايا';
    props.changeActiveItemSideBar(10, 'أنواع القضايا');
    this.state = {
      openDeletePopup: false,
      showBackground: false,
      hasError: false,
      selected_id: null,
      selected_name: '',
      selected_type: '',
    };
    this.closeModal = this.closeModal.bind(this);
    this.handleSelectedId = this.handleSelectedId.bind(this);
  }
  handleSelectedId(id, title, type) {
    this.setState({
      openDeletePopup: true,
      showBackground: true,
      selected_id: id,
      selected_name: title,
      selected_type: type,
    });
  }
  closeModal() {
    this.setState({
      openDeletePopup: false,
      showBackground: false,
      selected_id: null,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { addSuitType, destroySuitType } = this.props;
    if (addSuitType !== prevProps.addSuitType && addSuitType) {
      this.closeModal();
    }
    if (destroySuitType !== prevProps.destroySuitType && destroySuitType) {
      this.closeModal();
    }
  }

  render() {
    const { suitTypes, isLoadingSuitTypes } = this.props;
    const {
      openDeletePopup,
      selected_id,
      hasError,
      showBackground,
      selected_type,
      selected_name,
    } = this.state;
    return (
      <div style={{ display: 'block' }} className="params_col-left">
        {isLoadingSuitTypes ? (
          <CircularProgress
            style={{
              textAlign: 'center',
              width: '30px',
              height: '30px',
              marginTop: '20%',
              marginRight: '50%',
            }}
          />
        ) : (
          <>
            <div className="params_table_section__btn_grp">
              <button
                onClick={() => this.handleSelectedId(null, '', 'add')}
                className="params_add_button"
              >
                <svg
                  height="12"
                  viewBox="0 0 448 448"
                  width="12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m408 184h-136c-4.417969 0-8-3.582031-8-8v-136c0-22.089844-17.910156-40-40-40s-40 17.910156-40 40v136c0 4.417969-3.582031 8-8 8h-136c-22.089844 0-40 17.910156-40 40s17.910156 40 40 40h136c4.417969 0 8 3.582031 8 8v136c0 22.089844 17.910156 40 40 40s40-17.910156 40-40v-136c0-4.417969 3.582031-8 8-8h136c22.089844 0 40-17.910156 40-40s-17.910156-40-40-40zm0 0" />
                </svg>
                نوع جديد
              </button>
            </div>
            <div className="params_table_section">
              <div
                style={{ display: 'flex' }}
                className="params_table judgement_types_table"
              >
                <table>
                  <tbody>
                    {suitTypes && suitTypes.length > 0 ? (
                      suitTypes.map((suitType) => {
                        return (
                          <tr key={suitType.id}>
                            <td>{suitType.title}</td>
                            <td>
                              {suitType.lawyer_id ? (
                                <>
                                  <Tooltip title="حذف">
                                    <div className="params_table__del">
                                      <svg
                                        onClick={() =>
                                          this.handleSelectedId(
                                            suitType.id,
                                            suitType.title,
                                            'delete',
                                          )
                                        }
                                        className="params_center_svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20.906"
                                        height="25.74"
                                        viewBox="0 0 20.906 25.74"
                                      >
                                        <g id="delete" transform="translate(0 0)">
                                          <path
                                            id="Path_82"
                                            data-name="Path 82"
                                            d="M223,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,223,154.7Zm0,0"
                                            transform="translate(-208.991 -145.377)"
                                            fill="#ca2946"
                                          />
                                          <path
                                            id="Path_83"
                                            data-name="Path 83"
                                            d="M105,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,105,154.7Zm0,0"
                                            transform="translate(-98.105 -145.377)"
                                            fill="#ca2946"
                                          />
                                          <path
                                            id="Path_84"
                                            data-name="Path 84"
                                            d="M1.709,7.662V22.514a3.326,3.326,0,0,0,.884,2.294,2.969,2.969,0,0,0,2.154.931H16.152a2.968,2.968,0,0,0,2.154-.931,3.326,3.326,0,0,0,.884-2.294V7.662A2.3,2.3,0,0,0,18.6,3.133H15.513V2.38A2.368,2.368,0,0,0,13.126,0H7.773A2.368,2.368,0,0,0,5.386,2.38v.754H2.3a2.3,2.3,0,0,0-.591,4.528ZM16.152,24.533H4.747a1.909,1.909,0,0,1-1.832-2.019V7.715h15.07v14.8a1.909,1.909,0,0,1-1.832,2.019ZM6.592,2.38A1.161,1.161,0,0,1,7.773,1.2h5.353A1.161,1.161,0,0,1,14.308,2.38v.754H6.592ZM2.3,4.339H18.6a1.085,1.085,0,0,1,0,2.17H2.3a1.085,1.085,0,0,1,0-2.17Zm0,0"
                                            transform="translate(0.003 0.002)"
                                            fill="#ca2946"
                                          />
                                          <path
                                            id="Path_85"
                                            data-name="Path 85"
                                            d="M164,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,164,154.7Zm0,0"
                                            transform="translate(-153.548 -145.377)"
                                            fill="#ca2946"
                                          />
                                        </g>
                                      </svg>
                                    </div>
                                  </Tooltip>
                                  <Tooltip title="تحديث">
                                    <div>
                                      <svg
                                        onClick={() =>
                                          this.handleSelectedId(
                                            suitType.id,
                                            suitType.title,
                                            'edit',
                                          )
                                        }
                                        className="params_center_svg"
                                        id="Group_23"
                                        data-name="Group 23"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="23.304"
                                        height="24.288"
                                        viewBox="0 0 23.304 24.288"
                                      >
                                        <g id="Group_22" data-name="Group 22">
                                          <path
                                            id="Path_86"
                                            data-name="Path 86"
                                            d="M26.636,19.431h.131L33.1,18.053a.623.623,0,0,0,.328-.164L44.163,7.156A4.136,4.136,0,0,0,45.377,4.2a4.267,4.267,0,0,0-1.214-2.987A4.135,4.135,0,0,0,41.209,0a4.037,4.037,0,0,0-2.954,1.247l-10.7,10.733a.591.591,0,0,0-.2.3l-1.378,6.335a.722.722,0,0,0,.2.624A.656.656,0,0,0,26.636,19.431ZM41.209,1.314a2.855,2.855,0,0,1,2.856,2.855q0,.016,0,.033a2.757,2.757,0,0,1-.821,2l-4.037-4.07A2.823,2.823,0,0,1,41.209,1.314ZM38.287,3.086l4.037,4.037L32.97,16.444l-4.037-4ZM28.408,13.753,31.657,17l-4.168.919Z"
                                            transform="translate(-24.83 0)"
                                            fill="#ca2946"
                                          />
                                          <path
                                            id="Path_87"
                                            data-name="Path 87"
                                            d="M30.485,365.717H8.494a.656.656,0,0,0,0,1.313H30.485a.656.656,0,0,0,0-1.313Z"
                                            transform="translate(-7.838 -342.742)"
                                            fill="#ca2946"
                                          />
                                        </g>
                                      </svg>
                                    </div>
                                  </Tooltip>
                                </>
                              ) : (
                                ''
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td style={{ justifyContent: 'center' }} colSpan={2}>
                          لا يوجد سجل متاح
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
        <div className={openDeletePopup ? 'popup active' : 'popup'}>
          <div className="popup__title">
            {selected_type === 'delete' ? (
              <>
                أنت بصدد حذف نوع القضية <strong>{selected_name}</strong>
              </>
            ) : selected_type === 'edit' ? (
              <>
                أنت بصدد تعديل نوع القضية <strong>{selected_name}</strong>
              </>
            ) : (
              'أنت بصدد اضافة نوع جديد'
            )}
          </div>
          <div className="popup__item">
            <div className="popup__text">
              {selected_type === 'add' ? (
                <div style={{ flexDirection: 'row' }} className="lawsuit_form__sections">
                  <label htmlFor="attr">النوع</label>
                  <InputField
                    //onKeyPress={() => this.submitForm(initialValues)}
                    value={selected_name}
                    name="selected_name"
                    placeholder="النوع"
                    type="text"
                    hasError={hasError}
                    validInput="text"
                    onChange={(event) => {
                      this.setState({
                        selected_name: event.target.value,
                      });
                    }}
                  />
                </div>
              ) : selected_type === 'edit' ? (
                <div style={{ flexDirection: 'row' }} className="lawsuit_form__sections">
                  <label htmlFor="attr">النوع</label>
                  <InputField
                    //onKeyPress={() => this.submitForm(initialValues)}
                    value={selected_name}
                    name="selected_name"
                    placeholder="النوع"
                    type="text"
                    hasError={hasError}
                    validInput="text"
                    onChange={(event) => {
                      this.setState({
                        selected_name: event.target.value,
                      });
                    }}
                  />
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className="popup__btn_grp">
            <button onClick={() => this.closeModal()} className="cancel_popup">
              <span>الغاء</span>
              <span>&lt;</span>
            </button>
            <button
              style={{
                background:
                  selected_type === 'edit' || selected_type === 'add'
                    ? '#63B17D'
                    : '#CA2946',
              }}
              onClick={() => {
                if (selected_type === 'edit' || selected_type === 'add')
                  if (selected_name === '') {
                    this.setState({
                      hasError: true,
                    });
                  } else {
                    this.props.submitSuitTypeForm({
                      id: selected_id,
                      name: selected_name,
                    });
                  }
                else {
                  this.props.deleteSuitType(selected_id);
                }
              }}
            >
              <span>تأكيد</span>
              <span className="trashSpan">
                {selected_type === 'edit' || selected_type === 'add' ? (
                  <svg
                    className="tickSpan"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20.125"
                    height="17.774"
                    viewBox="0 0 29.125 23.774"
                  >
                    <path
                      id="Path_92"
                      data-name="Path 92"
                      d="M1325.354,167.5l8.34,9.625,17.8-19.417"
                      transform="translate(-1323.842 -156.357)"
                      fill="none"
                      stroke="#f9f9f9"
                      strokeWidth="4"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20.906"
                    height="25.74"
                    viewBox="0 0 20.906 25.74"
                  >
                    <g id="delete" transform="translate(0.003 0.002)">
                      <path
                        id="Path_82"
                        data-name="Path 82"
                        d="M223,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,223,154.7Zm0,0"
                        transform="translate(-208.995 -145.379)"
                        fill="#fff"
                      />
                      <path
                        id="Path_83"
                        data-name="Path 83"
                        d="M105,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,105,154.7Zm0,0"
                        transform="translate(-98.108 -145.379)"
                        fill="#fff"
                      />
                      <path
                        id="Path_84"
                        data-name="Path 84"
                        d="M1.709,7.662V22.514a3.326,3.326,0,0,0,.884,2.294,2.969,2.969,0,0,0,2.154.931H16.152a2.968,2.968,0,0,0,2.154-.931,3.326,3.326,0,0,0,.884-2.294V7.662A2.3,2.3,0,0,0,18.6,3.133H15.513V2.38A2.368,2.368,0,0,0,13.126,0H7.773A2.368,2.368,0,0,0,5.386,2.38v.754H2.3a2.3,2.3,0,0,0-.591,4.528ZM16.152,24.533H4.747a1.909,1.909,0,0,1-1.832-2.019V7.715h15.07v14.8a1.909,1.909,0,0,1-1.832,2.019ZM6.592,2.38A1.161,1.161,0,0,1,7.773,1.2h5.353A1.161,1.161,0,0,1,14.308,2.38v.754H6.592ZM2.3,4.339H18.6a1.085,1.085,0,0,1,0,2.17H2.3a1.085,1.085,0,0,1,0-2.17Zm0,0"
                        transform="translate(0 0)"
                        fill="#fff"
                      />
                      <path
                        id="Path_85"
                        data-name="Path 85"
                        d="M164,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,164,154.7Zm0,0"
                        transform="translate(-153.551 -145.379)"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                )}
              </span>
            </button>
          </div>
        </div>
        <div id="overlay" className={openDeletePopup ? 'active' : ''}></div>
      </div>
    );
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SuitType));
