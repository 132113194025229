import React, { Component } from 'react';
import Form from './containers/form';
import actions from './store/actions';
import actionsApp from '../../js/actions';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import actionsSettings from '../settings/store/actions';

const mapStateToProps = (state) => ({
  isLoadingTypeContract: state.AppReducer.isLoadingTypeContract,
  typeContracts: state.AppReducer.typeContracts,
  contract: state.ContractReducer.contract,
  isLoadingContract: state.ContractReducer.isLoadingContract,
  customersList: state.AppReducer.customersList,
  isLoadingCustomersLis: state.AppReducer.isLoadingCustomersLis,
  lastBill: state.AppReducer.lastBill,
  isLoadingLawyerTraineeShip: state.SettingsReducer.isLoadingLawyerTraineeShip,
  lawyerTraineeShip: state.SettingsReducer.lawyerTraineeShip,
});

const mapDispatchToProps = (dispatch) => ({
  getLastBill: () => dispatch(actionsApp.getLastBill()),
  getContractById: (id) => dispatch(actions.getContractById(id)),
  getListCustomerByLawyer: () => dispatch(actionsApp.getListCustomerByLawyer()),
  getListTypeContract: () => dispatch(actionsApp.getListTypeContract()),
  getLawyerTraineeShip: () => {
    dispatch(actionsSettings.getLawyerTraineeShip(true));
  },
});

class FormContract extends Component {
  constructor(props) {
    super(props);
    if (props.match.params && props.match.params.id) {
      props.changeActiveItemSideBar(11, 'تحديث العقد');
      document.title = 'تحديث العقد';
    } else {
      props.changeActiveItemSideBar(11, 'اضافة عقد');
      document.title = 'اضافة عقد';
    }
    props.getLawyerTraineeShip();
    props.getLastBill();
    props.getListTypeContract();
    props.getListCustomerByLawyer();
    props.getContractById(props.match.params && props.match.params.id);
    this.goBack = this.goBack.bind(this);
  }
  goBack() {
    this.props.history.goBack();
  }
  render() {
    const {
      contract,
      lawyerTraineeShip,
      customersList,
      lastBill,
      typeContracts,
      isLoadingContract,
    } = this.props;
    if (isLoadingContract) {
      return (
        <CircularProgress
          style={{
            textAlign: 'center',
            width: '30px',
            height: '30px',
            marginTop: '20%',
            marginRight: '50%',
          }}
        />
      );
    }
    return (
      <div style={{ display: 'flex', marginBottom: '5%' }} className="lawsuit_newLawsuit">
        <Form
          goBack={this.goBack}
          lawyerTraineeShip={lawyerTraineeShip}
          lastBill={lastBill}
          typeContracts={typeContracts}
          contract={contract}
          customersList={customersList}
        />
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(FormContract);
