import { getStatusError } from '../../../utils/helpres';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastAction from '../../../components/ToastAction';
import React from 'react';
import { apiCall } from '../../../js/actions';
import moment from 'moment-timezone';
const getAppealById = (id) => async (dispatch) => {
  dispatch({ type: 'GET_APPEAL_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'get',
      url: `/api/appeal/get-one/${id}`,
    });
    dispatch({ type: 'GET_APPEAL_SUCCESS', appeal: data.data });
  } catch (err) {
    const { response } = err;
    if (response)
      dispatch({
        type: 'GET_APPEAL_FAILURE',
        err: err.message,
        status: getStatusError(err),
      });
  }
};
const submitAppealForm = (values) => async (dispatch) => {
  dispatch({ type: 'UPDATE_APPEAL_REQUESTING' });
  const {
    judgment_id,
    court,
    requests,
    session_id,
    judgment_,
    date_appeal,
    date_judgment,
  } = values;
  let request = {
    date_appeal: moment(date_appeal).tz('UTC').utc().format('YYYY-MM-DD HH:mm:ss'),
    date_judgment: moment(date_judgment).tz('UTC').utc().format('YYYY-MM-DD HH:mm:ss'),
    judgment_id,
    requests,
    session_id,
    court,
    judgment_,
  };
  try {
    const { data } = await apiCall({
      method: values.id ? 'patch' : 'post',
      url: `/api/appeal/${values.id ? `update/${values.id}` : 'create'}`,
      data: request,
    });
    if (data.type === 'success') {
      if (values.file) {
        let formData = new FormData();
        formData.append('file', values.file);
        formData.append('type', 'appeal');
        formData.append('type_doc', 'documents');
        formData.append('id', data.data.id);
        await apiCall({
          method: 'post',
          url: `/api/upload`,
          data: formData,
        });
        toast(({ closeToast }) => <ToastAction type="success" message={data.message} />, {
          hideProgressBar: true,
        });
        dispatch({
          type: 'UPDATE_APPEAL_SUCCESS',
          appeal: data.data,
        });
      } else {
        toast(({ closeToast }) => <ToastAction type="success" message={data.message} />, {
          hideProgressBar: true,
        });
        dispatch({
          type: 'UPDATE_APPEAL_SUCCESS',
          appeal: data.data,
        });
      }
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      // toast.warn(data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_APPEAL_FAILURE',
        ErrorMessage: data.message,
        appeal: values,
        status: true,
      });
    }
  } catch (e) {
    const { response } = e;
    if (response) {
      const { data } = response;
      if (data.message) {
        toast(
          ({ closeToast }) => (
            <ToastAction type="error" message={response.data.message} />
          ),
          { hideProgressBar: true },
        );
      } else
        toast(
          ({ closeToast }) => (
            <ToastAction
              type="error"
              message="عفوًا ، لم يتم إنشاء الطعن ، يرجى إعادة المحاولة."
            />
          ),
          { hideProgressBar: true },
        );
      // toast.error(`Profil non ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_APPEAL_FAILURE',
        msg: 'error server',
        err: data.errors || data.message,
        appeal: values,
        status: getStatusError(e),
      });
    } else {
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="error"
            message="عفوًا ، لم يتم إنشاء الطعن ، يرجى إعادة المحاولة."
          />
        ),
        { hideProgressBar: true },
      );
      // toast.error(`Profil non ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_APPEAL_FAILURE',
        msg: 'error server',
        appeal: values,
        status: getStatusError(e),
      });
    }
  }
};
const deleteAppeal = (id) => async (dispatch) => {
  dispatch({ type: 'ACTION_DELETE_APPEAL_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'delete',
      url: `/api/appeal/delete/${id}`,
    });
    if (data.type === 'success') {
      toast(({ closeToast }) => <ToastAction type="success" message={data.message} />, {
        hideProgressBar: true,
      });
      //toast.success(`Formation supprimé`, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'ACTION_DELETE_APPEAL_SUCCESS',
        destroyAppeal: data,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      //toast.warn(data.data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'ACTION_DELETE_APPEAL_FAILURE',
        ErrorMessage: data.message,
        data: false,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    toast(
      ({ closeToast }) => <ToastAction type="error" message="لم يتم حذف نوع القضية" />,
      { hideProgressBar: true },
    );
    // toast.error(`Formation n'est pas supprimé`, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'ACTION_DELETE_SUIT_TYPE_FAILURE',
      msg: 'error server',
      err: data.errors || data.message,
      data: false,
    });
  }
};
export default {
  getAppealById,
  submitAppealForm,
  deleteAppeal,
};
