import React, { Component } from 'react';
import actions from '../../js/actions';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import actionsSession from '../session/store/actions';
import actionsRdv from '../appointment/store/actions';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import frLocale from '@fullcalendar/core/locales/ar';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import moment from 'moment';
import SwipeableTemporaryDrawerRDV from '../appointment/containers/form-rdv';
import SwipeableTemporaryDrawerSession from './components/SwipeableTemporaryDrawerSession';

import '@fullcalendar/daygrid/main.css';
import actionsSettings from '../settings/store/actions';
import { newDataLawyers } from '../../utils/helpres';
import { host, typeColors } from '../../utils/constants';
import DatePicker from 'react-datepicker';
import SearchIcon from '../../assets/img/search-interface-symbol.svg';

const mapStateToProps = (state) => ({
  account: state.AppReducer.account,
  isLoadingAllActifRDV: state.AppReducer.isLoadingAllActifRDV,
  allActifRDV: state.AppReducer.allActifRDV,
  isLoadingCalender: state.AppReducer.isLoadingCalender,
  calenderData: state.AppReducer.calenderData,
  paramsCalender: state.AppReducer.paramsCalender,
  updateRdv: state.AgendaReducer.updateRdv,
  get_img_account: state.AgendaReducer.get_img_account,
  lawyerTraineeShip: state.SettingsReducer.lawyerTraineeShip,
});

const mapDispatchToProps = (dispatch) => ({
  getCalender: (paramsCalender) => dispatch(actions.getCalender(paramsCalender)),
  changeSearchedStringCalender: async (paramsCalender) => {
    await dispatch(actions.changeSearchedStringCalender(paramsCalender));
    dispatch(actions.getCalender(paramsCalender));
  },
  initialState: () => dispatch(actions.initialState()),
  submitRDVForm: async (values, paramsCalender) => {
    await dispatch(actionsRdv.submitRDVForm(values));
    dispatch(actions.getCalender(paramsCalender));
  },
  DuplicateSessionForm: async (paramsCalender, values) => {
    await dispatch(actionsSession.DuplicateSessionForm(values));
    dispatch(actions.getCalender(paramsCalender));
  },
  getLawyerTraineeShip: () => {
    dispatch(actionsSettings.getLawyerTraineeShip(true));
  },
});
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const EventDetail = (eventInfo) => {
  return eventInfo.view.type === 'dayGridMonth' ? (
    <>
      {eventInfo.event._def.extendedProps.type === 'session' ? (
        <div
          className="fc-daygrid-event-dot"
          style={{
            borderColor: typeColors[eventInfo.event._def.extendedProps.type_session],
            width: '2px',
            height: '2px',
          }}
        ></div>
      ) : (
        <div
          className="fc-daygrid-event-dot"
          style={{ borderColor: eventInfo.borderColor, width: '2px', height: '2px' }}
        ></div>
      )}

      <div className="dot">
        {eventInfo.event._def.extendedProps.urlImg && (
          <span style={{ fontSize: 13, verticalAlign: 'sub', fontWeight: 'bold' }}>
            {eventInfo.event._def.extendedProps.urlImg}
          </span>
        )}
      </div>
      <div className="fc-event-title">
        <div style={{ fontWeight: '400', fontSize: '13px' }}>
          {eventInfo.event._def.extendedProps.display_name}
        </div>
        <div style={{ fontWeight: '400', fontSize: '13px' }}>
          {moment(eventInfo.event.start).format('HH:mm')}
        </div>
      </div>
    </>
  ) : eventInfo.view.type === 'resourceTimeGridFourDay' ||
    eventInfo.view.type === 'timeGridWeek' ? (
    <>
      <div
        className="fc-daygrid-event-dot"
        style={{ borderColor: eventInfo.borderColor, width: '2px', height: '2px' }}
      ></div>
      <div className="fc-event-title">
        <div style={{ fontWeight: 'bold', color: '#fcfcfc', fontSize: '13px' }}>
          {eventInfo.event._def.extendedProps.display_name}
        </div>
        <div style={{ fontWeight: '400', color: '#fcfcfc', fontSize: '13px' }}>
          {moment(eventInfo.event.start).format('HH:mm')}
        </div>
      </div>
    </>
  ) : (
    <>
      <div
        className="fc-daygrid-event-dot"
        style={{ borderColor: eventInfo.borderColor, width: '2px', height: '2px' }}
      ></div>
      <div className="fc-event-title">
        <div style={{ fontWeight: 'bold', color: '#fcfcfc', fontSize: '13px' }}>
          {eventInfo.event._def.extendedProps.display_name}
          <span style={{ color: '#fcfcfc', fontWeight: '400', fontSize: '13px' }}>
            {'   ' + moment(eventInfo.event.start).format('HH:mm')}
          </span>
        </div>
      </div>
    </>
  );
};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    props.initialState();
    props.getLawyerTraineeShip();
    props.changeActiveItemSideBar(0, 'الرئيسية');
    document.title = 'الرئيسية';
    this.state = {
      calendarWeekends: true,
      sessionDrawer: false,
      addRdvDrawer: false,
      date: new Date(),
      typeView: 'dayGridMonth',
      hasError: false,
      anchor: false,
      left: false,
      rdv: null,
      session: null,
      selected_id: null,
    };
    this.goBack = this.goBack.bind(this);
    this.handleChangeView = this.handleChangeView.bind(this);
    this.goBackRdv = this.goBackRdv.bind(this);
  }
  getItemRdv(id) {
    const { calenderData } = this.props;
    const rdv = calenderData.find(
      (element) => element.type === 'rdv' && element.id === parseInt(id),
    );
    this.setState({
      rdv,
    });
  }
  getItemSession(id) {
    const { calenderData } = this.props;
    const session = calenderData.find(
      (element) => element.type === 'session' && element.id === parseInt(id),
    );
    this.setState({
      session,
    });
  }
  componentDidMount() {
    this.props.changeSearchedStringCalender({
      date: '',
      currentPage: 1,
      limit: 5,
      number_lawsuit: '',
      type: 'all',
    });
  }
  handleChangeView(info) {
    this.setState({
      typeView: info.view.type,
    });
  }
  calendarComponentRef = React.createRef();
  gotoPast = (date) => {
    let calendarApi = this.calendarComponentRef.current.getApi();
    this.props.changeSearchedStringCalender({ ...this.props.paramsCalender, date });
    this.setState({
      date,
    });
    calendarApi.gotoDate(date); // call a method on the Calendar object
  };
  toggleDrawer = (anchor, open, rdv) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    this.setState({
      left: open,
      anchor: 'left',
      rdv: rdv,
      hasError: false,
    });
  };
  toggleDrawerSession = (anchor, open, session) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    this.setState({
      sessionDrawer: open,
      anchor: 'left',
      session: session,
      hasError: false,
    });
  };
  toggleDrawerAddRdv = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    this.setState({
      addRdvDrawer: open,
      anchor: 'left',
      rdv: null,
      hasError: false,
    });
  };
  goBackRdv() {
    this.setState({
      left: false,
      anchor: 'left',
      rdv: null,
      hasError: false,
    });
  }
  goBack() {
    this.setState({
      sessionDrawer: false,
      session: null,
      hasError: false,
    });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { destroyRDV, updateRdv, createdNewDispo } = this.props;
    if (updateRdv !== prevProps.updateRdv && updateRdv) {
      this.setState({
        addRdvDrawer: false,
        left: false,
        anchor: 'left',
        hasError: false,
      });
    }
  }
  render() {
    const {
      isLoadingSessions,
      account,
      lawyerTraineeShip,
      paramsCalender,
      calenderData,
    } = this.props;
    const {
      typeView,
      date,
      addRdvDrawer,
      session,
      sessionDrawer,
      hasError,
      left,
      anchor,
      rdv,
    } = this.state;
    if (isLoadingSessions)
      return (
        <CircularProgress
          style={{
            textAlign: 'center',
            width: '30px',
            height: '30px',
            marginTop: '25%',
            marginRight: '50%',
          }}
        />
      );
    return (
      <div className="home_middle">
        {left && rdv && rdv.id ? (
          <SwipeableTemporaryDrawerRDV
            hasError={hasError}
            params={paramsCalender}
            left={left}
            anchor={anchor}
            appointment={rdv}
            type="dashboard"
            toggleDrawer={this.toggleDrawer}
            goBackRdv={this.goBackRdv}
          />
        ) : (
          ''
        )}
        {addRdvDrawer ? (
          <SwipeableTemporaryDrawerRDV
            type="dashboard"
            hasError={hasError}
            params={paramsCalender}
            left={addRdvDrawer}
            anchor={anchor}
            appointment={rdv}
            toggleDrawer={this.toggleDrawerAddRdv}
          />
        ) : (
          ''
        )}
        {sessionDrawer && session ? (
          <SwipeableTemporaryDrawerSession
            hasError={hasError}
            params={paramsCalender}
            left={sessionDrawer}
            anchor={anchor}
            session={session}
            toggleDrawer={this.toggleDrawerSession}
            goBack={this.goBack}
          />
        ) : (
          ''
        )}
        <div className="home_middle__col-right">
          <div
            /*style={{
              position: 'fixed',
              top: '10px',
              left: '10px',
              bottom: '10px',
              width: '180px',
              overflowY: 'scroll',
            }}*/
            className="table_controls"
          >
            <div className="sort_grp">
              <div className="sort_grp__txt">ترتيب حسب</div>
              <div className="sort_grp__buttons">
                <div className="login_form_field">
                  <img className="login_login_form__icon" src={SearchIcon} alt="user" />
                  <input
                    onChange={(e) =>
                      this.props.changeSearchedStringCalender({
                        ...paramsCalender,
                        number_lawsuit: e.target.value,
                      })
                    }
                    placeholder="عدد القضية"
                    style={{
                      height: '3rem',
                      borderRadius: '10px',
                      boxShadow: '0 0.0rem 0.0rem rgb(79 79 79 / 19%)',
                    }}
                  />
                </div>
                <div className="login_form_field">
                  <select
                    onChange={(e) =>
                      this.props.changeSearchedStringCalender({
                        ...paramsCalender,
                        type: e.target.value,
                      })
                    }
                    style={{
                      border: '0',
                      fontSize: '14px',
                      width: '25rem',
                      height: '3rem',
                      borderRadius: '10px',
                      boxShadow: '0 0.0rem 0.0rem rgb(79 79 79 / 19%)',
                    }}
                  >
                    <option value="all">المواعيد و الجلسات </option>
                    <option value="rdv">المواعيد</option>
                    <option value="session">الجلسات</option>
                  </select>
                </div>
                <DatePicker
                  style={{ width: '10%' }}
                  selected={new Date()}
                  locale="ar"
                  onChange={(date) => this.gotoPast(moment(date).format('YYYY-MM-DD'))}
                  customInput={
                    <svg
                      style={{ cursor: 'pointer' }}
                      id="calendar"
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                    >
                      <g id="Group_28" data-name="Group 28">
                        <g id="Group_27" data-name="Group 27">
                          <ellipse
                            id="Ellipse_20"
                            data-name="Ellipse 20"
                            cx="1"
                            cy="1.5"
                            rx="1"
                            ry="1.5"
                            transform="translate(22 11)"
                            fill="#ca2946"
                          />
                          <path
                            id="Path_89"
                            data-name="Path 89"
                            d="M25.312,2.344H23.789V1.172a1.172,1.172,0,0,0-2.344,0V2.344H16.113V1.172a1.172,1.172,0,0,0-2.344,0V2.344H8.5V1.172a1.172,1.172,0,1,0-2.344,0V2.344H4.688A4.693,4.693,0,0,0,0,7.031V25.312A4.693,4.693,0,0,0,4.688,30h8.965a1.172,1.172,0,0,0,0-2.344H4.688a2.346,2.346,0,0,1-2.344-2.344V7.031A2.346,2.346,0,0,1,4.688,4.688H6.152V5.859a1.172,1.172,0,0,0,2.344,0V4.688H13.77V5.859a1.172,1.172,0,0,0,2.344,0V4.688h5.332V5.859a1.172,1.172,0,0,0,2.344,0V4.688h1.523a2.346,2.346,0,0,1,2.344,2.344v6.68a1.172,1.172,0,0,0,2.344,0V7.031A4.693,4.693,0,0,0,25.312,2.344Z"
                            fill="#ca2946"
                          />
                          <path
                            id="Path_90"
                            data-name="Path 90"
                            d="M277.09,270a7.09,7.09,0,1,0,7.09,7.09A7.1,7.1,0,0,0,277.09,270Zm0,11.836a4.746,4.746,0,1,1,4.746-4.746A4.751,4.751,0,0,1,277.09,281.836Z"
                            transform="translate(-254.18 -254.18)"
                            fill="#ca2946"
                          />
                          <path
                            id="Path_91"
                            data-name="Path 91"
                            d="M373.871,332.4h-.527v-1.23a1.172,1.172,0,0,0-2.344,0v2.4a1.172,1.172,0,0,0,1.172,1.172h1.7a1.172,1.172,0,0,0,0-2.344Z"
                            transform="translate(-349.262 -310.664)"
                            fill="#ca2946"
                          />
                          <circle
                            id="Ellipse_21"
                            data-name="Ellipse 21"
                            cx="1.5"
                            cy="1.5"
                            r="1.5"
                            transform="translate(16 11)"
                            fill="#ca2946"
                          />
                          <circle
                            id="Ellipse_22"
                            data-name="Ellipse 22"
                            cx="1.5"
                            cy="1.5"
                            r="1.5"
                            transform="translate(11 16)"
                            fill="#ca2946"
                          />
                          <ellipse
                            id="Ellipse_23"
                            data-name="Ellipse 23"
                            cx="1"
                            cy="1.5"
                            rx="1"
                            ry="1.5"
                            transform="translate(6 11)"
                            fill="#ca2946"
                          />
                          <ellipse
                            id="Ellipse_24"
                            data-name="Ellipse 24"
                            cx="1"
                            cy="1.5"
                            rx="1"
                            ry="1.5"
                            transform="translate(6 16)"
                            fill="#ca2946"
                          />
                          <circle
                            id="Ellipse_25"
                            data-name="Ellipse 25"
                            cx="1"
                            cy="1"
                            r="1"
                            transform="translate(6 22)"
                            fill="#ca2946"
                          />
                          <ellipse
                            id="Ellipse_26"
                            data-name="Ellipse 26"
                            cx="1.5"
                            cy="1"
                            rx="1.5"
                            ry="1"
                            transform="translate(11 22)"
                            fill="#ca2946"
                          />
                          <circle
                            id="Ellipse_27"
                            data-name="Ellipse 27"
                            cx="1.5"
                            cy="1.5"
                            r="1.5"
                            transform="translate(11 11)"
                            fill="#ca2946"
                          />
                        </g>
                      </g>
                    </svg>
                  }
                />
              </div>
              <div>
                <ul style={{ margin: '10px', display: 'flex' }}>
                  <li style={{ alignItems: 'baseline', display: 'flex', padding: '7px' }}>
                    <div
                      className="fc-daygrid-event-dot"
                      style={{
                        borderColor: typeColors[5],
                        width: '2px',
                        height: '2px',
                      }}
                    ></div>
                    <span style={{ fontSize: 13 }}>لجوابنا</span>
                  </li>
                  <li style={{ alignItems: 'baseline', display: 'flex', padding: '7px' }}>
                    <div
                      className="fc-daygrid-event-dot"
                      style={{
                        borderColor: typeColors[1],
                        width: '2px',
                        height: '2px',
                      }}
                    ></div>
                    <span style={{ fontSize: 13 }}>لجواب الزميل</span>
                  </li>
                  <li style={{ alignItems: 'baseline', display: 'flex', padding: '7px' }}>
                    <div
                      className="fc-daygrid-event-dot"
                      style={{
                        borderColor: typeColors[2],
                        width: '2px',
                        height: '2px',
                      }}
                    ></div>
                    <span style={{ fontSize: 13 }}>تحضيري</span>
                  </li>
                  <li style={{ alignItems: 'baseline', display: 'flex', padding: '7px' }}>
                    <div
                      className="fc-daygrid-event-dot"
                      style={{
                        borderColor: typeColors[3],
                        width: '2px',
                        height: '2px',
                      }}
                    ></div>
                    <span style={{ fontSize: 13 }}>مرافعة</span>
                  </li>
                  <li style={{ alignItems: 'baseline', display: 'flex', padding: '7px' }}>
                    <div
                      className="fc-daygrid-event-dot"
                      style={{
                        borderColor: typeColors[4],
                        width: '2px',
                        height: '2px',
                      }}
                    ></div>
                    <span style={{ fontSize: 13 }}>لتصريح بالحكم</span>
                  </li>
                  <li style={{ alignItems: 'baseline', display: 'flex', padding: '7px' }}>
                    <div
                      className="fc-daygrid-event-dot"
                      style={{
                        borderColor: typeColors[6],
                        width: '2px',
                        height: '2px',
                      }}
                    ></div>
                    <span style={{ fontSize: 13 }}>جلسة جديدة</span>
                  </li>
                  <li style={{ alignItems: 'baseline', display: 'flex', padding: '7px' }}>
                    <div
                      className="fc-daygrid-event-dot"
                      style={{
                        borderColor: 'green',
                        width: '2px',
                        height: '2px',
                      }}
                    ></div>
                    <span style={{ fontSize: 13 }}>المواعيد</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <br />
          <FullCalendar
            //firstDay={1}
            slotMinTime="08:00:00"
            slotMaxTime="20:00:00"
            forceEventDuration={true}
            droppable={true}
            slotEventOverlap={true}
            hiddenDays={[6, 0]}
            fixedWeekCount={false}
            customButtons={{
              today: {
                text: 'يوم',
                click: async () => {
                  this.gotoPast(moment().format('YYYY-MM-DD'));
                },
              },
              prev: {
                click: async () => {
                  if (typeView === 'dayGridMonth')
                    this.gotoPast(
                      moment(date, 'YYYY-MM-DD').add(-1, 'months').format('YYYY-MM-DD'),
                    );
                  if (
                    typeView === 'timeGridWeek' ||
                    typeView === 'resourceTimeGridFourDay'
                  )
                    this.gotoPast(
                      moment(date, 'YYYY-MM-DD').add(-1, 'weeks').format('YYYY-MM-DD'),
                    );
                  if (typeView === 'timeGridDay' || typeView === 'timeGridDay1')
                    this.gotoPast(
                      moment(date, 'YYYY-MM-DD').add(-1, 'days').format('YYYY-MM-DD'),
                    );
                },
              },
              next: {
                click: () => {
                  if (typeView === 'dayGridMonth')
                    this.gotoPast(
                      moment(date, 'YYYY-MM-DD').add(1, 'months').format('YYYY-MM-DD'),
                    );
                  if (
                    typeView === 'timeGridWeek' ||
                    typeView === 'resourceTimeGridFourDay'
                  )
                    this.gotoPast(
                      moment(date, 'YYYY-MM-DD').add(1, 'weeks').format('YYYY-MM-DD'),
                    );
                  if (typeView === 'timeGridDay' || typeView === 'timeGridDay1')
                    this.gotoPast(
                      moment(date, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD'),
                    );
                },
              },
            }}
            viewClassNames={this.handleChangeView}
            titleFormat={function (date) {
              if (typeView === 'dayGridMonth')
                return `${moment(date.date).format('MMMM')} ${moment(date.date).format(
                  'YYYY',
                )}`;
              if (typeView === 'timeGridWeek' || typeView === 'resourceTimeGridFourDay')
                return `من ${moment(date.start).format('dddd')} ${moment(date.start)
                  .startOf('week')
                  .add(1, 'days')
                  .format('DD')} ${moment(date.date).format('MMMM')} الى ${moment(
                  date.date,
                )
                  .add(-3, 'days')
                  .format('dddd')} ${moment(date.date)
                  .endOf('week')
                  .add(-1, 'days')
                  .format('DD')} ${moment(date.end).format('MMMM')} ${moment(
                  date.end,
                ).format('YYYY')}`;
              if (typeView === 'timeGridDay' || typeView === 'timeGridDay1')
                return `${moment(date.date).format('dddd')} ${moment(date.date).format(
                  'DD',
                )} ${moment(date.date).format('MMMM')} ${moment(date.date).format(
                  'YYYY',
                )}`;
            }}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right:
                account.pack !== 'traineeship'
                  ? 'dayGridMonth,resourceTimeGridFourDay,timeGridDay1'
                  : 'dayGridMonth,timeGridWeek,timeGridDay',
            }}
            weekends={this.state.calendarWeekends}
            schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
            ref={this.calendarComponentRef}
            // dateClick={this.handleDateClick}
            displayEventTime={true}
            selectable={true}
            initialView="dayGridMonth"
            resources={newDataLawyers(lawyerTraineeShip)}
            views={
              account.pack !== 'traineeship'
                ? {
                    resourceTimeGridFourDay: {
                      type: 'resourceTimeGridWeek',
                      //duration: { days: 7 },
                      buttonText: 'أسبوع',
                      // startDays: 1,
                    },
                    timeGridDay1: {
                      type: 'resourceTimeGridDay',
                      duration: { days: 1 },
                      buttonText: 'يوم',
                    },
                  }
                : ''
            }
            eventOrder={(a, b) => {
              var result = 0;
              if (a.sort < b.sort) result = -1;
              //event a should be listed first
              else if (a.sort > b.sort) result = 1; //event b should be listed first
              return result;
            }}
            plugins={[
              //CustomView,
              resourceTimelinePlugin,
              dayGridPlugin,
              interactionPlugin,
              timeGridPlugin,
              resourceTimeGridPlugin,
            ]}
            dayMaxEventRows={true}
            eventClick={(event) => {
              if (event.event._def.extendedProps.type === 'rdv') {
                this.setState({
                  left: true,
                });
                this.getItemRdv(parseInt(event.event._def.publicId));
              } else {
                this.setState({
                  sessionDrawer: true,
                });
                this.getItemSession(parseInt(event.event._def.publicId));
              }
            }}
            eventContent={EventDetail}
            viewDidMount={(info) => {
              this.setState({ typeView: info.view.type });
            }}
            events={calenderData}
            select={(info) => {
              this.setState({
                addRdvDrawer: true,
                rdv: {
                  id: null,
                  date: moment(info.startStr).format('YYYY-MM-DD HH:mm:ss'),
                },
              });
            }}
            eventDrop={async (info) => {
              //<--- see from here
              if (info.event._def.extendedProps.type === 'session')
                // console.log(info.event);
                await this.props.DuplicateSessionForm(
                  {
                    ...paramsCalender,
                    date: moment(info.event._instance.range.start).format('YYYY-MM-DD'),
                  },
                  {
                    date: moment(info.event._instance.range.start).format(
                      'YYYY-MM-DD HH:mm:ss',
                    ),
                    responsible_id: parseInt(info.event._def.resourceIds[0]),
                    lawsuit_id: parseInt(info.event._def.extendedProps.lawsuit.id),
                    id: parseInt(info.event._def.publicId),
                  },
                );
            }}
            editable={true}
            locale={frLocale}
            //timeZone="UTC"
          />
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
{
  /*<CalendarE
                      changeSearchedStringCalender={
                        this.props.changeSearchedStringCalender
                      }
                      submitRDVForm={this.props.submitRDVForm}
                      updateRdv={updateRdv}
                      paramsCalender={paramsCalender}
                      calenderData={calenderData}
                    />*/
}
