import React, { Component } from 'react';
import { firstNumberPhone, isNumeric, lengthPhone } from '../../../utils/helpres';
import { CircularProgress } from '@material-ui/core';
class Contact extends Component {
  constructor(props) {
    super(props);
    document.title = 'تواصل معنا';
    props.changeActiveItemSideBar(10, 'تواصل معنا');
    this.state = {
      initialValues: {
        first_name: props.account.first_name,
        last_name: props.account.last_name,
        email: props.account.email,
        phone: props.account.phone,
        message: '',
      },
      hasError: false,
      openPopup: false,
    };
  }
  handleSubmit(values) {
    if (
      !values.first_name ||
      !values.last_name ||
      !values.email ||
      !values.message ||
      (values.phone && !firstNumberPhone(values.phone)) ||
      (values.phone && !isNumeric(values.phone)) ||
      (values.phone && !lengthPhone(values.phone)) ||
      !values.phone
    ) {
      this.setState({
        hasError: true,
      });
    } else {
      this.setState({
        hasError: false,
      });
      this.props.submitRegisterForm(values);
    }
  }

  render() {
    const { hasError, initialValues } = this.state;
    const { account, isLoadingRegister } = this.props;
    return (
      <div
        style={{
          display: 'block',
          width: '100%',
          backgroundColor: 'white',
          padding: '26px',
        }}
        className="params_col-left"
      >
        <div style={{ width: '100%' }}>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label style={{ color: '#ca2946', fontSize: '15px' }} htmlFor="name">
                البريد الالكتروني
              </label>
              <label style={{ color: 'black', fontSize: '15px' }} htmlFor="name">
                support@lavocato.com
              </label>
            </div>
            <div className="form-group col-md-6">
              <label style={{ color: '#ca2946', fontSize: '15px' }} htmlFor="name">
                الهاتف
              </label>
              <label style={{ color: 'black', fontSize: '15px' }} htmlFor="name">
                088 579 56 (216+)
              </label>
            </div>
          </div>
          <form method="post" role="form" className="php-email-form">
            <div className="form-row">
              <div className="form-group col-md-6">
                <label style={{ fontSize: '15px' }} htmlFor="name">
                  الاسم
                </label>
                <input
                  disabled={true}
                  type="text"
                  name="first_name"
                  className={
                    hasError && !initialValues.first_name
                      ? 'form-control error'
                      : 'form-control'
                  }
                  value={account.first_name}
                  id="name"
                  data-rule="minlen:4"
                  data-msg="Please enter at least 4 chars"
                />
                <div className="validate"></div>
              </div>
              <div className="form-group col-md-6">
                <label style={{ fontSize: '15px' }} htmlFor="name">
                  اللقب
                </label>
                <input
                  disabled={true}
                  type="text"
                  name="last_name"
                  className={
                    hasError && !initialValues.last_name
                      ? 'form-control error'
                      : 'form-control'
                  }
                  value={initialValues.last_name}
                  id="name"
                  data-rule="minlen:4"
                  data-msg="Please enter at least 4 chars"
                />
                <div className="validate"></div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label style={{ fontSize: '15px' }} htmlFor="name">
                  البريد الالكتروني
                </label>
                <input
                  disabled={true}
                  type="email"
                  className={
                    hasError && !initialValues.email
                      ? 'form-control error'
                      : 'form-control'
                  }
                  value={initialValues.email}
                  name="email"
                  id="subject"
                  data-rule="minlen:4"
                  data-msg="Please enter at least 8 chars of subject"
                />
                <div className="validate"></div>
              </div>
              <div className="form-group col-md-6">
                <label style={{ fontSize: '15px' }} htmlFor="name">
                  الهاتف
                </label>
                <input
                  value={initialValues.phone}
                  disabled={true}
                  type="text"
                  className={
                    (hasError && !initialValues.phone) ||
                    (initialValues.phone && !firstNumberPhone(initialValues.phone)) ||
                    (initialValues.phone && !lengthPhone(initialValues.phone)) ||
                    (initialValues.phone && !isNumeric(initialValues.phone))
                      ? 'form-control error'
                      : 'form-control'
                  }
                  name="phone"
                  id="email"
                  data-rule="email"
                  data-msg="Please enter a valid email"
                />
                <div className="validate"></div>
              </div>
            </div>
            <div className="form-group">
              <label style={{ fontSize: '15px' }} htmlFor="name">
                رسالة
              </label>
              <textarea
                onChange={(e) =>
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      message: e.target.value,
                    },
                  })
                }
                className={
                  hasError && !initialValues.message
                    ? 'form-control error'
                    : 'form-control'
                }
                name="message"
                rows="10"
                data-rule="required"
                data-msg="Please write something for us"
                value={initialValues.message}
              ></textarea>
              <div className="validate"></div>
            </div>
          </form>
        </div>
        <div style={{ display: 'flex' }} className="popup__btn_grp">
          <button
            onClick={() => this.handleSubmit(initialValues)}
            disabled=""
            style={{ width: '15%', background: '#63B17D' }}
            type="button"
          >
            {isLoadingRegister ? (
              <CircularProgress
                style={{
                  textAlign: 'center',
                  width: '18px',
                  height: '15px',
                  color: 'white',
                }}
              />
            ) : (
              <>
                <svg
                  className="tickSpan"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.125"
                  height="17.774"
                  viewBox="0 0 29.125 23.774"
                >
                  <path
                    id="Path_92"
                    data-name="Path 92"
                    d="M1325.354,167.5l8.34,9.625,17.8-19.417"
                    transform="translate(-1323.842 -156.357)"
                    fill="none"
                    stroke="#f9f9f9"
                    strokeWidth="4"
                  ></path>
                </svg>
                تسجيل
              </>
            )}
          </button>
        </div>
      </div>
    );
  }
}
export default Contact;
