import React, { Component } from 'react';
import Form from './containers/form';
import actions from './store/actions';
import actionsApp from '../../js/actions';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import actionsSettings from '../settings/store/actions';

const mapStateToProps = (state) => ({
  session: state.SessionReducer.session,
  isLoadingSession: state.SessionReducer.isLoadingSession,
  lawsuitsSessions: state.AppReducer.lawsuitsSessions,
  isLoadingLawsuitsSessions: state.AppReducer.isLoadingLawsuitsSessions,
  isLoadingLawyerTraineeShip: state.SettingsReducer.isLoadingLawyerTraineeShip,
  lawyerTraineeShip: state.SettingsReducer.lawyerTraineeShip,
});

const mapDispatchToProps = (dispatch) => ({
  getSessionById: (id) => dispatch(actions.getSessionById(id)),
  getListLawsuitsWithOutSession: () =>
    dispatch(actionsApp.getListLawsuitsWithOutSession()),
  getLawyerTraineeShip: () => {
    dispatch(actionsSettings.getLawyerTraineeShip(true));
  },
});

class FormSession extends Component {
  constructor(props) {
    super(props);
    props.getLawyerTraineeShip();
    if (props.match.params && props.match.params.id) {
      props.changeActiveItemSideBar(1, 'تحديث جلسة');
      document.title = 'تحديث جلسة';
    } else {
      props.changeActiveItemSideBar(1, 'اضافة جلسة');
      document.title = 'اضافة جلسة';
    }
    props.getListLawsuitsWithOutSession();
    props.getSessionById(props.match.params && props.match.params.id);
    this.goBack = this.goBack.bind(this);
  }
  goBack() {
    this.props.history.goBack();
  }
  render() {
    const {
      session,
      lawyerTraineeShip,
      lawsuitsSessions,
      isLoadingSession,
      isLoadingLawsuitsSessions,
    } = this.props;
    if (isLoadingSession) {
      return (
        <CircularProgress
          style={{
            textAlign: 'center',
            width: '30px',
            height: '30px',
            marginTop: '20%',
            marginRight: '50%',
          }}
        />
      );
    }
    if (isLoadingLawsuitsSessions) {
      return (
        <CircularProgress
          style={{
            textAlign: 'center',
            width: '30px',
            height: '30px',
            marginTop: '20%',
            marginRight: '50%',
          }}
        />
      );
    }

    return (
      <div className="jalaset_main">
        <div className="jalaset_newSession" style={{ display: 'flex' }}>
          <Form
            goBack={this.goBack}
            lawyerTraineeShip={lawyerTraineeShip}
            session={session}
            lawsuitsSessions={lawsuitsSessions}
            isLoadingLawsuitsSessions={isLoadingLawsuitsSessions}
          />
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(FormSession);
