import React from 'react';
import LogoSmall from './assets/img/logo.png';
import LogoBlanc from './assets/img/logo-blanc.png';
const routes = [
  {
    id: 13,
    path: '/legal-journals',
    name: 'لافوكاتو',
    permission: 'الرئيسية',
    img: <img style={{ width: '26px' }} src={LogoSmall} />,
    imgActive: <img style={{ width: '26px' }} src={LogoBlanc} />,
    layout: '/',
  },
  {
    id: 0,
    path: '/',
    name: 'الرئيسية',
    permission: 'الرئيسية',
    img: (
      <svg
        className="side_menu_svg"
        xmlns="http://www.w3.org/2000/svg"
        width="38.108"
        height="36.546"
        viewBox="0 0 38.108 36.546"
      >
        <g id="home" transform="translate(0 -10.492)">
          <g id="Group_12" data-name="Group 12" transform="translate(0 10.492)">
            <path
              id="Path_48"
              data-name="Path 48"
              d="M37.676,27.307,22.165,11.695c-.02-.02-.04-.04-.061-.059a4.462,4.462,0,0,0-5.953-.013q-.032.028-.062.058L.437,27.3a1.489,1.489,0,1,0,2.1,2.107l1.181-1.179V41.084a5.961,5.961,0,0,0,5.954,5.954h5.359a1.489,1.489,0,0,0,1.489-1.489V33.418h5.21V45.55a1.489,1.489,0,0,0,1.489,1.489h5.21a5.961,5.961,0,0,0,5.954-5.954,1.489,1.489,0,0,0-2.977,0,2.981,2.981,0,0,1-2.977,2.977H24.711V31.929a1.489,1.489,0,0,0-1.489-1.489H15.035a1.489,1.489,0,0,0-1.489,1.489V44.061H9.676A2.981,2.981,0,0,1,6.7,41.084V25.3q0-.022,0-.044L18.157,13.824a1.487,1.487,0,0,1,1.931,0l11.321,11.4v8.417a1.489,1.489,0,0,0,2.977,0v-5.42l1.177,1.185a1.489,1.489,0,1,0,2.112-2.1Z"
              transform="translate(0 -10.492)"
              fill="#ca2946"
            />
          </g>
        </g>
      </svg>
    ),
    imgActive: (
      <svg
        className="home_menu_svg active_svg"
        xmlns="http://www.w3.org/2000/svg"
        width="28.5"
        height="25.501"
        viewBox="0 0 38.108 36.546"
      >
        <g id="home" transform="translate(0 -10.492)">
          <g id="Group_12" data-name="Group 12" transform="translate(0 10.492)">
            <path
              className="home_fill_path"
              data-name="Path 48"
              d="M37.676,27.307,22.165,11.695c-.02-.02-.04-.04-.061-.059a4.462,4.462,0,0,0-5.953-.013q-.032.028-.062.058L.437,27.3a1.489,1.489,0,1,0,2.1,2.107l1.181-1.179V41.084a5.961,5.961,0,0,0,5.954,5.954h5.359a1.489,1.489,0,0,0,1.489-1.489V33.418h5.21V45.55a1.489,1.489,0,0,0,1.489,1.489h5.21a5.961,5.961,0,0,0,5.954-5.954,1.489,1.489,0,0,0-2.977,0,2.981,2.981,0,0,1-2.977,2.977H24.711V31.929a1.489,1.489,0,0,0-1.489-1.489H15.035a1.489,1.489,0,0,0-1.489,1.489V44.061H9.676A2.981,2.981,0,0,1,6.7,41.084V25.3q0-.022,0-.044L18.157,13.824a1.487,1.487,0,0,1,1.931,0l11.321,11.4v8.417a1.489,1.489,0,0,0,2.977,0v-5.42l1.177,1.185a1.489,1.489,0,1,0,2.112-2.1Z"
              transform="translate(0 -10.492)"
              fill="#ca2946"
            />
          </g>
        </g>
      </svg>
    ),
    layout: '/',
  },
  {
    id: 2,
    path: '/appointment',
    name: 'المواعيد',
    permission: 'قراءة-المواعيد',
    img: (
      <svg
        className="home_menu_svg"
        id="calendar"
        xmlns="http://www.w3.org/2000/svg"
        width="28.5"
        height="25.501"
        viewBox="0 0 36.5 36.5"
      >
        <g id="Group_11" data-name="Group 11">
          <g id="Group_10" data-name="Group 10">
            <ellipse
              data-name="Ellipse 2"
              cx="1"
              cy="1.5"
              rx="1"
              ry="1.5"
              transform="translate(26.5 13.5)"
              fill="#ca2946"
            />
            <path
              data-name="Path 43"
              d="M30.8,2.852H28.943V1.426a1.426,1.426,0,0,0-2.852,0V2.852H19.6V1.426a1.426,1.426,0,0,0-2.852,0V2.852H10.337V1.426a1.426,1.426,0,1,0-2.852,0V2.852H5.7A5.71,5.71,0,0,0,0,8.555V30.8a5.71,5.71,0,0,0,5.7,5.7H16.61a1.426,1.426,0,0,0,0-2.852H5.7A2.855,2.855,0,0,1,2.852,30.8V8.555A2.855,2.855,0,0,1,5.7,5.7H7.485V7.129a1.426,1.426,0,1,0,2.852,0V5.7h6.416V7.129a1.426,1.426,0,0,0,2.852,0V5.7h6.487V7.129a1.426,1.426,0,0,0,2.852,0V5.7H30.8a2.855,2.855,0,0,1,2.852,2.852v8.127a1.426,1.426,0,0,0,2.852,0V8.555A5.71,5.71,0,0,0,30.8,2.852Z"
              fill="#ca2946"
            />
            <path
              data-name="Path 44"
              d="M278.626,270a8.626,8.626,0,1,0,8.626,8.626A8.636,8.636,0,0,0,278.626,270Zm0,14.4a5.774,5.774,0,1,1,5.774-5.774A5.781,5.781,0,0,1,278.626,284.4Z"
              transform="translate(-250.752 -250.752)"
              fill="#ca2946"
            />
            <path
              data-name="Path 45"
              d="M374.493,332.923h-.642v-1.5a1.426,1.426,0,0,0-2.852,0v2.923a1.426,1.426,0,0,0,1.426,1.426h2.067a1.426,1.426,0,0,0,0-2.852Z"
              transform="translate(-344.552 -306.475)"
              fill="#ca2946"
            />
            <circle
              data-name="Ellipse 3"
              cx="1.5"
              cy="1.5"
              r="1.5"
              transform="translate(19.5 13.5)"
              fill="#ca2946"
            />
            <circle
              data-name="Ellipse 4"
              cx="1.5"
              cy="1.5"
              r="1.5"
              transform="translate(13.5 19.5)"
              fill="#ca2946"
            />
            <circle
              data-name="Ellipse 5"
              cx="1.5"
              cy="1.5"
              r="1.5"
              transform="translate(7.5 13.5)"
              fill="#ca2946"
            />
            <circle
              data-name="Ellipse 6"
              cx="1.5"
              cy="1.5"
              r="1.5"
              transform="translate(7.5 19.5)"
              fill="#ca2946"
            />
            <circle
              data-name="Ellipse 7"
              cx="1.5"
              cy="1.5"
              r="1.5"
              transform="translate(7.5 25.5)"
              fill="#ca2946"
            />
            <circle
              data-name="Ellipse 8"
              cx="1.5"
              cy="1.5"
              r="1.5"
              transform="translate(13.5 25.5)"
              fill="#ca2946"
            />
            <circle
              data-name="Ellipse 9"
              cx="1.5"
              cy="1.5"
              r="1.5"
              transform="translate(13.5 13.5)"
              fill="#ca2946"
            />
          </g>
        </g>
      </svg>
    ),
    imgActive: (
      <svg
        className="home_menu_svg active_svg"
        id="calendar"
        xmlns="http://www.w3.org/2000/svg"
        width="28.5"
        height="25.501"
        viewBox="0 0 36.5 36.5"
      >
        <g id="Group_11" data-name="Group 11">
          <g id="Group_10" data-name="Group 10">
            <ellipse
              data-name="Ellipse 2"
              cx="1"
              cy="1.5"
              rx="1"
              ry="1.5"
              transform="translate(26.5 13.5)"
              fill="#ca2946"
            />
            <path
              data-name="Path 43"
              d="M30.8,2.852H28.943V1.426a1.426,1.426,0,0,0-2.852,0V2.852H19.6V1.426a1.426,1.426,0,0,0-2.852,0V2.852H10.337V1.426a1.426,1.426,0,1,0-2.852,0V2.852H5.7A5.71,5.71,0,0,0,0,8.555V30.8a5.71,5.71,0,0,0,5.7,5.7H16.61a1.426,1.426,0,0,0,0-2.852H5.7A2.855,2.855,0,0,1,2.852,30.8V8.555A2.855,2.855,0,0,1,5.7,5.7H7.485V7.129a1.426,1.426,0,1,0,2.852,0V5.7h6.416V7.129a1.426,1.426,0,0,0,2.852,0V5.7h6.487V7.129a1.426,1.426,0,0,0,2.852,0V5.7H30.8a2.855,2.855,0,0,1,2.852,2.852v8.127a1.426,1.426,0,0,0,2.852,0V8.555A5.71,5.71,0,0,0,30.8,2.852Z"
              fill="#ca2946"
            />
            <path
              data-name="Path 44"
              d="M278.626,270a8.626,8.626,0,1,0,8.626,8.626A8.636,8.636,0,0,0,278.626,270Zm0,14.4a5.774,5.774,0,1,1,5.774-5.774A5.781,5.781,0,0,1,278.626,284.4Z"
              transform="translate(-250.752 -250.752)"
              fill="#ca2946"
            />
            <path
              data-name="Path 45"
              d="M374.493,332.923h-.642v-1.5a1.426,1.426,0,0,0-2.852,0v2.923a1.426,1.426,0,0,0,1.426,1.426h2.067a1.426,1.426,0,0,0,0-2.852Z"
              transform="translate(-344.552 -306.475)"
              fill="#ca2946"
            />
            <circle
              data-name="Ellipse 3"
              cx="1.5"
              cy="1.5"
              r="1.5"
              transform="translate(19.5 13.5)"
              fill="#ca2946"
            />
            <circle
              data-name="Ellipse 4"
              cx="1.5"
              cy="1.5"
              r="1.5"
              transform="translate(13.5 19.5)"
              fill="#ca2946"
            />
            <circle
              data-name="Ellipse 5"
              cx="1.5"
              cy="1.5"
              r="1.5"
              transform="translate(7.5 13.5)"
              fill="#ca2946"
            />
            <circle
              data-name="Ellipse 6"
              cx="1.5"
              cy="1.5"
              r="1.5"
              transform="translate(7.5 19.5)"
              fill="#ca2946"
            />
            <circle
              data-name="Ellipse 7"
              cx="1.5"
              cy="1.5"
              r="1.5"
              transform="translate(7.5 25.5)"
              fill="#ca2946"
            />
            <circle
              data-name="Ellipse 8"
              cx="1.5"
              cy="1.5"
              r="1.5"
              transform="translate(13.5 25.5)"
              fill="#ca2946"
            />
            <circle
              data-name="Ellipse 9"
              cx="1.5"
              cy="1.5"
              r="1.5"
              transform="translate(13.5 13.5)"
              fill="#ca2946"
            />
          </g>
        </g>
      </svg>
    ),
    layout: '/',
  },
  {
    id: 1,
    path: '/session',
    name: 'الجلسات',
    permission: 'قراءة-الجلسات',
    imgActive: (
      <svg
        className="home_menu_svg active_svg"
        xmlns="http://www.w3.org/2000/svg"
        width="28.5"
        height="25.501"
        viewBox="0 0 38.239 38.241"
      >
        <g id="gavel" transform="translate(-0.01 0)">
          <path
            data-name="Path 41"
            d="M240.99,422.87v4.5a1.12,1.12,0,0,0,1.12,1.12h18a1.12,1.12,0,0,0,1.12-1.12v-4.5a1.12,1.12,0,0,0-1.12-1.12h-18A1.12,1.12,0,0,0,240.99,422.87Zm2.241,1.12H258.99v2.259H243.231Z"
            transform="translate(-222.981 -390.25)"
            fill="#ca2946"
          />
          <path
            data-name="Path 42"
            d="M1,33.732a3.374,3.374,0,0,0,4.766,0L19.289,20.205l1.6,1.6-.8.8a1.12,1.12,0,0,0,0,1.584l3.182,3.182a1.12,1.12,0,0,0,1.584,0L34.4,17.822a1.12,1.12,0,0,0,0-1.584l-3.182-3.182a1.12,1.12,0,0,0-1.584,0l-.8.8L20.873,5.893l.8-.8a1.12,1.12,0,0,0,0-1.584L18.49.328a1.12,1.12,0,0,0-1.584,0L7.36,9.874a1.12,1.12,0,0,0,0,1.584l3.182,3.182a1.12,1.12,0,0,0,1.584,0l.8-.8,1.6,1.6L1,28.966a3.374,3.374,0,0,0,0,4.766ZM19.3,4.3l-7.962,7.962-1.6-1.6L17.7,2.7Zm3.169,19.092,7.962-7.962,1.6,1.6-7.962,7.962Zm.007-3.175L14.51,12.257l4.78-4.78,7.962,7.962ZM2.58,30.55,16.107,17.024l1.6,1.6L4.178,32.148a1.13,1.13,0,0,1-1.6-1.6Z"
            transform="translate(0 0)"
            fill="#ca2946"
          />
        </g>
      </svg>
    ),
    img: (
      <svg
        className="home_menu_svg"
        xmlns="http://www.w3.org/2000/svg"
        width="28.5"
        height="25.501"
        viewBox="0 0 38.239 38.241"
      >
        <g id="gavel" transform="translate(-0.01 0)">
          <path
            data-name="Path 41"
            d="M240.99,422.87v4.5a1.12,1.12,0,0,0,1.12,1.12h18a1.12,1.12,0,0,0,1.12-1.12v-4.5a1.12,1.12,0,0,0-1.12-1.12h-18A1.12,1.12,0,0,0,240.99,422.87Zm2.241,1.12H258.99v2.259H243.231Z"
            transform="translate(-222.981 -390.25)"
            fill="#ca2946"
          />
          <path
            data-name="Path 42"
            d="M1,33.732a3.374,3.374,0,0,0,4.766,0L19.289,20.205l1.6,1.6-.8.8a1.12,1.12,0,0,0,0,1.584l3.182,3.182a1.12,1.12,0,0,0,1.584,0L34.4,17.822a1.12,1.12,0,0,0,0-1.584l-3.182-3.182a1.12,1.12,0,0,0-1.584,0l-.8.8L20.873,5.893l.8-.8a1.12,1.12,0,0,0,0-1.584L18.49.328a1.12,1.12,0,0,0-1.584,0L7.36,9.874a1.12,1.12,0,0,0,0,1.584l3.182,3.182a1.12,1.12,0,0,0,1.584,0l.8-.8,1.6,1.6L1,28.966a3.374,3.374,0,0,0,0,4.766ZM19.3,4.3l-7.962,7.962-1.6-1.6L17.7,2.7Zm3.169,19.092,7.962-7.962,1.6,1.6-7.962,7.962Zm.007-3.175L14.51,12.257l4.78-4.78,7.962,7.962ZM2.58,30.55,16.107,17.024l1.6,1.6L4.178,32.148a1.13,1.13,0,0,1-1.6-1.6Z"
            transform="translate(0 0)"
            fill="#ca2946"
          />
        </g>
      </svg>
    ),
    layout: '/',
  },
  {
    id: 3,
    path: '/lawsuits',
    name: 'القضايا',
    permission: 'قراءة-القضايا',
    img: (
      <svg
        className="side_menu_svg"
        xmlns="http://www.w3.org/2000/svg"
        width="46.165"
        height="46.477"
        viewBox="0 0 46.165 46.477"
      >
        <g id="judgement" transform="translate(-1.252 0.5)">
          <path
            data-name="Path 79"
            d="M46.252,7.27a.666.666,0,0,0-.666.666v6.152A3.192,3.192,0,0,1,42.4,17.276H31.2a.666.666,0,0,0-.573.327l-1.051,1.775L28.524,17.6a.666.666,0,0,0-.573-.327,8.472,8.472,0,0,1-2.371-.13v-.825A6.948,6.948,0,0,0,23.3,6.443V4.52a3.192,3.192,0,0,1,3.189-3.188H42.4A3.192,3.192,0,0,1,45.585,4.52a.666.666,0,0,0,1.332,0A4.526,4.526,0,0,0,42.4,0H26.486a4.526,4.526,0,0,0-4.521,4.52V5.845a6.976,6.976,0,0,0-8.009,10.476v1.343a5.681,5.681,0,0,0,.841,2.981,6.949,6.949,0,0,0-4.024,6.324v3.274H2.4a.666.666,0,0,0,0,1.332H4.252l1.134,6.242a2.027,2.027,0,0,0,2,1.665h1.3c0,1.753,0,3.549-.033,5.316a.666.666,0,0,0,1.332.022c.03-1.778.033-3.58.033-5.338h1.642a.666.666,0,0,0,0-1.332q-2.136,0-4.272,0A.7.7,0,0,1,6.7,37.58l-1.091-6H33.929l-1.091,6a.7.7,0,0,1-.685.571H15.2a.666.666,0,0,0,0,1.332H29.556c0,1.8,0,3.551,0,5.328a.666.666,0,0,0,1.332,0c0-1.777,0-3.526,0-5.326h1.265a2.027,2.027,0,0,0,2-1.666l1.134-6.242h1.854a.666.666,0,0,0,0-1.332H33.885V28.307a.666.666,0,0,0-.666-.666H32.043V26.194h5.094a.666.666,0,0,0,0-1.332H32.043V22.749a.666.666,0,0,0-.666-.666H27.949a.666.666,0,0,0-.662.589,6.957,6.957,0,0,0-2.549-2.026,5.681,5.681,0,0,0,.78-2.142,9.629,9.629,0,0,0,2.053.1L29,21.025a.672.672,0,0,0,1.147,0l1.43-2.417H42.4a4.526,4.526,0,0,0,4.521-4.52V7.936a.666.666,0,0,0-.666-.666ZM19.768,6.828a5.653,5.653,0,0,1,5.509,6.961,2.309,2.309,0,0,0-2-1.157H16.262a2.31,2.31,0,0,0-2,1.159,5.653,5.653,0,0,1,5.509-6.963Zm0,19.15-1.625-2.789a5.932,5.932,0,0,0,3.251,0Zm.013-3.9a4.448,4.448,0,0,1-4.493-4.418V14.919a.967.967,0,0,1,.973-.955h7.012a.966.966,0,0,1,.973.957v2.743a4.447,4.447,0,0,1-4.466,4.418ZM12.106,26.97a5.623,5.623,0,0,1,3.548-5.243s2.39,3.939,3.434,5.731v2.785H12.106Zm20.446,3.274H26.773v-1.27h5.779Zm-3.937-6.829h2.1v4.226h-2.1Zm-1.333,2.268v1.958H26.107a.666.666,0,0,0-.666.666v1.936h-5.02V27.5c1.052-1.805,3.46-5.776,3.46-5.776a5.623,5.623,0,0,1,3.4,3.956Z"
            fill="#ca2946"
            stroke="#ca2946"
            strokeWidth="1"
          />
          <path
            data-name="Path 80"
            d="M300.895,36.819a.666.666,0,0,0-.666-.666H294.12v-.863a.666.666,0,0,0-1.332,0v.863h-6.108a.666.666,0,1,0,0,1.332h1.477l-1.095,2.869a2.207,2.207,0,1,0,4.124,0l-1.095-2.869h2.7v7.764h-2.949a.666.666,0,1,0,0,1.332h7.231a.666.666,0,1,0,0-1.332H294.12V37.485h2.7l-1.095,2.869a2.207,2.207,0,1,0,4.124,0l-1.095-2.869h1.476a.666.666,0,0,0,.666-.666Zm-11.052,4.818a.876.876,0,0,1-1.537-.808l.817-2.141.817,2.141A.863.863,0,0,1,289.843,41.636Zm8.662,0a.876.876,0,0,1-1.537-.808l.817-2.141.817,2.141A.863.863,0,0,1,298.505,41.636Z"
            transform="translate(-259.012 -31.548)"
            fill="#ca2946"
            stroke="#ca2946"
            strokeWidth="1"
          />
        </g>
      </svg>
    ),
    imgActive: (
      <svg
        className="side_menu_svg active_svg"
        xmlns="http://www.w3.org/2000/svg"
        width="46.165"
        height="46.477"
        viewBox="0 0 46.165 46.477"
      >
        <g id="judgement" transform="translate(-1.252 0.5)">
          <path
            data-name="Path 79"
            d="M46.252,7.27a.666.666,0,0,0-.666.666v6.152A3.192,3.192,0,0,1,42.4,17.276H31.2a.666.666,0,0,0-.573.327l-1.051,1.775L28.524,17.6a.666.666,0,0,0-.573-.327,8.472,8.472,0,0,1-2.371-.13v-.825A6.948,6.948,0,0,0,23.3,6.443V4.52a3.192,3.192,0,0,1,3.189-3.188H42.4A3.192,3.192,0,0,1,45.585,4.52a.666.666,0,0,0,1.332,0A4.526,4.526,0,0,0,42.4,0H26.486a4.526,4.526,0,0,0-4.521,4.52V5.845a6.976,6.976,0,0,0-8.009,10.476v1.343a5.681,5.681,0,0,0,.841,2.981,6.949,6.949,0,0,0-4.024,6.324v3.274H2.4a.666.666,0,0,0,0,1.332H4.252l1.134,6.242a2.027,2.027,0,0,0,2,1.665h1.3c0,1.753,0,3.549-.033,5.316a.666.666,0,0,0,1.332.022c.03-1.778.033-3.58.033-5.338h1.642a.666.666,0,0,0,0-1.332q-2.136,0-4.272,0A.7.7,0,0,1,6.7,37.58l-1.091-6H33.929l-1.091,6a.7.7,0,0,1-.685.571H15.2a.666.666,0,0,0,0,1.332H29.556c0,1.8,0,3.551,0,5.328a.666.666,0,0,0,1.332,0c0-1.777,0-3.526,0-5.326h1.265a2.027,2.027,0,0,0,2-1.666l1.134-6.242h1.854a.666.666,0,0,0,0-1.332H33.885V28.307a.666.666,0,0,0-.666-.666H32.043V26.194h5.094a.666.666,0,0,0,0-1.332H32.043V22.749a.666.666,0,0,0-.666-.666H27.949a.666.666,0,0,0-.662.589,6.957,6.957,0,0,0-2.549-2.026,5.681,5.681,0,0,0,.78-2.142,9.629,9.629,0,0,0,2.053.1L29,21.025a.672.672,0,0,0,1.147,0l1.43-2.417H42.4a4.526,4.526,0,0,0,4.521-4.52V7.936a.666.666,0,0,0-.666-.666ZM19.768,6.828a5.653,5.653,0,0,1,5.509,6.961,2.309,2.309,0,0,0-2-1.157H16.262a2.31,2.31,0,0,0-2,1.159,5.653,5.653,0,0,1,5.509-6.963Zm0,19.15-1.625-2.789a5.932,5.932,0,0,0,3.251,0Zm.013-3.9a4.448,4.448,0,0,1-4.493-4.418V14.919a.967.967,0,0,1,.973-.955h7.012a.966.966,0,0,1,.973.957v2.743a4.447,4.447,0,0,1-4.466,4.418ZM12.106,26.97a5.623,5.623,0,0,1,3.548-5.243s2.39,3.939,3.434,5.731v2.785H12.106Zm20.446,3.274H26.773v-1.27h5.779Zm-3.937-6.829h2.1v4.226h-2.1Zm-1.333,2.268v1.958H26.107a.666.666,0,0,0-.666.666v1.936h-5.02V27.5c1.052-1.805,3.46-5.776,3.46-5.776a5.623,5.623,0,0,1,3.4,3.956Z"
            fill="white"
            stroke="white"
            strokeWidth="1"
          />
          <path
            data-name="Path 80"
            d="M300.895,36.819a.666.666,0,0,0-.666-.666H294.12v-.863a.666.666,0,0,0-1.332,0v.863h-6.108a.666.666,0,1,0,0,1.332h1.477l-1.095,2.869a2.207,2.207,0,1,0,4.124,0l-1.095-2.869h2.7v7.764h-2.949a.666.666,0,1,0,0,1.332h7.231a.666.666,0,1,0,0-1.332H294.12V37.485h2.7l-1.095,2.869a2.207,2.207,0,1,0,4.124,0l-1.095-2.869h1.476a.666.666,0,0,0,.666-.666Zm-11.052,4.818a.876.876,0,0,1-1.537-.808l.817-2.141.817,2.141A.863.863,0,0,1,289.843,41.636Zm8.662,0a.876.876,0,0,1-1.537-.808l.817-2.141.817,2.141A.863.863,0,0,1,298.505,41.636Z"
            transform="translate(-259.012 -31.548)"
            fill="white"
            stroke="white"
            strokeWidth="1"
          />
        </g>
      </svg>
    ),
    layout: '/',
    type: true,
  },
  {
    id: 11,
    path: '/contracts',
    name: 'العقود',
    permission: 'قراءة-العقود',
    img: (
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
        style={{ enableBackground: 'new 0 0 512 512' }}
        xmlSpace="preserve"
        fill="rgb(189, 35, 60)"
      >
        <g>
          <path
            d="M333.8,149.8H110.8c-6.5,0-11.8,5.3-11.8,11.8s5.3,11.8,11.8,11.8h222.9c6.5,0,11.8-5.3,11.8-11.8S340.3,149.8,333.8,149.8

                                        z"
          />

          <path d="M110.8,126h111.5c6.5,0,11.8-5.3,11.8-11.8s-5.3-11.8-11.8-11.8H110.8c-6.5,0-11.8,5.3-11.8,11.8S104.3,126,110.8,126z" />

          <path
            d="M333.8,191.9H110.8c-6.5,0-11.8,5.3-11.8,11.8s5.3,11.8,11.8,11.8h222.9c6.5,0,11.8-5.3,11.8-11.8

                                        C345.6,197.2,340.3,191.9,333.8,191.9z"
          />

          <path
            d="M470.8,362.2l-58-97.9c-2.7-4.6-7.1-7.9-12.3-9.2c-5.2-1.3-10.6-0.6-15.2,2.2l-14.1,8.4c-11.1,6.6-17,19.3-15.3,31.8

                                        c-2.4,0.2-4.7-0.2-7-1l-0.3-0.1c-2.7-0.9-5.3-2-8-3.1c-7.7-3.3-16.4-7-28.3-4c-9.1,2.3-14.6,3.7-18,4.6c-2.8,0.7-4.4,1.1-5.3,1.4

                                        c-13.8-7.6-23.6-10-39.4-3.8l-13.2,5.1c-4.7,1.8-9.8,1.4-14.1-1.2l-0.8-0.5c0.7-11.8-5.2-22.9-15.5-29.1l-14.1-8.4

                                        c-9.5-5.6-21.8-2.5-27.4,7l-58,97.9c-2.7,4.6-3.5,10-2.2,15.2c1.3,5.2,4.6,9.5,9.2,12.3l14.1,8.4c4.9,2.9,10.5,4.4,16.1,4.4

                                        c2.2,0,4.4-0.2,6.5-0.7c-3.8,5.8-5.2,12.8-4.1,19.7c1.2,7.4,5.2,13.8,11.3,18.2c1.6,1.2,3.3,2.1,5.1,2.9c-0.3,0-0.6,0-0.8,0H90.3

                                        c-9,0-16.2-7.3-16.2-16.2V64.5c0-9,7.3-16.2,16.2-16.2h202.5v37.9c0,22,17.9,39.9,39.9,39.9h37.9v113.6c0,6.5,5.3,11.8,11.8,11.8

                                        s11.8-5.3,11.8-11.8V123c0-8.8-3.4-17.2-9.7-23.4l-65.3-65.3c-6.3-6.3-14.6-9.7-23.4-9.7H90.3c-22,0-39.9,17.9-39.9,39.9v361.8

                                        c0,22,17.9,39.9,39.9,39.9h71.3c6.5,0,11.8-5.3,11.8-11.8c0-4.3-2.3-8-5.7-10.1c1.8,0.4,3.7,0.6,5.6,0.6

                                        c1.9,5.4,5.4,10.1,10.1,13.6c4.8,3.4,10.3,5.2,16.1,5.2c1.9,5.4,5.4,10.1,10.1,13.5c6.7,4.8,15,6.4,22.8,4.5

                                        c1.9,3.3,4.5,6.1,7.7,8.3c10.6,7.6,25.1,6.7,34.7-1.7l13.1,7.8c4.4,2.6,9.3,3.9,14.2,3.9c2.3,0,4.7-0.3,7-0.9

                                        c7.2-1.9,13.3-6.4,17.1-12.8c0.9-1.5,1.6-3,2.2-4.5c12.1,3.8,25.7-1.1,32.4-12.4c1.3-2.2,2.3-4.5,2.9-6.9

                                        c10.2,0.5,20.2-4.6,25.6-13.7c1.1-1.8,1.9-3.7,2.5-5.6c11.2,1.9,22.7-3.2,28.7-13.3c3.8-6.4,4.9-14,3-21.2

                                        c-0.6-2.2-1.4-4.3-2.4-6.2l2.5-1.4c8.7,2.8,18,1.8,25.9-2.9l14.1-8.4c4.6-2.7,7.9-7.1,9.2-12.3

                                        C474.3,372.2,473.6,366.9,470.8,362.2z M316.5,86.1V65l37.3,37.3h-21.1C323.8,102.3,316.5,95.1,316.5,86.1z M317.6,387.5

                                        c-3.3,5.6-1.5,12.9,4.2,16.2l45.8,27.2c1,0.6,1.7,1.5,2,2.6c0.3,1.1,0.1,2.3-0.5,3.2c-1.2,2-3.8,2.7-5.9,1.5L317.4,411

                                        c-5.6-3.3-12.9-1.5-16.2,4.2c-3.3,5.6-1.5,12.9,4.2,16.2l33.6,19.9l0.2,0.1c2,1.2,2.7,3.8,1.5,5.8c-1.2,2-3.8,2.7-5.9,1.5

                                        l-17.6-10.4l-1-1h-0.7l-12-7.1c-2.7-1.6-5.9-2.1-9-1.3c-3.1,0.8-5.6,2.7-7.3,5.4c0,0,0,0,0,0.1c-1.9-3.3-4.6-6.3-7.9-8.7

                                        c-2-1.4-4.1-2.6-6.4-3.4c1.7-10.3-2.5-21-11.3-27.3c-4.7-3.4-10.3-5.2-16.1-5.2c-1.9-5.4-5.4-10.1-10.1-13.6

                                        c-9.6-6.9-22.2-7-31.7-0.7c-1.4-1.5-2.9-2.9-4.6-4.1c-6.5-4.7-14.6-6.2-22.1-4.6l35.5-60c10.1,5.1,21.8,5.7,32.4,1.6l13.2-5.1

                                        c4-1.6,6.6-2.2,9.4-1.7c-3.1,5.4-4.8,11.6-4.8,17.9v30.4c0,10.8,8.8,19.6,19.6,19.6c9.4,0,18.3-3.7,24.9-10.3c2.4-2.4,4.4-5.1,6-8

                                        l15.5,9.2c25,14.8,64,37.9,70,41.5c1,0.6,1.7,1.5,2,2.6c0.3,1.1,0.1,2.2-0.5,3.2c-1.2,2-3.8,2.7-5.8,1.5l-60.6-35.9

                                        C328.2,380,320.9,381.8,317.6,387.5z M290.8,460.3c0.1,0.1,0.3,0.2,0.5,0.3l13,7.7l0.9,0.7c1.4,1.3,1.8,3.5,0.7,5.3

                                        c-1.2,2-3.8,2.7-5.9,1.5l-11.4-6.7C290,466.2,290.6,463.2,290.8,460.3z M254,470.8c-1.9-1.4-2.3-4-1-5.9l6.4-8.9

                                        c0.7-0.9,1.6-1.5,2.8-1.7c0.2,0,0.5-0.1,0.7-0.1c0.9,0,1.7,0.3,2.5,0.8c0.9,0.7,1.5,1.6,1.7,2.8c0.2,1.1-0.1,2.3-0.7,3.2l-6.4,8.9

                                        c-0.7,0.9-1.6,1.5-2.8,1.7C256.1,471.7,255,471.5,254,470.8L254,470.8z M170.2,414.4l9.1-12.7c0.7-0.9,1.6-1.5,2.8-1.7

                                        c0.2,0,0.5-0.1,0.7-0.1c0.9,0,1.7,0.3,2.5,0.8c1.9,1.4,2.3,4,1,6l-9.1,12.7c-0.7,0.9-1.6,1.5-2.8,1.7c-1.1,0.2-2.3-0.1-3.2-0.7

                                        c-0.9-0.7-1.5-1.7-1.7-2.8S169.6,415.4,170.2,414.4z M196.4,433.2l9.3-13l9.9-13.7c0.7-0.9,1.6-1.5,2.8-1.7

                                        c1.1-0.2,2.3,0.1,3.2,0.7c0.9,0.7,1.5,1.6,1.7,2.8c0.2,1.1-0.1,2.2-0.7,3.2l-19.2,26.7c-0.7,0.9-1.6,1.5-2.8,1.7

                                        c-1.1,0.2-2.3-0.1-3.2-0.7c-0.9-0.7-1.5-1.6-1.7-2.8C195.5,435.3,195.7,434.2,196.4,433.2z M223.6,458c-1.8-1.3-2.3-3.8-1.2-5.7

                                        l0.2-0.2l0.2-0.4l19-26.4c0.7-0.9,1.6-1.5,2.8-1.7c1.1-0.2,2.3,0.1,3.2,0.7c0.9,0.7,1.5,1.6,1.7,2.8s-0.1,2.3-0.7,3.2L229.5,457

                                        c-0.7,0.9-1.6,1.5-2.8,1.7C225.6,458.9,224.5,458.6,223.6,458L223.6,458z M388.7,334.2c-5.6,3.3-7.5,10.6-4.2,16.2l20.2,34.1

                                        l-1.6,0.9c-0.4,0.2-0.8,0.5-1.2,0.7l-90.3-53.5c-1.9-1.1-4-1.7-6-1.7c-2,0-4,0.5-5.9,1.6c-3.7,2.1-5.9,5.9-6,10.1

                                        c0,0.2,0,0.4,0,0.6c0.1,3.7-1.1,6.7-3.4,9.1c-1.2,1.2-2.6,2.1-4.1,2.7v-25.6c0-5.5,3.7-10.3,9.1-11.6l22.7-5.8c4-1,6.6,0,12.7,2.6

                                        c2.7,1.2,5.8,2.5,9.7,3.8c8.3,3.2,17.3,3.5,25.7,1l2,3.4c1.6,2.7,4.2,4.7,7.3,5.4c3.1,0.8,6.3,0.3,9-1.3c5.6-3.3,7.5-10.6,4.2-16.2

                                        l-8.1-13.7c-2.3-3.8-1-8.8,2.8-11.1l11-6.5l54.3,91.6l-10.9,6.5c-3.8,2.3-8.8,1-11.1-2.8L405,338.4

                                        C401.6,332.8,394.4,330.9,388.7,334.2z M182.9,279.5l10.9,6.5c3.8,2.3,5.1,7.2,2.8,11.1l-46,77.7c-1.1,1.9-2.9,3.2-4.9,3.7

                                        c-2.1,0.5-4.3,0.2-6.1-0.9l-11-6.5L182.9,279.5z"
          />
        </g>
      </svg>
    ),
    imgActive: (
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
        fill="white"
        stroke="white"
        style={{ enableBackground: 'new 0 0 512 512' }}
        xmlSpace="preserve"
      >
        <g>
          <path
            style={{ fill: 'white' }}
            d="M333.8,149.8H110.8c-6.5,0-11.8,5.3-11.8,11.8s5.3,11.8,11.8,11.8h222.9c6.5,0,11.8-5.3,11.8-11.8S340.3,149.8,333.8,149.8

                                        z"
          ></path>

          <path
            style={{ fill: 'white' }}
            d="M110.8,126h111.5c6.5,0,11.8-5.3,11.8-11.8s-5.3-11.8-11.8-11.8H110.8c-6.5,0-11.8,5.3-11.8,11.8S104.3,126,110.8,126z"
          ></path>

          <path
            style={{ fill: 'white' }}
            d="M333.8,191.9H110.8c-6.5,0-11.8,5.3-11.8,11.8s5.3,11.8,11.8,11.8h222.9c6.5,0,11.8-5.3,11.8-11.8

                                        C345.6,197.2,340.3,191.9,333.8,191.9z"
          ></path>

          <path
            style={{ fill: 'white' }}
            d="M470.8,362.2l-58-97.9c-2.7-4.6-7.1-7.9-12.3-9.2c-5.2-1.3-10.6-0.6-15.2,2.2l-14.1,8.4c-11.1,6.6-17,19.3-15.3,31.8

                                        c-2.4,0.2-4.7-0.2-7-1l-0.3-0.1c-2.7-0.9-5.3-2-8-3.1c-7.7-3.3-16.4-7-28.3-4c-9.1,2.3-14.6,3.7-18,4.6c-2.8,0.7-4.4,1.1-5.3,1.4

                                        c-13.8-7.6-23.6-10-39.4-3.8l-13.2,5.1c-4.7,1.8-9.8,1.4-14.1-1.2l-0.8-0.5c0.7-11.8-5.2-22.9-15.5-29.1l-14.1-8.4

                                        c-9.5-5.6-21.8-2.5-27.4,7l-58,97.9c-2.7,4.6-3.5,10-2.2,15.2c1.3,5.2,4.6,9.5,9.2,12.3l14.1,8.4c4.9,2.9,10.5,4.4,16.1,4.4

                                        c2.2,0,4.4-0.2,6.5-0.7c-3.8,5.8-5.2,12.8-4.1,19.7c1.2,7.4,5.2,13.8,11.3,18.2c1.6,1.2,3.3,2.1,5.1,2.9c-0.3,0-0.6,0-0.8,0H90.3

                                        c-9,0-16.2-7.3-16.2-16.2V64.5c0-9,7.3-16.2,16.2-16.2h202.5v37.9c0,22,17.9,39.9,39.9,39.9h37.9v113.6c0,6.5,5.3,11.8,11.8,11.8

                                        s11.8-5.3,11.8-11.8V123c0-8.8-3.4-17.2-9.7-23.4l-65.3-65.3c-6.3-6.3-14.6-9.7-23.4-9.7H90.3c-22,0-39.9,17.9-39.9,39.9v361.8

                                        c0,22,17.9,39.9,39.9,39.9h71.3c6.5,0,11.8-5.3,11.8-11.8c0-4.3-2.3-8-5.7-10.1c1.8,0.4,3.7,0.6,5.6,0.6

                                        c1.9,5.4,5.4,10.1,10.1,13.6c4.8,3.4,10.3,5.2,16.1,5.2c1.9,5.4,5.4,10.1,10.1,13.5c6.7,4.8,15,6.4,22.8,4.5

                                        c1.9,3.3,4.5,6.1,7.7,8.3c10.6,7.6,25.1,6.7,34.7-1.7l13.1,7.8c4.4,2.6,9.3,3.9,14.2,3.9c2.3,0,4.7-0.3,7-0.9

                                        c7.2-1.9,13.3-6.4,17.1-12.8c0.9-1.5,1.6-3,2.2-4.5c12.1,3.8,25.7-1.1,32.4-12.4c1.3-2.2,2.3-4.5,2.9-6.9

                                        c10.2,0.5,20.2-4.6,25.6-13.7c1.1-1.8,1.9-3.7,2.5-5.6c11.2,1.9,22.7-3.2,28.7-13.3c3.8-6.4,4.9-14,3-21.2

                                        c-0.6-2.2-1.4-4.3-2.4-6.2l2.5-1.4c8.7,2.8,18,1.8,25.9-2.9l14.1-8.4c4.6-2.7,7.9-7.1,9.2-12.3

                                        C474.3,372.2,473.6,366.9,470.8,362.2z M316.5,86.1V65l37.3,37.3h-21.1C323.8,102.3,316.5,95.1,316.5,86.1z M317.6,387.5

                                        c-3.3,5.6-1.5,12.9,4.2,16.2l45.8,27.2c1,0.6,1.7,1.5,2,2.6c0.3,1.1,0.1,2.3-0.5,3.2c-1.2,2-3.8,2.7-5.9,1.5L317.4,411

                                        c-5.6-3.3-12.9-1.5-16.2,4.2c-3.3,5.6-1.5,12.9,4.2,16.2l33.6,19.9l0.2,0.1c2,1.2,2.7,3.8,1.5,5.8c-1.2,2-3.8,2.7-5.9,1.5

                                        l-17.6-10.4l-1-1h-0.7l-12-7.1c-2.7-1.6-5.9-2.1-9-1.3c-3.1,0.8-5.6,2.7-7.3,5.4c0,0,0,0,0,0.1c-1.9-3.3-4.6-6.3-7.9-8.7

                                        c-2-1.4-4.1-2.6-6.4-3.4c1.7-10.3-2.5-21-11.3-27.3c-4.7-3.4-10.3-5.2-16.1-5.2c-1.9-5.4-5.4-10.1-10.1-13.6

                                        c-9.6-6.9-22.2-7-31.7-0.7c-1.4-1.5-2.9-2.9-4.6-4.1c-6.5-4.7-14.6-6.2-22.1-4.6l35.5-60c10.1,5.1,21.8,5.7,32.4,1.6l13.2-5.1

                                        c4-1.6,6.6-2.2,9.4-1.7c-3.1,5.4-4.8,11.6-4.8,17.9v30.4c0,10.8,8.8,19.6,19.6,19.6c9.4,0,18.3-3.7,24.9-10.3c2.4-2.4,4.4-5.1,6-8

                                        l15.5,9.2c25,14.8,64,37.9,70,41.5c1,0.6,1.7,1.5,2,2.6c0.3,1.1,0.1,2.2-0.5,3.2c-1.2,2-3.8,2.7-5.8,1.5l-60.6-35.9

                                        C328.2,380,320.9,381.8,317.6,387.5z M290.8,460.3c0.1,0.1,0.3,0.2,0.5,0.3l13,7.7l0.9,0.7c1.4,1.3,1.8,3.5,0.7,5.3

                                        c-1.2,2-3.8,2.7-5.9,1.5l-11.4-6.7C290,466.2,290.6,463.2,290.8,460.3z M254,470.8c-1.9-1.4-2.3-4-1-5.9l6.4-8.9

                                        c0.7-0.9,1.6-1.5,2.8-1.7c0.2,0,0.5-0.1,0.7-0.1c0.9,0,1.7,0.3,2.5,0.8c0.9,0.7,1.5,1.6,1.7,2.8c0.2,1.1-0.1,2.3-0.7,3.2l-6.4,8.9

                                        c-0.7,0.9-1.6,1.5-2.8,1.7C256.1,471.7,255,471.5,254,470.8L254,470.8z M170.2,414.4l9.1-12.7c0.7-0.9,1.6-1.5,2.8-1.7

                                        c0.2,0,0.5-0.1,0.7-0.1c0.9,0,1.7,0.3,2.5,0.8c1.9,1.4,2.3,4,1,6l-9.1,12.7c-0.7,0.9-1.6,1.5-2.8,1.7c-1.1,0.2-2.3-0.1-3.2-0.7

                                        c-0.9-0.7-1.5-1.7-1.7-2.8S169.6,415.4,170.2,414.4z M196.4,433.2l9.3-13l9.9-13.7c0.7-0.9,1.6-1.5,2.8-1.7

                                        c1.1-0.2,2.3,0.1,3.2,0.7c0.9,0.7,1.5,1.6,1.7,2.8c0.2,1.1-0.1,2.2-0.7,3.2l-19.2,26.7c-0.7,0.9-1.6,1.5-2.8,1.7

                                        c-1.1,0.2-2.3-0.1-3.2-0.7c-0.9-0.7-1.5-1.6-1.7-2.8C195.5,435.3,195.7,434.2,196.4,433.2z M223.6,458c-1.8-1.3-2.3-3.8-1.2-5.7

                                        l0.2-0.2l0.2-0.4l19-26.4c0.7-0.9,1.6-1.5,2.8-1.7c1.1-0.2,2.3,0.1,3.2,0.7c0.9,0.7,1.5,1.6,1.7,2.8s-0.1,2.3-0.7,3.2L229.5,457

                                        c-0.7,0.9-1.6,1.5-2.8,1.7C225.6,458.9,224.5,458.6,223.6,458L223.6,458z M388.7,334.2c-5.6,3.3-7.5,10.6-4.2,16.2l20.2,34.1

                                        l-1.6,0.9c-0.4,0.2-0.8,0.5-1.2,0.7l-90.3-53.5c-1.9-1.1-4-1.7-6-1.7c-2,0-4,0.5-5.9,1.6c-3.7,2.1-5.9,5.9-6,10.1

                                        c0,0.2,0,0.4,0,0.6c0.1,3.7-1.1,6.7-3.4,9.1c-1.2,1.2-2.6,2.1-4.1,2.7v-25.6c0-5.5,3.7-10.3,9.1-11.6l22.7-5.8c4-1,6.6,0,12.7,2.6

                                        c2.7,1.2,5.8,2.5,9.7,3.8c8.3,3.2,17.3,3.5,25.7,1l2,3.4c1.6,2.7,4.2,4.7,7.3,5.4c3.1,0.8,6.3,0.3,9-1.3c5.6-3.3,7.5-10.6,4.2-16.2

                                        l-8.1-13.7c-2.3-3.8-1-8.8,2.8-11.1l11-6.5l54.3,91.6l-10.9,6.5c-3.8,2.3-8.8,1-11.1-2.8L405,338.4

                                        C401.6,332.8,394.4,330.9,388.7,334.2z M182.9,279.5l10.9,6.5c3.8,2.3,5.1,7.2,2.8,11.1l-46,77.7c-1.1,1.9-2.9,3.2-4.9,3.7

                                        c-2.1,0.5-4.3,0.2-6.1-0.9l-11-6.5L182.9,279.5z"
          ></path>
        </g>
      </svg>
    ),
    layout: '/',
    type: true,
  },
  {
    id: 4,
    path: '/reports',
    name: 'تبادل التقارير',
    permission: 'قراءة-تبادل-التقارير',
    img: (
      <svg
        className="home_menu_svg"
        xmlns="http://www.w3.org/2000/svg"
        width="28.5"
        height="25.501"
        viewBox="0 0 38.129 38.129"
      >
        <g id="paper" transform="translate(-1.5 -1.5)">
          <path
            data-name="Path 122"
            d="M16.574,22.156H2.53a.53.53,0,0,1-.53-.53V2.53A.53.53,0,0,1,2.53,2h9.551a.53.53,0,0,1,.375.155l4.493,4.49a.53.53,0,0,1,.155.375v14.6A.53.53,0,0,1,16.574,22.156ZM3.061,21.095H16.043V7.24l-4.182-4.18h-8.8Z"
            fill="#ca2946"
            stroke="#ca2946"
            strokeWidth="1"
          />
          <path
            data-name="Path 123"
            d="M25.029,7.557H20.536a.53.53,0,0,1-.53-.53V2.53a.53.53,0,1,1,1.061,0V6.5h3.962a.53.53,0,0,1,0,1.061Z"
            transform="translate(-8.455 0)"
            fill="#ca2946"
            stroke="#ca2946"
            strokeWidth="1"
          />
          <path
            data-name="Path 124"
            d="M16.315,31.482H6.77a.53.53,0,1,1,0-1.061h9.545a.53.53,0,1,1,0,1.061Z"
            transform="translate(-1.991 -13.346)"
            fill="#ca2946"
            stroke="#ca2946"
            strokeWidth="1"
          />
          <path
            data-name="Path 125"
            d="M16.315,24.535H6.77a.53.53,0,1,1,0-1.061h9.545a.53.53,0,1,1,0,1.061Z"
            transform="translate(-1.991 -10.084)"
            fill="#ca2946"
            stroke="#ca2946"
            strokeWidth="1"
          />
          <path
            data-name="Path 126"
            d="M16.315,17.587H6.77a.53.53,0,1,1,0-1.061h9.545a.53.53,0,1,1,0,1.061Z"
            transform="translate(-1.991 -6.821)"
            fill="#ca2946"
            stroke="#ca2946"
            strokeWidth="1"
          />
          <path
            data-name="Path 127"
            d="M11.826,10.64H6.77a.53.53,0,1,1,0-1.061h5.055a.53.53,0,1,1,0,1.061Z"
            transform="translate(-1.991 -3.559)"
            fill="#ca2946"
            stroke="#ca2946"
            strokeWidth="1"
          />
          <path
            data-name="Path 128"
            d="M58.1,54.156H44.054a.53.53,0,0,1-.53-.53V34.53a.53.53,0,0,1,.53-.53H53.6a.53.53,0,0,1,.375.155l4.493,4.49a.53.53,0,0,1,.155.375v14.6A.53.53,0,0,1,58.1,54.156ZM44.585,53.095H57.567V39.24l-4.182-4.18h-8.8Z"
            transform="translate(-19.499 -15.027)"
            fill="#ca2946"
            stroke="#ca2946"
            strokeWidth="1"
          />
          <path
            data-name="Path 129"
            d="M66.553,39.557H62.059a.53.53,0,0,1-.53-.53v-4.5a.53.53,0,1,1,1.061,0V38.5h3.963a.53.53,0,1,1,0,1.061Z"
            transform="translate(-27.954 -15.027)"
            fill="#ca2946"
            stroke="#ca2946"
            strokeWidth="1"
          />
          <path
            data-name="Path 130"
            d="M57.838,63.482H48.29a.53.53,0,1,1,0-1.061h9.547a.53.53,0,1,1,0,1.061Z"
            transform="translate(-21.488 -28.373)"
            fill="#ca2946"
            stroke="#ca2946"
            strokeWidth="1"
          />
          <path
            data-name="Path 131"
            d="M57.838,56.535H48.29a.53.53,0,1,1,0-1.061h9.547a.53.53,0,1,1,0,1.061Z"
            transform="translate(-21.488 -25.111)"
            fill="#ca2946"
            stroke="#ca2946"
            strokeWidth="1"
          />
          <path
            data-name="Path 132"
            d="M57.838,49.587H48.29a.53.53,0,1,1,0-1.061h9.547a.53.53,0,1,1,0,1.061Z"
            transform="translate(-21.488 -21.848)"
            fill="#ca2946"
            stroke="#ca2946"
            strokeWidth="1"
          />
          <path
            data-name="Path 133"
            d="M53.355,42.64H48.3a.53.53,0,0,1,0-1.061h5.055a.53.53,0,0,1,0,1.061Z"
            transform="translate(-21.493 -18.586)"
            fill="#ca2946"
            stroke="#ca2946"
            strokeWidth="1"
          />
          <path
            data-name="Path 134"
            d="M52.737,18.7a.53.53,0,0,1-.53-.53V11.211H44.78a.53.53,0,1,1,0-1.061h7.956a.53.53,0,0,1,.53.53v7.488A.53.53,0,0,1,52.737,18.7Z"
            transform="translate(-19.84 -3.827)"
            fill="#ca2946"
            stroke="#ca2946"
            strokeWidth="1"
          />
          <path
            data-name="Path 135"
            d="M46.516,11.41a.53.53,0,0,1-.375-.155L44.406,9.519a.53.53,0,0,1,0-.75l1.736-1.736a.53.53,0,1,1,.75.75l-1.361,1.36L46.891,10.5a.53.53,0,0,1-.375.905Z"
            transform="translate(-19.84 -2.291)"
            fill="#ca2946"
            stroke="#ca2946"
            strokeWidth="1"
          />
          <path
            data-name="Path 136"
            d="M21.237,56.281H13.28a.53.53,0,0,1-.53-.53V48.262a.53.53,0,0,1,1.061,0V55.22h7.426a.53.53,0,0,1,0,1.061Z"
            transform="translate(-5.048 -21.475)"
            fill="#ca2946"
            stroke="#ca2946"
            strokeWidth="1"
          />
          <path
            data-name="Path 137"
            d="M25.009,63.11a.53.53,0,0,1-.376-.905l1.361-1.361-1.361-1.361a.531.531,0,1,1,.751-.75l1.735,1.736a.53.53,0,0,1,0,.75l-1.735,1.736A.53.53,0,0,1,25.009,63.11Z"
            transform="translate(-10.555 -26.568)"
            fill="#ca2946"
            stroke="#ca2946"
            strokeWidth="1"
          />
        </g>
      </svg>
    ),
    imgActive: (
      <svg
        className="home_menu_svg active_svg"
        xmlns="http://www.w3.org/2000/svg"
        width="28.5"
        height="25.501"
        viewBox="0 0 38.129 38.129"
      >
        <g id="paper" transform="translate(-1.5 -1.5)">
          <path
            data-name="Path 122"
            d="M16.574,22.156H2.53a.53.53,0,0,1-.53-.53V2.53A.53.53,0,0,1,2.53,2h9.551a.53.53,0,0,1,.375.155l4.493,4.49a.53.53,0,0,1,.155.375v14.6A.53.53,0,0,1,16.574,22.156ZM3.061,21.095H16.043V7.24l-4.182-4.18h-8.8Z"
            fill="white"
            stroke="white"
            strokeWidth="1"
          />
          <path
            data-name="Path 123"
            d="M25.029,7.557H20.536a.53.53,0,0,1-.53-.53V2.53a.53.53,0,1,1,1.061,0V6.5h3.962a.53.53,0,0,1,0,1.061Z"
            transform="translate(-8.455 0)"
            fill="white"
            stroke="white"
            strokeWidth="1"
          />
          <path
            data-name="Path 124"
            d="M16.315,31.482H6.77a.53.53,0,1,1,0-1.061h9.545a.53.53,0,1,1,0,1.061Z"
            transform="translate(-1.991 -13.346)"
            fill="white"
            stroke="white"
            strokeWidth="1"
          />
          <path
            data-name="Path 125"
            d="M16.315,24.535H6.77a.53.53,0,1,1,0-1.061h9.545a.53.53,0,1,1,0,1.061Z"
            transform="translate(-1.991 -10.084)"
            fill="white"
            stroke="white"
            strokeWidth="1"
          />
          <path
            data-name="Path 126"
            d="M16.315,17.587H6.77a.53.53,0,1,1,0-1.061h9.545a.53.53,0,1,1,0,1.061Z"
            transform="translate(-1.991 -6.821)"
            fill="white"
            stroke="white"
            strokeWidth="1"
          />
          <path
            data-name="Path 127"
            d="M11.826,10.64H6.77a.53.53,0,1,1,0-1.061h5.055a.53.53,0,1,1,0,1.061Z"
            transform="translate(-1.991 -3.559)"
            fill="white"
            stroke="white"
            strokeWidth="1"
          />
          <path
            data-name="Path 128"
            d="M58.1,54.156H44.054a.53.53,0,0,1-.53-.53V34.53a.53.53,0,0,1,.53-.53H53.6a.53.53,0,0,1,.375.155l4.493,4.49a.53.53,0,0,1,.155.375v14.6A.53.53,0,0,1,58.1,54.156ZM44.585,53.095H57.567V39.24l-4.182-4.18h-8.8Z"
            transform="translate(-19.499 -15.027)"
            fill="white"
            stroke="white"
            strokeWidth="1"
          />
          <path
            data-name="Path 129"
            d="M66.553,39.557H62.059a.53.53,0,0,1-.53-.53v-4.5a.53.53,0,1,1,1.061,0V38.5h3.963a.53.53,0,1,1,0,1.061Z"
            transform="translate(-27.954 -15.027)"
            fill="white"
            stroke="white"
            strokeWidth="1"
          />
          <path
            data-name="Path 130"
            d="M57.838,63.482H48.29a.53.53,0,1,1,0-1.061h9.547a.53.53,0,1,1,0,1.061Z"
            transform="translate(-21.488 -28.373)"
            fill="white"
            stroke="white"
            strokeWidth="1"
          />
          <path
            data-name="Path 131"
            d="M57.838,56.535H48.29a.53.53,0,1,1,0-1.061h9.547a.53.53,0,1,1,0,1.061Z"
            transform="translate(-21.488 -25.111)"
            fill="white"
            stroke="white"
            strokeWidth="1"
          />
          <path
            data-name="Path 132"
            d="M57.838,49.587H48.29a.53.53,0,1,1,0-1.061h9.547a.53.53,0,1,1,0,1.061Z"
            transform="translate(-21.488 -21.848)"
            fill="white"
            stroke="white"
            strokeWidth="1"
          />
          <path
            data-name="Path 133"
            d="M53.355,42.64H48.3a.53.53,0,0,1,0-1.061h5.055a.53.53,0,0,1,0,1.061Z"
            transform="translate(-21.493 -18.586)"
            fill="white"
            stroke="white"
            strokeWidth="1"
          />
          <path
            data-name="Path 134"
            d="M52.737,18.7a.53.53,0,0,1-.53-.53V11.211H44.78a.53.53,0,1,1,0-1.061h7.956a.53.53,0,0,1,.53.53v7.488A.53.53,0,0,1,52.737,18.7Z"
            transform="translate(-19.84 -3.827)"
            fill="white"
            stroke="white"
            strokeWidth="1"
          />
          <path
            data-name="Path 135"
            d="M46.516,11.41a.53.53,0,0,1-.375-.155L44.406,9.519a.53.53,0,0,1,0-.75l1.736-1.736a.53.53,0,1,1,.75.75l-1.361,1.36L46.891,10.5a.53.53,0,0,1-.375.905Z"
            transform="translate(-19.84 -2.291)"
            fill="white"
            stroke="white"
            strokeWidth="1"
          />
          <path
            data-name="Path 136"
            d="M21.237,56.281H13.28a.53.53,0,0,1-.53-.53V48.262a.53.53,0,0,1,1.061,0V55.22h7.426a.53.53,0,0,1,0,1.061Z"
            transform="translate(-5.048 -21.475)"
            fill="white"
            stroke="white"
            strokeWidth="1"
          />
          <path
            data-name="Path 137"
            d="M25.009,63.11a.53.53,0,0,1-.376-.905l1.361-1.361-1.361-1.361a.531.531,0,1,1,.751-.75l1.735,1.736a.53.53,0,0,1,0,.75l-1.735,1.736A.53.53,0,0,1,25.009,63.11Z"
            transform="translate(-10.555 -26.568)"
            fill="white"
            stroke="white"
            strokeWidth="1"
          />
        </g>
      </svg>
    ),
    layout: '/',
    type: true,
  },
  {
    id: 5,
    path: '/appeals',
    name: 'الطعون',
    permission: 'قراءة-الطعون',
    img: (
      <svg
        className="home_menu_svg"
        xmlns="http://www.w3.org/2000/svg"
        width="28.5"
        height="25.501"
        viewBox="0 0 37.322 33.663"
      >
        <g id="browser" transform="translate(0 -30)">
          <path
            data-name="Path 107"
            d="M31.7,30H5.6A5.611,5.611,0,0,0,0,35.651V57.862a5.8,5.8,0,0,0,5.812,5.8h25.7a5.8,5.8,0,0,0,5.812-5.8V35.651A5.6,5.6,0,0,0,31.7,30Zm-.188,1.83a3.99,3.99,0,0,1,3.983,3.988v1.5H1.83v-1.5A3.99,3.99,0,0,1,5.812,31.83Zm0,30H5.812A3.976,3.976,0,0,1,1.83,57.862V39.208H35.492V57.862A3.976,3.976,0,0,1,31.509,61.833Z"
            fill="#ca2946"
          />
          <path
            data-name="Path 108"
            d="M94.181,102.526a1.047,1.047,0,1,0-1.047-1.047A1.047,1.047,0,0,0,94.181,102.526Z"
            transform="translate(-87.454 -66.136)"
            fill="#ca2946"
          />
          <path
            data-name="Path 109"
            d="M151.378,102.526a1.047,1.047,0,1,0-1.047-1.047A1.046,1.046,0,0,0,151.378,102.526Z"
            transform="translate(-141.163 -66.136)"
            fill="#ca2946"
          />
          <path
            data-name="Path 110"
            d="M208.584,102.526a1.047,1.047,0,1,0-1.046-1.047A1.046,1.046,0,0,0,208.584,102.526Z"
            transform="translate(-194.882 -66.136)"
            fill="#ca2946"
          />
          <path
            data-name="Path 111"
            d="M248.129,454.245a6.2,6.2,0,0,1-2.583-.587.91.91,0,0,0-1.219.424.87.87,0,0,0,.447,1.175,7.818,7.818,0,0,0,3.343.655h.005a.837.837,0,1,0,.006-1.667Z"
            transform="translate(-229.348 -397.738)"
            fill="#ca2946"
          />
          <path
            data-name="Path 112"
            d="M182.5,380.906a.918.918,0,1,0-1.761.516,8.038,8.038,0,0,0,1.639,3.01.918.918,0,1,0,1.389-1.2A6.218,6.218,0,0,1,182.5,380.906Z"
            transform="translate(-169.682 -328.885)"
            fill="#ca2946"
          />
          <path
            data-name="Path 113"
            d="M193.193,230.024l-1.375.438a8.089,8.089,0,0,0-15.8,2.44.918.918,0,1,0,1.835,0,6.253,6.253,0,0,1,12.219-1.882l-1.47.469a.621.621,0,0,0-.3.969.776.776,0,0,0,.242.2l3,1.555a.613.613,0,0,0,.828-.262l1.55-3a.8.8,0,0,0,.079-.31A.62.62,0,0,0,193.193,230.024Z"
            transform="translate(-165.281 -182.926)"
            fill="#ca2946"
          />
        </g>
      </svg>
    ),
    imgActive: (
      <svg
        className="home_menu_svg active_svg"
        xmlns="http://www.w3.org/2000/svg"
        width="28.5"
        height="25.501"
        viewBox="0 0 37.322 33.663"
      >
        <g id="browser" transform="translate(0 -30)">
          <path
            data-name="Path 107"
            d="M31.7,30H5.6A5.611,5.611,0,0,0,0,35.651V57.862a5.8,5.8,0,0,0,5.812,5.8h25.7a5.8,5.8,0,0,0,5.812-5.8V35.651A5.6,5.6,0,0,0,31.7,30Zm-.188,1.83a3.99,3.99,0,0,1,3.983,3.988v1.5H1.83v-1.5A3.99,3.99,0,0,1,5.812,31.83Zm0,30H5.812A3.976,3.976,0,0,1,1.83,57.862V39.208H35.492V57.862A3.976,3.976,0,0,1,31.509,61.833Z"
            fill="white"
          />
          <path
            data-name="Path 108"
            d="M94.181,102.526a1.047,1.047,0,1,0-1.047-1.047A1.047,1.047,0,0,0,94.181,102.526Z"
            transform="translate(-87.454 -66.136)"
            fill="white"
          />
          <path
            data-name="Path 109"
            d="M151.378,102.526a1.047,1.047,0,1,0-1.047-1.047A1.046,1.046,0,0,0,151.378,102.526Z"
            transform="translate(-141.163 -66.136)"
            fill="white"
          />
          <path
            data-name="Path 110"
            d="M208.584,102.526a1.047,1.047,0,1,0-1.046-1.047A1.046,1.046,0,0,0,208.584,102.526Z"
            transform="translate(-194.882 -66.136)"
            fill="white"
          />
          <path
            data-name="Path 111"
            d="M248.129,454.245a6.2,6.2,0,0,1-2.583-.587.91.91,0,0,0-1.219.424.87.87,0,0,0,.447,1.175,7.818,7.818,0,0,0,3.343.655h.005a.837.837,0,1,0,.006-1.667Z"
            transform="translate(-229.348 -397.738)"
            fill="white"
          />
          <path
            data-name="Path 112"
            d="M182.5,380.906a.918.918,0,1,0-1.761.516,8.038,8.038,0,0,0,1.639,3.01.918.918,0,1,0,1.389-1.2A6.218,6.218,0,0,1,182.5,380.906Z"
            transform="translate(-169.682 -328.885)"
            fill="white"
          />
          <path
            data-name="Path 113"
            d="M193.193,230.024l-1.375.438a8.089,8.089,0,0,0-15.8,2.44.918.918,0,1,0,1.835,0,6.253,6.253,0,0,1,12.219-1.882l-1.47.469a.621.621,0,0,0-.3.969.776.776,0,0,0,.242.2l3,1.555a.613.613,0,0,0,.828-.262l1.55-3a.8.8,0,0,0,.079-.31A.62.62,0,0,0,193.193,230.024Z"
            transform="translate(-165.281 -182.926)"
            fill="white"
          />
        </g>
      </svg>
    ),
    layout: '/admin',
    type: true,
  },
  {
    id: 8,
    path: '/customers',
    name: 'ملفات الحرفاء',
    permission: 'قراءة-ملفات-الحرفاء',
    img: (
      <svg
        className="home_menu_svg"
        xmlns="http://www.w3.org/2000/svg"
        width="28.5"
        height="25.501"
        viewBox="0 0 42.878 38.008"
      >
        <path
          d="M37.128,13.268h-2.66V9.07a4.992,4.992,0,0,0-4.986-4.985H22.488A3.324,3.324,0,0,1,20.3,3.268l-2.229-1.94A4.739,4.739,0,0,0,14.965.164H4.985A4.991,4.991,0,0,0,0,5.149V31.434a5.745,5.745,0,0,0,5.436,5.73v.008H33.681a4.748,4.748,0,0,0,4.645-3.806L41.77,18.951l.006-.027a4.738,4.738,0,0,0-4.648-5.656ZM1.412,31.434V5.149A3.578,3.578,0,0,1,4.986,1.575h9.979a3.326,3.326,0,0,1,2.184.817l2.229,1.94A4.737,4.737,0,0,0,22.488,5.5h6.994a3.579,3.579,0,0,1,3.575,3.575v4.2h-.671a3.87,3.87,0,0,0-3.8-3.158h-.233a3.937,3.937,0,0,0-3.867-3.226H8.259a4.743,4.743,0,0,0-4.738,4.738V35.148a4.327,4.327,0,0,1-2.11-3.714ZM28.588,11.522a2.456,2.456,0,0,1,2.349,1.747H18.952A4.72,4.72,0,0,0,14.346,16.9L9.955,32.4l-.008.03a4.322,4.322,0,0,1-.982,1.888V14.848a3.33,3.33,0,0,1,3.326-3.326ZM4.933,35.684V11.622A3.33,3.33,0,0,1,8.259,8.3H24.489a2.524,2.524,0,0,1,2.419,1.814H12.291a4.743,4.743,0,0,0-4.738,4.738V35.363a4.336,4.336,0,0,1-2.621.321ZM40.394,18.637,36.951,33.051l-.006.028a3.335,3.335,0,0,1-3.263,2.682H9.515a5.726,5.726,0,0,0,1.8-2.988l4.391-15.5.008-.03a3.315,3.315,0,0,1,3.237-2.559H37.128a3.327,3.327,0,0,1,3.266,3.958Zm0,0"
          transform="translate(0.5 0.336)"
          fill="#ca2946"
          stroke="#ca2946"
          strokeWidth="1"
        />
      </svg>
    ),
    imgActive: (
      <svg
        className="home_menu_svg active_svg"
        xmlns="http://www.w3.org/2000/svg"
        width="28.5"
        height="25.501"
        viewBox="0 0 42.878 38.008"
      >
        <path
          d="M37.128,13.268h-2.66V9.07a4.992,4.992,0,0,0-4.986-4.985H22.488A3.324,3.324,0,0,1,20.3,3.268l-2.229-1.94A4.739,4.739,0,0,0,14.965.164H4.985A4.991,4.991,0,0,0,0,5.149V31.434a5.745,5.745,0,0,0,5.436,5.73v.008H33.681a4.748,4.748,0,0,0,4.645-3.806L41.77,18.951l.006-.027a4.738,4.738,0,0,0-4.648-5.656ZM1.412,31.434V5.149A3.578,3.578,0,0,1,4.986,1.575h9.979a3.326,3.326,0,0,1,2.184.817l2.229,1.94A4.737,4.737,0,0,0,22.488,5.5h6.994a3.579,3.579,0,0,1,3.575,3.575v4.2h-.671a3.87,3.87,0,0,0-3.8-3.158h-.233a3.937,3.937,0,0,0-3.867-3.226H8.259a4.743,4.743,0,0,0-4.738,4.738V35.148a4.327,4.327,0,0,1-2.11-3.714ZM28.588,11.522a2.456,2.456,0,0,1,2.349,1.747H18.952A4.72,4.72,0,0,0,14.346,16.9L9.955,32.4l-.008.03a4.322,4.322,0,0,1-.982,1.888V14.848a3.33,3.33,0,0,1,3.326-3.326ZM4.933,35.684V11.622A3.33,3.33,0,0,1,8.259,8.3H24.489a2.524,2.524,0,0,1,2.419,1.814H12.291a4.743,4.743,0,0,0-4.738,4.738V35.363a4.336,4.336,0,0,1-2.621.321ZM40.394,18.637,36.951,33.051l-.006.028a3.335,3.335,0,0,1-3.263,2.682H9.515a5.726,5.726,0,0,0,1.8-2.988l4.391-15.5.008-.03a3.315,3.315,0,0,1,3.237-2.559H37.128a3.327,3.327,0,0,1,3.266,3.958Zm0,0"
          transform="translate(0.5 0.336)"
          fill="white"
          stroke="white"
          strokeWidth="1"
        />
      </svg>
    ),
    layout: '/',
    type: true,
  },
  {
    id: 9,
    path: '/tasks',
    name: 'المهام',
    permission: 'قراءة-المهام',
    img: (
      <svg
        width="28.5"
        height="25.501"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 30 24"
        fill="#ca2946"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path d="M29,4H21V1a1,1,0,0,0-1-1H10A1,1,0,0,0,9,1V4H1A1,1,0,0,0,0,5V23a1,1,0,0,0,1,1H29a1,1,0,0,0,1-1V5A1,1,0,0,0,29,4ZM11,2h8V4H11ZM28,22H2V12.33L14.73,16a1,1,0,0,0,.55,0l8-2.29-.55-1.92L15,14,2,10.25V6H28Z" />
          </g>
        </g>
      </svg>
    ),
    imgActive: (
      <svg
        width="28.5"
        height="25.501"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 30 24"
        fill="#ffffff"
      >
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path d="M29,4H21V1a1,1,0,0,0-1-1H10A1,1,0,0,0,9,1V4H1A1,1,0,0,0,0,5V23a1,1,0,0,0,1,1H29a1,1,0,0,0,1-1V5A1,1,0,0,0,29,4ZM11,2h8V4H11ZM28,22H2V12.33L14.73,16a1,1,0,0,0,.55,0l8-2.29-.55-1.92L15,14,2,10.25V6H28Z" />
          </g>
        </g>
      </svg>
    ),
    layout: '/',
    type: true,
  },
  /*  {
    id: 10,
    path: '/jurisprudence',
    name: 'فقه القضاء',
    img: (
      <svg
        className="home_menu_svg"
        xmlns="http://www.w3.org/2000/svg"
        width="28.5"
        height="25.501"
        viewBox="0 0 36.5 36.501"
      >
        <g id="courthouse" transform="translate(0.5 0.5)">
          <g id="Group_21" data-name="Group 21" transform="translate(0 0.001)">
            <path
              data-name="Path 73"
              d="M31.2,14.021a.52.52,0,0,0,.52-.52V11.687h.461a.52.52,0,0,0,.52-.52V9.445H34.98a.52.52,0,0,0,.52-.52V5a.52.52,0,0,0-.389-.5L17.881.017a.52.52,0,0,0-.262,0L.389,4.5A.52.52,0,0,0,0,5V8.925a.52.52,0,0,0,.52.52H2.8v1.721a.52.52,0,0,0,.52.52h.461v16.05H3.322a.52.52,0,0,0-.52.52v1.721H1.921a.52.52,0,0,0-.52.52v1.721H.52a.52.52,0,0,0-.52.52V34.98a.52.52,0,0,0,.52.52H34.98a.52.52,0,0,0,.52-.52V32.739a.52.52,0,0,0-.52-.52H34.1V30.5a.52.52,0,0,0-.52-.52H32.7V28.257a.52.52,0,0,0-.52-.52h-.461V15.928a.52.52,0,0,0-1.04,0V27.737H26.955V11.687h3.723V13.5a.52.52,0,0,0,.52.52Zm.461-3.374H25.974v-1.2h5.684ZM1.04,5.405,17.75,1.058,34.46,5.405v3H1.04Zm19.552,5.242H14.908v-1.2h5.684ZM9.526,9.445v1.2H3.842v-1.2Zm-4.7,2.241H8.545v4.044a.52.52,0,0,0,1.04,0V11.687h.461a.52.52,0,0,0,.52-.52V9.445h3.3v1.721a.52.52,0,0,0,.52.52h.461v16.05h-.461a.52.52,0,0,0-.52.52v1.721h-3.3V28.257a.52.52,0,0,0-.52-.52H9.585V18.157a.52.52,0,0,0-1.04,0v9.579H4.822Zm15.77,17.09v1.2H14.908v-1.2Zm-16.75,0H9.526v1.2H3.842ZM34.46,34.46H1.04v-1.2H34.46Zm-1.4-2.241H2.441v-1.2H33.059Zm-7.085-2.241v-1.2h5.684v1.2Zm-.059-2.241h-.461a.52.52,0,0,0-.52.52v1.721h-3.3V28.257a.52.52,0,0,0-.52-.52h-.46V22.91a.52.52,0,0,0-1.04,0v4.826H15.889V11.687h3.723v8.8a.52.52,0,0,0,1.04,0v-8.8h.46a.52.52,0,0,0,.52-.52V9.445h3.3v1.721a.52.52,0,0,0,.52.52h.461Z"
              transform="translate(0 -0.001)"
              fill="#ca2946"
              stroke="#ca2946"
              strokeWidth="1"
            />
            <path
              data-name="Path 74"
              d="M223.167,38.227a2.446,2.446,0,1,0-2.446-2.446A2.449,2.449,0,0,0,223.167,38.227Zm0-3.852a1.406,1.406,0,1,1-1.406,1.406A1.408,1.408,0,0,1,223.167,34.375Z"
              transform="translate(-205.417 -31.024)"
              fill="#ca2946"
              stroke="#ca2946"
              strokeWidth="1"
            />
            <path
              data-name="Path 75"
              d="M309.629,55.589h.7a.52.52,0,0,0,0-1.04h-.7a.52.52,0,0,0,0,1.04Z"
              transform="translate(-287.677 -50.767)"
              fill="#ca2946"
              stroke="#ca2946"
              strokeWidth="1"
            />
            <path
              data-name="Path 76"
              d="M178.309,55.589h.7a.52.52,0,0,0,0-1.04h-.7a.52.52,0,0,0,0,1.04Z"
              transform="translate(-165.462 -50.767)"
              fill="#ca2946"
              stroke="#ca2946"
              strokeWidth="1"
            />
            <path
              data-name="Path 77"
              d="M309.629,86.4h.7a.52.52,0,0,0,0-1.04h-.7a.52.52,0,0,0,0,1.04Z"
              transform="translate(-287.677 -79.441)"
              fill="#ca2946"
              stroke="#ca2946"
              strokeWidth="1"
            />
            <path
              data-name="Path 78"
              d="M178.309,86.4h.7a.52.52,0,0,0,0-1.04h-.7a.52.52,0,0,0,0,1.04Z"
              transform="translate(-165.462 -79.441)"
              fill="#ca2946"
              stroke="#ca2946"
              strokeWidth="1"
            />
          </g>
        </g>
      </svg>
    ),
    imgActive: (
      <svg
        className="home_menu_svg active_svg"
        xmlns="http://www.w3.org/2000/svg"
        width="28.5"
        height="25.501"
        viewBox="0 0 36.5 36.501"
      >
        <g id="courthouse" transform="translate(0.5 0.5)">
          <g id="Group_21" data-name="Group 21" transform="translate(0 0.001)">
            <path
              data-name="Path 73"
              d="M31.2,14.021a.52.52,0,0,0,.52-.52V11.687h.461a.52.52,0,0,0,.52-.52V9.445H34.98a.52.52,0,0,0,.52-.52V5a.52.52,0,0,0-.389-.5L17.881.017a.52.52,0,0,0-.262,0L.389,4.5A.52.52,0,0,0,0,5V8.925a.52.52,0,0,0,.52.52H2.8v1.721a.52.52,0,0,0,.52.52h.461v16.05H3.322a.52.52,0,0,0-.52.52v1.721H1.921a.52.52,0,0,0-.52.52v1.721H.52a.52.52,0,0,0-.52.52V34.98a.52.52,0,0,0,.52.52H34.98a.52.52,0,0,0,.52-.52V32.739a.52.52,0,0,0-.52-.52H34.1V30.5a.52.52,0,0,0-.52-.52H32.7V28.257a.52.52,0,0,0-.52-.52h-.461V15.928a.52.52,0,0,0-1.04,0V27.737H26.955V11.687h3.723V13.5a.52.52,0,0,0,.52.52Zm.461-3.374H25.974v-1.2h5.684ZM1.04,5.405,17.75,1.058,34.46,5.405v3H1.04Zm19.552,5.242H14.908v-1.2h5.684ZM9.526,9.445v1.2H3.842v-1.2Zm-4.7,2.241H8.545v4.044a.52.52,0,0,0,1.04,0V11.687h.461a.52.52,0,0,0,.52-.52V9.445h3.3v1.721a.52.52,0,0,0,.52.52h.461v16.05h-.461a.52.52,0,0,0-.52.52v1.721h-3.3V28.257a.52.52,0,0,0-.52-.52H9.585V18.157a.52.52,0,0,0-1.04,0v9.579H4.822Zm15.77,17.09v1.2H14.908v-1.2Zm-16.75,0H9.526v1.2H3.842ZM34.46,34.46H1.04v-1.2H34.46Zm-1.4-2.241H2.441v-1.2H33.059Zm-7.085-2.241v-1.2h5.684v1.2Zm-.059-2.241h-.461a.52.52,0,0,0-.52.52v1.721h-3.3V28.257a.52.52,0,0,0-.52-.52h-.46V22.91a.52.52,0,0,0-1.04,0v4.826H15.889V11.687h3.723v8.8a.52.52,0,0,0,1.04,0v-8.8h.46a.52.52,0,0,0,.52-.52V9.445h3.3v1.721a.52.52,0,0,0,.52.52h.461Z"
              transform="translate(0 -0.001)"
              fill="white"
              stroke="white"
              strokeWidth="1"
            />
            <path
              data-name="Path 74"
              d="M223.167,38.227a2.446,2.446,0,1,0-2.446-2.446A2.449,2.449,0,0,0,223.167,38.227Zm0-3.852a1.406,1.406,0,1,1-1.406,1.406A1.408,1.408,0,0,1,223.167,34.375Z"
              transform="translate(-205.417 -31.024)"
              fill="white"
              stroke="white"
              strokeWidth="1"
            />
            <path
              data-name="Path 75"
              d="M309.629,55.589h.7a.52.52,0,0,0,0-1.04h-.7a.52.52,0,0,0,0,1.04Z"
              transform="translate(-287.677 -50.767)"
              fill="white"
              stroke="white"
              strokeWidth="1"
            />
            <path
              data-name="Path 76"
              d="M178.309,55.589h.7a.52.52,0,0,0,0-1.04h-.7a.52.52,0,0,0,0,1.04Z"
              transform="translate(-165.462 -50.767)"
              fill="white"
              stroke="white"
              strokeWidth="1"
            />
            <path
              data-name="Path 77"
              d="M309.629,86.4h.7a.52.52,0,0,0,0-1.04h-.7a.52.52,0,0,0,0,1.04Z"
              transform="translate(-287.677 -79.441)"
              fill="white"
              stroke="white"
              strokeWidth="1"
            />
            <path
              data-name="Path 78"
              d="M178.309,86.4h.7a.52.52,0,0,0,0-1.04h-.7a.52.52,0,0,0,0,1.04Z"
              transform="translate(-165.462 -79.441)"
              fill="white"
              stroke="white"
              strokeWidth="1"
            />
          </g>
        </g>
      </svg>
    ),
    layout: '/',
    type: true,
  },*/
  {
    id: 12,
    path: '/messenger',
    permission: 'قراءة-المحادثات',
    name: 'المحادثات',
    img: (
      <svg
        className="side_menu_svg"
        xmlns="http://www.w3.org/2000/svg"
        width="39.374"
        height="34.51"
        viewBox="0 0 39.374 34.51"
      >
        <g id="chatting" transform="translate(0 -31.626)">
          <path
            id="Path_152"
            data-name="Path 152"
            d="M0,56.034a1.154,1.154,0,0,0,1.823.939l6.342-4.524a3.1,3.1,0,0,1,1.812-.58H23.926a4.458,4.458,0,0,0,4.453-4.453V32.78a1.154,1.154,0,0,0-1.154-1.154H4.453A4.458,4.458,0,0,0,0,36.079ZM2.307,36.079a2.148,2.148,0,0,1,2.146-2.146H26.072V47.416a2.148,2.148,0,0,1-2.146,2.146H9.978a5.4,5.4,0,0,0-3.152,1.009L2.307,53.795Z"
            transform="translate(0 0)"
            fill="#ca2946"
          ></path>
          <path
            id="Path_153"
            data-name="Path 153"
            d="M171.349,152.419a4.458,4.458,0,0,0-4.453-4.453h-3.081a1.154,1.154,0,1,0,0,2.307H166.9a2.148,2.148,0,0,1,2.146,2.146v17.715l-4.519-3.223a5.4,5.4,0,0,0-3.152-1.009H147.423a2.148,2.148,0,0,1-2.146-2.146v-1.033a1.154,1.154,0,1,0-2.307,0v1.033a4.458,4.458,0,0,0,4.453,4.453h13.949a3.1,3.1,0,0,1,1.812.58l6.342,4.524a1.155,1.155,0,0,0,1.823-.939V152.419Z"
            transform="translate(-131.975 -107.393)"
            fill="#ca2946"
          ></path>
          <path
            id="Path_154"
            data-name="Path 154"
            d="M94.016,115.646h13.327a1.154,1.154,0,1,0,0-2.307H94.016a1.154,1.154,0,1,0,0,2.307Z"
            transform="translate(-85.721 -75.429)"
            fill="#ca2946"
          ></path>
          <path
            id="Path_155"
            data-name="Path 155"
            d="M94.016,185.646h13.327a1.154,1.154,0,1,0,0-2.307H94.016a1.154,1.154,0,1,0,0,2.307Z"
            transform="translate(-85.721 -140.046)"
            fill="#ca2946"
          ></path>
        </g>
      </svg>
    ),
    imgActive: (
      <svg
        className="active_svg side_menu_svg"
        xmlns="http://www.w3.org/2000/svg"
        width="39.374"
        height="34.51"
        viewBox="0 0 39.374 34.51"
      >
        <g id="chatting" transform="translate(0 -31.626)">
          <path
            id="Path_152"
            data-name="Path 152"
            d="M0,56.034a1.154,1.154,0,0,0,1.823.939l6.342-4.524a3.1,3.1,0,0,1,1.812-.58H23.926a4.458,4.458,0,0,0,4.453-4.453V32.78a1.154,1.154,0,0,0-1.154-1.154H4.453A4.458,4.458,0,0,0,0,36.079ZM2.307,36.079a2.148,2.148,0,0,1,2.146-2.146H26.072V47.416a2.148,2.148,0,0,1-2.146,2.146H9.978a5.4,5.4,0,0,0-3.152,1.009L2.307,53.795Z"
            transform="translate(0 0)"
            fill="white"
          />
          <path
            id="Path_153"
            data-name="Path 153"
            d="M171.349,152.419a4.458,4.458,0,0,0-4.453-4.453h-3.081a1.154,1.154,0,1,0,0,2.307H166.9a2.148,2.148,0,0,1,2.146,2.146v17.715l-4.519-3.223a5.4,5.4,0,0,0-3.152-1.009H147.423a2.148,2.148,0,0,1-2.146-2.146v-1.033a1.154,1.154,0,1,0-2.307,0v1.033a4.458,4.458,0,0,0,4.453,4.453h13.949a3.1,3.1,0,0,1,1.812.58l6.342,4.524a1.155,1.155,0,0,0,1.823-.939V152.419Z"
            transform="translate(-131.975 -107.393)"
            fill="white"
          />
          <path
            id="Path_154"
            data-name="Path 154"
            d="M94.016,115.646h13.327a1.154,1.154,0,1,0,0-2.307H94.016a1.154,1.154,0,1,0,0,2.307Z"
            transform="translate(-85.721 -75.429)"
            fill="white"
          />
          <path
            id="Path_155"
            data-name="Path 155"
            d="M94.016,185.646h13.327a1.154,1.154,0,1,0,0-2.307H94.016a1.154,1.154,0,1,0,0,2.307Z"
            transform="translate(-85.721 -140.046)"
            fill="white"
          />
        </g>
      </svg>
    ),
    layout: '/',
    type: true,
  },
  {
    id: 7,
    path: '/bills',
    name: 'المحاسبة',
    permission: 'قراءة-المحاسبة',
    img: (
      <svg
        className="home_menu_svg"
        id="maths"
        xmlns="http://www.w3.org/2000/svg"
        width="28.5"
        height="25.501"
        viewBox="0 0 37.069 37.069"
      >
        <path
          data-name="Path 66"
          d="M0,0V37.069H37.069V0ZM34.847,17.434H19.635V2.222H34.847V17.434ZM17.434,2.222V17.434H2.222V2.222ZM2.222,19.635H17.434V34.847H2.222ZM19.635,34.847V19.635H34.847V34.847H19.635Z"
          fill="#ca2946"
        />
        <path
          data-name="Path 67"
          d="M73.9,79.745h2.2V76.1h3.64V73.9H76.1V70.263H73.9V73.9H70.263v2.2H73.9Z"
          transform="translate(-65.176 -65.176)"
          fill="#ca2946"
        />
        <path
          data-name="Path 68"
          d="M310.459,361.057h9.482v2.2h-9.482Z"
          transform="translate(-287.982 -334.917)"
          fill="#ca2946"
        />
        <path
          data-name="Path 69"
          d="M87.271,320.761l-1.556-1.556-2.574,2.574-2.574-2.574-1.556,1.556,2.574,2.574L79.01,325.91l1.556,1.556,2.574-2.574,2.574,2.574,1.556-1.556L84.7,323.335Z"
          transform="translate(-73.29 -296.095)"
          fill="#ca2946"
        />
        <path
          data-name="Path 70"
          d="M310.459,120.547h9.482v2.2h-9.482Z"
          transform="translate(-287.982 -111.819)"
          fill="#ca2946"
        />
        <path
          data-name="Path 71"
          d="M360.519,300.035h2.233v2.2h-2.233Z"
          transform="translate(-334.418 -278.313)"
          fill="#ca2946"
        />
        <path
          data-name="Path 72"
          d="M360.519,420.652h2.233v2.2h-2.233Z"
          transform="translate(-334.418 -390.197)"
          fill="#ca2946"
        />
      </svg>
    ),
    imgActive: (
      <svg
        className="home_menu_svg active_svg"
        id="maths"
        xmlns="http://www.w3.org/2000/svg"
        width="28.5"
        height="25.501"
        viewBox="0 0 37.069 37.069"
      >
        <path
          data-name="Path 66"
          d="M0,0V37.069H37.069V0ZM34.847,17.434H19.635V2.222H34.847V17.434ZM17.434,2.222V17.434H2.222V2.222ZM2.222,19.635H17.434V34.847H2.222ZM19.635,34.847V19.635H34.847V34.847H19.635Z"
          fill="#ca2946"
        />
        <path
          data-name="Path 67"
          d="M73.9,79.745h2.2V76.1h3.64V73.9H76.1V70.263H73.9V73.9H70.263v2.2H73.9Z"
          transform="translate(-65.176 -65.176)"
          fill="#ca2946"
        />
        <path
          data-name="Path 68"
          d="M310.459,361.057h9.482v2.2h-9.482Z"
          transform="translate(-287.982 -334.917)"
          fill="#ca2946"
        />
        <path
          data-name="Path 69"
          d="M87.271,320.761l-1.556-1.556-2.574,2.574-2.574-2.574-1.556,1.556,2.574,2.574L79.01,325.91l1.556,1.556,2.574-2.574,2.574,2.574,1.556-1.556L84.7,323.335Z"
          transform="translate(-73.29 -296.095)"
          fill="#ca2946"
        />
        <path
          data-name="Path 70"
          d="M310.459,120.547h9.482v2.2h-9.482Z"
          transform="translate(-287.982 -111.819)"
          fill="#ca2946"
        />
        <path
          data-name="Path 71"
          d="M360.519,300.035h2.233v2.2h-2.233Z"
          transform="translate(-334.418 -278.313)"
          fill="#ca2946"
        />
        <path
          data-name="Path 72"
          d="M360.519,420.652h2.233v2.2h-2.233Z"
          transform="translate(-334.418 -390.197)"
          fill="#ca2946"
        />
      </svg>
    ),
    layout: '/',
    type: true,
  },
  {
    id: 14,
    path: '/receivables',
    name: 'المصاريف و المقبوضات',
    permission: 'قراءة-المحاسبة',
    img: (
      <svg
        width="28.5"
        height="25.501"
        viewBox="0 0 64 64"
        xmlns="http://www.w3.org/2000/svg"
        fill="#ca2946"
      >
        <path d="M32 0C14.355 0 0 14.355 0 32s14.355 32 32 32 32-14.355 32-32S49.645 0 32 0zm0 60C15.458 60 4 48.542 4 32S15.458 4 32 4s28 11.458 28 28-11.458 28-28 28z" />
        <path d="M42.707 39.707l-8 8c-.391.391-.902.586-1.414.586s-1.023-.195-1.414-.586l-8-8a2 2 0 0 1 2.828-2.828L31 42.172V20a2 2 0 0 1 4 0v22.172l5.293-5.293a2 2 0 1 1 2.828 2.828z" />
        <path d="M21.293 24.293l8-8a2 2 0 0 1 2.828 0l8 8a2 2 0 1 1-2.828 2.828L33 21.828V44a2 2 0 0 1-4 0V21.828l-5.293 5.293a2 2 0 1 1-2.828-2.828z" />
      </svg>
    ),
    imgActive: (
      <svg
        width="28.5"
        height="25.501"
        viewBox="0 0 64 64"
        xmlns="http://www.w3.org/2000/svg"
        fill="white"
      >
        <path d="M32 0C14.355 0 0 14.355 0 32s14.355 32 32 32 32-14.355 32-32S49.645 0 32 0zm0 60C15.458 60 4 48.542 4 32S15.458 4 32 4s28 11.458 28 28-11.458 28-28 28z" />
        <path d="M42.707 39.707l-8 8c-.391.391-.902.586-1.414.586s-1.023-.195-1.414-.586l-8-8a2 2 0 0 1 2.828-2.828L31 42.172V20a2 2 0 0 1 4 0v22.172l5.293-5.293a2 2 0 1 1 2.828 2.828z" />
        <path d="M21.293 24.293l8-8a2 2 0 0 1 2.828 0l8 8a2 2 0 1 1-2.828 2.828L33 21.828V44a2 2 0 0 1-4 0V21.828l-5.293 5.293a2 2 0 1 1-2.828-2.828z" />
      </svg>
    ),
    layout: '/',
    type: true,
  },
  {
    id: 6,
    path: '/archives',
    name: 'الأرشيف',
    permission: 'قراءة-الأرشيف',
    img: (
      <svg
        className="home_menu_svg"
        xmlns="http://www.w3.org/2000/svg"
        width="28.5"
        height="25.501"
        viewBox="0 0 38.241 38.241"
      >
        <path
          data-name="Path 46"
          d="M33.759,8.963H31.519V4.481H29.278V0H20.089L18.6,2.241H8.963V6.722H6.722V11.2H4.481v3.022L0,20.947V38.241H38.241V20.947l-4.481-6.722ZM11.2,4.481h8.591l1.494-2.241h5.749V4.481H22.33L20.836,6.722H11.2ZM8.963,8.963H22.035l1.494-2.241h5.749V8.963H24.57L23.077,11.2H8.963ZM6.722,13.444H24.276L25.77,11.2h5.749v8.963h-7.9l-.747,2.241h-7.5l-.747-2.241h-7.9ZM4.481,18.265v1.9H3.214ZM36,36H2.241V22.407H13.01l.747,2.241H24.484l.747-2.241H36Zm-.973-15.834H33.759v-1.9Zm0,0"
          transform="translate(0)"
          fill="#ca2946"
        />
        <path
          data-name="Path 47"
          d="M180,360h11.353v2.241H180Zm0,0"
          transform="translate(-166.556 -333.112)"
          fill="#ca2946"
        />
      </svg>
    ),
    imgActive: (
      <svg
        className="home_menu_svg active_svg"
        xmlns="http://www.w3.org/2000/svg"
        width="28.5"
        height="25.501"
        viewBox="0 0 38.241 38.241"
      >
        <path
          data-name="Path 46"
          d="M33.759,8.963H31.519V4.481H29.278V0H20.089L18.6,2.241H8.963V6.722H6.722V11.2H4.481v3.022L0,20.947V38.241H38.241V20.947l-4.481-6.722ZM11.2,4.481h8.591l1.494-2.241h5.749V4.481H22.33L20.836,6.722H11.2ZM8.963,8.963H22.035l1.494-2.241h5.749V8.963H24.57L23.077,11.2H8.963ZM6.722,13.444H24.276L25.77,11.2h5.749v8.963h-7.9l-.747,2.241h-7.5l-.747-2.241h-7.9ZM4.481,18.265v1.9H3.214ZM36,36H2.241V22.407H13.01l.747,2.241H24.484l.747-2.241H36Zm-.973-15.834H33.759v-1.9Zm0,0"
          transform="translate(0)"
          fill="#ca2946"
        />
        <path
          data-name="Path 47"
          d="M180,360h11.353v2.241H180Zm0,0"
          transform="translate(-166.556 -333.112)"
          fill="#ca2946"
        />
      </svg>
    ),
    layout: '/admin',
    type: true,
  },
  {
    id: 10,
    path: '/settings',
    name: 'الإعدادات',
    permission: 'قراءة-الإعدادت',
    img: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24.483"
        height="24"
        viewBox="0 0 24.483 24"
      >
        <g id="setting-lines" transform="translate(0 -4.503)">
          <path
            id="Path_138"
            data-name="Path 138"
            d="M5.232,54.027H.72a.72.72,0,0,1,0-1.44H5.232a.72.72,0,0,1,0,1.44Z"
            transform="translate(0 -45.505)"
            fill="#ca2946"
            stroke="#ca2946"
          />
          <path
            id="Path_139"
            data-name="Path 139"
            d="M87.434,11.1a3.3,3.3,0,1,1,3.3-3.3A3.3,3.3,0,0,1,87.434,11.1Zm0-5.156A1.858,1.858,0,1,0,89.292,7.8,1.86,1.86,0,0,0,87.434,5.943Z"
            transform="translate(-79.624)"
            fill="#ca2946"
            stroke="#ca2946"
          />
          <path
            id="Path_140"
            data-name="Path 140"
            d="M240.487,54.027H229.725a.72.72,0,0,1,0-1.44h10.762a.72.72,0,0,1,0,1.44Z"
            transform="translate(-216.725 -45.505)"
            fill="#ca2946"
            stroke="#ca2946"
          />
          <path
            id="Path_141"
            data-name="Path 141"
            d="M267.727,173.37a3.3,3.3,0,1,1,3.3-3.3A3.3,3.3,0,0,1,267.727,173.37Zm0-5.156a1.858,1.858,0,1,0,1.858,1.858A1.86,1.86,0,0,0,267.727,168.214Z"
            transform="translate(-250.249 -153.569)"
            fill="#ca2946"
            stroke="#ca2946"
          />
          <path
            id="Path_142"
            data-name="Path 142"
            d="M12.288,216.29H.72a.72.72,0,1,1,0-1.44H12.288a.72.72,0,1,1,0,1.44Z"
            transform="translate(0 -199.067)"
            fill="#ca2946"
            stroke="#ca2946"
          />
          <path
            id="Path_143"
            data-name="Path 143"
            d="M365.013,216.29h-3.706a.72.72,0,1,1,0-1.44h3.706a.72.72,0,1,1,0,1.44Z"
            transform="translate(-341.25 -199.067)"
            fill="#ca2946"
            stroke="#ca2946"
          />
          <path
            id="Path_144"
            data-name="Path 144"
            d="M72.411,335.634a3.3,3.3,0,1,1,3.3-3.3A3.3,3.3,0,0,1,72.411,335.634Zm0-5.157a1.858,1.858,0,1,0,1.858,1.858A1.86,1.86,0,0,0,72.411,330.477Z"
            transform="translate(-65.407 -307.131)"
            fill="#ca2946"
            stroke="#ca2946"
          />
          <path
            id="Path_145"
            data-name="Path 145"
            d="M226.267,378.554H214.7a.72.72,0,1,1,0-1.44h11.568a.72.72,0,1,1,0,1.44Z"
            transform="translate(-202.504 -352.63)"
            fill="#ca2946"
            stroke="#ca2946"
          />
          <path
            id="Path_146"
            data-name="Path 146"
            d="M4.426,378.553H.72a.72.72,0,0,1,0-1.44H4.426a.72.72,0,0,1,0,1.44Z"
            transform="translate(0 -352.629)"
            fill="#ca2946"
            stroke="#ca2946"
          />
        </g>
      </svg>
    ),
    imgActive: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24.483"
        height="24"
        viewBox="0 0 24.483 24"
      >
        <g id="setting-lines" transform="translate(0 -4.503)">
          <path
            id="Path_138"
            data-name="Path 138"
            d="M5.232,54.027H.72a.72.72,0,0,1,0-1.44H5.232a.72.72,0,0,1,0,1.44Z"
            transform="translate(0 -45.505)"
            fill="#ffffff"
            stroke="#ffffff"
          />
          <path
            id="Path_139"
            data-name="Path 139"
            d="M87.434,11.1a3.3,3.3,0,1,1,3.3-3.3A3.3,3.3,0,0,1,87.434,11.1Zm0-5.156A1.858,1.858,0,1,0,89.292,7.8,1.86,1.86,0,0,0,87.434,5.943Z"
            transform="translate(-79.624)"
            fill="#ffffff"
            stroke="#ffffff"
          />
          <path
            id="Path_140"
            data-name="Path 140"
            d="M240.487,54.027H229.725a.72.72,0,0,1,0-1.44h10.762a.72.72,0,0,1,0,1.44Z"
            transform="translate(-216.725 -45.505)"
            fill="#ffffff"
            stroke="#ffffff"
          />
          <path
            id="Path_141"
            data-name="Path 141"
            d="M267.727,173.37a3.3,3.3,0,1,1,3.3-3.3A3.3,3.3,0,0,1,267.727,173.37Zm0-5.156a1.858,1.858,0,1,0,1.858,1.858A1.86,1.86,0,0,0,267.727,168.214Z"
            transform="translate(-250.249 -153.569)"
            fill="#ffffff"
            stroke="#ffffff"
          />
          <path
            id="Path_142"
            data-name="Path 142"
            d="M12.288,216.29H.72a.72.72,0,1,1,0-1.44H12.288a.72.72,0,1,1,0,1.44Z"
            transform="translate(0 -199.067)"
            fill="#ffffff"
            stroke="#ffffff"
          />
          <path
            id="Path_143"
            data-name="Path 143"
            d="M365.013,216.29h-3.706a.72.72,0,1,1,0-1.44h3.706a.72.72,0,1,1,0,1.44Z"
            transform="translate(-341.25 -199.067)"
            fill="#ffffff"
            stroke="#ffffff"
          />
          <path
            id="Path_144"
            data-name="Path 144"
            d="M72.411,335.634a3.3,3.3,0,1,1,3.3-3.3A3.3,3.3,0,0,1,72.411,335.634Zm0-5.157a1.858,1.858,0,1,0,1.858,1.858A1.86,1.86,0,0,0,72.411,330.477Z"
            transform="translate(-65.407 -307.131)"
            fill="#ffffff"
            stroke="#ffffff"
          />
          <path
            id="Path_145"
            data-name="Path 145"
            d="M226.267,378.554H214.7a.72.72,0,1,1,0-1.44h11.568a.72.72,0,1,1,0,1.44Z"
            transform="translate(-202.504 -352.63)"
            fill="#ffffff"
            stroke="#ffffff"
          />
          <path
            id="Path_146"
            data-name="Path 146"
            d="M4.426,378.553H.72a.72.72,0,0,1,0-1.44H4.426a.72.72,0,0,1,0,1.44Z"
            transform="translate(0 -352.629)"
            fill="#ffffff"
            stroke="#ffffff"
          />
        </g>
      </svg>
    ),
    layout: '/admin',
    type: true,
  },
];
export default routes;
