import React, { Component } from 'react';
import { CircularProgress } from '@material-ui/core';
import InputField from '../../../../components/inputField';
import actions from '../../store/actions';
import actionsJs from '../../../../js/actions';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { cities, host } from '../../../../utils/constants';
import SimpleReactValidator from 'simple-react-validator';
import Checkbox from '@mui/material/Checkbox';
import Img from '../../../../assets/img/pf-icon2.png';
import scrollTo from 'gatsby-plugin-smoothscroll';

import {
  firstNumberPhone,
  getPermissionFormated,
  getPermissionSelected,
  isHasPermission,
  isNumeric,
  lengthPhone,
} from '../../../../utils/helpres';
import { Col, Row } from 'reactstrap';
//53 54 55 56
const mapStateToProps = (state) => ({
  isLoadingSecretaries: state.SettingsReducer.isLoadingSecretaries,
  secretaries: state.SettingsReducer.secretaries,
  updateSecretary: state.AppReducer.updateSecretary,
  updateLawyerTraineeShip: state.AppReducer.updateLawyerTraineeShip,
  isLoadingUpdateSecretary: state.AppReducer.isLoadingUpdateSecretary,
  isLoadingDestroySecretary: state.SettingsReducer.isLoadingDestroySecretary,
  destroySecretary: state.SettingsReducer.destroySecretary,
  isLoadingGroupPermission: state.SettingsReducer.isLoadingGroupPermission,
  groupPermission: state.SettingsReducer.groupPermission,
  account: state.AppReducer.account,
  active: state.SettingsReducer.active,
  isLoadingLawyerTraineeShip: state.SettingsReducer.isLoadingLawyerTraineeShip,
  lawyerTraineeShip: state.SettingsReducer.lawyerTraineeShip,
  destroyLawyerTraineeShip: state.SettingsReducer.destroyLawyerTraineeShip,
});

const mapDispatchToProps = (dispatch) => ({
  getSecretaries: () => {
    dispatch(actions.getSecretaries());
  },
  getLawyerTraineeShip: () => {
    dispatch(actions.getLawyerTraineeShip(null));
  },
  getGroupPermissions: async () => {
    await dispatch(actions.getGroupPermissions());
  },
  submitSecretaryForm: async (values) => {
    await dispatch(actionsJs.submitSecretaryForm(values));
    await dispatch(actions.getSecretaries());
  },
  deleteSecretary: async (id) => {
    await dispatch(actions.deleteSecretary(id));
    await dispatch(actions.getSecretaries());
  },
  deleteLawyerTraineeShip: async (id) => {
    await dispatch(actions.deleteLawyerTraineeShip(id));
    await dispatch(actions.getLawyerTraineeShip());
  },
  ActivitedDeactivetedAccount: async (values) => {
    await dispatch(actions.ActivitedDeactivetedAccount(values));
    await dispatch(actions.getSecretaries());
    await dispatch(actions.getLawyerTraineeShip(null));
  },
  submitLawyerTraineeShipForm: async (values) => {
    await dispatch(actionsJs.submitLawyerTraineeShipForm(values));
    await dispatch(actions.getLawyerTraineeShip(null));
  },
});

class Secretary extends Component {
  constructor(props) {
    super(props);
    props.getSecretaries();
    props.getLawyerTraineeShip();
    props.getGroupPermissions();
    document.title = 'المستخدمين';
    props.changeActiveItemSideBar(10, 'المستخدمين');
    this.state = {
      checked: false,
      checkedRead: false,
      openDeletePopup: false,
      openUpdatePopup: false,
      openActivePopup: false,
      showBackground: false,
      hasErrorNewPassword: false,
      hasError: false,
      hasErrorPermission: false,
      selected_id: null,
      selected_name: '',
      selected_type: '',
      initialValues: {
        access_status: '',
        id: null,
        login_access_id: null,
        permissions: [],
        street: '',
        city: '',
        first_name: '',
        last_name: '',
        confirm_password: '',
        password: '',
        email: '',
        phone: '',
        file: '',
        type:
          (props.account.pack && props.account.pack === 'office') ||
          (props.account.lawyer && props.account.lawyer.pack === 'office')
            ? 'كاتب(ة)'
            : '',
      },
    };
    this.handleChangeAll = this.handleChangeAll.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.validator = new SimpleReactValidator({
      messages: {
        email: 'Adresse e-mail invalide',
        min: 'Saisissez un mot de passe comprenant au moins 6 caractères.',
        max: 'Le mot de passe doit comporter de 6 à 20 caractères. ',
        // OR
        default: 'Champ requise.', // will override all messenger
      },
    });
    this.myRef = React.createRef();
  }
  submitForm(initialValues) {
    if (
      !initialValues.last_name ||
      !initialValues.first_name ||
      !initialValues.email ||
      !initialValues.type ||
      !initialValues.city ||
      !initialValues.street ||
      (!initialValues.id && !initialValues.password) ||
      (initialValues.id && initialValues.password && !initialValues.confirm_password) ||
      (!initialValues.id && !initialValues.confirm_password) ||
      !initialValues.phone ||
      (initialValues.permissions && initialValues.permissions.length === 0) ||
      (initialValues.phone && !firstNumberPhone(initialValues.phone)) ||
      (initialValues.phone && !lengthPhone(initialValues.phone)) ||
      (initialValues.phone && !isNumeric(initialValues.phone))
    ) {
      this.setState({
        ...initialValues,
        hasError: true,
      });
      scrollTo('#mainView');
    } else if (
      !initialValues.id &&
      initialValues.password &&
      initialValues.password.localeCompare(initialValues.confirm_password)
    ) {
      this.setState({
        ...initialValues,
        hasErrorNewPassword: true,
      });
      scrollTo('#mainView');
    } else if (initialValues.permissions.length === 0) {
      this.setState({
        ...initialValues,
        hasErrorPermission: true,
      });
      scrollTo('#mainView');
    } else {
      if (initialValues.type === 'استاذ(ة)') {
        this.props.submitLawyerTraineeShipForm(initialValues);
      } else this.props.submitSecretaryForm(initialValues);
    }
  }

  closeModal() {
    this.setState({
      openDeletePopup: false,
      openUpdatePopup: false,
      openActivePopup: false,
      showBackground: false,
      selected_id: null,
      selected_type: null,
      hasErrorNewPassword: false,
      hasError: false,
      hasErrorPermission: false,
      initialValues: {
        access_status: '',
        street: '',
        city: '',
        first_name: '',
        last_name: '',
        confirm_password: '',
        password: '',
        email: '',
        phone: '',
        file: '',
        type:
          (this.props.account.pack && this.props.account.pack === 'office') ||
          (this.props.account.lawyer && this.props.account.lawyer.pack === 'office')
            ? 'كاتب(ة)'
            : '',
      },
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      updateSecretary,
      updateLawyerTraineeShip,
      destroyLawyerTraineeShip,
      active,
      destroySecretary,
    } = this.props;
    if (active !== prevProps.active && active) {
      this.closeModal();
    }
    if (updateSecretary !== prevProps.updateSecretary && updateSecretary) {
      this.closeModal();
    }
    if (
      updateLawyerTraineeShip !== prevProps.updateLawyerTraineeShip &&
      updateLawyerTraineeShip
    ) {
      this.closeModal();
    }
    if (destroySecretary !== prevProps.destroySecretary && destroySecretary) {
      this.closeModal();
    }
    if (
      destroyLawyerTraineeShip !== prevProps.destroyLawyerTraineeShip &&
      destroyLawyerTraineeShip
    ) {
      this.closeModal();
    }
  }
  handleChange(event, id) {
    const { initialValues } = this.state;
    let permissions = [...initialValues.permissions];
    if (event.target.checked) {
      permissions.push(id);
    } else {
      permissions = permissions.filter((item) => item !== id);
    }
    this.setState({
      initialValues: {
        ...initialValues,
        permissions,
      },
    });
  }
  async handleChangeAll(event, name, permissions) {
    const { initialValues } = this.state;
    const { groupPermission } = this.props;
    //if (permissions.length > 0)
    if (event.target.checked) {
      const result = await getPermissionSelected(groupPermission, name);
      let newPermissions = [...permissions, ...result];
      this.setState({
        [name]: name,
        initialValues: {
          ...initialValues,
          permissions: newPermissions,
        },
      });
    } else {
      const result = await getPermissionSelected(groupPermission, name);
      let newPermissions = permissions.filter((item) => !result.includes(item));
      this.setState({
        [name]: '',
        initialValues: {
          ...initialValues,
          permissions: newPermissions,
        },
      });
    }
  }

  render() {
    const {
      secretaries,
      lawyerTraineeShip,
      account,
      groupPermission,
      isLoadingSecretaries,
    } = this.props;
    const {
      openDeletePopup,
      openUpdatePopup,
      openActivePopup,
      selected_id,
      selected_type,
      hasError,
      initialValues,
      hasErrorNewPassword,
      hasErrorPermission,
    } = this.state;
    let data = [...secretaries, ...lawyerTraineeShip];
    return (
      <div
        style={{ display: 'block', backgroundColor: '#eeeeee', height: '50rem' }}
        className="params_col-left"
      >
        <Row>
          <Col lg={12} className="">
            {((account.nbr_max_users && account.nbr_max_users > account.nbr_users) ||
              (account.lawyer &&
                account.lawyer.nbr_max_users &&
                account.lawyer.nbr_max_users > account.lawyer.nbr_users)) &&
            isHasPermission(account.login_access.permissions, 'إضافة-المستخدمين') ? (
              <div
                style={{ width: '18rem', margin: '3px' }}
                //to="/add-session"
                onClick={() => this.setState({ openUpdatePopup: !openUpdatePopup })}
                className="jalaset_add_button"
              >
                <svg
                  height="10"
                  viewBox="0 0 448 448"
                  width="10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m408 184h-136c-4.417969 0-8-3.582031-8-8v-136c0-22.089844-17.910156-40-40-40s-40 17.910156-40 40v136c0 4.417969-3.582031 8-8 8h-136c-22.089844 0-40 17.910156-40 40s17.910156 40 40 40h136c4.417969 0 8 3.582031 8 8v136c0 22.089844 17.910156 40 40 40s40-17.910156 40-40v-136c0-4.417969 3.582031-8 8-8h136c22.089844 0 40-17.910156 40-40s-17.910156-40-40-40zm0 0" />
                </svg>
                اضافة مستخدم
              </div>
            ) : (
              ''
            )}
          </Col>
        </Row>
        <br />
        {isLoadingSecretaries ? (
          <Row>
            <CircularProgress
              style={{
                textAlign: 'center',
                width: '30px',
                height: '30px',
                marginTop: '20%',
                marginRight: '50%',
              }}
            />
          </Row>
        ) : (
          <Row>
            {data && data.length > 0
              ? data.map((secretary) => {
                  if (secretary.id !== account.id)
                    return (
                      <Col key={secretary.id} lg={4} className="">
                        <div key={secretary.id} className="media">
                          <div
                            style={{
                              gap: '10px',
                              width: '240px',
                              display: 'flex',
                              alignItems: 'center',
                              height: '120px',
                              backgroundColor: 'white',
                            }}
                            className="count-box bg-soft-primary text-center"
                          >
                            <img
                              style={{ borderRadius: '50%', height: '66px' }}
                              src={secretary.photo ? `${host}${secretary.photo}` : Img}
                              alt=""
                            />
                            <div>
                              <h3 className="counter-count mb-1 text-dark">
                                {`${secretary.first_name} ${secretary.last_name}`}
                              </h3>
                              <p className="text-uppercase text-muted mb-0 f-14">
                                {secretary.type ? secretary.type : 'استاذ(ة)'}
                              </p>
                            </div>
                            <div
                              className="btns-actions"
                              style={{
                                gap: 'inherit',
                                display: 'none',
                                flexFlow: 'column',
                                marginRight: '5%',
                              }}
                            >
                              {isHasPermission(
                                account.login_access.permissions,
                                'تعديل-المستخدمين',
                              ) ? (
                                <li
                                  key={1}
                                  onClick={() =>
                                    this.setState({
                                      openActivePopup: !openActivePopup,
                                      initialValues: {
                                        login_access_id: secretary.login_access.id,
                                        id: secretary.login_access.id,
                                        access_status:
                                          secretary.login_access.access_status,
                                      },
                                    })
                                  }
                                  style={{ cursor: 'pointer' }}
                                >
                                  {secretary.login_access.access_status === 'accepted' ? (
                                    <svg
                                      className="tickSpan"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20.125"
                                      height="17.774"
                                      viewBox="0 0 29.125 23.774"
                                    >
                                      <path
                                        id="Path_92"
                                        data-name="Path 92"
                                        d="M1325.354,167.5l8.34,9.625,17.8-19.417"
                                        transform="translate(-1323.842 -156.357)"
                                        fill="none"
                                        stroke="#63B17D"
                                        sstrokeWidthh="4"
                                      ></path>
                                    </svg>
                                  ) : (
                                    <svg
                                      key={secretary.id}
                                      className="tickSpan"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20.125"
                                      height="17.774"
                                      viewBox="0 0 29.125 23.774"
                                      style={{ cursor: 'pointer' }}
                                    >
                                      <path
                                        id="Path_92"
                                        data-name="Path 92"
                                        d="M1325.354,167.5l8.34,9.625,17.8-19.417"
                                        transform="translate(-1323.842 -156.357)"
                                        fill="none"
                                        stroke="#CA2946"
                                        sstrokeWidthh="4"
                                      ></path>
                                    </svg>
                                  )}
                                </li>
                              ) : (
                                ''
                              )}
                              {isHasPermission(
                                account.login_access.permissions,
                                'تعديل-المستخدمين',
                              ) ? (
                                <li
                                  key={2}
                                  style={{ cursor: 'pointer' }}
                                  onClick={() =>
                                    this.setState({
                                      openUpdatePopup: !openUpdatePopup,
                                      initialValues: {
                                        permissions: getPermissionFormated(
                                          secretary.login_access.permissions,
                                        ),
                                        id: secretary.id,
                                        photo: secretary.photo,
                                        first_name: secretary.first_name,
                                        last_name: secretary.last_name,
                                        phone: secretary.phone,
                                        email: secretary.email,
                                        street: secretary.street
                                          ? secretary.street
                                          : secretary.address_street,
                                        city: secretary.city
                                          ? secretary.city
                                          : secretary.address_city,
                                        type: secretary.type
                                          ? secretary.type
                                          : 'استاذ(ة)',
                                      },
                                    })
                                  }
                                >
                                  <svg
                                    className="params_center_svg"
                                    id="Group_23"
                                    data-name="Group 23"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="23.304"
                                    height="24.288"
                                    viewBox="0 0 23.304 24.288"
                                  >
                                    <g id="Group_22" data-name="Group 22">
                                      <path
                                        id="Path_86"
                                        data-name="Path 86"
                                        d="M26.636,19.431h.131L33.1,18.053a.623.623,0,0,0,.328-.164L44.163,7.156A4.136,4.136,0,0,0,45.377,4.2a4.267,4.267,0,0,0-1.214-2.987A4.135,4.135,0,0,0,41.209,0a4.037,4.037,0,0,0-2.954,1.247l-10.7,10.733a.591.591,0,0,0-.2.3l-1.378,6.335a.722.722,0,0,0,.2.624A.656.656,0,0,0,26.636,19.431ZM41.209,1.314a2.855,2.855,0,0,1,2.856,2.855q0,.016,0,.033a2.757,2.757,0,0,1-.821,2l-4.037-4.07A2.823,2.823,0,0,1,41.209,1.314ZM38.287,3.086l4.037,4.037L32.97,16.444l-4.037-4ZM28.408,13.753,31.657,17l-4.168.919Z"
                                        transform="translate(-24.83 0)"
                                        fill="#ca2946"
                                      ></path>
                                      <path
                                        id="Path_87"
                                        data-name="Path 87"
                                        d="M30.485,365.717H8.494a.656.656,0,0,0,0,1.313H30.485a.656.656,0,0,0,0-1.313Z"
                                        transform="translate(-7.838 -342.742)"
                                        fill="#ca2946"
                                      ></path>
                                    </g>
                                  </svg>
                                </li>
                              ) : (
                                ''
                              )}
                              {isHasPermission(
                                account.login_access.permissions,
                                'حذف-المستخدمين',
                              ) ? (
                                <li
                                  key={3}
                                  onClick={() =>
                                    this.setState({
                                      openDeletePopup: !openDeletePopup,
                                      selected_id: secretary.id,
                                      selected_type: secretary.lawyer_id
                                        ? 'secretary'
                                        : 'lawyer',
                                    })
                                  }
                                  style={{ cursor: 'pointer' }}
                                >
                                  <svg
                                    className="params_center_svg"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20.906"
                                    height="25.74"
                                    viewBox="0 0 20.906 25.74"
                                  >
                                    <g id="delete" transform="translate(0 0)">
                                      <path
                                        id="Path_82"
                                        data-name="Path 82"
                                        d="M223,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,223,154.7Zm0,0"
                                        transform="translate(-208.991 -145.377)"
                                        fill="#ca2946"
                                      ></path>
                                      <path
                                        id="Path_83"
                                        data-name="Path 83"
                                        d="M105,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,105,154.7Zm0,0"
                                        transform="translate(-98.105 -145.377)"
                                        fill="#ca2946"
                                      ></path>
                                      <path
                                        id="Path_84"
                                        data-name="Path 84"
                                        d="M1.709,7.662V22.514a3.326,3.326,0,0,0,.884,2.294,2.969,2.969,0,0,0,2.154.931H16.152a2.968,2.968,0,0,0,2.154-.931,3.326,3.326,0,0,0,.884-2.294V7.662A2.3,2.3,0,0,0,18.6,3.133H15.513V2.38A2.368,2.368,0,0,0,13.126,0H7.773A2.368,2.368,0,0,0,5.386,2.38v.754H2.3a2.3,2.3,0,0,0-.591,4.528ZM16.152,24.533H4.747a1.909,1.909,0,0,1-1.832-2.019V7.715h15.07v14.8a1.909,1.909,0,0,1-1.832,2.019ZM6.592,2.38A1.161,1.161,0,0,1,7.773,1.2h5.353A1.161,1.161,0,0,1,14.308,2.38v.754H6.592ZM2.3,4.339H18.6a1.085,1.085,0,0,1,0,2.17H2.3a1.085,1.085,0,0,1,0-2.17Zm0,0"
                                        transform="translate(0.003 0.002)"
                                        fill="#ca2946"
                                      ></path>
                                      <path
                                        id="Path_85"
                                        data-name="Path 85"
                                        d="M164,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,164,154.7Zm0,0"
                                        transform="translate(-153.548 -145.377)"
                                        fill="#ca2946"
                                      ></path>
                                    </g>
                                  </svg>
                                </li>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        </div>
                      </Col>
                    );
                })
              : ''}
          </Row>
        )}
        <div
          //ref={this.myRef}
          style={{ width: '52%', height: '90%', overflow: 'auto' }}
          className={openUpdatePopup ? 'popup active' : 'popup'}
        >
          <div id="mainView" style={{ width: '100%' }} className="popup__title">
            <span style={{ fontSize: '20px' }}>
              {initialValues.id ? 'أنت بصدد تعديل مستخدم' : 'أنت بصدد اضافة مستخدم جديد'}
            </span>
            <span
              onClick={() => this.closeModal()}
              style={{ fontWeight: '700', cursor: 'pointer', float: 'left' }}
            >
              X
            </span>
          </div>
          <div className="popup__item">
            <div style={{ width: '100%' }} className="popup__text">
              <div style={{ flexDirection: 'row' }} className="lawsuit_form__sections">
                <label htmlFor="attr">الاسم</label>
                <InputField
                  //onKeyPress={() => this.submitForm(initialValues)}
                  value={initialValues.first_name}
                  name="first_name"
                  placeholder="الاسم"
                  type="text"
                  hasError={hasError}
                  validInput="text"
                  onChange={(event) => {
                    this.setState({
                      initialValues: {
                        ...initialValues,
                        first_name: event.target.value,
                      },
                    });
                  }}
                />
                <label htmlFor="attr">اللقب</label>
                <InputField
                  //onKeyPress={() => this.submitForm(initialValues)}
                  value={initialValues.last_name}
                  name="last_name"
                  placeholder="اللقب"
                  type="text"
                  hasError={hasError}
                  validInput="text"
                  onChange={(event) => {
                    this.setState({
                      initialValues: {
                        ...initialValues,
                        last_name: event.target.value,
                      },
                    });
                  }}
                />
              </div>
              <div style={{ flexDirection: 'row' }} className="lawsuit_form__sections">
                <label htmlFor="attr">البريد الالكتروني</label>
                <InputField
                  //onKeyPress={() => this.submitForm(initialValues)}
                  value={initialValues.email}
                  name="email"
                  placeholder="البريد الالكتروني او اسم االمستخدم"
                  type="text"
                  hasError={hasError}
                  validInput="text"
                  onChange={(event) => {
                    this.setState({
                      initialValues: {
                        ...initialValues,
                        email: event.target.value,
                      },
                    });
                  }}
                />
                <label htmlFor="attr">الصفة</label>
                <select
                  disabled={
                    initialValues.id || (account.pack === 'office' && initialValues.type)
                  }
                  value={initialValues.type}
                  className={hasError && !initialValues.type ? 'input-error' : ''}
                  onChange={(e) => {
                    this.setState({
                      initialValues: {
                        ...initialValues,
                        type: e.target.value,
                      },
                    });
                  }}
                  name="type"
                >
                  <option value={null}>اختر</option>
                  <option value="استاذ(ة)">استاذ(ة)</option>
                  <option value="كاتب(ة)">كاتب(ة)</option>
                </select>
              </div>
              {initialValues.id ? '' : <></>}
              <div style={{ flexDirection: 'row' }} className="lawsuit_form__sections">
                <label htmlFor="attr">كلمة المرور </label>
                <InputField
                  //onKeyPress={() => this.submitForm(initialValues)}
                  value={initialValues.password}
                  name="password"
                  placeholder="كلمة المرور مثال Avocat123"
                  type="password"
                  hasError={hasError || hasErrorNewPassword}
                  validInput="password"
                  required={this.validator.message(
                    'password',
                    initialValues.password,
                    'required|string|min:8|max:20',
                  )}
                  onChange={(event) => {
                    this.setState({
                      initialValues: {
                        ...initialValues,
                        password: event.target.value,
                      },
                    });
                  }}
                />
                <label htmlFor="attr">اعادة كلمة المرور</label>
                <InputField
                  //onKeyPress={() => this.submitForm(initialValues)}
                  value={initialValues.confirm_password}
                  name="confirm_password"
                  placeholder="************"
                  type="password"
                  hasError={hasError || hasErrorNewPassword}
                  validInput="password"
                  required={this.validator.message(
                    'confirm_password',
                    initialValues.confirm_password,
                    'required|string|min:8|max:20',
                  )}
                  onChange={(event) => {
                    this.setState({
                      initialValues: {
                        ...initialValues,
                        confirm_password: event.target.value,
                      },
                    });
                  }}
                />
              </div>
              <div style={{ flexDirection: 'row' }} className="lawsuit_form__sections">
                <label htmlFor="attr">الهاتف</label>
                <InputField
                  //onKeyPress={() => this.submitForm(initialValues)}
                  name="phone"
                  value={initialValues.phone}
                  placeholder="الهاتف"
                  type="text"
                  hasError={hasError}
                  validInput="text"
                  onChange={(event) => {
                    this.setState({
                      initialValues: {
                        ...initialValues,
                        phone: event.target.value,
                      },
                    });
                  }}
                />
                <label htmlFor="params_state">الولاية</label>
                <select
                  className={hasError && !initialValues.city ? 'input-error' : ''}
                  onChange={(e) => {
                    this.setState({
                      initialValues: {
                        ...initialValues,
                        city: e.target.value,
                      },
                    });
                  }}
                  value={initialValues.city}
                >
                  <option value="">الولاية</option>
                  {cities.map((city) => {
                    return (
                      <option key={city.name} value={city.name}>
                        {city.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div style={{ flexDirection: 'row' }} className="lawsuit_form__sections">
                <label htmlFor="params_addresess">العنوان</label>
                <textarea
                  onChange={(event) => {
                    this.setState({
                      initialValues: {
                        ...initialValues,
                        street: event.target.value,
                      },
                    });
                  }}
                  value={initialValues.street}
                  className={hasError && !initialValues.street ? 'input-error' : ''}
                  name="address_street"
                  id="lawsuit_events"
                >
                  {initialValues.street}
                </textarea>
              </div>
              <div style={{ flexDirection: 'row' }} className="lawsuit_form__sections">
                <div style={{ marginRight: '15%' }} className="params_basic_pic_section">
                  {typeof initialValues.photo === 'string' && initialValues.photo ? (
                    <img
                      style={{
                        height: '70%',
                        width: '37%',
                        borderRadius: '10px',
                      }}
                      src={`${host}${initialValues.photo}`}
                    />
                  ) : initialValues.photo &&
                    initialValues.photo.length > 0 &&
                    initialValues.photo[0].name ? (
                    <div className="miniature-container">
                      <img
                        style={{
                          height: '70%',
                          width: '37%',
                          borderRadius: '10px',
                        }}
                        src={URL.createObjectURL(initialValues.photo[0])}
                      />
                    </div>
                  ) : (
                    <svg
                      className="params_user_pic"
                      xmlns="http://www.w3.org/2000/svg"
                      width="166"
                      height="165"
                      viewBox="0 0 166 165"
                    >
                      <rect
                        id="Rectangle_170"
                        data-name="Rectangle 170"
                        width="166"
                        height="165"
                        rx="9"
                        fill="#f3f3f3"
                      />
                      <path
                        id="user_1_"
                        data-name="user (1)"
                        d="M100.426,17.23a58.828,58.828,0,0,0-83.2,83.2,58.828,58.828,0,0,0,83.2-83.2ZM29.492,101.659a29.788,29.788,0,0,1,58.672,0,51.821,51.821,0,0,1-58.672,0ZM40.123,51.444A18.705,18.705,0,1,1,58.828,70.149,18.726,18.726,0,0,1,40.123,51.444ZM94.135,96.877A36.74,36.74,0,0,0,72.776,72.9a25.6,25.6,0,1,0-27.9,0A36.735,36.735,0,0,0,23.521,96.877a51.934,51.934,0,1,1,70.614,0Zm0,0"
                        transform="translate(24.172 26.122)"
                        fill="#fff"
                      />
                    </svg>
                  )}
                  <div className="params_set_user_pic">
                    <input
                      onChange={(e) => {
                        this.setState({
                          initialValues: {
                            ...initialValues,
                            photo: e.target.files,
                          },
                        });
                      }}
                      accept=".jpg,.png"
                      name="photo"
                      type="file"
                      id="upload_button"
                      hidden
                    />
                    <label style={{ width: '40%' }} htmlFor="upload_button">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="35.014"
                        height="27.558"
                        viewBox="0 0 35.014 27.558"
                      >
                        <g id="camera" transform="translate(0 -44.8)">
                          <g
                            id="Group_107"
                            data-name="Group 107"
                            transform="translate(0 44.8)"
                          >
                            <g
                              id="Group_106"
                              data-name="Group 106"
                              transform="translate(0 0)"
                            >
                              <path
                                id="Path_147"
                                data-name="Path 147"
                                d="M33.849,49.094a3.764,3.764,0,0,0-2.762-1.165H25.561v-.067a2.988,2.988,0,0,0-.9-2.163,3.045,3.045,0,0,0-2.163-.9H12.514a3.068,3.068,0,0,0-3.1,3.062v.067H3.927a3.764,3.764,0,0,0-2.762,1.165A3.957,3.957,0,0,0,0,51.856V68.431a3.764,3.764,0,0,0,1.165,2.762,3.957,3.957,0,0,0,2.762,1.165H31.086a3.764,3.764,0,0,0,2.762-1.165,3.957,3.957,0,0,0,1.165-2.762V51.856A3.764,3.764,0,0,0,33.849,49.094Zm-.566,19.337H33.25a2.159,2.159,0,0,1-2.163,2.163H3.927a2.159,2.159,0,0,1-2.163-2.163V51.856a2.159,2.159,0,0,1,2.163-2.163h6.424a.9.9,0,0,0,.9-.9v-.965a1.253,1.253,0,0,1,1.3-1.3H22.5a1.253,1.253,0,0,1,1.3,1.3v.965a.9.9,0,0,0,.9.9H31.12a2.159,2.159,0,0,1,2.163,2.163Z"
                                transform="translate(0 -44.8)"
                                fill="#ca2946"
                              />
                              <path
                                id="Path_148"
                                data-name="Path 148"
                                d="M120.188,130.8a8.182,8.182,0,1,0,5.791,2.4A8.2,8.2,0,0,0,120.188,130.8Zm4.526,12.747a6.428,6.428,0,0,1-9.053,0,6.363,6.363,0,0,1-1.864-4.526,6.5,6.5,0,0,1,1.864-4.526,6.363,6.363,0,0,1,4.526-1.864,6.5,6.5,0,0,1,4.526,1.864,6.363,6.363,0,0,1,1.864,4.526A6.228,6.228,0,0,1,124.714,143.547Z"
                                transform="translate(-102.681 -123.644)"
                                fill="#ca2946"
                              />
                              <circle
                                id="Ellipse_35"
                                data-name="Ellipse 35"
                                cx="1.631"
                                cy="1.631"
                                r="1.631"
                                transform="translate(27.725 7.123)"
                                fill="#ca2946"
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                    </label>
                  </div>
                </div>
              </div>
              <br />
              {hasError &&
              initialValues.permissions &&
              initialValues.permissions.length === 0 ? (
                <div style={{ flexDirection: 'row' }} className="lawsuit_form__sections">
                  <label
                    style={{ width: '100%', color: 'rgb(189, 35, 60)' }}
                    htmlFor="params_state"
                  >
                    العمل المسموح به ضروري
                  </label>
                </div>
              ) : (
                ''
              )}

              <div style={{ flexDirection: 'row' }} className="lawsuit_form__sections">
                <label style={{ width: '15%' }} htmlFor="attr">
                  العمل المسموح به
                </label>
                <label htmlFor="params_state">
                  قراءة
                  <Checkbox
                    checked={this.state['قراءة'] ? true : false}
                    onChange={(e) =>
                      this.handleChangeAll(e, 'قراءة', initialValues.permissions)
                    }
                    name="gilad"
                    size="medium"
                  />{' '}
                </label>
                <label htmlFor="params_state">
                  تعديل
                  <Checkbox
                    checked={this.state['تعديل'] ? true : false}
                    onChange={(e) =>
                      this.handleChangeAll(e, 'تعديل', initialValues.permissions)
                    }
                    size="medium"
                  />
                </label>
                <label htmlFor="params_state">
                  إضافة
                  <Checkbox
                    checked={this.state['إضافة'] ? true : false}
                    onChange={(e) =>
                      this.handleChangeAll(e, 'إضافة', initialValues.permissions)
                    }
                    size="medium"
                  />
                </label>
                <label htmlFor="params_state">
                  حذف
                  <Checkbox
                    checked={this.state['حذف'] ? true : false}
                    onChange={(e) =>
                      this.handleChangeAll(e, 'حذف', initialValues.permissions)
                    }
                    size="medium"
                  />
                </label>
              </div>
              <hr />
              {groupPermission && groupPermission.length > 0
                ? groupPermission.map((group) => {
                    return (
                      <div key={group.id}>
                        <div
                          style={{ flexDirection: 'row' }}
                          className="lawsuit_form__sections"
                        >
                          <label htmlFor="attr">{group.label}</label>
                          {group.permissions.map((permission) => {
                            return (
                              <div key={permission.id}>
                                <label htmlFor="attr">
                                  <Checkbox
                                    checked={
                                      initialValues.permissions &&
                                      initialValues.permissions.length > 0 &&
                                      initialValues.permissions.includes(permission.id)
                                        ? true
                                        : false
                                    }
                                    onChange={(e) => this.handleChange(e, permission.id)}
                                    size="medium"
                                  />
                                </label>
                              </div>
                            );
                          })}
                        </div>
                        <hr />
                      </div>
                    );
                  })
                : ''}
            </div>
          </div>
          <div className="popup__btn_grp">
            <button onClick={() => this.closeModal()} className="cancel_popup">
              <span>الغاء</span>
              <span>&lt;</span>
            </button>
            <button
              style={{
                background: '#63B17D',
              }}
              onClick={() => {
                this.submitForm(initialValues);
              }}
            >
              <span>تأكيد</span>
              <span className="trashSpan">
                <svg
                  className="tickSpan"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.125"
                  height="17.774"
                  viewBox="0 0 29.125 23.774"
                >
                  <path
                    id="Path_92"
                    data-name="Path 92"
                    d="M1325.354,167.5l8.34,9.625,17.8-19.417"
                    transform="translate(-1323.842 -156.357)"
                    fill="none"
                    stroke="#f9f9f9"
                    strokeWidth="4"
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>
        <div className={openDeletePopup ? 'popup active' : 'popup'}>
          <div className="popup__title">أنت بصدد حذف الحساب </div>
          <div className="popup__btn_grp">
            <button onClick={() => this.closeModal()} className="cancel_popup">
              <span>الغاء</span>
              <span>&lt;</span>
            </button>
            <button
              style={{
                background: '#CA2946',
              }}
              onClick={() => {
                if (selected_type === 'lawyer')
                  this.props.deleteLawyerTraineeShip(selected_id);
                else this.props.deleteSecretary(selected_id);
              }}
            >
              <span>تأكيد</span>
              <span className="trashSpan">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.906"
                  height="25.74"
                  viewBox="0 0 20.906 25.74"
                >
                  <g id="delete" transform="translate(0.003 0.002)">
                    <path
                      id="Path_82"
                      data-name="Path 82"
                      d="M223,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,223,154.7Zm0,0"
                      transform="translate(-208.995 -145.379)"
                      fill="#fff"
                    />
                    <path
                      id="Path_83"
                      data-name="Path 83"
                      d="M105,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,105,154.7Zm0,0"
                      transform="translate(-98.108 -145.379)"
                      fill="#fff"
                    />
                    <path
                      id="Path_84"
                      data-name="Path 84"
                      d="M1.709,7.662V22.514a3.326,3.326,0,0,0,.884,2.294,2.969,2.969,0,0,0,2.154.931H16.152a2.968,2.968,0,0,0,2.154-.931,3.326,3.326,0,0,0,.884-2.294V7.662A2.3,2.3,0,0,0,18.6,3.133H15.513V2.38A2.368,2.368,0,0,0,13.126,0H7.773A2.368,2.368,0,0,0,5.386,2.38v.754H2.3a2.3,2.3,0,0,0-.591,4.528ZM16.152,24.533H4.747a1.909,1.909,0,0,1-1.832-2.019V7.715h15.07v14.8a1.909,1.909,0,0,1-1.832,2.019ZM6.592,2.38A1.161,1.161,0,0,1,7.773,1.2h5.353A1.161,1.161,0,0,1,14.308,2.38v.754H6.592ZM2.3,4.339H18.6a1.085,1.085,0,0,1,0,2.17H2.3a1.085,1.085,0,0,1,0-2.17Zm0,0"
                      transform="translate(0 0)"
                      fill="#fff"
                    />
                    <path
                      id="Path_85"
                      data-name="Path 85"
                      d="M164,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,164,154.7Zm0,0"
                      transform="translate(-153.551 -145.379)"
                      fill="#fff"
                    />
                  </g>
                </svg>
              </span>
            </button>
          </div>
        </div>
        <div className={openActivePopup ? 'popup active' : 'popup'}>
          <div className="popup__title">
            {initialValues.access_status === 'identified'
              ? 'أنت بصدد قبول الحساب'
              : initialValues.access_status === 'accepted'
              ? 'أنت بصدد ايقاف الحساب'
              : 'تم قبول الحساب'}
          </div>
          <div className="popup__btn_grp">
            <button onClick={() => this.closeModal()} className="cancel_popup">
              <span>الغاء</span>
              <span>&lt;</span>
            </button>
            <button
              style={{
                background:
                  initialValues.access_status === 'identified' ? '#63B17D' : '#CA2946',
              }}
              onClick={() => {
                this.props.ActivitedDeactivetedAccount({
                  access_status:
                    initialValues.access_status === 'identified'
                      ? 'accepted'
                      : 'identified',
                  id: initialValues.login_access_id,
                });
              }}
            >
              <span>تأكيد</span>
              <span className="trashSpan">
                {initialValues.access_status === 'identified' ? (
                  <svg
                    className="tickSpan"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20.125"
                    height="17.774"
                    viewBox="0 0 29.125 23.774"
                  >
                    <path
                      id="Path_92"
                      data-name="Path 92"
                      d="M1325.354,167.5l8.34,9.625,17.8-19.417"
                      transform="translate(-1323.842 -156.357)"
                      fill="none"
                      stroke="#f9f9f9"
                      strokeWidth="4"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20.906"
                    height="25.74"
                    viewBox="0 0 20.906 25.74"
                  >
                    <g id="delete" transform="translate(0.003 0.002)">
                      <path
                        id="Path_82"
                        data-name="Path 82"
                        d="M223,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,223,154.7Zm0,0"
                        transform="translate(-208.995 -145.379)"
                        fill="#fff"
                      />
                      <path
                        id="Path_83"
                        data-name="Path 83"
                        d="M105,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,105,154.7Zm0,0"
                        transform="translate(-98.108 -145.379)"
                        fill="#fff"
                      />
                      <path
                        id="Path_84"
                        data-name="Path 84"
                        d="M1.709,7.662V22.514a3.326,3.326,0,0,0,.884,2.294,2.969,2.969,0,0,0,2.154.931H16.152a2.968,2.968,0,0,0,2.154-.931,3.326,3.326,0,0,0,.884-2.294V7.662A2.3,2.3,0,0,0,18.6,3.133H15.513V2.38A2.368,2.368,0,0,0,13.126,0H7.773A2.368,2.368,0,0,0,5.386,2.38v.754H2.3a2.3,2.3,0,0,0-.591,4.528ZM16.152,24.533H4.747a1.909,1.909,0,0,1-1.832-2.019V7.715h15.07v14.8a1.909,1.909,0,0,1-1.832,2.019ZM6.592,2.38A1.161,1.161,0,0,1,7.773,1.2h5.353A1.161,1.161,0,0,1,14.308,2.38v.754H6.592ZM2.3,4.339H18.6a1.085,1.085,0,0,1,0,2.17H2.3a1.085,1.085,0,0,1,0-2.17Zm0,0"
                        transform="translate(0 0)"
                        fill="#fff"
                      />
                      <path
                        id="Path_85"
                        data-name="Path 85"
                        d="M164,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,164,154.7Zm0,0"
                        transform="translate(-153.551 -145.379)"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                )}
              </span>
            </button>
          </div>
        </div>
        <div
          id="overlay"
          className={
            openUpdatePopup || openDeletePopup || openActivePopup ? 'active' : ''
          }
        ></div>
      </div>
    );
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Secretary));
