import axios from 'axios';
import { getStatusError } from '../../../utils/helpres';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastAction from '../../../components/ToastAction';
import React from 'react';
import { apiCall } from '../../../js/actions';
const ERROR_MAINTENANCE = 503;

const getLawsuitById = (id) => async (dispatch) => {
  dispatch({ type: 'GET_LAWSUIT_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'get',
      url: `/api/lawsuit/get-one/${id}`,
    });
    dispatch({ type: 'GET_LAWSUIT_SUCCESS', session: data.data });
  } catch (err) {
    const { response } = err;
    if (response)
      dispatch({
        type: 'GET_LAWSUIT_FAILURE',
        err: err.message,
        status: getStatusError(err),
      });
  }
};
const submitLawsuitForm = (values) => async (dispatch) => {
  dispatch({ type: 'UPDATE_LAWSUIT_REQUESTING' });
  const {
    date,
    email_court,
    send_to_court,
    folder_id,
    court,
    defendants,
    defendant,
    defendant_street,
    responsible_id,
    defendant_post,
    adjective,
    facts,
    evidence,
    requests,
    customer_id,
    suit_type_id,
    remaining_fees,
    sessions,
    advance_fees,
    fees,
    number,
    number_bill,
  } = values;
  try {
    let formData1 = new FormData();
    formData1.append('date', date);
    formData1.append('advance_fees', advance_fees);
    formData1.append('number_bill', number_bill);
    formData1.append('number', number);
    formData1.append('email_court', email_court);
    formData1.append('send_to_court', send_to_court);
    formData1.append('fees', fees);
    formData1.append('adjective', adjective);
    if (responsible_id)
      formData1.append(
        'responsible_id',
        responsible_id ? parseInt(responsible_id) : null,
      );
    formData1.append('defendant_post', defendant_post);
    formData1.append('defendant_street', defendant_street);
    formData1.append('defendant', defendant);
    formData1.append('court', court);
    formData1.append('defendants[]', JSON.stringify(defendants));
    formData1.append('remaining_fees', remaining_fees);
    formData1.append('session', date ? true : false);
    formData1.append('sessions[]', JSON.stringify(sessions));
    formData1.append('suit_type_id', suit_type_id);
    formData1.append('customer_id', customer_id);
    formData1.append('folder_id', folder_id);
    formData1.append('requests', requests);
    formData1.append('evidence', evidence);
    formData1.append('facts', facts);

    for (let i = 0; i < values.files.length; i++) {
      formData1.append('files', values.files[i].file);
    }
    /*if (values.allFiles && values.allFiles.length > 0) {
      formData1.append('files', values.allFiles);
    }*/
    const { data } = await apiCall({
      method: values.id ? 'patch' : 'post',
      url: `/api/lawsuit/${values.id ? `update/${values.id}` : 'create'}`,
      data: formData1,
    });

    if (data.type === 'success') {
      if (values.files && values.files.length > 0) {
        for (let n = 0; values.files.length; n++) {
          if (values.files[n]) {
            let formData = new FormData();
            formData.append('file', values.files[n].file);
            formData.append('type', 'lawsuit');
            formData.append('type_doc', 'documents');
            formData.append('id', data.data.id);
            await apiCall({
              method: 'post',
              url: `/api/upload`,
              data: formData,
            });
          } else break;
        }
        toast(({ closeToast }) => <ToastAction type="success" message={data.message} />, {
          hideProgressBar: true,
        });
        setTimeout(() => {
          dispatch({
            type: 'UPDATE_LAWSUIT_SUCCESS',
            addLawsuit: data.data,
          });
        }, 2000);
      } else {
        toast(({ closeToast }) => <ToastAction type="success" message={data.message} />, {
          hideProgressBar: true,
        });
        dispatch({
          type: 'UPDATE_LAWSUIT_SUCCESS',
          addLawsuit: data.data,
        });
      }
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      // toast.warn(data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_LAWSUIT_FAILURE',
        ErrorMessage: data.message,
        addLawsuit: values,
        status: true,
      });
    }
  } catch (e) {
    const { response } = e;
    if (response) {
      const { data } = response;
      if (data.message) {
        toast(
          ({ closeToast }) => (
            <ToastAction type="error" message={response.data.message} />
          ),
          { hideProgressBar: true },
        );
      } else
        toast(
          ({ closeToast }) => (
            <ToastAction
              type="error"
              message="عفوًا ، لم يتم إنشاء الجلسة ، يرجى إعادة المحاولة."
            />
          ),
          { hideProgressBar: true },
        );
      // toast.error(`Profil non ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_LAWSUIT_FAILURE',
        msg: 'error server',
        err: data.errors || data.message,
        addLawsuit: values,
        status: getStatusError(e),
      });
    } else {
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="error"
            message="عفوًا ، لم يتم إنشاء القضية ، يرجى إعادة المحاولة."
          />
        ),
        { hideProgressBar: true },
      );
      // toast.error(`Profil non ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_LAWSUIT_FAILURE',
        msg: 'error server',
        //err: data.errors || data.message,
        addLawsuit: values,
        status: getStatusError(e),
      });
    }
  }
};
const deleteFiles = (id) => async (dispatch) => {
  dispatch({ type: 'ACTION_DELETE_LAWSUIT_FILE_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'delete',
      url: `/api/lawsuit/file/delete/${id}`,
    });
    if (data.type === 'success') {
      toast(({ closeToast }) => <ToastAction type="success" message={data.message} />, {
        hideProgressBar: true,
      });
      dispatch({
        type: 'ACTION_DELETE_LAWSUIT_FILE_SUCCESS',
        deleteReport: data,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      dispatch({
        type: 'ACTION_DELETE_LAWSUIT_FILE_FAILURE',
        ErrorMessage: data.message,
        data: false,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    toast(({ closeToast }) => <ToastAction type="error" message="لم يتم حذف القضية" />, {
      hideProgressBar: true,
    });
    dispatch({
      type: 'ACTION_DELETE_LAWSUIT_FILE_FAILURE',
      msg: 'error server',
      err: data.errors || data.message,
      data: false,
    });
  }
};
const deleteLawsuit = (id, type) => async (dispatch) => {
  dispatch({ type: 'ACTION_DELETE_LAWSUIT_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'delete',
      url: `/api/lawsuit/delete/${id}?type=${type}`,
    });
    if (data.type === 'success') {
      toast(({ closeToast }) => <ToastAction type="success" message={data.message} />, {
        hideProgressBar: true,
      });
      dispatch({
        type: 'ACTION_DELETE_LAWSUIT_SUCCESS',
        destroyLawsuit: data,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      dispatch({
        type: 'ACTION_DELETE_LAWSUIT_FAILURE',
        ErrorMessage: data.message,
        data: false,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    toast(({ closeToast }) => <ToastAction type="error" message="لم يتم حذف القضية" />, {
      hideProgressBar: true,
    });
    dispatch({
      type: 'ACTION_DELETE_JURISPRUDENCE_FAILURE',
      msg: 'error server',
      err: data.errors || data.message,
      data: false,
    });
  }
};

const submitShareLawsuit = (values) => async (dispatch) => {
  dispatch({ type: 'SHARE_LAWSUIT_REQUESTING' });
  const { lawyer_id } = values;
  try {
    const { data } = await apiCall({
      method: 'post',
      url: `/api/lawsuit/shareLawsuit/${values.id}`,
      data: { lawyer_id },
    });

    if (data.type === 'success') {
      toast(({ closeToast }) => <ToastAction type="success" message={data.message} />, {
        hideProgressBar: true,
      });
      dispatch({
        type: 'SHARE_LAWSUIT_SUCCESS',
        addLawsuit: data.data,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      // toast.warn(data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'SHARE_LAWSUIT_FAILURE',
        ErrorMessage: data.message,
        addLawsuit: values,
        status: true,
      });
    }
  } catch (e) {
    const { response } = e;
    if (response) {
      const { data } = response;
      if (data.message) {
        toast(
          ({ closeToast }) => (
            <ToastAction type="error" message={response.data.message} />
          ),
          { hideProgressBar: true },
        );
      } else
        toast(
          ({ closeToast }) => (
            <ToastAction
              type="error"
              message="عفوًا ، لم يتم إنشاء الجلسة ، يرجى إعادة المحاولة."
            />
          ),
          { hideProgressBar: true },
        );
      // toast.error(`Profil non ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'SHARE_LAWSUIT_FAILURE',
        msg: 'error server',
        err: data.errors || data.message,
        addLawsuit: values,
        status: getStatusError(e),
      });
    } else {
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="error"
            message="عفوًا ، لم يتم إنشاء القضية ، يرجى إعادة المحاولة."
          />
        ),
        { hideProgressBar: true },
      );
      // toast.error(`Profil non ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'SHARE_LAWSUIT_FAILURE',
        msg: 'error server',
        //err: data.errors || data.message,
        addLawsuit: values,
        status: getStatusError(e),
      });
    }
  }
};
const submitCommentLawsuit = (values) => async (dispatch) => {
  dispatch({ type: 'COMMENT_LAWSUIT_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'post',
      url: `/api/lawsuit/create/comment`,
      data: values,
    });

    if (data.type === 'success') {
      toast(({ closeToast }) => <ToastAction type="success" message={data.message} />, {
        hideProgressBar: true,
      });
      dispatch({
        type: 'COMMENT_LAWSUIT_SUCCESS',
        addLawsuit: data.data,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      // toast.warn(data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'COMMENT_LAWSUIT_FAILURE',
        ErrorMessage: data.message,
        addLawsuit: values,
        status: true,
      });
    }
  } catch (e) {
    const { response } = e;
    if (response) {
      const { data } = response;
      if (data.message) {
        toast(
          ({ closeToast }) => (
            <ToastAction type="error" message={response.data.message} />
          ),
          { hideProgressBar: true },
        );
      } else
        toast(
          ({ closeToast }) => (
            <ToastAction
              type="error"
              message="عفوًا ، لم يتم اضافة التعليق ، يرجى إعادة المحاولة."
            />
          ),
          { hideProgressBar: true },
        );
      // toast.error(`Profil non ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'COMMENT_LAWSUIT_FAILURE',
        msg: 'error server',
        err: data.errors || data.message,
        addLawsuit: values,
        status: getStatusError(e),
      });
    } else {
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="error"
            message="عفوًا ، لم يتم اضافة التعليق ، يرجى إعادة المحاولة."
          />
        ),
        { hideProgressBar: true },
      );
      // toast.error(`Profil non ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'COMMENT_LAWSUIT_FAILURE',
        msg: 'error server',
        //err: data.errors || data.message,
        addLawsuit: values,
        status: getStatusError(e),
      });
    }
  }
};

export default {
  submitShareLawsuit,
  submitCommentLawsuit,
  getLawsuitById,
  submitLawsuitForm,
  deleteFiles,
  deleteLawsuit,
};
