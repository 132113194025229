const initialLawsuitState = {
  addLawsuit: null,
  isLoadingAddLawsuit: false,
  lawsuit: null,
  isLoadingLawsuit: false,
  isLoadingDestroyLawsuit: false,
  destroyLawsuit: false,
  isLoadingShareLawsuit: false,
  shareLawsuit: false,
  addComment: false,
};

const LawsuitReducer = (state = initialLawsuitState, action) => {
  switch (action.type) {
    case 'COMMENT_LAWSUIT_REQUESTING':
      return {
        ...state,
        isLoadingShareLawsuit: true,
      };
    case 'COMMENT_LAWSUIT_SUCCESS':
      return {
        ...state,
        isLoadingShareLawsuit: false,
        addComment: true,
      };
    case 'COMMENT_LAWSUIT_FAILURE':
      return {
        ...state,
        isLoadingShareLawsuit: false,
        addComment: false,
      };

    case 'SHARE_LAWSUIT_REQUESTING':
      return {
        ...state,
        isLoadingShareLawsuit: true,
      };
    case 'SHARE_LAWSUIT_SUCCESS':
      return {
        ...state,
        isLoadingShareLawsuit: false,
        shareLawsuit: true,
      };
    case 'SHARE_LAWSUIT_FAILURE':
      return {
        ...state,
        isLoadingShareLawsuit: false,
        shareLawsuit: false,
      };

    case 'ACTION_DELETE_LAWSUIT_REQUESTING':
      return {
        ...state,
        isLoadingDestroyLawsuit: true,
      };
    case 'ACTION_DELETE_LAWSUIT_SUCCESS':
      return {
        ...state,
        isLoadingDestroyLawsuit: false,
        destroyLawsuit: true,
      };
    case 'ACTION_DELETE_LAWSUIT_FAILURE':
      return {
        ...state,
        isLoadingDestroyLawsuit: false,
        destroyLawsuit: false,
      };
    case 'UPDATE_LAWSUIT_REQUESTING':
      return {
        ...state,
        isLoadingAddLawsuit: true,
      };
    case 'UPDATE_LAWSUIT_SUCCESS':
      return {
        ...state,
        isLoadingAddLawsuit: false,
        addLawsuit: action.addLawsuit,
      };
    case 'UPDATE_LAWSUIT_FAILURE':
      return {
        ...state,
        isLoadingAddLawsuit: false,
        addLawsuit: null,
      };
    case 'GET_LAWSUIT_REQUESTING':
      return {
        ...state,
        addComment: false,
        isLoadingLawsuit: true,
      };
    case 'GET_LAWSUIT_SUCCESS':
      return {
        ...state,
        addComment: false,
        isLoadingLawsuit: false,
        lawsuit: action.session,
      };
    case 'GET_LAWSUIT_FAILURE':
      return {
        ...state,
        isLoadingLawsuit: false,
        lawsuit: null,
      };

    default:
      return state;
  }
};

export default LawsuitReducer;
