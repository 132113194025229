import React, { Component } from 'react';
import actions from './store/actions';
import { connect } from 'react-redux';
import Table from '../../components/Table';

const mapStateToProps = (state) => ({
  addJurisprudence: state.JurisprudenceReducer.addJurisprudence,
  isLoadingAddJurisprudence: state.JurisprudenceReducer.isLoadingAddJurisprudence,
  isLoadingDeleteJurisprudence: state.JurisprudenceReducer.isLoadingDeleteJurisprudence,
  destroyJurisprudence: state.JurisprudenceReducer.deleteJurisprudence,
  Jurisprudences: state.JurisprudenceReducer.Jurisprudences,
  isLoadingJurisprudences: state.JurisprudenceReducer.isLoadingJurisprudences,
  paramsJurisprudence: state.JurisprudenceReducer.paramsJurisprudence,
});

const mapDispatchToProps = (dispatch) => ({
  getListJurisprudence: (paramsJurisprudence) =>
    dispatch(actions.getListJurisprudence(paramsJurisprudence)),
  deleteJurisprudence: async (paramsJurisprudence, id) => {
    await dispatch(actions.deleteJurisprudence(id));
    dispatch(actions.getListJurisprudence(paramsJurisprudence));
  },
  submitJurisprudenceForm: async (values, paramsJurisprudence) => {
    await dispatch(actions.submitJurisprudenceForm(values));
    dispatch(actions.getListJurisprudence(paramsJurisprudence));
  },
  changeSearchedStringJurisprudence: async (paramsJurisprudence) => {
    await dispatch(actions.changeSearchedStringJurisprudence(paramsJurisprudence));
    dispatch(actions.getListJurisprudence(paramsJurisprudence));
  },
});

class Jurisprudence extends Component {
  constructor(props) {
    super(props);
    props.changeActiveItemSideBar(0, 'فقه القضاء');
    document.title = 'فقه القضاء';
    this.state = {
      hasError: false,
      initialValues: {
        percent: 0,
        file: null,
        save: false,
        name: '',
      },
    };
    this.submitForm = this.submitForm.bind(this);
    this.increase = this.increase.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onSave = this.onSave.bind(this);
  }
  componentDidMount() {
    this.props.changeSearchedStringJurisprudence({
      currentPage: 1,
      limit: 5,
    });
  }
  submitForm(initialState) {
    if (!initialState.file) {
      this.setState({
        hasError: true,
      });
    } else {
      this.props.submitJurisprudenceForm(initialState, this.props.paramsJurisprudence);
    }
  }

  increase() {
    const { initialValues } = this.state;
    const newPercent = initialValues.percent + 1;
    if (newPercent >= 100) {
      clearTimeout(this.tm);
      return;
    }
    this.setState({
      initialValues: {
        ...initialValues,
        percent: newPercent,
      },
    });
    this.tm = setTimeout(this.increase, 20);
  }
  onConfirm() {
    const { initialValues } = this.state;
    this.setState({
      initialValues: {
        ...initialValues,
        percent: -1,
      },
    });
  }
  onSave() {
    const { initialValues } = this.state;
    this.setState({
      initialValues: {
        ...initialValues,
        save: !initialValues.save,
      },
    });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { addJurisprudence } = this.props;
    if (
      addJurisprudence !== prevProps.addJurisprudence &&
      addJurisprudence &&
      addJurisprudence.id
    ) {
      this.setState({
        initialValues: {
          ...this.state.initialValues,
          save: !this.state.initialValues.save,
          file: null,
          name: '',
        },
      });
      document.location.reload();
    }
  }

  render() {
    const {
      Jurisprudences,
      destroyJurisprudence,
      paramsJurisprudence,
      isLoadingJurisprudences,
      isLoadingAddJurisprudence,
    } = this.props;
    const { initialValues } = this.state;
    return (
      <div className="lj_main">
        <div className="lj_middle">
          <div className="table_controls">
            <form id="upload_form" action="">
              <input
                accept=".pdf,.docx"
                disabled={
                  initialValues.percent === 0 || isLoadingAddJurisprudence ? false : true
                }
                onChange={(e) => {
                  this.increase();
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      save: true,
                      file: e.target.files[0],
                      name: e.target.files[0].name,
                      //percent: 0,
                    },
                  });
                }}
                name="file"
                type="file"
                id="upload_button"
                hidden
              />
              {initialValues.percent >= 99 && initialValues.save ? (
                <button
                  style={{ background: '#63B17D', width: '12rem' }}
                  id="upload_button_label"
                  disabled={
                    initialValues.percent >= 99 || isLoadingAddJurisprudence
                      ? false
                      : true
                  }
                  onClick={() => this.submitForm(initialValues)}
                  type="button"
                >
                  <svg
                    className="tickSpan"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20.125"
                    height="17.774"
                    viewBox="0 0 29.125 23.774"
                  >
                    <path
                      id="Path_92"
                      data-name="Path 92"
                      d="M1325.354,167.5l8.34,9.625,17.8-19.417"
                      transform="translate(-1323.842 -156.357)"
                      fill="none"
                      stroke="#f9f9f9"
                      strokeWidth="4"
                    />
                  </svg>
                  تسجيل
                </button>
              ) : (
                <label
                  style={{ width: '10rem' }}
                  id="upload_button_label"
                  htmlFor="upload_button"
                >
                  <svg
                    height="10"
                    viewBox="0 0 448 448"
                    width="10"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m408 184h-136c-4.417969 0-8-3.582031-8-8v-136c0-22.089844-17.910156-40-40-40s-40 17.910156-40 40v136c0 4.417969-3.582031 8-8 8h-136c-22.089844 0-40 17.910156-40 40s17.910156 40 40 40h136c4.417969 0 8 3.582031 8 8v136c0 22.089844 17.910156 40 40 40s40-17.910156 40-40v-136c0-4.417969 3.582031-8 8-8h136c22.089844 0 40-17.910156 40-40s-17.910156-40-40-40zm0 0" />
                  </svg>
                  اضافة
                </label>
              )}
            </form>
            {!initialValues.file ? (
              ''
            ) : (
              <div style={{ display: 'flex' }} className="upload_status">
                <div className="uploaded_file__name">{initialValues.name}</div>
                <div id="progressBar">
                  <div
                    style={{ width: `${initialValues.percent + 1}%` }}
                    id="progressBarFill"
                  ></div>
                </div>
                <div className="upload_percenctage">{initialValues.percent + 1}%</div>
                <button
                  onClick={() =>
                    this.setState({
                      initialValues: {
                        ...initialValues,
                        percent: 0,
                        file: null,
                        name: '',
                        save: false,
                      },
                    })
                  }
                  type="button"
                  id="cancel_upload_button"
                >
                  إلغاء
                </button>
              </div>
            )}
          </div>
          <Table
            class1="lj__judgements_table"
            class2="lj_table"
            withTitle={false}
            colSpan={3}
            dataTable="legalJournal"
            messageDelete="أنت بصدد حذف المجلة"
            action={true}
            isLoading={isLoadingJurisprudences}
            params={paramsJurisprudence}
            changeSearchedString={this.props.changeSearchedStringJurisprudence}
            data={Jurisprudences}
            editPath=""
            deletePath={this.props.deleteJurisprudence}
            destroy={destroyJurisprudence}
            destroyYes={true}
            isPermissionDelete={true}
            headerItems={['العنوان', 'تاريخ الإضافة', 'الإعدادت']}
          />
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Jurisprudence);
