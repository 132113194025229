import React, { Component } from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import actions from '../store/actions';
import { connect } from 'react-redux';
import { DaysArabic, host } from '../../../utils/constants';
import moment from 'moment';
import { CircularProgress } from '@material-ui/core';

const mapStateToProps = (state) => ({
  contract: state.ContractReducer.contract,
  isLoadingContract: state.ContractReducer.isLoadingContract,
});

const mapDispatchToProps = (dispatch) => ({
  getContractById: (id) => dispatch(actions.getContractById(id)),
});

class Show extends Component {
  constructor(props) {
    super(props);
    props.getContractById(this.props.selected_id);
  }

  render() {
    const { left, anchor, contract, isLoadingContract } = this.props;
    return (
      <SwipeableDrawer
        style={{ width: '40rem' }}
        anchor="left"
        open={left}
        onClose={this.props.toggleDrawer(anchor, false)}
        onOpen={this.props.toggleDrawer(anchor, true)}
      >
        {isLoadingContract ? (
          <div
            style={{
              position: 'initial',
              width: '70rem',
              display: 'flex',
            }}
            className="rv_newDate"
          >
            <div className="clients_details_section">
              <CircularProgress
                style={{
                  textAlign: 'center',
                  width: '30px',
                  height: '30px',
                  marginTop: '39%',
                  marginRight: '42%',
                }}
              />
            </div>
          </div>
        ) : (
          <div
            style={{
              position: 'initial',
              width: '70rem',
              display: 'flex',
            }}
            className="rv_newDate"
          >
            <div className="clients_details_section">
              <div className="clients_details_section__body">
                {contract.responsible ? (
                  <div className="clients_details_section__field">
                    <div className="clients_field_title item-name">يسند إلى</div>
                    <div className="clients_data_name">{`${contract.responsible.first_name} ${contract.responsible.last_name}`}</div>
                  </div>
                ) : (
                  ''
                )}
                <div className="clients_details_section__field">
                  <div className="clients_field_title item-name">الحريف</div>
                  <div className="clients_data_name">{contract.customer.name}</div>
                </div>
                <div className="clients_details_section__field">
                  <div className="clients_field_title item-name">الطرف الثاني</div>
                  <div className="clients_data_phone">{contract.second_party_name}</div>
                </div>
                <div className="clients_details_section__field">
                  <div className="clients_field_title item-name">ب ت و</div>
                  <div className="clients_data_addr">{contract.second_party_cin}</div>
                </div>
                <div className="clients_details_section__field">
                  <div className="clients_field_title item-name">المقر</div>
                  <div className="clients_data_addr">{contract.second_party_address}</div>
                </div>
                <div className="clients_details_section__field">
                  <div className="clients_field_title item-name">المهنة</div>
                  <div className="clients_data_addr">{contract.second_party_post}</div>
                </div>
                <div className="clients_details_section__field">
                  <div className="clients_field_title item-name">نوع العقد</div>
                  <div className="clients_data_addr">{contract.type_contract.name}</div>
                </div>
                <div className="clients_details_section__field">
                  <div className="clients_field_title item-name">تاريخ العقد</div>
                  <div className="clients_data_addr">
                    {' '}
                    {DaysArabic[moment(contract.date).format('dddd')]}{' '}
                    <span style={{ color: '#ca2946' }}>
                      {moment(contract.date).format('DD/MM/YYYY')}
                    </span>
                  </div>
                </div>
                <div className="clients_details_section__field">
                  <div className="clients_field_title item-name">الثمن</div>
                  <div className="clients_data_city">{contract.price}</div>
                </div>
                <span className="ls_seperator">الأتعاب</span>
                <div className="clients_details_section__field">
                  <div className="clients_field_title item-name">الأتعاب</div>
                  <div className="clients_data_addr">{contract.fees}</div>
                </div>
              </div>
            </div>
          </div>
        )}
      </SwipeableDrawer>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Show);
