import React, { useRef } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import moment from 'moment/moment';

const PdfAllDocument = (props) => {
  const pdfRef = useRef();
  const lawsuits = props.location.state.lawsuits.data;
  document.title = `القضايا`;
  const account = props.location.state.account;
  console.log(lawsuits);
  const generatePdf = () => {
    const input = pdfRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      pdf.addImage(imgData, 'PNG', 0, 0);
      pdf.save(`القضايا.pdf`);
    });
  };

  return (
    <div className="jalaset_main">
      <div className="popup__btn_grp">
        <button className="jalaset_add_button" onClick={generatePdf}>
          <span>تحميل PDF</span>
        </button>
      </div>
      <div
        ref={pdfRef}
        style={{
          padding: '20px',
          width: '210mm',
          height: '297mm',
          margin: 'auto',
          backgroundColor: 'white',
        }}
      >
        <div style={{ display: 'flex' }}>
          <div style={{ alignItems: 'start' }}>
            <p style={{ textAlign: 'right', fontSize: '14px' }}>
              التاريخ: {moment().format('DD/MM/YYYY')}
            </p>
          </div>
        </div>
        <div>
          <h1 style={{ textAlign: 'center', fontSize: '18px' }}>
            الأستاذ {`${account.first_name} ${account.last_name}`}
          </h1>
          <h2 style={{ textAlign: 'center', fontSize: '16px' }}>
            المحامي لدى {account.adjective}
          </h2>
          <p style={{ textAlign: 'center', fontSize: '14px' }}>
            العنوان:{' '}
            {`${account.address_street || ''} ${account.zip_code || ''}, ${
              account.address_city || ''
            }`}
          </p>
          <p style={{ textAlign: 'center', fontSize: '14px' }}>
            الهاتف: {account.phone} - الفاكس: {account.fix_number || '-'}
          </p>
        </div>
        <table border="1" style={{ width: '100%', marginTop: '20px' }}>
          <thead>
            <tr>
              <th style={{ padding: 5 }}>عدد القضية</th>
              <th style={{ padding: 5 }}>نوع القضية</th>
              <th style={{ padding: 5 }}>المحكمة</th>
              <th style={{ padding: 5 }}>المدعي</th>
              <th style={{ padding: 5 }}>الضد</th>
              <th style={{ padding: 5 }}>تاريخ الجلسة</th>
            </tr>
          </thead>
          <tbody>
            {lawsuits &&
              lawsuits.length > 0 &&
              lawsuits.map((lawsuit) => {
                return (
                  <tr key={lawsuit.id}>
                    <td width="10%" style={{ padding: 5, fontSize: 13 }}>
                      {lawsuit.number}
                    </td>
                    <td width="10%" style={{ padding: 5, fontSize: 13 }}>
                      {lawsuit.suit_type ? lawsuit.suit_type.name : ''}
                    </td>
                    <td width="20%" style={{ padding: 5, fontSize: 13 }}>
                      {lawsuit.court}
                    </td>
                    <td width="20%" style={{ padding: 5, fontSize: 13 }}>
                      {lawsuit.customer.name}
                    </td>
                    <td width="20%" style={{ padding: 5, fontSize: 13 }}>
                      {lawsuit.defendants && lawsuit.defendants[0].display_name}
                    </td>
                    <td style={{ padding: 5, fontSize: 13, width: '20%' }} width="20%">
                      <>
                        <span
                          style={{ fontSize: '15px', position: 'inherit' }}
                          className="rv_td_span_day"
                        >{`${moment(lawsuit.date).format('dddd')}`}</span>
                        <span style={{ padding: '10px' }} className="rv_td_span_date">
                          {moment(lawsuit.date).format('YYYY/MM/DD')}
                        </span>
                      </>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {/* Add other fields and content here as needed */}
      </div>
    </div>
  );
};

export default PdfAllDocument;
