import React, { Component } from 'react';
import InputField from '../../components/inputField';
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import { courts, DaysArabic, host } from '../../utils/constants';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import actions from './store/actions';
import { connect } from 'react-redux';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const mapDispatchToProps = (dispatch) => ({
  getConsultation: () => dispatch(actions.getConsultation()),
});
class LawsuitRemotely extends Component {
  constructor(props) {
    super(props);
    props.changeActiveItemSideBar(3, 'متابعة القضايا عن بعد');
    document.title = 'متابعة القضايا عن بعد';
    this.state = {
      value: 0,
      initialValue: {},
    };
    this.handleChange = this.handleChange.bind(this);
  }
  a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  handleChange = (event, value) => {
    this.setState({
      value,
    });
  };
  render() {
    const { value, initialValues } = this.state;
    return (
      <div style={{ display: 'flex', marginBottom: '5%' }} className="lawsuit_newLawsuit">
        <div className="jalaset_middle">
          <div>
            <AppBar position="static">
              <Tabs
                value={value}
                onChange={this.handleChange}
                aria-label="simple tabs example"
              >
                <Tab label="متابعة القضايا المدنية" {...this.a11yProps(0)} />
                <Tab label="متابعة القضايا الجزائية" {...this.a11yProps(1)} />
                <Tab label="المحاضر والشكايات" {...this.a11yProps(2)} />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <h3>يتم معالجتها</h3>
              {/*<form action="">
                <div className="lawsuit_form__sections">
                  <div>
                    <label htmlFor="court">المحكمة</label>
                    <Autocomplete
                      debug
                      id="debug"
                      options={courts}
                      getOptionLabel={(option) => option.title}
                      style={{ marginBottom: '4%' }}
                      onChange={(event, option) => {
                        this.setState({
                          initialValues: {
                            ...initialValues,
                            court: option != null ? option.title : '',
                          },
                        });
                      }}
                      defaultValue={
                        {
                          //title: initialValues.court,
                        }
                      }
                      renderInput={(params) => (
                        <TextField
                          //error={!initialValues.court && hasError}
                          name="court"
                          style={{ border: 0 }}
                          {...params}
                          //label="المحكمة"
                          //variant="outlined"
                        />
                      )}
                    />
                  </div>
                  <div>
                    <label htmlFor="attr">نوع القضية</label>
                    <InputField
                      // value={initialValues.number}
                      name="number"
                      placeholder="عدد القضية"
                      type="text"
                      //hasError={hasError}
                      validInput="integer"
                      onChange={(event) => {
                        this.setState({
                          initialValues: {
                            ...initialValues,
                            number: event.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                  <div>
                    <label htmlFor="attr">عدد القضية</label>
                    <InputField
                      // value={initialValues.number}
                      name="number"
                      placeholder="عدد القضية"
                      type="text"
                      //hasError={hasError}
                      validInput="integer"
                      onChange={(event) => {
                        this.setState({
                          initialValues: {
                            ...initialValues,
                            number: event.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                  <div>
                    <label htmlFor="attr">السنة</label>
                    <InputField
                      // value={initialValues.number}
                      name="number"
                      placeholder="عدد القضية"
                      type="text"
                      //hasError={hasError}
                      validInput="integer"
                      onChange={(event) => {
                        this.setState({
                          initialValues: {
                            ...initialValues,
                            number: event.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="lawsuit_form__btn_grp">
                  <button
                    onClick={() => this.props.history.goBack()}
                    type="button"
                    className="lawsuit_cancel_session"
                  >
                    <svg
                      className="lawsuit_ltSpan"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      xlinkHref="http://svgjs.com/svgjs"
                      version="1.1"
                      width="12"
                      height="12"
                      x="0"
                      y="0"
                      viewBox="0 0 492.004 492.004"
                      style={{ enableBackground: 'new 0 0 512 512' }}
                      xmlSpace="preserve"
                    >
                      <g>
                        <g xmlns="http://www.w3.org/2000/svg">
                          <g>
                            <path
                              d="M382.678,226.804L163.73,7.86C158.666,2.792,151.906,0,144.698,0s-13.968,2.792-19.032,7.86l-16.124,16.12    c-10.492,10.504-10.492,27.576,0,38.064L293.398,245.9l-184.06,184.06c-5.064,5.068-7.86,11.824-7.86,19.028    c0,7.212,2.796,13.968,7.86,19.04l16.124,16.116c5.068,5.068,11.824,7.86,19.032,7.86s13.968-2.792,19.032-7.86L382.678,265    c5.076-5.084,7.864-11.872,7.848-19.088C390.542,238.668,387.754,231.884,382.678,226.804z"
                              fill="#2d2d2d"
                              data-original="#000000"
                              className=""
                            />
                          </g>
                        </g>
                        <g xmlns="http://www.w3.org/2000/svg"></g>
                        <g xmlns="http://www.w3.org/2000/svg"></g>
                        <g xmlns="http://www.w3.org/2000/svg"></g>
                        <g xmlns="http://www.w3.org/2000/svg"></g>
                        <g xmlns="http://www.w3.org/2000/svg"></g>
                        <g xmlns="http://www.w3.org/2000/svg"></g>
                        <g xmlns="http://www.w3.org/2000/svg"></g>
                        <g xmlns="http://www.w3.org/2000/svg"></g>
                        <g xmlns="http://www.w3.org/2000/svg"></g>
                        <g xmlns="http://www.w3.org/2000/svg"></g>
                        <g xmlns="http://www.w3.org/2000/svg"></g>
                        <g xmlns="http://www.w3.org/2000/svg"></g>
                        <g xmlns="http://www.w3.org/2000/svg"></g>
                        <g xmlns="http://www.w3.org/2000/svg"></g>
                        <g xmlns="http://www.w3.org/2000/svg"></g>
                      </g>
                    </svg>
                    الغاء
                  </button>
                  <button onClick={() => this.props.getConsultation()} type="button">
                    <svg
                      className="tickSpan"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20.125"
                      height="17.774"
                      viewBox="0 0 29.125 23.774"
                    >
                      <path
                        id="Path_92"
                        data-name="Path 92"
                        d="M1325.354,167.5l8.34,9.625,17.8-19.417"
                        transform="translate(-1323.842 -156.357)"
                        fill="none"
                        stroke="#f9f9f9"
                        strokeWidth="4"
                      />
                    </svg>
                    تسجيل
                  </button>
                </div>
                 <div className={openDeletePopupSession ? 'popup active' : 'popup'}>
                  <div className="popup__title">أنت بصدد حذف الجلسة</div>
                  <div className="popup__btn_grp">
                    <button
                      type="button"
                      onClick={() => this.handleActiveDeletePopupSession()}
                      className="cancel_popup"
                    >
                      <span>الغاء</span>
                      <span>&lt;</span>
                    </button>

                    <button
                      disabled={isLoadingAddLawsuit}
                      type="button"
                      onClick={() => {
                        this.props.destroySession(selected_session_id, initialValues.id);
                      }}
                    >
                      {isLoadingAddLawsuit ? (
                        <CircularProgress
                          style={{
                            textAlign: 'center',
                            width: '30px',
                            height: '30px',
                            color: 'white',
                          }}
                        />
                      ) : (
                        <span>تأكيد</span>
                      )}
                      <span className="trashSpan">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20.906"
                          height="25.74"
                          viewBox="0 0 20.906 25.74"
                        >
                          <g id="delete" transform="translate(0.003 0.002)">
                            <path
                              id="Path_82"
                              data-name="Path 82"
                              d="M223,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,223,154.7Zm0,0"
                              transform="translate(-208.995 -145.379)"
                              fill="#fff"
                            />
                            <path
                              id="Path_83"
                              data-name="Path 83"
                              d="M105,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,105,154.7Zm0,0"
                              transform="translate(-98.108 -145.379)"
                              fill="#fff"
                            />
                            <path
                              id="Path_84"
                              data-name="Path 84"
                              d="M1.709,7.662V22.514a3.326,3.326,0,0,0,.884,2.294,2.969,2.969,0,0,0,2.154.931H16.152a2.968,2.968,0,0,0,2.154-.931,3.326,3.326,0,0,0,.884-2.294V7.662A2.3,2.3,0,0,0,18.6,3.133H15.513V2.38A2.368,2.368,0,0,0,13.126,0H7.773A2.368,2.368,0,0,0,5.386,2.38v.754H2.3a2.3,2.3,0,0,0-.591,4.528ZM16.152,24.533H4.747a1.909,1.909,0,0,1-1.832-2.019V7.715h15.07v14.8a1.909,1.909,0,0,1-1.832,2.019ZM6.592,2.38A1.161,1.161,0,0,1,7.773,1.2h5.353A1.161,1.161,0,0,1,14.308,2.38v.754H6.592ZM2.3,4.339H18.6a1.085,1.085,0,0,1,0,2.17H2.3a1.085,1.085,0,0,1,0-2.17Zm0,0"
                              transform="translate(0 0)"
                              fill="#fff"
                            />
                            <path
                              id="Path_85"
                              data-name="Path 85"
                              d="M164,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,164,154.7Zm0,0"
                              transform="translate(-153.551 -145.379)"
                              fill="#fff"
                            />
                          </g>
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
                <div className={openDeletePopup ? 'popup active' : 'popup'}>
                  <div className="popup__title">أنت بصدد حذف الملف</div>
                  <div className="popup__btn_grp">
                    <button
                      type="button"
                      onClick={() => this.handleActivePopupDeleteItem()}
                      className="cancel_popup"
                    >
                      <span>الغاء</span>
                      <span>&lt;</span>
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        this.props.deleteFiles(initialValues.id, selected_id);
                      }}
                    >
                      <span>تأكيد</span>
                      <span className="trashSpan">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20.906"
                          height="25.74"
                          viewBox="0 0 20.906 25.74"
                        >
                          <g id="delete" transform="translate(0.003 0.002)">
                            <path
                              id="Path_82"
                              data-name="Path 82"
                              d="M223,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,223,154.7Zm0,0"
                              transform="translate(-208.995 -145.379)"
                              fill="#fff"
                            />
                            <path
                              id="Path_83"
                              data-name="Path 83"
                              d="M105,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,105,154.7Zm0,0"
                              transform="translate(-98.108 -145.379)"
                              fill="#fff"
                            />
                            <path
                              id="Path_84"
                              data-name="Path 84"
                              d="M1.709,7.662V22.514a3.326,3.326,0,0,0,.884,2.294,2.969,2.969,0,0,0,2.154.931H16.152a2.968,2.968,0,0,0,2.154-.931,3.326,3.326,0,0,0,.884-2.294V7.662A2.3,2.3,0,0,0,18.6,3.133H15.513V2.38A2.368,2.368,0,0,0,13.126,0H7.773A2.368,2.368,0,0,0,5.386,2.38v.754H2.3a2.3,2.3,0,0,0-.591,4.528ZM16.152,24.533H4.747a1.909,1.909,0,0,1-1.832-2.019V7.715h15.07v14.8a1.909,1.909,0,0,1-1.832,2.019ZM6.592,2.38A1.161,1.161,0,0,1,7.773,1.2h5.353A1.161,1.161,0,0,1,14.308,2.38v.754H6.592ZM2.3,4.339H18.6a1.085,1.085,0,0,1,0,2.17H2.3a1.085,1.085,0,0,1,0-2.17Zm0,0"
                              transform="translate(0 0)"
                              fill="#fff"
                            />
                            <path
                              id="Path_85"
                              data-name="Path 85"
                              d="M164,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,164,154.7Zm0,0"
                              transform="translate(-153.551 -145.379)"
                              fill="#fff"
                            />
                          </g>
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
                <div className={openAddPopup ? 'popup active' : 'popup'}>
                  <div className="popup__title">أنت بصدد اضافة مآل</div>
                  <div className="popup__item">
                    <div className="popup__text">
                      <div
                        style={{ display: 'block', flexDirection: 'row' }}
                        className="lawsuit_form__sections"
                      >
                        <div className="rv_form__sections-left">
                          <div
                            style={{
                              alignItems: 'center',
                            }}
                            className="jalaset_form__sections-left"
                          >
                            <div
                              style={{
                                width: '9rem',
                                marginLeft: 0,
                              }}
                              className="jalaset_form__label"
                              htmlFor="date_selected"
                            >
                              تاريخ الجلسة
                            </div>
                            <div className="jalaset_form__cal">
                              <DatePicker
                                selected={new Date(initialValues.date_session)}
                                locale="ar"
                                onChange={(date) => {
                                  this.setState({
                                    initialValues: {
                                      ...initialValues,
                                      date_session: date,
                                    },
                                  });
                                }}
                                customInput={
                                  <svg
                                    style={{
                                      cursor: 'pointer',
                                    }}
                                    id="calendar"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    viewBox="0 0 30 30"
                                  >
                                    <g id="Group_28" data-name="Group 28">
                                      <g id="Group_27" data-name="Group 27">
                                        <ellipse
                                          id="Ellipse_20"
                                          data-name="Ellipse 20"
                                          cx="1"
                                          cy="1.5"
                                          rx="1"
                                          ry="1.5"
                                          transform="translate(22 11)"
                                          fill="#ca2946"
                                        />
                                        <path
                                          id="Path_89"
                                          data-name="Path 89"
                                          d="M25.312,2.344H23.789V1.172a1.172,1.172,0,0,0-2.344,0V2.344H16.113V1.172a1.172,1.172,0,0,0-2.344,0V2.344H8.5V1.172a1.172,1.172,0,1,0-2.344,0V2.344H4.688A4.693,4.693,0,0,0,0,7.031V25.312A4.693,4.693,0,0,0,4.688,30h8.965a1.172,1.172,0,0,0,0-2.344H4.688a2.346,2.346,0,0,1-2.344-2.344V7.031A2.346,2.346,0,0,1,4.688,4.688H6.152V5.859a1.172,1.172,0,0,0,2.344,0V4.688H13.77V5.859a1.172,1.172,0,0,0,2.344,0V4.688h5.332V5.859a1.172,1.172,0,0,0,2.344,0V4.688h1.523a2.346,2.346,0,0,1,2.344,2.344v6.68a1.172,1.172,0,0,0,2.344,0V7.031A4.693,4.693,0,0,0,25.312,2.344Z"
                                          fill="#ca2946"
                                        />
                                        <path
                                          id="Path_90"
                                          data-name="Path 90"
                                          d="M277.09,270a7.09,7.09,0,1,0,7.09,7.09A7.1,7.1,0,0,0,277.09,270Zm0,11.836a4.746,4.746,0,1,1,4.746-4.746A4.751,4.751,0,0,1,277.09,281.836Z"
                                          transform="translate(-254.18 -254.18)"
                                          fill="#ca2946"
                                        />
                                        <path
                                          id="Path_91"
                                          data-name="Path 91"
                                          d="M373.871,332.4h-.527v-1.23a1.172,1.172,0,0,0-2.344,0v2.4a1.172,1.172,0,0,0,1.172,1.172h1.7a1.172,1.172,0,0,0,0-2.344Z"
                                          transform="translate(-349.262 -310.664)"
                                          fill="#ca2946"
                                        />
                                        <circle
                                          id="Ellipse_21"
                                          data-name="Ellipse 21"
                                          cx="1.5"
                                          cy="1.5"
                                          r="1.5"
                                          transform="translate(16 11)"
                                          fill="#ca2946"
                                        />
                                        <circle
                                          id="Ellipse_22"
                                          data-name="Ellipse 22"
                                          cx="1.5"
                                          cy="1.5"
                                          r="1.5"
                                          transform="translate(11 16)"
                                          fill="#ca2946"
                                        />
                                        <ellipse
                                          id="Ellipse_23"
                                          data-name="Ellipse 23"
                                          cx="1"
                                          cy="1.5"
                                          rx="1"
                                          ry="1.5"
                                          transform="translate(6 11)"
                                          fill="#ca2946"
                                        />
                                        <ellipse
                                          id="Ellipse_24"
                                          data-name="Ellipse 24"
                                          cx="1"
                                          cy="1.5"
                                          rx="1"
                                          ry="1.5"
                                          transform="translate(6 16)"
                                          fill="#ca2946"
                                        />
                                        <circle
                                          id="Ellipse_25"
                                          data-name="Ellipse 25"
                                          cx="1"
                                          cy="1"
                                          r="1"
                                          transform="translate(6 22)"
                                          fill="#ca2946"
                                        />
                                        <ellipse
                                          id="Ellipse_26"
                                          data-name="Ellipse 26"
                                          cx="1.5"
                                          cy="1"
                                          rx="1.5"
                                          ry="1"
                                          transform="translate(11 22)"
                                          fill="#ca2946"
                                        />
                                        <circle
                                          id="Ellipse_27"
                                          data-name="Ellipse 27"
                                          cx="1.5"
                                          cy="1.5"
                                          r="1.5"
                                          transform="translate(11 11)"
                                          fill="#ca2946"
                                        />
                                      </g>
                                    </g>
                                  </svg>
                                }
                              />
                            </div>

                            <div className="jalaset_form__date_selected">
                              <div className="jalaset_form__date_selected__day">
                                {
                                  DaysArabic[
                                    moment(initialValues.date_session).format('dddd')
                                  ]
                                }
                              </div>
                              <div className="jalaset_form__date_selected__day">
                                {moment(initialValues.date_session).format('DD/MM/YYYY')}
                              </div>
                            </div>
                          </div>
                          <div className="rv_form__sections-left_field">
                            <div className="rv_form__label" htmlFor="date_selected">
                              توقيت الجلسة
                            </div>
                            <div className="rv_form__t">
                              <DatePicker
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                dateFormat="HH:mm"
                                selected={new Date(initialValues.date_session)}
                                locale="ar"
                                minTime="08"
                                maxTime={18}
                                onChange={(date) => {
                                  this.setState({
                                    initialValues: {
                                      ...initialValues,
                                      date_session: date,
                                    },
                                  });
                                }}
                                customInput={
                                  <svg
                                    className="rv_time_icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24.538"
                                    height="24.538"
                                    viewBox="0 0 24.538 24.538"
                                  >
                                    <g
                                      id="clock_1_"
                                      data-name="clock(1)"
                                      transform="translate(0.5 0.5)"
                                    >
                                      <path
                                        id="Path_103"
                                        data-name="Path 103"
                                        d="M11.769,0A11.769,11.769,0,1,0,23.538,11.769,11.782,11.782,0,0,0,11.769,0Zm0,22.067a10.3,10.3,0,1,1,10.3-10.3,10.309,10.309,0,0,1-10.3,10.3Z"
                                        fill="#a8192b"
                                        stroke="#a8192b"
                                        strokeWidth="1"
                                      />
                                      <path
                                        id="Path_104"
                                        data-name="Path 104"
                                        d="M209.265,83.118h-1.471v7.66l4.629,4.629,1.04-1.04-4.2-4.2Z"
                                        transform="translate(-196.761 -78.705)"
                                        fill="#a8192b"
                                        stroke="#a8192b"
                                        strokeWidth="1"
                                      />
                                    </g>
                                  </svg>
                                }
                              />
                            </div>
                            <div className="rv_form__time_selected">
                              {moment(initialValues.date_session).format('HH:mm')}س -
                              {moment(initialValues.date_session)
                                .add(30, 'minutes')
                                .format('HH:mm')}
                              س
                            </div>
                          </div>
                        </div>
                        <div>
                          {hasErrorDateSession &&
                          initialValues.date_session &&
                          moment().isAfter(moment(initialValues.date_session)) ? (
                            <div className="rv_form__sections-left_field">
                              <div>
                                <svg
                                  className="rv_danger_icon"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="27.077"
                                  height="24.294"
                                  viewBox="0 0 27.077 24.294"
                                >
                                  <g id="alert" transform="translate(0 -26.315)">
                                    <g
                                      id="Group_39"
                                      data-name="Group 39"
                                      transform="translate(0 26.315)"
                                    >
                                      <g
                                        id="Group_38"
                                        data-name="Group 38"
                                        transform="translate(0 0)"
                                      >
                                        <path
                                          id="Path_105"
                                          data-name="Path 105"
                                          d="M26.645,45.833,16.3,27.907a3.184,3.184,0,0,0-5.515,0L.431,45.833a3.184,3.184,0,0,0,2.757,4.776h20.7a3.184,3.184,0,0,0,2.757-4.776Zm-1.374,2.391a1.581,1.581,0,0,1-1.384.8H3.189a1.6,1.6,0,0,1-1.384-2.4L12.155,28.7a1.6,1.6,0,0,1,2.767,0l10.35,17.926A1.582,1.582,0,0,1,25.272,48.224Z"
                                          transform="translate(0 -26.315)"
                                          fill="#ff8000"
                                        />
                                      </g>
                                    </g>
                                    <g
                                      id="Group_41"
                                      data-name="Group 41"
                                      transform="translate(12.745 34.232)"
                                    >
                                      <g
                                        id="Group_40"
                                        data-name="Group 40"
                                        transform="translate(0 0)"
                                      >
                                        <rect
                                          id="Rectangle_145"
                                          data-name="Rectangle 145"
                                          width="1.586"
                                          height="7.932"
                                          fill="#ff8000"
                                        />
                                      </g>
                                    </g>
                                    <g
                                      id="Group_43"
                                      data-name="Group 43"
                                      transform="translate(12.481 43.75)"
                                    >
                                      <g id="Group_42" data-name="Group 42">
                                        <path
                                          id="Path_106"
                                          data-name="Path 106"
                                          d="M237.06,355.99a1.058,1.058,0,1,0,1.058,1.058A1.059,1.059,0,0,0,237.06,355.99Z"
                                          transform="translate(-236.002 -355.99)"
                                          fill="#ff8000"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </svg>
                              </div>
                              <div className="rv_err_msg">
                                الرجاء تحديد تاريخ في المستقبل
                              </div>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                        <div style={{ marginBottom: '4%', marginTop: '3%' }}>
                          <label htmlFor="court">المحكمة</label>
                          <Autocomplete
                            debug
                            id="debug"
                            options={courts}
                            getOptionLabel={(option) => option.title}
                            style={{ marginBottom: '4%' }}
                            onChange={(event, option) => {
                              this.setState({
                                initialValues: {
                                  ...initialValues,
                                  court_session: option != null ? option.title : '',
                                },
                              });
                            }}
                            defaultValue={{
                              title: initialValues.court_session,
                            }}
                            renderInput={(params) => (
                              <TextField
                                error={!initialValues.court_session && hasError}
                                name="court_session"
                                style={{ border: 0 }}
                                {...params}
                                //label="المحكمة"
                                //variant="outlined"
                              />
                            )}
                          />
                        </div>
                        <div>
                          <label htmlFor="lawsuit_events">وصف </label>
                          <textarea
                            onChange={(event) => {
                              this.setState({
                                initialValues: {
                                  ...initialValues,
                                  description: event.target.value,
                                },
                              });
                            }}
                            value={initialValues.description}
                            className={
                              hasError && !initialValues.description ? 'input-error' : ''
                            }
                            name="facts"
                            placeholder="وصف"
                            id="lawsuit_events"
                          >
                            {initialValues.description}
                          </textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="popup__btn_grp">
                    <button
                      type="button"
                      onClick={() => this.handleActiveAddPopup()}
                      className="cancel_popup"
                    >
                      <span>الغاء</span>
                      <span>&lt;</span>
                    </button>
                    <button
                      style={{
                        background: '#63B17D',
                      }}
                      type="button"
                      onClick={() => {
                        this.submitFormSession(initialValues);
                      }}
                    >
                      <span>تأكيد</span>
                      <span className="trashSpan">
                        <svg
                          className="tickSpan"
                          xmlns="http://www.w3.org/2000/svg"
                          width="20.125"
                          height="17.774"
                          viewBox="0 0 29.125 23.774"
                        >
                          <path
                            id="Path_92"
                            data-name="Path 92"
                            d="M1325.354,167.5l8.34,9.625,17.8-19.417"
                            transform="translate(-1323.842 -156.357)"
                            fill="none"
                            stroke="#f9f9f9"
                            strokeWidth="4"
                          />
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
                <div
                  id="overlay"
                  className={
                    openDeletePopup || openAddPopup || openDeletePopupSession
                      ? 'active'
                      : ''
                  }
                ></div>
              </form>*/}
            </TabPanel>
            <TabPanel value={value} index={1}>
              {/*<form action="">
                <div className="lawsuit_form__sections">
                  <div>
                    <label htmlFor="attr">عدد القضية</label>
                    <InputField
                      // value={initialValues.number}
                      name="number"
                      placeholder="عدد القضية"
                      type="text"
                      //hasError={hasError}
                      validInput="integer"
                      onChange={(event) => {
                        this.setState({
                          initialValues: {
                            ...initialValues,
                            number: event.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                  <div>
                    <label>المدعى عليه</label>
                    <InputField
                      //value={initialValues.defendant}
                      name="defendant"
                      placeholder=" الاسم الثلاثي"
                      type="text"
                      //hasError={hasError}
                      validInput="text"
                      onChange={(event) => {
                        this.setState({
                          initialValues: {
                            ...initialValues,
                            defendant: event.target.value,
                          },
                        });
                      }}
                    />
                    <InputField
                      //value={initialValues.defendant_street}
                      name="defendant_street"
                      placeholder="المقر"
                      type="text"
                      // hasError={hasError}
                      validInput="text"
                      onChange={(event) => {
                        this.setState({
                          initialValues: {
                            ...initialValues,
                            defendant_street: event.target.value,
                          },
                        });
                      }}
                    />
                    <InputField
                      //value={initialValues.defendant_post}
                      name="defendant_post"
                      placeholder="المهنة"
                      type="text"
                      //hasError={hasError}
                      validInput="text"
                      onChange={(event) => {
                        this.setState({
                          initialValues: {
                            ...initialValues,
                            defendant_post: event.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                  <div>
                    <label htmlFor="court">المحكمة</label>
                    <Autocomplete
                      debug
                      id="debug"
                      options={courts}
                      getOptionLabel={(option) => option.title}
                      style={{ marginBottom: '4%' }}
                      onChange={(event, option) => {
                        this.setState({
                          initialValues: {
                            ...initialValues,
                            court: option != null ? option.title : '',
                          },
                        });
                      }}
                      defaultValue={
                        {
                          //title: initialValues.court,
                        }
                      }
                      renderInput={(params) => (
                        <TextField
                          //error={!initialValues.court && hasError}
                          name="court"
                          style={{ border: 0 }}
                          {...params}
                          //label="المحكمة"
                          //variant="outlined"
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="lawsuit_form__btn_grp">
                  <button
                    onClick={() => this.props.goBack()}
                    type="button"
                    className="lawsuit_cancel_session"
                  >
                    <svg
                      className="lawsuit_ltSpan"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      xlinkHref="http://svgjs.com/svgjs"
                      version="1.1"
                      width="12"
                      height="12"
                      x="0"
                      y="0"
                      viewBox="0 0 492.004 492.004"
                      style={{ enableBackground: 'new 0 0 512 512' }}
                      xmlSpace="preserve"
                    >
                      <g>
                        <g xmlns="http://www.w3.org/2000/svg">
                          <g>
                            <path
                              d="M382.678,226.804L163.73,7.86C158.666,2.792,151.906,0,144.698,0s-13.968,2.792-19.032,7.86l-16.124,16.12    c-10.492,10.504-10.492,27.576,0,38.064L293.398,245.9l-184.06,184.06c-5.064,5.068-7.86,11.824-7.86,19.028    c0,7.212,2.796,13.968,7.86,19.04l16.124,16.116c5.068,5.068,11.824,7.86,19.032,7.86s13.968-2.792,19.032-7.86L382.678,265    c5.076-5.084,7.864-11.872,7.848-19.088C390.542,238.668,387.754,231.884,382.678,226.804z"
                              fill="#2d2d2d"
                              data-original="#000000"
                              className=""
                            />
                          </g>
                        </g>
                        <g xmlns="http://www.w3.org/2000/svg"></g>
                        <g xmlns="http://www.w3.org/2000/svg"></g>
                        <g xmlns="http://www.w3.org/2000/svg"></g>
                        <g xmlns="http://www.w3.org/2000/svg"></g>
                        <g xmlns="http://www.w3.org/2000/svg"></g>
                        <g xmlns="http://www.w3.org/2000/svg"></g>
                        <g xmlns="http://www.w3.org/2000/svg"></g>
                        <g xmlns="http://www.w3.org/2000/svg"></g>
                        <g xmlns="http://www.w3.org/2000/svg"></g>
                        <g xmlns="http://www.w3.org/2000/svg"></g>
                        <g xmlns="http://www.w3.org/2000/svg"></g>
                        <g xmlns="http://www.w3.org/2000/svg"></g>
                        <g xmlns="http://www.w3.org/2000/svg"></g>
                        <g xmlns="http://www.w3.org/2000/svg"></g>
                        <g xmlns="http://www.w3.org/2000/svg"></g>
                      </g>
                    </svg>
                    الغاء
                  </button>
                  <button onClick={() => this.submitForm(initialValues)} type="button">
                    <svg
                      className="tickSpan"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20.125"
                      height="17.774"
                      viewBox="0 0 29.125 23.774"
                    >
                      <path
                        id="Path_92"
                        data-name="Path 92"
                        d="M1325.354,167.5l8.34,9.625,17.8-19.417"
                        transform="translate(-1323.842 -156.357)"
                        fill="none"
                        stroke="#f9f9f9"
                        strokeWidth="4"
                      />
                    </svg>
                    تسجيل
                  </button>
                </div>
                <div className={openDeletePopupSession ? 'popup active' : 'popup'}>
                  <div className="popup__title">أنت بصدد حذف الجلسة</div>
                  <div className="popup__btn_grp">
                    <button
                      type="button"
                      onClick={() => this.handleActiveDeletePopupSession()}
                      className="cancel_popup"
                    >
                      <span>الغاء</span>
                      <span>&lt;</span>
                    </button>

                    <button
                      disabled={isLoadingAddLawsuit}
                      type="button"
                      onClick={() => {
                        this.props.destroySession(selected_session_id, initialValues.id);
                      }}
                    >
                      {isLoadingAddLawsuit ? (
                        <CircularProgress
                          style={{
                            textAlign: 'center',
                            width: '30px',
                            height: '30px',
                            color: 'white',
                          }}
                        />
                      ) : (
                        <span>تأكيد</span>
                      )}
                      <span className="trashSpan">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20.906"
                          height="25.74"
                          viewBox="0 0 20.906 25.74"
                        >
                          <g id="delete" transform="translate(0.003 0.002)">
                            <path
                              id="Path_82"
                              data-name="Path 82"
                              d="M223,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,223,154.7Zm0,0"
                              transform="translate(-208.995 -145.379)"
                              fill="#fff"
                            />
                            <path
                              id="Path_83"
                              data-name="Path 83"
                              d="M105,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,105,154.7Zm0,0"
                              transform="translate(-98.108 -145.379)"
                              fill="#fff"
                            />
                            <path
                              id="Path_84"
                              data-name="Path 84"
                              d="M1.709,7.662V22.514a3.326,3.326,0,0,0,.884,2.294,2.969,2.969,0,0,0,2.154.931H16.152a2.968,2.968,0,0,0,2.154-.931,3.326,3.326,0,0,0,.884-2.294V7.662A2.3,2.3,0,0,0,18.6,3.133H15.513V2.38A2.368,2.368,0,0,0,13.126,0H7.773A2.368,2.368,0,0,0,5.386,2.38v.754H2.3a2.3,2.3,0,0,0-.591,4.528ZM16.152,24.533H4.747a1.909,1.909,0,0,1-1.832-2.019V7.715h15.07v14.8a1.909,1.909,0,0,1-1.832,2.019ZM6.592,2.38A1.161,1.161,0,0,1,7.773,1.2h5.353A1.161,1.161,0,0,1,14.308,2.38v.754H6.592ZM2.3,4.339H18.6a1.085,1.085,0,0,1,0,2.17H2.3a1.085,1.085,0,0,1,0-2.17Zm0,0"
                              transform="translate(0 0)"
                              fill="#fff"
                            />
                            <path
                              id="Path_85"
                              data-name="Path 85"
                              d="M164,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,164,154.7Zm0,0"
                              transform="translate(-153.551 -145.379)"
                              fill="#fff"
                            />
                          </g>
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
                <div className={openDeletePopup ? 'popup active' : 'popup'}>
                  <div className="popup__title">أنت بصدد حذف الملف</div>
                  <div className="popup__btn_grp">
                    <button
                      type="button"
                      onClick={() => this.handleActivePopupDeleteItem()}
                      className="cancel_popup"
                    >
                      <span>الغاء</span>
                      <span>&lt;</span>
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        this.props.deleteFiles(initialValues.id, selected_id);
                      }}
                    >
                      <span>تأكيد</span>
                      <span className="trashSpan">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20.906"
                          height="25.74"
                          viewBox="0 0 20.906 25.74"
                        >
                          <g id="delete" transform="translate(0.003 0.002)">
                            <path
                              id="Path_82"
                              data-name="Path 82"
                              d="M223,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,223,154.7Zm0,0"
                              transform="translate(-208.995 -145.379)"
                              fill="#fff"
                            />
                            <path
                              id="Path_83"
                              data-name="Path 83"
                              d="M105,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,105,154.7Zm0,0"
                              transform="translate(-98.108 -145.379)"
                              fill="#fff"
                            />
                            <path
                              id="Path_84"
                              data-name="Path 84"
                              d="M1.709,7.662V22.514a3.326,3.326,0,0,0,.884,2.294,2.969,2.969,0,0,0,2.154.931H16.152a2.968,2.968,0,0,0,2.154-.931,3.326,3.326,0,0,0,.884-2.294V7.662A2.3,2.3,0,0,0,18.6,3.133H15.513V2.38A2.368,2.368,0,0,0,13.126,0H7.773A2.368,2.368,0,0,0,5.386,2.38v.754H2.3a2.3,2.3,0,0,0-.591,4.528ZM16.152,24.533H4.747a1.909,1.909,0,0,1-1.832-2.019V7.715h15.07v14.8a1.909,1.909,0,0,1-1.832,2.019ZM6.592,2.38A1.161,1.161,0,0,1,7.773,1.2h5.353A1.161,1.161,0,0,1,14.308,2.38v.754H6.592ZM2.3,4.339H18.6a1.085,1.085,0,0,1,0,2.17H2.3a1.085,1.085,0,0,1,0-2.17Zm0,0"
                              transform="translate(0 0)"
                              fill="#fff"
                            />
                            <path
                              id="Path_85"
                              data-name="Path 85"
                              d="M164,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,164,154.7Zm0,0"
                              transform="translate(-153.551 -145.379)"
                              fill="#fff"
                            />
                          </g>
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
                <div className={openAddPopup ? 'popup active' : 'popup'}>
                  <div className="popup__title">أنت بصدد اضافة مآل</div>
                  <div className="popup__item">
                    <div className="popup__text">
                      <div
                        style={{ display: 'block', flexDirection: 'row' }}
                        className="lawsuit_form__sections"
                      >
                        <div className="rv_form__sections-left">
                          <div
                            style={{
                              alignItems: 'center',
                            }}
                            className="jalaset_form__sections-left"
                          >
                            <div
                              style={{
                                width: '9rem',
                                marginLeft: 0,
                              }}
                              className="jalaset_form__label"
                              htmlFor="date_selected"
                            >
                              تاريخ الجلسة
                            </div>
                            <div className="jalaset_form__cal">
                              <DatePicker
                                selected={new Date(initialValues.date_session)}
                                locale="ar"
                                onChange={(date) => {
                                  this.setState({
                                    initialValues: {
                                      ...initialValues,
                                      date_session: date,
                                    },
                                  });
                                }}
                                customInput={
                                  <svg
                                    style={{
                                      cursor: 'pointer',
                                    }}
                                    id="calendar"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    viewBox="0 0 30 30"
                                  >
                                    <g id="Group_28" data-name="Group 28">
                                      <g id="Group_27" data-name="Group 27">
                                        <ellipse
                                          id="Ellipse_20"
                                          data-name="Ellipse 20"
                                          cx="1"
                                          cy="1.5"
                                          rx="1"
                                          ry="1.5"
                                          transform="translate(22 11)"
                                          fill="#ca2946"
                                        />
                                        <path
                                          id="Path_89"
                                          data-name="Path 89"
                                          d="M25.312,2.344H23.789V1.172a1.172,1.172,0,0,0-2.344,0V2.344H16.113V1.172a1.172,1.172,0,0,0-2.344,0V2.344H8.5V1.172a1.172,1.172,0,1,0-2.344,0V2.344H4.688A4.693,4.693,0,0,0,0,7.031V25.312A4.693,4.693,0,0,0,4.688,30h8.965a1.172,1.172,0,0,0,0-2.344H4.688a2.346,2.346,0,0,1-2.344-2.344V7.031A2.346,2.346,0,0,1,4.688,4.688H6.152V5.859a1.172,1.172,0,0,0,2.344,0V4.688H13.77V5.859a1.172,1.172,0,0,0,2.344,0V4.688h5.332V5.859a1.172,1.172,0,0,0,2.344,0V4.688h1.523a2.346,2.346,0,0,1,2.344,2.344v6.68a1.172,1.172,0,0,0,2.344,0V7.031A4.693,4.693,0,0,0,25.312,2.344Z"
                                          fill="#ca2946"
                                        />
                                        <path
                                          id="Path_90"
                                          data-name="Path 90"
                                          d="M277.09,270a7.09,7.09,0,1,0,7.09,7.09A7.1,7.1,0,0,0,277.09,270Zm0,11.836a4.746,4.746,0,1,1,4.746-4.746A4.751,4.751,0,0,1,277.09,281.836Z"
                                          transform="translate(-254.18 -254.18)"
                                          fill="#ca2946"
                                        />
                                        <path
                                          id="Path_91"
                                          data-name="Path 91"
                                          d="M373.871,332.4h-.527v-1.23a1.172,1.172,0,0,0-2.344,0v2.4a1.172,1.172,0,0,0,1.172,1.172h1.7a1.172,1.172,0,0,0,0-2.344Z"
                                          transform="translate(-349.262 -310.664)"
                                          fill="#ca2946"
                                        />
                                        <circle
                                          id="Ellipse_21"
                                          data-name="Ellipse 21"
                                          cx="1.5"
                                          cy="1.5"
                                          r="1.5"
                                          transform="translate(16 11)"
                                          fill="#ca2946"
                                        />
                                        <circle
                                          id="Ellipse_22"
                                          data-name="Ellipse 22"
                                          cx="1.5"
                                          cy="1.5"
                                          r="1.5"
                                          transform="translate(11 16)"
                                          fill="#ca2946"
                                        />
                                        <ellipse
                                          id="Ellipse_23"
                                          data-name="Ellipse 23"
                                          cx="1"
                                          cy="1.5"
                                          rx="1"
                                          ry="1.5"
                                          transform="translate(6 11)"
                                          fill="#ca2946"
                                        />
                                        <ellipse
                                          id="Ellipse_24"
                                          data-name="Ellipse 24"
                                          cx="1"
                                          cy="1.5"
                                          rx="1"
                                          ry="1.5"
                                          transform="translate(6 16)"
                                          fill="#ca2946"
                                        />
                                        <circle
                                          id="Ellipse_25"
                                          data-name="Ellipse 25"
                                          cx="1"
                                          cy="1"
                                          r="1"
                                          transform="translate(6 22)"
                                          fill="#ca2946"
                                        />
                                        <ellipse
                                          id="Ellipse_26"
                                          data-name="Ellipse 26"
                                          cx="1.5"
                                          cy="1"
                                          rx="1.5"
                                          ry="1"
                                          transform="translate(11 22)"
                                          fill="#ca2946"
                                        />
                                        <circle
                                          id="Ellipse_27"
                                          data-name="Ellipse 27"
                                          cx="1.5"
                                          cy="1.5"
                                          r="1.5"
                                          transform="translate(11 11)"
                                          fill="#ca2946"
                                        />
                                      </g>
                                    </g>
                                  </svg>
                                }
                              />
                            </div>

                            <div className="jalaset_form__date_selected">
                              <div className="jalaset_form__date_selected__day">
                                {
                                  DaysArabic[
                                    moment(initialValues.date_session).format('dddd')
                                  ]
                                }
                              </div>
                              <div className="jalaset_form__date_selected__day">
                                {moment(initialValues.date_session).format('DD/MM/YYYY')}
                              </div>
                            </div>
                          </div>
                          <div className="rv_form__sections-left_field">
                            <div className="rv_form__label" htmlFor="date_selected">
                              توقيت الجلسة
                            </div>
                            <div className="rv_form__t">
                              <DatePicker
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                dateFormat="HH:mm"
                                selected={new Date(initialValues.date_session)}
                                locale="ar"
                                minTime="08"
                                maxTime={18}
                                onChange={(date) => {
                                  this.setState({
                                    initialValues: {
                                      ...initialValues,
                                      date_session: date,
                                    },
                                  });
                                }}
                                customInput={
                                  <svg
                                    className="rv_time_icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24.538"
                                    height="24.538"
                                    viewBox="0 0 24.538 24.538"
                                  >
                                    <g
                                      id="clock_1_"
                                      data-name="clock(1)"
                                      transform="translate(0.5 0.5)"
                                    >
                                      <path
                                        id="Path_103"
                                        data-name="Path 103"
                                        d="M11.769,0A11.769,11.769,0,1,0,23.538,11.769,11.782,11.782,0,0,0,11.769,0Zm0,22.067a10.3,10.3,0,1,1,10.3-10.3,10.309,10.309,0,0,1-10.3,10.3Z"
                                        fill="#a8192b"
                                        stroke="#a8192b"
                                        strokeWidth="1"
                                      />
                                      <path
                                        id="Path_104"
                                        data-name="Path 104"
                                        d="M209.265,83.118h-1.471v7.66l4.629,4.629,1.04-1.04-4.2-4.2Z"
                                        transform="translate(-196.761 -78.705)"
                                        fill="#a8192b"
                                        stroke="#a8192b"
                                        strokeWidth="1"
                                      />
                                    </g>
                                  </svg>
                                }
                              />
                            </div>
                            <div className="rv_form__time_selected">
                              {moment(initialValues.date_session).format('HH:mm')}س -
                              {moment(initialValues.date_session)
                                .add(30, 'minutes')
                                .format('HH:mm')}
                              س
                            </div>
                          </div>
                        </div>
                        <div>
                          {hasErrorDateSession &&
                          initialValues.date_session &&
                          moment().isAfter(moment(initialValues.date_session)) ? (
                            <div className="rv_form__sections-left_field">
                              <div>
                                <svg
                                  className="rv_danger_icon"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="27.077"
                                  height="24.294"
                                  viewBox="0 0 27.077 24.294"
                                >
                                  <g id="alert" transform="translate(0 -26.315)">
                                    <g
                                      id="Group_39"
                                      data-name="Group 39"
                                      transform="translate(0 26.315)"
                                    >
                                      <g
                                        id="Group_38"
                                        data-name="Group 38"
                                        transform="translate(0 0)"
                                      >
                                        <path
                                          id="Path_105"
                                          data-name="Path 105"
                                          d="M26.645,45.833,16.3,27.907a3.184,3.184,0,0,0-5.515,0L.431,45.833a3.184,3.184,0,0,0,2.757,4.776h20.7a3.184,3.184,0,0,0,2.757-4.776Zm-1.374,2.391a1.581,1.581,0,0,1-1.384.8H3.189a1.6,1.6,0,0,1-1.384-2.4L12.155,28.7a1.6,1.6,0,0,1,2.767,0l10.35,17.926A1.582,1.582,0,0,1,25.272,48.224Z"
                                          transform="translate(0 -26.315)"
                                          fill="#ff8000"
                                        />
                                      </g>
                                    </g>
                                    <g
                                      id="Group_41"
                                      data-name="Group 41"
                                      transform="translate(12.745 34.232)"
                                    >
                                      <g
                                        id="Group_40"
                                        data-name="Group 40"
                                        transform="translate(0 0)"
                                      >
                                        <rect
                                          id="Rectangle_145"
                                          data-name="Rectangle 145"
                                          width="1.586"
                                          height="7.932"
                                          fill="#ff8000"
                                        />
                                      </g>
                                    </g>
                                    <g
                                      id="Group_43"
                                      data-name="Group 43"
                                      transform="translate(12.481 43.75)"
                                    >
                                      <g id="Group_42" data-name="Group 42">
                                        <path
                                          id="Path_106"
                                          data-name="Path 106"
                                          d="M237.06,355.99a1.058,1.058,0,1,0,1.058,1.058A1.059,1.059,0,0,0,237.06,355.99Z"
                                          transform="translate(-236.002 -355.99)"
                                          fill="#ff8000"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </svg>
                              </div>
                              <div className="rv_err_msg">
                                الرجاء تحديد تاريخ في المستقبل
                              </div>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                        <div style={{ marginBottom: '4%', marginTop: '3%' }}>
                          <label htmlFor="court">المحكمة</label>
                          <Autocomplete
                            debug
                            id="debug"
                            options={courts}
                            getOptionLabel={(option) => option.title}
                            style={{ marginBottom: '4%' }}
                            onChange={(event, option) => {
                              this.setState({
                                initialValues: {
                                  ...initialValues,
                                  court_session: option != null ? option.title : '',
                                },
                              });
                            }}
                            defaultValue={{
                              title: initialValues.court_session,
                            }}
                            renderInput={(params) => (
                              <TextField
                                error={!initialValues.court_session && hasError}
                                name="court_session"
                                style={{ border: 0 }}
                                {...params}
                                //label="المحكمة"
                                //variant="outlined"
                              />
                            )}
                          />
                        </div>
                        <div>
                          <label htmlFor="lawsuit_events">وصف </label>
                          <textarea
                            onChange={(event) => {
                              this.setState({
                                initialValues: {
                                  ...initialValues,
                                  description: event.target.value,
                                },
                              });
                            }}
                            value={initialValues.description}
                            className={
                              hasError && !initialValues.description ? 'input-error' : ''
                            }
                            name="facts"
                            placeholder="وصف"
                            id="lawsuit_events"
                          >
                            {initialValues.description}
                          </textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="popup__btn_grp">
                    <button
                      type="button"
                      onClick={() => this.handleActiveAddPopup()}
                      className="cancel_popup"
                    >
                      <span>الغاء</span>
                      <span>&lt;</span>
                    </button>
                    <button
                      style={{
                        background: '#63B17D',
                      }}
                      type="button"
                      onClick={() => {
                        this.submitFormSession(initialValues);
                      }}
                    >
                      <span>تأكيد</span>
                      <span className="trashSpan">
                        <svg
                          className="tickSpan"
                          xmlns="http://www.w3.org/2000/svg"
                          width="20.125"
                          height="17.774"
                          viewBox="0 0 29.125 23.774"
                        >
                          <path
                            id="Path_92"
                            data-name="Path 92"
                            d="M1325.354,167.5l8.34,9.625,17.8-19.417"
                            transform="translate(-1323.842 -156.357)"
                            fill="none"
                            stroke="#f9f9f9"
                            strokeWidth="4"
                          />
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
                <div
                  id="overlay"
                  className={
                    openDeletePopup || openAddPopup || openDeletePopupSession
                      ? 'active'
                      : ''
                  }
                ></div>
              </form>*/}
              <h3>يتم معالجتها</h3>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <h3>يتم معالجتها</h3>
              {/*<form action="">
                <div className="lawsuit_form__sections">
                  <div>
                    <label htmlFor="attr">عدد القضية</label>
                    <InputField
                      // value={initialValues.number}
                      name="number"
                      placeholder="عدد القضية"
                      type="text"
                      //hasError={hasError}
                      validInput="integer"
                      onChange={(event) => {
                        this.setState({
                          initialValues: {
                            ...initialValues,
                            number: event.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                  <div>
                    <label>المدعى عليه</label>
                    <InputField
                      //value={initialValues.defendant}
                      name="defendant"
                      placeholder=" الاسم الثلاثي"
                      type="text"
                      //hasError={hasError}
                      validInput="text"
                      onChange={(event) => {
                        this.setState({
                          initialValues: {
                            ...initialValues,
                            defendant: event.target.value,
                          },
                        });
                      }}
                    />
                    <InputField
                      //value={initialValues.defendant_street}
                      name="defendant_street"
                      placeholder="المقر"
                      type="text"
                      // hasError={hasError}
                      validInput="text"
                      onChange={(event) => {
                        this.setState({
                          initialValues: {
                            ...initialValues,
                            defendant_street: event.target.value,
                          },
                        });
                      }}
                    />
                    <InputField
                      //value={initialValues.defendant_post}
                      name="defendant_post"
                      placeholder="المهنة"
                      type="text"
                      //hasError={hasError}
                      validInput="text"
                      onChange={(event) => {
                        this.setState({
                          initialValues: {
                            ...initialValues,
                            defendant_post: event.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                  <div>
                    <label htmlFor="court">المحكمة</label>
                    <Autocomplete
                      debug
                      id="debug"
                      options={courts}
                      getOptionLabel={(option) => option.title}
                      style={{ marginBottom: '4%' }}
                      onChange={(event, option) => {
                        this.setState({
                          initialValues: {
                            ...initialValues,
                            court: option != null ? option.title : '',
                          },
                        });
                      }}
                      defaultValue={
                        {
                          //title: initialValues.court,
                        }
                      }
                      renderInput={(params) => (
                        <TextField
                          //error={!initialValues.court && hasError}
                          name="court"
                          style={{ border: 0 }}
                          {...params}
                          //label="المحكمة"
                          //variant="outlined"
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="lawsuit_form__btn_grp">
                  <button
                    onClick={() => this.props.goBack()}
                    type="button"
                    className="lawsuit_cancel_session"
                  >
                    <svg
                      className="lawsuit_ltSpan"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      xlinkHref="http://svgjs.com/svgjs"
                      version="1.1"
                      width="12"
                      height="12"
                      x="0"
                      y="0"
                      viewBox="0 0 492.004 492.004"
                      style={{ enableBackground: 'new 0 0 512 512' }}
                      xmlSpace="preserve"
                    >
                      <g>
                        <g xmlns="http://www.w3.org/2000/svg">
                          <g>
                            <path
                              d="M382.678,226.804L163.73,7.86C158.666,2.792,151.906,0,144.698,0s-13.968,2.792-19.032,7.86l-16.124,16.12    c-10.492,10.504-10.492,27.576,0,38.064L293.398,245.9l-184.06,184.06c-5.064,5.068-7.86,11.824-7.86,19.028    c0,7.212,2.796,13.968,7.86,19.04l16.124,16.116c5.068,5.068,11.824,7.86,19.032,7.86s13.968-2.792,19.032-7.86L382.678,265    c5.076-5.084,7.864-11.872,7.848-19.088C390.542,238.668,387.754,231.884,382.678,226.804z"
                              fill="#2d2d2d"
                              data-original="#000000"
                              className=""
                            />
                          </g>
                        </g>
                        <g xmlns="http://www.w3.org/2000/svg"></g>
                        <g xmlns="http://www.w3.org/2000/svg"></g>
                        <g xmlns="http://www.w3.org/2000/svg"></g>
                        <g xmlns="http://www.w3.org/2000/svg"></g>
                        <g xmlns="http://www.w3.org/2000/svg"></g>
                        <g xmlns="http://www.w3.org/2000/svg"></g>
                        <g xmlns="http://www.w3.org/2000/svg"></g>
                        <g xmlns="http://www.w3.org/2000/svg"></g>
                        <g xmlns="http://www.w3.org/2000/svg"></g>
                        <g xmlns="http://www.w3.org/2000/svg"></g>
                        <g xmlns="http://www.w3.org/2000/svg"></g>
                        <g xmlns="http://www.w3.org/2000/svg"></g>
                        <g xmlns="http://www.w3.org/2000/svg"></g>
                        <g xmlns="http://www.w3.org/2000/svg"></g>
                        <g xmlns="http://www.w3.org/2000/svg"></g>
                      </g>
                    </svg>
                    الغاء
                  </button>
                  <button onClick={() => this.submitForm(initialValues)} type="button">
                    <svg
                      className="tickSpan"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20.125"
                      height="17.774"
                      viewBox="0 0 29.125 23.774"
                    >
                      <path
                        id="Path_92"
                        data-name="Path 92"
                        d="M1325.354,167.5l8.34,9.625,17.8-19.417"
                        transform="translate(-1323.842 -156.357)"
                        fill="none"
                        stroke="#f9f9f9"
                        strokeWidth="4"
                      />
                    </svg>
                    تسجيل
                  </button>
                </div>
                <div className={openDeletePopupSession ? 'popup active' : 'popup'}>
                  <div className="popup__title">أنت بصدد حذف الجلسة</div>
                  <div className="popup__btn_grp">
                    <button
                      type="button"
                      onClick={() => this.handleActiveDeletePopupSession()}
                      className="cancel_popup"
                    >
                      <span>الغاء</span>
                      <span>&lt;</span>
                    </button>

                    <button
                      disabled={isLoadingAddLawsuit}
                      type="button"
                      onClick={() => {
                        this.props.destroySession(selected_session_id, initialValues.id);
                      }}
                    >
                      {isLoadingAddLawsuit ? (
                        <CircularProgress
                          style={{
                            textAlign: 'center',
                            width: '30px',
                            height: '30px',
                            color: 'white',
                          }}
                        />
                      ) : (
                        <span>تأكيد</span>
                      )}
                      <span className="trashSpan">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20.906"
                          height="25.74"
                          viewBox="0 0 20.906 25.74"
                        >
                          <g id="delete" transform="translate(0.003 0.002)">
                            <path
                              id="Path_82"
                              data-name="Path 82"
                              d="M223,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,223,154.7Zm0,0"
                              transform="translate(-208.995 -145.379)"
                              fill="#fff"
                            />
                            <path
                              id="Path_83"
                              data-name="Path 83"
                              d="M105,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,105,154.7Zm0,0"
                              transform="translate(-98.108 -145.379)"
                              fill="#fff"
                            />
                            <path
                              id="Path_84"
                              data-name="Path 84"
                              d="M1.709,7.662V22.514a3.326,3.326,0,0,0,.884,2.294,2.969,2.969,0,0,0,2.154.931H16.152a2.968,2.968,0,0,0,2.154-.931,3.326,3.326,0,0,0,.884-2.294V7.662A2.3,2.3,0,0,0,18.6,3.133H15.513V2.38A2.368,2.368,0,0,0,13.126,0H7.773A2.368,2.368,0,0,0,5.386,2.38v.754H2.3a2.3,2.3,0,0,0-.591,4.528ZM16.152,24.533H4.747a1.909,1.909,0,0,1-1.832-2.019V7.715h15.07v14.8a1.909,1.909,0,0,1-1.832,2.019ZM6.592,2.38A1.161,1.161,0,0,1,7.773,1.2h5.353A1.161,1.161,0,0,1,14.308,2.38v.754H6.592ZM2.3,4.339H18.6a1.085,1.085,0,0,1,0,2.17H2.3a1.085,1.085,0,0,1,0-2.17Zm0,0"
                              transform="translate(0 0)"
                              fill="#fff"
                            />
                            <path
                              id="Path_85"
                              data-name="Path 85"
                              d="M164,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,164,154.7Zm0,0"
                              transform="translate(-153.551 -145.379)"
                              fill="#fff"
                            />
                          </g>
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
                <div className={openDeletePopup ? 'popup active' : 'popup'}>
                  <div className="popup__title">أنت بصدد حذف الملف</div>
                  <div className="popup__btn_grp">
                    <button
                      type="button"
                      onClick={() => this.handleActivePopupDeleteItem()}
                      className="cancel_popup"
                    >
                      <span>الغاء</span>
                      <span>&lt;</span>
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        this.props.deleteFiles(initialValues.id, selected_id);
                      }}
                    >
                      <span>تأكيد</span>
                      <span className="trashSpan">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20.906"
                          height="25.74"
                          viewBox="0 0 20.906 25.74"
                        >
                          <g id="delete" transform="translate(0.003 0.002)">
                            <path
                              id="Path_82"
                              data-name="Path 82"
                              d="M223,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,223,154.7Zm0,0"
                              transform="translate(-208.995 -145.379)"
                              fill="#fff"
                            />
                            <path
                              id="Path_83"
                              data-name="Path 83"
                              d="M105,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,105,154.7Zm0,0"
                              transform="translate(-98.108 -145.379)"
                              fill="#fff"
                            />
                            <path
                              id="Path_84"
                              data-name="Path 84"
                              d="M1.709,7.662V22.514a3.326,3.326,0,0,0,.884,2.294,2.969,2.969,0,0,0,2.154.931H16.152a2.968,2.968,0,0,0,2.154-.931,3.326,3.326,0,0,0,.884-2.294V7.662A2.3,2.3,0,0,0,18.6,3.133H15.513V2.38A2.368,2.368,0,0,0,13.126,0H7.773A2.368,2.368,0,0,0,5.386,2.38v.754H2.3a2.3,2.3,0,0,0-.591,4.528ZM16.152,24.533H4.747a1.909,1.909,0,0,1-1.832-2.019V7.715h15.07v14.8a1.909,1.909,0,0,1-1.832,2.019ZM6.592,2.38A1.161,1.161,0,0,1,7.773,1.2h5.353A1.161,1.161,0,0,1,14.308,2.38v.754H6.592ZM2.3,4.339H18.6a1.085,1.085,0,0,1,0,2.17H2.3a1.085,1.085,0,0,1,0-2.17Zm0,0"
                              transform="translate(0 0)"
                              fill="#fff"
                            />
                            <path
                              id="Path_85"
                              data-name="Path 85"
                              d="M164,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,164,154.7Zm0,0"
                              transform="translate(-153.551 -145.379)"
                              fill="#fff"
                            />
                          </g>
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
                <div className={openAddPopup ? 'popup active' : 'popup'}>
                  <div className="popup__title">أنت بصدد اضافة مآل</div>
                  <div className="popup__item">
                    <div className="popup__text">
                      <div
                        style={{ display: 'block', flexDirection: 'row' }}
                        className="lawsuit_form__sections"
                      >
                        <div className="rv_form__sections-left">
                          <div
                            style={{
                              alignItems: 'center',
                            }}
                            className="jalaset_form__sections-left"
                          >
                            <div
                              style={{
                                width: '9rem',
                                marginLeft: 0,
                              }}
                              className="jalaset_form__label"
                              htmlFor="date_selected"
                            >
                              تاريخ الجلسة
                            </div>
                            <div className="jalaset_form__cal">
                              <DatePicker
                                selected={new Date(initialValues.date_session)}
                                locale="ar"
                                onChange={(date) => {
                                  this.setState({
                                    initialValues: {
                                      ...initialValues,
                                      date_session: date,
                                    },
                                  });
                                }}
                                customInput={
                                  <svg
                                    style={{
                                      cursor: 'pointer',
                                    }}
                                    id="calendar"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    viewBox="0 0 30 30"
                                  >
                                    <g id="Group_28" data-name="Group 28">
                                      <g id="Group_27" data-name="Group 27">
                                        <ellipse
                                          id="Ellipse_20"
                                          data-name="Ellipse 20"
                                          cx="1"
                                          cy="1.5"
                                          rx="1"
                                          ry="1.5"
                                          transform="translate(22 11)"
                                          fill="#ca2946"
                                        />
                                        <path
                                          id="Path_89"
                                          data-name="Path 89"
                                          d="M25.312,2.344H23.789V1.172a1.172,1.172,0,0,0-2.344,0V2.344H16.113V1.172a1.172,1.172,0,0,0-2.344,0V2.344H8.5V1.172a1.172,1.172,0,1,0-2.344,0V2.344H4.688A4.693,4.693,0,0,0,0,7.031V25.312A4.693,4.693,0,0,0,4.688,30h8.965a1.172,1.172,0,0,0,0-2.344H4.688a2.346,2.346,0,0,1-2.344-2.344V7.031A2.346,2.346,0,0,1,4.688,4.688H6.152V5.859a1.172,1.172,0,0,0,2.344,0V4.688H13.77V5.859a1.172,1.172,0,0,0,2.344,0V4.688h5.332V5.859a1.172,1.172,0,0,0,2.344,0V4.688h1.523a2.346,2.346,0,0,1,2.344,2.344v6.68a1.172,1.172,0,0,0,2.344,0V7.031A4.693,4.693,0,0,0,25.312,2.344Z"
                                          fill="#ca2946"
                                        />
                                        <path
                                          id="Path_90"
                                          data-name="Path 90"
                                          d="M277.09,270a7.09,7.09,0,1,0,7.09,7.09A7.1,7.1,0,0,0,277.09,270Zm0,11.836a4.746,4.746,0,1,1,4.746-4.746A4.751,4.751,0,0,1,277.09,281.836Z"
                                          transform="translate(-254.18 -254.18)"
                                          fill="#ca2946"
                                        />
                                        <path
                                          id="Path_91"
                                          data-name="Path 91"
                                          d="M373.871,332.4h-.527v-1.23a1.172,1.172,0,0,0-2.344,0v2.4a1.172,1.172,0,0,0,1.172,1.172h1.7a1.172,1.172,0,0,0,0-2.344Z"
                                          transform="translate(-349.262 -310.664)"
                                          fill="#ca2946"
                                        />
                                        <circle
                                          id="Ellipse_21"
                                          data-name="Ellipse 21"
                                          cx="1.5"
                                          cy="1.5"
                                          r="1.5"
                                          transform="translate(16 11)"
                                          fill="#ca2946"
                                        />
                                        <circle
                                          id="Ellipse_22"
                                          data-name="Ellipse 22"
                                          cx="1.5"
                                          cy="1.5"
                                          r="1.5"
                                          transform="translate(11 16)"
                                          fill="#ca2946"
                                        />
                                        <ellipse
                                          id="Ellipse_23"
                                          data-name="Ellipse 23"
                                          cx="1"
                                          cy="1.5"
                                          rx="1"
                                          ry="1.5"
                                          transform="translate(6 11)"
                                          fill="#ca2946"
                                        />
                                        <ellipse
                                          id="Ellipse_24"
                                          data-name="Ellipse 24"
                                          cx="1"
                                          cy="1.5"
                                          rx="1"
                                          ry="1.5"
                                          transform="translate(6 16)"
                                          fill="#ca2946"
                                        />
                                        <circle
                                          id="Ellipse_25"
                                          data-name="Ellipse 25"
                                          cx="1"
                                          cy="1"
                                          r="1"
                                          transform="translate(6 22)"
                                          fill="#ca2946"
                                        />
                                        <ellipse
                                          id="Ellipse_26"
                                          data-name="Ellipse 26"
                                          cx="1.5"
                                          cy="1"
                                          rx="1.5"
                                          ry="1"
                                          transform="translate(11 22)"
                                          fill="#ca2946"
                                        />
                                        <circle
                                          id="Ellipse_27"
                                          data-name="Ellipse 27"
                                          cx="1.5"
                                          cy="1.5"
                                          r="1.5"
                                          transform="translate(11 11)"
                                          fill="#ca2946"
                                        />
                                      </g>
                                    </g>
                                  </svg>
                                }
                              />
                            </div>

                            <div className="jalaset_form__date_selected">
                              <div className="jalaset_form__date_selected__day">
                                {
                                  DaysArabic[
                                    moment(initialValues.date_session).format('dddd')
                                  ]
                                }
                              </div>
                              <div className="jalaset_form__date_selected__day">
                                {moment(initialValues.date_session).format('DD/MM/YYYY')}
                              </div>
                            </div>
                          </div>
                          <div className="rv_form__sections-left_field">
                            <div className="rv_form__label" htmlFor="date_selected">
                              توقيت الجلسة
                            </div>
                            <div className="rv_form__t">
                              <DatePicker
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                dateFormat="HH:mm"
                                selected={new Date(initialValues.date_session)}
                                locale="ar"
                                minTime="08"
                                maxTime={18}
                                onChange={(date) => {
                                  this.setState({
                                    initialValues: {
                                      ...initialValues,
                                      date_session: date,
                                    },
                                  });
                                }}
                                customInput={
                                  <svg
                                    className="rv_time_icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24.538"
                                    height="24.538"
                                    viewBox="0 0 24.538 24.538"
                                  >
                                    <g
                                      id="clock_1_"
                                      data-name="clock(1)"
                                      transform="translate(0.5 0.5)"
                                    >
                                      <path
                                        id="Path_103"
                                        data-name="Path 103"
                                        d="M11.769,0A11.769,11.769,0,1,0,23.538,11.769,11.782,11.782,0,0,0,11.769,0Zm0,22.067a10.3,10.3,0,1,1,10.3-10.3,10.309,10.309,0,0,1-10.3,10.3Z"
                                        fill="#a8192b"
                                        stroke="#a8192b"
                                        strokeWidth="1"
                                      />
                                      <path
                                        id="Path_104"
                                        data-name="Path 104"
                                        d="M209.265,83.118h-1.471v7.66l4.629,4.629,1.04-1.04-4.2-4.2Z"
                                        transform="translate(-196.761 -78.705)"
                                        fill="#a8192b"
                                        stroke="#a8192b"
                                        strokeWidth="1"
                                      />
                                    </g>
                                  </svg>
                                }
                              />
                            </div>
                            <div className="rv_form__time_selected">
                              {moment(initialValues.date_session).format('HH:mm')}س -
                              {moment(initialValues.date_session)
                                .add(30, 'minutes')
                                .format('HH:mm')}
                              س
                            </div>
                          </div>
                        </div>
                        <div>
                          {hasErrorDateSession &&
                          initialValues.date_session &&
                          moment().isAfter(moment(initialValues.date_session)) ? (
                            <div className="rv_form__sections-left_field">
                              <div>
                                <svg
                                  className="rv_danger_icon"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="27.077"
                                  height="24.294"
                                  viewBox="0 0 27.077 24.294"
                                >
                                  <g id="alert" transform="translate(0 -26.315)">
                                    <g
                                      id="Group_39"
                                      data-name="Group 39"
                                      transform="translate(0 26.315)"
                                    >
                                      <g
                                        id="Group_38"
                                        data-name="Group 38"
                                        transform="translate(0 0)"
                                      >
                                        <path
                                          id="Path_105"
                                          data-name="Path 105"
                                          d="M26.645,45.833,16.3,27.907a3.184,3.184,0,0,0-5.515,0L.431,45.833a3.184,3.184,0,0,0,2.757,4.776h20.7a3.184,3.184,0,0,0,2.757-4.776Zm-1.374,2.391a1.581,1.581,0,0,1-1.384.8H3.189a1.6,1.6,0,0,1-1.384-2.4L12.155,28.7a1.6,1.6,0,0,1,2.767,0l10.35,17.926A1.582,1.582,0,0,1,25.272,48.224Z"
                                          transform="translate(0 -26.315)"
                                          fill="#ff8000"
                                        />
                                      </g>
                                    </g>
                                    <g
                                      id="Group_41"
                                      data-name="Group 41"
                                      transform="translate(12.745 34.232)"
                                    >
                                      <g
                                        id="Group_40"
                                        data-name="Group 40"
                                        transform="translate(0 0)"
                                      >
                                        <rect
                                          id="Rectangle_145"
                                          data-name="Rectangle 145"
                                          width="1.586"
                                          height="7.932"
                                          fill="#ff8000"
                                        />
                                      </g>
                                    </g>
                                    <g
                                      id="Group_43"
                                      data-name="Group 43"
                                      transform="translate(12.481 43.75)"
                                    >
                                      <g id="Group_42" data-name="Group 42">
                                        <path
                                          id="Path_106"
                                          data-name="Path 106"
                                          d="M237.06,355.99a1.058,1.058,0,1,0,1.058,1.058A1.059,1.059,0,0,0,237.06,355.99Z"
                                          transform="translate(-236.002 -355.99)"
                                          fill="#ff8000"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </svg>
                              </div>
                              <div className="rv_err_msg">
                                الرجاء تحديد تاريخ في المستقبل
                              </div>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                        <div style={{ marginBottom: '4%', marginTop: '3%' }}>
                          <label htmlFor="court">المحكمة</label>
                          <Autocomplete
                            debug
                            id="debug"
                            options={courts}
                            getOptionLabel={(option) => option.title}
                            style={{ marginBottom: '4%' }}
                            onChange={(event, option) => {
                              this.setState({
                                initialValues: {
                                  ...initialValues,
                                  court_session: option != null ? option.title : '',
                                },
                              });
                            }}
                            defaultValue={{
                              title: initialValues.court_session,
                            }}
                            renderInput={(params) => (
                              <TextField
                                error={!initialValues.court_session && hasError}
                                name="court_session"
                                style={{ border: 0 }}
                                {...params}
                                //label="المحكمة"
                                //variant="outlined"
                              />
                            )}
                          />
                        </div>
                        <div>
                          <label htmlFor="lawsuit_events">وصف </label>
                          <textarea
                            onChange={(event) => {
                              this.setState({
                                initialValues: {
                                  ...initialValues,
                                  description: event.target.value,
                                },
                              });
                            }}
                            value={initialValues.description}
                            className={
                              hasError && !initialValues.description ? 'input-error' : ''
                            }
                            name="facts"
                            placeholder="وصف"
                            id="lawsuit_events"
                          >
                            {initialValues.description}
                          </textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="popup__btn_grp">
                    <button
                      type="button"
                      onClick={() => this.handleActiveAddPopup()}
                      className="cancel_popup"
                    >
                      <span>الغاء</span>
                      <span>&lt;</span>
                    </button>
                    <button
                      style={{
                        background: '#63B17D',
                      }}
                      type="button"
                      onClick={() => {
                        this.submitFormSession(initialValues);
                      }}
                    >
                      <span>تأكيد</span>
                      <span className="trashSpan">
                        <svg
                          className="tickSpan"
                          xmlns="http://www.w3.org/2000/svg"
                          width="20.125"
                          height="17.774"
                          viewBox="0 0 29.125 23.774"
                        >
                          <path
                            id="Path_92"
                            data-name="Path 92"
                            d="M1325.354,167.5l8.34,9.625,17.8-19.417"
                            transform="translate(-1323.842 -156.357)"
                            fill="none"
                            stroke="#f9f9f9"
                            strokeWidth="4"
                          />
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
                <div
                  id="overlay"
                  className={
                    openDeletePopup || openAddPopup || openDeletePopupSession
                      ? 'active'
                      : ''
                  }
                ></div>
              </form>*/}
            </TabPanel>
          </div>
        </div>
      </div>
    );
  }
}
export default connect(null, mapDispatchToProps)(LawsuitRemotely);
