import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import routes from '../routes';
import { useEffect } from 'react';
import { isHasPermission } from '../utils/helpres';
import Tooltip from '@mui/material/Tooltip';

export default function SideBar({
  changeCountMessage,
  changeActiveItemSideBar,
  activeItemSideBar,
  pusher,
  account,
  countMessagesIsSeen,
  sandwich_menu_btn,
}) {
  useEffect(async () => {
    const channel = pusher.subscribe('conversations');
    channel.bind(`countMessageIsSeen-${account.id}`, (data) => {
      changeCountMessage(data.count);
    });
    channel.bind(`countMessageIsSeen-to-${account.id}`, (data) => {
      changeCountMessage(data.count);
    });
  }, []);
  return (
    <div
      className="side"
      id="sidebar"
      style={{ width: !sandwich_menu_btn ? '5%' : '12%' }}
    >
      <div style={{ display: 'flex' }} className="side__menu">
        <ul>
          {routes.map((route) => {
            let isPermission = isHasPermission(
              account.login_access.permissions,
              route.permission,
            );
            if (
              route.id !== 9 &&
              route.id !== 12 &&
              route.id !== 7 &&
              route.id !== 14 &&
              (isPermission || route.permission === 'الرئيسية')
            )
              return (
                <Tooltip key={route.id} placement="left-start" title={route.name}>
                  <li
                    onClick={() => {
                      changeActiveItemSideBar(route.id);
                    }}
                    key={route.id}
                  >
                    <Link
                      className={activeItemSideBar === route.id ? 'active_a' : ''}
                      to={route.path}
                    >
                      {sandwich_menu_btn ? route.name : ''}
                      {activeItemSideBar === route.id ? route.imgActive : route.img}
                    </Link>
                  </li>
                </Tooltip>
              );
            if (
              (route.id === 9 || route.id === 12 || route.id === 7 || route.id === 14) &&
              isPermission &&
              ((account.pack && account.pack !== 'beginner') ||
                (account.lawyer && account.lawyer.pack !== 'beginner'))
            )
              return (
                <Tooltip key={route.id} placement="left-start" title={route.name}>
                  <li
                    onClick={() => {
                      changeActiveItemSideBar(route.id);
                    }}
                    key={route.id}
                  >
                    <Link
                      className={activeItemSideBar === route.id ? 'active_a' : ''}
                      to={route.path}
                    >
                      {sandwich_menu_btn ? route.name : ''}
                      {route.id === 12 && countMessagesIsSeen > 0 ? (
                        <div className="msng_dot_div">
                          <div className="msng_dot_div__inner show_block"></div>
                          {activeItemSideBar === route.id ? route.imgActive : route.img}
                        </div>
                      ) : activeItemSideBar === route.id ? (
                        route.imgActive
                      ) : (
                        route.img
                      )}
                    </Link>
                  </li>
                </Tooltip>
              );
          })}
        </ul>{' '}
      </div>
    </div>
  );
}
