const initialBill = {
  addBill: null,
  isLoadingAddBill: false,
  bill: null,
  isLoadingBill: false,
  destroyBill: false,
  isLoadingDestroyBill: false,
};

const BillReducer = (state = initialBill, action) => {
  switch (action.type) {
    case 'GET_BILL_REQUESTING':
      return {
        ...state,
        isLoadingBill: true,
      };
    case 'GET_BILL_SUCCESS':
      return {
        ...state,
        isLoadingBill: false,
        bill: action.bill,
      };
    case 'GET_BILL_FAILURE':
      return {
        ...state,
        isLoadingBill: false,
        bill: false,
      };
    case 'ACTION_DELETE_BILL_REQUESTING':
      return {
        ...state,
        isLoadingDestroyBill: true,
      };
    case 'ACTION_DELETE_BILL_SUCCESS':
      return {
        ...state,
        isLoadingDestroyBill: false,
        destroyBill: true,
      };
    case 'ACTION_DELETE_BILL_FAILURE':
      return {
        ...state,
        isLoadingDestroyBill: false,
        destroyBill: false,
      };
    case 'UPDATE_BILL_REQUESTING':
      return {
        ...state,
        isLoadingAddBill: true,
      };
    case 'UPDATE_BILL_SUCCESS':
      return {
        ...state,
        isLoadingAddBill: false,
        addBill: action.addBill,
      };
    case 'UPDATE_BILL_FAILURE':
      return {
        ...state,
        isLoadingAddBill: false,
        addBill: null,
      };

    default:
      return state;
  }
};

export default BillReducer;
