import React, { Component } from 'react';
import { CircularProgress } from '@material-ui/core';
import { withRouter } from 'react-router';
import { Col, Row } from 'reactstrap';

class Statistic extends Component {
  constructor(props) {
    super(props);
    document.title = 'الإحصائيات';
    props.changeActiveItemSideBar(10, 'الإحصائيات');
    this.state = {
      openDeletePopup: false,
      showBackground: false,
      hasError: false,
      selected_id: null,
      selected_name: '',
      selected_type: '',

      counters: [
        {
          id: '1',
          extraclass: '',
          start: 25,
          end: 49,
          title: 'Employees',
          description:
            'Aliquam egestas vehicula sapien cibus sit amet rutrum dolor molestie.',
        },
        {
          id: '2',
          extraclass: 'pt-3',
          start: 25,
          end: 76,
          title: 'Projects',
          description: 'Pellentesque habitant morbi malesuada fames ac turpis egestas.',
        },
        {
          id: '3',
          extraclass: 'pt-3',
          start: 25,
          end: 99,
          title: 'CLIENTS',
          description:
            'Suspendisse congue risus consequat sapien potitor risus tincidunt.',
        },
      ],
    };
  }

  render() {
    const { isLoadingStatistic, statisticData, lawyerTraineeShip } = this.props;
    return (
      <div style={{ display: 'block' }} className="params_col-left">
        {isLoadingStatistic ? (
          <CircularProgress
            style={{
              textAlign: 'center',
              width: '30px',
              height: '30px',
              marginTop: '20%',
              marginRight: '50%',
            }}
          />
        ) : (
          <>
            <div style={{ display: 'block' }} className="params_table_section">
              <Row>
                <Col lg={5} className="">
                  <div
                    style={{
                      display: 'flex',
                      gap: '10px',
                      alignItems: 'baseline',
                    }}
                    className="counter-box"
                  >
                    <div className="media">
                      <div
                        style={{
                          gap: '10px',
                          width: '240px',
                          display: 'flex',
                          alignItems: 'center',
                          height: '120px',
                          backgroundColor: 'white',
                        }}
                        className="count-box bg-soft-primary text-center"
                      >
                        <svg
                          className="side_menu_svg"
                          xmlns="http://www.w3.org/2000/svg"
                          width="66.165"
                          height="46.477"
                          viewBox="0 0 46.165 46.477"
                          style={{ width: '4rem' }}
                        >
                          <g id="judgement" transform="translate(-1.252 0.5)">
                            <path
                              data-name="Path 79"
                              d="M46.252,7.27a.666.666,0,0,0-.666.666v6.152A3.192,3.192,0,0,1,42.4,17.276H31.2a.666.666,0,0,0-.573.327l-1.051,1.775L28.524,17.6a.666.666,0,0,0-.573-.327,8.472,8.472,0,0,1-2.371-.13v-.825A6.948,6.948,0,0,0,23.3,6.443V4.52a3.192,3.192,0,0,1,3.189-3.188H42.4A3.192,3.192,0,0,1,45.585,4.52a.666.666,0,0,0,1.332,0A4.526,4.526,0,0,0,42.4,0H26.486a4.526,4.526,0,0,0-4.521,4.52V5.845a6.976,6.976,0,0,0-8.009,10.476v1.343a5.681,5.681,0,0,0,.841,2.981,6.949,6.949,0,0,0-4.024,6.324v3.274H2.4a.666.666,0,0,0,0,1.332H4.252l1.134,6.242a2.027,2.027,0,0,0,2,1.665h1.3c0,1.753,0,3.549-.033,5.316a.666.666,0,0,0,1.332.022c.03-1.778.033-3.58.033-5.338h1.642a.666.666,0,0,0,0-1.332q-2.136,0-4.272,0A.7.7,0,0,1,6.7,37.58l-1.091-6H33.929l-1.091,6a.7.7,0,0,1-.685.571H15.2a.666.666,0,0,0,0,1.332H29.556c0,1.8,0,3.551,0,5.328a.666.666,0,0,0,1.332,0c0-1.777,0-3.526,0-5.326h1.265a2.027,2.027,0,0,0,2-1.666l1.134-6.242h1.854a.666.666,0,0,0,0-1.332H33.885V28.307a.666.666,0,0,0-.666-.666H32.043V26.194h5.094a.666.666,0,0,0,0-1.332H32.043V22.749a.666.666,0,0,0-.666-.666H27.949a.666.666,0,0,0-.662.589,6.957,6.957,0,0,0-2.549-2.026,5.681,5.681,0,0,0,.78-2.142,9.629,9.629,0,0,0,2.053.1L29,21.025a.672.672,0,0,0,1.147,0l1.43-2.417H42.4a4.526,4.526,0,0,0,4.521-4.52V7.936a.666.666,0,0,0-.666-.666ZM19.768,6.828a5.653,5.653,0,0,1,5.509,6.961,2.309,2.309,0,0,0-2-1.157H16.262a2.31,2.31,0,0,0-2,1.159,5.653,5.653,0,0,1,5.509-6.963Zm0,19.15-1.625-2.789a5.932,5.932,0,0,0,3.251,0Zm.013-3.9a4.448,4.448,0,0,1-4.493-4.418V14.919a.967.967,0,0,1,.973-.955h7.012a.966.966,0,0,1,.973.957v2.743a4.447,4.447,0,0,1-4.466,4.418ZM12.106,26.97a5.623,5.623,0,0,1,3.548-5.243s2.39,3.939,3.434,5.731v2.785H12.106Zm20.446,3.274H26.773v-1.27h5.779Zm-3.937-6.829h2.1v4.226h-2.1Zm-1.333,2.268v1.958H26.107a.666.666,0,0,0-.666.666v1.936h-5.02V27.5c1.052-1.805,3.46-5.776,3.46-5.776a5.623,5.623,0,0,1,3.4,3.956Z"
                              fill="#ca2946"
                              stroke="#ca2946"
                              strokeWidth="1"
                            ></path>
                            <path
                              data-name="Path 80"
                              d="M300.895,36.819a.666.666,0,0,0-.666-.666H294.12v-.863a.666.666,0,0,0-1.332,0v.863h-6.108a.666.666,0,1,0,0,1.332h1.477l-1.095,2.869a2.207,2.207,0,1,0,4.124,0l-1.095-2.869h2.7v7.764h-2.949a.666.666,0,1,0,0,1.332h7.231a.666.666,0,1,0,0-1.332H294.12V37.485h2.7l-1.095,2.869a2.207,2.207,0,1,0,4.124,0l-1.095-2.869h1.476a.666.666,0,0,0,.666-.666Zm-11.052,4.818a.876.876,0,0,1-1.537-.808l.817-2.141.817,2.141A.863.863,0,0,1,289.843,41.636Zm8.662,0a.876.876,0,0,1-1.537-.808l.817-2.141.817,2.141A.863.863,0,0,1,298.505,41.636Z"
                              transform="translate(-259.012 -31.548)"
                              fill="#ca2946"
                              stroke="#ca2946"
                              strokeWidth="1"
                            ></path>
                          </g>
                        </svg>
                        <div>
                          <h3 className="counter-count mb-1 text-primary">
                            {statisticData.countLawsuit.length}
                          </h3>
                          <p className="text-uppercase text-muted mb-0 f-14">القضايا</p>
                        </div>
                      </div>
                    </div>
                    <div className="media">
                      <div
                        style={{
                          gap: '10px',
                          height: '120px',
                          width: '240px',
                          display: 'flex',
                          alignItems: 'center',
                          backgroundColor: 'white',
                        }}
                        className="count-box bg-soft-primary text-center"
                      >
                        <svg
                          className="home_menu_svg"
                          xmlns="http://www.w3.org/2000/svg"
                          width="66.165"
                          height="46.477"
                          viewBox="0 0 42.878 38.008"
                          style={{ width: '4rem' }}
                        >
                          <path
                            d="M37.128,13.268h-2.66V9.07a4.992,4.992,0,0,0-4.986-4.985H22.488A3.324,3.324,0,0,1,20.3,3.268l-2.229-1.94A4.739,4.739,0,0,0,14.965.164H4.985A4.991,4.991,0,0,0,0,5.149V31.434a5.745,5.745,0,0,0,5.436,5.73v.008H33.681a4.748,4.748,0,0,0,4.645-3.806L41.77,18.951l.006-.027a4.738,4.738,0,0,0-4.648-5.656ZM1.412,31.434V5.149A3.578,3.578,0,0,1,4.986,1.575h9.979a3.326,3.326,0,0,1,2.184.817l2.229,1.94A4.737,4.737,0,0,0,22.488,5.5h6.994a3.579,3.579,0,0,1,3.575,3.575v4.2h-.671a3.87,3.87,0,0,0-3.8-3.158h-.233a3.937,3.937,0,0,0-3.867-3.226H8.259a4.743,4.743,0,0,0-4.738,4.738V35.148a4.327,4.327,0,0,1-2.11-3.714ZM28.588,11.522a2.456,2.456,0,0,1,2.349,1.747H18.952A4.72,4.72,0,0,0,14.346,16.9L9.955,32.4l-.008.03a4.322,4.322,0,0,1-.982,1.888V14.848a3.33,3.33,0,0,1,3.326-3.326ZM4.933,35.684V11.622A3.33,3.33,0,0,1,8.259,8.3H24.489a2.524,2.524,0,0,1,2.419,1.814H12.291a4.743,4.743,0,0,0-4.738,4.738V35.363a4.336,4.336,0,0,1-2.621.321ZM40.394,18.637,36.951,33.051l-.006.028a3.335,3.335,0,0,1-3.263,2.682H9.515a5.726,5.726,0,0,0,1.8-2.988l4.391-15.5.008-.03a3.315,3.315,0,0,1,3.237-2.559H37.128a3.327,3.327,0,0,1,3.266,3.958Zm0,0"
                            transform="translate(0.5 0.336)"
                            fill="#ca2946"
                            stroke="#ca2946"
                            strokeWidth="1"
                          ></path>
                        </svg>
                        <div>
                          <h3 className="counter-count mb-1 text-primary">
                            {statisticData.countCustomer.length}
                          </h3>
                          <p className="text-uppercase text-muted mb-0 f-14">
                            ملفات الحرفاء
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="media">
                      <div
                        style={{
                          gap: '10px',
                          width: '240px',
                          display: 'flex',
                          height: '120px',
                          alignItems: 'center',
                          backgroundColor: 'white',
                        }}
                        className="count-box bg-soft-primary text-center"
                      >
                        <svg
                          className="side_menu_svg"
                          focusable="false"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          data-testid="PeopleAltIcon"
                          style={{
                            color: 'rgb(189, 35, 60)',
                            width: '4rem',
                            height: '5rem',
                          }}
                        >
                          <path
                            fill="#ca2946"
                            stroke="#ca2946"
                            fillRule="evenodd"
                            d="M16.67 13.13C18.04 14.06 19 15.32 19 17v3h4v-3c0-2.18-3.57-3.47-6.33-3.87z"
                          ></path>
                          <circle
                            fill="#ca2946"
                            stroke="#ca2946"
                            cx="9"
                            cy="8"
                            r="4"
                            fillRule="evenodd"
                          ></circle>
                          <path
                            fill="#ca2946"
                            stroke="#ca2946"
                            fillRule="evenodd"
                            d="M15 12c2.21 0 4-1.79 4-4s-1.79-4-4-4c-.47 0-.91.1-1.33.24C14.5 5.27 15 6.58 15 8s-.5 2.73-1.33 3.76c.42.14.86.24 1.33.24zm-6 1c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z"
                          ></path>
                        </svg>
                        <div>
                          <h3 className="counter-count mb-1 text-primary">
                            {statisticData.countSecretary.length +
                              lawyerTraineeShip.length}
                          </h3>
                          <p className="text-uppercase text-muted mb-0 f-14">
                            المستخدمين
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <br />
              <Row>
                <Col lg={5} className="">
                  <div
                    style={{
                      display: 'flex',
                      gap: '10px',
                      alignItems: 'baseline',
                    }}
                    className="counter-box"
                  >
                    <div className="media">
                      <div
                        style={{
                          gap: '10px',
                          width: '240px',
                          display: 'flex',
                          alignItems: 'center',
                          height: '120px',
                          backgroundColor: 'white',
                        }}
                        className="count-box bg-soft-primary text-center"
                      >
                        <svg
                          className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv"
                          focusable="false"
                          viewBox="0 0 24 24"
                          /*aria-hidden="true"
                          dataTestid="LocalAtmIcon"*/
                          style={{
                            color: 'rgb(189, 35, 60)',
                            width: '4rem',
                            height: '5rem',
                          }}
                        >
                          <path
                            fill="#ca2946"
                            d="M11 17h2v-1h1c.55 0 1-.45 1-1v-3c0-.55-.45-1-1-1h-3v-1h4V8h-2V7h-2v1h-1c-.55 0-1 .45-1 1v3c0 .55.45 1 1 1h3v1H9v2h2v1zm9-13H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4V6h16v12z"
                          ></path>
                        </svg>
                        <div>
                          <h3 className="counter-count mb-1 text-primary">
                            {statisticData.sumLawsuit[0].total_amount || 0}
                          </h3>
                          <p className="text-uppercase text-muted mb-0 f-14">الأتعاب</p>
                        </div>
                      </div>
                    </div>
                    <div className="media">
                      <div
                        style={{
                          gap: '10px',
                          width: '240px',
                          display: 'flex',
                          alignItems: 'center',
                          backgroundColor: 'white',
                          height: '120px',
                        }}
                        className="count-box bg-soft-primary text-center"
                      >
                        <svg
                          className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv"
                          focusable="false"
                          viewBox="0 0 24 24"
                          /* ariaHidden="true"
                          dataTestid="LocalAtmIcon"*/
                          style={{
                            color: 'rgb(189, 35, 60)',
                            width: '4rem',
                            height: '5rem',
                          }}
                        >
                          <path
                            fill="#ca2946"
                            d="M11 17h2v-1h1c.55 0 1-.45 1-1v-3c0-.55-.45-1-1-1h-3v-1h4V8h-2V7h-2v1h-1c-.55 0-1 .45-1 1v3c0 .55.45 1 1 1h3v1H9v2h2v1zm9-13H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4V6h16v12z"
                          ></path>
                        </svg>
                        <div>
                          <h3 className="counter-count mb-1 text-primary">
                            {statisticData.sumLawsuit[0].total_advance_fees || 0}
                          </h3>
                          <p className="text-uppercase text-muted mb-0 f-14">تسبقة</p>
                        </div>
                      </div>
                    </div>
                    <div className="media">
                      <div
                        style={{
                          gap: '10px',
                          height: '120px',
                          width: '240px',
                          display: 'flex',
                          backgroundColor: 'white',
                          alignItems: 'center',
                        }}
                        className="count-box bg-soft-primary text-center"
                      >
                        <svg
                          className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv"
                          focusable="false"
                          viewBox="0 0 24 24"
                          /*  ariaHidden="true"
                          dataTestid="LocalAtmIcon"*/
                          style={{
                            color: 'rgb(189, 35, 60)',
                            width: '4rem',
                            height: '5rem',
                          }}
                        >
                          <path
                            fill="#ca2946"
                            d="M11 17h2v-1h1c.55 0 1-.45 1-1v-3c0-.55-.45-1-1-1h-3v-1h4V8h-2V7h-2v1h-1c-.55 0-1 .45-1 1v3c0 .55.45 1 1 1h3v1H9v2h2v1zm9-13H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4V6h16v12z"
                          ></path>
                        </svg>
                        <div>
                          <h3 className="counter-count mb-1 text-primary">
                            {statisticData.sumLawsuit[0].total_remaining_fees || 0}
                          </h3>
                          <p className="text-uppercase text-muted mb-0 f-14">
                            باقي الأتعاب
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </>
        )}
      </div>
    );
  }
}
export default withRouter(Statistic);
