const initialReceivableState = {
  addReceivable: null,
  isLoadingAddReceivable: false,
  receivable: null,
  isLoadingReceivable: false,
  isLoadingDestroyReceivable: false,
  destroyReceivable: false,
  duplicateReceivable: null,
  isLoadingDuplicateReceivable: false,
  contracts: [],
  isLoadingContracts: false,
};

const ReceivableReducer = (state = initialReceivableState, action) => {
  switch (action.type) {
    case 'GET_RECEIVABLE_BY_CUSTOMER_REQUESTING':
      return {
        ...state,
        isLoadingContracts: true,
      };
    case 'GET_RECEIVABLE_BY_CUSTOMER_SUCCESS':
      return {
        ...state,
        isLoadingContracts: false,
        contracts: action.contracts,
      };
    case 'GET_RECEIVABLE_BY_CUSTOMER_FAILURE':
      return {
        ...state,
        isLoadingContracts: false,
        contracts: [],
      };
    case 'DUPLICATE_RECEIVABLE_REQUESTING':
      return {
        ...state,
        isLoadingDuplicateReceivable: true,
      };
    case 'DUPLICATE_RECEIVABLE_SUCCESS':
      return {
        ...state,
        isLoadingDuplicateReceivable: false,
        duplicateReceivable: action.session,
      };
    case 'DUPLICATE_RECEIVABLE_FAILURE':
      return {
        ...state,
        isLoadingDuplicateReceivable: false,
        duplicateReceivable: null,
      };
    case 'ACTION_DELETE_RECEIVABLE_REQUESTING':
      return {
        ...state,
        isLoadingDestroyReceivable: true,
      };
    case 'ACTION_DELETE_RECEIVABLE_SUCCESS':
      return {
        ...state,
        isLoadingDestroyReceivable: false,
        destroyReceivable: true,
      };
    case 'ACTION_DELETE_RECEIVABLE_FAILURE':
      return {
        ...state,
        isLoadingDestroyReceivable: false,
        destroyReceivable: false,
      };
    case 'UPDATE_RECEIVABLE_REQUESTING':
      return {
        ...state,
        isLoadingAddReceivable: true,
      };
    case 'UPDATE_RECEIVABLE_SUCCESS':
      return {
        ...state,
        isLoadingAddReceivable: false,
        addReceivable: action.session,
      };
    case 'UPDATE_RECEIVABLE_FAILURE':
      return {
        ...state,
        isLoadingAddReceivable: false,
        addReceivable: null,
      };
    case 'GET_RECEIVABLE_REQUESTING':
      return {
        ...state,
        isLoadingReceivable: true,
      };
    case 'GET_RECEIVABLE_SUCCESS':
      return {
        ...state,
        isLoadingReceivable: false,
        receivable: action.receivable,
      };
    case 'GET_RECEIVABLE_FAILURE':
      return {
        ...state,
        isLoadingReceivable: false,
        receivable: null,
      };

    default:
      return state;
  }
};

export default ReceivableReducer;
