import React, { Component } from 'react';
import ItemTable from '../../../components/item-table';
import TitleTable from '../../../components/title-table';
import { Link } from 'react-router-dom';
import { Autocomplete, Skeleton } from '@material-ui/lab';
import { dataBill2 } from '../../../services/data-table';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import EmptyData from '../../../assets/img/undraw_design_team_af2y.svg';
import Tooltip from '@mui/material/Tooltip';
import ShareIcon from '@mui/icons-material/Share';
import TextField from '@material-ui/core/TextField';
class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDeletePopup: false,
      openUpdatePopup: false,
      openSharePopup: false,
      hasError: false,
      selected_lawsuit_id: null,
      selected_status: '',
      selected_id: null,
      lawyer_id: null,
    };
    this.handleActivePopupDeleteItem = this.handleActivePopupDeleteItem.bind(this);
    this.handleActivePopupShare = this.handleActivePopupShare.bind(this);
    this.handleActivePopupUpdate = this.handleActivePopupUpdate.bind(this);
  }
  handleActivePopupUpdate(id, selected_status, selected_lawsuit_id) {
    this.setState({
      selected_id: id,
      selected_status: selected_status,
      selected_lawsuit_id: selected_lawsuit_id,
      openUpdatePopup: !this.state.openUpdatePopup,
    });
  }
  handleActivePopupShare(id) {
    this.setState({
      selected_id: id,
      openSharePopup: !this.state.openSharePopup,
      lawyer_id: null,
    });
  }
  handleActivePopupDeleteItem(id) {
    this.setState({
      selected_id: id,
      openDeletePopup: !this.state.openDeletePopup,
    });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { destroy, addSession, shareLawsuit } = this.props;

    if (destroy !== prevProps.destroy && destroy) {
      this.handleActivePopupDeleteItem();
    }
    if (shareLawsuit !== prevProps.shareLawsuit && shareLawsuit) {
      this.handleActivePopupShare();
    }
    if (addSession !== prevProps.addSession && addSession) {
      this.handleActivePopupUpdate();
    }
  }

  render() {
    const {
      headerItems,
      editPath,
      updateSession,
      data,
      title,
      actionPath,
      withTitle,
      dataTable,
      action,
      isLoading,
      account,
      params,
      colSpan,
      messageDelete,
      icon,
      destroyYes,
      class1,
      class2,
      isPermissionDelete,
      isPermissionEdit,
      lawyers,
      editAction,
    } = this.props;
    const {
      openDeletePopup,
      selected_lawsuit_id,
      selected_status,
      openUpdatePopup,
      selected_id,
      lawyer_id,
      hasError,
      openSharePopup,
    } = this.state;

    let i = 0;
    let j = 0;
    return (
      <div className={class1}>
        {withTitle ? <TitleTable title={title} icon={icon} /> : ''}
        <div style={{ overflow: 'auto', height: '550px' }} className={class2}>
          {data && data.data && data.data.length > 0 ? (
            <table>
              <thead>
                <tr style={{ height: '4.2rem' }}>
                  {headerItems.map((headerItem) => {
                    j++;
                    return <th key={j}>{headerItem}</th>;
                  })}
                </tr>
              </thead>
              {isLoading ? (
                <tbody>
                  <tr>
                    <td style={{ display: 'revert' }} colSpan={colSpan} key={j}>
                      <Skeleton
                        style={{
                          padding: '10px',
                        }}
                        variant="rect"
                        //width={500}
                        height={30}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ display: 'revert' }} colSpan={colSpan} key={j}>
                      <Skeleton
                        style={{
                          padding: '10px',
                        }}
                        variant="rect"
                        //width={500}
                        height={30}
                      />
                    </td>
                  </tr>
                </tbody>
              ) : data && data.data && data.data.length > 0 ? (
                <tbody>
                  {data &&
                    data.data.map((item) => {
                      i++;
                      return (
                        <tr
                          style={{
                            backgroundColor:
                              dataTable === 'reports' &&
                              item.to_ &&
                              item.to_.id === account.id
                                ? '#f1f1f1'
                                : '#ffffff',
                          }}
                          key={i}
                        >
                          <ItemTable item={dataBill2(item)} />
                          {action ? (
                            <td
                              style={{
                                display: 'flex',
                              }}
                            >
                              <Tooltip title="تحديث">
                                <div
                                  onClick={() => this.props.handleEdit(item.id)}
                                  style={{
                                    padding: '4px',
                                  }}
                                >
                                  <svg
                                    style={{ cursor: 'pointer' }}
                                    id="Group_23"
                                    data-name="Group 23"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="23.304"
                                    height="24.288"
                                    viewBox="0 0 23.304 24.288"
                                  >
                                    <g id="Group_22" data-name="Group 22">
                                      <path
                                        id="Path_86"
                                        data-name="Path 86"
                                        d="M26.636,19.431h.131L33.1,18.053a.623.623,0,0,0,.328-.164L44.163,7.156A4.136,4.136,0,0,0,45.377,4.2a4.267,4.267,0,0,0-1.214-2.987A4.135,4.135,0,0,0,41.209,0a4.037,4.037,0,0,0-2.954,1.247l-10.7,10.733a.591.591,0,0,0-.2.3l-1.378,6.335a.722.722,0,0,0,.2.624A.656.656,0,0,0,26.636,19.431ZM41.209,1.314a2.855,2.855,0,0,1,2.856,2.855q0,.016,0,.033a2.757,2.757,0,0,1-.821,2l-4.037-4.07A2.823,2.823,0,0,1,41.209,1.314ZM38.287,3.086l4.037,4.037L32.97,16.444l-4.037-4ZM28.408,13.753,31.657,17l-4.168.919Z"
                                        transform="translate(-24.83 0)"
                                        fill="#ca2946"
                                      />
                                      <path
                                        id="Path_87"
                                        data-name="Path 87"
                                        d="M30.485,365.717H8.494a.656.656,0,0,0,0,1.313H30.485a.656.656,0,0,0,0-1.313Z"
                                        transform="translate(-7.838 -342.742)"
                                        fill="#ca2946"
                                      />
                                    </g>
                                  </svg>
                                </div>
                              </Tooltip>

                              {(isPermissionDelete &&
                                dataTable !== 'reports' &&
                                dataTable !== 'legalJournal') ||
                              (isPermissionDelete &&
                                dataTable === 'legalJournal' &&
                                item.lawyer_id) ||
                              (isPermissionDelete &&
                                dataTable === 'reports' &&
                                item.from_.id === account.id) ? (
                                <Tooltip title="حذف">
                                  <div
                                    style={{
                                      padding: '4px',
                                    }}
                                    className="jalaset_table__del"
                                  >
                                    <svg
                                      style={{ cursor: 'pointer' }}
                                      onClick={() =>
                                        this.handleActivePopupDeleteItem(item.id)
                                      }
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20.906"
                                      height="25.74"
                                      viewBox="0 0 20.906 25.74"
                                    >
                                      <g id="delete" transform="translate(0 0)">
                                        <path
                                          id="Path_82"
                                          data-name="Path 82"
                                          d="M223,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,223,154.7Zm0,0"
                                          transform="translate(-208.991 -145.377)"
                                          fill="#ca2946"
                                        />
                                        <path
                                          id="Path_83"
                                          data-name="Path 83"
                                          d="M105,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,105,154.7Zm0,0"
                                          transform="translate(-98.105 -145.377)"
                                          fill="#ca2946"
                                        />
                                        <path
                                          id="Path_84"
                                          data-name="Path 84"
                                          d="M1.709,7.662V22.514a3.326,3.326,0,0,0,.884,2.294,2.969,2.969,0,0,0,2.154.931H16.152a2.968,2.968,0,0,0,2.154-.931,3.326,3.326,0,0,0,.884-2.294V7.662A2.3,2.3,0,0,0,18.6,3.133H15.513V2.38A2.368,2.368,0,0,0,13.126,0H7.773A2.368,2.368,0,0,0,5.386,2.38v.754H2.3a2.3,2.3,0,0,0-.591,4.528ZM16.152,24.533H4.747a1.909,1.909,0,0,1-1.832-2.019V7.715h15.07v14.8a1.909,1.909,0,0,1-1.832,2.019ZM6.592,2.38A1.161,1.161,0,0,1,7.773,1.2h5.353A1.161,1.161,0,0,1,14.308,2.38v.754H6.592ZM2.3,4.339H18.6a1.085,1.085,0,0,1,0,2.17H2.3a1.085,1.085,0,0,1,0-2.17Zm0,0"
                                          transform="translate(0.003 0.002)"
                                          fill="#ca2946"
                                        />
                                        <path
                                          id="Path_85"
                                          data-name="Path 85"
                                          d="M164,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,164,154.7Zm0,0"
                                          transform="translate(-153.548 -145.377)"
                                          fill="#ca2946"
                                        />
                                      </g>
                                    </svg>
                                  </div>
                                </Tooltip>
                              ) : (
                                ''
                              )}
                              {updateSession && isPermissionEdit ? (
                                <Tooltip title="التأكد من وجود كل الملفات">
                                  <div
                                    style={{
                                      padding: '4px',
                                    }}
                                    className="jalaset_table__del"
                                  >
                                    <span>
                                      <svg
                                        style={{ cursor: 'pointer' }}
                                        onClick={() =>
                                          this.handleActivePopupUpdate(
                                            item.id,
                                            item.status,
                                            item.lawsuits[0].id,
                                          )
                                        }
                                        className="tickSpan"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20.125"
                                        height="17.774"
                                        viewBox="0 0 29.125 23.774"
                                      >
                                        <path
                                          id="Path_92"
                                          data-name="Path 92"
                                          d="M1325.354,167.5l8.34,9.625,17.8-19.417"
                                          transform="translate(-1323.842 -156.357)"
                                          fill="none"
                                          stroke={
                                            item.status === 'waiting'
                                              ? '#CA2946'
                                              : '#63B17D'
                                          }
                                          strokeWidth="4"
                                        />
                                      </svg>
                                    </span>
                                  </div>
                                </Tooltip>
                              ) : (
                                ''
                              )}
                            </td>
                          ) : (
                            ''
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              ) : (
                <tbody>
                  <tr
                    style={{
                      height: '3rem',
                      textAlign: 'center',
                    }}
                  >
                    <td
                      colSpan={colSpan}
                      style={{
                        height: '3rem',
                        textAlign: 'center',
                      }}
                    ></td>
                  </tr>
                </tbody>
              )}
            </table>
          ) : (
            <div
              className="clients_col-left"
              style={{
                justifyContent: 'center',
                gap: '10%',
                marginTop: '8%',
                display: 'grid',
              }}
            >
              <img src={EmptyData} style={{ textAlign: 'center' }} />
              <span style={{ textAlign: 'center', marginRight: '12%', fontSize: '15px' }}>
                لا يوجد سجل متاح
              </span>
            </div>
          )}
        </div>
        {data && data.total > 5 && data.data.length < data.total ? (
          <div>
            <div style={{ height: '4.2rem' }}>
              <div style={{ textAlign: 'center' }} colSpan={colSpan}>
                <div className="home_table__footer">
                  {actionPath ? (
                    <Link to={actionPath}>
                      المزيد
                      <svg
                        className=""
                        xmlns="http://www.w3.org/2000/svg"
                        width="15.225"
                        height="5.492"
                        viewBox="0 0 15.225 5.492"
                      >
                        <path
                          id="Path_53"
                          data-name="Path 53"
                          d="M3020.1,1976.5l7.3,4.478,7.4-4.478"
                          transform="translate(-3019.841 -1976.072)"
                          fill="none"
                          stroke="var(--color-primary-red)"
                          strokeWidth="1"
                        />
                      </svg>
                    </Link>
                  ) : (
                    <div
                      onClick={() =>
                        this.props.changeSearchedString({
                          ...params,
                          currentPage: 1,
                          limit: params.limit + 5,
                          order: 'DESC',
                        })
                      }
                    >
                      المزيد
                      <svg
                        className=""
                        xmlns="http://www.w3.org/2000/svg"
                        width="15.225"
                        height="5.492"
                        viewBox="0 0 15.225 5.492"
                      >
                        <path
                          id="Path_53"
                          data-name="Path 53"
                          d="M3020.1,1976.5l7.3,4.478,7.4-4.478"
                          transform="translate(-3019.841 -1976.072)"
                          fill="none"
                          stroke="var(--color-primary-red)"
                          strokeWidth="1"
                        />
                      </svg>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : data && data.total > 5 && data.data.length === data.total ? (
          <div>
            <div style={{ height: '4.2rem' }}>
              <div style={{ textAlign: 'center' }} colSpan={colSpan}>
                <div className="home_table__footer">
                  <div
                    onClick={() =>
                      this.props.changeSearchedString({
                        ...params,
                        currentPage: 1,
                        limit: params.limit - 5,
                        order: 'DESC',
                      })
                    }
                  >
                    اقل
                    <svg
                      className=""
                      xmlns="http://www.w3.org/2000/svg"
                      width="15.225"
                      height="5.492"
                      viewBox="0 0 15.225 5.492"
                    >
                      <path
                        id="Path_53"
                        data-name="Path 53"
                        d="M3020.1,1976.5l7.3,4.478,7.4-4.478"
                        transform="translate(-3019.841 -1976.072)"
                        fill="none"
                        stroke="var(--color-primary-red)"
                        strokeWidth="1"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
        <div className={openDeletePopup ? 'popup active' : 'popup'}>
          <div className="popup__title">{messageDelete}</div>
          <div className="popup__btn_grp">
            <button
              onClick={() => this.handleActivePopupDeleteItem()}
              className="cancel_popup"
            >
              <span>الغاء</span>
              <span>&lt;</span>
            </button>
            <button
              onClick={() => {
                if (destroyYes) this.props.deletePath(params, selected_id);
              }}
            >
              <span>تأكيد</span>
              <span className="trashSpan">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.906"
                  height="25.74"
                  viewBox="0 0 20.906 25.74"
                >
                  <g id="delete" transform="translate(0.003 0.002)">
                    <path
                      id="Path_82"
                      data-name="Path 82"
                      d="M223,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,223,154.7Zm0,0"
                      transform="translate(-208.995 -145.379)"
                      fill="#fff"
                    />
                    <path
                      id="Path_83"
                      data-name="Path 83"
                      d="M105,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,105,154.7Zm0,0"
                      transform="translate(-98.108 -145.379)"
                      fill="#fff"
                    />
                    <path
                      id="Path_84"
                      data-name="Path 84"
                      d="M1.709,7.662V22.514a3.326,3.326,0,0,0,.884,2.294,2.969,2.969,0,0,0,2.154.931H16.152a2.968,2.968,0,0,0,2.154-.931,3.326,3.326,0,0,0,.884-2.294V7.662A2.3,2.3,0,0,0,18.6,3.133H15.513V2.38A2.368,2.368,0,0,0,13.126,0H7.773A2.368,2.368,0,0,0,5.386,2.38v.754H2.3a2.3,2.3,0,0,0-.591,4.528ZM16.152,24.533H4.747a1.909,1.909,0,0,1-1.832-2.019V7.715h15.07v14.8a1.909,1.909,0,0,1-1.832,2.019ZM6.592,2.38A1.161,1.161,0,0,1,7.773,1.2h5.353A1.161,1.161,0,0,1,14.308,2.38v.754H6.592ZM2.3,4.339H18.6a1.085,1.085,0,0,1,0,2.17H2.3a1.085,1.085,0,0,1,0-2.17Zm0,0"
                      transform="translate(0 0)"
                      fill="#fff"
                    />
                    <path
                      id="Path_85"
                      data-name="Path 85"
                      d="M164,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,164,154.7Zm0,0"
                      transform="translate(-153.551 -145.379)"
                      fill="#fff"
                    />
                  </g>
                </svg>
              </span>
            </button>
          </div>
        </div>
        <div className={openUpdatePopup ? 'popup active' : 'popup'}>
          <div className="popup__title">
            {selected_status === 'waiting'
              ? 'تأكدت من وجود كل الملفات'
              : 'لست متأكد من وجود كل الملفات'}
          </div>
          <div className="popup__btn_grp">
            <button
              onClick={() => this.handleActivePopupUpdate()}
              className="cancel_popup"
            >
              <span>الغاء</span>
              <span>&lt;</span>
            </button>
            <button
              style={{ background: '#63B17D' }}
              onClick={() => {
                this.props.updateSessionFunction(params, {
                  id: selected_id,
                  status: selected_status === 'waiting' ? 'completed' : 'waiting',
                  lawsuit_id: selected_lawsuit_id,
                });
              }}
            >
              <span>تأكيد</span>
              <span className="trashSpan">
                <svg
                  className="tickSpan"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.125"
                  height="17.774"
                  viewBox="0 0 29.125 23.774"
                >
                  <path
                    id="Path_92"
                    data-name="Path 92"
                    d="M1325.354,167.5l8.34,9.625,17.8-19.417"
                    transform="translate(-1323.842 -156.357)"
                    fill="none"
                    stroke="#f9f9f9"
                    strokeWidth="4"
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>
        <div className={openSharePopup ? 'popup active' : 'popup'}>
          <div className="popup__title">مشاركة مع</div>
          <div className="popup__item">
            <div>
              <label htmlFor="lawsuit_type">الاستاذ</label>
              <Autocomplete
                debug
                id="debug"
                options={lawyers}
                getOptionLabel={(option) => option.title}
                style={{ marginBottom: '4%' }}
                onChange={(event, option) => {
                  this.setState({
                    lawyer_id: option != null ? option.id : '',
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    error={!lawyer_id && hasError}
                    name="to"
                    style={{ border: 0 }}
                    {...params}
                    //label="المدعي"
                    //variant="outlined"
                  />
                )}
              />
            </div>
          </div>
          <div className="popup__btn_grp">
            <button
              onClick={() => this.handleActivePopupShare()}
              className="cancel_popup"
            >
              <span>الغاء</span>
              <span>&lt;</span>
            </button>
            <button
              style={{ background: '#63B17D' }}
              onClick={() => {
                if (!lawyer_id) {
                  this.setState({
                    hasError: true,
                  });
                } else {
                  this.setState({
                    hasError: false,
                  });
                  this.props.submitShareLawsuit({
                    id: selected_id,
                    lawyer_id,
                  });
                }
              }}
            >
              <span>تأكيد</span>
              <span className="trashSpan">
                <svg
                  className="tickSpan"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.125"
                  height="17.774"
                  viewBox="0 0 29.125 23.774"
                >
                  <path
                    id="Path_92"
                    data-name="Path 92"
                    d="M1325.354,167.5l8.34,9.625,17.8-19.417"
                    transform="translate(-1323.842 -156.357)"
                    fill="none"
                    stroke="#f9f9f9"
                    strokeWidth="4"
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>
        <div
          id="overlay"
          className={openDeletePopup || openUpdatePopup || openSharePopup ? 'active' : ''}
        ></div>
      </div>
    );
  }
}

export default Table;
