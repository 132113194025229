import React, { Component } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import ar from 'date-fns/locale/ar-DZ';
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import { cities, courts, DaysArabic, host } from '../../../utils/constants';
import { generateBillNumber, isHasPermission, isNumeric } from '../../../utils/helpres';
import { connect } from 'react-redux';
import moment from 'moment';
import actions from '../store/actions';
import actionsSession from '../../session/store/actions';
import InputField from '../../../components/inputField';
import { CircularProgress } from '@material-ui/core';
import update from 'immutability-helper';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Link } from 'react-router-dom';
import scrollTo from 'gatsby-plugin-smoothscroll';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { toast } from 'react-toastify';
import ToastAction from '../../../components/ToastAction';
import axios from 'axios';

registerLocale('ar', ar);

const mapStateToProps = (state, ownProps) => {
  const { lawsuit, lastBill, customersList } = ownProps;
  let date;
  let court;
  let send_to_court;
  let defendant;
  let defendant_street;
  let defendant_post;
  let type;
  let adjective;
  let facts;
  let evidence;
  let requests;
  let customer_id;
  let customer_name;
  let suit_type_id;
  let suit_type_name;
  let responsible_id;
  let folder_id;
  let folder_name;
  let email_court;
  let files;
  let id;
  let percent;
  let advance_fees;
  let fees;
  let remaining_fees;
  let lawsuit_files;
  let lawsuit_sessions;
  let defendants;
  let number;
  let folders;
  if (lawsuit) {
    folders =
      customersList &&
      customersList.find((item) => {
        if (item._id === lawsuit.customer.id) return item.folders;
        return [];
      });
    /* let _contentState = ContentState.createFromText(lawsuit.requests || '');
    const raw = convertToRaw(_contentState);*/
    date = lawsuit && lawsuit.date ? lawsuit.date : new Date();
    number = lawsuit && lawsuit.number ? lawsuit.number : null;
    send_to_court = lawsuit && lawsuit.send_to_court ? lawsuit.send_to_court : false;
    remaining_fees = lawsuit && lawsuit.remaining_fees ? lawsuit.remaining_fees : null;
    advance_fees = lawsuit && lawsuit.advance_fees ? lawsuit.advance_fees : null;
    fees = lawsuit && lawsuit.fees ? lawsuit.fees : null;
    id = lawsuit && lawsuit.id ? lawsuit.id : null;
    responsible_id = lawsuit && lawsuit.responsible_id ? lawsuit.responsible_id : null;
    lawsuit_files =
      lawsuit && lawsuit.lawsuit_files && lawsuit.lawsuit_files.length > 0
        ? lawsuit.lawsuit_files
        : [];
    lawsuit_sessions =
      lawsuit && lawsuit.sessions && lawsuit.sessions.length > 0 ? lawsuit.sessions : [];
    defendants =
      lawsuit && lawsuit.defendants && lawsuit.defendants.length > 0
        ? lawsuit.defendants
        : [];
    defendant_street =
      lawsuit && lawsuit.defendant_street ? lawsuit.defendant_street : '';
    defendant_post = lawsuit && lawsuit.defendant_post ? lawsuit.defendant_post : '';
    requests = lawsuit && lawsuit.requests ? lawsuit.requests : '';
    evidence = lawsuit && lawsuit.evidence ? lawsuit.evidence : '';
    facts = lawsuit && lawsuit.facts ? lawsuit.facts : '';
    adjective = lawsuit && lawsuit.adjective ? lawsuit.adjective : '';
    type = lawsuit && lawsuit.type ? lawsuit.type : '';
    court = lawsuit && lawsuit.court ? lawsuit.court : '';
    defendant = lawsuit && lawsuit.defendant ? lawsuit.defendant : '';
    suit_type_id = lawsuit && lawsuit.suit_type ? lawsuit.suit_type.id : '';
    suit_type_name = lawsuit && lawsuit.suit_type ? lawsuit.suit_type.name : '';
    customer_name = lawsuit && lawsuit.customer ? lawsuit.customer.name : '';
    customer_id = lawsuit && lawsuit.customer ? lawsuit.customer.id : '';
    folder_name = lawsuit && lawsuit.folder ? lawsuit.folder.name : '';
    folder_id = lawsuit && lawsuit.folder ? lawsuit.folder.id : '';
  }
  return {
    addLawsuit: state.LawsuitReducer.addLawsuit,
    isLoadingAddLawsuit: state.LawsuitReducer.isLoadingAddLawsuit,
    isLoadingDestroySession: state.SessionReducer.isLoadingDestroySession,
    account: state.AppReducer.account,
    initialValues: {
      folders: folders?.folders,
      number: number || '',
      remaining_fees: remaining_fees || 0,
      advance_fees: advance_fees || 0,
      fees: fees || 0,
      id: id || '',
      responsible_id: responsible_id || null,
      date: date || new Date(),
      court: court || '',
      email_court: email_court || '',
      folder_id: folder_id || null,
      folder_name: folder_name || '',
      send_to_court: send_to_court || false,
      requests: requests || '',
      facts: facts || '',
      evidence: evidence || '',
      adjective: adjective || '',
      type: type || '',
      defendant_post: defendant_post || '',
      defendant_street: defendant_street || '',
      defendant: defendant || '',
      customer_name: customer_name || '',
      customer_id: customer_id || null,
      suit_type_name: suit_type_name || '',
      suit_type_id: suit_type_id || null,
      percent: percent || 0,
      lawsuit_files: lawsuit_files || [],
      lawsuit_sessions: lawsuit_sessions || [],
      files: [],
      sessions: lawsuit_sessions || [],
      number_bill:
        lastBill && lastBill.length > 0
          ? `${generateBillNumber(
              parseInt(lastBill[0].number.split('/')[0]),
              moment().format('YYYY'),
            )}`
          : `${generateBillNumber(0, moment().format('YYYY'))}`,
      defendants: defendants || [
        {
          id: 1,
          display_name: '',
          address_street: '',
          post: '',
          cin: '',
        },
      ],
    },
  };
};
const mapDispatchToProps = (dispatch) => ({
  submitLawsuitForm: (values) => dispatch(actions.submitLawsuitForm(values)),
  deleteFiles: async (lawsuit_id, id) => {
    await dispatch(actions.deleteFiles(id));
    dispatch(actions.getLawsuitById(lawsuit_id));
  },
  destroySession: async (id, lawsuit_id) => {
    await dispatch(actionsSession.deleteSession(id));
    dispatch(actions.getLawsuitById(lawsuit_id));
  },
  submitSessionForm: (values) => dispatch(actionsSession.submitSessionForm(values)),
});

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_id: null,
      selected_session_id: null,
      openDeletePopup: false,
      openDeletePopupSession: false,
      openAddPopup: false,
      hasError: false,
      hasErrorDateSession: false,
      hasErrorDate: false,
      hasErrorDefendants: false,
      isOpenModal: false,
      urlImg: null,
      initialValues: {
        ...props.initialValues,
        date_session: new Date(),
        description: '',
        court_session: '',
        allFiles: [],
      },
    };
    this.onSelectFile = this.onSelectFile.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.increase = this.increase.bind(this);
    this.deleteFiles = this.deleteFiles.bind(this);
    this.deleteSession = this.deleteSession.bind(this);
    this.handleActivePopupDeleteItem = this.handleActivePopupDeleteItem.bind(this);
    this.handleActiveAddPopup = this.handleActiveAddPopup.bind(this);
    this.handleActiveDeletePopupSession = this.handleActiveDeletePopupSession.bind(this);
    this.submitFormDefendant = this.submitFormDefendant.bind(this);
    this.handleInputItem = this.handleInputItem.bind(this);
  }
  handleActivePopupDeleteItem(id) {
    this.setState({
      selected_id: id,
      openDeletePopup: !this.state.openDeletePopup,
    });
  }
  handleActiveAddPopup(id) {
    this.setState({
      openAddPopup: !this.state.openAddPopup,
    });
  }
  handleActiveDeletePopupSession(id) {
    this.setState({
      openDeletePopupSession: !this.state.openDeletePopupSession,
      selected_session_id: id,
    });
  }
  deleteFiles(id) {
    const { initialValues } = this.state;
    this.setState((prevState) => {
      let data = [...prevState.initialValues.files];
      data = data.filter((item) => item.id !== id);

      this.setState({
        initialValues: {
          ...initialValues,
          files: data,
        },
      });
    });
  }
  deleteSession(id) {
    const { initialValues } = this.state;
    this.setState((prevState) => {
      let data = [...prevState.initialValues.sessions];
      data = data.filter((item) => item.id !== id);

      this.setState({
        initialValues: {
          ...initialValues,
          sessions: data,
        },
        openDeletePopupSession: false,
      });
    });
  }
  increase() {
    const { initialValues } = this.state;
    const newPercent = initialValues.percent + 1;
    if (newPercent >= 20) {
      clearTimeout(this.tm);
      return;
    }
    this.setState({
      initialValues: {
        ...initialValues,
        percent: newPercent,
      },
    });
    this.tm = setTimeout(this.increase, 20);
  }
  handleInputItem(value, id, element, name) {
    const { initialValues } = this.state;
    this.setState((prevState) => {
      const data = [...prevState.initialValues[element]];

      var commentIndex = data.findIndex(function (c) {
        return c.id === id;
      });
      let updatedComment = update(data[commentIndex], { [name]: { $set: value } });
      data[commentIndex] = updatedComment;
      this.setState({
        initialValues: {
          ...initialValues,
          [element]: data,
        },
      });
    });
  }
  onConfirm() {
    const { initialValues } = this.state;
    this.setState({
      initialValues: {
        ...initialValues,
        percent: -1,
      },
    });
  }
  submitForm(initialValues) {
    if (
      !initialValues.court ||
      /* !initialValues.defendant ||
      !initialValues.defendant_street ||
      !initialValues.defendant_post ||*/
      !initialValues.date ||
      //  !initialValues.adjective ||
      !initialValues.facts ||
      !initialValues.number ||
      // (initialValues.number && !isNumeric(initialValues.number)) ||
      // !initialValues.evidence ||
      !initialValues.customer_id ||
      // !initialValues.responsible_id ||
      //!initialValues.requests ||
      !initialValues.suit_type_id
    ) {
      this.setState({
        ...initialValues,
        hasError: true,
      });
      scrollTo('#mainView');
    } else if (
      !initialValues.id &&
      initialValues.date &&
      moment().isAfter(moment(initialValues.date))
    ) {
      this.setState({
        ...initialValues,
        hasErrorDate: true,
      });
      scrollTo('#mainView');
    } else {
      this.props.submitLawsuitForm(initialValues);
    }
  }
  submitFormSession(initialValues) {
    if (!initialValues.court_session || !initialValues.date_session) {
      this.setState({
        ...initialValues,
        hasError: true,
      });
    } /*else if (
      initialValues.date_session &&
      moment().isAfter(moment(initialValues.date_session))
    ) {
      this.setState({
        ...initialValues,
        hasErrorDateSession: true,
      });
    }*/ else {
      this.setState({
        initialValues: {
          ...initialValues,
          sessions: [
            ...initialValues.sessions,
            {
              id:
                initialValues.sessions.length > 0
                  ? initialValues.sessions[initialValues.sessions.length - 1].id + 1
                  : 1,
              court: initialValues.court_session,
              date: initialValues.date_session,
              description: initialValues.description,
              type: initialValues.type,
            },
          ],
          //percent: 0,
        },
        openAddPopup: false,
      });
      this.props.submitSessionForm({
        date: initialValues.date_session,
        court: initialValues.court_session,
        responsible_id: initialValues.responsible_id
          ? initialValues.responsible_id
          : null,
        lawsuit_id: initialValues.id,
        description: initialValues.description,
        type: initialValues.type,
      });
    }
  }
  handleDeleteItemDefendant(id) {
    const { initialValues } = this.state;
    this.setState((prevState) => {
      let data = [...prevState.initialValues.defendants];
      if (data.length > 1) {
        data = data.filter((item) => item.id !== id);

        this.setState({
          initialValues: {
            ...initialValues,
            defendants: data,
          },
        });
      }
    });
  }
  submitFormDefendant(initialValues) {
    if (!initialValues.defendants[0].display_name) {
      this.setState({
        ...initialValues,
        hasErrorDefendants: true,
      });
    } else {
      this.setState({
        hasErrorDefendants: false,
        initialValues: {
          ...initialValues,
          defendants: [
            ...initialValues.defendants,
            {
              id:
                initialValues.defendants.length > 0
                  ? initialValues.defendants[initialValues.defendants.length - 1].id + 1
                  : 1,
              display_name: '',
              address_street: '',
              post: '',
              cin: '',
            },
          ],
          //percent: 0,
        },
        openAddPopup: false,
      });
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { addLawsuit } = this.props;
    if (addLawsuit !== prevProps.addLawsuit && addLawsuit && addLawsuit.id) {
      this.props.goBack();
    }
  }
  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };
  onSelectFile = (e) => {
    const { initialValues } = this.state;
    let files = [];
    this.increase();
    for (var i = 0; i < e.target.files.length; i++) {
      files.push({
        id: files.length > 0 ? files[files.length - 1].id + 1 : 1,
        name: e.target.files[i].name,
        file: e.target.files[i],
      });
    }
    this.setState({
      initialValues: {
        ...initialValues,
        files,
        allFiles: e.target.files,
      },
    });
  };
  openModal() {
    this.setState({
      isOpenModal: !this.state.isOpenModal,
    });
  }

  render() {
    const {
      hasErrorDate,
      openAddPopup,
      initialValues,
      selected_session_id,
      selected_id,
      openDeletePopup,
      openDeletePopupSession,
      hasError,
      hasErrorDateSession,
      hasErrorDefendants,
      isOpenModal,
      urlImg,
    } = this.state;
    const {
      suitTypes,
      account,
      customersList,
      isLoadingDestroySession,
      isLoadingAddLawsuit,
      lawyerTraineeShip,
      //folders,
    } = this.props;
    let i = 0;
    let isPermissionDelete = isHasPermission(
      account.login_access.permissions,
      'حذف-الجلسات',
    );
    return (
      <form action="" id="mainView">
        {initialValues.percent < 5 && initialValues.files.length > 0 ? (
          <>
            {' '}
            <div id="overlay" className="active"></div>
            <CircularProgress
              style={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transition: '200ms ease-in-out',
                fontSize: '65px',
                transform: 'translate(-50%, -50%) scale(1)',
              }}
            />
          </>
        ) : (
          ''
        )}
        <div className="lawsuit_form__sections">
          {account && account.lawyer && account.lawyer.pack === 'beginner' ? (
            ''
          ) : lawyerTraineeShip && lawyerTraineeShip.length > 1 ? (
            <div>
              <label htmlFor="attr">تسند إلى</label>
              <select
                className={hasError && !initialValues.responsible_id ? 'input-error' : ''}
                onChange={(e) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      responsible_id:
                        e.target.value === 'تسند إلى' ? null : e.target.value,
                    },
                  });
                }}
                value={initialValues.responsible_id}
              >
                <option value={null}>تسند إلى</option>
                {lawyerTraineeShip.map((lawyerT) => {
                  return (
                    <option key={lawyerT.id} value={lawyerT.id}>
                      {`${lawyerT.first_name} ${lawyerT.last_name}`}
                    </option>
                  );
                })}
              </select>
            </div>
          ) : (
            ''
          )}

          <div>
            <label htmlFor="attr">عدد القضية</label>
            <InputField
              value={initialValues.number}
              name="number"
              placeholder="عدد القضية"
              type="text"
              hasError={hasError}
              validInput="string"
              onChange={(event) => {
                this.setState({
                  initialValues: {
                    ...initialValues,
                    number: event.target.value,
                  },
                });
              }}
            />
          </div>
          <div>
            <label htmlFor="lawsuit_type">الحريف</label>
            <Autocomplete
              debug
              noOptionsText={'قائمة الحرفاء فارغة'}
              id="debug"
              options={customersList}
              getOptionLabel={(option) => option.title}
              style={{ marginBottom: '4%' }}
              onChange={(event, option) => {
                //this.props.getAllFolders(option.id);
                this.setState({
                  initialValues: {
                    ...initialValues,
                    folders: option.folders,
                    customer_id: option != null ? option.id : '',
                  },
                });
              }}
              defaultValue={{
                title: initialValues.customer_name,
              }}
              renderInput={(params) => (
                <TextField
                  error={!initialValues.customer_id && hasError}
                  name="customer_id"
                  style={{ border: 0 }}
                  {...params}
                  //label="المدعي"
                  //variant="outlined"
                />
              )}
            />
          </div>
          {initialValues.customer_id &&
            initialValues.folders &&
            initialValues.folders.length > 0 && (
              <div>
                <label htmlFor="lawsuit_type">الملف</label>
                <Autocomplete
                  debug
                  noOptionsText={'قائمة ملفات فارغة'}
                  id="debug"
                  options={initialValues.folders}
                  getOptionLabel={(option) => option.title}
                  style={{ marginBottom: '4%' }}
                  onChange={(event, option) => {
                    this.setState({
                      initialValues: {
                        ...initialValues,
                        folder_id: option != null ? option.id : '',
                      },
                    });
                  }}
                  defaultValue={{
                    title: initialValues.folder_name,
                  }}
                  renderInput={(params) => (
                    <TextField
                      error={!initialValues.folder_id && hasError}
                      name="folder_id"
                      style={{ border: 0 }}
                      {...params}
                      //label="المدعي"
                      //variant="outlined"
                    />
                  )}
                />
              </div>
            )}
          {initialValues.defendants.map((item) => {
            i++;
            return (
              <div key={item.id}>
                <label>الضد</label>
                <InputField
                  value={item.display_name}
                  name="display_name"
                  placeholder=" الاسم الثلاثي"
                  type="text"
                  hasError={hasError || hasErrorDefendants}
                  validInput="text"
                  onChange={(event) => {
                    this.handleInputItem(
                      event.target.value,
                      item.id,
                      'defendants',
                      'display_name',
                    );
                  }}
                />
                <InputField
                  value={item.cin}
                  name="cin"
                  placeholder="ب ت"
                  type="text"
                  hasError={hasErrorDefendants}
                  validInput="text"
                  onChange={(event) => {
                    this.handleInputItem(
                      event.target.value,
                      item.id,
                      'defendants',
                      'cin',
                    );
                  }}
                />
                <InputField
                  value={item.address_street}
                  name="address_street"
                  placeholder="المقر"
                  type="text"
                  //hasError={hasError}
                  validInput="text"
                  onChange={(event) => {
                    this.handleInputItem(
                      event.target.value,
                      item.id,
                      'defendants',
                      'address_street',
                    );
                  }}
                />
                <InputField
                  value={item.post}
                  name="post"
                  placeholder="المهنة"
                  type="text"
                  //hasError={hasError}
                  validInput="text"
                  onChange={(event) => {
                    this.handleInputItem(
                      event.target.value,
                      item.id,
                      'defendants',
                      'post',
                    );
                  }}
                />
                <ControlPointIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.submitFormDefendant(initialValues)}
                  fontSize="large"
                  htmlColor="rgb(189, 35, 60)"
                />
                <svg
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.handleDeleteItemDefendant(item.id)}
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.906"
                  height="25.74"
                  viewBox="0 0 20.906 25.74"
                >
                  <g id="delete" transform="translate(0 0)">
                    <path
                      id="Path_82"
                      data-name="Path 82"
                      d="M223,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,223,154.7Zm0,0"
                      transform="translate(-208.991 -145.377)"
                      fill="#ca2946"
                    />
                    <path
                      id="Path_83"
                      data-name="Path 83"
                      d="M105,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,105,154.7Zm0,0"
                      transform="translate(-98.105 -145.377)"
                      fill="#ca2946"
                    />
                    <path
                      id="Path_84"
                      data-name="Path 84"
                      d="M1.709,7.662V22.514a3.326,3.326,0,0,0,.884,2.294,2.969,2.969,0,0,0,2.154.931H16.152a2.968,2.968,0,0,0,2.154-.931,3.326,3.326,0,0,0,.884-2.294V7.662A2.3,2.3,0,0,0,18.6,3.133H15.513V2.38A2.368,2.368,0,0,0,13.126,0H7.773A2.368,2.368,0,0,0,5.386,2.38v.754H2.3a2.3,2.3,0,0,0-.591,4.528ZM16.152,24.533H4.747a1.909,1.909,0,0,1-1.832-2.019V7.715h15.07v14.8a1.909,1.909,0,0,1-1.832,2.019ZM6.592,2.38A1.161,1.161,0,0,1,7.773,1.2h5.353A1.161,1.161,0,0,1,14.308,2.38v.754H6.592ZM2.3,4.339H18.6a1.085,1.085,0,0,1,0,2.17H2.3a1.085,1.085,0,0,1,0-2.17Zm0,0"
                      transform="translate(0.003 0.002)"
                      fill="#ca2946"
                    />
                    <path
                      id="Path_85"
                      data-name="Path 85"
                      d="M164,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,164,154.7Zm0,0"
                      transform="translate(-153.548 -145.377)"
                      fill="#ca2946"
                    />
                  </g>
                </svg>
              </div>
            );
          })}

          <div>
            <label htmlFor="lawsuit_type">نوع القضية</label>
            <Autocomplete
              debug
              id="debug"
              options={suitTypes}
              getOptionLabel={(option) => option.title}
              style={{ marginBottom: '4%' }}
              onChange={(event, option) => {
                this.setState({
                  initialValues: {
                    ...initialValues,
                    suit_type_id: option != null ? option.id : '',
                  },
                });
              }}
              defaultValue={{
                title: initialValues.suit_type_name,
              }}
              renderInput={(params) => (
                <TextField
                  error={!initialValues.suit_type_id && hasError}
                  name="suit_type_id"
                  style={{ border: 0 }}
                  {...params}
                  //label="نوع القضية"
                  //variant="outlined"
                />
              )}
            />
            <div className="rv_form__sections-left">
              <div
                style={{
                  alignItems: 'center',
                }}
                className="jalaset_form__sections-left"
              >
                <div
                  style={{
                    width: '9rem',
                    marginLeft: 0,
                  }}
                  className="jalaset_form__label"
                  htmlFor="date_selected"
                >
                  تاريخ الجلسة
                </div>
                <div className="jalaset_form__cal">
                  <DatePicker
                    selected={new Date(initialValues.date)}
                    locale="ar"
                    onChange={(date) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          date: date,
                        },
                      });
                    }}
                    customInput={
                      <svg
                        style={{
                          cursor: 'pointer',
                        }}
                        id="calendar"
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                      >
                        <g id="Group_28" data-name="Group 28">
                          <g id="Group_27" data-name="Group 27">
                            <ellipse
                              id="Ellipse_20"
                              data-name="Ellipse 20"
                              cx="1"
                              cy="1.5"
                              rx="1"
                              ry="1.5"
                              transform="translate(22 11)"
                              fill="#ca2946"
                            />
                            <path
                              id="Path_89"
                              data-name="Path 89"
                              d="M25.312,2.344H23.789V1.172a1.172,1.172,0,0,0-2.344,0V2.344H16.113V1.172a1.172,1.172,0,0,0-2.344,0V2.344H8.5V1.172a1.172,1.172,0,1,0-2.344,0V2.344H4.688A4.693,4.693,0,0,0,0,7.031V25.312A4.693,4.693,0,0,0,4.688,30h8.965a1.172,1.172,0,0,0,0-2.344H4.688a2.346,2.346,0,0,1-2.344-2.344V7.031A2.346,2.346,0,0,1,4.688,4.688H6.152V5.859a1.172,1.172,0,0,0,2.344,0V4.688H13.77V5.859a1.172,1.172,0,0,0,2.344,0V4.688h5.332V5.859a1.172,1.172,0,0,0,2.344,0V4.688h1.523a2.346,2.346,0,0,1,2.344,2.344v6.68a1.172,1.172,0,0,0,2.344,0V7.031A4.693,4.693,0,0,0,25.312,2.344Z"
                              fill="#ca2946"
                            />
                            <path
                              id="Path_90"
                              data-name="Path 90"
                              d="M277.09,270a7.09,7.09,0,1,0,7.09,7.09A7.1,7.1,0,0,0,277.09,270Zm0,11.836a4.746,4.746,0,1,1,4.746-4.746A4.751,4.751,0,0,1,277.09,281.836Z"
                              transform="translate(-254.18 -254.18)"
                              fill="#ca2946"
                            />
                            <path
                              id="Path_91"
                              data-name="Path 91"
                              d="M373.871,332.4h-.527v-1.23a1.172,1.172,0,0,0-2.344,0v2.4a1.172,1.172,0,0,0,1.172,1.172h1.7a1.172,1.172,0,0,0,0-2.344Z"
                              transform="translate(-349.262 -310.664)"
                              fill="#ca2946"
                            />
                            <circle
                              id="Ellipse_21"
                              data-name="Ellipse 21"
                              cx="1.5"
                              cy="1.5"
                              r="1.5"
                              transform="translate(16 11)"
                              fill="#ca2946"
                            />
                            <circle
                              id="Ellipse_22"
                              data-name="Ellipse 22"
                              cx="1.5"
                              cy="1.5"
                              r="1.5"
                              transform="translate(11 16)"
                              fill="#ca2946"
                            />
                            <ellipse
                              id="Ellipse_23"
                              data-name="Ellipse 23"
                              cx="1"
                              cy="1.5"
                              rx="1"
                              ry="1.5"
                              transform="translate(6 11)"
                              fill="#ca2946"
                            />
                            <ellipse
                              id="Ellipse_24"
                              data-name="Ellipse 24"
                              cx="1"
                              cy="1.5"
                              rx="1"
                              ry="1.5"
                              transform="translate(6 16)"
                              fill="#ca2946"
                            />
                            <circle
                              id="Ellipse_25"
                              data-name="Ellipse 25"
                              cx="1"
                              cy="1"
                              r="1"
                              transform="translate(6 22)"
                              fill="#ca2946"
                            />
                            <ellipse
                              id="Ellipse_26"
                              data-name="Ellipse 26"
                              cx="1.5"
                              cy="1"
                              rx="1.5"
                              ry="1"
                              transform="translate(11 22)"
                              fill="#ca2946"
                            />
                            <circle
                              id="Ellipse_27"
                              data-name="Ellipse 27"
                              cx="1.5"
                              cy="1.5"
                              r="1.5"
                              transform="translate(11 11)"
                              fill="#ca2946"
                            />
                          </g>
                        </g>
                      </svg>
                    }
                  />
                </div>

                <div className="jalaset_form__date_selected">
                  <div className="jalaset_form__date_selected__day">
                    {DaysArabic[moment(initialValues.date).format('dddd')]}
                  </div>
                  <div className="jalaset_form__date_selected__day">
                    {moment(initialValues.date).format('DD/MM/YYYY')}
                  </div>
                </div>
              </div>
              <div className="rv_form__sections-left_field">
                <div className="rv_form__label" htmlFor="date_selected">
                  توقيت الجلسة
                </div>
                <div className="rv_form__t">
                  <DatePicker
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                    selected={new Date(initialValues.date)}
                    locale="ar"
                    minTime={new Date(initialValues.date).setHours(8, 0, 0, 0)}
                    maxTime={new Date(initialValues.date).setHours(18, 0, 0, 0)}
                    // maxTime="18:00"
                    onChange={(date) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          date: date,
                        },
                      });
                    }}
                    customInput={
                      <svg
                        className="rv_time_icon"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24.538"
                        height="24.538"
                        viewBox="0 0 24.538 24.538"
                      >
                        <g
                          id="clock_1_"
                          data-name="clock(1)"
                          transform="translate(0.5 0.5)"
                        >
                          <path
                            id="Path_103"
                            data-name="Path 103"
                            d="M11.769,0A11.769,11.769,0,1,0,23.538,11.769,11.782,11.782,0,0,0,11.769,0Zm0,22.067a10.3,10.3,0,1,1,10.3-10.3,10.309,10.309,0,0,1-10.3,10.3Z"
                            fill="#a8192b"
                            stroke="#a8192b"
                            strokeWidth="1"
                          />
                          <path
                            id="Path_104"
                            data-name="Path 104"
                            d="M209.265,83.118h-1.471v7.66l4.629,4.629,1.04-1.04-4.2-4.2Z"
                            transform="translate(-196.761 -78.705)"
                            fill="#a8192b"
                            stroke="#a8192b"
                            strokeWidth="1"
                          />
                        </g>
                      </svg>
                    }
                  />
                </div>
                <div className="rv_form__time_selected">
                  {moment(initialValues.date).format('HH:mm')}س -
                  {moment(initialValues.date).add(30, 'minutes').format('HH:mm')}س
                </div>
              </div>
              {hasErrorDate &&
              initialValues.date &&
              moment().isAfter(moment(initialValues.date)) ? (
                <div className="rv_form__sections-left_field">
                  <div>
                    <svg
                      className="rv_danger_icon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="27.077"
                      height="24.294"
                      viewBox="0 0 27.077 24.294"
                    >
                      <g id="alert" transform="translate(0 -26.315)">
                        <g
                          id="Group_39"
                          data-name="Group 39"
                          transform="translate(0 26.315)"
                        >
                          <g
                            id="Group_38"
                            data-name="Group 38"
                            transform="translate(0 0)"
                          >
                            <path
                              id="Path_105"
                              data-name="Path 105"
                              d="M26.645,45.833,16.3,27.907a3.184,3.184,0,0,0-5.515,0L.431,45.833a3.184,3.184,0,0,0,2.757,4.776h20.7a3.184,3.184,0,0,0,2.757-4.776Zm-1.374,2.391a1.581,1.581,0,0,1-1.384.8H3.189a1.6,1.6,0,0,1-1.384-2.4L12.155,28.7a1.6,1.6,0,0,1,2.767,0l10.35,17.926A1.582,1.582,0,0,1,25.272,48.224Z"
                              transform="translate(0 -26.315)"
                              fill="#ff8000"
                            />
                          </g>
                        </g>
                        <g
                          id="Group_41"
                          data-name="Group 41"
                          transform="translate(12.745 34.232)"
                        >
                          <g
                            id="Group_40"
                            data-name="Group 40"
                            transform="translate(0 0)"
                          >
                            <rect
                              id="Rectangle_145"
                              data-name="Rectangle 145"
                              width="1.586"
                              height="7.932"
                              fill="#ff8000"
                            />
                          </g>
                        </g>
                        <g
                          id="Group_43"
                          data-name="Group 43"
                          transform="translate(12.481 43.75)"
                        >
                          <g id="Group_42" data-name="Group 42">
                            <path
                              id="Path_106"
                              data-name="Path 106"
                              d="M237.06,355.99a1.058,1.058,0,1,0,1.058,1.058A1.059,1.059,0,0,0,237.06,355.99Z"
                              transform="translate(-236.002 -355.99)"
                              fill="#ff8000"
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                  <div className="rv_err_msg">الرجاء تحديد تاريخ في المستقبل</div>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
          <div>
            <label htmlFor="court">المحكمة</label>
            <Autocomplete
              debug
              id="debug"
              options={courts}
              getOptionLabel={(option) => option.title}
              style={{ marginBottom: '4%' }}
              onChange={(event, option) => {
                this.setState({
                  initialValues: {
                    ...initialValues,
                    court: option != null ? option.title : '',
                    email_court: option != null ? option.email : '',
                    send_to_court: false,
                  },
                });
              }}
              defaultValue={{
                title: initialValues.court,
              }}
              renderInput={(params) => (
                <TextField
                  error={!initialValues.court && hasError}
                  name="court"
                  style={{ border: 0 }}
                  {...params}
                  //label="المحكمة"
                  //variant="outlined"
                />
              )}
            />
            <div>
              <FormControlLabel
                value="end"
                control={
                  <Checkbox
                    color="error"
                    onChange={(e) => {
                      let court;
                      if (initialValues.court) {
                        court = courts.find((item) => item.title === initialValues.court);
                        if (court && court.email) {
                          this.setState({
                            initialValues: {
                              ...initialValues,
                              send_to_court: e.target.checked,
                              email_court: (court && court.email) || '',
                            },
                          });
                        } else
                          toast(
                            ({ closeToast }) => (
                              <ToastAction
                                type="warning"
                                message={`لا يوجد بريد إلكتروني خاص ب${court.title}`}
                              />
                            ),
                            {
                              hideProgressBar: true,
                            },
                          );
                      }
                    }}
                    checked={initialValues.send_to_court}
                  />
                }
                label="مشاركة هذه القضية عن طريق البريد الالكتروني للمحكمة"
                labelPlacement="start"
              />
              {/*<FormControlLabel
                labelPlacement="start"
                label="مشاركة هذه القضية عن طريق البريد الالكتروني للمحكمة"
                control={
                  <Switch
                    color="error"
                    onChange={(e) =>
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          send_to_court: e.target.checked,
                        },
                      })
                    }
                    defaultChecked={initialValues.send_to_court}
                  />
                }
              />*/}
            </div>
          </div>
          {initialValues.id ? (
            <>
              <span className="ls_seperator">مآل القضية</span>
              <div>
                <label htmlFor="lawsuit_requests">اضافة مآل</label>
                <label
                  onClick={() => this.handleActiveAddPopup()}
                  style={{ textAlign: 'center' }}
                  id="upload_button_label"
                >
                  <svg
                    height="10"
                    viewBox="0 0 448 448"
                    width="10"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m408 184h-136c-4.417969 0-8-3.582031-8-8v-136c0-22.089844-17.910156-40-40-40s-40 17.910156-40 40v136c0 4.417969-3.582031 8-8 8h-136c-22.089844 0-40 17.910156-40 40s17.910156 40 40 40h136c4.417969 0 8 3.582031 8 8v136c0 22.089844 17.910156 40 40 40s40-17.910156 40-40v-136c0-4.417969 3.582031-8 8-8h136c22.089844 0 40-17.910156 40-40s-17.910156-40-40-40zm0 0" />
                  </svg>
                </label>
              </div>
              {initialValues.sessions.length === 0 ? (
                ''
              ) : (
                <div className="jalaset__judgements_table">
                  <div style={{ width: '100%' }} className="jalaset_table">
                    <table>
                      <thead>
                        <tr>
                          <th>التاريخ</th>
                          <th>المحكمة</th>
                          <th>الوصف</th>
                          {isPermissionDelete ? <th>الإعدادت</th> : ''}
                        </tr>
                      </thead>
                      <tbody>
                        {initialValues.sessions.map((session) => {
                          return (
                            <tr height="30px" key={session.id}>
                              <td style={{ minWidth: '20rem' }}>
                                <span className="rv_td_span_date">
                                  {moment(session.date).format('DD/MM/YYYY')}
                                </span>
                              </td>
                              <td>{session.court}</td>
                              <td>
                                <textarea>{session.description}</textarea>
                              </td>
                              {isPermissionDelete ? (
                                <td>
                                  <Link to={`/edit-session/${session.id}`}>
                                    <div
                                      title="تحديث"
                                      style={{
                                        padding: '4px',
                                      }}
                                    >
                                      <svg
                                        style={{ cursor: 'pointer' }}
                                        id="Group_23"
                                        data-name="Group 23"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="23.304"
                                        height="24.288"
                                        viewBox="0 0 23.304 24.288"
                                      >
                                        <g id="Group_22" data-name="Group 22">
                                          <path
                                            id="Path_86"
                                            data-name="Path 86"
                                            d="M26.636,19.431h.131L33.1,18.053a.623.623,0,0,0,.328-.164L44.163,7.156A4.136,4.136,0,0,0,45.377,4.2a4.267,4.267,0,0,0-1.214-2.987A4.135,4.135,0,0,0,41.209,0a4.037,4.037,0,0,0-2.954,1.247l-10.7,10.733a.591.591,0,0,0-.2.3l-1.378,6.335a.722.722,0,0,0,.2.624A.656.656,0,0,0,26.636,19.431ZM41.209,1.314a2.855,2.855,0,0,1,2.856,2.855q0,.016,0,.033a2.757,2.757,0,0,1-.821,2l-4.037-4.07A2.823,2.823,0,0,1,41.209,1.314ZM38.287,3.086l4.037,4.037L32.97,16.444l-4.037-4ZM28.408,13.753,31.657,17l-4.168.919Z"
                                            transform="translate(-24.83 0)"
                                            fill="#ca2946"
                                          />
                                          <path
                                            id="Path_87"
                                            data-name="Path 87"
                                            d="M30.485,365.717H8.494a.656.656,0,0,0,0,1.313H30.485a.656.656,0,0,0,0-1.313Z"
                                            transform="translate(-7.838 -342.742)"
                                            fill="#ca2946"
                                          />
                                        </g>
                                      </svg>
                                    </div>
                                  </Link>
                                  <div
                                    style={{
                                      padding: '4px',
                                    }}
                                    className="jalaset_table__del"
                                  >
                                    <svg
                                      style={{ cursor: 'pointer' }}
                                      onClick={() =>
                                        this.handleActiveDeletePopupSession(session.id)
                                      }
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20.906"
                                      height="25.74"
                                      viewBox="0 0 20.906 25.74"
                                    >
                                      <g id="delete" transform="translate(0 0)">
                                        <path
                                          id="Path_82"
                                          data-name="Path 82"
                                          d="M223,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,223,154.7Zm0,0"
                                          transform="translate(-208.991 -145.377)"
                                          fill="#ca2946"
                                        />
                                        <path
                                          id="Path_83"
                                          data-name="Path 83"
                                          d="M105,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,105,154.7Zm0,0"
                                          transform="translate(-98.105 -145.377)"
                                          fill="#ca2946"
                                        />
                                        <path
                                          id="Path_84"
                                          data-name="Path 84"
                                          d="M1.709,7.662V22.514a3.326,3.326,0,0,0,.884,2.294,2.969,2.969,0,0,0,2.154.931H16.152a2.968,2.968,0,0,0,2.154-.931,3.326,3.326,0,0,0,.884-2.294V7.662A2.3,2.3,0,0,0,18.6,3.133H15.513V2.38A2.368,2.368,0,0,0,13.126,0H7.773A2.368,2.368,0,0,0,5.386,2.38v.754H2.3a2.3,2.3,0,0,0-.591,4.528ZM16.152,24.533H4.747a1.909,1.909,0,0,1-1.832-2.019V7.715h15.07v14.8a1.909,1.909,0,0,1-1.832,2.019ZM6.592,2.38A1.161,1.161,0,0,1,7.773,1.2h5.353A1.161,1.161,0,0,1,14.308,2.38v.754H6.592ZM2.3,4.339H18.6a1.085,1.085,0,0,1,0,2.17H2.3a1.085,1.085,0,0,1,0-2.17Zm0,0"
                                          transform="translate(0.003 0.002)"
                                          fill="#ca2946"
                                        />
                                        <path
                                          id="Path_85"
                                          data-name="Path 85"
                                          d="M164,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,164,154.7Zm0,0"
                                          transform="translate(-153.548 -145.377)"
                                          fill="#ca2946"
                                        />
                                      </g>
                                    </svg>
                                  </div>
                                </td>
                              ) : (
                                ''
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </>
          ) : (
            ''
          )}

          <span className="ls_seperator">العريضة</span>

          {/* <div>
            <label htmlFor="attr">الصفة</label>
            <InputField
              value={initialValues.adjective}
              name="adjective"
              placeholder="الصفة"
              type="text"
              hasError={hasError}
              validInput="text"
              onChange={(event) => {
                this.setState({
                  initialValues: {
                    ...initialValues,
                    adjective: event.target.value,
                  },
                });
              }}
            />
          </div>*/}
          <div>
            <label htmlFor="lawsuit_events">
              وقائع القضية ({initialValues.facts.length}/1000){' '}
            </label>
            <textarea
              maxLength={999}
              onChange={(event) => {
                this.setState({
                  initialValues: {
                    ...initialValues,
                    facts: event.target.value,
                  },
                });
              }}
              value={initialValues.facts}
              className={hasError && !initialValues.facts ? 'input-error' : ''}
              name="facts"
              id="lawsuit_events"
            >
              {initialValues.facts}
            </textarea>
          </div>
          <div>
            <label htmlFor="lawsuit_evidence">
              الادلة ({initialValues.evidence.length}/1000){' '}
            </label>
            <textarea
              maxLength={999}
              onChange={(event) => {
                this.setState({
                  initialValues: {
                    ...initialValues,
                    evidence: event.target.value,
                  },
                });
              }}
              value={initialValues.evidence}
              //className={hasError && !initialValues.evidence ? 'input-error' : ''}
              name="evidence"
              id="lawsuit_evidence"
            >
              {initialValues.evidence}
            </textarea>
          </div>
          <div>
            <label htmlFor="lawsuit_requests">
              الطلبات ({initialValues.requests.length}/1000)
            </label>
            <textarea
              maxLength={999}
              onChange={(event) => {
                this.setState({
                  initialValues: {
                    ...initialValues,
                    requests: event.target.value,
                  },
                });
              }}
              value={initialValues.requests}
              //className={hasError && !initialValues.requests ? 'input-error' : ''}
              name="requests"
              id="lawsuit_evidence"
            >
              {initialValues.requests}
            </textarea>
          </div>
          <div>
            <label htmlFor="lawsuit_requests">اضافة ملف</label>
            <div style={{ width: '100%' }} id="upload_form">
              <input
                accept=".pdf,image/*,.docx"
                onChange={(e) => {
                  this.onSelectFile(e);
                }}
                name="files"
                type="file"
                id="upload_button"
                hidden
                multiple
              />
              <label
                style={{ textAlign: 'center' }}
                id="upload_button_label"
                htmlFor="upload_button"
              >
                <svg
                  height="10"
                  viewBox="0 0 448 448"
                  width="10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m408 184h-136c-4.417969 0-8-3.582031-8-8v-136c0-22.089844-17.910156-40-40-40s-40 17.910156-40 40v136c0 4.417969-3.582031 8-8 8h-136c-22.089844 0-40 17.910156-40 40s17.910156 40 40 40h136c4.417969 0 8 3.582031 8 8v136c0 22.089844 17.910156 40 40 40s40-17.910156 40-40v-136c0-4.417969 3.582031-8 8-8h136c22.089844 0 40-17.910156 40-40s-17.910156-40-40-40zm0 0" />
                </svg>
              </label>
            </div>
          </div>
          {initialValues.lawsuit_files.length === 0 ? (
            ''
          ) : (
            <div style={{ display: 'block' }} className="upload_status">
              {initialValues.lawsuit_files &&
                initialValues.lawsuit_files.length > 0 &&
                initialValues.lawsuit_files.map((file) => {
                  return (
                    <div key={file.id} style={{ display: 'flex', marginBottom: '10px' }}>
                      <div
                        onClick={() => {
                          axios(`${host}${file.file}`, {
                            method: 'GET',
                            headers: {
                              Authorization: `JWT ${window.localStorage.getItem(
                                'token',
                              )}`,
                            },
                            responseType: 'blob', //Force to receive data in a Blob Format
                          })
                            .then((response) => {
                              //Create a Blob from the PDF Stream
                              let url;
                              if (file.file.split('.')[1] === 'pdf') {
                                let fileP = new Blob([response.data], {
                                  type: 'application/pdf',
                                });
                                url = URL.createObjectURL(fileP);

                                window.open(url, '_blank');
                              } else if (file.file.split('.')[1] === 'docx') {
                                var blob = new Blob([response.data], {
                                  type: 'application/octet-stream',
                                });

                                var link = document.createElement('a');
                                link.href = URL.createObjectURL(blob);
                                // set the name of the file
                                link.download = `${initialValues.number}.docx`;
                                // clicking the anchor element will download the file
                                link.click();
                              } else {
                                this.setState({
                                  urlImg: URL.createObjectURL(new Blob([response.data])),
                                  isOpenModal: true,
                                });
                              }
                            })
                            .catch((error) => {
                              console.log(error);
                            });
                        }}
                        style={{ cursor: 'pointer', width: '30%', color: 'blue' }}
                        className="uploaded_file__name"
                      >
                        {file.file_name}{' '}
                        {/* <a target="_blank" href={`${host}${file.file}`}>

                        </a>*/}
                      </div>
                      <button
                        onClick={() => this.handleActivePopupDeleteItem(file.id)}
                        type="button"
                        id="cancel_upload_button"
                      >
                        إلغاء
                      </button>
                    </div>
                  );
                })}
            </div>
          )}
          {initialValues.files.length === 0 ? (
            ''
          ) : (
            <div style={{ display: 'block' }} className="upload_status">
              {initialValues.files &&
                initialValues.files.length > 0 &&
                initialValues.files.map((file) => {
                  return (
                    <div key={file.id} style={{ display: 'flex', marginBottom: '10px' }}>
                      <div style={{ width: '30%' }} className="uploaded_file__name">
                        <a target="_blank" href={URL.createObjectURL(file.file)}>
                          {file.name}
                        </a>
                      </div>
                      <button
                        onClick={() => {
                          this.deleteFiles(file.id);
                          this.setState({
                            initialValues: {
                              ...initialValues,
                              percent: 0,
                            },
                          });
                        }}
                        type="button"
                        id="cancel_upload_button"
                      >
                        إلغاء
                      </button>
                    </div>
                  );
                })}
            </div>
          )}
          <span style={{ fontSize: '16px' }} className="ls_seperator">
            الأتعاب
          </span>
          <div>
            <label htmlFor="attr">الأتعاب</label>
            <InputField
              value={initialValues.fees}
              name="fees"
              placeholder="الأتعاب"
              type="text"
              hasError={hasError && initialValues.fees}
              validInput="integer"
              onChange={(event) => {
                this.setState({
                  initialValues: {
                    ...initialValues,
                    fees: event.target.value,
                    remaining_fees: initialValues.advance_fees
                      ? event.target.value - initialValues.advance_fees
                      : '',
                  },
                });
              }}
            />
          </div>
          <div>
            <label htmlFor="attr">تسبقة</label>
            <InputField
              value={initialValues.advance_fees}
              name="advance_fees"
              placeholder="تسبقة"
              type="text"
              hasError={hasError && initialValues.advance_fees}
              validInput="text"
              onChange={(event) => {
                this.setState({
                  initialValues: {
                    ...initialValues,
                    remaining_fees: initialValues.fees
                      ? initialValues.fees - event.target.value
                      : '',
                    advance_fees: event.target.value,
                  },
                });
              }}
            />
          </div>
          <div>
            <label htmlFor="attr">باقي الأتعاب</label>
            <InputField
              disabled={true}
              value={initialValues.remaining_fees > 0 ? initialValues.remaining_fees : 0}
              name="remaining_fees"
              placeholder="باقي الأتعاب"
              type="text"
              validInput="text"
            />
          </div>
        </div>
        <div className="lawsuit_form__btn_grp">
          <button
            onClick={() => this.props.goBack()}
            type="button"
            className="lawsuit_cancel_session"
          >
            <svg
              className="lawsuit_ltSpan"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              xlinkHref="http://svgjs.com/svgjs"
              version="1.1"
              width="12"
              height="12"
              x="0"
              y="0"
              viewBox="0 0 492.004 492.004"
              style={{ enableBackground: 'new 0 0 512 512' }}
              xmlSpace="preserve"
            >
              <g>
                <g xmlns="http://www.w3.org/2000/svg">
                  <g>
                    <path
                      d="M382.678,226.804L163.73,7.86C158.666,2.792,151.906,0,144.698,0s-13.968,2.792-19.032,7.86l-16.124,16.12    c-10.492,10.504-10.492,27.576,0,38.064L293.398,245.9l-184.06,184.06c-5.064,5.068-7.86,11.824-7.86,19.028    c0,7.212,2.796,13.968,7.86,19.04l16.124,16.116c5.068,5.068,11.824,7.86,19.032,7.86s13.968-2.792,19.032-7.86L382.678,265    c5.076-5.084,7.864-11.872,7.848-19.088C390.542,238.668,387.754,231.884,382.678,226.804z"
                      fill="#2d2d2d"
                      data-original="#000000"
                      className=""
                    />
                  </g>
                </g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
              </g>
            </svg>
            الغاء
          </button>
          <button
            disabled={isLoadingAddLawsuit}
            onClick={() => this.submitForm(initialValues)}
            type="button"
          >
            <svg
              className="tickSpan"
              xmlns="http://www.w3.org/2000/svg"
              width="20.125"
              height="17.774"
              viewBox="0 0 29.125 23.774"
            >
              <path
                id="Path_92"
                data-name="Path 92"
                d="M1325.354,167.5l8.34,9.625,17.8-19.417"
                transform="translate(-1323.842 -156.357)"
                fill="none"
                stroke="#f9f9f9"
                strokeWidth="4"
              />
            </svg>
            {isLoadingAddLawsuit ? (
              <CircularProgress
                style={{
                  textAlign: 'center',
                  width: '30px',
                  height: '30px',
                  color: 'white',
                }}
              />
            ) : (
              'تسجيل'
            )}
          </button>
        </div>
        <div className={openDeletePopupSession ? 'popup active' : 'popup'}>
          <div className="popup__title">أنت بصدد حذف الجلسة</div>
          <div className="popup__btn_grp">
            <button
              type="button"
              onClick={() => this.handleActiveDeletePopupSession()}
              className="cancel_popup"
            >
              <span>الغاء</span>
              <span>&lt;</span>
            </button>

            <button
              disabled={isLoadingDestroySession}
              type="button"
              onClick={() => {
                this.props.destroySession(selected_session_id, initialValues.id);
              }}
            >
              {isLoadingDestroySession ? (
                <CircularProgress
                  style={{
                    textAlign: 'center',
                    width: '30px',
                    height: '30px',
                    color: 'white',
                  }}
                />
              ) : (
                <span>تأكيد</span>
              )}
              <span className="trashSpan">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.906"
                  height="25.74"
                  viewBox="0 0 20.906 25.74"
                >
                  <g id="delete" transform="translate(0.003 0.002)">
                    <path
                      id="Path_82"
                      data-name="Path 82"
                      d="M223,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,223,154.7Zm0,0"
                      transform="translate(-208.995 -145.379)"
                      fill="#fff"
                    />
                    <path
                      id="Path_83"
                      data-name="Path 83"
                      d="M105,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,105,154.7Zm0,0"
                      transform="translate(-98.108 -145.379)"
                      fill="#fff"
                    />
                    <path
                      id="Path_84"
                      data-name="Path 84"
                      d="M1.709,7.662V22.514a3.326,3.326,0,0,0,.884,2.294,2.969,2.969,0,0,0,2.154.931H16.152a2.968,2.968,0,0,0,2.154-.931,3.326,3.326,0,0,0,.884-2.294V7.662A2.3,2.3,0,0,0,18.6,3.133H15.513V2.38A2.368,2.368,0,0,0,13.126,0H7.773A2.368,2.368,0,0,0,5.386,2.38v.754H2.3a2.3,2.3,0,0,0-.591,4.528ZM16.152,24.533H4.747a1.909,1.909,0,0,1-1.832-2.019V7.715h15.07v14.8a1.909,1.909,0,0,1-1.832,2.019ZM6.592,2.38A1.161,1.161,0,0,1,7.773,1.2h5.353A1.161,1.161,0,0,1,14.308,2.38v.754H6.592ZM2.3,4.339H18.6a1.085,1.085,0,0,1,0,2.17H2.3a1.085,1.085,0,0,1,0-2.17Zm0,0"
                      transform="translate(0 0)"
                      fill="#fff"
                    />
                    <path
                      id="Path_85"
                      data-name="Path 85"
                      d="M164,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,164,154.7Zm0,0"
                      transform="translate(-153.551 -145.379)"
                      fill="#fff"
                    />
                  </g>
                </svg>
              </span>
            </button>
          </div>
        </div>
        <div className={openDeletePopup ? 'popup active' : 'popup'}>
          <div className="popup__title">أنت بصدد حذف الملف</div>
          <div className="popup__btn_grp">
            <button
              type="button"
              onClick={() => this.handleActivePopupDeleteItem()}
              className="cancel_popup"
            >
              <span>الغاء</span>
              <span>&lt;</span>
            </button>
            <button
              type="button"
              onClick={() => {
                this.props.deleteFiles(initialValues.id, selected_id);
              }}
            >
              <span>تأكيد</span>
              <span className="trashSpan">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.906"
                  height="25.74"
                  viewBox="0 0 20.906 25.74"
                >
                  <g id="delete" transform="translate(0.003 0.002)">
                    <path
                      id="Path_82"
                      data-name="Path 82"
                      d="M223,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,223,154.7Zm0,0"
                      transform="translate(-208.995 -145.379)"
                      fill="#fff"
                    />
                    <path
                      id="Path_83"
                      data-name="Path 83"
                      d="M105,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,105,154.7Zm0,0"
                      transform="translate(-98.108 -145.379)"
                      fill="#fff"
                    />
                    <path
                      id="Path_84"
                      data-name="Path 84"
                      d="M1.709,7.662V22.514a3.326,3.326,0,0,0,.884,2.294,2.969,2.969,0,0,0,2.154.931H16.152a2.968,2.968,0,0,0,2.154-.931,3.326,3.326,0,0,0,.884-2.294V7.662A2.3,2.3,0,0,0,18.6,3.133H15.513V2.38A2.368,2.368,0,0,0,13.126,0H7.773A2.368,2.368,0,0,0,5.386,2.38v.754H2.3a2.3,2.3,0,0,0-.591,4.528ZM16.152,24.533H4.747a1.909,1.909,0,0,1-1.832-2.019V7.715h15.07v14.8a1.909,1.909,0,0,1-1.832,2.019ZM6.592,2.38A1.161,1.161,0,0,1,7.773,1.2h5.353A1.161,1.161,0,0,1,14.308,2.38v.754H6.592ZM2.3,4.339H18.6a1.085,1.085,0,0,1,0,2.17H2.3a1.085,1.085,0,0,1,0-2.17Zm0,0"
                      transform="translate(0 0)"
                      fill="#fff"
                    />
                    <path
                      id="Path_85"
                      data-name="Path 85"
                      d="M164,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,164,154.7Zm0,0"
                      transform="translate(-153.551 -145.379)"
                      fill="#fff"
                    />
                  </g>
                </svg>
              </span>
            </button>
          </div>
        </div>
        <div className={openAddPopup ? 'popup active' : 'popup'}>
          <div className="popup__title">أنت بصدد اضافة مآل</div>
          <div className="popup__item">
            <div className="popup__text">
              <div
                style={{ display: 'block', flexDirection: 'row' }}
                className="lawsuit_form__sections"
              >
                <div className="rv_form__sections-left">
                  <div
                    style={{
                      alignItems: 'center',
                    }}
                    className="jalaset_form__sections-left"
                  >
                    <div
                      style={{
                        width: '9rem',
                        marginLeft: 0,
                      }}
                      className="jalaset_form__label"
                      htmlFor="date_selected"
                    >
                      تاريخ الجلسة
                    </div>
                    <div className="jalaset_form__cal">
                      <DatePicker
                        selected={new Date(initialValues.date_session)}
                        locale="ar"
                        onChange={(date) => {
                          this.setState({
                            initialValues: {
                              ...initialValues,
                              date_session: date,
                            },
                          });
                        }}
                        customInput={
                          <svg
                            style={{
                              cursor: 'pointer',
                            }}
                            id="calendar"
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                          >
                            <g id="Group_28" data-name="Group 28">
                              <g id="Group_27" data-name="Group 27">
                                <ellipse
                                  id="Ellipse_20"
                                  data-name="Ellipse 20"
                                  cx="1"
                                  cy="1.5"
                                  rx="1"
                                  ry="1.5"
                                  transform="translate(22 11)"
                                  fill="#ca2946"
                                />
                                <path
                                  id="Path_89"
                                  data-name="Path 89"
                                  d="M25.312,2.344H23.789V1.172a1.172,1.172,0,0,0-2.344,0V2.344H16.113V1.172a1.172,1.172,0,0,0-2.344,0V2.344H8.5V1.172a1.172,1.172,0,1,0-2.344,0V2.344H4.688A4.693,4.693,0,0,0,0,7.031V25.312A4.693,4.693,0,0,0,4.688,30h8.965a1.172,1.172,0,0,0,0-2.344H4.688a2.346,2.346,0,0,1-2.344-2.344V7.031A2.346,2.346,0,0,1,4.688,4.688H6.152V5.859a1.172,1.172,0,0,0,2.344,0V4.688H13.77V5.859a1.172,1.172,0,0,0,2.344,0V4.688h5.332V5.859a1.172,1.172,0,0,0,2.344,0V4.688h1.523a2.346,2.346,0,0,1,2.344,2.344v6.68a1.172,1.172,0,0,0,2.344,0V7.031A4.693,4.693,0,0,0,25.312,2.344Z"
                                  fill="#ca2946"
                                />
                                <path
                                  id="Path_90"
                                  data-name="Path 90"
                                  d="M277.09,270a7.09,7.09,0,1,0,7.09,7.09A7.1,7.1,0,0,0,277.09,270Zm0,11.836a4.746,4.746,0,1,1,4.746-4.746A4.751,4.751,0,0,1,277.09,281.836Z"
                                  transform="translate(-254.18 -254.18)"
                                  fill="#ca2946"
                                />
                                <path
                                  id="Path_91"
                                  data-name="Path 91"
                                  d="M373.871,332.4h-.527v-1.23a1.172,1.172,0,0,0-2.344,0v2.4a1.172,1.172,0,0,0,1.172,1.172h1.7a1.172,1.172,0,0,0,0-2.344Z"
                                  transform="translate(-349.262 -310.664)"
                                  fill="#ca2946"
                                />
                                <circle
                                  id="Ellipse_21"
                                  data-name="Ellipse 21"
                                  cx="1.5"
                                  cy="1.5"
                                  r="1.5"
                                  transform="translate(16 11)"
                                  fill="#ca2946"
                                />
                                <circle
                                  id="Ellipse_22"
                                  data-name="Ellipse 22"
                                  cx="1.5"
                                  cy="1.5"
                                  r="1.5"
                                  transform="translate(11 16)"
                                  fill="#ca2946"
                                />
                                <ellipse
                                  id="Ellipse_23"
                                  data-name="Ellipse 23"
                                  cx="1"
                                  cy="1.5"
                                  rx="1"
                                  ry="1.5"
                                  transform="translate(6 11)"
                                  fill="#ca2946"
                                />
                                <ellipse
                                  id="Ellipse_24"
                                  data-name="Ellipse 24"
                                  cx="1"
                                  cy="1.5"
                                  rx="1"
                                  ry="1.5"
                                  transform="translate(6 16)"
                                  fill="#ca2946"
                                />
                                <circle
                                  id="Ellipse_25"
                                  data-name="Ellipse 25"
                                  cx="1"
                                  cy="1"
                                  r="1"
                                  transform="translate(6 22)"
                                  fill="#ca2946"
                                />
                                <ellipse
                                  id="Ellipse_26"
                                  data-name="Ellipse 26"
                                  cx="1.5"
                                  cy="1"
                                  rx="1.5"
                                  ry="1"
                                  transform="translate(11 22)"
                                  fill="#ca2946"
                                />
                                <circle
                                  id="Ellipse_27"
                                  data-name="Ellipse 27"
                                  cx="1.5"
                                  cy="1.5"
                                  r="1.5"
                                  transform="translate(11 11)"
                                  fill="#ca2946"
                                />
                              </g>
                            </g>
                          </svg>
                        }
                      />
                    </div>

                    <div className="jalaset_form__date_selected">
                      <div className="jalaset_form__date_selected__day">
                        {DaysArabic[moment(initialValues.date_session).format('dddd')]}
                      </div>
                      <div className="jalaset_form__date_selected__day">
                        {moment(initialValues.date_session).format('DD/MM/YYYY')}
                      </div>
                    </div>
                  </div>
                  <div className="rv_form__sections-left_field">
                    <div className="rv_form__label" htmlFor="date_selected">
                      توقيت الجلسة
                    </div>
                    <div className="rv_form__t">
                      <DatePicker
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        timeFormat="HH:mm"
                        selected={new Date(initialValues.date_session)}
                        locale="ar"
                        minTime={new Date(initialValues.date_session).setHours(
                          8,
                          0,
                          0,
                          0,
                        )}
                        maxTime={new Date(initialValues.date_session).setHours(
                          18,
                          0,
                          0,
                          0,
                        )}
                        onChange={(date) => {
                          this.setState({
                            initialValues: {
                              ...initialValues,
                              date_session: date,
                            },
                          });
                        }}
                        customInput={
                          <svg
                            className="rv_time_icon"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24.538"
                            height="24.538"
                            viewBox="0 0 24.538 24.538"
                          >
                            <g
                              id="clock_1_"
                              data-name="clock(1)"
                              transform="translate(0.5 0.5)"
                            >
                              <path
                                id="Path_103"
                                data-name="Path 103"
                                d="M11.769,0A11.769,11.769,0,1,0,23.538,11.769,11.782,11.782,0,0,0,11.769,0Zm0,22.067a10.3,10.3,0,1,1,10.3-10.3,10.309,10.309,0,0,1-10.3,10.3Z"
                                fill="#a8192b"
                                stroke="#a8192b"
                                strokeWidth="1"
                              />
                              <path
                                id="Path_104"
                                data-name="Path 104"
                                d="M209.265,83.118h-1.471v7.66l4.629,4.629,1.04-1.04-4.2-4.2Z"
                                transform="translate(-196.761 -78.705)"
                                fill="#a8192b"
                                stroke="#a8192b"
                                strokeWidth="1"
                              />
                            </g>
                          </svg>
                        }
                      />
                    </div>
                    <div className="rv_form__time_selected">
                      {moment(initialValues.date_session).format('HH:mm')}س -
                      {moment(initialValues.date_session)
                        .add(30, 'minutes')
                        .format('HH:mm')}
                      س
                    </div>
                  </div>
                </div>
                <div>
                  {hasErrorDateSession &&
                  initialValues.date_session &&
                  moment().isAfter(moment(initialValues.date_session)) ? (
                    <div className="rv_form__sections-left_field">
                      <div>
                        <svg
                          className="rv_danger_icon"
                          xmlns="http://www.w3.org/2000/svg"
                          width="27.077"
                          height="24.294"
                          viewBox="0 0 27.077 24.294"
                        >
                          <g id="alert" transform="translate(0 -26.315)">
                            <g
                              id="Group_39"
                              data-name="Group 39"
                              transform="translate(0 26.315)"
                            >
                              <g
                                id="Group_38"
                                data-name="Group 38"
                                transform="translate(0 0)"
                              >
                                <path
                                  id="Path_105"
                                  data-name="Path 105"
                                  d="M26.645,45.833,16.3,27.907a3.184,3.184,0,0,0-5.515,0L.431,45.833a3.184,3.184,0,0,0,2.757,4.776h20.7a3.184,3.184,0,0,0,2.757-4.776Zm-1.374,2.391a1.581,1.581,0,0,1-1.384.8H3.189a1.6,1.6,0,0,1-1.384-2.4L12.155,28.7a1.6,1.6,0,0,1,2.767,0l10.35,17.926A1.582,1.582,0,0,1,25.272,48.224Z"
                                  transform="translate(0 -26.315)"
                                  fill="#ff8000"
                                />
                              </g>
                            </g>
                            <g
                              id="Group_41"
                              data-name="Group 41"
                              transform="translate(12.745 34.232)"
                            >
                              <g
                                id="Group_40"
                                data-name="Group 40"
                                transform="translate(0 0)"
                              >
                                <rect
                                  id="Rectangle_145"
                                  data-name="Rectangle 145"
                                  width="1.586"
                                  height="7.932"
                                  fill="#ff8000"
                                />
                              </g>
                            </g>
                            <g
                              id="Group_43"
                              data-name="Group 43"
                              transform="translate(12.481 43.75)"
                            >
                              <g id="Group_42" data-name="Group 42">
                                <path
                                  id="Path_106"
                                  data-name="Path 106"
                                  d="M237.06,355.99a1.058,1.058,0,1,0,1.058,1.058A1.059,1.059,0,0,0,237.06,355.99Z"
                                  transform="translate(-236.002 -355.99)"
                                  fill="#ff8000"
                                />
                              </g>
                            </g>
                          </g>
                        </svg>
                      </div>
                      <div className="rv_err_msg">الرجاء تحديد تاريخ في المستقبل</div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div style={{ marginBottom: '4%', marginTop: '3%' }}>
                  <label htmlFor="court">المحكمة</label>
                  <Autocomplete
                    debug
                    id="debug"
                    options={courts}
                    getOptionLabel={(option) => option.title}
                    style={{ marginBottom: '4%' }}
                    onChange={(event, option) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          court_session: option != null ? option.title : '',
                        },
                      });
                    }}
                    defaultValue={{
                      title: initialValues.court_session,
                    }}
                    renderInput={(params) => (
                      <TextField
                        error={!initialValues.court_session && hasError}
                        name="court_session"
                        style={{ border: 0 }}
                        {...params}
                        //label="المحكمة"
                        //variant="outlined"
                      />
                    )}
                  />
                </div>
                <div>
                  <label htmlFor="attr">نوع الجلسة</label>
                  <select
                    className={hasError && !initialValues.type ? 'input-error' : ''}
                    onChange={(e) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          type: e.target.value === 'نوع الجلسة' ? null : e.target.value,
                        },
                      });
                    }}
                    value={initialValues.type}
                  >
                    <option value={null}>نوع الجلسة</option>
                    <option key={5} value={5}>
                      لجوابنا
                    </option>
                    <option key={1} value={1}>
                      لجواب الزميل
                    </option>
                    <option key={2} value={2}>
                      تحضيري
                    </option>
                    <option key={3} value={3}>
                      مرافعة
                    </option>
                    <option key={4} value={4}>
                      لتصريح بالحكم
                    </option>
                    <option key={6} value={6}>
                      جلسة جديدة
                    </option>
                  </select>
                </div>
                <div>
                  <label htmlFor="lawsuit_events">وصف </label>
                  <textarea
                    onChange={(event) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          description: event.target.value,
                        },
                      });
                    }}
                    value={initialValues.description}
                    className={
                      hasError && !initialValues.description ? 'input-error' : ''
                    }
                    name="facts"
                    placeholder="وصف"
                    id="lawsuit_events"
                  >
                    {initialValues.description}
                  </textarea>
                </div>
              </div>
            </div>
          </div>
          <div className="popup__btn_grp">
            <button
              type="button"
              onClick={() => this.handleActiveAddPopup()}
              className="cancel_popup"
            >
              <span>الغاء</span>
              <span>&lt;</span>
            </button>
            <button
              style={{
                background: '#63B17D',
              }}
              type="button"
              onClick={() => {
                this.submitFormSession(initialValues);
              }}
            >
              <span>تأكيد</span>
              <span className="trashSpan">
                <svg
                  className="tickSpan"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.125"
                  height="17.774"
                  viewBox="0 0 29.125 23.774"
                >
                  <path
                    id="Path_92"
                    data-name="Path 92"
                    d="M1325.354,167.5l8.34,9.625,17.8-19.417"
                    transform="translate(-1323.842 -156.357)"
                    fill="none"
                    stroke="#f9f9f9"
                    strokeWidth="4"
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>
        <div className={isOpenModal ? 'popup active' : 'popup'}>
          <div className="popup__title">{/*{appeal?.file_name}*/}</div>
          <div className="popup__item">
            <div style={{ width: '100%' }} className="popup__text">
              <div
                style={{ display: 'block', flexDirection: 'row' }}
                className="lawsuit_form__sections"
              >
                <img style={{ width: '100%', height: '500px' }} src={urlImg} />
              </div>
            </div>
          </div>
          <div className="popup__btn_grp">
            <button
              type="button"
              onClick={() => this.openModal()}
              className="cancel_popup"
            >
              <span>الغاء</span>
              <span>&lt;</span>
            </button>
          </div>
        </div>
        <div
          id="overlay"
          className={
            openDeletePopup || isOpenModal || openAddPopup || openDeletePopupSession
              ? 'active'
              : ''
          }
        ></div>
      </form>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form);
