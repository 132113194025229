import React, { Component } from 'react';
import {
  firstNumberCin,
  firstNumberPhone,
  isNumeric,
  lengthPhone,
  validate,
  validPassword,
} from '../utils/helpres';

class InputField extends Component {
  render() {
    const {
      hasError,
      value,
      onChange,
      required,
      disabled,
      validInput,
      type,
      name,
      placeholder,
      label,
      errorDate,
      className,
      width,
      onKeyPress,
    } = this.props;

    return (
      <input
        disabled={disabled}
        className={
          (hasError && !value) ||
          (hasError && !validate(value) && validInput === 'string') ||
          (value && !isNumeric(value) && validInput === 'integer') ||
          (value && required && required.type) ||
          (value && !firstNumberPhone(value) && validInput === 'tel') ||
          (value && !lengthPhone(value) && validInput === 'tel') ||
          (value && !isNumeric(value) && validInput === 'tel') ||
          /*(value && !lengthPhone(value) && validInput === 'cin') ||
          (value && !firstNumberCin(value) && validInput === 'cin') ||
          (value && !isNumeric(value) && validInput === 'cin') ||*/
          (value && !validPassword(value) && validInput === 'password') ||
          errorDate ||
          (hasError && !validate(value) && validInput === 'string')
            ? `input-error`
            : ''
        }
        onChange={onChange}
        onKeyPress={onKeyPress}
        value={value}
        name={name}
        placeholder={placeholder}
        type={type}
      />
    );
  }
}
export default InputField;
