import React, { Component } from 'react';
import ListTasks from './containers/lists';
import actions from '../settings/store/actions';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
const mapStateToProps = (state) => ({
  isLoadingSecretaries: state.SettingsReducer.isLoadingSecretaries,
  secretaries: state.SettingsReducer.secretaries,
  account: state.AppReducer.account,
  isLoadingLawyerTraineeShip: state.SettingsReducer.isLoadingLawyerTraineeShip,
  lawyerTraineeShip: state.SettingsReducer.lawyerTraineeShip,
});

const mapDispatchToProps = (dispatch) => ({
  getSecretaries: async () => {
    await dispatch(actions.getSecretaries());
  },
  getLawyerTraineeShip: () => {
    dispatch(actions.getLawyerTraineeShip(true));
  },
});
class Tasks extends Component {
  constructor(props) {
    super(props);
    props.getLawyerTraineeShip();
    props.getSecretaries();
    props.changeActiveItemSideBar(9, 'المهام');
    document.title = 'المهام';
  }
  render() {
    const { secretaries, account, lawyerTraineeShip } = this.props;
    let data = [...secretaries, ...lawyerTraineeShip];
    return (
      <div className="lj_main">
        <ListTasks
          changeActiveItemSideBar={this.props.changeActiveItemSideBar}
          changeNameHeader={this.props.changeNameHeader}
          account={account}
          secretaries={data}
        />
      </div>
    );
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tasks));
