import React, { Component } from 'react';
import { CircularProgress } from '@material-ui/core';
import actions from '../store/actions';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';
import { getPricePack } from '../../../utils/helpres';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Link } from 'react-router-dom';
import { packs } from '../../../utils/constants';
import EmptyData from '../../../assets/img/undraw_design_team_af2y.svg';

const mapStateToProps = (state) => ({
  isLoadingPayments: state.SettingsReducer.isLoadingPayments,
  payments: state.SettingsReducer.payments,
  updatePayment: state.SettingsReducer.updatePayment,
  isLoadingUpdatePayment: state.SettingsReducer.isLoadingUpdatePayment,
});

const mapDispatchToProps = (dispatch) => ({
  getPayments: () => dispatch(actions.getPayments()),
  submitPaymentForm: async (values) => {
    await dispatch(actions.submitPaymentForm(values));
    dispatch(actions.getPayments());
  },
});
class Payments extends Component {
  constructor(props) {
    super(props);
    document.title = 'الاشتركات';
    props.changeActiveItemSideBar(10, 'الاشتركات');
    props.getPayments();
    this.state = {
      openDeletePopup: false,
      showBackground: false,
      hasError: false,
      selected_id: null,
      selected_name: '',
      selected_type: '',
      initialValues: {
        id: null,
        mode: '',
        file: null,
        percent: 0,
        price: 0,
      },
    };
    this.handleSelectedId = this.handleSelectedId.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.increase = this.increase.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
  }
  increase() {
    const { initialValues } = this.state;
    const newPercent = initialValues.percent + 1;
    if (newPercent >= 100) {
      clearTimeout(this.tm);
      return;
    }
    this.setState({
      initialValues: {
        ...initialValues,
        percent: newPercent,
      },
    });
    this.tm = setTimeout(this.increase, 20);
  }
  onConfirm() {
    const { initialValues } = this.state;
    this.setState({
      initialValues: {
        ...initialValues,
        percent: -1,
      },
    });
  }

  handleSelectedId(id, price, type) {
    this.setState({
      openDeletePopup: true,
      showBackground: true,
      selected_id: id,
      price,
      selected_type: type,
      initialValues: {
        ...this.state.initialValues,
        id,
        price,
      },
    });
  }
  closeModal() {
    this.setState({
      openDeletePopup: false,
      showBackground: false,
      selected_id: null,
      price: null,
      initialValues: {
        id: null,
        mode: '',
        file: null,
        price: '',
        percent: 0,
      },
    });
  }
  handleSubmit(values) {
    if (!values.file || !values.mode) {
      this.setState({
        hasError: true,
      });
    } else {
      this.setState({
        hasError: false,
      });
      this.props.submitPaymentForm(values);
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { updatePayment } = this.props;
    if (updatePayment !== prevProps.updatePayment && updatePayment) {
      this.closeModal();
    }
  }

  render() {
    const { payments, isLoadingUpdatePayment, account, isLoadingPayments } = this.props;
    const { openDeletePopup, initialValues, hasError, price } = this.state;
    return (
      <div style={{ display: 'block' }} className="params_col-left">
        {isLoadingPayments ? (
          <CircularProgress
            style={{
              textAlign: 'center',
              width: '30px',
              height: '30px',
              marginTop: '20%',
              marginRight: '50%',
            }}
          />
        ) : (
          <>
            <div className="params_table_section__btn_grp">
              <button className="params_add_button">{packs[account.pack]}</button>
            </div>
            <div className="">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  marginTop: '5%',
                }}
              >
                <div
                  style={{
                    width: '33%',
                  }}
                  className="membership-info-elem"
                >
                  <div className="membership-info-elem-title">
                    <span style={{ color: '#ca2946' }}>اشتراك 3 اشهر</span>
                  </div>
                  <div
                    style={{
                      border: '1px solid rgba(0, 115, 255, 0.06)',
                      borderRadius: '10px',
                      backgroundColor: 'white',
                      padding: '10px',
                      cursor: 'pointer',
                    }}
                    className="membership-info-elem-desc"
                  >
                    <span>
                      {' '}
                      <strong style={{ fontSize: '20px' }}>
                        {getPricePack(
                          (account.pack && account.pack) ||
                            (account.lawyer && account.lawyer.pack),
                          'monthly',
                        )}{' '}
                      </strong>
                      دينار{' '}
                    </span>
                  </div>
                </div>
                <div
                  style={{ width: '33%', marginBottom: '3%' }}
                  className="membership-info-elem"
                >
                  <div className="membership-info-elem-title">
                    <span style={{ color: '#ca2946' }}>اشتراك سنوي</span>
                  </div>
                  <div
                    style={{
                      border: '1px solid rgba(0, 115, 255, 0.06)',
                      borderRadius: '10px',
                      backgroundColor: 'white',
                      padding: '10px',
                      cursor: 'pointer',
                    }}
                    className="membership-info-elem-desc"
                  >
                    <span>
                      {' '}
                      <strong style={{ fontSize: '20px' }}>
                        {getPricePack(
                          (account.pack && account.pack) ||
                            (account.lawyer && account.lawyer.pack),
                          'annual',
                        )}{' '}
                      </strong>
                      دينار{' '}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="reports__judgements_table">
              <div style={{ display: 'block' }} className="params_table reports_table">
                <table>
                  <thead>
                    <tr>
                      <th style={{ fontSize: '13px' }}>المرجع</th>
                      <th style={{ fontSize: '13px' }}>الثمن</th>
                      <th style={{ fontSize: '13px' }}>التاريخ</th>
                      <th style={{ fontSize: '13px' }}>الآجال</th>
                      <th style={{ fontSize: '13px' }}>الحالة</th>
                      <th style={{ fontSize: '13px' }}>الإعدادت</th>
                    </tr>
                  </thead>
                  <tbody>
                    {payments && payments.length > 0
                      ? payments.map((payment) => {
                          return (
                            <tr key={payment.id}>
                              <td>{payment.number}</td>
                              <td>{parseFloat(payment.price).toFixed(2)} دينار</td>
                              <td>{moment(payment.date).format('YYYY/MM')}</td>
                              <td>{moment(payment.date_end).format('YYYY/MM/DD')}</td>
                              <td>
                                {payment.status === 'not_payed'
                                  ? 'فاتورة غير مدفوعة'
                                  : payment.status === 'payed'
                                  ? 'فاتورة مدفوعة'
                                  : 'فاتورة في عملية التحقق'}
                              </td>
                              <td>
                                {payment.status === 'payed' ? (
                                  <Link
                                    to={{
                                      pathname: '/generate-pdf-payment',
                                      state: {
                                        payment: payment,
                                        account: account,
                                      },
                                    }}
                                  >
                                    <div
                                      title="إظهار البيانات"
                                      style={{
                                        padding: '4px',
                                        marginTop: '-50%',
                                      }}
                                    >
                                      <ArrowDownwardIcon
                                        className="lawsuit_center_svg"
                                        style={{
                                          background: 'rgb(202, 41, 70)',
                                          color: 'white',
                                          borderRadius: '3px',
                                          width: '25px',
                                          height: '25px',
                                        }}
                                      />
                                    </div>
                                  </Link>
                                ) : (
                                  ''
                                )}
                                {payment.status === 'not_payed' ? (
                                  <>
                                    <div>
                                      <svg
                                        style={{ marginTop: '-2rem' }}
                                        onClick={() =>
                                          this.handleSelectedId(
                                            payment.id,
                                            payment.price,
                                            'edit',
                                          )
                                        }
                                        className="params_center_svg"
                                        id="Group_23"
                                        data-name="Group 23"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="23.304"
                                        height="24.288"
                                        viewBox="0 0 23.304 24.288"
                                      >
                                        <g id="Group_22" data-name="Group 22">
                                          <path
                                            id="Path_86"
                                            data-name="Path 86"
                                            d="M26.636,19.431h.131L33.1,18.053a.623.623,0,0,0,.328-.164L44.163,7.156A4.136,4.136,0,0,0,45.377,4.2a4.267,4.267,0,0,0-1.214-2.987A4.135,4.135,0,0,0,41.209,0a4.037,4.037,0,0,0-2.954,1.247l-10.7,10.733a.591.591,0,0,0-.2.3l-1.378,6.335a.722.722,0,0,0,.2.624A.656.656,0,0,0,26.636,19.431ZM41.209,1.314a2.855,2.855,0,0,1,2.856,2.855q0,.016,0,.033a2.757,2.757,0,0,1-.821,2l-4.037-4.07A2.823,2.823,0,0,1,41.209,1.314ZM38.287,3.086l4.037,4.037L32.97,16.444l-4.037-4ZM28.408,13.753,31.657,17l-4.168.919Z"
                                            transform="translate(-24.83 0)"
                                            fill="#ca2946"
                                          />
                                          <path
                                            id="Path_87"
                                            data-name="Path 87"
                                            d="M30.485,365.717H8.494a.656.656,0,0,0,0,1.313H30.485a.656.656,0,0,0,0-1.313Z"
                                            transform="translate(-7.838 -342.742)"
                                            fill="#ca2946"
                                          />
                                        </g>
                                      </svg>
                                    </div>
                                  </>
                                ) : (
                                  ''
                                )}
                              </td>
                            </tr>
                          );
                        })
                      : ''}
                  </tbody>
                </table>
                {payments && payments.length === 0 ? (
                  <div
                    className="clients_col-left"
                    style={{
                      justifyContent: 'center',
                      gap: '10%',
                      marginTop: '8%',
                      display: 'grid',
                    }}
                  >
                    <img src={EmptyData} style={{ textAlign: 'center' }} />
                    <span style={{ marginRight: '33%', fontSize: '15px' }}>
                      لا يوجد سجل متاح
                    </span>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </>
        )}
        <div className={openDeletePopup ? 'popup active' : 'popup'}>
          <div className="popup__title">أنت بصدد تدفع الفاتورة</div>
          <div className="popup__item">
            <div style={{ width: '100%', flexDirection: 'row' }} className="popup__text">
              <label htmlFor="attr">الثمن</label>
              <label style={{ marginRight: '10%' }} htmlFor="attr">
                {parseFloat(initialValues.price).toFixed(2) || 0} دينار
              </label>
            </div>
          </div>
          <div className="popup__item">
            <div style={{ width: '100%', flexDirection: 'row' }} className="popup__text">
              <label htmlFor="attr">الاشتراك</label>
              <select
                style={{ width: '60%', marginRight: '5%', borderRadius: '5px' }}
                className={hasError && !initialValues.mode ? 'input-error' : ''}
                onChange={(e) => {
                  this.setState({
                    //price: getPricePack(account.pack, e.target.value),
                    initialValues: {
                      ...initialValues,
                      mode: e.target.value,
                      price: getPricePack(
                        (account.pack && account.pack) ||
                          (account.lawyer && account.lawyer.pack),
                        e.target.value,
                      ),
                    },
                  });
                }}
                value={initialValues.mode}
              >
                <option value="">اشتراك</option>
                <option value="monthly">اشتراك 3 اشهر</option>
                <option value="annual">اشتراك سنوي</option>
              </select>
            </div>
          </div>
          <div className="popup__item">
            <div style={{ width: '100%' }} className="popup__text">
              <div
                style={{ width: parseInt(initialValues.share) === 2 ? '50%' : '100%' }}
                id="upload_form"
              >
                <input
                  accept=".png,.jpg"
                  onChange={(e) => {
                    this.increase();
                    this.setState({
                      initialValues: {
                        ...initialValues,
                        file: e.target.files[0],
                        name: e.target.files[0].name,
                        //percent: 0,
                      },
                    });
                  }}
                  name="file"
                  type="file"
                  id="upload_button"
                  hidden
                />
                <label
                  style={{ width: '80%' }}
                  id="upload_button_label"
                  htmlFor="upload_button"
                >
                  <svg
                    height="10"
                    viewBox="0 0 448 448"
                    width="10"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m408 184h-136c-4.417969 0-8-3.582031-8-8v-136c0-22.089844-17.910156-40-40-40s-40 17.910156-40 40v136c0 4.417969-3.582031 8-8 8h-136c-22.089844 0-40 17.910156-40 40s17.910156 40 40 40h136c4.417969 0 8 3.582031 8 8v136c0 22.089844 17.910156 40 40 40s40-17.910156 40-40v-136c0-4.417969 3.582031-8 8-8h136c22.089844 0 40-17.910156 40-40s-17.910156-40-40-40zm0 0" />
                  </svg>
                  اضافة تبرير الدفع للبنك
                </label>
                <br />
                {hasError && !initialValues.file ? (
                  <span style={{ color: 'rgb(189, 35, 60) ' }}>ملف مهم</span>
                ) : (
                  ''
                )}
              </div>
              <div style={{ display: 'flex' }} className="upload_status">
                {!initialValues.file ? (
                  ''
                ) : (
                  <>
                    <div className="uploaded_file__name">{initialValues.name}</div>
                    <div id="progressBar">
                      <div
                        style={{ width: `${initialValues.percent + 1}%` }}
                        id="progressBarFill"
                      ></div>
                    </div>
                    <div className="upload_percenctage">{initialValues.percent + 1}%</div>
                    <br />
                    <button
                      onClick={() =>
                        this.setState({
                          initialValues: {
                            ...initialValues,
                            percent: 0,
                            file: null,
                            name: '',
                          },
                        })
                      }
                      type="button"
                      id="cancel_upload_button"
                    >
                      إلغاء
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="popup__btn_grp">
            <button onClick={() => this.closeModal()} className="cancel_popup">
              <span>الغاء</span>
              <span>&lt;</span>
            </button>
            <button
              style={{
                background: '#63B17D',
              }}
              disabled={
                initialValues.percent >= 99 || isLoadingUpdatePayment ? false : true
              }
              onClick={() => {
                if (!isLoadingUpdatePayment) this.handleSubmit(initialValues);
              }}
            >
              <span>تأكيد</span>
              <span className="trashSpan">
                <svg
                  className="tickSpan"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.125"
                  height="17.774"
                  viewBox="0 0 29.125 23.774"
                >
                  <path
                    id="Path_92"
                    data-name="Path 92"
                    d="M1325.354,167.5l8.34,9.625,17.8-19.417"
                    transform="translate(-1323.842 -156.357)"
                    fill="none"
                    stroke="#f9f9f9"
                    strokeWidth="4"
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>
        <div id="overlay" className={openDeletePopup ? 'active' : ''}></div>
      </div>
    );
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Payments));
