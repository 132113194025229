import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import CountUp from 'react-countup';

import { Link } from 'react-router-dom';
import HomeUrl from '../assets/images/home-border.png';

export default class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services: [
        {
          id: 'service1',
          icon: (
            <svg
              className="home_menu_svg"
              id="calendar"
              xmlns="http://www.w3.org/2000/svg"
              width="28.5"
              height="25.501"
              viewBox="0 0 36.5 36.5"
            >
              <g id="Group_11" data-name="Group 11">
                <g id="Group_10" data-name="Group 10">
                  <ellipse
                    data-name="Ellipse 2"
                    cx="1"
                    cy="1.5"
                    rx="1"
                    ry="1.5"
                    transform="translate(26.5 13.5)"
                    fill="#ca2946"
                  ></ellipse>
                  <path
                    data-name="Path 43"
                    d="M30.8,2.852H28.943V1.426a1.426,1.426,0,0,0-2.852,0V2.852H19.6V1.426a1.426,1.426,0,0,0-2.852,0V2.852H10.337V1.426a1.426,1.426,0,1,0-2.852,0V2.852H5.7A5.71,5.71,0,0,0,0,8.555V30.8a5.71,5.71,0,0,0,5.7,5.7H16.61a1.426,1.426,0,0,0,0-2.852H5.7A2.855,2.855,0,0,1,2.852,30.8V8.555A2.855,2.855,0,0,1,5.7,5.7H7.485V7.129a1.426,1.426,0,1,0,2.852,0V5.7h6.416V7.129a1.426,1.426,0,0,0,2.852,0V5.7h6.487V7.129a1.426,1.426,0,0,0,2.852,0V5.7H30.8a2.855,2.855,0,0,1,2.852,2.852v8.127a1.426,1.426,0,0,0,2.852,0V8.555A5.71,5.71,0,0,0,30.8,2.852Z"
                    fill="#ca2946"
                  ></path>
                  <path
                    data-name="Path 44"
                    d="M278.626,270a8.626,8.626,0,1,0,8.626,8.626A8.636,8.636,0,0,0,278.626,270Zm0,14.4a5.774,5.774,0,1,1,5.774-5.774A5.781,5.781,0,0,1,278.626,284.4Z"
                    transform="translate(-250.752 -250.752)"
                    fill="#ca2946"
                  ></path>
                  <path
                    data-name="Path 45"
                    d="M374.493,332.923h-.642v-1.5a1.426,1.426,0,0,0-2.852,0v2.923a1.426,1.426,0,0,0,1.426,1.426h2.067a1.426,1.426,0,0,0,0-2.852Z"
                    transform="translate(-344.552 -306.475)"
                    fill="#ca2946"
                  ></path>
                  <circle
                    data-name="Ellipse 3"
                    cx="1.5"
                    cy="1.5"
                    r="1.5"
                    transform="translate(19.5 13.5)"
                    fill="#ca2946"
                  ></circle>
                  <circle
                    data-name="Ellipse 4"
                    cx="1.5"
                    cy="1.5"
                    r="1.5"
                    transform="translate(13.5 19.5)"
                    fill="#ca2946"
                  ></circle>
                  <circle
                    data-name="Ellipse 5"
                    cx="1.5"
                    cy="1.5"
                    r="1.5"
                    transform="translate(7.5 13.5)"
                    fill="#ca2946"
                  ></circle>
                  <circle
                    data-name="Ellipse 6"
                    cx="1.5"
                    cy="1.5"
                    r="1.5"
                    transform="translate(7.5 19.5)"
                    fill="#ca2946"
                  ></circle>
                  <circle
                    data-name="Ellipse 7"
                    cx="1.5"
                    cy="1.5"
                    r="1.5"
                    transform="translate(7.5 25.5)"
                    fill="#ca2946"
                  ></circle>
                  <circle
                    data-name="Ellipse 8"
                    cx="1.5"
                    cy="1.5"
                    r="1.5"
                    transform="translate(13.5 25.5)"
                    fill="#ca2946"
                  ></circle>
                  <circle
                    data-name="Ellipse 9"
                    cx="1.5"
                    cy="1.5"
                    r="1.5"
                    transform="translate(13.5 13.5)"
                    fill="#ca2946"
                  ></circle>
                </g>
              </g>
            </svg>
          ),
          title: 'المواعيد',
          description: 'للتذكير بكافة انواع المواعيد اصافة و تعديل المعلومات الخاصة بها.',
        },
        {
          id: 'service2',
          icon: (
            <svg
              className="side_menu_svg"
              xmlns="http://www.w3.org/2000/svg"
              width="30.165"
              height="30.477"
              viewBox="0 0 46.165 46.477"
            >
              <g id="judgement" transform="translate(-1.252 0.5)">
                <path
                  data-name="Path 79"
                  d="M46.252,7.27a.666.666,0,0,0-.666.666v6.152A3.192,3.192,0,0,1,42.4,17.276H31.2a.666.666,0,0,0-.573.327l-1.051,1.775L28.524,17.6a.666.666,0,0,0-.573-.327,8.472,8.472,0,0,1-2.371-.13v-.825A6.948,6.948,0,0,0,23.3,6.443V4.52a3.192,3.192,0,0,1,3.189-3.188H42.4A3.192,3.192,0,0,1,45.585,4.52a.666.666,0,0,0,1.332,0A4.526,4.526,0,0,0,42.4,0H26.486a4.526,4.526,0,0,0-4.521,4.52V5.845a6.976,6.976,0,0,0-8.009,10.476v1.343a5.681,5.681,0,0,0,.841,2.981,6.949,6.949,0,0,0-4.024,6.324v3.274H2.4a.666.666,0,0,0,0,1.332H4.252l1.134,6.242a2.027,2.027,0,0,0,2,1.665h1.3c0,1.753,0,3.549-.033,5.316a.666.666,0,0,0,1.332.022c.03-1.778.033-3.58.033-5.338h1.642a.666.666,0,0,0,0-1.332q-2.136,0-4.272,0A.7.7,0,0,1,6.7,37.58l-1.091-6H33.929l-1.091,6a.7.7,0,0,1-.685.571H15.2a.666.666,0,0,0,0,1.332H29.556c0,1.8,0,3.551,0,5.328a.666.666,0,0,0,1.332,0c0-1.777,0-3.526,0-5.326h1.265a2.027,2.027,0,0,0,2-1.666l1.134-6.242h1.854a.666.666,0,0,0,0-1.332H33.885V28.307a.666.666,0,0,0-.666-.666H32.043V26.194h5.094a.666.666,0,0,0,0-1.332H32.043V22.749a.666.666,0,0,0-.666-.666H27.949a.666.666,0,0,0-.662.589,6.957,6.957,0,0,0-2.549-2.026,5.681,5.681,0,0,0,.78-2.142,9.629,9.629,0,0,0,2.053.1L29,21.025a.672.672,0,0,0,1.147,0l1.43-2.417H42.4a4.526,4.526,0,0,0,4.521-4.52V7.936a.666.666,0,0,0-.666-.666ZM19.768,6.828a5.653,5.653,0,0,1,5.509,6.961,2.309,2.309,0,0,0-2-1.157H16.262a2.31,2.31,0,0,0-2,1.159,5.653,5.653,0,0,1,5.509-6.963Zm0,19.15-1.625-2.789a5.932,5.932,0,0,0,3.251,0Zm.013-3.9a4.448,4.448,0,0,1-4.493-4.418V14.919a.967.967,0,0,1,.973-.955h7.012a.966.966,0,0,1,.973.957v2.743a4.447,4.447,0,0,1-4.466,4.418ZM12.106,26.97a5.623,5.623,0,0,1,3.548-5.243s2.39,3.939,3.434,5.731v2.785H12.106Zm20.446,3.274H26.773v-1.27h5.779Zm-3.937-6.829h2.1v4.226h-2.1Zm-1.333,2.268v1.958H26.107a.666.666,0,0,0-.666.666v1.936h-5.02V27.5c1.052-1.805,3.46-5.776,3.46-5.776a5.623,5.623,0,0,1,3.4,3.956Z"
                  fill="#ca2946"
                  stroke="#ca2946"
                  strokeWidth="1"
                ></path>
                <path
                  data-name="Path 80"
                  d="M300.895,36.819a.666.666,0,0,0-.666-.666H294.12v-.863a.666.666,0,0,0-1.332,0v.863h-6.108a.666.666,0,1,0,0,1.332h1.477l-1.095,2.869a2.207,2.207,0,1,0,4.124,0l-1.095-2.869h2.7v7.764h-2.949a.666.666,0,1,0,0,1.332h7.231a.666.666,0,1,0,0-1.332H294.12V37.485h2.7l-1.095,2.869a2.207,2.207,0,1,0,4.124,0l-1.095-2.869h1.476a.666.666,0,0,0,.666-.666Zm-11.052,4.818a.876.876,0,0,1-1.537-.808l.817-2.141.817,2.141A.863.863,0,0,1,289.843,41.636Zm8.662,0a.876.876,0,0,1-1.537-.808l.817-2.141.817,2.141A.863.863,0,0,1,298.505,41.636Z"
                  transform="translate(-259.012 -31.548)"
                  fill="#ca2946"
                  stroke="#ca2946"
                  strokeWidth="1"
                ></path>
              </g>
            </svg>
          ),
          title: 'القضايا',
          description: (
            <p>
              الاطلاع, اضافة و تعديل المعلومات الخاصة بالقضايا.
              <br /> ارسال القضايا الى المحكمة عبر البريد الالكتروني.
            </p>
          ),
        },
        {
          id: 'service3',
          icon: (
            <svg
              className="home_menu_svg"
              xmlns="http://www.w3.org/2000/svg"
              width="28.5"
              height="25.501"
              viewBox="0 0 38.129 38.129"
            >
              <g id="paper" transform="translate(-1.5 -1.5)">
                <path
                  data-name="Path 122"
                  d="M16.574,22.156H2.53a.53.53,0,0,1-.53-.53V2.53A.53.53,0,0,1,2.53,2h9.551a.53.53,0,0,1,.375.155l4.493,4.49a.53.53,0,0,1,.155.375v14.6A.53.53,0,0,1,16.574,22.156ZM3.061,21.095H16.043V7.24l-4.182-4.18h-8.8Z"
                  fill="#ca2946"
                  stroke="#ca2946"
                  strokeWidth="1"
                ></path>
                <path
                  data-name="Path 123"
                  d="M25.029,7.557H20.536a.53.53,0,0,1-.53-.53V2.53a.53.53,0,1,1,1.061,0V6.5h3.962a.53.53,0,0,1,0,1.061Z"
                  transform="translate(-8.455 0)"
                  fill="#ca2946"
                  stroke="#ca2946"
                  strokeWidth="1"
                ></path>
                <path
                  data-name="Path 124"
                  d="M16.315,31.482H6.77a.53.53,0,1,1,0-1.061h9.545a.53.53,0,1,1,0,1.061Z"
                  transform="translate(-1.991 -13.346)"
                  fill="#ca2946"
                  stroke="#ca2946"
                  strokeWidth="1"
                ></path>
                <path
                  data-name="Path 125"
                  d="M16.315,24.535H6.77a.53.53,0,1,1,0-1.061h9.545a.53.53,0,1,1,0,1.061Z"
                  transform="translate(-1.991 -10.084)"
                  fill="#ca2946"
                  stroke="#ca2946"
                  strokeWidth="1"
                ></path>
                <path
                  data-name="Path 126"
                  d="M16.315,17.587H6.77a.53.53,0,1,1,0-1.061h9.545a.53.53,0,1,1,0,1.061Z"
                  transform="translate(-1.991 -6.821)"
                  fill="#ca2946"
                  stroke="#ca2946"
                  strokeWidth="1"
                ></path>
                <path
                  data-name="Path 127"
                  d="M11.826,10.64H6.77a.53.53,0,1,1,0-1.061h5.055a.53.53,0,1,1,0,1.061Z"
                  transform="translate(-1.991 -3.559)"
                  fill="#ca2946"
                  stroke="#ca2946"
                  strokeWidth="1"
                ></path>
                <path
                  data-name="Path 128"
                  d="M58.1,54.156H44.054a.53.53,0,0,1-.53-.53V34.53a.53.53,0,0,1,.53-.53H53.6a.53.53,0,0,1,.375.155l4.493,4.49a.53.53,0,0,1,.155.375v14.6A.53.53,0,0,1,58.1,54.156ZM44.585,53.095H57.567V39.24l-4.182-4.18h-8.8Z"
                  transform="translate(-19.499 -15.027)"
                  fill="#ca2946"
                  stroke="#ca2946"
                  strokeWidth="1"
                ></path>
                <path
                  data-name="Path 129"
                  d="M66.553,39.557H62.059a.53.53,0,0,1-.53-.53v-4.5a.53.53,0,1,1,1.061,0V38.5h3.963a.53.53,0,1,1,0,1.061Z"
                  transform="translate(-27.954 -15.027)"
                  fill="#ca2946"
                  stroke="#ca2946"
                  strokeWidth="1"
                ></path>
                <path
                  data-name="Path 130"
                  d="M57.838,63.482H48.29a.53.53,0,1,1,0-1.061h9.547a.53.53,0,1,1,0,1.061Z"
                  transform="translate(-21.488 -28.373)"
                  fill="#ca2946"
                  stroke="#ca2946"
                  strokeWidth="1"
                ></path>
                <path
                  data-name="Path 131"
                  d="M57.838,56.535H48.29a.53.53,0,1,1,0-1.061h9.547a.53.53,0,1,1,0,1.061Z"
                  transform="translate(-21.488 -25.111)"
                  fill="#ca2946"
                  stroke="#ca2946"
                  strokeWidth="1"
                ></path>
                <path
                  data-name="Path 132"
                  d="M57.838,49.587H48.29a.53.53,0,1,1,0-1.061h9.547a.53.53,0,1,1,0,1.061Z"
                  transform="translate(-21.488 -21.848)"
                  fill="#ca2946"
                  stroke="#ca2946"
                  strokeWidth="1"
                ></path>
                <path
                  data-name="Path 133"
                  d="M53.355,42.64H48.3a.53.53,0,0,1,0-1.061h5.055a.53.53,0,0,1,0,1.061Z"
                  transform="translate(-21.493 -18.586)"
                  fill="#ca2946"
                  stroke="#ca2946"
                  strokeWidth="1"
                ></path>
                <path
                  data-name="Path 134"
                  d="M52.737,18.7a.53.53,0,0,1-.53-.53V11.211H44.78a.53.53,0,1,1,0-1.061h7.956a.53.53,0,0,1,.53.53v7.488A.53.53,0,0,1,52.737,18.7Z"
                  transform="translate(-19.84 -3.827)"
                  fill="#ca2946"
                  stroke="#ca2946"
                  strokeWidth="1"
                ></path>
                <path
                  data-name="Path 135"
                  d="M46.516,11.41a.53.53,0,0,1-.375-.155L44.406,9.519a.53.53,0,0,1,0-.75l1.736-1.736a.53.53,0,1,1,.75.75l-1.361,1.36L46.891,10.5a.53.53,0,0,1-.375.905Z"
                  transform="translate(-19.84 -2.291)"
                  fill="#ca2946"
                  stroke="#ca2946"
                  strokeWidth="1"
                ></path>
                <path
                  data-name="Path 136"
                  d="M21.237,56.281H13.28a.53.53,0,0,1-.53-.53V48.262a.53.53,0,0,1,1.061,0V55.22h7.426a.53.53,0,0,1,0,1.061Z"
                  transform="translate(-5.048 -21.475)"
                  fill="#ca2946"
                  stroke="#ca2946"
                  strokeWidth="1"
                ></path>
                <path
                  data-name="Path 137"
                  d="M25.009,63.11a.53.53,0,0,1-.376-.905l1.361-1.361-1.361-1.361a.531.531,0,1,1,.751-.75l1.735,1.736a.53.53,0,0,1,0,.75l-1.735,1.736A.53.53,0,0,1,25.009,63.11Z"
                  transform="translate(-10.555 -26.568)"
                  fill="#ca2946"
                  stroke="#ca2946"
                  strokeWidth="1"
                ></path>
              </g>
            </svg>
          ),
          title: 'تبادل التقارير',
          description: (
            <p>
              تخول للمستخدم تبادل التقارير مع زملائه و الاطلاع عليها.
              <br /> ارسال التقارير الى المحكمة عبر البريد الالكتروني.
            </p>
          ),
        },
      ],
      counters: [
        {
          id: '1',
          extraclass: '',
          start: 25,
          end: 49,
          title: 'أعمال الحرفاء',
          description: `العقود والاستدعاءات والتقارير والاعتراضات. وحدة جدول الأعمال وإدارة التعيينات والغيابات والبعثات. المحاسبة والرسائل.`,
        },
        {
          id: '2',
          extraclass: 'pt-3',
          start: 25,
          end: 76,
          title: 'حل متعدد الحسابات',
          description:
            'رئيس الموظفين والسكرتير والمحامين المتعاونين.وإدارة الاعمال المسموح بها',
        },
        {
          id: '3',
          extraclass: 'pt-3',
          start: 25,
          end: 99,
          title: 'الاتصال بين المهنيين',
          description: ' نقل ملفات الحرفاء بين الزملاء. التواصل عبر lavocato messenger',
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section bg-services" id="services">
          <Container>
            <Row>
              <Col lg="12">
                <div className="title-box text-center">
                  <h3 className="title-heading mt-4">نبذة عن الخدمات</h3>
                  {/*<p className="text-muted f-17 mt-3">
                    Vivamus ac nulla ultrices laoreet neque mollis mi morbi elementum
                    mauris sit amet arcu <br /> fringilla auctor In eleifend maximus nisi
                    sed vulputate.
                  </p>*/}

                  <img src={HomeUrl} height="15" className="mt-3" alt="" />
                </div>
              </Col>
            </Row>
            <Row className="mt-5 pt-4">
              {/* Render Footer Link */}
              {this.state.services.map((item, key) => (
                <Col lg={4} key={key}>
                  <div className="services-box p-4 mt-4">
                    <div className="services-icon bg-soft-primary">{item.icon}</div>

                    <h5 className="mt-4">{item.title}</h5>
                    <p style={{ fontSize: '13px' }} className="text-muted mt-3">
                      {item.description}
                    </p>

                    {/*<div className="mt-3">
                      <Link to="#" className="text-primary f-16">
                        Learn More <i className="mdi mdi-arrow-right ml-1"></i>
                      </Link>
                    </div>*/}
                  </div>
                </Col>
              ))}
            </Row>
            <Row className="align-items-center mt-5 pt-4" id="counter">
              <Col lg={6}>
                <div className="pr-4 mt-4">
                  <h3>حل رقمي استراتيجي وسليم!</h3>
                  <p style={{ fontSize: '13px' }} className="text-muted mt-3">
                    تطبيق واجهة الويب / الهاتف الجوال للحرفاء مما يسمح لهم بالتواصل مع
                    محاميهم.
                    <br />
                  </p>
                  <div className="mt-4 pt-1">
                    <Link to="/register" className="btn btn-outline-primary">
                      انشاء حساب
                    </Link>
                  </div>
                </div>
              </Col>
              <Col lg={5} className="offset-lg-1">
                <div className="counter-box">
                  {this.state.counters.map((counteritem, key) => (
                    <div
                      className={counteritem.id !== '1' ? 'mt-4 pt-3' : 'mt-4'}
                      key={key}
                    >
                      <div className="media">
                        <div className="count-box bg-soft-primary text-center">
                          <p className="text-uppercase text-muted mb-0 f-14">
                            {counteritem.title}{' '}
                          </p>
                        </div>
                        <div className="media-body pl-4">
                          <p
                            style={{ fontSize: '13px' }}
                            className="text-muted mb-0 mt-3"
                          >
                            {counteritem.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
