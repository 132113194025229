import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { host } from '../../../../utils/constants';
import ToastAction from '../../../../components/ToastAction';

const InitialState = () => async (dispatch) => {
  dispatch({
    type: 'INITIAL_STATE_SIGN_UP',
  });
};

const submitAccountForm = (values) => async (dispatch) => {
  dispatch({
    type: 'CREATE_NEW_ACCOUNT_REQUESTING',
  });
  const { first_name, pack, last_name, email, password, address_city, phone } = values;
  let val = {
    first_name,
    last_name,
    pack,
    email,
    password,
    address_city,
    phone,
  };
  try {
    const { data } = await axios.post(`${host}/api/lawyer/create/`, val);
    const response = data;
    const incompleteUser = !!(
      response.data.firstName === '' ||
      response.data.lastName === '' ||
      response.data.email === '' ||
      response.data.password === ''
    );
    if (response.type === 'success') {
      /*const valueSendMail = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        motif: 0,
      };
      window.localStorage.setItem('signUpId', data.data.id);
      await axios.post(`${host}/api/sendmail`, valueSendMail);*/
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="success"
            message="تهانينا ! تم إنشاء حسابك بنجاح ، يرجى مراجعة بريدك الإلكتروني للتحقق من صحته."
          />
        ),
        { hideProgressBar: true },
      );
      // toast.success(`User a été ajouté`, {position: toast.POSITION.TOP_CENTER});
      if (incompleteUser)
        toast(
          ({ closeToast }) => (
            <ToastAction type="warning" message="User est incomplet !" />
          ),
          { hideProgressBar: true },
        );
      //toast.warn(`User est incomplet !`, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'CREATE_NEW_ACCOUNT_SUCCESS',
        msg: response.message,
        data: response.data,
      });
    } else {
      toast(
        ({ closeToast }) => <ToastAction type="error" message="User n'est pas ajouté" />,
        { hideProgressBar: true },
      );
      //toast.error(`User n'est pas ajouté`, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'CREATE_NEW_ACCOUNT_FAILURE',
        msg: response.data,
        ErrorMessage: response.message,
        site: values,
      });
    }
  } catch (e) {
    const { response } = e;
    if (response) {
      const { data } = response;
      toast(({ closeToast }) => <ToastAction type="error" message={data.message} />, {
        hideProgressBar: true,
      });
      dispatch({
        type: 'CREATE_NEW_ACCOUNT_FAILURE',
        msg: 'error server',
        err: data.data || data.type,
        data: values,
      });
    }
  }
};

export default {
  submitAccountForm,
  InitialState,
};
