import React, { Component } from 'react';
import EmailIcon from '../../../assets/img/email.svg';
import PasswordIcon from '../../../assets/img/padlock.svg';
import Logo from '../../../assets/img/Lavocato.png';
import actions from '../../../js/actions';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { validPassword } from '../../../utils/helpres';
import SimpleReactValidator from 'simple-react-validator';
import InputField from '../../../components/inputField';
import { Link } from 'react-router-dom';

const mapStateToProps = (state) => ({
  isLoadingLogin: state.AppReducer.isLoadingLogin,
  isAuthorized: state.AppReducer.isAuthorized,
  account: state.AppReducer.account,
  authLogin: state.AppReducer.authLogin,
  message: state.AppReducer.message,
  error: state.AppReducer.error,
});

const mapDispatchToProps = (dispatch) => ({
  login: (values) => dispatch(actions.login(values)),
});

class Login extends Component {
  constructor(props) {
    super(props);
    document.title = 'تسجيل الدخول';
    this.state = {
      initialState: {
        email: '',
        password: '',
        checked1: true,
      },
      checkedBtnRadio: false,
      showPassword: false,
    };
    this.validator = new SimpleReactValidator({
      messages: {
        email: 'Adresse e-mail invalide',
        min: 'Saisissez un mot de passe comprenant au moins 6 caractères.',
        max: 'Le mot de passe doit comporter de 6 à 20 caractères. ',
        // OR
        default: 'Champ requise.', // will override all messenger
      },
    });

    this.handleShowPassword = this.handleShowPassword.bind(this);
    this.keyPressed = this.keyPressed.bind(this);
    this.checkedBtnRadio = this.checkedBtnRadio.bind(this);
  }
  checkedBtnRadio = () => {
    this.setState({
      checkedBtnRadio: !this.state.checkedBtnRadio,
    });
  };

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }
  keyPressed(event) {
    if (event.key === 'Enter') {
      const { isLoading } = this.props;
      const { initialState } = this.state;
      if (
        !initialState.password ||
        !initialState.email ||
        !validPassword(initialState.password)
      )
        this.setState({
          ...initialState,
          hasError: true,
        });
      else if (!isLoading && initialState.password && initialState.email)
        this.submitForm(initialState);
    }
  }
  submitForm(initialState) {
    if (
      !initialState.email ||
      !initialState.password ||
      !validPassword(initialState.password)
    ) {
      this.setState({
        ...initialState,
        hasError: true,
      });
    } else if (this.validator.allValid()) {
      this.props.login(initialState);
    } else {
      this.setState({
        ...initialState,
        hasError: true,
      });
      this.validator.showMessages();
      // rerender to show messenger for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  }

  handleShowPassword() {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { account, authLogin } = this.props;
    if (
      authLogin !== prevProps.authLogin &&
      account !== prevProps.account &&
      account.login_access.access_status === 'accepted'
    ) {
      window.location.href = `/dashboard`;
    }
  }
  render() {
    const { initialState, showPassword, hasError, checkedBtnRadio } = this.state;
    const { isLoadingLogin } = this.props;
    return (
      <div className="login_container">
        <div className="login_wrapper">
          <img className="logo-auth logo" src={Logo} alt="logo" />
          <form className="login_login_form">
            <div className="login_form_field">
              <img className="login_login_form__icon" src={EmailIcon} alt="user" />
              <InputField
                onKeyPress={this.keyPressed}
                value={initialState.email}
                name="email"
                placeholder="البريد الالكتروني "
                type="email"
                hasError={hasError}
                required={this.validator.message(
                  'email',
                  initialState.email,
                  'required|string',
                )}
                onChange={(e) => {
                  this.setState({
                    initialState: {
                      ...initialState,
                      email: e.target.value,
                    },
                  });
                }}
              />
            </div>
            <div className="login_form_field">
              <img
                style={{ cursor: 'pointer' }}
                onClick={() => this.handleShowPassword()}
                className="login_login_form__icon"
                src={PasswordIcon}
                alt="user"
              />
              <InputField
                onKeyPress={this.keyPressed}
                value={initialState.password}
                name="password"
                placeholder="كلمة المرور مثال Avocat123"
                type={showPassword ? 'text' : 'password'}
                hasError={hasError}
                validInput="password"
                required={this.validator.message(
                  'password',
                  initialState.password,
                  'required|string|min:8|max:20',
                )}
                onChange={(e) => {
                  this.setState({
                    initialState: {
                      ...initialState,
                      password: e.target.value,
                    },
                  });
                }}
              />
            </div>
            {initialState.password && !validPassword(initialState.password) ? (
              <span
                style={{
                  textAlign: 'right',
                  fontSize: '11px',
                  color: 'rgb(189, 35, 60)',
                }}
              >
                {/*كلمة مرور غير صالح
                <br />*/}
                8 أحرف
                <br />
                1 حرف كبير و 1 حرف صغير
                <br />
                رقم واحد
              </span>
            ) : (
              ''
            )}
            <div className="login_form_field login_submit_btn">
              <button
                type="button"
                onClick={() => {
                  this.submitForm(initialState);
                }}
              >
                {isLoadingLogin ? (
                  <CircularProgress
                    style={{
                      textAlign: 'center',
                      width: '30px',
                      height: '30px',
                      color: 'white',
                    }}
                  />
                ) : (
                  'تسجيل الدخول'
                )}
              </button>
            </div>
            <div className="login_form_field login_alt-link">
              <Link to="/register">انشاء حساب</Link>
              <Link to="/recover-password">نسيت كلمة المرور</Link>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);
