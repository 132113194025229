const initialLawsuitState = {
  addReport: null,
  isLoadingAddReport: false,
  report: null,
  isLoadingReport: false,
  isLoadingDeleteReport: false,
  deleteReport: false,
};

const ReportsReducer = (state = initialLawsuitState, action) => {
  switch (action.type) {
    case 'ACTION_DELETE_REPORT_REQUESTING':
      return {
        ...state,
        isLoadingDeleteReport: true,
      };
    case 'ACTION_DELETE_REPORT_SUCCESS':
      return {
        ...state,
        isLoadingDeleteReport: false,
        deleteReport: action.deleteReport,
      };
    case 'ACTION_DELETE_REPORT_FAILURE':
      return {
        ...state,
        isLoadingDeleteReport: false,
        deleteReport: null,
      };
    case 'UPDATE_REPORT_REQUESTING':
      return {
        ...state,
        isLoadingAddReport: true,
      };
    case 'UPDATE_REPORT_SUCCESS':
      return {
        ...state,
        isLoadingAddReport: false,
        addReport: action.addReport,
      };
    case 'UPDATE_REPORT_FAILURE':
      return {
        ...state,
        isLoadingAddReport: false,
        addReport: null,
      };
    case 'GET_REPORT_REQUESTING':
      return {
        ...state,
        isLoadingReport: true,
      };
    case 'GET_REPORT_SUCCESS':
      return {
        ...state,
        isLoadingReport: false,
        report: action.report,
      };
    case 'GET_REPORT_FAILURE':
      return {
        ...state,
        isLoadingReport: false,
        report: null,
      };

    default:
      return state;
  }
};

export default ReportsReducer;
