import React, { Component } from 'react';

class TitleTable extends Component {
  render() {
    const { title, icon } = this.props;
    return (
      <div style={{ height: '3.8rem' }} className="home_table_title home_table_title-red">
        {title}
        {icon}
      </div>
    );
  }
}

export default TitleTable;
