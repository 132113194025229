import React, { Component } from 'react';
import { connect } from 'react-redux';
import location from '../../assets/img/location-pin.svg';
import Calendar from '../dashboard/components/calendar';
import actions from './store/actions';
import { DaysArabic } from '../../utils/constants';
import moment from 'moment';
import SwipeableTemporaryDrawerRDV from './containers/form-rdv';
import SwipeableTemporaryDrawerUnavailability from './containers/form-unavailability';
import { CircularProgress } from '@material-ui/core';
import { isHasPermission } from '../../utils/helpres';
import EmptyData from '../../assets/img/undraw_design_team_af2y.svg';
import Tooltip from '@mui/material/Tooltip';

const mapStateToProps = (state) => ({
  DestroyUnavailability: state.AgendaReducer.deleteUnavailability,
  account: state.AppReducer.account,
  isLoadingGetAllUnavailability: state.AgendaReducer.isLoadingGetAllUnavailability,
  isLoadingGetAllRDV: state.AgendaReducer.isLoadingGetAllRDV,
  isLoadingAddRdv: state.AgendaReducer.isLoadingAddRdv,
  updateRdv: state.AgendaReducer.updateRdv,
  canceledRdv: state.AgendaReducer.canceledRdv,
  isLoadingAddDispo: state.AgendaReducer.isLoadingAddDispo,
  createdNewDispo: state.AgendaReducer.createdNewDispo,
  isLoadingDeleteRDV: state.AgendaReducer.isLoadingDeleteRDV,
  destroyRDV: state.AgendaReducer.deleteRDV,
  getListUnavailability: state.AgendaReducer.getAllUnavailability,
  motifs: state.AgendaReducer.motifs,
  getAllRDV: state.AgendaReducer.getAllRDV,
  params: state.AgendaReducer.params,
});

const mapDispatchToProps = (dispatch) => ({
  getAllUnavailability: (params) => dispatch(actions.getAllUnavailability(params)),
  getAllRdv: (params) => dispatch(actions.getAllRdvByDay(params)),
  submitRDVForm: async (params, values) => {
    await dispatch(actions.submitRDVForm(values));
    setTimeout(() => {
      dispatch(actions.getAllRdvByDay(params));
    }, 1000);
  },
  deleteRdv: async (params, values) => {
    await dispatch(actions.deleteRdv(values));
    dispatch(actions.getAllRdvByDay(params));
  },
  changeDate: async (params) => {
    await dispatch(actions.changeParams(params));
    dispatch(actions.getAllUnavailability(params));
    dispatch(actions.getAllRdvByDay(params));
  },
  submitUnavailabilityForm: async (params, values) => {
    await dispatch(actions.submitUnavailabilityForm(values));
    dispatch(actions.getAllUnavailability(params));
  },
  deleteUnavailability: async (params, item) => {
    await dispatch(actions.deleteUnavailability(item));
    dispatch(actions.getAllUnavailability(params));
  },
});

class Appointment extends Component {
  constructor(props) {
    super(props);
    props.getAllRdv(props.params);
    props.getAllUnavailability(props.params);
    props.changeActiveItemSideBar(2, 'المواعيد');
    document.title = 'المواعيد';
    this.state = {
      navItem: false,
      hasError: false,
      anchor: false,
      left: false,
      anchorUnavailability: false,
      leftUnavailability: false,
      rdv: null,
      unavailability: null,
      selected_id: null,
      openDeletePopup: false,
    };
    this.handleChangeItem = this.handleChangeItem.bind(this);
    this.handleSelectedId = this.handleSelectedId.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.toggleDrawerUnavailability = this.toggleDrawerUnavailability.bind(this);
  }
  componentDidMount() {
    this.props.changeDate({
      date:
        this.props.location.state && this.props.location.state.date
          ? this.props.location.state.date
          : new Date(),
      currentPage: 1,
      limit: 5,
    });
  }
  handleSelectedId(id) {
    this.setState({
      openDeletePopup: true,
      showBackground: true,
      selected_id: id,
    });
  }
  closeModal() {
    this.setState({
      openDeletePopup: false,
      showBackground: false,
      selected_id: null,
    });
  }
  handleChangeItem(navItem) {
    this.setState({
      navItem: navItem,
    });
  }
  toggleDrawer = (anchor, open, rdv) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    this.setState({
      left: open,
      anchor: 'left',
      rdv: rdv,
      hasError: false,
    });
  };
  toggleDrawerUnavailability =
    (anchorUnavailability, openUnavailability, unavailability) => (event) => {
      if (
        event &&
        event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift')
      ) {
        return;
      }
      this.setState({
        leftUnavailability: openUnavailability,
        anchorUnavailability: 'left',
        unavailability: unavailability,
        hasError: false,
      });
    };
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { destroyRDV, updateRdv, createdNewDispo } = this.props;
    if (updateRdv !== prevProps.updateRdv && updateRdv) {
      this.setState({
        left: false,
        anchor: 'left',
        hasError: false,
      });
    }
    if (createdNewDispo !== prevProps.createdNewDispo && createdNewDispo) {
      this.setState({
        leftUnavailability: false,
        anchorUnavailability: 'left',
        hasError: false,
      });
    }
    if (destroyRDV !== prevProps.destroyRDV && destroyRDV) {
      this.closeModal();
    }
  }
  render() {
    const {
      navItem,
      left,
      leftUnavailability,
      anchorUnavailability,
      unavailability,
      openDeletePopup,
      selected_id,
      rdv,
      anchor,
      hasError,
    } = this.state;
    const {
      getAllRDV,
      getListUnavailability,
      isLoadingGetAllUnavailability,
      params,
      isLoadingGetAllRDV,
      account,
    } = this.props;
    let isPermissionEdit = isHasPermission(
      account.login_access.permissions,
      'تعديل-المواعيد',
    );
    let isPermissionDelete = isHasPermission(
      account.login_access.permissions,
      'حذف-المواعيد',
    );
    let isPermissionAdd = isHasPermission(
      account.login_access.permissions,
      'إضافة-المواعيد',
    );
    return (
      <div className="clients_middle">
        {' '}
        {left ? (
          <SwipeableTemporaryDrawerRDV
            hasError={hasError}
            params={params}
            left={left}
            anchor={anchor}
            appointment={rdv}
            toggleDrawer={this.toggleDrawer}
          />
        ) : (
          ''
        )}
        {leftUnavailability ? (
          <SwipeableTemporaryDrawerUnavailability
            hasError={hasError}
            params={params}
            left={leftUnavailability}
            anchor={anchorUnavailability}
            unavailability={unavailability}
            toggleDrawer={this.toggleDrawerUnavailability}
          />
        ) : (
          ''
        )}
        {/*style={{ position: 'relative' }}*/}
        <div className="rv_middle__top_section">
          <div className="rv_top_section__btn_div">
            {!navItem && isPermissionAdd ? (
              <button
                onClick={this.toggleDrawer(anchor, true, null)}
                className="jalaset_add_button"
              >
                <svg
                  height="10"
                  viewBox="0 0 448 448"
                  width="10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m408 184h-136c-4.417969 0-8-3.582031-8-8v-136c0-22.089844-17.910156-40-40-40s-40 17.910156-40 40v136c0 4.417969-3.582031 8-8 8h-136c-22.089844 0-40 17.910156-40 40s17.910156 40 40 40h136c4.417969 0 8 3.582031 8 8v136c0 22.089844 17.910156 40 40 40s40-17.910156 40-40v-136c0-4.417969 3.582031-8 8-8h136c22.089844 0 40-17.910156 40-40s-17.910156-40-40-40zm0 0" />
                </svg>
                موعد
              </button>
            ) : isPermissionAdd ? (
              <button
                onClick={this.toggleDrawerUnavailability(anchorUnavailability, true)}
                className="jalaset_add_button"
              >
                <svg
                  height="10"
                  viewBox="0 0 448 448"
                  width="10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m408 184h-136c-4.417969 0-8-3.582031-8-8v-136c0-22.089844-17.910156-40-40-40s-40 17.910156-40 40v136c0 4.417969-3.582031 8-8 8h-136c-22.089844 0-40 17.910156-40 40s17.910156 40 40 40h136c4.417969 0 8 3.582031 8 8v136c0 22.089844 17.910156 40 40 40s40-17.910156 40-40v-136c0-4.417969 3.582031-8 8-8h136c22.089844 0 40-17.910156 40-40s-17.910156-40-40-40zm0 0" />
                </svg>
                مشغول
              </button>
            ) : (
              ''
            )}
          </div>
          <div className="top_section__btn_div"></div>
        </div>
        <div className="clients_middle__wrapper">
          <div style={{ flex: '0 1' }} className="clients_col-right rdv_col-right">
            <div className="clients_cards">
              <div
                onClick={() => this.handleChangeItem(false)}
                className={!navItem ? 'selectedCard clients_card' : 'clients_card'}
                style={{ height: '5rem', width: '100%' }}
              >
                <div className="clients_card__name">
                  <div>المواعيد</div>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9.931"
                  height="19.123"
                  viewBox="0 0 9.931 19.123"
                >
                  <path
                    id="Path_114"
                    data-name="Path 114"
                    d="M4398-2500l-7.845,8.97L4398-2482.2"
                    transform="translate(-4388.823 2500.658)"
                    fill="none"
                    stroke="#707070"
                    strokeWidth="2"
                  />
                </svg>
              </div>
            </div>
            <div className="clients_cards">
              <div
                onClick={() => this.handleChangeItem(true)}
                className={navItem ? 'selectedCard clients_card' : 'clients_card'}
                style={{ height: '5rem', width: '100%' }}
              >
                <div className="clients_card__name">
                  <div>مشغول</div>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9.931"
                  height="19.123"
                  viewBox="0 0 9.931 19.123"
                >
                  <path
                    id="Path_114"
                    data-name="Path 114"
                    d="M4398-2500l-7.845,8.97L4398-2482.2"
                    transform="translate(-4388.823 2500.658)"
                    fill="none"
                    stroke="#707070"
                    strokeWidth="2"
                  />
                </svg>
              </div>
            </div>
            <Calendar params={params} changeDate={this.props.changeDate} />
          </div>

          <div className="rv_middle__col-left">
            <div className="rv__judgements_table">
              <div className="rv_table">
                {!navItem ? (
                  <table>
                    <thead>
                      <tr>
                        <th>التاريخ</th>
                        <th>الموكل</th>
                        <th>الموضوع</th>
                        <th>الحالة</th>
                        <th>الإعدادت</th>
                      </tr>
                    </thead>
                    {isLoadingGetAllRDV ? (
                      <tbody>
                        <tr>
                          <td>
                            <CircularProgress
                              style={{
                                textAlign: 'center',
                                width: '30px',
                                height: '30px',
                                marginTop: '30%',
                                marginRight: '100%',
                              }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody>
                        {getAllRDV && getAllRDV.length > 0 ? (
                          getAllRDV.map((rdv) => {
                            return (
                              <tr key={rdv.id}>
                                <td style={{ minWidth: '20rem' }}>
                                  <span className="rv_td_span_day">{`${moment(
                                    rdv.date_from,
                                  ).format('dddd')}`}</span>
                                  <span className="rv_td_span_date">
                                    {moment(rdv.date_from).format('DD/MM/YYYY')}
                                  </span>
                                  <span className="rv_td_span_time">
                                    {moment(rdv.date_from).format('HH:mm')}h -
                                    {moment(rdv.date_to).format('HH:mm')}h
                                  </span>
                                </td>
                                <td>{rdv.display_name}</td>
                                <td>{rdv.description}</td>
                                <td>
                                  {rdv.status === 'actif' ? 'actif' : 'تم إلغاء الموعد'}
                                </td>
                                <td>
                                  {isPermissionDelete ? (
                                    <Tooltip title="حذف">
                                      <div className="rv_table__del">
                                        <svg
                                          onClick={() => this.handleSelectedId(rdv.id)}
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20.906"
                                          height="25.74"
                                          viewBox="0 0 20.906 25.74"
                                        >
                                          <g id="delete" transform="translate(0 0)">
                                            <path
                                              id="Path_82"
                                              data-name="Path 82"
                                              d="M223,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,223,154.7Zm0,0"
                                              transform="translate(-208.991 -145.377)"
                                              fill="#ca2946"
                                            />
                                            <path
                                              id="Path_83"
                                              data-name="Path 83"
                                              d="M105,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,105,154.7Zm0,0"
                                              transform="translate(-98.105 -145.377)"
                                              fill="#ca2946"
                                            />
                                            <path
                                              id="Path_84"
                                              data-name="Path 84"
                                              d="M1.709,7.662V22.514a3.326,3.326,0,0,0,.884,2.294,2.969,2.969,0,0,0,2.154.931H16.152a2.968,2.968,0,0,0,2.154-.931,3.326,3.326,0,0,0,.884-2.294V7.662A2.3,2.3,0,0,0,18.6,3.133H15.513V2.38A2.368,2.368,0,0,0,13.126,0H7.773A2.368,2.368,0,0,0,5.386,2.38v.754H2.3a2.3,2.3,0,0,0-.591,4.528ZM16.152,24.533H4.747a1.909,1.909,0,0,1-1.832-2.019V7.715h15.07v14.8a1.909,1.909,0,0,1-1.832,2.019ZM6.592,2.38A1.161,1.161,0,0,1,7.773,1.2h5.353A1.161,1.161,0,0,1,14.308,2.38v.754H6.592ZM2.3,4.339H18.6a1.085,1.085,0,0,1,0,2.17H2.3a1.085,1.085,0,0,1,0-2.17Zm0,0"
                                              transform="translate(0.003 0.002)"
                                              fill="#ca2946"
                                            />
                                            <path
                                              id="Path_85"
                                              data-name="Path 85"
                                              d="M164,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,164,154.7Zm0,0"
                                              transform="translate(-153.548 -145.377)"
                                              fill="#ca2946"
                                            />
                                          </g>
                                        </svg>
                                      </div>
                                    </Tooltip>
                                  ) : (
                                    ''
                                  )}
                                  {isPermissionEdit ? (
                                    <Tooltip title="تحديث">
                                      <div>
                                        <svg
                                          onClick={this.toggleDrawer(anchor, true, rdv)}
                                          id="Group_23"
                                          data-name="Group 23"
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="23.304"
                                          height="24.288"
                                          viewBox="0 0 23.304 24.288"
                                        >
                                          <g id="Group_22" data-name="Group 22">
                                            <path
                                              id="Path_86"
                                              data-name="Path 86"
                                              d="M26.636,19.431h.131L33.1,18.053a.623.623,0,0,0,.328-.164L44.163,7.156A4.136,4.136,0,0,0,45.377,4.2a4.267,4.267,0,0,0-1.214-2.987A4.135,4.135,0,0,0,41.209,0a4.037,4.037,0,0,0-2.954,1.247l-10.7,10.733a.591.591,0,0,0-.2.3l-1.378,6.335a.722.722,0,0,0,.2.624A.656.656,0,0,0,26.636,19.431ZM41.209,1.314a2.855,2.855,0,0,1,2.856,2.855q0,.016,0,.033a2.757,2.757,0,0,1-.821,2l-4.037-4.07A2.823,2.823,0,0,1,41.209,1.314ZM38.287,3.086l4.037,4.037L32.97,16.444l-4.037-4ZM28.408,13.753,31.657,17l-4.168.919Z"
                                              transform="translate(-24.83 0)"
                                              fill="#ca2946"
                                            />
                                            <path
                                              id="Path_87"
                                              data-name="Path 87"
                                              d="M30.485,365.717H8.494a.656.656,0,0,0,0,1.313H30.485a.656.656,0,0,0,0-1.313Z"
                                              transform="translate(-7.838 -342.742)"
                                              fill="#ca2946"
                                            />
                                          </g>
                                        </svg>
                                      </div>
                                    </Tooltip>
                                  ) : (
                                    ''
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr style={{ backgroundColor: '#eeeeee' }}>
                            <td colSpan={4}>
                              <div
                                className="clients_col-left"
                                style={{
                                  justifyContent: 'center',
                                  gap: '10%',
                                  marginTop: '8%',
                                  display: 'grid',
                                }}
                              >
                                <img src={EmptyData} style={{ textAlign: 'center' }} />
                                <span style={{ marginRight: '35%', fontSize: '15px' }}>
                                  لا يوجد سجل متاح
                                </span>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    )}
                  </table>
                ) : (
                  <table>
                    <thead>
                      <tr>
                        <th> التاريخ</th>
                        <th>ملاحظة</th>
                        <th>الإعدادت</th>
                      </tr>
                    </thead>

                    {isLoadingGetAllUnavailability ? (
                      <tbody>
                        <tr>
                          <td>
                            <CircularProgress
                              style={{
                                textAlign: 'center',
                                width: '30px',
                                height: '30px',
                                marginTop: '30%',
                                marginRight: '100%',
                              }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody>
                        {getListUnavailability && getListUnavailability.length > 0 ? (
                          getListUnavailability.map((unavailability) => {
                            return (
                              <tr key={unavailability.id}>
                                <td style={{ minWidth: '1rem' }}>
                                  <span className="rv_td_span_day">{`${moment(
                                    unavailability.date_from,
                                  ).format('dddd')}`}</span>
                                  <span className="rv_td_span_date">
                                    {moment(unavailability.date_from).format(
                                      'DD/MM/YYYY',
                                    )}
                                  </span>
                                  <span
                                    style={{ left: '11rem' }}
                                    className="rv_td_span_day rv_td_span_time"
                                  >
                                    {moment(unavailability.date_from).format('HH')}س -{' '}
                                    {moment(unavailability.date_to).format('HH')}س
                                  </span>
                                </td>
                                <td style={{ maxWidth: '16rem' }}>
                                  {unavailability.note}
                                </td>
                                <td>
                                  {isPermissionDelete ? (
                                    <Tooltip title="تحديث">
                                      <div>
                                        <svg
                                          onClick={this.toggleDrawerUnavailability(
                                            anchorUnavailability,
                                            true,
                                            unavailability,
                                          )}
                                          style={{ cursor: 'pointer' }}
                                          id="Group_23"
                                          data-name="Group 23"
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="23.304"
                                          height="24.288"
                                          viewBox="0 0 23.304 24.288"
                                        >
                                          <g id="Group_22" data-name="Group 22">
                                            <path
                                              id="Path_86"
                                              data-name="Path 86"
                                              d="M26.636,19.431h.131L33.1,18.053a.623.623,0,0,0,.328-.164L44.163,7.156A4.136,4.136,0,0,0,45.377,4.2a4.267,4.267,0,0,0-1.214-2.987A4.135,4.135,0,0,0,41.209,0a4.037,4.037,0,0,0-2.954,1.247l-10.7,10.733a.591.591,0,0,0-.2.3l-1.378,6.335a.722.722,0,0,0,.2.624A.656.656,0,0,0,26.636,19.431ZM41.209,1.314a2.855,2.855,0,0,1,2.856,2.855q0,.016,0,.033a2.757,2.757,0,0,1-.821,2l-4.037-4.07A2.823,2.823,0,0,1,41.209,1.314ZM38.287,3.086l4.037,4.037L32.97,16.444l-4.037-4ZM28.408,13.753,31.657,17l-4.168.919Z"
                                              transform="translate(-24.83 0)"
                                              fill="#ca2946"
                                            />
                                            <path
                                              id="Path_87"
                                              data-name="Path 87"
                                              d="M30.485,365.717H8.494a.656.656,0,0,0,0,1.313H30.485a.656.656,0,0,0,0-1.313Z"
                                              transform="translate(-7.838 -342.742)"
                                              fill="#ca2946"
                                            />
                                          </g>
                                        </svg>
                                      </div>
                                    </Tooltip>
                                  ) : (
                                    ''
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr style={{ backgroundColor: '#eeeeee' }}>
                            <td colSpan={4}>
                              <div
                                className="clients_col-left"
                                style={{
                                  justifyContent: 'center',
                                  gap: '10%',
                                  marginTop: '8%',
                                  display: 'grid',
                                }}
                              >
                                <img src={EmptyData} style={{ textAlign: 'center' }} />
                                <span style={{ marginRight: '35%', fontSize: '15px' }}>
                                  لا يوجد سجل متاح
                                </span>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    )}
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={openDeletePopup ? 'popup active' : 'popup'}>
          <div className="popup__title">أنت بصدد حذف الموعد</div>
          <div className="popup__btn_grp">
            <button onClick={() => this.closeModal()} className="cancel_popup">
              <span>الغاء</span>
              <span>&lt;</span>
            </button>
            <button
              onClick={() => {
                this.props.deleteRdv(params, selected_id);
              }}
            >
              <span>تأكيد</span>
              <span className="trashSpan">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.906"
                  height="25.74"
                  viewBox="0 0 20.906 25.74"
                >
                  <g id="delete" transform="translate(0.003 0.002)">
                    <path
                      id="Path_82"
                      data-name="Path 82"
                      d="M223,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,223,154.7Zm0,0"
                      transform="translate(-208.995 -145.379)"
                      fill="#fff"
                    />
                    <path
                      id="Path_83"
                      data-name="Path 83"
                      d="M105,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,105,154.7Zm0,0"
                      transform="translate(-98.108 -145.379)"
                      fill="#fff"
                    />
                    <path
                      id="Path_84"
                      data-name="Path 84"
                      d="M1.709,7.662V22.514a3.326,3.326,0,0,0,.884,2.294,2.969,2.969,0,0,0,2.154.931H16.152a2.968,2.968,0,0,0,2.154-.931,3.326,3.326,0,0,0,.884-2.294V7.662A2.3,2.3,0,0,0,18.6,3.133H15.513V2.38A2.368,2.368,0,0,0,13.126,0H7.773A2.368,2.368,0,0,0,5.386,2.38v.754H2.3a2.3,2.3,0,0,0-.591,4.528ZM16.152,24.533H4.747a1.909,1.909,0,0,1-1.832-2.019V7.715h15.07v14.8a1.909,1.909,0,0,1-1.832,2.019ZM6.592,2.38A1.161,1.161,0,0,1,7.773,1.2h5.353A1.161,1.161,0,0,1,14.308,2.38v.754H6.592ZM2.3,4.339H18.6a1.085,1.085,0,0,1,0,2.17H2.3a1.085,1.085,0,0,1,0-2.17Zm0,0"
                      transform="translate(0 0)"
                      fill="#fff"
                    />
                    <path
                      id="Path_85"
                      data-name="Path 85"
                      d="M164,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,164,154.7Zm0,0"
                      transform="translate(-153.551 -145.379)"
                      fill="#fff"
                    />
                  </g>
                </svg>
              </span>
            </button>
          </div>
        </div>
        <div id="overlay" className={openDeletePopup ? 'active' : ''}></div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Appointment);
