import React, { Component } from 'react';
import EmailIcon from '../../../assets/img/email.svg';
import PasswordIcon from '../../../assets/img/padlock.svg';
import Logo from '../../../assets/img/Lavocato.png';
import actions from '../../../js/actions';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { validPassword } from '../../../utils/helpres';
import SimpleReactValidator from 'simple-react-validator';
import InputField from '../../../components/inputField';
import { Link } from 'react-router-dom';

const mapStateToProps = (state) => ({
  isLoadingGetEmail: state.AppReducer.isLoadingGetEmail,
  emailExist: state.AppReducer.emailExist,
  isLoadingRestPassword: state.AppReducer.isLoadingRestPassword,
  restPassword: state.AppReducer.restPassword,
  account: state.AppReducer.account,
});

const mapDispatchToProps = (dispatch) => ({
  getEmail: (email) => dispatch(actions.getEmail(email)),
  submitResetPasswordForm: (values) => dispatch(actions.submitResetPasswordForm(values)),
});

class Recover extends Component {
  constructor(props) {
    super(props);
    document.title = 'نسيت رقمك السري';
    this.state = {
      initialState: {
        confirmPassword: '',
        code: '',
        email: '',
        password: '',
      },
      showPassword: false,
      showNewPassword: false,
      showEmail: true,
      hiddenPassword: true,
      hasErrorNewPassword: false,
      hasError: false,
    };
    this.validator = new SimpleReactValidator({
      messages: {
        email: 'Adresse e-mail invalide',
        min: 'Saisissez un mot de passe comprenant au moins 6 caractères.',
        max: 'Le mot de passe doit comporter de 6 à 20 caractères. ',
        // OR
        default: 'Champ requise.', // will override all messenger
      },
    });

    this.keyPressed = this.keyPressed.bind(this);
    this.handleShowPassword = this.handleShowPassword.bind(this);
    this.handleShowNewPassword = this.handleShowNewPassword.bind(this);
  }
  handleShowPassword() {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }
  handleShowNewPassword() {
    this.setState({
      showNewPassword: !this.state.showNewPassword,
    });
  }
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }
  keyPressed(event) {
    if (event.key === 'Enter') {
      const { isLoading } = this.props;
      const { initialState } = this.state;
      if (
        !initialState.password ||
        !initialState.email ||
        !validPassword(initialState.password)
      )
        this.setState({
          ...initialState,
          hasError: true,
        });
      else if (!isLoading && initialState.password && initialState.email)
        this.submitForm(initialState);
    }
  }
  submitForm(initialState) {
    if (!initialState.email) {
      this.setState({
        ...initialState,
        hasError: true,
      });
    } else if (this.validator.allValid()) {
      this.props.getEmail(initialState.email);
    } else {
      this.setState({
        ...initialState,
        hasError: true,
      });
      this.validator.showMessages();
      // rerender to show messenger for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { emailExist, restPassword } = this.props;
    const { hiddenPassword } = this.state;
    if (emailExist !== prevProps.emailExist && emailExist.code) {
      this.setState({
        showEmail: false,
        hasError: false,
      });
    }
    if (restPassword && hiddenPassword) {
      window.location.href = `/login`;
    }
  }
  submitFormResetPassword(initialState) {
    if (initialState.password.localeCompare(initialState.confirmPassword)) {
      this.setState({
        ...initialState,
        hasErrorNewPassword: true,
      });
    } else if (this.validator.allValid()) {
      this.props.submitResetPasswordForm(initialState);
    } else {
      this.setState({
        hasError: true,
      });
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  }
  render() {
    const {
      initialState,
      showNewPassword,
      showPassword,
      hasErrorNewPassword,
      hiddenPassword,
      hasError,
      showEmail,
    } = this.state;
    const { emailExist, isLoadingGetEmail } = this.props;
    return (
      <div className="login_container">
        <div className="login_wrapper">
          <img className="logo-auth logo" src={Logo} alt="logo" />
          {showEmail ? (
            <form className="login_login_form">
              <div className="login_form_field">
                <img className="login_login_form__icon" src={EmailIcon} alt="user" />
                <InputField
                  onKeyPress={this.keyPressed}
                  value={initialState.email}
                  name="email"
                  placeholder="البريد الالكتروني "
                  type="email"
                  hasError={hasError}
                  required={this.validator.message(
                    'email',
                    initialState.email,
                    'required|email',
                  )}
                  onChange={(e) => {
                    this.setState({
                      initialState: {
                        ...initialState,
                        email: e.target.value,
                      },
                    });
                  }}
                />
              </div>
              <div className="login_form_field login_submit_btn">
                <button
                  type="button"
                  onClick={() => {
                    if (!initialState.email) {
                      this.setState({
                        hasError: true,
                      });
                    } else this.submitForm(initialState);
                  }}
                >
                  {isLoadingGetEmail ? (
                    <CircularProgress
                      style={{
                        textAlign: 'center',
                        width: '30px',
                        height: '30px',
                        color: 'white',
                      }}
                    />
                  ) : (
                    'تحقق'
                  )}
                </button>
              </div>
              <div
                style={{
                  marginBottom: '3%',
                }}
                className="signup_form_field signup_alt-link"
              >
                <Link to="/login">تسجيل الدخول</Link>
              </div>
            </form>
          ) : (
            <form className="login_login_form">
              <div className="login_form_field">
                <img className="login_login_form__icon" src={PasswordIcon} alt="user" />
                <InputField
                  width={'100%'}
                  type="text"
                  placeholder="رمز التحقق"
                  hasError={hasError}
                  value={initialState.code}
                  name="code"
                  icon={<i className="fas fa-key"></i>}
                  required={this.validator.message(
                    'code',
                    initialState.code,
                    `${hiddenPassword && emailExist ? `in:${emailExist.code}` : ''}`,
                    { messages: { in: 'شفرة خاطئة.' } },
                  )}
                  onChange={(e) => {
                    this.setState({
                      initialState: {
                        ...initialState,
                        code: e.target.value,
                      },
                    });
                  }}
                />
              </div>
              <div className="login_form_field">
                <img
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.handleShowPassword()}
                  className="login_login_form__icon"
                  src={PasswordIcon}
                  alt="user"
                />
                <InputField
                  onKeyPress={this.keyPressed}
                  value={initialState.password}
                  name="password"
                  placeholder="كلمة المرورالجديدة مثال Avocat123"
                  type={showPassword ? 'text' : 'password'}
                  hasError={hasError}
                  validInput="password"
                  required={this.validator.message(
                    'password',
                    initialState.password,
                    'required|string|min:8|max:20',
                  )}
                  onChange={(e) => {
                    this.setState({
                      initialState: {
                        ...initialState,
                        password: e.target.value,
                      },
                    });
                  }}
                />
              </div>
              {initialState.password && !validPassword(initialState.password) ? (
                <span
                  style={{
                    textAlign: 'right',
                    fontSize: '11px',
                    color: 'rgb(189, 35, 60)',
                  }}
                >
                  {/*كلمة مرور غير صالح
                <br />*/}
                  8 أحرف
                  <br />
                  1 حرف كبير و 1 حرف صغير
                  <br />
                  رقم واحد
                </span>
              ) : (
                ''
              )}
              <div className="login_form_field">
                <img
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.handleShowNewPassword()}
                  className="login_login_form__icon"
                  src={PasswordIcon}
                  alt="user"
                />
                <InputField
                  onKeyPress={this.keyPressed}
                  value={initialState.confirmPassword}
                  name="confirmPassword"
                  placeholder="اعادة كلمة المرور "
                  type={showNewPassword ? 'text' : 'password'}
                  hasError={hasError}
                  validInput="password"
                  required={this.validator.message(
                    'confirmPassword',
                    initialState.confirmPassword,
                    'required|string|min:8|max:20',
                  )}
                  onChange={(e) => {
                    this.setState({
                      initialState: {
                        ...initialState,
                        confirmPassword: e.target.value,
                      },
                    });
                  }}
                />
              </div>
              {initialState.confirmPassword && hasErrorNewPassword ? (
                <div style={{ color: 'red', marginBottom: '2%', fontSize: '13px' }}>
                  يرجى التحقق من تطابق كلمات المرور الخاصة بك
                </div>
              ) : (
                ''
              )}
              <div className="login_form_field login_submit_btn">
                <button
                  type="button"
                  onClick={() => {
                    if (
                      !initialState.confirmPassword ||
                      !initialState.password ||
                      !initialState.code
                    )
                      this.setState({
                        ...this.state,
                        hasError: true,
                      });
                    if (
                      initialState.confirmPassword &&
                      initialState.password &&
                      initialState.code
                    ) {
                      this.submitFormResetPassword(initialState);
                    }
                  }}
                >
                  {isLoadingGetEmail ? (
                    <CircularProgress
                      style={{
                        textAlign: 'center',
                        width: '30px',
                        height: '30px',
                        color: 'white',
                      }}
                    />
                  ) : (
                    'تسجيل'
                  )}
                </button>
              </div>
              <div className="login_form_field login_alt-link">
                <Link to="/register">انشاء حساب</Link>
              </div>
            </form>
          )}
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Recover);
