import React, { Component } from 'react';
import actions from '../../js/actions';
import actionsReceivable from './store/actions';
import { connect } from 'react-redux';
import Table from './components/tables';
import Filter from './components/filter';
import { isHasPermission } from '../../utils/helpres';
import FormReceivable from './form-receivable';

const mapStateToProps = (state) => ({
  account: state.AppReducer.account,
  receivables: state.AppReducer.receivables,
  addReceivable: state.ReceivableReducer.addReceivable,
  isLoadingReceivables: state.AppReducer.isLoadingReceivables,
  paramsReceivables: state.AppReducer.paramsReceivables,
  destroyReceivable: state.ReceivableReducer.destroyReceivable,
  isLoadingDestroyReceivable: state.ReceivableReducer.isLoadingDestroyReceivable,
  isLoadingReceivable: state.ReceivableReducer.isLoadingReceivable,
  receivable: state.ReceivableReducer.receivable,
});

const mapDispatchToProps = (dispatch) => ({
  getReceivableById: (id) => dispatch(actionsReceivable.getReceivableById(id)),
  getListReceivables: (paramsReceivables) =>
    dispatch(actions.getListReceivables(paramsReceivables)),
  changeSearchedStringReceivable: async (paramsReceivables) => {
    await dispatch(actions.changeSearchedStringReceivable(paramsReceivables));
    dispatch(actions.getListReceivables(paramsReceivables));
  },
  deleteReceivable: async (paramsReceivables, id) => {
    await dispatch(actionsReceivable.deleteReceivable(id));
    dispatch(actions.getListReceivables(paramsReceivables));
  },
  updateReceivable: async (paramsReceivables, values) => {
    await dispatch(actionsReceivable.submitReceivableForm(values));
    dispatch(actions.getListReceivables(paramsReceivables));
  },
  changeActiveItemSideBar: (item, nameOfpage) =>
    dispatch(actions.changeActiveItemSideBar(item, nameOfpage)),
});

class Receivables extends Component {
  constructor(props) {
    super(props);
    props.changeActiveItemSideBar(14, 'المصاريف و المقبوضات');
    document.title = 'المصاريف و المقبوضات';
    this.state = {
      id: null,
      openPopup: false,
      selected_type: 'add',
    };
    this.handleEdit = this.handleEdit.bind(this);
  }
  componentDidMount() {
    this.props.changeSearchedStringReceivable({
      search: '',
      date:
        this.props.location.state && this.props.location.state.date
          ? this.props.location.state.date
          : '',
      currentPage: 1,
      limit: 5,
      order: 'DESC',
    });
  }
  handleEdit(id) {
    this.setState({ openPopup: true });
    this.props.getReceivableById(id);
  }
  render() {
    const {
      account,
      receivables,
      destroyReceivable,
      addReceivable,
      isLoadingReceivables,
      paramsReceivables,
      isLoadingReceivable,
      receivable,
    } = this.props;
    const { openPopup, selected_type, id } = this.state;
    let isPermissionEdit = isHasPermission(
      account.login_access.permissions,
      'تعديل-المحاسبة',
    );
    let isPermissionDelete = isHasPermission(
      account.login_access.permissions,
      'حذف-المحاسبة',
    );
    let isPermissionAdd = isHasPermission(
      account.login_access.permissions,
      'إضافة-المحاسبة',
    );
    return (
      <div className="jalaset_main">
        <div className="jalaset_middle">
          <div className="table_controls">
            {isPermissionAdd ? (
              <button
                onClick={() => this.setState({ openPopup: true })}
                className="jalaset_add_button"
              >
                <svg
                  height="10"
                  viewBox="0 0 448 448"
                  width="10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m408 184h-136c-4.417969 0-8-3.582031-8-8v-136c0-22.089844-17.910156-40-40-40s-40 17.910156-40 40v136c0 4.417969-3.582031 8-8 8h-136c-22.089844 0-40 17.910156-40 40s17.910156 40 40 40h136c4.417969 0 8 3.582031 8 8v136c0 22.089844 17.910156 40 40 40s40-17.910156 40-40v-136c0-4.417969 3.582031-8 8-8h136c22.089844 0 40-17.910156 40-40s-17.910156-40-40-40zm0 0" />
                </svg>
                اضافة
              </button>
            ) : (
              ''
            )}
            <div className="sort_grp">
              <div className="sort_grp__txt">ترتيب حسب</div>
              <Filter
                changeSearchedStringReceivable={this.props.changeSearchedStringReceivable}
                paramsReceivables={paramsReceivables}
              />
            </div>
          </div>
          <Table
            handleEdit={this.handleEdit}
            class1="jalaset__judgements_table"
            class2="jalaset_table"
            colSpan={7}
            dataTable="bill2"
            messageDelete="أنت بصدد الحذف"
            withTitle={false}
            action={true}
            isLoading={isLoadingReceivables}
            params={paramsReceivables}
            changeSearchedString={this.props.changeSearchedStringReceivable}
            data={receivables}
            //sessions={sessions}
            title="المصاريف"
            isPermissionEdit={isPermissionEdit}
            isPermissionDelete={isPermissionDelete}
            isPermissionAdd={isPermissionAdd}
            editPath="edit-session"
            deletePath={this.props.deleteReceivable}
            destroy={destroyReceivable}
            updateReceivable={true}
            addReceivable={addReceivable}
            updateReceivableFunction={this.props.updateReceivable}
            destroyYes={true}
            headerItems={[
              'الحريف',
              'المصاريف و المقبوضات',
              'نوع الخدمة',
              'ألمبلغ',
              'نوع الدفع',
              'تاريخ',
              'الإعدادت',
            ]}
          />
        </div>
        <div className={openPopup ? 'popup active' : 'popup'}>
          <div className="popup__title">
            {selected_type === 'edit' ? <> تعديل</> : 'اضافة'}
          </div>
          <div className="popup__item">
            <div className="popup__text">
              {selected_type === 'add' ? (
                <FormReceivable
                  closeModal={() => this.setState({ openPopup: false })}
                  id=""
                  paramsReceivables={paramsReceivables}
                  changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                />
              ) : selected_type === 'edit' ? (
                <FormReceivable
                  closeModal={() => this.setState({ openPopup: false })}
                  id={id}
                  paramsReceivables={paramsReceivables}
                  isLoadingReceivable={isLoadingReceivable}
                  changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div id="overlay" className={openPopup ? 'active' : ''}></div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Receivables);
