import React, { Component } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import ar from 'date-fns/locale/ar-DZ';
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import { courts, DaysArabic } from '../../../utils/constants';
import { connect } from 'react-redux';
import moment from 'moment';
import actions from '../store/actions';
import InputField from '../../../components/inputField';
import SimpleReactValidator from 'simple-react-validator';
import { CircularProgress } from '@material-ui/core';

registerLocale('ar', ar);

const mapStateToProps = (state, ownProps) => {
  const { report } = ownProps;
  let date;
  let name;
  let file;
  let to;
  let lawyer_name;
  let percent;
  let id;
  if (report) {
    date = report && report.date ? report.date : new Date();
    id = report && report.id ? report.id : null;
    to = report && report.to ? report.to : null;
    lawyer_name = report.lawyer
      ? `${report.lawyer.first_name} ${report.lawyer.last_name}`
      : null;
    name = report && report.name ? report.name : '';
    file = report && report.file ? report.file : '';
    percent = report ? 99 : 0;
  }
  return {
    addReport: state.ReportsReducer.addReport,
    isLoadingAddReport: state.ReportsReducer.isLoadingAddReport,
    account: state.AppReducer.account,
    initialValues: {
      id: id || '',
      date: date || new Date(),
      name: name || '',
      to: to || '',
      lawyer_name: lawyer_name || '',
      percent: percent || 0,
      file: file || null,
    },
  };
};
const mapDispatchToProps = (dispatch) => ({
  submitReportForm: (values) => dispatch(actions.submitReportForm(values)),
});

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      percent: 0,
      hasErrorDate: false,
      hasError: false,
      initialValues: {
        ...props.initialValues,
        share: null,
        subject: '',
        message: '',
        files: [],
      },
    };
    this.submitForm = this.submitForm.bind(this);
    this.increase = this.increase.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.validator = new SimpleReactValidator({
      messages: {
        email: 'Adresse e-mail invalide',
        min: 'Saisissez un mot de passe comprenant au moins 6 caractères.',
        max: 'Le mot de passe doit comporter de 6 à 20 caractères. ',
        // OR
        default: 'Champ requise.', // will override all messenger
      },
    });
  }
  submitForm(initialValues) {
    if (
      initialValues.share === 1 &&
      (!initialValues.file || !initialValues.to || !initialValues.date)
    ) {
      this.setState({
        ...initialValues,
        hasError: true,
      });
    } else if (
      initialValues.share === 1 &&
      initialValues.date &&
      moment().isAfter(moment(initialValues.date))
    ) {
      this.setState({
        ...initialValues,
        hasErrorDate: true,
      });
    } else if (
      initialValues.share === 2 &&
      (!initialValues.email || !initialValues.subject)
    ) {
      this.setState({
        ...initialValues,
        hasError: true,
      });
    } else if (this.validator.allValid()) {
      this.props.submitReportForm(initialValues);
    } else {
      this.setState({
        ...initialValues,
        hasError: true,
      });
      this.validator.showMessages();
      // rerender to show messenger for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  }
  componentDidMount() {
    // this.increase();
  }

  increase() {
    const { initialValues } = this.state;
    const newPercent = initialValues.percent + 1;
    if (newPercent >= 100) {
      clearTimeout(this.tm);
      return;
    }
    this.setState({
      initialValues: {
        ...initialValues,
        percent: newPercent,
      },
    });
    this.tm = setTimeout(this.increase, 20);
  }
  onConfirm() {
    const { initialValues } = this.state;
    this.setState({
      initialValues: {
        ...initialValues,
        percent: -1,
      },
    });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { addReport } = this.props;
    if (addReport !== prevProps.addReport && addReport && addReport.id) {
      this.props.goBack();
    }
  }
  render() {
    const { initialValues, hasError, hasErrorDate } = this.state;
    const { lawyers, isLoadingAddReport } = this.props;
    return (
      <form action="">
        <div className="lawsuit_form__sections">
          <div>
            <label htmlFor="lawsuit_type">مشاركة</label>
            <select
              className={hasError && !initialValues.share ? 'input-error' : ''}
              onChange={(e) =>
                this.setState({
                  initialValues: {
                    ...initialValues,
                    share: e.target.value,
                  },
                })
              }
              name="share"
            >
              <option value={null}>مشاركة مع</option>
              <option value={1}>استاذ</option>
              <option value={2}>مشاركة عن طريق البريد الالكتروني</option>
            </select>
          </div>
          {initialValues.share ? (
            parseInt(initialValues.share) === 1 ? (
              <div>
                <label htmlFor="lawsuit_type">الاستاذ</label>
                <Autocomplete
                  debug
                  id="debug"
                  options={lawyers}
                  getOptionLabel={(option) => option.title}
                  style={{ marginBottom: '4%' }}
                  onChange={(event, option) => {
                    this.setState({
                      initialValues: {
                        ...initialValues,
                        to: option != null ? option.id : '',
                      },
                    });
                  }}
                  defaultValue={{
                    title: initialValues.lawyer_name,
                  }}
                  renderInput={(params) => (
                    <TextField
                      error={!initialValues.to && hasError}
                      name="to"
                      style={{ border: 0 }}
                      {...params}
                      //label="المدعي"
                      //variant="outlined"
                    />
                  )}
                />
              </div>
            ) : (
              <>
                <div>
                  <label htmlFor="lawsuit_type">البريد الالكتروني</label>
                  <InputField
                    value={initialValues.email}
                    name="email"
                    placeholder="البريد الالكتروني لالمحكمة"
                    type="email"
                    hasError={hasError}
                    required={this.validator.message(
                      'email',
                      initialValues.email,
                      'required|email',
                    )}
                    onChange={(e) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          email: e.target.value,
                        },
                      });
                    }}
                  />
                </div>
                <div>
                  <label htmlFor="lawsuit_type">الموضوع</label>
                  <InputField
                    value={initialValues.subject}
                    name="subject"
                    placeholder="الموضوع"
                    type="text"
                    hasError={hasError}
                    onChange={(e) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          subject: e.target.value,
                        },
                      });
                    }}
                  />
                </div>
                <div>
                  <label htmlFor="lawsuit_events">وصف </label>
                  <textarea
                    style={{ width: '420px' }}
                    onChange={(event) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          message: event.target.value,
                        },
                      });
                    }}
                    value={initialValues.message}
                    className={hasError && !initialValues.message ? 'input-error' : ''}
                    name="facts"
                    placeholder="وصف"
                    id="lawsuit_events"
                  >
                    {initialValues.message}
                  </textarea>
                </div>
              </>
            )
          ) : (
            ''
          )}

          <div
            style={{
              alignItems: 'center',
              marginRight: '5%',
            }}
            className="lawsuit_form_sections__date"
          >
            <div
              style={{
                width: '9rem',
                marginLeft: 0,
              }}
              className="jalaset_form__label"
              htmlFor="date_selected"
            >
              الآجال
            </div>
            <div className="jalaset_form__cal">
              <DatePicker
                selected={initialValues.data}
                locale="ar"
                onChange={(date) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      date: moment(date).format('YYYY-MM-DD'),
                    },
                  });
                }}
                customInput={
                  <svg
                    style={{
                      cursor: 'pointer',
                    }}
                    id="calendar"
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                  >
                    <g id="Group_28" data-name="Group 28">
                      <g id="Group_27" data-name="Group 27">
                        <ellipse
                          id="Ellipse_20"
                          data-name="Ellipse 20"
                          cx="1"
                          cy="1.5"
                          rx="1"
                          ry="1.5"
                          transform="translate(22 11)"
                          fill="#ca2946"
                        />
                        <path
                          id="Path_89"
                          data-name="Path 89"
                          d="M25.312,2.344H23.789V1.172a1.172,1.172,0,0,0-2.344,0V2.344H16.113V1.172a1.172,1.172,0,0,0-2.344,0V2.344H8.5V1.172a1.172,1.172,0,1,0-2.344,0V2.344H4.688A4.693,4.693,0,0,0,0,7.031V25.312A4.693,4.693,0,0,0,4.688,30h8.965a1.172,1.172,0,0,0,0-2.344H4.688a2.346,2.346,0,0,1-2.344-2.344V7.031A2.346,2.346,0,0,1,4.688,4.688H6.152V5.859a1.172,1.172,0,0,0,2.344,0V4.688H13.77V5.859a1.172,1.172,0,0,0,2.344,0V4.688h5.332V5.859a1.172,1.172,0,0,0,2.344,0V4.688h1.523a2.346,2.346,0,0,1,2.344,2.344v6.68a1.172,1.172,0,0,0,2.344,0V7.031A4.693,4.693,0,0,0,25.312,2.344Z"
                          fill="#ca2946"
                        />
                        <path
                          id="Path_90"
                          data-name="Path 90"
                          d="M277.09,270a7.09,7.09,0,1,0,7.09,7.09A7.1,7.1,0,0,0,277.09,270Zm0,11.836a4.746,4.746,0,1,1,4.746-4.746A4.751,4.751,0,0,1,277.09,281.836Z"
                          transform="translate(-254.18 -254.18)"
                          fill="#ca2946"
                        />
                        <path
                          id="Path_91"
                          data-name="Path 91"
                          d="M373.871,332.4h-.527v-1.23a1.172,1.172,0,0,0-2.344,0v2.4a1.172,1.172,0,0,0,1.172,1.172h1.7a1.172,1.172,0,0,0,0-2.344Z"
                          transform="translate(-349.262 -310.664)"
                          fill="#ca2946"
                        />
                        <circle
                          id="Ellipse_21"
                          data-name="Ellipse 21"
                          cx="1.5"
                          cy="1.5"
                          r="1.5"
                          transform="translate(16 11)"
                          fill="#ca2946"
                        />
                        <circle
                          id="Ellipse_22"
                          data-name="Ellipse 22"
                          cx="1.5"
                          cy="1.5"
                          r="1.5"
                          transform="translate(11 16)"
                          fill="#ca2946"
                        />
                        <ellipse
                          id="Ellipse_23"
                          data-name="Ellipse 23"
                          cx="1"
                          cy="1.5"
                          rx="1"
                          ry="1.5"
                          transform="translate(6 11)"
                          fill="#ca2946"
                        />
                        <ellipse
                          id="Ellipse_24"
                          data-name="Ellipse 24"
                          cx="1"
                          cy="1.5"
                          rx="1"
                          ry="1.5"
                          transform="translate(6 16)"
                          fill="#ca2946"
                        />
                        <circle
                          id="Ellipse_25"
                          data-name="Ellipse 25"
                          cx="1"
                          cy="1"
                          r="1"
                          transform="translate(6 22)"
                          fill="#ca2946"
                        />
                        <ellipse
                          id="Ellipse_26"
                          data-name="Ellipse 26"
                          cx="1.5"
                          cy="1"
                          rx="1.5"
                          ry="1"
                          transform="translate(11 22)"
                          fill="#ca2946"
                        />
                        <circle
                          id="Ellipse_27"
                          data-name="Ellipse 27"
                          cx="1.5"
                          cy="1.5"
                          r="1.5"
                          transform="translate(11 11)"
                          fill="#ca2946"
                        />
                      </g>
                    </g>
                  </svg>
                }
              />
            </div>

            <div className="jalaset_form__date_selected">
              <div className="jalaset_form__date_selected__day">
                {DaysArabic[moment(initialValues.date).format('dddd')]}
              </div>
              <div className="jalaset_form__date_selected__day">
                {moment(initialValues.date).format('DD/MM/YYYY')}
              </div>
            </div>
          </div>
          {hasErrorDate ||
          (initialValues.date && moment().isAfter(moment(initialValues.date))) ? (
            <div className="rv_form__sections-left_field">
              <div>
                <svg
                  className="rv_danger_icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="27.077"
                  height="24.294"
                  viewBox="0 0 27.077 24.294"
                >
                  <g id="alert" transform="translate(0 -26.315)">
                    <g id="Group_39" data-name="Group 39" transform="translate(0 26.315)">
                      <g id="Group_38" data-name="Group 38" transform="translate(0 0)">
                        <path
                          id="Path_105"
                          data-name="Path 105"
                          d="M26.645,45.833,16.3,27.907a3.184,3.184,0,0,0-5.515,0L.431,45.833a3.184,3.184,0,0,0,2.757,4.776h20.7a3.184,3.184,0,0,0,2.757-4.776Zm-1.374,2.391a1.581,1.581,0,0,1-1.384.8H3.189a1.6,1.6,0,0,1-1.384-2.4L12.155,28.7a1.6,1.6,0,0,1,2.767,0l10.35,17.926A1.582,1.582,0,0,1,25.272,48.224Z"
                          transform="translate(0 -26.315)"
                          fill="#ff8000"
                        />
                      </g>
                    </g>
                    <g
                      id="Group_41"
                      data-name="Group 41"
                      transform="translate(12.745 34.232)"
                    >
                      <g id="Group_40" data-name="Group 40" transform="translate(0 0)">
                        <rect
                          id="Rectangle_145"
                          data-name="Rectangle 145"
                          width="1.586"
                          height="7.932"
                          fill="#ff8000"
                        />
                      </g>
                    </g>
                    <g
                      id="Group_43"
                      data-name="Group 43"
                      transform="translate(12.481 43.75)"
                    >
                      <g id="Group_42" data-name="Group 42">
                        <path
                          id="Path_106"
                          data-name="Path 106"
                          d="M237.06,355.99a1.058,1.058,0,1,0,1.058,1.058A1.059,1.059,0,0,0,237.06,355.99Z"
                          transform="translate(-236.002 -355.99)"
                          fill="#ff8000"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <div className="rv_err_msg">الرجاء تحديد تاريخ في المستقبل</div>
            </div>
          ) : (
            ''
          )}
          <div
            style={{ width: parseInt(initialValues.share) === 2 ? '50%' : '100%' }}
            id="upload_form"
          >
            <input
              accept=".pdf,.docx"
              onChange={(e) => {
                this.increase();
                this.setState({
                  initialValues: {
                    ...initialValues,
                    file: e.target.files[0],
                    files: e.target.files,
                    name: e.target.files[0].name,
                    //percent: 0,
                  },
                });
              }}
              name="file"
              type="file"
              id="upload_button"
              hidden
              multiple={parseInt(initialValues.share) === 1 ? false : true}
            />
            <label
              style={{ width: '80%' }}
              id="upload_button_label"
              htmlFor="upload_button"
            >
              <svg
                height="10"
                viewBox="0 0 448 448"
                width="10"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="m408 184h-136c-4.417969 0-8-3.582031-8-8v-136c0-22.089844-17.910156-40-40-40s-40 17.910156-40 40v136c0 4.417969-3.582031 8-8 8h-136c-22.089844 0-40 17.910156-40 40s17.910156 40 40 40h136c4.417969 0 8 3.582031 8 8v136c0 22.089844 17.910156 40 40 40s40-17.910156 40-40v-136c0-4.417969 3.582031-8 8-8h136c22.089844 0 40-17.910156 40-40s-17.910156-40-40-40zm0 0" />
              </svg>
              اضافة تقرير او ملفات
            </label>
            {hasError && !initialValues.file ? (
              <span style={{ color: 'rgb(189, 35, 60) ' }}>sssssssssssss</span>
            ) : (
              ''
            )}
          </div>
          <div className="upload_status">
            {!initialValues.file ? (
              ''
            ) : (
              <>
                <div className="uploaded_file__name">{initialValues.name}</div>
                <div id="progressBar">
                  <div
                    style={{ width: `${initialValues.percent + 1}%` }}
                    id="progressBarFill"
                  ></div>
                </div>
                <div className="upload_percenctage">{initialValues.percent + 1}%</div>
                <br />
                <button
                  onClick={() =>
                    this.setState({
                      initialValues: {
                        ...initialValues,
                        percent: 0,
                        file: null,
                        name: '',
                      },
                    })
                  }
                  type="button"
                  id="cancel_upload_button"
                >
                  إلغاء
                </button>
              </>
            )}
          </div>
        </div>
        <div className="lawsuit_form__btn_grp">
          <button
            onClick={() => this.props.goBack()}
            type="button"
            className="lawsuit_cancel_session"
          >
            <svg
              className="lawsuit_ltSpan"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              xlinkHref="http://svgjs.com/svgjs"
              version="1.1"
              width="12"
              height="12"
              x="0"
              y="0"
              viewBox="0 0 492.004 492.004"
              style={{ enableBackground: 'new 0 0 512 512' }}
              xmlSpace="preserve"
            >
              <g>
                <g xmlns="http://www.w3.org/2000/svg">
                  <g>
                    <path
                      d="M382.678,226.804L163.73,7.86C158.666,2.792,151.906,0,144.698,0s-13.968,2.792-19.032,7.86l-16.124,16.12    c-10.492,10.504-10.492,27.576,0,38.064L293.398,245.9l-184.06,184.06c-5.064,5.068-7.86,11.824-7.86,19.028    c0,7.212,2.796,13.968,7.86,19.04l16.124,16.116c5.068,5.068,11.824,7.86,19.032,7.86s13.968-2.792,19.032-7.86L382.678,265    c5.076-5.084,7.864-11.872,7.848-19.088C390.542,238.668,387.754,231.884,382.678,226.804z"
                      fill="#2d2d2d"
                      data-original="#000000"
                      className=""
                    />
                  </g>
                </g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
              </g>
            </svg>
            الغاء
          </button>
          <button
            disabled={initialValues.percent >= 99 || isLoadingAddReport ? false : true}
            onClick={() => {
              if (!isLoadingAddReport) this.submitForm(initialValues);
            }}
            type="button"
          >
            {isLoadingAddReport ? (
              <CircularProgress
                style={{
                  textAlign: 'center',
                  width: '30px',
                  height: '30px',
                  color: 'white',
                }}
              />
            ) : (
              <>
                <svg
                  className="tickSpan"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.125"
                  height="17.774"
                  viewBox="0 0 29.125 23.774"
                >
                  <path
                    id="Path_92"
                    data-name="Path 92"
                    d="M1325.354,167.5l8.34,9.625,17.8-19.417"
                    transform="translate(-1323.842 -156.357)"
                    fill="none"
                    stroke="#f9f9f9"
                    strokeWidth="4"
                  />
                </svg>
                تسجيل
              </>
            )}
          </button>
        </div>
      </form>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Form);
