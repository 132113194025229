const initialLawsuitState = {
  addContract: null,
  isLoadingAddContract: false,
  contract: null,
  isLoadingContract: false,
  isLoadingDestroyContract: false,
  destroyContract: false,
};

const ContractReducer = (state = initialLawsuitState, action) => {
  switch (action.type) {
    case 'ACTION_DELETE_CONTRACT_REQUESTING':
      return {
        ...state,
        isLoadingDestroyContract: true,
      };
    case 'ACTION_DELETE_CONTRACT_SUCCESS':
      return {
        ...state,
        isLoadingDestroyContract: false,
        destroyContract: true,
      };
    case 'ACTION_DELETE_CONTRACT_FAILURE':
      return {
        ...state,
        isLoadingDestroyContract: false,
        destroyContract: false,
      };
    case 'UPDATE_CONTRACT_REQUESTING':
      return {
        ...state,
        isLoadingAddContract: true,
      };
    case 'UPDATE_CONTRACT_SUCCESS':
      return {
        ...state,
        isLoadingAddContract: false,
        addContract: action.addContract,
      };
    case 'UPDATE_CONTRACT_FAILURE':
      return {
        ...state,
        isLoadingAddContract: false,
        addContract: null,
      };
    case 'GET_CONTRACT_REQUESTING':
      return {
        ...state,
        isLoadingContract: true,
      };
    case 'GET_CONTRACT_SUCCESS':
      return {
        ...state,
        isLoadingContract: false,
        contract: action.contract,
      };
    case 'GET_CONTRACT_FAILURE':
      return {
        ...state,
        isLoadingContract: false,
        contract: null,
      };

    default:
      return state;
  }
};

export default ContractReducer;
