const initialSessionState = {
  addAppeal: null,
  isLoadingAddAppeal: false,
  appeal: null,
  isLoadingAppeal: false,
  isLoadingDeleteAppeal: false,
  destroyAppeal: false,
};

const AppealReducer = (state = initialSessionState, action) => {
  switch (action.type) {
    case 'ACTION_DELETE_APPEAL_REQUESTING':
      return {
        ...state,
        isLoadingDeleteAppeal: true,
      };
    case 'ACTION_DELETE_APPEAL_SUCCESS':
      return {
        ...state,
        isLoadingDeleteAppeal: false,
        destroyAppeal: true,
      };
    case 'ACTION_DELETE_APPEAL_FAILURE':
      return {
        ...state,
        isLoadingDeleteAppeal: false,
        destroyAppeal: false,
      };
    case 'UPDATE_APPEAL_REQUESTING':
      return {
        ...state,
        isLoadingAddAppeal: true,
      };
    case 'UPDATE_APPEAL_SUCCESS':
      return {
        ...state,
        isLoadingAddAppeal: false,
        addAppeal: action.appeal,
      };
    case 'UPDATE_APPEAL_FAILURE':
      return {
        ...state,
        isLoadingAddAppeal: false,
        addAppeal: null,
      };
    case 'GET_APPEAL_REQUESTING':
      return {
        ...state,
        isLoadingAppeal: true,
      };
    case 'GET_APPEAL_SUCCESS':
      return {
        ...state,
        isLoadingAppeal: false,
        appeal: action.appeal,
      };
    case 'GET_APPEAL_FAILURE':
      return {
        ...state,
        isLoadingAppeal: false,
        appeal: null,
      };

    default:
      return state;
  }
};

export default AppealReducer;
