import React, { Component } from 'react';

class ItemTable extends Component {
  render() {
    const { item } = this.props;
    let i = 0;
    return (
      <>
        {Object.keys(item).map((keyName, i) => (
          <td key={i++}>{item[keyName]}</td>
        ))}
      </>
    );
  }
}

export default ItemTable;
