import React, { useRef } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Badge from '@material-ui/core/Badge';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Bar from '../assets/img/bar.svg';
import Close from '../assets/img/close.svg';

export default function Header({
  pusher,
  getCountNotificationSeen,
  getListNotifications,
  getNotificationIsSeen,
  namePage,
  account,
  paramsNotification,
  countNotification,
  listNotifications,
  logout,
  sandwich_menu_btn,
  handleChangeMenuBtn,
}) {
  let accountId = account.id;
  if (account.login_access.account_type === 'secretary') accountId = account.lawyer_id;
  if (account.login_access.account_type === 'lawyer' && account.pack === 'traineeship')
    accountId = account.user_id;
  let i = 0;
  const wrapperRef = useRef(null);
  const [hideDropDown, updateHideDropDown] = useState(false);
  const [openLogoutPopup, setOpenLogoutPopup] = useState(false);
  const [hideDropDownNotif, updateHideDropDownNotif] = useState(false);
  const [notification, updateNotification] = useState(countNotification);
  const [notifications, updateNotifications] = useState(
    listNotifications && listNotifications.data,
  );
  const [open, setOpen] = React.useState(false);
  const [openNotif, setOpenNotif] = React.useState(false);
  const anchorRef = React.useRef(null);
  const anchorRefNotif = React.useRef(null);

  const handleToggleNotif = () => {
    setOpenNotif((prevOpen) => !prevOpen);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    setOpenNotif(false);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    if (anchorRefNotif.current && anchorRefNotif.current.contains(event.target)) {
      return;
    }

    setOpen(false);
    setOpenNotif(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
      setOpenNotif(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
      setOpenNotif(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  const prevOpenNotif = React.useRef(openNotif);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    if (prevOpenNotif.current === true && openNotif === false) {
      anchorRefNotif.current.focus();
    }

    prevOpen.current = open;
    prevOpenNotif.current = openNotif;
  }, [open, openNotif]);

  useEffect(async () => {
    const channelUser = pusher.subscribe('canceled-user');
    channelUser.bind(`canceled-${account.id}`, (data) => {
      document.location.reload();
    });

    getCountNotificationSeen();
    getListNotifications(paramsNotification);
    channelUser.bind(
      `canceled-${account.id}-${account.login_access.account_type}`,
      (data) => {
        document.location.reload();
      },
    );
    channelUser.bind(
      `refresh-${account.id}-${account.login_access.account_type}`,
      (data) => {
        document.location.reload();
      },
    );
    const channel = pusher.subscribe('notification');
    channel.bind(`count-${accountId}`, (data) => {
      updateNotification(data.count);
    });
    channel.bind(`list-${accountId}`, (data) => {
      updateNotifications(data);
    });
  }, []);
  return (
    <div
      style={{ width: !sandwich_menu_btn ? '95%' : '87.2%', height: '4.8rem' }}
      className="top_bar"
      id="header"
    >
      <div className="top_bar__el-right">
        <div onClick={() => handleChangeMenuBtn()} className="menu_icon_container">
          {sandwich_menu_btn ? (
            <img src={Close} className="sandwich_menu_btn" alt="" />
          ) : (
            <img src={Bar} className="sandwich_menu_btn" alt="" />
          )}
        </div>
        {namePage}
        <div className="main__date main__date" id="home_main__date">
          {`${moment().format('dddd')}  ${moment().format('YYYY/MM/DD')}`}
        </div>
      </div>
      <div className="top_bar__el-left">
        <div
          style={{
            display: 'contents',
          }}
          onClick={() => {
            getNotificationIsSeen();
            getListNotifications(paramsNotification);
            getNotificationIsSeen();
          }}
        >
          <Badge
            invisible={notification > 0 ? false : true}
            badgeContent={notification}
            color="secondary"
            className="top_bar__identifier"
          >
            <svg
              ref={anchorRefNotif}
              id="composition-button"
              aria-controls={openNotif ? 'composition-menu' : undefined}
              aria-expanded={openNotif ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleToggleNotif}
              style={{ cursor: 'pointer' }}
              className="noti_dropdown"
              version="1.2"
              baseProfile="tiny-ps"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 29 31"
              width="25"
              height="31"
            >
              <g id="">
                <g id="Groupe 116">
                  <g id="notification">
                    <path
                      id="Tracé 156"
                      className="shp0"
                      d="M12.82 31C10.17 31 8.01 28.84 8.01 26.19C8.01 25.66 8.44 25.23 8.97 25.23C9.5 25.23 9.93 25.66 9.93 26.19C9.93 27.78 11.23 29.08 12.82 29.08C14.41 29.08 15.7 27.78 15.7 26.19C15.7 25.66 16.13 25.23 16.66 25.23C17.19 25.23 17.62 25.66 17.62 26.19C17.62 28.84 15.47 31 12.82 31Z"
                    />
                    <path
                      id="Tracé 157"
                      className="shp0"
                      d="M23.39 27.15L2.24 27.15C1.01 27.15 0 26.15 0 24.91C0 24.26 0.29 23.63 0.78 23.21C0.82 23.18 0.85 23.15 0.89 23.13C2.77 21.49 3.84 19.13 3.84 16.63L3.84 13.06C3.84 8.11 7.87 4.09 12.82 4.09C13.02 4.09 13.24 4.09 13.45 4.12C13.97 4.21 14.33 4.71 14.24 5.23C14.15 5.75 13.65 6.11 13.13 6.02C13.03 6 12.92 6.01 12.82 6.01C8.93 6.01 5.77 9.17 5.77 13.06L5.77 16.63C5.77 19.73 4.41 22.66 2.05 24.67C2.03 24.69 2.01 24.7 1.99 24.71C1.96 24.76 1.92 24.82 1.92 24.91C1.92 25.09 2.07 25.23 2.24 25.23L23.39 25.23C23.56 25.23 23.71 25.09 23.71 24.91C23.71 24.82 23.68 24.76 23.64 24.71C23.62 24.7 23.61 24.69 23.59 24.67C21.22 22.66 19.87 19.73 19.87 16.63L19.87 15.24C19.87 14.71 20.3 14.27 20.83 14.27C21.36 14.27 21.79 14.71 21.79 15.24L21.79 16.63C21.79 19.13 22.87 21.49 24.75 23.13C24.78 23.16 24.82 23.18 24.85 23.21C25.35 23.63 25.63 24.26 25.63 24.91C25.63 26.15 24.63 27.15 23.39 27.15L23.39 27.15Z"
                    />
                    <path
                      id="Tracé 157 copy"
                      className="shp0"
                      d="M23.39 27.15L2.24 27.15C1.01 27.15 0 26.15 0 24.91C0 24.26 0.29 23.63 0.78 23.21C0.82 23.18 0.85 23.15 0.89 23.13C2.77 21.49 3.84 19.13 3.84 16.63L3.84 13.06C3.84 8.11 7.87 4.09 12.82 4.09C13.02 4.09 13.24 4.09 13.45 4.12C13.97 4.21 14.33 4.71 14.24 5.23C14.15 5.75 13.65 6.11 13.13 6.02C13.03 6 12.92 6.01 12.82 6.01C8.93 6.01 5.77 9.17 5.77 13.06L5.77 16.63C5.77 19.73 4.41 22.66 2.05 24.67C2.03 24.69 2.01 24.7 1.99 24.71C1.96 24.76 1.92 24.82 1.92 24.91C1.92 25.09 2.07 25.23 2.24 25.23L23.39 25.23C23.56 25.23 23.71 25.09 23.71 24.91C23.71 24.82 23.68 24.76 23.64 24.71C23.62 24.7 23.61 24.69 23.59 24.67C21.22 22.66 19.87 19.73 19.87 16.63L19.87 15.24C19.87 14.71 20.3 14.27 20.83 14.27C21.36 14.27 21.79 14.71 21.79 15.24L21.79 16.63C21.79 19.13 22.87 21.49 24.75 23.13C24.78 23.16 24.82 23.18 24.85 23.21C25.35 23.63 25.63 24.26 25.63 24.91C25.63 26.15 24.63 27.15 23.39 27.15L23.39 27.15Z"
                    />
                    {/*<path
                      id="Tracé 157 copy 2"
                      className="shp0"
                      d="M23.39 27.15L2.24 27.15C1.01 27.15 0 26.15 0 24.91C0 24.26 0.29 23.63 0.78 23.21C0.82 23.18 0.85 23.15 0.89 23.13C2.77 21.49 3.84 19.13 3.84 16.63L3.84 13.06C3.84 8.11 7.87 4.09 12.82 4.09C13.02 4.09 13.24 4.09 13.45 4.12C13.97 4.21 14.33 4.71 14.24 5.23C14.15 5.75 13.65 6.11 13.13 6.02C13.03 6 12.92 6.01 12.82 6.01C8.93 6.01 5.77 9.17 5.77 13.06L5.77 16.63C5.77 19.73 4.41 22.66 2.05 24.67C2.03 24.69 2.01 24.7 1.99 24.71C1.96 24.76 1.92 24.82 1.92 24.91C1.92 25.09 2.07 25.23 2.24 25.23L23.39 25.23C23.56 25.23 23.71 25.09 23.71 24.91C23.71 24.82 23.68 24.76 23.64 24.71C23.62 24.7 23.61 24.69 23.59 24.67C21.22 22.66 19.87 19.73 19.87 16.63L19.87 15.24C19.87 14.71 20.3 14.27 20.83 14.27C21.36 14.27 21.79 14.71 21.79 15.24L21.79 16.63C21.79 19.13 22.87 21.49 24.75 23.13C24.78 23.16 24.82 23.18 24.85 23.21C25.35 23.63 25.63 24.26 25.63 24.91C25.63 26.15 24.63 27.15 23.39 27.15L23.39 27.15Z"
                    />
                    <path
                      id="Tracé 158"
                      className="shp1"
                      d="M21.79 13.06C18.25 13.06 15.38 10.18 15.38 6.65C15.38 3.11 18.25 0.24 21.79 0.24C25.32 0.24 28.2 3.11 28.2 6.65C28.2 10.18 25.32 13.06 21.79 13.06Z"
                    />*/}
                  </g>
                  <text id="3 " style={{ transform: 'matrix(1,0,0,1,24,9)' }}>
                    <tspan id="numberOfNotifications" x="0" y="0" className="txt2">
                      {notification > 0 ? notification : ''}
                    </tspan>
                  </text>
                </g>
              </g>
            </svg>
          </Badge>
          <Popper
            open={openNotif}
            anchorEl={anchorRefNotif.current}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal
            style={{
              zIndex: '2000',
            }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      className="user_dropdown__content"
                      autoFocusItem={openNotif}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                    >
                      {notifications && notifications.length > 0
                        ? notifications.map((notif) => {
                            i++;
                            if (i <= 4) {
                              if (notif.type === 'rdv')
                                return (
                                  <MenuItem key={notif.id} onClick={handleClose}>
                                    <Link
                                      to={{
                                        pathname: '/appointment',
                                        state: { date: new Date(notif.date) },
                                      }}
                                      style={{ width: '100%', display: 'contents' }}
                                    >
                                      <svg
                                        style={{ width: '20%' }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="44"
                                        height="44"
                                        viewBox="0 0 44 44"
                                      >
                                        <circle
                                          id="Ellipse_36"
                                          data-name="Ellipse 36"
                                          cx="22"
                                          cy="22"
                                          r="22"
                                          fill="#ca2946"
                                        />
                                        <g id="calendar" transform="translate(7.089 6.5)">
                                          <g id="Group_11" data-name="Group 11">
                                            <g id="Group_10" data-name="Group 10">
                                              <circle
                                                id="Ellipse_2"
                                                data-name="Ellipse 2"
                                                cx="1"
                                                cy="1"
                                                r="1"
                                                transform="translate(21.911 11.5)"
                                                fill="#fff"
                                              />
                                              <path
                                                id="Path_43"
                                                data-name="Path 43"
                                                d="M25.312,2.344H23.789V1.172a1.172,1.172,0,0,0-2.344,0V2.344H16.113V1.172a1.172,1.172,0,0,0-2.344,0V2.344H8.5V1.172a1.172,1.172,0,1,0-2.344,0V2.344H4.687A4.693,4.693,0,0,0,0,7.031V25.312A4.693,4.693,0,0,0,4.687,30h8.965a1.172,1.172,0,0,0,0-2.344H4.687a2.346,2.346,0,0,1-2.344-2.344V7.031A2.346,2.346,0,0,1,4.687,4.687H6.152V5.859a1.172,1.172,0,1,0,2.344,0V4.687H13.77V5.859a1.172,1.172,0,0,0,2.344,0V4.687h5.332V5.859a1.172,1.172,0,0,0,2.344,0V4.687h1.523a2.346,2.346,0,0,1,2.344,2.344v6.68a1.172,1.172,0,0,0,2.344,0V7.031A4.693,4.693,0,0,0,25.312,2.344Z"
                                                fill="#fff"
                                              />
                                              <path
                                                id="Path_44"
                                                data-name="Path 44"
                                                d="M277.09,270a7.09,7.09,0,1,0,7.09,7.09A7.1,7.1,0,0,0,277.09,270Zm0,11.836a4.746,4.746,0,1,1,4.746-4.746A4.751,4.751,0,0,1,277.09,281.836Z"
                                                transform="translate(-254.18 -254.18)"
                                                fill="#fff"
                                              />
                                              <path
                                                id="Path_45"
                                                data-name="Path 45"
                                                d="M373.871,332.4h-.527v-1.23a1.172,1.172,0,0,0-2.344,0v2.4a1.172,1.172,0,0,0,1.172,1.172h1.7a1.172,1.172,0,1,0,0-2.344Z"
                                                transform="translate(-349.262 -310.664)"
                                                fill="#fff"
                                              />
                                              <ellipse
                                                id="Ellipse_3"
                                                data-name="Ellipse 3"
                                                cx="1.5"
                                                cy="1"
                                                rx="1.5"
                                                ry="1"
                                                transform="translate(15.911 11.5)"
                                                fill="#fff"
                                              />
                                              <ellipse
                                                id="Ellipse_4"
                                                data-name="Ellipse 4"
                                                cx="1"
                                                cy="1.5"
                                                rx="1"
                                                ry="1.5"
                                                transform="translate(11.911 15.5)"
                                                fill="#fff"
                                              />
                                              <ellipse
                                                id="Ellipse_5"
                                                data-name="Ellipse 5"
                                                cx="1.5"
                                                cy="1"
                                                rx="1.5"
                                                ry="1"
                                                transform="translate(5.911 11.5)"
                                                fill="#fff"
                                              />
                                              <circle
                                                id="Ellipse_6"
                                                data-name="Ellipse 6"
                                                cx="1.5"
                                                cy="1.5"
                                                r="1.5"
                                                transform="translate(5.911 15.5)"
                                                fill="#fff"
                                              />
                                              <circle
                                                id="Ellipse_7"
                                                data-name="Ellipse 7"
                                                cx="1.5"
                                                cy="1.5"
                                                r="1.5"
                                                transform="translate(5.911 20.5)"
                                                fill="#fff"
                                              />
                                              <ellipse
                                                id="Ellipse_8"
                                                data-name="Ellipse 8"
                                                cx="1"
                                                cy="1.5"
                                                rx="1"
                                                ry="1.5"
                                                transform="translate(11.911 20.5)"
                                                fill="#fff"
                                              />
                                              <circle
                                                id="Ellipse_9"
                                                data-name="Ellipse 9"
                                                cx="1"
                                                cy="1"
                                                r="1"
                                                transform="translate(11.911 11.5)"
                                                fill="#fff"
                                              />
                                            </g>
                                          </g>
                                        </g>
                                      </svg>
                                      <div
                                        style={{ width: '80%' }}
                                        className="single_noti_content"
                                      >
                                        <div className="noti_category">موعد</div>
                                        <div className="noti_text">
                                          <div className="noti_mobile_date">
                                            <span className="span_time">
                                              {moment(notif.date).format('HH')}h
                                              {moment(notif.date).format('mm')}
                                            </span>
                                            <span className="span_date">
                                              {moment(notif.date).format('DD/MM/YYYY')}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </Link>
                                  </MenuItem>
                                );
                              if (notif.type === 'report')
                                return (
                                  <MenuItem key={notif.id} onClick={handleClose}>
                                    <Link
                                      to={{
                                        pathname: '/reports',
                                        state: { date: new Date(notif.date) },
                                      }}
                                      style={{ width: '100%', display: 'contents' }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="44"
                                        height="44"
                                        viewBox="0 0 44 44"
                                        style={{ width: '20%' }}
                                      >
                                        <circle
                                          id="Ellipse_36"
                                          data-name="Ellipse 36"
                                          cx="22"
                                          cy="22"
                                          r="22"
                                          fill="#ca2946"
                                        />
                                        <g id="calendar" transform="translate(7.089 6.5)">
                                          <g id="Group_11" data-name="Group 11">
                                            <g id="Group_10" data-name="Group 10">
                                              <circle
                                                id="Ellipse_2"
                                                data-name="Ellipse 2"
                                                cx="1"
                                                cy="1"
                                                r="1"
                                                transform="translate(21.911 11.5)"
                                                fill="#fff"
                                              />
                                              <path
                                                id="Path_43"
                                                data-name="Path 43"
                                                d="M25.312,2.344H23.789V1.172a1.172,1.172,0,0,0-2.344,0V2.344H16.113V1.172a1.172,1.172,0,0,0-2.344,0V2.344H8.5V1.172a1.172,1.172,0,1,0-2.344,0V2.344H4.687A4.693,4.693,0,0,0,0,7.031V25.312A4.693,4.693,0,0,0,4.687,30h8.965a1.172,1.172,0,0,0,0-2.344H4.687a2.346,2.346,0,0,1-2.344-2.344V7.031A2.346,2.346,0,0,1,4.687,4.687H6.152V5.859a1.172,1.172,0,1,0,2.344,0V4.687H13.77V5.859a1.172,1.172,0,0,0,2.344,0V4.687h5.332V5.859a1.172,1.172,0,0,0,2.344,0V4.687h1.523a2.346,2.346,0,0,1,2.344,2.344v6.68a1.172,1.172,0,0,0,2.344,0V7.031A4.693,4.693,0,0,0,25.312,2.344Z"
                                                fill="#fff"
                                              />
                                              <path
                                                id="Path_44"
                                                data-name="Path 44"
                                                d="M277.09,270a7.09,7.09,0,1,0,7.09,7.09A7.1,7.1,0,0,0,277.09,270Zm0,11.836a4.746,4.746,0,1,1,4.746-4.746A4.751,4.751,0,0,1,277.09,281.836Z"
                                                transform="translate(-254.18 -254.18)"
                                                fill="#fff"
                                              />
                                              <path
                                                id="Path_45"
                                                data-name="Path 45"
                                                d="M373.871,332.4h-.527v-1.23a1.172,1.172,0,0,0-2.344,0v2.4a1.172,1.172,0,0,0,1.172,1.172h1.7a1.172,1.172,0,1,0,0-2.344Z"
                                                transform="translate(-349.262 -310.664)"
                                                fill="#fff"
                                              />
                                              <ellipse
                                                id="Ellipse_3"
                                                data-name="Ellipse 3"
                                                cx="1.5"
                                                cy="1"
                                                rx="1.5"
                                                ry="1"
                                                transform="translate(15.911 11.5)"
                                                fill="#fff"
                                              />
                                              <ellipse
                                                id="Ellipse_4"
                                                data-name="Ellipse 4"
                                                cx="1"
                                                cy="1.5"
                                                rx="1"
                                                ry="1.5"
                                                transform="translate(11.911 15.5)"
                                                fill="#fff"
                                              />
                                              <ellipse
                                                id="Ellipse_5"
                                                data-name="Ellipse 5"
                                                cx="1.5"
                                                cy="1"
                                                rx="1.5"
                                                ry="1"
                                                transform="translate(5.911 11.5)"
                                                fill="#fff"
                                              />
                                              <circle
                                                id="Ellipse_6"
                                                data-name="Ellipse 6"
                                                cx="1.5"
                                                cy="1.5"
                                                r="1.5"
                                                transform="translate(5.911 15.5)"
                                                fill="#fff"
                                              />
                                              <circle
                                                id="Ellipse_7"
                                                data-name="Ellipse 7"
                                                cx="1.5"
                                                cy="1.5"
                                                r="1.5"
                                                transform="translate(5.911 20.5)"
                                                fill="#fff"
                                              />
                                              <ellipse
                                                id="Ellipse_8"
                                                data-name="Ellipse 8"
                                                cx="1"
                                                cy="1.5"
                                                rx="1"
                                                ry="1.5"
                                                transform="translate(11.911 20.5)"
                                                fill="#fff"
                                              />
                                              <circle
                                                id="Ellipse_9"
                                                data-name="Ellipse 9"
                                                cx="1"
                                                cy="1"
                                                r="1"
                                                transform="translate(11.911 11.5)"
                                                fill="#fff"
                                              />
                                            </g>
                                          </g>
                                        </g>
                                      </svg>
                                      <div
                                        style={{ width: '80%' }}
                                        className="single_noti_content"
                                      >
                                        <div className="noti_category">
                                          {notif.description}
                                        </div>
                                      </div>
                                    </Link>
                                  </MenuItem>
                                );
                              if (notif.type === 'session')
                                return (
                                  <MenuItem key={notif.id} onClick={handleClose}>
                                    <Link
                                      style={{ width: '100%', display: 'contents' }}
                                      key={notif.id}
                                      to={{
                                        pathname: '/session',
                                        state: { date: new Date(notif.date) },
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="44"
                                        height="44"
                                        viewBox="0 0 44 44"
                                        style={{ width: '20%' }}
                                      >
                                        <circle
                                          id="Ellipse_37"
                                          data-name="Ellipse 37"
                                          cx="22"
                                          cy="22"
                                          r="22"
                                          fill="#ca2946"
                                        />
                                        <g id="gavel" transform="translate(5.568 5)">
                                          <path
                                            id="Path_41"
                                            data-name="Path 41"
                                            d="M240.99,422.673v3.707a.923.923,0,0,0,.923.923H256.74a.923.923,0,0,0,.923-.923v-3.707a.923.923,0,0,0-.923-.923H241.913A.923.923,0,0,0,240.99,422.673Zm1.846.923h12.982v1.861H242.836Z"
                                            transform="translate(-226.164 -395.802)"
                                            fill="#fff"
                                          />
                                          <path
                                            id="Path_42"
                                            data-name="Path 42"
                                            d="M.822,27.786a2.779,2.779,0,0,0,3.926,0L15.891,16.644l1.316,1.316-.658.658a.923.923,0,0,0,0,1.305l2.621,2.621a.923.923,0,0,0,1.305,0l7.863-7.863a.923.923,0,0,0,0-1.305l-2.621-2.621a.923.923,0,0,0-1.305,0l-.658.658L17.2,4.854l.658-.658a.923.923,0,0,0,0-1.305L15.233.27a.923.923,0,0,0-1.305,0L6.065,8.134a.923.923,0,0,0,0,1.305L8.686,12.06a.923.923,0,0,0,1.305,0l.658-.658,1.316,1.316L.822,23.86a2.779,2.779,0,0,0,0,3.926ZM15.9,3.544,9.338,10.1,8.022,8.786,14.58,2.228Zm2.61,15.727,6.558-6.558,1.316,1.316-6.558,6.558Zm.005-2.616L11.954,10.1,15.891,6.16l6.558,6.558ZM2.127,25.165,13.27,14.023l1.316,1.316L3.443,26.481a.931.931,0,1,1-1.316-1.316Z"
                                            transform="translate(-0.011 0)"
                                            fill="#fff"
                                          />
                                        </g>
                                      </svg>

                                      <div
                                        style={{ width: '80%' }}
                                        className="single_noti_content"
                                      >
                                        <div className="noti_category">
                                          {notif.description}
                                        </div>
                                        {/*<div className="noti_text">
                                          <div className="noti_mobile_date">
                                            <span className="span_time">
                                              {moment(notif.date).format('HH')}h
                                              {moment(notif.date).format('mm')}
                                            </span>
                                            <span className="span_date">
                                              {moment(notif.date).format('DD/MM/YYYY')}
                                            </span>
                                          </div>
                                        </div>*/}
                                      </div>
                                    </Link>
                                  </MenuItem>
                                );
                            }
                          })
                        : ''}
                      {notifications && notifications.length > 0 ? (
                        <MenuItem key="notifications" onClick={handleClose}>
                          <Link style={{ textAlign: 'center' }} to="/notifications">
                            المزيد
                            <svg
                              className=""
                              xmlns="http://www.w3.org/2000/svg"
                              width="15.225"
                              height="5.492"
                              viewBox="0 0 15.225 5.492"
                            >
                              <path
                                id="Path_53"
                                data-name="Path 53"
                                d="M3020.1,1976.5l7.3,4.478,7.4-4.478"
                                transform="translate(-3019.841 -1976.072)"
                                fill="none"
                                stroke="var(--color-primary-red)"
                                strokeWidth="1"
                              />
                            </svg>
                          </Link>
                        </MenuItem>
                      ) : (
                        <>
                          <MenuItem key="notifications" onClick={handleClose}>
                            <span> لا يوجد اشعارات جديدة</span>{' '}
                          </MenuItem>
                          <MenuItem key="notifications" onClick={handleClose}>
                            <Link style={{ textAlign: 'center' }} to="/notifications">
                              المزيد
                              <svg
                                className=""
                                xmlns="http://www.w3.org/2000/svg"
                                width="15.225"
                                height="5.492"
                                viewBox="0 0 15.225 5.492"
                              >
                                <path
                                  id="Path_53"
                                  data-name="Path 53"
                                  d="M3020.1,1976.5l7.3,4.478,7.4-4.478"
                                  transform="translate(-3019.841 -1976.072)"
                                  fill="none"
                                  stroke="var(--color-primary-red)"
                                  strokeWidth="1"
                                />
                              </svg>
                            </Link>
                          </MenuItem>
                        </>
                      )}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
        {/*  <div className="top_bar_notifications">
          
          <Badge
            invisible={notification > 0 ? false : true}
            badgeContent={notification}
            color="secondary"
          >
            <svg
              onClick={() => {
                updateHideDropDownNotif(!hideDropDownNotif);
                updateHideDropDown(false);
                if (!hideDropDownNotif) {
                  getNotificationIsSeen();
                  getListNotifications(paramsNotification);
                }
              }}
              className="noti_dropdown"
              version="1.2"
              baseProfile="tiny-ps"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 29 31"
              width="25"
              height="31"
            >
              <g id="">
                <g id="Groupe 116">
                  <g id="notification">
                    <path
                      id="Tracé 156"
                      className="shp0"
                      d="M12.82 31C10.17 31 8.01 28.84 8.01 26.19C8.01 25.66 8.44 25.23 8.97 25.23C9.5 25.23 9.93 25.66 9.93 26.19C9.93 27.78 11.23 29.08 12.82 29.08C14.41 29.08 15.7 27.78 15.7 26.19C15.7 25.66 16.13 25.23 16.66 25.23C17.19 25.23 17.62 25.66 17.62 26.19C17.62 28.84 15.47 31 12.82 31Z"
                    />
                    <path
                      id="Tracé 157"
                      className="shp0"
                      d="M23.39 27.15L2.24 27.15C1.01 27.15 0 26.15 0 24.91C0 24.26 0.29 23.63 0.78 23.21C0.82 23.18 0.85 23.15 0.89 23.13C2.77 21.49 3.84 19.13 3.84 16.63L3.84 13.06C3.84 8.11 7.87 4.09 12.82 4.09C13.02 4.09 13.24 4.09 13.45 4.12C13.97 4.21 14.33 4.71 14.24 5.23C14.15 5.75 13.65 6.11 13.13 6.02C13.03 6 12.92 6.01 12.82 6.01C8.93 6.01 5.77 9.17 5.77 13.06L5.77 16.63C5.77 19.73 4.41 22.66 2.05 24.67C2.03 24.69 2.01 24.7 1.99 24.71C1.96 24.76 1.92 24.82 1.92 24.91C1.92 25.09 2.07 25.23 2.24 25.23L23.39 25.23C23.56 25.23 23.71 25.09 23.71 24.91C23.71 24.82 23.68 24.76 23.64 24.71C23.62 24.7 23.61 24.69 23.59 24.67C21.22 22.66 19.87 19.73 19.87 16.63L19.87 15.24C19.87 14.71 20.3 14.27 20.83 14.27C21.36 14.27 21.79 14.71 21.79 15.24L21.79 16.63C21.79 19.13 22.87 21.49 24.75 23.13C24.78 23.16 24.82 23.18 24.85 23.21C25.35 23.63 25.63 24.26 25.63 24.91C25.63 26.15 24.63 27.15 23.39 27.15L23.39 27.15Z"
                    />
                    <path
                      id="Tracé 157 copy"
                      className="shp0"
                      d="M23.39 27.15L2.24 27.15C1.01 27.15 0 26.15 0 24.91C0 24.26 0.29 23.63 0.78 23.21C0.82 23.18 0.85 23.15 0.89 23.13C2.77 21.49 3.84 19.13 3.84 16.63L3.84 13.06C3.84 8.11 7.87 4.09 12.82 4.09C13.02 4.09 13.24 4.09 13.45 4.12C13.97 4.21 14.33 4.71 14.24 5.23C14.15 5.75 13.65 6.11 13.13 6.02C13.03 6 12.92 6.01 12.82 6.01C8.93 6.01 5.77 9.17 5.77 13.06L5.77 16.63C5.77 19.73 4.41 22.66 2.05 24.67C2.03 24.69 2.01 24.7 1.99 24.71C1.96 24.76 1.92 24.82 1.92 24.91C1.92 25.09 2.07 25.23 2.24 25.23L23.39 25.23C23.56 25.23 23.71 25.09 23.71 24.91C23.71 24.82 23.68 24.76 23.64 24.71C23.62 24.7 23.61 24.69 23.59 24.67C21.22 22.66 19.87 19.73 19.87 16.63L19.87 15.24C19.87 14.71 20.3 14.27 20.83 14.27C21.36 14.27 21.79 14.71 21.79 15.24L21.79 16.63C21.79 19.13 22.87 21.49 24.75 23.13C24.78 23.16 24.82 23.18 24.85 23.21C25.35 23.63 25.63 24.26 25.63 24.91C25.63 26.15 24.63 27.15 23.39 27.15L23.39 27.15Z"
                    />
                    <path
                      id="Tracé 157 copy 2"
                      className="shp0"
                      d="M23.39 27.15L2.24 27.15C1.01 27.15 0 26.15 0 24.91C0 24.26 0.29 23.63 0.78 23.21C0.82 23.18 0.85 23.15 0.89 23.13C2.77 21.49 3.84 19.13 3.84 16.63L3.84 13.06C3.84 8.11 7.87 4.09 12.82 4.09C13.02 4.09 13.24 4.09 13.45 4.12C13.97 4.21 14.33 4.71 14.24 5.23C14.15 5.75 13.65 6.11 13.13 6.02C13.03 6 12.92 6.01 12.82 6.01C8.93 6.01 5.77 9.17 5.77 13.06L5.77 16.63C5.77 19.73 4.41 22.66 2.05 24.67C2.03 24.69 2.01 24.7 1.99 24.71C1.96 24.76 1.92 24.82 1.92 24.91C1.92 25.09 2.07 25.23 2.24 25.23L23.39 25.23C23.56 25.23 23.71 25.09 23.71 24.91C23.71 24.82 23.68 24.76 23.64 24.71C23.62 24.7 23.61 24.69 23.59 24.67C21.22 22.66 19.87 19.73 19.87 16.63L19.87 15.24C19.87 14.71 20.3 14.27 20.83 14.27C21.36 14.27 21.79 14.71 21.79 15.24L21.79 16.63C21.79 19.13 22.87 21.49 24.75 23.13C24.78 23.16 24.82 23.18 24.85 23.21C25.35 23.63 25.63 24.26 25.63 24.91C25.63 26.15 24.63 27.15 23.39 27.15L23.39 27.15Z"
                    />
                    <path
                      id="Tracé 158"
                      className="shp1"
                      d="M21.79 13.06C18.25 13.06 15.38 10.18 15.38 6.65C15.38 3.11 18.25 0.24 21.79 0.24C25.32 0.24 28.2 3.11 28.2 6.65C28.2 10.18 25.32 13.06 21.79 13.06Z"
                    />
                  </g>
                  <text id="3 " style={{ transform: 'matrix(1,0,0,1,24,9)' }}>
                    <tspan id="numberOfNotifications" x="0" y="0" className="txt2">
                      {notification > 0 ? notification : ''}
                    </tspan>
                  </text>
                </g>
              </g>
            </svg>
          </Badge>
          <div
            style={{ display: hideDropDownNotif ? 'flex' : 'none' }}
            className="noti_dropdown__content"
          >
            {notifications && notifications.length > 0
              ? notifications.map((notif) => {
                  i++;
                  if (i <= 4) {
                    if (notif.type === 'rdv')
                      return (
                        <Link
                          key={notif.id}
                          to={{
                            pathname: '/appointment',
                            state: { date: new Date(notif.date) },
                          }}
                          onClick={() => {
                            updateHideDropDownNotif(!hideDropDownNotif);
                            updateHideDropDown(false);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="44"
                            height="44"
                            viewBox="0 0 44 44"
                          >
                            <circle
                              id="Ellipse_36"
                              data-name="Ellipse 36"
                              cx="22"
                              cy="22"
                              r="22"
                              fill="#ca2946"
                            />
                            <g id="calendar" transform="translate(7.089 6.5)">
                              <g id="Group_11" data-name="Group 11">
                                <g id="Group_10" data-name="Group 10">
                                  <circle
                                    id="Ellipse_2"
                                    data-name="Ellipse 2"
                                    cx="1"
                                    cy="1"
                                    r="1"
                                    transform="translate(21.911 11.5)"
                                    fill="#fff"
                                  />
                                  <path
                                    id="Path_43"
                                    data-name="Path 43"
                                    d="M25.312,2.344H23.789V1.172a1.172,1.172,0,0,0-2.344,0V2.344H16.113V1.172a1.172,1.172,0,0,0-2.344,0V2.344H8.5V1.172a1.172,1.172,0,1,0-2.344,0V2.344H4.687A4.693,4.693,0,0,0,0,7.031V25.312A4.693,4.693,0,0,0,4.687,30h8.965a1.172,1.172,0,0,0,0-2.344H4.687a2.346,2.346,0,0,1-2.344-2.344V7.031A2.346,2.346,0,0,1,4.687,4.687H6.152V5.859a1.172,1.172,0,1,0,2.344,0V4.687H13.77V5.859a1.172,1.172,0,0,0,2.344,0V4.687h5.332V5.859a1.172,1.172,0,0,0,2.344,0V4.687h1.523a2.346,2.346,0,0,1,2.344,2.344v6.68a1.172,1.172,0,0,0,2.344,0V7.031A4.693,4.693,0,0,0,25.312,2.344Z"
                                    fill="#fff"
                                  />
                                  <path
                                    id="Path_44"
                                    data-name="Path 44"
                                    d="M277.09,270a7.09,7.09,0,1,0,7.09,7.09A7.1,7.1,0,0,0,277.09,270Zm0,11.836a4.746,4.746,0,1,1,4.746-4.746A4.751,4.751,0,0,1,277.09,281.836Z"
                                    transform="translate(-254.18 -254.18)"
                                    fill="#fff"
                                  />
                                  <path
                                    id="Path_45"
                                    data-name="Path 45"
                                    d="M373.871,332.4h-.527v-1.23a1.172,1.172,0,0,0-2.344,0v2.4a1.172,1.172,0,0,0,1.172,1.172h1.7a1.172,1.172,0,1,0,0-2.344Z"
                                    transform="translate(-349.262 -310.664)"
                                    fill="#fff"
                                  />
                                  <ellipse
                                    id="Ellipse_3"
                                    data-name="Ellipse 3"
                                    cx="1.5"
                                    cy="1"
                                    rx="1.5"
                                    ry="1"
                                    transform="translate(15.911 11.5)"
                                    fill="#fff"
                                  />
                                  <ellipse
                                    id="Ellipse_4"
                                    data-name="Ellipse 4"
                                    cx="1"
                                    cy="1.5"
                                    rx="1"
                                    ry="1.5"
                                    transform="translate(11.911 15.5)"
                                    fill="#fff"
                                  />
                                  <ellipse
                                    id="Ellipse_5"
                                    data-name="Ellipse 5"
                                    cx="1.5"
                                    cy="1"
                                    rx="1.5"
                                    ry="1"
                                    transform="translate(5.911 11.5)"
                                    fill="#fff"
                                  />
                                  <circle
                                    id="Ellipse_6"
                                    data-name="Ellipse 6"
                                    cx="1.5"
                                    cy="1.5"
                                    r="1.5"
                                    transform="translate(5.911 15.5)"
                                    fill="#fff"
                                  />
                                  <circle
                                    id="Ellipse_7"
                                    data-name="Ellipse 7"
                                    cx="1.5"
                                    cy="1.5"
                                    r="1.5"
                                    transform="translate(5.911 20.5)"
                                    fill="#fff"
                                  />
                                  <ellipse
                                    id="Ellipse_8"
                                    data-name="Ellipse 8"
                                    cx="1"
                                    cy="1.5"
                                    rx="1"
                                    ry="1.5"
                                    transform="translate(11.911 20.5)"
                                    fill="#fff"
                                  />
                                  <circle
                                    id="Ellipse_9"
                                    data-name="Ellipse 9"
                                    cx="1"
                                    cy="1"
                                    r="1"
                                    transform="translate(11.911 11.5)"
                                    fill="#fff"
                                  />
                                </g>
                              </g>
                            </g>
                          </svg>
                          <div className="single_noti_content">
                            <div className="noti_category">موعد</div>
                            <div className="noti_text">
                              <div className="noti_mobile_date">
                                <span className="span_time">
                                  {moment(notif.date).format('HH')}h
                                  {moment(notif.date).format('mm')}
                                </span>
                                <span className="span_date">
                                  {moment(notif.date).format('DD/MM/YYYY')}
                                </span>
                              </div>
                            </div>
                          </div>
                        </Link>
                      );
                    if (notif.type === 'report')
                      return (
                        <Link
                          key={notif.id}
                          to={{
                            pathname: '/reports',
                            state: { date: new Date(notif.date) },
                          }}
                          onClick={() => {
                            updateHideDropDownNotif(!hideDropDownNotif);
                            updateHideDropDown(false);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="44"
                            height="44"
                            viewBox="0 0 44 44"
                          >
                            <circle
                              id="Ellipse_36"
                              data-name="Ellipse 36"
                              cx="22"
                              cy="22"
                              r="22"
                              fill="#ca2946"
                            />
                            <g id="calendar" transform="translate(7.089 6.5)">
                              <g id="Group_11" data-name="Group 11">
                                <g id="Group_10" data-name="Group 10">
                                  <circle
                                    id="Ellipse_2"
                                    data-name="Ellipse 2"
                                    cx="1"
                                    cy="1"
                                    r="1"
                                    transform="translate(21.911 11.5)"
                                    fill="#fff"
                                  />
                                  <path
                                    id="Path_43"
                                    data-name="Path 43"
                                    d="M25.312,2.344H23.789V1.172a1.172,1.172,0,0,0-2.344,0V2.344H16.113V1.172a1.172,1.172,0,0,0-2.344,0V2.344H8.5V1.172a1.172,1.172,0,1,0-2.344,0V2.344H4.687A4.693,4.693,0,0,0,0,7.031V25.312A4.693,4.693,0,0,0,4.687,30h8.965a1.172,1.172,0,0,0,0-2.344H4.687a2.346,2.346,0,0,1-2.344-2.344V7.031A2.346,2.346,0,0,1,4.687,4.687H6.152V5.859a1.172,1.172,0,1,0,2.344,0V4.687H13.77V5.859a1.172,1.172,0,0,0,2.344,0V4.687h5.332V5.859a1.172,1.172,0,0,0,2.344,0V4.687h1.523a2.346,2.346,0,0,1,2.344,2.344v6.68a1.172,1.172,0,0,0,2.344,0V7.031A4.693,4.693,0,0,0,25.312,2.344Z"
                                    fill="#fff"
                                  />
                                  <path
                                    id="Path_44"
                                    data-name="Path 44"
                                    d="M277.09,270a7.09,7.09,0,1,0,7.09,7.09A7.1,7.1,0,0,0,277.09,270Zm0,11.836a4.746,4.746,0,1,1,4.746-4.746A4.751,4.751,0,0,1,277.09,281.836Z"
                                    transform="translate(-254.18 -254.18)"
                                    fill="#fff"
                                  />
                                  <path
                                    id="Path_45"
                                    data-name="Path 45"
                                    d="M373.871,332.4h-.527v-1.23a1.172,1.172,0,0,0-2.344,0v2.4a1.172,1.172,0,0,0,1.172,1.172h1.7a1.172,1.172,0,1,0,0-2.344Z"
                                    transform="translate(-349.262 -310.664)"
                                    fill="#fff"
                                  />
                                  <ellipse
                                    id="Ellipse_3"
                                    data-name="Ellipse 3"
                                    cx="1.5"
                                    cy="1"
                                    rx="1.5"
                                    ry="1"
                                    transform="translate(15.911 11.5)"
                                    fill="#fff"
                                  />
                                  <ellipse
                                    id="Ellipse_4"
                                    data-name="Ellipse 4"
                                    cx="1"
                                    cy="1.5"
                                    rx="1"
                                    ry="1.5"
                                    transform="translate(11.911 15.5)"
                                    fill="#fff"
                                  />
                                  <ellipse
                                    id="Ellipse_5"
                                    data-name="Ellipse 5"
                                    cx="1.5"
                                    cy="1"
                                    rx="1.5"
                                    ry="1"
                                    transform="translate(5.911 11.5)"
                                    fill="#fff"
                                  />
                                  <circle
                                    id="Ellipse_6"
                                    data-name="Ellipse 6"
                                    cx="1.5"
                                    cy="1.5"
                                    r="1.5"
                                    transform="translate(5.911 15.5)"
                                    fill="#fff"
                                  />
                                  <circle
                                    id="Ellipse_7"
                                    data-name="Ellipse 7"
                                    cx="1.5"
                                    cy="1.5"
                                    r="1.5"
                                    transform="translate(5.911 20.5)"
                                    fill="#fff"
                                  />
                                  <ellipse
                                    id="Ellipse_8"
                                    data-name="Ellipse 8"
                                    cx="1"
                                    cy="1.5"
                                    rx="1"
                                    ry="1.5"
                                    transform="translate(11.911 20.5)"
                                    fill="#fff"
                                  />
                                  <circle
                                    id="Ellipse_9"
                                    data-name="Ellipse 9"
                                    cx="1"
                                    cy="1"
                                    r="1"
                                    transform="translate(11.911 11.5)"
                                    fill="#fff"
                                  />
                                </g>
                              </g>
                            </g>
                          </svg>
                          <div className="single_noti_content">
                            <div className="noti_category">{notif.description}</div>
                            <div className="noti_text">
                              <div className="noti_mobile_date">
                                <span className="span_name">{`${notif.lawyer.first_name} ${notif.lawyer.last_name}`}</span>
                              </div>
                            </div>
                          </div>
                        </Link>
                      );
                    if (notif.type === 'session')
                      return (
                        <Link
                          key={notif.id}
                          to={{
                            pathname: '/session',
                            state: { date: new Date(notif.date) },
                          }}
                          onClick={() => {
                            updateHideDropDownNotif(!hideDropDownNotif);
                            updateHideDropDown(false);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="44"
                            height="44"
                            viewBox="0 0 44 44"
                          >
                            <circle
                              id="Ellipse_37"
                              data-name="Ellipse 37"
                              cx="22"
                              cy="22"
                              r="22"
                              fill="#ca2946"
                            />
                            <g id="gavel" transform="translate(5.568 5)">
                              <path
                                id="Path_41"
                                data-name="Path 41"
                                d="M240.99,422.673v3.707a.923.923,0,0,0,.923.923H256.74a.923.923,0,0,0,.923-.923v-3.707a.923.923,0,0,0-.923-.923H241.913A.923.923,0,0,0,240.99,422.673Zm1.846.923h12.982v1.861H242.836Z"
                                transform="translate(-226.164 -395.802)"
                                fill="#fff"
                              />
                              <path
                                id="Path_42"
                                data-name="Path 42"
                                d="M.822,27.786a2.779,2.779,0,0,0,3.926,0L15.891,16.644l1.316,1.316-.658.658a.923.923,0,0,0,0,1.305l2.621,2.621a.923.923,0,0,0,1.305,0l7.863-7.863a.923.923,0,0,0,0-1.305l-2.621-2.621a.923.923,0,0,0-1.305,0l-.658.658L17.2,4.854l.658-.658a.923.923,0,0,0,0-1.305L15.233.27a.923.923,0,0,0-1.305,0L6.065,8.134a.923.923,0,0,0,0,1.305L8.686,12.06a.923.923,0,0,0,1.305,0l.658-.658,1.316,1.316L.822,23.86a2.779,2.779,0,0,0,0,3.926ZM15.9,3.544,9.338,10.1,8.022,8.786,14.58,2.228Zm2.61,15.727,6.558-6.558,1.316,1.316-6.558,6.558Zm.005-2.616L11.954,10.1,15.891,6.16l6.558,6.558ZM2.127,25.165,13.27,14.023l1.316,1.316L3.443,26.481a.931.931,0,1,1-1.316-1.316Z"
                                transform="translate(-0.011 0)"
                                fill="#fff"
                              />
                            </g>
                          </svg>

                          <div className="single_noti_content">
                            <div className="noti_category">جلسة</div>
                            <div className="noti_text">
                              <div className="noti_mobile_date">
                                <span className="span_time">
                                  {moment(notif.date).format('HH')}h
                                  {moment(notif.date).format('mm')}
                                </span>
                                <span className="span_date">
                                  {moment(notif.date).format('DD/MM/YYYY')}
                                </span>
                              </div>
                            </div>
                          </div>
                        </Link>
                      );
                  }
                })
              : ''}
            {notifications && notifications.length > 0 ? (
              <div className="noti_more">
                <Link
                  to="/notifications"
                  onClick={() => {
                    updateHideDropDownNotif(!hideDropDownNotif);
                    updateHideDropDown(false);
                  }}
                >
                  المزيد
                  <svg
                    className=""
                    xmlns="http://www.w3.org/2000/svg"
                    width="15.225"
                    height="5.492"
                    viewBox="0 0 15.225 5.492"
                  >
                    <path
                      id="Path_53"
                      data-name="Path 53"
                      d="M3020.1,1976.5l7.3,4.478,7.4-4.478"
                      transform="translate(-3019.841 -1976.072)"
                      fill="none"
                      stroke="var(--color-primary-red)"
                      strokeWidth="1"
                    />
                  </svg>
                </Link>
              </div>
            ) : (
              <div className="noti_more">لا يجد اشعارات جديدة</div>
            )}
          </div>
        </div>*/}
        <div
          style={{
            display: 'contents',
          }}
        >
          <div
            ref={anchorRef}
            id="composition-button"
            aria-controls={open ? 'composition-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            style={{ cursor: 'pointer' }}
            className="top_bar__identifier"
          >
            <div className="top_bar__pic">
              <div className="top_bar__user_initial" id="home_top_bar__user_initial">
                {account.first_name.charAt(0)}
              </div>
            </div>
            <div className="top_bar__username" id="home_top_bar__username">
              {`${account.first_name} ${account.last_name}`}
            </div>
          </div>
          <div
            style={{ cursor: 'pointer' }}
            ref={anchorRef}
            id="composition-button"
            aria-controls={open ? 'composition-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <svg
              className="side__mobile_menu-open"
              id="side__mobile_menu-open"
              xmlns="http://www.w3.org/2000/svg"
              width="35"
              height="25"
              viewBox="0 0 37 25"
            >
              <g id="Group_107" data-name="Group 107" transform="translate(-365 -24)">
                <rect
                  id="Rectangle_8"
                  data-name="Rectangle 8"
                  width="37"
                  height="5"
                  rx="2.5"
                  transform="translate(365 24)"
                  fill="#961829"
                ></rect>
                <rect
                  id="Rectangle_9"
                  data-name="Rectangle 9"
                  width="27"
                  height="5"
                  rx="2.5"
                  transform="translate(365 34)"
                  fill="#961829"
                ></rect>
                <rect
                  id="Rectangle_10"
                  data-name="Rectangle 10"
                  width="32"
                  height="5"
                  rx="2.5"
                  transform="translate(365 44)"
                  fill="#961829"
                ></rect>
              </g>
            </svg>
          </div>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal
            style={{
              zIndex: '2000',
            }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      className="user_dropdown__content"
                      autoFocusItem={open}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem onClick={handleClose}>
                        <Link
                          to="/questions"
                          /*onClick={() => {
                            updateHideDropDown(!hideDropDown);
                            updateHideDropDownNotif(false);
                          }}*/
                        >
                          <HelpOutlineIcon
                            style={{ cursor: 'pointer' }}
                            fontSize="large"
                            htmlColor="rgb(189, 35, 60)"
                          />
                          أسئلة
                        </Link>
                      </MenuItem>
                      <MenuItem onClick={handleClose}>
                        <Link
                          to="/legal-journals"
                          /*onClick={() => {
                            updateHideDropDown(!hideDropDown);
                            updateHideDropDownNotif(false);
                          }}*/
                        >
                          <svg
                            className="home_menu_svg"
                            xmlns="http://www.w3.org/2000/svg"
                            width="28.5"
                            height="25.501"
                            viewBox="0 0 42.084 42.111"
                          >
                            <g id="balance" transform="translate(0.248 0.308)">
                              <g data-name="Group 16" transform="translate(0.002 -0.001)">
                                <path
                                  data-name="Path 57"
                                  d="M41.486,25.338l.006,0L34.67,10.45l1.655-.823-.619-1.24-1.613.8-.218-.476a.693.693,0,0,0-1.259,0l-.195.425a8.909,8.909,0,0,0-3.747-.829H22.856V6.925h1.385V5.54H23.051l.5-1.993a2.855,2.855,0,1,0-5.541,0l.5,1.993H17.316V6.925H18.7V8.31H12.887a8.909,8.909,0,0,0-3.75.831l-.195-.425a.693.693,0,0,0-1.259,0l-.218.476L5.852,8.385,5.233,9.626l1.655.827L.065,25.336A.676.676,0,0,0,0,25.625,4.853,4.853,0,0,0,4.85,30.472h6.926a4.853,4.853,0,0,0,4.848-4.848.677.677,0,0,0-.069-.287L9.713,10.4a7.533,7.533,0,0,1,3.174-.7H18.7V35.034L16.337,37.4H13.853a3.467,3.467,0,0,0-3.463,3.463.693.693,0,0,0,.693.693H30.475a.693.693,0,0,0,.693-.693A3.467,3.467,0,0,0,27.7,37.4H25.221l-2.364-2.364V9.7h5.818a7.533,7.533,0,0,1,3.174.7L25,25.336a.676.676,0,0,0-.063.289,4.853,4.853,0,0,0,4.848,4.848h6.926a4.853,4.853,0,0,0,4.848-4.848A.674.674,0,0,0,41.486,25.338Zm-29.711,3.75H4.85a3.468,3.468,0,0,1-3.394-2.77H15.169A3.468,3.468,0,0,1,11.775,29.087Zm3.076-4.155H1.773l6.362-13.88a.684.684,0,0,0,.355,0ZM19.62,1.95a1.515,1.515,0,0,1,2.317,0,1.462,1.462,0,0,1,.268,1.262L21.623,5.54H19.935l-.582-2.328A1.462,1.462,0,0,1,19.62,1.95Zm.466,6.36V6.925h1.385V8.31ZM21.471,9.7V34.628H20.086V9.7ZM27.7,38.783a2.082,2.082,0,0,1,1.959,1.385H11.894a2.082,2.082,0,0,1,1.959-1.385ZM23.262,37.4H18.3l1.385-1.385h2.2Zm9.8-26.344a.473.473,0,0,0,.35-.015l6.367,13.892H26.706Zm3.641,18.033H29.782a3.468,3.468,0,0,1-3.394-2.77H40.1A3.468,3.468,0,0,1,36.708,29.087Z"
                                  transform="translate(-0.002 0.001)"
                                  fill="#ca2946"
                                  stroke="#ca2946"
                                  strokeWidth="0.5"
                                />
                              </g>
                            </g>
                          </svg>
                          المجلات القانونية
                        </Link>
                      </MenuItem>
                      <MenuItem onClick={handleClose}>
                        {' '}
                        <Link
                          to="/jurisprudence"
                          /*onClick={() => {
                            updateHideDropDown(!hideDropDown);
                            updateHideDropDownNotif(false);
                          }}*/
                        >
                          <svg
                            className="home_menu_svg"
                            xmlns="http://www.w3.org/2000/svg"
                            width="28.5"
                            height="25.501"
                            viewBox="0 0 36.5 36.501"
                          >
                            <g id="courthouse" transform="translate(0.5 0.5)">
                              <g
                                id="Group_21"
                                data-name="Group 21"
                                transform="translate(0 0.001)"
                              >
                                <path
                                  data-name="Path 73"
                                  d="M31.2,14.021a.52.52,0,0,0,.52-.52V11.687h.461a.52.52,0,0,0,.52-.52V9.445H34.98a.52.52,0,0,0,.52-.52V5a.52.52,0,0,0-.389-.5L17.881.017a.52.52,0,0,0-.262,0L.389,4.5A.52.52,0,0,0,0,5V8.925a.52.52,0,0,0,.52.52H2.8v1.721a.52.52,0,0,0,.52.52h.461v16.05H3.322a.52.52,0,0,0-.52.52v1.721H1.921a.52.52,0,0,0-.52.52v1.721H.52a.52.52,0,0,0-.52.52V34.98a.52.52,0,0,0,.52.52H34.98a.52.52,0,0,0,.52-.52V32.739a.52.52,0,0,0-.52-.52H34.1V30.5a.52.52,0,0,0-.52-.52H32.7V28.257a.52.52,0,0,0-.52-.52h-.461V15.928a.52.52,0,0,0-1.04,0V27.737H26.955V11.687h3.723V13.5a.52.52,0,0,0,.52.52Zm.461-3.374H25.974v-1.2h5.684ZM1.04,5.405,17.75,1.058,34.46,5.405v3H1.04Zm19.552,5.242H14.908v-1.2h5.684ZM9.526,9.445v1.2H3.842v-1.2Zm-4.7,2.241H8.545v4.044a.52.52,0,0,0,1.04,0V11.687h.461a.52.52,0,0,0,.52-.52V9.445h3.3v1.721a.52.52,0,0,0,.52.52h.461v16.05h-.461a.52.52,0,0,0-.52.52v1.721h-3.3V28.257a.52.52,0,0,0-.52-.52H9.585V18.157a.52.52,0,0,0-1.04,0v9.579H4.822Zm15.77,17.09v1.2H14.908v-1.2Zm-16.75,0H9.526v1.2H3.842ZM34.46,34.46H1.04v-1.2H34.46Zm-1.4-2.241H2.441v-1.2H33.059Zm-7.085-2.241v-1.2h5.684v1.2Zm-.059-2.241h-.461a.52.52,0,0,0-.52.52v1.721h-3.3V28.257a.52.52,0,0,0-.52-.52h-.46V22.91a.52.52,0,0,0-1.04,0v4.826H15.889V11.687h3.723v8.8a.52.52,0,0,0,1.04,0v-8.8h.46a.52.52,0,0,0,.52-.52V9.445h3.3v1.721a.52.52,0,0,0,.52.52h.461Z"
                                  transform="translate(0 -0.001)"
                                  fill="#ca2946"
                                  stroke="#ca2946"
                                  strokeWidth="1"
                                />
                                <path
                                  data-name="Path 74"
                                  d="M223.167,38.227a2.446,2.446,0,1,0-2.446-2.446A2.449,2.449,0,0,0,223.167,38.227Zm0-3.852a1.406,1.406,0,1,1-1.406,1.406A1.408,1.408,0,0,1,223.167,34.375Z"
                                  transform="translate(-205.417 -31.024)"
                                  fill="#ca2946"
                                  stroke="#ca2946"
                                  strokeWidth="1"
                                />
                                <path
                                  data-name="Path 75"
                                  d="M309.629,55.589h.7a.52.52,0,0,0,0-1.04h-.7a.52.52,0,0,0,0,1.04Z"
                                  transform="translate(-287.677 -50.767)"
                                  fill="#ca2946"
                                  stroke="#ca2946"
                                  strokeWidth="1"
                                />
                                <path
                                  data-name="Path 76"
                                  d="M178.309,55.589h.7a.52.52,0,0,0,0-1.04h-.7a.52.52,0,0,0,0,1.04Z"
                                  transform="translate(-165.462 -50.767)"
                                  fill="#ca2946"
                                  stroke="#ca2946"
                                  strokeWidth="1"
                                />
                                <path
                                  data-name="Path 77"
                                  d="M309.629,86.4h.7a.52.52,0,0,0,0-1.04h-.7a.52.52,0,0,0,0,1.04Z"
                                  transform="translate(-287.677 -79.441)"
                                  fill="#ca2946"
                                  stroke="#ca2946"
                                  strokeWidth="1"
                                />
                                <path
                                  data-name="Path 78"
                                  d="M178.309,86.4h.7a.52.52,0,0,0,0-1.04h-.7a.52.52,0,0,0,0,1.04Z"
                                  transform="translate(-165.462 -79.441)"
                                  fill="#ca2946"
                                  stroke="#ca2946"
                                  strokeWidth="1"
                                />
                              </g>
                            </g>
                          </svg>
                          فقه القضاء
                        </Link>
                      </MenuItem>
                      <MenuItem onClick={handleClose}>
                        <Link
                          to=""
                          onClick={() => {
                            setOpenLogoutPopup(true);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25.135"
                            height="25.136"
                            viewBox="0 0 25.135 25.136"
                          >
                            <path
                              id="exit"
                              d="M18.223,19.245v1.964A3.932,3.932,0,0,1,14.3,25.136H4.428A3.932,3.932,0,0,1,.5,21.209V3.928A3.932,3.932,0,0,1,4.428,0H14.3a3.932,3.932,0,0,1,3.928,3.928V5.891a.982.982,0,0,1-1.964,0V3.928A1.966,1.966,0,0,0,14.3,1.964H4.428A1.966,1.966,0,0,0,2.464,3.928V21.209a1.966,1.966,0,0,0,1.964,1.964H14.3a1.966,1.966,0,0,0,1.964-1.964V19.245a.982.982,0,0,1,1.964,0Zm6.694-8.364-2.2-2.2a.982.982,0,1,0-1.389,1.388l1.564,1.564H11.1a.982.982,0,0,0,0,1.964h11.79L21.33,15.163a.982.982,0,1,0,1.389,1.388l2.2-2.2a2.457,2.457,0,0,0,0-3.472Zm0,0"
                              transform="translate(-0.5)"
                              fill="#ca2946"
                              stroke="#ca2946"
                            />
                          </svg>
                          خروج
                        </Link>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
      <div className={openLogoutPopup ? 'popup active' : 'popup'}>
        <div className="popup__title">هل أنت متأكد أنك تريد تسجيل الخروج؟</div>
        <div className="popup__btn_grp">
          <button onClick={() => setOpenLogoutPopup(false)} className="cancel_popup">
            <span>الغاء</span>
            <span>&lt;</span>
          </button>
          <button
            style={{
              background: '#63B17D',
            }}
            onClick={() => {
              logout(account.login_access.id);
              localStorage.clear();
              window.location.href = '/';
              setOpenLogoutPopup(false);
            }}
          >
            <span>تأكيد</span>
            <span className="trashSpan">
              <svg
                className="tickSpan"
                xmlns="http://www.w3.org/2000/svg"
                width="20.125"
                height="17.774"
                viewBox="0 0 29.125 23.774"
              >
                <path
                  id="Path_92"
                  data-name="Path 92"
                  d="M1325.354,167.5l8.34,9.625,17.8-19.417"
                  transform="translate(-1323.842 -156.357)"
                  fill="none"
                  stroke="#f9f9f9"
                  strokeWidth="4"
                />
              </svg>
            </span>
          </button>
        </div>
      </div>
      <div id="overlay" className={openLogoutPopup ? 'active' : ''}></div>
    </div>
  );
}
