const initialAppState = {
  error: undefined,
  created: false,
  isLoading: false,
  emailExist: false,
  isLoadingGetEmail: false,
  isLoadingNewAccount: false,
  isLoadingUploadNewFile: false,
  uploadNewFile: false,
  addAccount: null,
  newUser: {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  },
  message: '',
};
const RegisterReducer = (state = initialAppState, action) => {
  switch (action.type) {
    case 'INITIAL_STATE_SIGN_UP': {
      return {
        ...state,
        isLoading: false,
        uploadNewFile: false,
        addAccount: null,
      };
    }

    case 'UPLOAD_FILE_REQUESTING': {
      return {
        ...state,
        isLoadingUploadNewFile: true,
      };
    }
    case 'UPLOAD_FILE_SUCCESS': {
      return {
        ...state,
        isLoadingUploadNewFile: false,
        uploadNewFile: true,
      };
    }
    case 'UPLOAD_FILE_FAILURE': {
      return {
        ...state,
        isLoadingUploadNewFile: false,
        uploadNewFile: false,
      };
    }

    case 'CREATE_NEW_ACCOUNT_REQUESTING': {
      return {
        ...state,
        isLoadingNewAccount: true,
        addAccount: false,
      };
    }
    case 'CREATE_NEW_ACCOUNT_SUCCESS': {
      return {
        ...state,
        isLoadingNewAccount: false,
        addAccount: action.data,
      };
    }
    case 'CREATE_NEW_ACCOUNT_FAILURE': {
      return {
        ...state,
        isLoadingNewAccount: false,
        addAccount: false,
        message: action.err,
        ErrorMessage: action.ErrorMessage,
      };
    }

    case 'CREATE_NEW_USER_REQUESTING': {
      return {
        ...state,
        isLoading: true,
        created: false,
      };
    }
    case 'CREATE_NEW_USER_SUCCESS': {
      return {
        ...state,
        isLoading: false,
        created: true,
        user: {
          ...action.data,
        },
      };
    }
    case 'CREATE_NEW_USER_FAILURE': {
      return {
        ...state,
        isLoading: false,
        created: false,
        user: {
          ...action.data,
        },
        message: action.err,
        //error: getAppErrorCode(action.status),
        ErrorMessage: action.ErrorMessage,
      };
    }
    default: {
      return state;
    }
  }
};

export default RegisterReducer;
