import React, { Component } from 'react';

class Item extends Component {
  componentDidMount() {
    this.props.getBillById(this.props.bill_selected);
  }

  render() {
    const { bill, bill_selected } = this.props;
    return (
      <div className="clients_cards">
        <div
          onClick={() => this.props.handleChangedBillSelected(bill.id)}
          className={
            bill.id === bill_selected ? 'selectedCard clients_card' : 'clients_card'
          }
        >
          <div className="clients_card__name">
            <div className="clients_card__pic">
              <div>{bill.number.charAt(0)}</div>
            </div>
            <div>{bill.number}</div>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="9.931"
            height="19.123"
            viewBox="0 0 9.931 19.123"
          >
            <path
              id="Path_114"
              data-name="Path 114"
              d="M4398-2500l-7.845,8.97L4398-2482.2"
              transform="translate(-4388.823 2500.658)"
              fill="none"
              stroke="#707070"
              strokeWidth="2"
            />
          </svg>
        </div>
      </div>
    );
  }
}

export default Item;
