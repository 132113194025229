import React, { Component } from 'react';

// Importing Section
import Navbar from '../../component/Navbar/NavBar';

import Section from './Section';
import Services from '../../component/Services';
import Pricing from '../../component/Pricing';
import Team from '../../component/Team';
import Clients from '../../component/Clients';
import Contact from '../../component/Contact';
import Footer from '../../component/Footer/Footer';
import actions from '../../../../js/actions';
import actionsJournal from '../../../legal-journal/store/actions';
import { connect } from 'react-redux';
import '../../assets/css/materialdesignicons.min.css';
import '../../assets/scss/themes.scss';

const mapStateToProps = (state) => ({
  register: state.AppReducer.register,
  isLoadingRegister: state.AppReducer.isLoadingRegister,
  allLegalJournals: state.LegalJournalReducer.allLegalJournals,
  isLoadingLegalJournals: state.LegalJournalReducer.isLoadingLegalJournals,
  paramsLegalJournals: state.LegalJournalReducer.paramsLegalJournals,
});

const mapDispatchToProps = (dispatch) => ({
  submitRegisterForm: (values) => dispatch(actions.submitRegisterForm(values)),
  getListAllLegalJournal: () => dispatch(actionsJournal.getListAllLegalJournal()),
});
class Layout1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navItems: [
        { id: 1, idnm: 'home', navheading: 'الصفحة الرئيسية' },
        { id: 3, idnm: 'services', navheading: 'الخدمات' },
        { id: 4, idnm: 'pricing', navheading: 'العروض' },
        { id: 5, idnm: 'team', navheading: 'الفريق' },
        //{ id: 6, idnm: 'clients', navheading: 'العملاء' },
        { id: 6, idnm: 'contact', navheading: 'تواصل معنا' },
      ],
      pos: document.documentElement.scrollTop,
      imglight: false,
      navClass: '',
      fixTop: true,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollNavigation, true);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > this.state.pos) {
      this.setState({ navClass: 'nav-sticky', imglight: false });
    } else {
      this.setState({ navClass: '', imglight: false });
    }
  };

  render() {
    return (
      <React.Fragment>
        {/* Importing Navbar */}
        <div style={{ backgroundColor: 'white' }}>
          <Navbar
            navItems={this.state.navItems}
            navClass={this.state.navClass}
            imglight={this.state.imglight}
            top={this.state.fixTop}
          />

          {/* Importing Section */}
          <Section />

          {/* Importing Service */}
          <Services />

          {/* Importing Pricing */}
          <Pricing />
          {/* Importing Team */}
          <Team />

          {/* Importing Clients */}
          {/* <Clients /> */}

          {/* Importing Contact Us */}
          <Contact submitRegisterForm={this.props.submitRegisterForm} />

          {/* Importing Footer */}
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Layout1);
