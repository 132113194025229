import moment from 'moment';
import React from 'react';
import { DaysArabic, host, typeSession } from '../utils/constants';
import axios from 'axios';

export const dataSession = (item) => {
  console.log(item);
  return {
    customer:
      item.lawsuits && item.lawsuits.length > 0 ? item.lawsuits[0].customer?.name : '',
    lawsuit: item.lawsuits && item.lawsuits.length > 0 ? item.lawsuits[0].number : '',
    date: (
      <>
        <span
          style={{ fontSize: '15px', position: 'inherit' }}
          className="rv_td_span_day"
        >{`${moment(item.date).format('dddd')}`}</span>
        <span style={{ padding: '10px' }} className="rv_td_span_date">
          {moment(item.date).format('DD/MM/YYYY')}
        </span>
      </>
    ),
    court: item.court,
    type: typeSession[item.type],
  };
};
export const dataBill = (item) => {
  return {
    customer: item.customer ? item.customer.name : '',
    type: item.lawsuit
      ? item.lawsuit.suit_type.name
      : item.contract && item.contract.type_contract
      ? item.contract.type_contract.name
      : '',
    fees: item.fees,
    date: (
      <>
        <span
          style={{ fontSize: '15px', position: 'inherit' }}
          className="rv_td_span_day"
        >{`${moment(item.date).format('dddd')}`}</span>
        <span
          style={{ fontSize: '15px', position: 'inherit' }}
          className="rv_td_span_time"
        >
          {moment(item.date).format('HH')}h -{moment(item.date).format('HH')}h
        </span>
        <span style={{ padding: '10px' }} className="rv_td_span_date">
          {moment(item.date).format('DD/MM/YYYY')}
        </span>
      </>
    ),
  };
};
export const dataBill2 = (item) => {
  return {
    customer: item.customer ? item.customer.name : '',
    type: item.type === 1 ? 'المصاريف' : 'المقبوضات',
    type1:
      item.type1 === 1
        ? `قضية (${item.lawsuit.number})`
        : item.type1 === 2
        ? `عقد (${item.contract.type_contract.name})`
        : 'أشياء أخرى',
    amount: parseFloat(item.amount, 2),
    payment_type: item.payment_type,
    date: (
      <>
        <span
          style={{ fontSize: '15px', position: 'inherit' }}
          className="rv_td_span_day"
        >{`${moment(item.date).format('dddd')}`}</span>
        <span style={{ padding: '10px' }} className="rv_td_span_date">
          {moment(item.date).format('DD/MM/YYYY')}
        </span>
      </>
    ),
  };
};
export const dataContract = (item) => {
  return {
    customer_id: item.customer ? item.customer.name : '',
    type: item.type_contract ? item.type_contract.name : '',
    second_party_name: item.second_party_name ? item.second_party_name : '',
    date_appeal: moment(item.date).format('DD/MM/YYYY'),
  };
};
export const dataAppeals = (item) => {
  return {
    date_judgment: moment(item.date_judgment).format('DD/MM/YYYY'),
    date_court: moment(item.date_court).format('DD/MM/YYYY'),
    /*judgment_: item.judgment_ ? item.judgment_ : '',*/
    date_appeal: moment(item.date_appeal).format('DD/MM/YYYY'),
    judgment_id: item.judgment ? item.judgment.name : '',
    court: item.court,
  };
};
export const dataReports = (item, account_id) => {
  return {
    name: item.name ? (
      <div
        onClick={() => {
          axios(`${host}${item.file}`, {
            method: 'GET',
            headers: {
              Authorization: `JWT ${window.localStorage.getItem('token')}`,
            },
            responseType: 'blob', //Force to receive data in a Blob Format
          })
            .then((response) => {
              //Create a Blob from the PDF Stream
              if (item.name.split('.')[1] === 'pdf') {
                const fileP = new Blob([response.data], {
                  type: 'application/pdf',
                });
                window.open(URL.createObjectURL(fileP), '_blank');
              } else if (item.name.split('.')[1] === 'docx') {
                var blob = new Blob([response.data], {
                  type: 'application/octet-stream',
                });

                var link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                // set the name of the file
                link.download = item.name;
                // clicking the anchor element will download the file
                link.click();
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }}
        style={{ cursor: 'pointer', width: '100%' }}
        className="uploaded_file__name"
      >
        {item.name}
      </div>
    ) : (
      'تم إرسال هذا التقرير عبر البريد الإلكتروني'
    ),
    lawyer: item.name
      ? item.from_.id === account_id
        ? `${item.to_.first_name} ${item.to_.last_name}`
        : `${item.from_.first_name} ${item.from_.last_name}`
      : item.court,
    date: moment(item.date).format('YYYY-MM-DD'),
    created_at: moment(item.created_at).format('YYYY-MM-DD'),
  };
};
export const dataLegalJournal = (item) => {
  return {
    name: (
      <a target="_blank" href={`${host}${item.file}`}>
        {item.name.split('.')[0]}
      </a>
    ),
    date: moment(item.date).format('YYYY-MM-DD'),
  };
};
export const dataLawsuits = (item) => {
  return {
    number: item.number,
    type: item.suit_type ? item.suit_type.name : '',
    court: item.court,
    customer: item.customer && item.customer.name,
    folder: item.folder && item.folder.name,
    defendant: item.defendants && item.defendants[0].display_name,
    date: (
      <div>
        <span
          style={{ fontSize: '15px', position: 'inherit' }}
          className="rv_td_span_day"
        >{`${moment(item.date).format('dddd')}`}</span>
        <span style={{ padding: '10px' }} className="rv_td_span_date">
          {moment(item.date).format('YYYY/MM/DD')}
        </span>
      </div>
    ),
    // date: moment(item.date).format('YYYY-MM-DD'),
  };
};
export const dataLawsuitsDashboard = (item) => {
  return {
    customer: item.customer && item.customer.name,
    defendant: item.defendant,
    date: moment(item.date).format('YYYY-MM-DD'),
    type: item.suit_type ? item.suit_type.name : '',
    court: item.court,
  };
};
