import { getStatusError } from '../../../utils/helpres';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastAction from '../../../components/ToastAction';
import React from 'react';
import { apiCall } from '../../../js/actions';
import axios from 'axios';
import { host } from '../../../utils/constants';

const submitLegalJournalForm = (values) => async (dispatch) => {
  dispatch({ type: 'UPDATE_LEGAL_JOURNAL_REQUESTING' });
  const { file } = values;
  try {
    let formData = new FormData();
    formData.append('file', file);
    const { data } = await apiCall({
      method: values.id ? 'patch' : 'post',
      url: `/api/legal-journal/${values.id ? `update/${values.id}` : 'create'}`,
      data: formData,
    });
    if (data.type === 'success') {
      toast(({ closeToast }) => <ToastAction type="success" message={data.message} />, {
        hideProgressBar: true,
      });

      dispatch({
        type: 'UPDATE_LEGAL_JOURNAL_SUCCESS',
        addLegalJournal: data.data,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      dispatch({
        type: 'UPDATE_LEGAL_JOURNAL_FAILURE',
        ErrorMessage: data.message,
        addLegalJournal: values,
        status: true,
      });
    }
  } catch (e) {
    const { response } = e;
    if (response) {
      const { data } = response;
      if (data.message) {
        toast(
          ({ closeToast }) => (
            <ToastAction type="error" message={response.data.message} />
          ),
          { hideProgressBar: true },
        );
      } else
        toast(
          ({ closeToast }) => (
            <ToastAction
              type="error"
              message="عفوًا ، لم يتم إنشاء المجلة، يرجى إعادة المحاولة."
            />
          ),
          { hideProgressBar: true },
        );
      dispatch({
        type: 'UPDATE_LEGAL_JOURNAL_FAILURE',
        msg: 'error server',
        err: data.errors || data.message,
        addLegalJournal: values,
        status: getStatusError(e),
      });
    } else {
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="error"
            message="عفوًا ، لم يتم إنشاء المجلة، يرجى إعادة المحاولة."
          />
        ),
        { hideProgressBar: true },
      );
      dispatch({
        type: 'UPDATE_LEGAL_JOURNAL_FAILURE',
        msg: 'error server',
        err: data.errors || data.message,
        addLegalJournal: values,
        status: getStatusError(e),
      });
    }
  }
};

const deleteLegalJournal = (id) => async (dispatch) => {
  dispatch({ type: 'ACTION_DELETE_LEGAL_JOURNAL_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'delete',
      url: `/api/legal-journal/delete/${id}`,
    });
    if (data.type === 'success') {
      toast(({ closeToast }) => <ToastAction type="success" message={data.message} />, {
        hideProgressBar: true,
      });
      dispatch({
        type: 'ACTION_DELETE_LEGAL_JOURNAL_SUCCESS',
        deleteLegalJournal: data,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      dispatch({
        type: 'ACTION_DELETE_LEGAL_JOURNAL_FAILURE',
        ErrorMessage: data.message,
        data: false,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    toast(({ closeToast }) => <ToastAction type="error" message="لم يتم حذف المجلة" />, {
      hideProgressBar: true,
    });
    dispatch({
      type: 'ACTION_DELETE_LEGAL_JOURNAL_FAILURE',
      msg: 'error server',
      err: data.errors || data.message,
      data: false,
    });
  }
};
const changeSearchedStringLegalJournal = (paramsLegalJournals) => ({
  type: 'UPDATE_SEARCH_LEGAL_JOURNALS_PARAMS',
  paramsLegalJournals,
});

const getListLegalJournal = (paramsLegalJournals) => async (dispatch) => {
  dispatch({
    type: 'GET_LEGAL_JOURNALS_REQUESTING',
    paramsLegalJournals,
  });
  try {
    const { currentPage } = paramsLegalJournals || {
      currentPage: 1,
    };
    const { limit } = paramsLegalJournals || {
      limit: '',
    };
    let url = `/api/legal-journal?limit=${limit}&current_page=${currentPage}`;

    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_LEGAL_JOURNALS_SUCCESS',
      legalJournals: data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_LEGAL_JOURNALS_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};

const getListAllLegalJournal = () => async (dispatch) => {
  dispatch({
    type: 'GET_ALL_LEGAL_JOURNALS_REQUESTING',
  });
  try {
    const { data } = await axios.get(
      `${host}/api/legal-journal/all?limit=${100}&current_page=${1}`,
    );
    dispatch({
      type: 'GET_ALL_LEGAL_JOURNALS_SUCCESS',
      allLegalJournals: data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_ALL_LEGAL_JOURNALS_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};

export default {
  getListAllLegalJournal,
  submitLegalJournalForm,
  deleteLegalJournal,
  getListLegalJournal,
  changeSearchedStringLegalJournal,
};
