import React, { Component } from 'react';
import actions from '../../../js/actions';
import { connect } from 'react-redux';
import Table from '../../../components/Table';
import Filter from '../../contract/components/filter';
import { isHasPermission } from '../../../utils/helpres';
import actionsContract from '../../contract/store/actions';

const mapStateToProps = (state) => ({
  account: state.AppReducer.account,
  contracts: state.AppReducer.contracts,
  isLoadingContracts: state.AppReducer.isLoadingContracts,
  paramsContract: state.AppReducer.paramsContract,
  destroyContract: state.ContractReducer.destroyContract,
  isLoadingDestroyLawsuit: state.ContractReducer.isLoadingDestroyLawsuit,
});

const mapDispatchToProps = (dispatch) => ({
  getListContract: (paramsContract) =>
    dispatch(actions.getListContract(paramsContract, 1)),
  changeSearchedStringContract: async (paramsContract) => {
    await dispatch(actions.changeSearchedStringContract(paramsContract));
    dispatch(actions.getListContract(paramsContract, 1));
  },
  deleteContract: async (paramsContract, id) => {
    await dispatch(actionsContract.deleteContract(id, 'delete'));
    dispatch(actions.getListContract(paramsContract, 0));
  },
});

class ContractsArchive extends Component {
  constructor(props) {
    super(props);
    props.getListContract(props.paramsContract);
    props.changeActiveItemSideBar(6, 'أرشيف العقود');
    document.title = 'أرشيف العقود';
  }
  componentDidMount() {
    this.props.changeSearchedStringContract({
      search: '',
      date: '',
      currentPage: 1,
      limit: 5,
      order: 'DESC',
    });
  }
  render() {
    const {
      contracts,
      isLoadingDestroyLawsuit,
      destroyContract,
      isLoadingContracts,
      paramsContract,
      account,
    } = this.props;
    let isPermissionEdit = isHasPermission(
      account.login_access.permissions,
      'تعديل-العقود',
    );
    let isPermissionDelete = isHasPermission(
      account.login_access.permissions,
      'حذف-العقود',
    );
    let isPermissionAdd = isHasPermission(
      account.login_access.permissions,
      'إضافة-العقود',
    );
    return (
      <div className="jalaset_main">
        <div className="jalaset_middle">
          <div className="table_controls">
            <div className="sort_grp">
              <div className="sort_grp__txt">ترتيب حسب</div>
              <Filter
                nameInput="الحريف"
                changeSearchedStringLawsuits={this.props.changeSearchedStringContract}
                paramsSessions={paramsContract}
              />
            </div>
          </div>
          <Table
            class1="lawsuit__judgements_table"
            class2="lawsuit_table"
            withTitle={false}
            colSpan={4}
            dataTable="contract"
            messageDelete="أنت بصدد حذف العقد"
            action={true}
            isLoading={isLoadingContracts}
            params={paramsContract}
            isPermissionEdit={isPermissionEdit}
            isPermissionDelete={isPermissionDelete}
            isPermissionAdd={isPermissionAdd}
            changeSearchedString={this.props.changeSearchedStringContract}
            toggleDrawer={this.toggleDrawer}
            show={false}
            data={contracts}
            //sessions={sessions}
            title="الجلسات"
            editPath=""
            deletePath={this.props.deleteContract}
            destroy={destroyContract}
            destroyYes={true}
            headerItems={['الحريف', 'نوع العقد', 'الطرف الثاني', 'تاريخ', 'الإعدادت']}
          />
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ContractsArchive);
