//export const host = 'http://localhost:9000';
export const host = 'https://lavocato-api.amitechss.com';

export const courts = [
  { id: 7, title: 'المحكمة الإبتدائية بتونس', email: 'alikelifi@gmail.com' },
  { id: 8, title: 'المحكمة الإبتدائية تونس2', email: 'alikelifi@gmail.com' },
  { id: 9, title: 'المحكمة الإبتدائية بأريانة', email: 'alikelifi@gmail.com' },
  { id: 10, title: 'المحكمة الإبتدائية بنعروس', email: 'alikelifi@gmail.com' },
  { id: 11, title: 'المحكمة الإبتدائية بمنوبة', email: 'alikelifi@gmail.com' },
  { id: 12, title: 'المحكمة الإبتدائية بقرمبالية', email: 'alikelifi@gmail.com' },
  { id: 13, title: 'المحكمة الإبتدائية بزغوان', email: 'alikelifi@gmail.com' },
  { id: 14, title: 'المحكمة الإبتدائية بنابل', email: 'alikelifi@gmail.com' },
  { id: 15, title: 'المحكمة الإبتدائية ببنزرت', email: 'alikelifi@gmail.com' },
  { id: 16, title: 'المحكمة الإبتدائية باجة', email: 'alikelifi@gmail.com' },
  { id: 17, title: 'المحكمة الإبتدائية بالكاف', email: 'alikelifi@gmail.com' },
  { id: 18, title: 'المحكمة الإبتدائية بجندوبة', email: 'alikelifi@gmail.com' },
  { id: 19, title: 'المحكمة الإبتدائية بسليانة', email: 'alikelifi@gmail.com' },
  { id: 20, title: 'المحكمة الإبتدائية بالقصرين', email: 'alikelifi@gmail.com' },
  { id: 21, title: 'المحكمة الإبتدائية بسوسة', email: 'alikelifi@gmail.com' },
  { id: 22, title: 'المحكمة الإبتدائية سوسة2', email: 'alikelifi@gmail.com' },
  { id: 23, title: 'المحكمة الإبتدائية بالقيروان' },
  { id: 1, title: 'المحكمة الإبتدائية بسيدي بوزيد' },
  { id: 2, title: 'ابتدائية دائرة محكمة الاستئناف بالقصرين' },
  { id: 3, title: 'المحكمة الإبتدائية بمدنين' },
  { id: 4, title: 'المحكمة الإبتدائية بتطاوين' },
  { id: 5, title: 'المحكمة الإبتدائية بقابس' },
  { id: 6, title: 'المحكمة الإبتدائية بقبلي' },
  { id: 24, title: 'المحكمة الإبتدائية بالمنستير' },
  { id: 25, title: 'المحكمة الإبتدائية بالمهدية' },
  { id: 26, title: 'المحكمة الإبتدائية بصفاقس' },
  { id: 27, title: 'المحكمة الإبتدائية بصفاقس2' },
  { id: 28, title: 'المحكمة الإبتدائية بقفصة' },
  { id: 29, title: 'المحكمة الإبتدائية بتوزر' },
  { id: 30, title: 'محكمة التعقيب' },
  { id: 162, title: 'المحكمة العسكرية بتونس' },
  { id: 163, title: 'المحكمة العسكرية بصفاقس' },
  { id: 164, title: 'المحكمة العسكرية بسوسة' },
  { id: 165, title: 'المحكمة العسكرية بالكاف' },
  { id: 31, title: 'محكمة الاستئناف بتونس' },
  { id: 32, title: 'محكمة الاستئناف بنابل' },
  { id: 33, title: 'محكمة الاستئناف بنزرت' },
  { id: 34, title: 'محكمة الاستئناف بالكاف' },
  { id: 35, title: 'محكمة الاستئناف بجندوبة' },
  { id: 36, title: 'محكمة الاستئناف بباجة' },
  { id: 37, title: 'محكمة الاستئناف بالقصرين' },
  { id: 38, title: 'محكمة الاستئناف بالقيروان' },
  { id: 39, title: 'محكمة الاستئناف بسوسة' },
  { id: 40, title: 'محكمة الاستئناف بالمهدية' },
  { id: 41, title: 'محكمة الاستئناف بالمنستير' },
  { id: 42, title: 'محكمة الاستئناف بصفاقس' },
  { id: 43, title: 'محكمة الاسئناف بسيدي بوزيد' },
  { id: 44, title: 'محكمة الاستئناف بقفصة' },
  { id: 45, title: 'محكمة الاستئناف بقابس' },
  { id: 46, title: 'محكمة الاستئناف بمدنين' },
  { id: 47, title: 'محكمة الناحية بتونس' },
  { id: 48, title: 'محكمة الناحية بقرطاج' },
  { id: 49, title: 'محكمة الناحية تونس 2 الزهور' },
  { id: 50, title: 'محكمة الناحية بالوردية' },
  { id: 51, title: 'محكمة الناحية بأريانة' },
  { id: 52, title: 'محكمة الناحية بالتضامن' },
  { id: 53, title: 'محكمة الناحية ببنعروس' },
  { id: 54, title: 'محكمة الناحية بحمام الأنف' },
  { id: 55, title: 'محكمة الناحية بمنوبة' },
  { id: 56, title: 'محكمة الناحية بطبربة' },
  { id: 57, title: 'محكمة الناحية بقرمبالية' },
  { id: 58, title: 'محكمة الناحية بمنزل بوزلفة' },
  { id: 59, title: 'محكمة الناحية بالحمامات' },
  { id: 60, title: 'محكمة الناحية بنابل' },
  { id: 61, title: 'محكمة الناحية بمنزل تميم' },
  { id: 62, title: 'محكمة الناحية بزغوان' },
  { id: 63, title: 'محكمة الناحية بالفحص' },
  { id: 64, title: 'محكمة الناحية ببنزرت' },
  { id: 65, title: 'محكمة الناحية بمنزل بورقيبة' },
  { id: 66, title: 'محكمة الناحية بماطر' },
  { id: 67, title: 'محكمة الناحية برأس الجبل' },
  { id: 68, title: 'محكمة الناحية بباجة' },
  { id: 69, title: 'محكمة الناحية بتبرسق' },
  { id: 70, title: 'محكمة الناحية بمجاز الباب' },
  { id: 71, title: 'محكمة الناحية بنفزة' },
  { id: 72, title: 'محكمة الناحية بالكاف' },
  { id: 73, title: 'محكمة الناحية بتاجروين' },
  { id: 74, title: 'محكمة الناحية بالدهماني' },
  { id: 75, title: 'محكمة الناحية بجندوبة' },
  { id: 76, title: 'محكمة الناحية بغار الدماء' },
  { id: 77, title: 'محكمة الناحية ببوسالم' },
  { id: 78, title: 'محكمة الناحية بعين دراهم' },
  { id: 79, title: 'محكمة الناحية بطبرقة' },
  { id: 80, title: 'محكمة الناحية بسليانة' },
  { id: 81, title: 'محكمة الناحية بمكثر' },
  { id: 82, title: 'محكمة الناحية بقعفور' },
  { id: 83, title: 'محكمة الناحية بالكريب' },
  { id: 84, title: 'محكمة الناحية بالقصرين' },
  { id: 85, title: 'محكمة الناحية بتالة' },
  { id: 86, title: 'محكمة الناحية بسبيطلة' },
  { id: 87, title: 'محكمة الناحية بسبيبة' },
  { id: 88, title: 'محكمة الناحية بفريانة' },
  { id: 89, title: 'محكمة الناحية بفوسانة' },
  { id: 90, title: 'محكمة الناحية بسوسة' },
  { id: 91, title: 'محكمة الناحية بمساكن' },
  { id: 92, title: 'محكمة الناحية سوسة 2' },
  { id: 93, title: 'محكمة الناحية بالنفيضة' },
  { id: 94, title: 'محكمة الناحية بالقيروان' },
  { id: 95, title: 'محكمة الناحية بحفوز' },
  { id: 96, title: 'محكمة الناحية ببوحجلة' },
  { id: 97, title: 'محكمة الناحية بالوسلاتية' },
  { id: 98, title: 'محكمة الناحية بالسبيخة' },
  { id: 99, title: 'محكمة الناحية بالمنستير' },
  { id: 100, title: 'محكمة الناحية بجمال' },
  { id: 101, title: 'محكمة الناحية بالمكنين' },
  { id: 102, title: 'محكمة الناحية بالمهدية' },
  { id: 103, title: 'محكمة الناحية بالسواسي' },
  { id: 104, title: 'محكمة الناحية بالجم' },
  { id: 105, title: 'محكمة الناحية بقصور الساف' },
  { id: 106, title: 'محكمة الناحية بالشابة' },
  { id: 107, title: 'محكمة الناحية بصفاقس' },
  { id: 108, title: 'محكمة الناحية بجبنيانة' },
  { id: 109, title: 'محكمة الناحية بساقية الزيت' },
  { id: 110, title: 'محكمة الناحية صفاقس1' },
  { id: 166, title: 'محكمة الناحية صفاقس2' },
  { id: 111, title: 'محكمة الناحية بالمحرس' },
  { id: 112, title: 'محكمة الناحية بعقارب' },
  { id: 113, title: 'محكمة الناحية ببئر علي بن خليفة' },
  { id: 114, title: 'محكمة الناحية بقفصة' },
  { id: 115, title: 'محكمة الناحية بالمتلوي' },
  { id: 116, title: 'محكمة الناحية بتوزر' },
  { id: 117, title: 'محكمة الناحية بسيدي بوزيد' },
  { id: 118, title: 'محكمة الناحية بجلمة' },
  { id: 119, title: 'محكمة الناحية ببن عون' },
  { id: 120, title: 'محكمة الناحية بالمكناسي' },
  { id: 121, title: 'محكمة الناحية بالرقاب' },
  { id: 122, title: 'محكمة الناحية بقابس' },
  { id: 123, title: 'محكمة الناحية بالحامة' },
  { id: 124, title: 'محكمة الناحية بمارث' },
  { id: 125, title: 'محكمة الناحية بقبلي' },
  { id: 126, title: 'محكمة الناحية بدوز' },
  { id: 127, title: 'محكمة الناحية بمدنين' },
  { id: 128, title: 'محكمة الناحية ببن قردان' },
  { id: 129, title: 'محكمة الناحية بجربة' },
  { id: 130, title: 'محكمة الناحية بجرجيس' },
  { id: 131, title: 'محكمة الناحية بتطاوين' },
  { id: 132, title: 'محكمة ناحية غمراسن' },
  { id: 133, title: 'فرع المحكمة العقارية ببنزرت' },
  { id: 134, title: 'فرع المحكمة العقارية بباجة' },
  { id: 135, title: 'فرع المحكمة العقارية بالكاف' },
  { id: 136, title: 'فرع المحكمة العقارية بسليانة' },
  { id: 137, title: 'فرع المحكمة العقارية بالقصرين' },
  { id: 138, title: 'فرع المحكمة العقارية بسوسة' },
  { id: 139, title: 'فرع المحكمة العقارية بالقيروان' },
  { id: 140, title: 'فرع المحكمة العقارية بالمنستير' },
  { id: 141, title: 'فرع المحكمة العقارية بالمهدية' },
  { id: 142, title: 'فرع المحكمة العقارية بصفاقس' },
  { id: 143, title: 'فرع المحكمة العقارية بقفصة' },
  { id: 144, title: 'فرع المحكمة العقارية بسيدي بوزيد' },
  { id: 145, title: 'فرع المحكمة العقارية بقابس' },
  { id: 146, title: 'فرع المحكمة العقارية بقبلي' },
  { id: 147, title: 'فرع المحكمة العقارية بمدنين' },
  { id: 148, title: 'فرع المحكمة العقارية بتطاوين' },
  { id: 149, title: 'المحكمة العقارية بتونس' },
  { id: 150, title: 'المحكمة الادارية بنابل' },
  { id: 151, title: 'المحكمة الادارية ببنزرت' },
  { id: 152, title: 'المحكمة الادارية بالكاف' },
  { id: 153, title: 'المحكمة الادارية بسوسة' },
  { id: 154, title: 'المحكمة الادارية بالمنستير' },
  { id: 155, title: 'المحكمة الادارية بصفاقس' },
  { id: 156, title: 'المحكمة الادارية بقفصة' },
  { id: 157, title: 'المحكمة الادارية بقابس' },
  { id: 158, title: 'المحكمة الادارية بمدنين' },
  { id: 159, title: 'المحكمة الادارية بالقصرين' },
  { id: 160, title: 'المحكمة الادارية بالقيروان' },
  { id: 161, title: 'المحكمة الادارية بسيدي بوزيد' },
];
export const cities = [
  { name: 'جندوبة' },
  { name: 'باجة' },
  { name: 'بنزرت' },
  { name: 'منوبة' },
  { name: 'أريانة' },
  { name: 'تونس العاصمة' },
  { name: 'بن عروس' },
  { name: 'نابل' },
  { name: 'الكاف' },
  { name: 'سليانة' },
  { name: 'زغوان' },
  { name: 'سوسة' },
  { name: 'القصرين' },
  { name: 'سيدي بوزيد' },
  { name: 'القيروان' },
  { name: 'المهدية' },
  { name: 'المنستير' },
  { name: 'قفصة' },
  { name: 'صفاقس' },
  { name: 'توزر' },
  { name: 'قبلي' },
  { name: 'قابس' },
  { name: 'مدنين' },
  { name: 'تطاوين' },
];
export const classTask = {
  lawsuit: 'القضايا',
  report: 'تبادل التقارير',
  appeal: 'الطعون',
  customer: 'ملفات الحرفاء',
  session: 'الجلسات',
  contract: 'العقود',
  appointment: 'المواعيد',
};
export const DaysArabic = {
  Sunday: 'الأحد',
  Monday: 'الاثنين',
  Tuesday: 'الثلاثاء',
  Wednesday: 'الأربعاء',
  Thursday: 'الخميس',
  Friday: 'الجمعة',
  Saturday: 'السبت',
};
export const MonthArabic = {
  Janvier: 'يَنَايِر',
  Février: 'فَبْرَايِر',
  Mars: 'مَارِسْ',
  Avril: 'أَبْرِيلْ',
  Mai: 'مَايُو',
  Juin: 'يُونْيُو',
  Juillet: 'يُولِيُو',
  Août: 'أَغُسْطُسْ',
  Septembre: 'سِبْتَمْبَر',
  Octobre: 'أُكْتُوبَرْ',
  Novembre: 'نُوفَمْبَرْ',
  Décembre: 'دِيسَمْبَرْ',
};

export const packs = {
  beginner: 'مبتدئ',
  office: 'مكتب محامي',
  our_office: 'مكتب محاماة',
  company: 'شركة',
};

export const typeSession = {
  5: 'لجوابنا',
  1: 'لجواب الزميل',
  2: 'تحضيري',
  3: 'مرافعة',
  4: 'لتصريح بالحكم',
  6: 'جلسة جديدة',
};

export const typeColors = {
  5: '#FFA800',
  1: '#008AE3',
  2: '#AE00ED',
  3: '#FF333E',
  4: 'rgb(189, 35, 60)',
  6: '#1add3b',
};
