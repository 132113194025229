import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Col, Container, Row } from 'reactstrap';
import Slider from 'react-slick';

import HomeUrl from '../assets/images/home-border.png';

import Img1 from '../assets/images/users/ali-1.jpg';
import Img2 from '../assets/images/users/houcem.jpeg';
import Img4 from '../assets/images/users/206881.png';
import Img3 from '../assets/images/users/biya.jpg';
import LogoDark from '../../../assets/img/logo.png';

export default class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teams: [
        {
          id: 1,
          img: Img1,
          name: 'علي خليفي',
          nickname: 'مطور الويب',
          email: 'alikelifi@gmail.com',
          fb: 'https://www.facebook.com/khelifiiAli',
          description:
            'Anterdum maleada fames Integer molestie ante primis nulla facibus felis ulputate platea elit.',
        },
        {
          id: 2,
          img: Img2,
          email: 'h.sanai@amitechss.com',
          fb: 'https://www.facebook.com/zikou.ski98',
          name: 'حسام الدين الصانعي',
          nickname: 'نشر موقع الويب وأمنه',
          description:
            'Anterdum maleada fames Integer molestie ante primis nulla facibus felis ulputate platea elit.',
        },
        {
          id: 3,
          img: Img3,
          email: 'lavocato.app@gmail.com',
          fb: 'https://www.facebook.com/profile.php?id=100009196333366',
          name: 'ابراهيم خليفي',
          nickname: 'المدير التجاري',
          description:
            'Anterdum maleada fames Integer molestie ante primis nulla facibus felis ulputate platea elit.',
        },
        {
          id: 4,
          img: Img4,
          email: 'namahamdi12@gmail.com',
          fb: 'https://www.facebook.com/profile.php?id=100004086064343',
          name: 'نما حامدي',
          nickname: 'التسويق الرقمي',
          description:
            'Anterdum maleada fames Integer molestie ante primis nulla facibus felis ulputate platea elit.',
        },
      ],
    };
  }

  render() {
    var temsettings = {
      autoplay: true,
      dots: true,
      speed: 300,
      infinite: false,
      arrows: false,
      slidesToShow: 2,
      slidesToScroll: 1,
    };

    const teamslides = this.state.teams.map((team, teamindex) => {
      return (
        <div className="team-box p-3" key={teamindex}>
          <Row className="align-items-center">
            <Col lg={6}>
              <div className="mt-4">
                <h5 className="mt-2">{team.name}</h5>
                <p className="team-badge bg-primary text-white rounded f-14 mt-2">
                  #{team.nickname}
                </p>
                {/* <p style={{ fontSize: '13px' }} className="text-muted mt-3">
                  {team.description}
                </p> */}
                <div className="team-social mt-4 pt-2">
                  <ul className="list-inline mb-0">
                    <li className="list-inline-item">
                      <a target="_blank" href={team.fb} className="text-reset">
                        <i className="mdi mdi-facebook"></i>
                      </a>
                    </li>
                    {/* <li className="list-inline-item">
                      <Link to="#" className="text-reset">
                        <i className="mdi mdi-twitter"></i>
                      </Link>
                    </li> */}
                    <li className="list-inline-item">
                      <a href={`mailto:${team.email}`}>
                        <i className="mdi mdi-google"></i>
                      </a>
                    </li>
                    {/* <li className="list-inline-item">
                      <Link to="#" className="text-reset">
                        <i className="mdi mdi-pinterest"></i>
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="mt-4">
                <img
                  style={{ height: '280px' }}
                  src={team.img}
                  alt=""
                  className="img-fluid rounded"
                />
              </div>
            </Col>
          </Row>
        </div>
      );
    });
    return (
      <React.Fragment>
        <section className="section pt-0" id="team">
          <Container>
            <Row>
              <Col lg="12">
                <div className="title-box text-center">
                  <h3 className="title-heading mt-4">الفريق </h3>
                  {/*<p className="text-muted f-17 mt-3">
                    Vivamus ac nulla ultrices laoreet neque mollis mi morbi elementum
                    mauris sit amet arcu <br /> fringilla auctor In eleifend maximus nisi
                    sed vulputate.
                  </p>*/}
                  <img src={HomeUrl} height="15" className="mt-3" alt="" />
                </div>
              </Col>
            </Row>
            <Row className="mt-5 pt-4">
              <Col lg="12">
                <div className="team-carousel">
                  <Slider {...temsettings}>{teamslides}</Slider>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="section bg-light bg-cta">
          <Container>
            <Row className="justify-content-center">
              <Col lg={9}>
                <div className="text-center">
                  <h2>
                    ابدأ مع <span className="text-primary">لافوكاتو</span>
                  </h2>
                  <p style={{ fontSize: '13px' }} className="text-muted mt-3">
                    "لافوكاتو" هو موقع واب و تطبيقة على الهواتف المحمولة (المحامي و
                    الحريف)، تساهم في رقمنة العمل للسادة المحامين. <br />
                    الغاية من هذه الفكرة هي تنظيم اجندة العمل و تلقي إشعارات عند وصول
                    تقرير و قبل مدة زمنية محددة من انطلاق الجلسة او الموعد .
                  </p>
                  <div className="mt-4 pt-2">
                    <Link
                      style={{ color: 'white' }}
                      to="/login"
                      className="btn btn-primary btn-round btn-rounded"
                    >
                      ابدأ الآن
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
