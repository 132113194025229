import React, { Component } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import ar from 'date-fns/locale/ar-DZ';
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import { DaysArabic } from '../../../utils/constants';
import { connect } from 'react-redux';
import moment from 'moment';
import actions from '../store/actions';
import actionsJs from '../../../js/actions';
import { CircularProgress } from '@material-ui/core';
import InputField from '../../../components/inputField';

registerLocale('ar', ar);

const mapStateToProps = (state, ownProps) => {
  const { receivable } = ownProps;
  let date;
  let contract_id;
  let lawsuit_id;
  let lawsuit_number;
  let customer_id;
  let customer_name;
  let folder_id;
  let id;
  let type;
  let content;
  let amount;
  let type1;
  let contract_name;
  let payment_type;
  if (receivable) {
    date = receivable && receivable.date ? receivable.date : new Date();
    id = receivable && receivable.id ? receivable.id : null;
    type1 = receivable && receivable.type1 ? receivable.type1 : null;
    folder_id = receivable && receivable.folder_id ? receivable.folder_id : null;
    customer_name = receivable && receivable.customer ? receivable.customer.name : '';
    customer_id = receivable && receivable.customer ? receivable.customer.id : '';
    lawsuit_number = receivable && receivable.lawsuit ? receivable.lawsuit.number : '';
    payment_type = receivable && receivable.payment_type ? receivable.payment_type : '';
    type = receivable && receivable.type ? receivable.type : '';
    content = receivable && receivable.content ? receivable.content : '';
    amount = receivable && receivable.amount ? receivable.amount : 0;
    lawsuit_id = receivable && receivable.lawsuit ? receivable.lawsuit.id : '';
    contract_id = receivable && receivable.contract ? receivable.contract.id : '';
    contract_name =
      receivable && receivable.contract && receivable.contract.type_contract
        ? receivable.contract.type_contract.name
        : '';
  }
  return {
    addReceivable: state.ReceivableReducer.addReceivable,
    isLoadingAddReceivable: state.ReceivableReducer.isLoadingAddReceivable,
    contracts: state.ReceivableReducer.contracts,
    isLoadingContracts: state.ReceivableReducer.isLoadingContracts,
    account: state.AppReducer.account,
    initialValues: {
      id: id || '',
      contract_id: contract_id || null,
      folder_id: folder_id || null,
      date: date || new Date(),
      contract_name: contract_name || '',
      type: type || null,
      lawsuit_number: lawsuit_number || '',
      customer_name: customer_name || '',
      payment_type: payment_type || '',
      content: content || '',
      lawsuit_id: lawsuit_id || null,
      customer_id: customer_id || null,
      amount: amount || '',
      type1: type1 || 1,
    },
  };
};
const mapDispatchToProps = (dispatch) => ({
  submitReceivableForm: async (values, paramsReceivables) => {
    await dispatch(actions.submitReceivableForm(values));
    dispatch(actionsJs.getListReceivables(paramsReceivables));
  },
  getByCustomerId: (customerId) => dispatch(actions.getByCustomerId(customerId)),
});

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      hasErrorDate: false,
      initialValues: {
        ...props.initialValues,
      },
    };
    this.submitForm = this.submitForm.bind(this);
  }
  submitForm(initialValues) {
    if (!initialValues.content || !initialValues.type || !initialValues.amount) {
      this.setState({
        ...initialValues,
        hasError: true,
      });
    } else {
      this.props.submitReceivableForm(initialValues, this.props.paramsReceivables);
      this.props.goBack();
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { addSession } = this.props;
    if (addSession !== prevProps.addSession && addSession && addSession.id) {
      this.props.goBack();
    }
  }
  render() {
    const { initialValues, hasErrorDate, hasError } = this.state;
    const {
      isLoadingLawsuitsSessions,
      isLoadingAddSession,
      lawsuitsSessions,
      customers,
      contracts,
      isLoadingContracts,
    } = this.props;
    console.log('++++++++++++++contracts', contracts);
    if (isLoadingLawsuitsSessions) {
      return (
        <CircularProgress
          style={{
            textAlign: 'center',
            width: '30px',
            height: '30px',
            marginTop: '20%',
            marginRight: '50%',
          }}
        />
      );
    }
    return (
      <form style={{ width: '100%' }} action="">
        <div className="jalaset_form__sections">
          <div className="jalaset_form__sections-right">
            <div>
              <label style={{ width: '33%' }} htmlFor="lawsuit_type">
                الحريف
              </label>
              <Autocomplete
                debug
                noOptionsText={'قائمة الحرفاء فارغة'}
                id="debug"
                options={customers}
                getOptionLabel={(option) => option.title}
                style={{ marginBottom: '4%' }}
                onChange={(event, option) => {
                  if (option != null && option.id) this.props.getByCustomerId(option.id);
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      customer_id: option != null ? option.id : '',
                    },
                  });
                }}
                defaultValue={{
                  title: initialValues.customer_name,
                }}
                renderInput={(params) => (
                  <TextField
                    error={!initialValues.customer_id && hasError}
                    name="customer_id"
                    style={{ border: 0 }}
                    {...params}
                    //label="المدعي"
                    //variant="outlined"
                  />
                )}
              />
            </div>
            <div>
              <label style={{ width: '33%' }} htmlFor="address">
                النوع
              </label>
              <select
                className={hasError && !initialValues.type ? 'input-error' : ''}
                name="type"
                onChange={(e) =>
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      type: e.target.value,
                    },
                  })
                }
                value={initialValues.type}
                style={{ width: '70%', marginRight: '5%' }}
              >
                <option value={null}>المصاريف او المقبوضات</option>
                <option value={1}>المصاريف</option>
                <option value={2}>المقبوضات</option>
              </select>
            </div>
            <div>
              قضية
              <input
                onChange={(e) =>
                  this.setState({ initialValues: { ...initialValues, type1: 1 } })
                }
                name="type1"
                value={1}
                className="form-control"
                type="radio"
                checked={initialValues.type1 === 1}
              />
              عقد
              <input
                checked={initialValues.type1 === 2}
                onChange={(e) =>
                  this.setState({ initialValues: { ...initialValues, type1: 2 } })
                }
                name="type1"
                value={2}
                className="form-control"
                type="radio"
              />
              أشياء أخرى
              <input
                checked={initialValues.type1 === 3}
                onChange={(e) =>
                  this.setState({ initialValues: { ...initialValues, type1: 3 } })
                }
                name="type1"
                value={3}
                className="form-control"
                type="radio"
              />
            </div>
            {initialValues.type1 === 1 ? (
              <div>
                <label
                  style={{
                    width: '44%',
                  }}
                  htmlFor="court"
                >
                  القضية
                </label>
                <Autocomplete
                  debug
                  noOptionsText={'قائمة القضايا فارغة'}
                  id="debug"
                  options={lawsuitsSessions}
                  getOptionLabel={(option) => option.title}
                  style={{ marginBottom: '4%' }}
                  onChange={(event, option) => {
                    this.setState({
                      initialValues: {
                        ...initialValues,
                        lawsuit_id: option != null ? option.id : '',
                      },
                    });
                  }}
                  defaultValue={{
                    title: initialValues.lawsuit_number,
                  }}
                  renderInput={(params) => (
                    <TextField
                      error={!initialValues.lawsuit_id && hasError}
                      name="lawsuit_id"
                      style={{ border: 0 }}
                      placeholder="القضية"
                      {...params}
                      //label="القضية"
                      //variant="outlined"
                    />
                  )}
                />
              </div>
            ) : initialValues.type1 === 2 ? (
              <div>
                <label
                  style={{
                    width: '44%',
                  }}
                  htmlFor="court"
                >
                  العقد
                </label>
                <Autocomplete
                  debug
                  noOptionsText={'قائمة العقد فارغة'}
                  id="debug"
                  loading={isLoadingContracts}
                  options={contracts}
                  getOptionLabel={(option) => option.title}
                  style={{ marginBottom: '4%' }}
                  onChange={(event, option) => {
                    this.setState({
                      initialValues: {
                        ...initialValues,
                        contract_id: option != null ? option.id : '',
                      },
                    });
                  }}
                  defaultValue={{
                    title: initialValues.contract_name,
                  }}
                  renderInput={(params) => (
                    <TextField
                      error={!initialValues.contract_id && hasError}
                      name="contract_id"
                      style={{ border: 0 }}
                      placeholder="العقد"
                      {...params}
                      //label="القضية"
                      //variant="outlined"
                    />
                  )}
                />
              </div>
            ) : (
              ''
            )}
          </div>

          <div className="rv_form__sections-left">
            <div style={{ display: 'flex', gap: 28 }}>
              <label style={{ width: '33%' }} htmlFor="address">
                ألمبلغ
              </label>
              <InputField
                value={initialValues.amount}
                name="amount"
                placeholder="ألمبلغ"
                type="text"
                hasError={hasError}
                validInput="text"
                onChange={(event) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      amount: event.target.value,
                    },
                  });
                }}
              />
            </div>
            <div style={{ display: 'flex', gap: 28 }}>
              <label style={{ width: '33%' }} htmlFor="address">
                نوع الدفع
              </label>
              <select
                className={hasError && !initialValues.payment_type ? 'input-error' : ''}
                name="payment_type"
                onChange={(e) =>
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      payment_type: e.target.value,
                    },
                  })
                }
                value={initialValues.payment_type}
                style={{}}
              >
                <option value="">نوع الدفع</option>
                <option value="شيك">شيك</option>
                <option value="نقدا">نقدا</option>
              </select>
            </div>
            <div
              style={{
                alignItems: 'center',
              }}
              className="jalaset_form__sections-left"
            >
              <div
                style={{
                  width: '9rem',
                  marginLeft: 0,
                }}
                className="jalaset_form__label"
                htmlFor="date_selected"
              >
                تاريخ
              </div>
              <div className="jalaset_form__cal">
                <DatePicker
                  selected={new Date(initialValues.date)}
                  locale="ar"
                  onChange={(date) => {
                    this.setState({
                      initialValues: {
                        ...initialValues,
                        date: date,
                      },
                    });
                  }}
                  customInput={
                    <svg
                      style={{
                        cursor: 'pointer',
                      }}
                      id="calendar"
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                    >
                      <g id="Group_28" data-name="Group 28">
                        <g id="Group_27" data-name="Group 27">
                          <ellipse
                            id="Ellipse_20"
                            data-name="Ellipse 20"
                            cx="1"
                            cy="1.5"
                            rx="1"
                            ry="1.5"
                            transform="translate(22 11)"
                            fill="#ca2946"
                          />
                          <path
                            id="Path_89"
                            data-name="Path 89"
                            d="M25.312,2.344H23.789V1.172a1.172,1.172,0,0,0-2.344,0V2.344H16.113V1.172a1.172,1.172,0,0,0-2.344,0V2.344H8.5V1.172a1.172,1.172,0,1,0-2.344,0V2.344H4.688A4.693,4.693,0,0,0,0,7.031V25.312A4.693,4.693,0,0,0,4.688,30h8.965a1.172,1.172,0,0,0,0-2.344H4.688a2.346,2.346,0,0,1-2.344-2.344V7.031A2.346,2.346,0,0,1,4.688,4.688H6.152V5.859a1.172,1.172,0,0,0,2.344,0V4.688H13.77V5.859a1.172,1.172,0,0,0,2.344,0V4.688h5.332V5.859a1.172,1.172,0,0,0,2.344,0V4.688h1.523a2.346,2.346,0,0,1,2.344,2.344v6.68a1.172,1.172,0,0,0,2.344,0V7.031A4.693,4.693,0,0,0,25.312,2.344Z"
                            fill="#ca2946"
                          />
                          <path
                            id="Path_90"
                            data-name="Path 90"
                            d="M277.09,270a7.09,7.09,0,1,0,7.09,7.09A7.1,7.1,0,0,0,277.09,270Zm0,11.836a4.746,4.746,0,1,1,4.746-4.746A4.751,4.751,0,0,1,277.09,281.836Z"
                            transform="translate(-254.18 -254.18)"
                            fill="#ca2946"
                          />
                          <path
                            id="Path_91"
                            data-name="Path 91"
                            d="M373.871,332.4h-.527v-1.23a1.172,1.172,0,0,0-2.344,0v2.4a1.172,1.172,0,0,0,1.172,1.172h1.7a1.172,1.172,0,0,0,0-2.344Z"
                            transform="translate(-349.262 -310.664)"
                            fill="#ca2946"
                          />
                          <circle
                            id="Ellipse_21"
                            data-name="Ellipse 21"
                            cx="1.5"
                            cy="1.5"
                            r="1.5"
                            transform="translate(16 11)"
                            fill="#ca2946"
                          />
                          <circle
                            id="Ellipse_22"
                            data-name="Ellipse 22"
                            cx="1.5"
                            cy="1.5"
                            r="1.5"
                            transform="translate(11 16)"
                            fill="#ca2946"
                          />
                          <ellipse
                            id="Ellipse_23"
                            data-name="Ellipse 23"
                            cx="1"
                            cy="1.5"
                            rx="1"
                            ry="1.5"
                            transform="translate(6 11)"
                            fill="#ca2946"
                          />
                          <ellipse
                            id="Ellipse_24"
                            data-name="Ellipse 24"
                            cx="1"
                            cy="1.5"
                            rx="1"
                            ry="1.5"
                            transform="translate(6 16)"
                            fill="#ca2946"
                          />
                          <circle
                            id="Ellipse_25"
                            data-name="Ellipse 25"
                            cx="1"
                            cy="1"
                            r="1"
                            transform="translate(6 22)"
                            fill="#ca2946"
                          />
                          <ellipse
                            id="Ellipse_26"
                            data-name="Ellipse 26"
                            cx="1.5"
                            cy="1"
                            rx="1.5"
                            ry="1"
                            transform="translate(11 22)"
                            fill="#ca2946"
                          />
                          <circle
                            id="Ellipse_27"
                            data-name="Ellipse 27"
                            cx="1.5"
                            cy="1.5"
                            r="1.5"
                            transform="translate(11 11)"
                            fill="#ca2946"
                          />
                        </g>
                      </g>
                    </svg>
                  }
                />
              </div>

              <div className="jalaset_form__date_selected">
                <div className="jalaset_form__date_selected__day">
                  {DaysArabic[moment(initialValues.date).format('dddd')]}
                </div>
                <div className="jalaset_form__date_selected__day">
                  {moment(initialValues.date).format('DD/MM/YYYY')}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="jalaset_form__sections">
          <div className="jalaset_form__sections-right">
            <label htmlFor="lawsuit_events">
              وصف({initialValues.content.length}/500){' '}
            </label>
            <textarea
              style={{
                border: '1px solid rgb(194 189 189)',
                width: '100%',
                height: '20rem',
              }}
              maxLength={490}
              onChange={(event) => {
                this.setState({
                  initialValues: {
                    ...initialValues,
                    content: event.target.value,
                  },
                });
              }}
              value={initialValues.content}
              className={hasError && !initialValues.content ? 'input-error' : ''}
              name="description"
              placeholder="وصف"
              id="lawsuit_events"
            >
              {initialValues.content}
            </textarea>
          </div>
        </div>
        <div className="jalaset_form__btn_grp">
          <button
            type="button"
            onClick={() => this.props.goBack()}
            className="jalaset_cancel_session"
          >
            <svg
              className="jalaset_ltSpan"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              xmlnsSvgjs="http://svgjs.com/svgjs"
              version="1.1"
              width="12"
              height="12"
              x="0"
              y="0"
              viewBox="0 0 492.004 492.004"
              style={{ enableBackground: 'new 0 0 512 512' }}
              xmlSpace="preserve"
            >
              <g>
                <g xmlns="http://www.w3.org/2000/svg">
                  <g>
                    <path
                      d="M382.678,226.804L163.73,7.86C158.666,2.792,151.906,0,144.698,0s-13.968,2.792-19.032,7.86l-16.124,16.12    c-10.492,10.504-10.492,27.576,0,38.064L293.398,245.9l-184.06,184.06c-5.064,5.068-7.86,11.824-7.86,19.028    c0,7.212,2.796,13.968,7.86,19.04l16.124,16.116c5.068,5.068,11.824,7.86,19.032,7.86s13.968-2.792,19.032-7.86L382.678,265    c5.076-5.084,7.864-11.872,7.848-19.088C390.542,238.668,387.754,231.884,382.678,226.804z"
                      fill="#2d2d2d"
                      data-original="#000000"
                      className=""
                    />
                  </g>
                </g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
              </g>
            </svg>
            الغاء
          </button>
          <button onClick={() => this.submitForm(initialValues)} type="button">
            <svg
              className="tickSpan"
              xmlns="http://www.w3.org/2000/svg"
              width="20.125"
              height="17.774"
              viewBox="0 0 29.125 23.774"
            >
              <path
                id="Path_92"
                data-name="Path 92"
                d="M1325.354,167.5l8.34,9.625,17.8-19.417"
                transform="translate(-1323.842 -156.357)"
                fill="none"
                stroke="#f9f9f9"
                strokeWidth="4"
              />
            </svg>
            {isLoadingAddSession ? (
              <CircularProgress
                style={{
                  textAlign: 'center',
                  width: '30px',
                  height: '30px',
                  color: 'white',
                }}
              />
            ) : (
              'تسجيل'
            )}
          </button>
        </div>
      </form>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Form);
