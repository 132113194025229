const initialLawsuitState = {
  addJurisprudence: null,
  isLoadingAddJurisprudence: false,
  isLoadingDeleteJurisprudence: false,
  deleteJurisprudence: false,
  Jurisprudences: [],
  isLoadingJurisprudences: false,
  paramsJurisprudence: {
    currentPage: 1,
    limit: 5,
  },
};

const JurisprudenceReducer = (state = initialLawsuitState, action) => {
  switch (action.type) {
    case 'UPDATE_SEARCH_JURISPRUDENCE_PARAMS':
      return {
        ...state,
        paramsJurisprudence: action.paramsJurisprudence,
      };
    case 'GET_JURISPRUDENCE_REQUESTING':
      return {
        ...state,
        isLoadingJurisprudences: true,
      };
    case 'GET_JURISPRUDENCE_SUCCESS':
      return {
        ...state,
        isLoadingJurisprudences: false,
        Jurisprudences: action.Jurisprudences,
      };
    case 'GET_JURISPRUDENCE_FAILURE':
      return {
        ...state,
        isLoadingJurisprudences: false,
        Jurisprudences: null,
      };
    case 'ACTION_DELETE_JURISPRUDENCE_REQUESTING':
      return {
        ...state,
        isLoadingDeleteJurisprudence: true,
      };
    case 'ACTION_DELETE_JURISPRUDENCE_SUCCESS':
      return {
        ...state,
        isLoadingDeleteJurisprudence: false,
        deleteJurisprudence: action.deleteJurisprudence,
      };
    case 'ACTION_DELETE_JURISPRUDENCE_FAILURE':
      return {
        ...state,
        isLoadingDeleteJurisprudence: false,
        deleteJurisprudence: null,
      };
    case 'UPDATE_JURISPRUDENCE_REQUESTING':
      return {
        ...state,
        isLoadingAddJurisprudence: true,
      };
    case 'UPDATE_JURISPRUDENCE_SUCCESS':
      return {
        ...state,
        isLoadingAddJurisprudence: false,
        addJurisprudence: action.addJurisprudence,
      };
    case 'UPDATE_JURISPRUDENCE_FAILURE':
      return {
        ...state,
        isLoadingAddJurisprudence: false,
        addJurisprudence: null,
      };

    default:
      return state;
  }
};

export default JurisprudenceReducer;
