import React, { useState, useEffect, Fragment } from 'react';
//import React, { Component } from 'react';
import {
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Font,
} from '@react-pdf/renderer';
import Logo from '../../assets/img/Lavocato.png';
import Roboto from '../../assets/Almarai-Regular.ttf';
import moment from 'moment';

Font.register({
  family: 'Almarai',
  src: Roboto,
});

const newstyles = StyleSheet.create({
  page: {
    // fontFamily: 'Almarai',
  },
  PDFViewer: {
    flex: 1,
    //fontFamily: 'Almarai',
    width: '100%',
    height: '100%',
  },
  headerLeft: {
    width: '70%',
    fontFamily: 'Almarai',
    color: 'black',
    textAlign: 'left',
    margin: 30,
  },
  headerRight: {
    fontFamily: 'Almarai',
    alignItems: 'flex-end',
    justifyContent: 'center',
    textAlign: 'right',
    color: 'black',
    margin: 30,
    width: '50%',
    marginRight: 100,
  },
  section: { fontFamily: 'Almarai', color: 'black', textAlign: 'center' },
  body: { margin: 30, textAlign: 'right', fontFamily: 'Almarai', color: 'black' },
  logo: {
    width: '10%',
    justifyContent: 'center',
  },
});

function LawsuitPdf(props) {
  const lawsuit = props.location.state.lawsuit;
  document.title = `قضية عدد ${lawsuit.number}`;
  const account = props.location.state.account;
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [i, setI] = useState(0);
  useEffect(() => {
    let i = 0;
    props.changeActiveItemSideBar(3);
    lawsuit &&
      lawsuit.sessions.length > 0 &&
      lawsuit.sessions.map((session) => {
        setI(i + 1);
        i++;
        if (
          (i < 5 && lawsuit.facts.length < 500) ||
          (i < 3 && lawsuit.facts.length > 500)
        )
          data.push({
            date: moment(session.date).format('YYYY/MM/DD'),
            court: session.court,
            description: session.description,
          });
        else
          data2.push({
            date: moment(session.date).format('YYYY/MM/DD'),
            court: session.court,
            description: session.description,
          });
      });
    setData(data);
    setData2(data2);
  }, [data, data2]);
  let te = 'اللقب';
  return (
    /* language="ar"*/
    <Fragment>
      <PDFViewer style={newstyles.PDFViewer}>
        <Document>
          <Page style={newstyles.page} size="A4">
            <View
              style={{
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <View style={newstyles.headerLeft}>
                <Image src={Logo} style={newstyles.logo} />
              </View>
              <View style={newstyles.headerRight}>
                <Text style={{ textAlign: 'right', fontSize: 13 }}>
                  {lawsuit.number} عدد القضية{' '}
                </Text>
                <Text style={{ display: 'flex', textAlign: 'right', fontSize: 13 }}>
                  {moment(lawsuit.date).format('DD/MM/YYYY')} التاريخ{' '}
                </Text>
              </View>
            </View>
            <View style={newstyles.section}>
              <Text style={{ fontSize: 15, margin: 3 }}>مكتب</Text>
              <Text style={{ fontWeight: 'bold', fontSize: 17, margin: 3 }}>
                {' '}
                <Text style={{ fontSize: 20, fontWeight: 'bold' }}>
                  {' '}
                  {`${account.first_name} ${account.last_name}`}
                </Text>{' '}
                الاستاذ)ة(
              </Text>
              <Text style={{ fontSize: 15, margin: 3 }}>
                {` المحامي لدى  ${account.adjective} `}
              </Text>
              <Text
                style={{ textAlign: 'center', margin: 3, borderTop: '1px solid black' }}
              ></Text>
              <Text style={{ fontSize: 12, margin: 3 }}>{account.phone}الهاتف: </Text>
              <Text
                style={{ fontSize: 12, margin: 3 }}
              >{`${account.address_street} ${account.zip_code}, ${account.address_city}`}</Text>
            </View>
            <View style={newstyles.body}>
              <View
                style={{
                  alignItems: 'flex-end',
                  justifyContent: 'flex-end',
                  textAlign: 'right',
                  display: 'flex',
                  flexDirection: 'row',
                  margin: 10,
                }}
              >
                <Text style={{ textAlign: 'right', fontSize: 15, width: '30%' }}>
                  {' '}
                  {lawsuit.customer.name}{' '}
                </Text>
                <Text style={{ fontSize: 13, width: '20%' }}> الحريف السيد </Text>
                <Text style={{ textAlign: 'right', fontSize: 15, width: '40%' }}>
                  {' '}
                  {lawsuit.court}{' '}
                </Text>
                <Text style={{ fontSize: 13, width: '20%' }}> المحكمة </Text>
              </View>
              <View
                style={{
                  alignItems: 'flex-end',
                  justifyContent: 'flex-end',
                  textAlign: 'right',
                  display: 'flex',
                  flexDirection: 'row',
                  margin: 10,
                }}
              >
                <Text style={{ textAlign: 'right', fontSize: 15, width: '30%' }}>
                  {' '}
                  {lawsuit.customer.phone}{' '}
                </Text>
                <Text style={{ fontSize: 13, width: '20%' }}> الهاتف </Text>
                <Text style={{ width: '50%', fontSize: 13 }}>
                  {' '}
                  <Text
                    style={{ textAlign: 'right', fontSize: 15, width: '50%', margin: 3 }}
                  >{`${lawsuit.customer.address_street || ''} ${
                    lawsuit.customer.zip_code || ''
                  }, ${lawsuit.customer.address_city || ''}`}</Text>{' '}
                </Text>
                <Text style={{ fontSize: 13, width: '20%' }}> العنوان </Text>
              </View>
              <View
                style={{
                  alignItems: 'flex-end',
                  justifyContent: 'flex-end',
                  textAlign: 'right',
                  display: 'flex',
                  flexDirection: 'row',
                  margin: 10,
                }}
              >
                <Text style={{ textAlign: 'right', fontSize: 15, width: '80%' }}>
                  {' '}
                  {lawsuit.defendants[0].display_name}{' '}
                </Text>
                <Text style={{ fontSize: 13, width: '20%' }}> الضد السيد </Text>
              </View>
              <View
                style={{
                  alignItems: 'flex-start',
                  justifyContent: 'flex-end',
                  textAlign: 'right',
                  display: 'flex',
                  flexDirection: 'row',
                  margin: 10,
                }}
              >
                <View
                  style={{
                    marginTop: 20,
                  }}
                >
                  <Text
                    style={{
                      textAlign: 'right',
                      display: 'block',
                      fontSize: 15,
                      width: '80%',
                    }}
                  >
                    {' '}
                    {lawsuit.facts}{' '}
                  </Text>
                </View>
                <Text
                  style={{
                    fontSize: 13,
                    width: '20%',
                  }}
                >
                  {' '}
                  موضوع القضية{' '}
                </Text>
              </View>
            </View>
            {data && data.length > 0 ? (
              <View
                style={{
                  alignItems: 'flex-end',
                  justifyContent: 'flex-end',
                  marginRight: 30,
                  width: '91%',
                  ...newstyles.body,
                }}
              >
                <View
                  style={{
                    width: '100%',
                    // border: 1,
                    height: 35,
                    display: 'flex',
                    flexDirection: 'row',
                    // margin:5
                  }}
                >
                  <View
                    style={{
                      width: '50%',
                      border: 1,
                      height: 35,
                      alignItems: 'flex-end',
                      justifyContent: 'center',
                      textAlign: 'center',
                    }}
                  >
                    <Text
                      style={{
                        padding: 5,
                        fontSize: 12,
                        alignItems: 'flex-end',
                        justifyContent: 'center',
                        textAlign: 'center',
                      }}
                    >
                      الملاحضات
                    </Text>
                  </View>

                  <View
                    style={{
                      width: '20%',
                      border: 1,
                      height: 35,
                      alignItems: 'flex-end',
                      justifyContent: 'center',
                      textAlign: 'center',
                    }}
                  >
                    <Text
                      style={{
                        padding: 5,
                        fontSize: 12,
                        alignItems: 'flex-end',
                        justifyContent: 'center',
                        textAlign: 'center',
                      }}
                    >
                      تاريخ الجلسة
                    </Text>
                  </View>
                  <View
                    style={{
                      width: '30%',
                      border: 1,
                      alignItems: 'flex-end',
                      justifyContent: 'center',
                      textAlign: 'center',
                    }}
                  >
                    <Text
                      style={{
                        padding: 5,
                        fontSize: 12,
                      }}
                    >
                      المحكمة
                    </Text>
                  </View>
                </View>
                {data.length > 0 ? (
                  data.map((item) => {
                    return (
                      <View
                        key={item.id}
                        style={{
                          height: 35,
                          width: '100%',
                          // border: 1,
                          display: 'flex',
                          flexDirection: 'row',
                          // margin:5
                        }}
                      >
                        <View
                          style={{
                            width: '50%',
                            border: 1,
                            alignItems: 'flex-end',
                            justifyContent: 'center',
                            textAlign: 'center',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>
                            {' '}
                            {item.description}
                          </Text>
                        </View>

                        <View
                          style={{
                            width: '20%',
                            border: 1,
                            alignItems: 'flex-end',
                            justifyContent: 'center',
                            textAlign: 'center',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>{item.date} </Text>
                        </View>
                        <View
                          style={{
                            width: '30%',
                            border: 1,
                            alignItems: 'flex-end',
                            justifyContent: 'center',
                            textAlign: 'center',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>{item.court}</Text>
                        </View>
                      </View>
                    );
                  })
                ) : (
                  <Text></Text>
                )}
              </View>
            ) : (
              <Text></Text>
            )}
          </Page>
          {data2 && data2.length > 0 ? (
            <Page style={newstyles.page} size="A4">
              <View
                style={{
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
              >
                <View style={newstyles.headerLeft}>
                  <Image src={Logo} style={newstyles.logo} />
                </View>
                <View style={newstyles.headerRight}>
                  <Text style={{ textAlign: 'right', fontSize: 13 }}>
                    {lawsuit.number} عدد القضية{' '}
                  </Text>
                  <Text style={{ textAlign: 'right', fontSize: 13 }}>
                    {`${moment(lawsuit.date).format('dddd')} ${moment(
                      lawsuit.date,
                    ).format('DD/MM/YYYY')}`}{' '}
                    التاريخ{' '}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  alignItems: 'flex-end',
                  justifyContent: 'flex-end',
                  marginRight: 30,
                  width: '91%',
                  ...newstyles.body,
                }}
              >
                <View
                  style={{
                    width: '100%',
                    // border: 1,
                    height: 35,
                    display: 'flex',
                    flexDirection: 'row',
                    // margin:5
                  }}
                >
                  <View
                    style={{
                      width: '50%',
                      border: 1,
                      height: 35,
                      alignItems: 'flex-end',
                      justifyContent: 'center',
                      textAlign: 'right',
                    }}
                  >
                    <Text style={{ padding: 5, fontSize: 12 }}>الملاحضات</Text>
                  </View>

                  <View
                    style={{
                      width: '20%',
                      border: 1,
                      height: 35,
                      alignItems: 'flex-end',
                      justifyContent: 'center',
                      textAlign: 'right',
                    }}
                  >
                    <Text style={{ padding: 5, fontSize: 12 }}>تاريخ الجلسة</Text>
                  </View>
                  <View
                    style={{
                      width: '30%',
                      border: 1,
                      height: 35,
                      alignItems: 'flex-end',
                      justifyContent: 'center',
                      textAlign: 'right',
                    }}
                  >
                    <Text style={{ padding: 5, fontSize: 12 }}>المحكمة</Text>
                  </View>
                </View>
                {data2.length > 0 ? (
                  data2.map((item) => {
                    return (
                      <View
                        key={item.id}
                        style={{
                          width: '100%',
                          // border: 1,
                          display: 'flex',
                          flexDirection: 'row',
                          // margin:5
                        }}
                      >
                        <View
                          style={{
                            width: '50%',
                            border: 1,
                            alignItems: 'flex-end',
                            justifyContent: 'center',
                            textAlign: 'right',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>
                            {' '}
                            {item.description}
                          </Text>
                        </View>

                        <View
                          style={{
                            width: '20%',
                            border: 1,
                            alignItems: 'flex-end',
                            justifyContent: 'center',
                            textAlign: 'right',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>{item.date} </Text>
                        </View>
                        <View
                          style={{
                            width: '30%',
                            border: 1,
                            alignItems: 'flex-end',
                            justifyContent: 'center',
                            textAlign: 'right',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>{item.court}</Text>
                        </View>
                      </View>
                    );
                  })
                ) : (
                  <Text></Text>
                )}
              </View>
            </Page>
          ) : (
            <Text></Text>
          )}
        </Document>
      </PDFViewer>
    </Fragment>
  );
}

export default LawsuitPdf;
