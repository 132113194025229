import React, { Component } from 'react';
import EmailIcon from '../../../assets/img/email.svg';
import PasswordIcon from '../../../assets/img/padlock.svg';
import Logo from '../../../assets/img/Lavocato.png';
import UserIcon from '../../../assets/img/user.svg';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import actions from './store/actions';
import SimpleReactValidator from 'simple-react-validator';
import {
  firstNumberPhone,
  isNumeric,
  lengthPhone,
  validate,
  validPassword,
} from '../../../utils/helpres';
import { CircularProgress } from '@material-ui/core';
import InputField from '../../../components/inputField';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import { cities } from '../../../utils/constants';
import BackpackIcon from '@mui/icons-material/Backpack';

const mapStateToProps = (state) => ({
  isLoadingNewAccount: state.RegisterReducer.isLoadingNewAccount,
  message: state.RegisterReducer.message,
  error: state.RegisterReducer.error,
  addAccount: state.RegisterReducer.addAccount,
  isLoading: state.RegisterReducer.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  InitialState: () => dispatch(actions.InitialState()),
  submitAccountForm: (values) => dispatch(actions.submitAccountForm(values)),
});

class Register extends Component {
  constructor(props) {
    super(props);
    props.InitialState();
    document.title = 'إنشاء حساب';
    this.state = {
      initialState: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        address_city: '',
        phone: '',
        confirmPassword: '',
        pack: '',
        hasErrorNewPassword: false,
        checked1: true,
      },
      onFocus: false,
      showPassword: false,
    };
    this.validator = new SimpleReactValidator({
      messages: {
        email: 'Adresse e-mail invalide',
        min: 'Saisissez un mot de passe comprenant au moins 6 caractères.',
        max: 'Le mot de passe doit comporter de 6 à 20 caractères. ',
        // OR
        default: 'Champ requise.', // will override all messenger
      },
    });

    this.handleShowPassword = this.handleShowPassword.bind(this);
    this.handleShowNewPassword = this.handleShowNewPassword.bind(this);
    this.keyPressed = this.keyPressed.bind(this);
  }
  handleShowNewPassword() {
    this.setState({
      showNewPassword: !this.state.showNewPassword,
    });
  }
  keyPressed(event) {
    if (event.key === 'Enter') {
      const { isLoading } = this.props;
      const { initialState } = this.state;
      if (initialState.password.localeCompare(initialState.confirmPassword)) {
        this.setState({
          ...initialState,
          hasErrorNewPassword: true,
        });
      } else if (
        !initialState.phone ||
        !initialState.pack ||
        !initialState.address_city ||
        !initialState.first_name ||
        !initialState.last_name ||
        !initialState.password ||
        !initialState.email ||
        !validPassword(initialState.password) ||
        !validate(initialState.first_name) ||
        !validate(initialState.last_name) ||
        (initialState.phone && !firstNumberPhone(initialState.phone)) ||
        (initialState.phone && !lengthPhone(initialState.phone)) ||
        (initialState.phone && !isNumeric(initialState.phone)) ||
        (initialState.phone && !lengthPhone(initialState.phone))
      )
        this.setState({
          ...initialState,
          hasError: true,
        });
      else if (
        !isLoading &&
        initialState.first_name &&
        initialState.last_name &&
        initialState.phone &&
        initialState.branch &&
        initialState.password &&
        initialState.pack &&
        initialState.address_city &&
        initialState.email &&
        validate(initialState.first_name) &&
        validate(initialState.last_name)
      )
        this.submitForm(initialState);
    }
  }

  componentDidMount() {
    this.props.InitialState();
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }

  submitForm(initialState) {
    if (initialState.password.localeCompare(initialState.confirmPassword)) {
      this.setState({
        ...initialState,
        hasErrorNewPassword: true,
      });
    } else if (
      !initialState.phone ||
      !initialState.address_city ||
      !initialState.first_name ||
      !initialState.last_name ||
      !initialState.password ||
      !initialState.email ||
      !initialState.pack ||
      !validPassword(initialState.password) ||
      !validate(initialState.first_name) ||
      !validate(initialState.last_name) ||
      (initialState.phone && !firstNumberPhone(initialState.phone)) ||
      (initialState.phone && !lengthPhone(initialState.phone)) ||
      (initialState.phone && !isNumeric(initialState.phone)) ||
      (initialState.phone && !lengthPhone(initialState.phone))
    ) {
      this.setState({
        ...initialState,
        hasError: true,
      });
    } else if (this.validator.allValid()) {
      this.props.submitAccountForm(initialState);
    } else {
      this.setState({
        ...initialState,
        hasError: true,
      });
      this.validator.showMessages();
      // rerender to show messenger for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  }

  handleShowPassword() {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { addAccount } = this.props;
    if (addAccount !== prevProps.addAccount && addAccount && addAccount.id) {
      this.props.history.push('/login');
    }
  }
  render() {
    const { initialState, showNewPassword, hasErrorNewPassword, showPassword, hasError } =
      this.state;
    const { isLoadingNewAccount } = this.props;
    return (
      <div className="signup_container">
        <div className="signup_wrapper">
          <img
            style={{
              width: '22%',
              marginRight: '37%',
            }}
            className="logo-auth logo logo"
            src={Logo}
            alt="logo"
          />
          <form className="signup_signup_form">
            <div className="signup_form_field">
              <img className="signup_signup_form__icon" src={UserIcon} alt="user" />
              <InputField
                onKeyPress={this.keyPressed}
                value={initialState.first_name}
                name="first_name"
                placeholder="الاسم"
                type="text"
                hasError={hasError}
                required={this.validator.message(
                  'first_name',
                  initialState.first_name,
                  'required|string',
                )}
                onChange={(e) => {
                  this.setState({
                    initialState: {
                      ...initialState,
                      first_name: e.target.value,
                    },
                  });
                }}
              />
            </div>
            <div className="signup_form_field">
              <img className="signup_signup_form__icon" src={UserIcon} alt="user" />
              <InputField
                onKeyPress={this.keyPressed}
                value={initialState.last_name}
                name="last_name"
                placeholder="اللقب"
                type="text"
                hasError={hasError}
                required={this.validator.message(
                  'last_name',
                  initialState.last_name,
                  'required|string',
                )}
                onChange={(e) => {
                  this.setState({
                    initialState: {
                      ...initialState,
                      last_name: e.target.value,
                    },
                  });
                }}
              />
            </div>
            <div className="signup_form_field">
              <img className="signup_signup_form__icon" src={EmailIcon} alt="user" />
              <InputField
                onKeyPress={this.keyPressed}
                value={initialState.email}
                name="email"
                placeholder="البريد الالكتروني "
                type="email"
                hasError={hasError}
                required={this.validator.message(
                  'email',
                  initialState.email,
                  'required|email',
                )}
                onChange={(e) => {
                  this.setState({
                    initialState: {
                      ...initialState,
                      email: e.target.value,
                    },
                  });
                }}
              />
            </div>
            <div className="signup_form_field">
              <LocalPhoneIcon className="signup_signup_form__icon" />
              <InputField
                onKeyPress={this.keyPressed}
                value={initialState.phone}
                name="phone"
                placeholder="الهاتف"
                type="text"
                validInput="tel"
                hasError={hasError}
                required={this.validator.message(
                  'phone',
                  initialState.phone,
                  'required|string',
                )}
                onChange={(e) => {
                  this.setState({
                    initialState: {
                      ...initialState,
                      phone: e.target.value,
                    },
                  });
                }}
              />
            </div>
            <div className="signup_form_field">
              <LocationCityIcon className="signup_signup_form__icon" />
              <select
                className={hasError && !initialState.address_city ? 'input-error' : ''}
                onChange={(e) => {
                  this.setState({
                    initialState: {
                      ...initialState,
                      address_city: e.target.value,
                    },
                  });
                }}
                value={initialState.address_city}
              >
                <option value="">الولاية</option>
                {cities.map((city) => {
                  return (
                    <option key={city.name} value={city.name}>
                      {city.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="signup_form_field">
              <BackpackIcon className="signup_signup_form__icon" />
              <select
                className={hasError && !initialState.pack ? 'input-error' : ''}
                onChange={(e) => {
                  this.setState({
                    initialState: {
                      ...initialState,
                      pack: e.target.value,
                    },
                  });
                }}
                value={initialState.pack}
              >
                <option value="">العرض</option>
                <option value="beginner">متمرن</option>
                <option value="office">مكتب محامي</option>
                <option value="our_office">مكتب محاماة</option>
                <option value="company">شركة</option>
              </select>
            </div>
            <div className="signup_form_field">
              <img
                style={{ cursor: 'pointer' }}
                onClick={() => this.handleShowPassword()}
                className="signup_signup_form__icon"
                src={PasswordIcon}
                alt="user"
              />
              <InputField
                onKeyPress={this.keyPressed}
                value={initialState.password}
                name="password"
                placeholder="كلمة المرور مثال Avocat123"
                type={showPassword ? 'text' : 'password'}
                hasError={hasError}
                validInput="password"
                required={this.validator.message(
                  'password',
                  initialState.password,
                  'required|string|min:8|max:20',
                )}
                onChange={(e) => {
                  this.setState({
                    initialState: {
                      ...initialState,
                      password: e.target.value,
                    },
                  });
                }}
              />
            </div>
            {initialState.password && !validPassword(initialState.password) ? (
              <span
                style={{
                  textAlign: 'right',
                  fontSize: '11px',
                  color: 'rgb(189, 35, 60)',
                }}
              >
                {/*كلمة مرور غير صالح
                <br />*/}
                8 أحرف
                <br />
                1 حرف كبير و 1 حرف صغير
                <br />
                رقم واحد
              </span>
            ) : (
              ''
            )}
            <div className="login_form_field">
              <img
                style={{ cursor: 'pointer' }}
                onClick={() => this.handleShowNewPassword()}
                className="login_login_form__icon"
                src={PasswordIcon}
                alt="user"
              />
              <InputField
                onKeyPress={this.keyPressed}
                value={initialState.confirmPassword}
                name="confirmPassword"
                placeholder="اعادة كلمة المرور "
                type={showNewPassword ? 'text' : 'password'}
                hasError={hasError}
                validInput="password"
                required={this.validator.message(
                  'confirmPassword',
                  initialState.confirmPassword,
                  'required|string|min:8|max:20',
                )}
                onChange={(e) => {
                  this.setState({
                    initialState: {
                      ...initialState,
                      confirmPassword: e.target.value,
                    },
                  });
                }}
              />
            </div>
            {initialState.confirmPassword && hasErrorNewPassword ? (
              <div style={{ color: 'red', marginBottom: '2%', fontSize: '13px' }}>
                يرجى التحقق من تطابق كلمات المرور الخاصة بك
              </div>
            ) : (
              ''
            )}
            <div className="signup_form_field signup_submit_btn">
              <button
                onClick={() => {
                  this.submitForm(initialState);
                }}
                type="button"
              >
                {isLoadingNewAccount ? (
                  <CircularProgress
                    style={{
                      textAlign: 'center',
                      width: '30px',
                      height: '30px',
                      color: 'white',
                    }}
                  />
                ) : (
                  'إنشاء حساب'
                )}
              </button>
            </div>
            <div
              style={{
                marginBottom: '3%',
              }}
              className="signup_form_field signup_alt-link"
            >
              <Link to="/login">تسجيل الدخول</Link>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);
