import React, { useEffect, useRef, useState } from 'react';
import Logo from '../../assets/img/user-pic.png';
import clock from '../../assets/images/clock.png';
import io from 'socket.io-client';
import { host } from '../../utils/constants';
import Picker from 'emoji-picker-react';
import { Link } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { diffDate } from '../../utils/helpres';
import { Col, Row } from 'reactstrap';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import Popper from '@mui/material/Popper';

const Questions = (props) => {
  const { account } = props;
  const [openCreatePostPopup, setOpenCreatePostPopup] = useState(false);
  const [text, setText] = useState('');
  const [endingIndex, setEndingIndex] = useState(200);
  const [openOption, setOpenOption] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [openEmoji, setOpenEmoji] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [question, setQuestion] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const socket = useRef(null);
  const [openSmile, setOpenSmile] = React.useState(false);
  const anchorRefSmile = React.useRef(null);
  const handleClose = (event) => {
    if (anchorRefSmile.current && anchorRefSmile.current.contains(event.target)) {
      return;
    }
    setOpenSmile(false);
  };
  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenSmile(false);
    } else if (event.key === 'Escape') {
      setOpenSmile(false);
    }
  }
  useEffect(
    () => {
      props.changeActiveItemSideBar('', 'أسئلة');
      document.title = 'أسئلة';
      //document.removeEventListener('mousedown', handleClickOutside);
      socket.current = io.connect(host);
      socket.current.emit(`questions`, (error) => {
        if (error) {
          alert(error);
        }
      });
      socket.current.on('get-questions', (result, error) => {
        setIsLoading(false);
        if (result && result.length > 0) {
          setQuestions(result);
        } else setQuestions([]);
        if (error) {
          alert(error);
        }
      });
    },
    [],
    questions,
  );
  const onEmojiClick = (event, emojiObject) => {
    setText(text + emojiObject.emoji);
  };
  const sendMessage = (event) => {
    event.preventDefault();
    if (text) {
      setHasError(false);
      question.lawyer_id = account.id;
      question.question = text;
      socket.current.emit('add-question', { question }, () => setText(''));
      setText('');
      socket.current.emit(`questions`, (error) => {
        if (error) {
          alert(error);
        }
      });
      setOpenCreatePostPopup(!openCreatePostPopup);
    } else setHasError(true);
  };
  const likeQuestion = (question_id) => {
    socket.current.emit(
      `like-question`,
      { question_id, accountId: account.login_access.id },
      (error) => {
        if (error) {
          alert(error);
        }
      },
    );
    socket.current.emit(`questions`, (error) => {
      if (error) {
        alert(error);
      }
    });
  };
  useEffect(
    () => {
      //document.removeEventListener('mousedown', handleClickOutside);
      socket.current = io.connect(host);
      socket.current.on('get-questions', (result, error) => {
        setIsLoading(false);
        if (result && result.length > 0) {
          setQuestions(result);
        } else setQuestions([]);
        if (error) {
          alert(error);
        }
      });
    },
    [],
    questions,
  );
  if (isLoading || !question) {
    return (
      <CircularProgress
        style={{
          textAlign: 'center',
          width: '30px',
          height: '30px',
          marginTop: '20%',
          marginRight: '50%',
        }}
      />
    );
  } else
    return (
      <div className="reports_main">
        <div className="reports_middle">
          <div className="table_controls">
            {account.login_access.account_type === 'lawyer' ? (
              <button
                onClick={() => setOpenCreatePostPopup(!openCreatePostPopup)}
                className="jalaset_add_button"
              >
                <svg
                  height="10"
                  viewBox="0 0 448 448"
                  width="10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m408 184h-136c-4.417969 0-8-3.582031-8-8v-136c0-22.089844-17.910156-40-40-40s-40 17.910156-40 40v136c0 4.417969-3.582031 8-8 8h-136c-22.089844 0-40 17.910156-40 40s17.910156 40 40 40h136c4.417969 0 8 3.582031 8 8v136c0 22.089844 17.910156 40 40 40s40-17.910156 40-40v-136c0-4.417969 3.582031-8 8-8h136c22.089844 0 40-17.910156 40-40s-17.910156-40-40-40zm0 0"></path>
                </svg>
                منشور
              </button>
            ) : (
              ''
            )}
          </div>
          <div className="reports__judgements_table">
            <div className="reports_table">
              <Row>
                {questions.length > 0
                  ? questions.map((item) => {
                      return (
                        <Col key={item.id} lg={12} className="">
                          <div className="media">
                            <div
                              style={{
                                gap: '10px',
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                height: '120px',
                                backgroundColor: 'white',
                              }}
                              className="count-box bg-soft-primary text-center"
                            >
                              <img
                                style={{ borderRadius: '50%', height: '66px' }}
                                src={
                                  item.lawyer.thumbnail
                                    ? `${host}${item.lawyer.thumbnail}`
                                    : Logo
                                }
                                alt=""
                              />
                              <div>
                                <h3
                                  style={{ textAlign: 'initial' }}
                                  className="counter-count mb-1 text-dark"
                                >
                                  {`${item.lawyer.first_name} ${item.lawyer.last_name}`}
                                </h3>
                                <p
                                  style={{ textAlign: 'initial' }}
                                  className="text-uppercase text-muted mb-0 f-14"
                                >
                                  {diffDate(item.created_at) ? (
                                    <span>
                                      <img src={clock} alt="" />
                                      {diffDate(item.created_at)}
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </p>
                                <Link to={`/question/${item.id}`}>
                                  <p className="text-uppercase text-muted mb-0 f-14">
                                    {item.question.slice(0, endingIndex)}
                                    {item.question.length > endingIndex ? (
                                      <a
                                        onClick={() =>
                                          setEndingIndex(item.question.length)
                                        }
                                        title=""
                                      >
                                        عرض المزيد
                                      </a>
                                    ) : (
                                      ''
                                    )}
                                  </p>
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div className="job-status-bar">
                            <ul className="like-com">
                              <li
                                style={{ cursor: 'pointer' }}
                                onClick={() => likeQuestion(item.id)}
                              >
                                <a
                                  style={{
                                    color:
                                      item.question_likes.length > 0 &&
                                      item.question_likes.find(
                                        (el) => el.user_id === account.login_access.id,
                                      )
                                        ? 'rgb(189, 35, 60)'
                                        : '',
                                  }}
                                >
                                  <i className="fas fa-heart"></i> اعجاب{' '}
                                  {question.question_likes &&
                                  question.question_likes.length > 0
                                    ? question.question_likes.length
                                    : ''}
                                </a>
                              </li>
                              <Link to={`/question/${item.id}`}>
                                <li style={{ cursor: 'pointer' }}>
                                  <a className="com">
                                    <i className="fas fa-comment-alt"></i> تعليق{' '}
                                    {item.responses.length > 0
                                      ? item.responses.length
                                      : ''}
                                  </a>
                                </li>
                              </Link>
                            </ul>
                          </div>
                        </Col>
                      );
                    })
                  : ''}
              </Row>
            </div>
          </div>
        </div>
        <div className={openCreatePostPopup ? 'popup active' : 'popup'}>
          <div className="popup__title">اضافة منشور</div>
          <div style={{ display: 'block' }} className="lawsuit_form__sections">
            <textarea
              onChange={(e) => {
                setText(e.target.value);
                setOpenSmile(false);
              }}
              style={{ width: '100%', height: '10rem' }}
              className={hasError ? 'input-error' : ''}
              name="facts"
              id="lawsuit_events"
              value={text}
            ></textarea>
          </div>
          <Popper
            open={openSmile}
            anchorEl={anchorRefSmile.current}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal
            style={{
              zIndex: '2000',
            }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      className="user_dropdown__content"
                      autoFocusItem={openSmile}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                    >
                      <Picker
                        style={{ marginRight: '-164%', marginTop: '-363%' }}
                        onEmojiClick={onEmojiClick}
                      />
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
          <div
            style={{ cursor: 'pointer' }}
            className="emoji-btn"
            onClick={() => {
              setOpenSmile(true);
            }}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.82031 11.8477C7.53646 13.0508 8.59635 13.6523 10 13.6523C11.4036 13.6523 12.4635 13.0508 13.1797 11.8477H14.6836C14.3112 12.793 13.6953 13.5664 12.8359 14.168C12.0052 14.7409 11.0599 15.0273 10 15.0273C8.9401 15.0273 7.98047 14.7409 7.12109 14.168C6.29036 13.5664 5.6888 12.793 5.31641 11.8477H6.82031ZM4.80078 15.1992C6.26172 16.6315 7.99479 17.3477 10 17.3477C12.0052 17.3477 13.724 16.6315 15.1562 15.1992C16.6172 13.7383 17.3477 12.0052 17.3477 10C17.3477 7.99479 16.6172 6.27604 15.1562 4.84375C13.724 3.38281 12.0052 2.65234 10 2.65234C7.99479 2.65234 6.26172 3.38281 4.80078 4.84375C3.36849 6.27604 2.65234 7.99479 2.65234 10C2.65234 12.0052 3.36849 13.7383 4.80078 15.1992ZM3.51172 3.55469C5.31641 1.75 7.47917 0.847656 10 0.847656C12.5208 0.847656 14.6693 1.75 16.4453 3.55469C18.25 5.33073 19.1523 7.47917 19.1523 10C19.1523 12.5208 18.25 14.6836 16.4453 16.4883C14.6693 18.2643 12.5208 19.1523 10 19.1523C7.47917 19.1523 5.31641 18.2643 3.51172 16.4883C1.73568 14.6836 0.847656 12.5208 0.847656 10C0.847656 7.47917 1.73568 5.33073 3.51172 3.55469ZM5.78906 8.71094C5.53125 8.42448 5.40234 8.09505 5.40234 7.72266C5.40234 7.35026 5.53125 7.03516 5.78906 6.77734C6.07552 6.49089 6.40495 6.34766 6.77734 6.34766C7.14974 6.34766 7.46484 6.49089 7.72266 6.77734C8.00911 7.03516 8.15234 7.35026 8.15234 7.72266C8.15234 8.09505 8.00911 8.42448 7.72266 8.71094C7.46484 8.96875 7.14974 9.09766 6.77734 9.09766C6.40495 9.09766 6.07552 8.96875 5.78906 8.71094ZM12.2344 8.71094C11.9766 8.42448 11.8477 8.09505 11.8477 7.72266C11.8477 7.35026 11.9766 7.03516 12.2344 6.77734C12.5208 6.49089 12.8503 6.34766 13.2227 6.34766C13.5951 6.34766 13.9102 6.49089 14.168 6.77734C14.4544 7.03516 14.5977 7.35026 14.5977 7.72266C14.5977 8.09505 14.4544 8.42448 14.168 8.71094C13.9102 8.96875 13.5951 9.09766 13.2227 9.09766C12.8503 9.09766 12.5208 8.96875 12.2344 8.71094Z"
                fill="#908E8E"
              />
            </svg>
          </div>
          <div className="popup__btn_grp">
            <button
              onClick={() => {
                setOpenCreatePostPopup(!openCreatePostPopup);
                setOpenEmoji(false);
              }}
              className="cancel_popup"
            >
              <span>الغاء</span>
              <span>&lt;</span>
            </button>
            <button
              onClick={(e) => {
                sendMessage(e);
              }}
              style={{ background: '#63B17D' }}
            >
              <span>تأكيد</span>
              <span className="trashSpan">
                <svg
                  className="tickSpan"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.125"
                  height="17.774"
                  viewBox="0 0 29.125 23.774"
                >
                  <path
                    id="Path_92"
                    data-name="Path 92"
                    d="M1325.354,167.5l8.34,9.625,17.8-19.417"
                    transform="translate(-1323.842 -156.357)"
                    fill="none"
                    stroke="#f9f9f9"
                    strokeWidth="4"
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>
        <div
          id="overlay"
          className={openCreatePostPopup || openCreatePostPopup ? 'active' : ''}
        ></div>
      </div>
    );
};

export default Questions;
