import React, { Component } from 'react';
import actions from '../js/actions';
import actionsJournal from '../pages/legal-journal/store/actions';
import { connect } from 'react-redux';
import Logo from '../assets/img/logo-blanc.png';
import J1 from '../assets/jornal/j1.jpg';
import '../assets/home.css';
import { Link } from 'react-router-dom';
import { host } from '../utils/constants';
import Slider from 'react-slick';
import BusinessIcon from '@mui/icons-material/Business';
import HomeWorkIcon from '@mui/icons-material/HomeWork';

const mapStateToProps = (state) => ({
  register: state.AppReducer.register,
  isLoadingRegister: state.AppReducer.isLoadingRegister,
  allLegalJournals: state.LegalJournalReducer.allLegalJournals,
  isLoadingLegalJournals: state.LegalJournalReducer.isLoadingLegalJournals,
  paramsLegalJournals: state.LegalJournalReducer.paramsLegalJournals,
});

const mapDispatchToProps = (dispatch) => ({
  submitRegisterForm: (values) => dispatch(actions.submitRegisterForm(values)),
  getListAllLegalJournal: () => dispatch(actionsJournal.getListAllLegalJournal()),
});

class Home extends Component {
  constructor(props) {
    super(props);
    props.getListAllLegalJournal();
    document.title = 'لافوكاتو';
    this.state = {
      initialValues: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        message: '',
      },
      hasError: false,
      openPopup: false,
    };
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { register } = this.props;
    if (register !== prevProps.register && register) {
      this.closeModal();
    }
  }
  handleSubmit(values) {
    if (
      !values.first_name ||
      !values.last_name ||
      !values.email ||
      !values.message ||
      !values.phone
    ) {
      this.setState({
        hasError: true,
      });
    } else {
      this.setState({
        hasError: false,
      });
      this.props.submitRegisterForm(values);
    }
  }
  render() {
    const { hasError, openPopup, initialValues } = this.state;
    const { allLegalJournals } = this.props;
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
    };
    return (
      <div>
        <section className="header-section">
          <div className="header-section-container">
            <div className="header-section-banner-overlay"></div>
            <div className="header-section-content">
              <div className="header-section-content-Nav">
                <div className="header-section-content-Nav-container">
                  <div className="header-section-content-Nav-content">
                    <div className="header-section-content-Nav-content-right-side">
                      <div style={{ width: '10%' }} className="platform-logo-placeholder">
                        <img style={{ width: '50%' }} src={Logo} />
                      </div>
                      <div className="nav-menu-container">
                        <div className="nav-menu-content">
                          <a
                            href="#"
                            onClick={() => this.setState({ openPopup: !openPopup })}
                          >
                            تواصل معنا
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="header-section-content-Nav-content-left-side">
                      <div className="sign-up-btn-container">
                        <Link to="/login">
                          <button type="button" className="btn btn-outline-light">
                            تسجيل
                          </button>
                        </Link>
                      </div>
                    </div>
                    <div className="menu-sandwich-btn-and-search-icon">
                      <div className="search-bar-container">
                        <div className="search-bar-content">
                          <div className="search-icon">
                            <svg
                              width="18"
                              height="17"
                              viewBox="0 0 17 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16.3992 13.8438C16.5242 14 16.618 14.1875 16.618 14.375C16.618 14.5938 16.5242 14.7812 16.3992 14.9062L15.5242 15.7812C15.368 15.9375 15.1805 16 14.993 16C14.7742 16 14.5867 15.9375 14.4617 15.7812L11.3367 12.6562C11.1805 12.5312 11.118 12.3438 11.118 12.125V11.625C9.93048 12.5625 8.61798 13 7.11798 13C5.93048 13 4.83673 12.7188 3.83673 12.125C2.83673 11.5625 2.05548 10.7812 1.49298 9.78125C0.899231 8.78125 0.617981 7.6875 0.617981 6.5C0.617981 5.3125 0.899231 4.25 1.49298 3.25C2.05548 2.25 2.83673 1.46875 3.83673 0.875C4.83673 0.3125 5.93048 0 7.11798 0C8.30548 0 9.36798 0.3125 10.368 0.875C11.368 1.46875 12.1492 2.25 12.743 3.25C13.3055 4.25 13.618 5.3125 13.618 6.5C13.618 8 13.1492 9.34375 12.243 10.5H12.743C12.9305 10.5 13.118 10.5938 13.2742 10.7188L16.3992 13.8438ZM7.11798 10.5C7.83673 10.5 8.49298 10.3438 9.11798 9.96875C9.74298 9.625 10.2117 9.15625 10.5867 8.53125C10.9305 7.90625 11.118 7.25 11.118 6.5C11.118 5.78125 10.9305 5.125 10.5867 4.5C10.2117 3.875 9.74298 3.40625 9.11798 3.03125C8.49298 2.6875 7.83673 2.5 7.11798 2.5C6.36798 2.5 5.71173 2.6875 5.08673 3.03125C4.46173 3.40625 3.99298 3.875 3.64923 4.5C3.27423 5.125 3.11798 5.78125 3.11798 6.5C3.11798 7.25 3.27423 7.90625 3.64923 8.53125C3.99298 9.15625 4.46173 9.625 5.08673 9.96875C5.71173 10.3438 6.36798 10.5 7.11798 10.5Z"
                                fill="gray"
                              />
                            </svg>
                          </div>
                          <input type="text" placeholder="ابحث عن الدورة..." />
                        </div>
                      </div>

                      <div className="menu-sandwich-btn-content">
                        <div className="menu-sandwich-btn-bar-prim"></div>
                        <div className="menu-sandwich-btn-bar-sec"></div>
                        <div className="menu-sandwich-btn-bar-tir"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="header-section-content-body">
                <div className="header-section-content-body-container">
                  <div className="header-section-content-body-content">
                    <div className="header-section-content-body-content-title-container">
                      <div className="header-section-content-body-content-title">
                        <span>لافوكاتو</span>
                      </div>
                      <div className="header-section-content-body-content-desc">
                        <span>
                          {' '}
                          "لافوكاتو" هو موقع واب و تطبيقة على الهواتف المحمولة (المحامي و
                          الحريف)، تساهم في رقمنة العمل للسادة المحامين. بها عدة محاور
                          خاصة بتنظيم الجلسات و ملفات الحرفاء و المواعيد و الدعاوي و
                          الطعون و تبادل التقارير و المحاسبة و مساحة خاصة بالرسائل مع
                          الزملاء.
                          <br />
                          الغاية من هذه الفكرة هي تنظيم اجندة العمل و تلقي إشعارات عند
                          وصول تقرير و قبل مدة زمنية محددة من انطلاق الجلسة او الموعد .
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="lower-cut-overlay-layer">
            <svg viewBox="0 0 1600 175" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1600 175H0V0C266.667 53.3333 533.333 80 800 80C1066.67 80 1333.33 53.3333 1600 0V175Z"
                fill="white"
              />
            </svg>
          </div>
        </section>

        <section className="body-section">
          <div className="body-section-container">
            <div className="body-section-content">
              <div className="body-subsection-container ">
                <div className="body-subsection-content">
                  <div className="body-subsection-content-header">
                    <div className="body-subsection-content-title">
                      <span>نبذة عن الخدمات</span>
                    </div>
                    {/*<div className="body-subsection-content-desc">
                      <span>
                        ﺗﻌﺘﻤﺪ ﻣﺎﺟﻴﻚ ﻋﻠﻰ ﻣﺠﻤﻮﻋﺔ ﻣﺘﻨﻮﻋﺔ وﻣﺘﺠﺪدﺔ ﻣﻦ ﻣﻨﻬﺠﻴﺎت وﺗﻘﻨﻴﺎت اﻟﺘﺪرﻳﺐ
                        وﺍﻟﺎﺳﺘﺸﺎرﺎت اﻟﻤﺨﺘﻠﻔﺔ .. وﺍﻟﺘﻲ ﻳﺘﻢ اﺳﺘﺨﺪﻣﻬﺎ وﺗﻄﺒﻴﻘﻬﺎ ﻟﺘﻌﺰﻳﺰ اﻟﺘﻔﺎﻋﻞ
                        اﻟﻔﺮﺪﻳ وﺍﻟﺠﻤﺎﻋﻲ وﺍﻟﻤﺆﺳﺴﻲ وﻣﻦ أﺟﻞ زﻳﺎدﺔ اﻟﻜﻔﺎءة وﺍﻟﺠﻮدﺔ اﻟﻌﺎﻟﻴﺔ
                        ﺑﺤﻴﺚ ﺗﻨﻌﻜﺲ إﻳﺠﺎﺑﺎ ﻋﻠﻰ ﺑﻴﺌﺔ اﻟﻌﻤﻞ وﺯﻳﺎدﺔ اﻟﺈﻧﺘﺎﺟﻴﺔ وﺗﺤﻘﻖ اﻟﺄﻫﺪﺎف
                        وﺍﻟﻤﻀﺎﻣﻴﻦ اﻟﻤﻨﺸﻮدﺔ.
                      </span>
                    </div>*/}
                  </div>
                  <div className="body-subsection-content-AC">
                    <Slider {...settings}>
                      <div className="membership-info-elem">
                        <div
                          style={{ marginBottom: '3%' }}
                          className="membership-info-elem-icon-placeholder"
                        >
                          <svg
                            className="home_menu_svg"
                            id="calendar"
                            xmlns="http://www.w3.org/2000/svg"
                            width="28.5"
                            height="25.501"
                            viewBox="0 0 36.5 36.5"
                          >
                            <g id="Group_11" data-name="Group 11">
                              <g id="Group_10" data-name="Group 10">
                                <ellipse
                                  data-name="Ellipse 2"
                                  cx="1"
                                  cy="1.5"
                                  rx="1"
                                  ry="1.5"
                                  transform="translate(26.5 13.5)"
                                  fill="#ca2946"
                                ></ellipse>
                                <path
                                  data-name="Path 43"
                                  d="M30.8,2.852H28.943V1.426a1.426,1.426,0,0,0-2.852,0V2.852H19.6V1.426a1.426,1.426,0,0,0-2.852,0V2.852H10.337V1.426a1.426,1.426,0,1,0-2.852,0V2.852H5.7A5.71,5.71,0,0,0,0,8.555V30.8a5.71,5.71,0,0,0,5.7,5.7H16.61a1.426,1.426,0,0,0,0-2.852H5.7A2.855,2.855,0,0,1,2.852,30.8V8.555A2.855,2.855,0,0,1,5.7,5.7H7.485V7.129a1.426,1.426,0,1,0,2.852,0V5.7h6.416V7.129a1.426,1.426,0,0,0,2.852,0V5.7h6.487V7.129a1.426,1.426,0,0,0,2.852,0V5.7H30.8a2.855,2.855,0,0,1,2.852,2.852v8.127a1.426,1.426,0,0,0,2.852,0V8.555A5.71,5.71,0,0,0,30.8,2.852Z"
                                  fill="#ca2946"
                                ></path>
                                <path
                                  data-name="Path 44"
                                  d="M278.626,270a8.626,8.626,0,1,0,8.626,8.626A8.636,8.636,0,0,0,278.626,270Zm0,14.4a5.774,5.774,0,1,1,5.774-5.774A5.781,5.781,0,0,1,278.626,284.4Z"
                                  transform="translate(-250.752 -250.752)"
                                  fill="#ca2946"
                                ></path>
                                <path
                                  data-name="Path 45"
                                  d="M374.493,332.923h-.642v-1.5a1.426,1.426,0,0,0-2.852,0v2.923a1.426,1.426,0,0,0,1.426,1.426h2.067a1.426,1.426,0,0,0,0-2.852Z"
                                  transform="translate(-344.552 -306.475)"
                                  fill="#ca2946"
                                ></path>
                                <circle
                                  data-name="Ellipse 3"
                                  cx="1.5"
                                  cy="1.5"
                                  r="1.5"
                                  transform="translate(19.5 13.5)"
                                  fill="#ca2946"
                                ></circle>
                                <circle
                                  data-name="Ellipse 4"
                                  cx="1.5"
                                  cy="1.5"
                                  r="1.5"
                                  transform="translate(13.5 19.5)"
                                  fill="#ca2946"
                                ></circle>
                                <circle
                                  data-name="Ellipse 5"
                                  cx="1.5"
                                  cy="1.5"
                                  r="1.5"
                                  transform="translate(7.5 13.5)"
                                  fill="#ca2946"
                                ></circle>
                                <circle
                                  data-name="Ellipse 6"
                                  cx="1.5"
                                  cy="1.5"
                                  r="1.5"
                                  transform="translate(7.5 19.5)"
                                  fill="#ca2946"
                                ></circle>
                                <circle
                                  data-name="Ellipse 7"
                                  cx="1.5"
                                  cy="1.5"
                                  r="1.5"
                                  transform="translate(7.5 25.5)"
                                  fill="#ca2946"
                                ></circle>
                                <circle
                                  data-name="Ellipse 8"
                                  cx="1.5"
                                  cy="1.5"
                                  r="1.5"
                                  transform="translate(13.5 25.5)"
                                  fill="#ca2946"
                                ></circle>
                                <circle
                                  data-name="Ellipse 9"
                                  cx="1.5"
                                  cy="1.5"
                                  r="1.5"
                                  transform="translate(13.5 13.5)"
                                  fill="#ca2946"
                                ></circle>
                              </g>
                            </g>
                          </svg>
                        </div>
                        <div className="membership-info-elem-title">
                          <span>المواعيد</span>
                        </div>
                        <div className="membership-info-elem-desc">
                          <div className="membership-info-elem-desc">
                            <span>
                              للتذكير بكافة انواع المواعيد اصافة الى الاطلاع, اضافة و
                              تعديل المعلومات الخاصة بها{' '}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="membership-info-elem">
                        <div
                          style={{ marginBottom: '3%' }}
                          className="membership-info-elem-icon-placeholder"
                        >
                          <svg
                            className="side_menu_svg"
                            xmlns="http://www.w3.org/2000/svg"
                            width="30.165"
                            height="30.477"
                            viewBox="0 0 46.165 46.477"
                          >
                            <g id="judgement" transform="translate(-1.252 0.5)">
                              <path
                                data-name="Path 79"
                                d="M46.252,7.27a.666.666,0,0,0-.666.666v6.152A3.192,3.192,0,0,1,42.4,17.276H31.2a.666.666,0,0,0-.573.327l-1.051,1.775L28.524,17.6a.666.666,0,0,0-.573-.327,8.472,8.472,0,0,1-2.371-.13v-.825A6.948,6.948,0,0,0,23.3,6.443V4.52a3.192,3.192,0,0,1,3.189-3.188H42.4A3.192,3.192,0,0,1,45.585,4.52a.666.666,0,0,0,1.332,0A4.526,4.526,0,0,0,42.4,0H26.486a4.526,4.526,0,0,0-4.521,4.52V5.845a6.976,6.976,0,0,0-8.009,10.476v1.343a5.681,5.681,0,0,0,.841,2.981,6.949,6.949,0,0,0-4.024,6.324v3.274H2.4a.666.666,0,0,0,0,1.332H4.252l1.134,6.242a2.027,2.027,0,0,0,2,1.665h1.3c0,1.753,0,3.549-.033,5.316a.666.666,0,0,0,1.332.022c.03-1.778.033-3.58.033-5.338h1.642a.666.666,0,0,0,0-1.332q-2.136,0-4.272,0A.7.7,0,0,1,6.7,37.58l-1.091-6H33.929l-1.091,6a.7.7,0,0,1-.685.571H15.2a.666.666,0,0,0,0,1.332H29.556c0,1.8,0,3.551,0,5.328a.666.666,0,0,0,1.332,0c0-1.777,0-3.526,0-5.326h1.265a2.027,2.027,0,0,0,2-1.666l1.134-6.242h1.854a.666.666,0,0,0,0-1.332H33.885V28.307a.666.666,0,0,0-.666-.666H32.043V26.194h5.094a.666.666,0,0,0,0-1.332H32.043V22.749a.666.666,0,0,0-.666-.666H27.949a.666.666,0,0,0-.662.589,6.957,6.957,0,0,0-2.549-2.026,5.681,5.681,0,0,0,.78-2.142,9.629,9.629,0,0,0,2.053.1L29,21.025a.672.672,0,0,0,1.147,0l1.43-2.417H42.4a4.526,4.526,0,0,0,4.521-4.52V7.936a.666.666,0,0,0-.666-.666ZM19.768,6.828a5.653,5.653,0,0,1,5.509,6.961,2.309,2.309,0,0,0-2-1.157H16.262a2.31,2.31,0,0,0-2,1.159,5.653,5.653,0,0,1,5.509-6.963Zm0,19.15-1.625-2.789a5.932,5.932,0,0,0,3.251,0Zm.013-3.9a4.448,4.448,0,0,1-4.493-4.418V14.919a.967.967,0,0,1,.973-.955h7.012a.966.966,0,0,1,.973.957v2.743a4.447,4.447,0,0,1-4.466,4.418ZM12.106,26.97a5.623,5.623,0,0,1,3.548-5.243s2.39,3.939,3.434,5.731v2.785H12.106Zm20.446,3.274H26.773v-1.27h5.779Zm-3.937-6.829h2.1v4.226h-2.1Zm-1.333,2.268v1.958H26.107a.666.666,0,0,0-.666.666v1.936h-5.02V27.5c1.052-1.805,3.46-5.776,3.46-5.776a5.623,5.623,0,0,1,3.4,3.956Z"
                                fill="#ca2946"
                                stroke="#ca2946"
                                strokeWidth="1"
                              ></path>
                              <path
                                data-name="Path 80"
                                d="M300.895,36.819a.666.666,0,0,0-.666-.666H294.12v-.863a.666.666,0,0,0-1.332,0v.863h-6.108a.666.666,0,1,0,0,1.332h1.477l-1.095,2.869a2.207,2.207,0,1,0,4.124,0l-1.095-2.869h2.7v7.764h-2.949a.666.666,0,1,0,0,1.332h7.231a.666.666,0,1,0,0-1.332H294.12V37.485h2.7l-1.095,2.869a2.207,2.207,0,1,0,4.124,0l-1.095-2.869h1.476a.666.666,0,0,0,.666-.666Zm-11.052,4.818a.876.876,0,0,1-1.537-.808l.817-2.141.817,2.141A.863.863,0,0,1,289.843,41.636Zm8.662,0a.876.876,0,0,1-1.537-.808l.817-2.141.817,2.141A.863.863,0,0,1,298.505,41.636Z"
                                transform="translate(-259.012 -31.548)"
                                fill="#ca2946"
                                stroke="#ca2946"
                                strokeWidth="1"
                              ></path>
                            </g>
                          </svg>
                        </div>
                        <div className="membership-info-elem-title">
                          <span>القضايا</span>
                        </div>
                        <div className="membership-info-elem-desc">
                          <span>الاطلاع, اضافة و تعديل المعلومات الخاصة بالقضايا</span>
                        </div>
                      </div>
                      <div className="membership-info-elem">
                        <div
                          style={{ marginBottom: '3%' }}
                          className="membership-info-elem-icon-placeholder"
                        >
                          <svg
                            className="home_menu_svg"
                            xmlns="http://www.w3.org/2000/svg"
                            width="28.5"
                            height="25.501"
                            viewBox="0 0 38.129 38.129"
                          >
                            <g id="paper" transform="translate(-1.5 -1.5)">
                              <path
                                data-name="Path 122"
                                d="M16.574,22.156H2.53a.53.53,0,0,1-.53-.53V2.53A.53.53,0,0,1,2.53,2h9.551a.53.53,0,0,1,.375.155l4.493,4.49a.53.53,0,0,1,.155.375v14.6A.53.53,0,0,1,16.574,22.156ZM3.061,21.095H16.043V7.24l-4.182-4.18h-8.8Z"
                                fill="#ca2946"
                                stroke="#ca2946"
                                strokeWidth="1"
                              ></path>
                              <path
                                data-name="Path 123"
                                d="M25.029,7.557H20.536a.53.53,0,0,1-.53-.53V2.53a.53.53,0,1,1,1.061,0V6.5h3.962a.53.53,0,0,1,0,1.061Z"
                                transform="translate(-8.455 0)"
                                fill="#ca2946"
                                stroke="#ca2946"
                                strokeWidth="1"
                              ></path>
                              <path
                                data-name="Path 124"
                                d="M16.315,31.482H6.77a.53.53,0,1,1,0-1.061h9.545a.53.53,0,1,1,0,1.061Z"
                                transform="translate(-1.991 -13.346)"
                                fill="#ca2946"
                                stroke="#ca2946"
                                strokeWidth="1"
                              ></path>
                              <path
                                data-name="Path 125"
                                d="M16.315,24.535H6.77a.53.53,0,1,1,0-1.061h9.545a.53.53,0,1,1,0,1.061Z"
                                transform="translate(-1.991 -10.084)"
                                fill="#ca2946"
                                stroke="#ca2946"
                                strokeWidth="1"
                              ></path>
                              <path
                                data-name="Path 126"
                                d="M16.315,17.587H6.77a.53.53,0,1,1,0-1.061h9.545a.53.53,0,1,1,0,1.061Z"
                                transform="translate(-1.991 -6.821)"
                                fill="#ca2946"
                                stroke="#ca2946"
                                strokeWidth="1"
                              ></path>
                              <path
                                data-name="Path 127"
                                d="M11.826,10.64H6.77a.53.53,0,1,1,0-1.061h5.055a.53.53,0,1,1,0,1.061Z"
                                transform="translate(-1.991 -3.559)"
                                fill="#ca2946"
                                stroke="#ca2946"
                                strokeWidth="1"
                              ></path>
                              <path
                                data-name="Path 128"
                                d="M58.1,54.156H44.054a.53.53,0,0,1-.53-.53V34.53a.53.53,0,0,1,.53-.53H53.6a.53.53,0,0,1,.375.155l4.493,4.49a.53.53,0,0,1,.155.375v14.6A.53.53,0,0,1,58.1,54.156ZM44.585,53.095H57.567V39.24l-4.182-4.18h-8.8Z"
                                transform="translate(-19.499 -15.027)"
                                fill="#ca2946"
                                stroke="#ca2946"
                                strokeWidth="1"
                              ></path>
                              <path
                                data-name="Path 129"
                                d="M66.553,39.557H62.059a.53.53,0,0,1-.53-.53v-4.5a.53.53,0,1,1,1.061,0V38.5h3.963a.53.53,0,1,1,0,1.061Z"
                                transform="translate(-27.954 -15.027)"
                                fill="#ca2946"
                                stroke="#ca2946"
                                strokeWidth="1"
                              ></path>
                              <path
                                data-name="Path 130"
                                d="M57.838,63.482H48.29a.53.53,0,1,1,0-1.061h9.547a.53.53,0,1,1,0,1.061Z"
                                transform="translate(-21.488 -28.373)"
                                fill="#ca2946"
                                stroke="#ca2946"
                                strokeWidth="1"
                              ></path>
                              <path
                                data-name="Path 131"
                                d="M57.838,56.535H48.29a.53.53,0,1,1,0-1.061h9.547a.53.53,0,1,1,0,1.061Z"
                                transform="translate(-21.488 -25.111)"
                                fill="#ca2946"
                                stroke="#ca2946"
                                strokeWidth="1"
                              ></path>
                              <path
                                data-name="Path 132"
                                d="M57.838,49.587H48.29a.53.53,0,1,1,0-1.061h9.547a.53.53,0,1,1,0,1.061Z"
                                transform="translate(-21.488 -21.848)"
                                fill="#ca2946"
                                stroke="#ca2946"
                                strokeWidth="1"
                              ></path>
                              <path
                                data-name="Path 133"
                                d="M53.355,42.64H48.3a.53.53,0,0,1,0-1.061h5.055a.53.53,0,0,1,0,1.061Z"
                                transform="translate(-21.493 -18.586)"
                                fill="#ca2946"
                                stroke="#ca2946"
                                strokeWidth="1"
                              ></path>
                              <path
                                data-name="Path 134"
                                d="M52.737,18.7a.53.53,0,0,1-.53-.53V11.211H44.78a.53.53,0,1,1,0-1.061h7.956a.53.53,0,0,1,.53.53v7.488A.53.53,0,0,1,52.737,18.7Z"
                                transform="translate(-19.84 -3.827)"
                                fill="#ca2946"
                                stroke="#ca2946"
                                strokeWidth="1"
                              ></path>
                              <path
                                data-name="Path 135"
                                d="M46.516,11.41a.53.53,0,0,1-.375-.155L44.406,9.519a.53.53,0,0,1,0-.75l1.736-1.736a.53.53,0,1,1,.75.75l-1.361,1.36L46.891,10.5a.53.53,0,0,1-.375.905Z"
                                transform="translate(-19.84 -2.291)"
                                fill="#ca2946"
                                stroke="#ca2946"
                                strokeWidth="1"
                              ></path>
                              <path
                                data-name="Path 136"
                                d="M21.237,56.281H13.28a.53.53,0,0,1-.53-.53V48.262a.53.53,0,0,1,1.061,0V55.22h7.426a.53.53,0,0,1,0,1.061Z"
                                transform="translate(-5.048 -21.475)"
                                fill="#ca2946"
                                stroke="#ca2946"
                                strokeWidth="1"
                              ></path>
                              <path
                                data-name="Path 137"
                                d="M25.009,63.11a.53.53,0,0,1-.376-.905l1.361-1.361-1.361-1.361a.531.531,0,1,1,.751-.75l1.735,1.736a.53.53,0,0,1,0,.75l-1.735,1.736A.53.53,0,0,1,25.009,63.11Z"
                                transform="translate(-10.555 -26.568)"
                                fill="#ca2946"
                                stroke="#ca2946"
                                strokeWidth="1"
                              ></path>
                            </g>
                          </svg>
                        </div>
                        <div className="membership-info-elem-title">
                          <span>تبادل التقارير</span>
                        </div>
                        <div className="membership-info-elem-desc">
                          <span>
                            تخول للمستخدم تبادل التقرير مع لزملائه و الاطلاع عليها
                          </span>
                        </div>
                      </div>
                      <div className="membership-info-elem">
                        <div
                          style={{ marginBottom: '3%' }}
                          className="membership-info-elem-icon-placeholder"
                        >
                          <svg
                            className="home_menu_svg"
                            xmlns="http://www.w3.org/2000/svg"
                            width="28.5"
                            height="25.501"
                            viewBox="0 0 37.322 33.663"
                          >
                            <g id="browser" transform="translate(0 -30)">
                              <path
                                data-name="Path 107"
                                d="M31.7,30H5.6A5.611,5.611,0,0,0,0,35.651V57.862a5.8,5.8,0,0,0,5.812,5.8h25.7a5.8,5.8,0,0,0,5.812-5.8V35.651A5.6,5.6,0,0,0,31.7,30Zm-.188,1.83a3.99,3.99,0,0,1,3.983,3.988v1.5H1.83v-1.5A3.99,3.99,0,0,1,5.812,31.83Zm0,30H5.812A3.976,3.976,0,0,1,1.83,57.862V39.208H35.492V57.862A3.976,3.976,0,0,1,31.509,61.833Z"
                                fill="#ca2946"
                              ></path>
                              <path
                                data-name="Path 108"
                                d="M94.181,102.526a1.047,1.047,0,1,0-1.047-1.047A1.047,1.047,0,0,0,94.181,102.526Z"
                                transform="translate(-87.454 -66.136)"
                                fill="#ca2946"
                              ></path>
                              <path
                                data-name="Path 109"
                                d="M151.378,102.526a1.047,1.047,0,1,0-1.047-1.047A1.046,1.046,0,0,0,151.378,102.526Z"
                                transform="translate(-141.163 -66.136)"
                                fill="#ca2946"
                              ></path>
                              <path
                                data-name="Path 110"
                                d="M208.584,102.526a1.047,1.047,0,1,0-1.046-1.047A1.046,1.046,0,0,0,208.584,102.526Z"
                                transform="translate(-194.882 -66.136)"
                                fill="#ca2946"
                              ></path>
                              <path
                                data-name="Path 111"
                                d="M248.129,454.245a6.2,6.2,0,0,1-2.583-.587.91.91,0,0,0-1.219.424.87.87,0,0,0,.447,1.175,7.818,7.818,0,0,0,3.343.655h.005a.837.837,0,1,0,.006-1.667Z"
                                transform="translate(-229.348 -397.738)"
                                fill="#ca2946"
                              ></path>
                              <path
                                data-name="Path 112"
                                d="M182.5,380.906a.918.918,0,1,0-1.761.516,8.038,8.038,0,0,0,1.639,3.01.918.918,0,1,0,1.389-1.2A6.218,6.218,0,0,1,182.5,380.906Z"
                                transform="translate(-169.682 -328.885)"
                                fill="#ca2946"
                              ></path>
                              <path
                                data-name="Path 113"
                                d="M193.193,230.024l-1.375.438a8.089,8.089,0,0,0-15.8,2.44.918.918,0,1,0,1.835,0,6.253,6.253,0,0,1,12.219-1.882l-1.47.469a.621.621,0,0,0-.3.969.776.776,0,0,0,.242.2l3,1.555a.613.613,0,0,0,.828-.262l1.55-3a.8.8,0,0,0,.079-.31A.62.62,0,0,0,193.193,230.024Z"
                                transform="translate(-165.281 -182.926)"
                                fill="#ca2946"
                              ></path>
                            </g>
                          </svg>
                        </div>
                        <div className="membership-info-elem-title">
                          <span>المهام</span>
                        </div>
                        {/*<div className="membership-info-elem-desc">
                          <span>
                            تستطيع ان تكون مدرب معتمد في ماجيك المعهد الدولي للتدريب
                            والتدريب الالكتروني
                          </span>
                        </div>*/}
                      </div>

                      <div className="membership-info-elem">
                        <div
                          style={{ marginBottom: '3%' }}
                          className="membership-info-elem-icon-placeholder"
                        >
                          <svg
                            className="home_menu_svg"
                            id="maths"
                            xmlns="http://www.w3.org/2000/svg"
                            width="28.5"
                            height="25.501"
                            viewBox="0 0 37.069 37.069"
                          >
                            <path
                              data-name="Path 66"
                              d="M0,0V37.069H37.069V0ZM34.847,17.434H19.635V2.222H34.847V17.434ZM17.434,2.222V17.434H2.222V2.222ZM2.222,19.635H17.434V34.847H2.222ZM19.635,34.847V19.635H34.847V34.847H19.635Z"
                              fill="#ca2946"
                            ></path>
                            <path
                              data-name="Path 67"
                              d="M73.9,79.745h2.2V76.1h3.64V73.9H76.1V70.263H73.9V73.9H70.263v2.2H73.9Z"
                              transform="translate(-65.176 -65.176)"
                              fill="#ca2946"
                            ></path>
                            <path
                              data-name="Path 68"
                              d="M310.459,361.057h9.482v2.2h-9.482Z"
                              transform="translate(-287.982 -334.917)"
                              fill="#ca2946"
                            ></path>
                            <path
                              data-name="Path 69"
                              d="M87.271,320.761l-1.556-1.556-2.574,2.574-2.574-2.574-1.556,1.556,2.574,2.574L79.01,325.91l1.556,1.556,2.574-2.574,2.574,2.574,1.556-1.556L84.7,323.335Z"
                              transform="translate(-73.29 -296.095)"
                              fill="#ca2946"
                            ></path>
                            <path
                              data-name="Path 70"
                              d="M310.459,120.547h9.482v2.2h-9.482Z"
                              transform="translate(-287.982 -111.819)"
                              fill="#ca2946"
                            ></path>
                            <path
                              data-name="Path 71"
                              d="M360.519,300.035h2.233v2.2h-2.233Z"
                              transform="translate(-334.418 -278.313)"
                              fill="#ca2946"
                            ></path>
                            <path
                              data-name="Path 72"
                              d="M360.519,420.652h2.233v2.2h-2.233Z"
                              transform="translate(-334.418 -390.197)"
                              fill="#ca2946"
                            ></path>
                          </svg>
                        </div>
                        <div className="membership-info-elem-title">
                          <span>المحاسبة</span>
                        </div>
                        {/*<div className="membership-info-elem-desc">
                          <span>
                            تستطيع ان تكون مدرب معتمد في ماجيك المعهد الدولي للتدريب
                            والتدريب الالكتروني
                          </span>
                        </div>*/}
                      </div>
                      <div className="membership-info-elem">
                        <div
                          style={{ marginBottom: '3%' }}
                          className="membership-info-elem-icon-placeholder"
                        >
                          <svg
                            className="side_menu_svg"
                            xmlns="http://www.w3.org/2000/svg"
                            width="39.374"
                            height="34.51"
                            viewBox="0 0 39.374 34.51"
                          >
                            <g id="chatting" transform="translate(0 -31.626)">
                              <path
                                id="Path_152"
                                data-name="Path 152"
                                d="M0,56.034a1.154,1.154,0,0,0,1.823.939l6.342-4.524a3.1,3.1,0,0,1,1.812-.58H23.926a4.458,4.458,0,0,0,4.453-4.453V32.78a1.154,1.154,0,0,0-1.154-1.154H4.453A4.458,4.458,0,0,0,0,36.079ZM2.307,36.079a2.148,2.148,0,0,1,2.146-2.146H26.072V47.416a2.148,2.148,0,0,1-2.146,2.146H9.978a5.4,5.4,0,0,0-3.152,1.009L2.307,53.795Z"
                                transform="translate(0 0)"
                                fill="#ca2946"
                              ></path>
                              <path
                                id="Path_153"
                                data-name="Path 153"
                                d="M171.349,152.419a4.458,4.458,0,0,0-4.453-4.453h-3.081a1.154,1.154,0,1,0,0,2.307H166.9a2.148,2.148,0,0,1,2.146,2.146v17.715l-4.519-3.223a5.4,5.4,0,0,0-3.152-1.009H147.423a2.148,2.148,0,0,1-2.146-2.146v-1.033a1.154,1.154,0,1,0-2.307,0v1.033a4.458,4.458,0,0,0,4.453,4.453h13.949a3.1,3.1,0,0,1,1.812.58l6.342,4.524a1.155,1.155,0,0,0,1.823-.939V152.419Z"
                                transform="translate(-131.975 -107.393)"
                                fill="#ca2946"
                              ></path>
                              <path
                                id="Path_154"
                                data-name="Path 154"
                                d="M94.016,115.646h13.327a1.154,1.154,0,1,0,0-2.307H94.016a1.154,1.154,0,1,0,0,2.307Z"
                                transform="translate(-85.721 -75.429)"
                                fill="#ca2946"
                              ></path>
                              <path
                                id="Path_155"
                                data-name="Path 155"
                                d="M94.016,185.646h13.327a1.154,1.154,0,1,0,0-2.307H94.016a1.154,1.154,0,1,0,0,2.307Z"
                                transform="translate(-85.721 -140.046)"
                                fill="#ca2946"
                              ></path>
                            </g>
                          </svg>
                        </div>
                        <div className="membership-info-elem-title">
                          <span>المحادثات</span>
                        </div>
                        {/*<div className="membership-info-elem-desc">
                          <span>
                            تستطيع ان تكون مدرب معتمد في ماجيك المعهد الدولي للتدريب
                            والتدريب الالكتروني
                          </span>
                        </div>*/}
                      </div>
                      <div className="membership-info-elem">
                        <div
                          style={{ marginBottom: '3%' }}
                          className="membership-info-elem-icon-placeholder"
                        >
                          <svg
                            className="home_menu_svg"
                            xmlns="http://www.w3.org/2000/svg"
                            width="28.5"
                            height="25.501"
                            viewBox="0 0 38.239 38.241"
                          >
                            <g id="gavel" transform="translate(-0.01 0)">
                              <path
                                data-name="Path 41"
                                d="M240.99,422.87v4.5a1.12,1.12,0,0,0,1.12,1.12h18a1.12,1.12,0,0,0,1.12-1.12v-4.5a1.12,1.12,0,0,0-1.12-1.12h-18A1.12,1.12,0,0,0,240.99,422.87Zm2.241,1.12H258.99v2.259H243.231Z"
                                transform="translate(-222.981 -390.25)"
                                fill="#ca2946"
                              ></path>
                              <path
                                data-name="Path 42"
                                d="M1,33.732a3.374,3.374,0,0,0,4.766,0L19.289,20.205l1.6,1.6-.8.8a1.12,1.12,0,0,0,0,1.584l3.182,3.182a1.12,1.12,0,0,0,1.584,0L34.4,17.822a1.12,1.12,0,0,0,0-1.584l-3.182-3.182a1.12,1.12,0,0,0-1.584,0l-.8.8L20.873,5.893l.8-.8a1.12,1.12,0,0,0,0-1.584L18.49.328a1.12,1.12,0,0,0-1.584,0L7.36,9.874a1.12,1.12,0,0,0,0,1.584l3.182,3.182a1.12,1.12,0,0,0,1.584,0l.8-.8,1.6,1.6L1,28.966a3.374,3.374,0,0,0,0,4.766ZM19.3,4.3l-7.962,7.962-1.6-1.6L17.7,2.7Zm3.169,19.092,7.962-7.962,1.6,1.6-7.962,7.962Zm.007-3.175L14.51,12.257l4.78-4.78,7.962,7.962ZM2.58,30.55,16.107,17.024l1.6,1.6L4.178,32.148a1.13,1.13,0,0,1-1.6-1.6Z"
                                transform="translate(0 0)"
                                fill="#ca2946"
                              ></path>
                            </g>
                          </svg>
                        </div>
                        <div className="membership-info-elem-title">
                          <span>الجلسات</span>
                        </div>
                        {/* <div className="membership-info-elem-desc">
                          <span>
                            تستطيع ان تكون مدرب معتمد في ماجيك المعهد الدولي للتدريب
                            والتدريب الالكتروني
                          </span>
                        </div>*/}
                      </div>
                    </Slider>
                  </div>
                </div>
              </div>
              <div className="body-subsection-container available-courses-section">
                <div className="upper-cut-overlay-layer">
                  <svg
                    viewBox="0 0 1600 175"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M-0.000106771 0.000104264L1600 0.000244141L1600 175C1333.33 121.667 1066.67 95.0002 800 95.0002C533.333 95.0002 266.667 121.667 -0.00012207 175L-0.000106771 0.000104264Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="body-section">
          <div className="body-section-container">
            <div className="body-section-content">
              <div className="body-subsection-container ">
                <div className="body-subsection-content">
                  <div className="body-subsection-content-header">
                    <div className="body-subsection-content-title">
                      <span>العروض</span>
                    </div>
                    <div className="body-subsection-content-desc">
                      <span>
                        تجربة مجانية لمدة 30 يوم <br />
                        اختر العرض الذي يناسب احتياجاتك.
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                    className="body-subsection-content-AC"
                  >
                    {/*<div className="membership-info-elem">
                      <div className="membership-info-elem-icon-placeholder">
                        <HomeWorkIcon
                          style={{ width: '30.165', height: '30.477', fill: '#ca2946' }}
                        />
                      </div>
                      <div className="membership-info-elem-title">
                        <span>مبتدئين</span>
                      </div>
                      <div
                        style={{
                          border: '1px solid rgba(0, 115, 255, 0.06)',
                          width: '168px',
                          borderRadius: '10px',
                          backgroundColor: 'white',
                          padding: '10px',
                        }}
                        className="membership-info-elem-desc"
                      >
                        <span>
                          {' '}
                          <strong style={{ fontSize: '20px' }}>30</strong> دينار/ شهر{' '}
                        </span>
                        <span> حساب محامي</span>
                      </div>
                    </div>*/}
                    <div className="membership-info-elem">
                      <div className="membership-info-elem-icon-placeholder">
                        <HomeWorkIcon
                          style={{ width: '30.165', height: '30.477', fill: '#ca2946' }}
                        />
                      </div>
                      <div className="membership-info-elem-title">
                        <span>مكتب محامي</span>
                      </div>
                      <div
                        style={{
                          border: '1px solid rgba(0, 115, 255, 0.06)',
                          width: '168px',
                          borderRadius: '10px',
                          backgroundColor: 'white',
                          padding: '10px',
                        }}
                        className="membership-info-elem-desc"
                      >
                        <span>
                          {' '}
                          <strong style={{ fontSize: '20px' }}>40</strong> دينار/ شهر{' '}
                        </span>
                        <span>
                          {' '}
                          حساب محامي
                          <br />
                          <strong style={{ fontSize: '20px' }}> + </strong>
                          مستخدم{' '}
                        </span>
                      </div>
                    </div>
                    <div className="membership-info-elem">
                      <div className="membership-info-elem-icon-placeholder">
                        <HomeWorkIcon
                          style={{ width: '30.165', height: '30.477', fill: '#ca2946' }}
                        />
                      </div>
                      <div className="membership-info-elem-title">
                        <span>مكتب محاماة</span>
                      </div>
                      <div
                        style={{
                          border: '1px solid rgba(0, 115, 255, 0.06)',
                          width: '168px',
                          borderRadius: '10px',
                          backgroundColor: 'white',
                          padding: '10px',
                        }}
                        className="membership-info-elem-desc"
                      >
                        <span>
                          {' '}
                          <strong style={{ fontSize: '20px' }}>80</strong> دينار/ شهر{' '}
                        </span>
                        <span>
                          {' '}
                          حساب محامي
                          <br />
                          <strong style={{ fontSize: '20px' }}> + 5 </strong>
                          مستخدمين{' '}
                        </span>
                      </div>
                    </div>
                    <div style={{ marginBottom: '3%' }} className="membership-info-elem">
                      <div className="membership-info-elem-icon-placeholder">
                        <BusinessIcon
                          style={{ width: '30.165', height: '30.477', fill: '#ca2946' }}
                        />
                      </div>
                      <div className="membership-info-elem-title">
                        <span>شركة</span>
                      </div>
                      <div
                        style={{
                          border: '1px solid rgba(0, 115, 255, 0.06)',
                          width: '168px',
                          borderRadius: '10px',
                          backgroundColor: 'white',
                          padding: '10px',
                        }}
                        className="membership-info-elem-desc"
                      >
                        <span>
                          {' '}
                          <strong style={{ fontSize: '20px' }}>100</strong> دينار/ شهر{' '}
                        </span>
                        <span>
                          {' '}
                          حساب محامي
                          <br />
                          <strong style={{ fontSize: '20px' }}> + 10 </strong>
                          مستخدمين{' '}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="body-subsection-container available-courses-section">
                <div className="upper-cut-overlay-layer">
                  <svg
                    viewBox="0 0 1600 175"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clip-rule="evenodd"
                      d="M-0.000106771 0.000104264L1600 0.000244141L1600 175C1333.33 121.667 1066.67 95.0002 800 95.0002C533.333 95.0002 266.667 121.667 -0.00012207 175L-0.000106771 0.000104264Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div className="body-subsection-content available-courses-container">
                  <div className="body-subsection-content-header">
                    <div className="body-subsection-content-title">
                      <span>مجلات قانونية</span>
                    </div>
                  </div>
                  <div className="body-subsection-content-AC">
                    <div className="available-courses-container">
                      <div className="available-courses-content">
                        {allLegalJournals && allLegalJournals.length > 0
                          ? allLegalJournals.map((allLegalJournal) => {
                              return (
                                <div
                                  key={allLegalJournal.id}
                                  className="available-courses-content-elem"
                                >
                                  <a
                                    target="_blank"
                                    href={`${host}${allLegalJournal.file}`}
                                  >
                                    <div className="course-miniature">
                                      <img src={J1} alt="" />
                                    </div>
                                    <div className="course-title-container">
                                      <div className="course-title-content">
                                        <span>{allLegalJournal.name.split('.')[0]}</span>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              );
                            })
                          : ''}
                      </div>
                      {/*<div className="show-more-courses-btn-container">
                        <button type="button" className="btn btn-outline-dark">
                          تحميل المزيد
                        </button>
                      </div>*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section style={{ height: '100px' }} className="footer-section">
          <div className="footer-section-container">
            <div className="footer-section-content">
              <div className="news-letter-section-container">
                <div className="news-letter-section-content">
                  <span style={{ fontSize: '15px', color: 'white' }}>
                    حقوق النشر © 2021.
                  </span>
                  <span style={{ fontSize: '15px', color: 'white' }}>
                    طورت بواسطة شركة <a href="#">TEK4OFFICE</a>
                  </span>
                  <span style={{ fontSize: '15px', color: 'white' }}>
                    support@lavocato.com
                  </span>
                  <span style={{ fontSize: '15px', color: 'white' }}>
                    088 579 56 (216+) {'  '} 885 401 99 (216+){'  '} 022 579 56 (216+)
                  </span>
                </div>
              </div>
              <div className="copyright-text-container"></div>
            </div>
          </div>
        </section>
        <div className={openPopup ? 'popup active' : 'popup'}>
          <div className="popup__title">تواصل معنا</div>
          <div style={{ width: '100%' }}>
            <form method="post" role="form" className="php-email-form">
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label style={{ fontSize: '15px' }} htmlFor="name">
                    الاسم
                  </label>
                  <input
                    onChange={(e) =>
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          first_name: e.target.value,
                        },
                      })
                    }
                    type="text"
                    name="first_name"
                    className={
                      hasError && !initialValues.first_name
                        ? 'form-control error'
                        : 'form-control'
                    }
                    value={initialValues.first_name}
                    id="name"
                    data-rule="minlen:4"
                    data-msg="Please enter at least 4 chars"
                  />
                  <div className="validate"></div>
                </div>
                <div className="form-group col-md-6">
                  <label style={{ fontSize: '15px' }} htmlFor="name">
                    اللقب
                  </label>
                  <input
                    onChange={(e) =>
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          last_name: e.target.value,
                        },
                      })
                    }
                    type="text"
                    name="last_name"
                    className={
                      hasError && !initialValues.last_name
                        ? 'form-control error'
                        : 'form-control'
                    }
                    value={initialValues.last_name}
                    id="name"
                    data-rule="minlen:4"
                    data-msg="Please enter at least 4 chars"
                  />
                  <div className="validate"></div>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label style={{ fontSize: '15px' }} htmlFor="name">
                    البريد الالكتروني
                  </label>
                  <input
                    onChange={(e) =>
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          email: e.target.value,
                        },
                      })
                    }
                    type="email"
                    className={
                      hasError && !initialValues.email
                        ? 'form-control error'
                        : 'form-control'
                    }
                    value={initialValues.email}
                    name="email"
                    id="subject"
                    data-rule="minlen:4"
                    data-msg="Please enter at least 8 chars of subject"
                  />
                  <div className="validate"></div>
                </div>
                <div className="form-group col-md-6">
                  <label style={{ fontSize: '15px' }} htmlFor="name">
                    الهاتف
                  </label>
                  <input
                    value={initialValues.phone}
                    onChange={(e) =>
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          phone: e.target.value,
                        },
                      })
                    }
                    type="text"
                    className={
                      hasError && !initialValues.phone
                        ? 'form-control error'
                        : 'form-control'
                    }
                    name="phone"
                    id="email"
                    data-rule="email"
                    data-msg="Please enter a valid email"
                  />
                  <div className="validate"></div>
                </div>
              </div>
              <div className="form-group">
                <label style={{ fontSize: '15px' }} htmlFor="name">
                  رسالة
                </label>
                <textarea
                  onChange={(e) =>
                    this.setState({
                      initialValues: {
                        ...initialValues,
                        message: e.target.value,
                      },
                    })
                  }
                  className={
                    hasError && !initialValues.message
                      ? 'form-control error'
                      : 'form-control'
                  }
                  name="message"
                  rows="10"
                  data-rule="required"
                  data-msg="Please write something for us"
                ></textarea>
                <div className="validate"></div>
              </div>
            </form>
          </div>
          <div style={{ display: 'flex' }} className="popup__btn_grp">
            <button
              onClick={() => this.setState({ openPopup: !openPopup })}
              className="cancel_popup"
            >
              <span>الغاء</span>
              <span>&lt;</span>
            </button>
            <button
              onClick={() => this.handleSubmit(initialValues)}
              disabled=""
              style={{ background: '#63B17D' }}
              type="button"
            >
              <svg
                className="tickSpan"
                xmlns="http://www.w3.org/2000/svg"
                width="20.125"
                height="17.774"
                viewBox="0 0 29.125 23.774"
              >
                <path
                  id="Path_92"
                  data-name="Path 92"
                  d="M1325.354,167.5l8.34,9.625,17.8-19.417"
                  transform="translate(-1323.842 -156.357)"
                  fill="none"
                  stroke="#f9f9f9"
                  strokeWidth="4"
                ></path>
              </svg>
              تسجيل
            </button>
          </div>
        </div>
        <div id="overlay" className={openPopup ? 'active' : ''}></div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Home);
