import React, { useEffect, useRef, useState } from 'react';
import Logo from '../../../assets/img/user-pic.png';
import clock from '../../../assets/images/clock.png';
import io from 'socket.io-client';
import { host } from '../../../utils/constants';
import Picker from 'emoji-picker-react';
import { diffDate, getPermissionFormated, isHasPermission } from '../../../utils/helpres';
import { CircularProgress } from '@mui/material';
import Img from '../../../assets/img/pf-icon2.png';
import { Col, Row } from 'reactstrap';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Popper from '@mui/material/Popper';
import MoreVertIcon from '@mui/icons-material/MoreVert';
const Question = (props) => {
  const { account } = props;
  const { id } = props.match.params;
  const [openCreatePostPopup, setOpenCreatePostPopup] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [text, setText] = useState('');
  const [content, setContent] = useState('');
  const [endingIndex, setEndingIndex] = useState(200);
  const [isLoading, setIsLoading] = useState(false);
  const [question, setQuestion] = useState(null);
  const [openOption, setOpenOption] = useState(false);
  const [responses, setResponse] = useState([]);
  const [openEmoji, setOpenEmoji] = useState(false);
  const [openEmojiModal, setOpenEmojiModal] = useState(false);
  const socket = useRef(null);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [openSmile, setOpenSmile] = React.useState(false);
  const [openSmileModal, setOpenSmileModal] = React.useState(false);
  const anchorRefSmile = React.useRef(null);
  const anchorRefSmileModal = React.useRef(null);
  useEffect(() => {
    //document.removeEventListener('mousedown', handleClickOutside);
    socket.current = io.connect(host);

    socket.current.emit(
      `question`,
      { id, account_type: account.login_access.account_type },
      (error) => {
        if (error) {
          alert(error);
        }
      },
    );
    socket.current.on('get-responses', (result, error) => {
      if (result.length > 0) {
        setResponse(result);
      }
      if (error) {
        alert(error);
      }
    });
    socket.current.on('get-question', (result, error) => {
      if (result) {
        setIsLoading(false);
        setQuestion(result);
        setContent(result.question);
        socket.current.emit('responses', { question_id: id }, (error) => {
          if (error) {
            alert(error);
          }
        });
      } else setQuestion(null);
      if (error) {
        alert(error);
      }
    });
  }, question);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleToggleSmile = () => {
    setOpenSmile((prevOpen) => !prevOpen);
    setOpen(false);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    if (anchorRefSmile.current && anchorRefSmile.current.contains(event.target)) {
      return;
    }
    if (
      anchorRefSmileModal.current &&
      anchorRefSmileModal.current.contains(event.target)
    ) {
      return;
    }
    setOpen(false);
    setOpenSmile(false);
    setOpenSmileModal(false);
  };
  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
      setOpenSmile(false);
      setOpenSmileModal(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
      setOpenSmileModal(false);
    }
  }
  const onEmojiClickModal = (event, emojiObject) => {
    setContent(content + emojiObject.emoji);
  };
  const onEmojiClick = (event, emojiObject) => {
    setText(text + emojiObject.emoji);
  };

  const sendMessage = (event) => {
    if (text) {
      let comment = {
        user_id: account.login_access.id,
        question_id: question.id,
        response: text,
      };
      socket.current.emit('comment-question', { comment }, () => setText(''));
      setText('');
      socket.current.emit('responses', { question_id: id }, (error) => {
        if (error) {
          alert(error);
        }
      });
    }
  };
  const likeQuestion = (question_id) => {
    socket.current.emit(
      `like-question`,
      { question_id, accountId: account.login_access.id },
      (error) => {
        if (error) {
          alert(error);
        }
      },
    );
  };
  const updateQuestion = (event) => {
    event.preventDefault();
    if (content) {
      socket.current.emit('update-question', { id: question.id, question: content }, () =>
        setText(''),
      );
      setText('');
      socket.current.emit(
        `question`,
        { id, account_type: account.login_access.account_type },
        (error) => {
          if (error) {
            alert(error);
          }
        },
      );
      socket.current.emit(`questions`, (error) => {
        if (error) {
          alert(error);
        }
      });
    }
  };
  const deleteQuestion = () => {
    socket.current.emit('delete-question', { id: question.id }, () => setText(''));
    socket.current.emit(`questions`, (error) => {
      if (error) {
        alert(error);
      }
    });
    props.history.push('/questions');
  };
  if (isLoading || !question) {
    return (
      <Row>
        <CircularProgress
          style={{
            textAlign: 'center',
            width: '30px',
            height: '30px',
            marginTop: '20%',
            marginRight: '50%',
          }}
        />
      </Row>
    );
  } else
    return (
      <div className="reports_main">
        <div className="reports_middle">
          <div style={{ backgroundColor: 'white' }} className="main-section-data">
            <Row>
              <Col lg={12} className="">
                <div style={{ display: 'block' }} className="media">
                  {account.login_access.account_type === 'lawyer' &&
                  account.id === question.lawyer_id ? (
                    <MoreVertIcon
                      onClick={handleToggle}
                      style={{
                        marginTop: '5px',
                        marginLeft: '5px',
                        cursor: 'pointer',
                        float: 'left',
                      }}
                    />
                  ) : (
                    ''
                  )}
                  <div
                    style={{
                      gap: '10px',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      height: '120px',
                      backgroundColor: 'white',
                    }}
                    className="count-box bg-soft-primary text-center"
                  >
                    <img
                      style={{ borderRadius: '50%', height: '66px' }}
                      src={
                        question.lawyer.thumbnail
                          ? `${host}${question.lawyer.thumbnail}`
                          : Logo
                      }
                      alt=""
                    />
                    <div>
                      <h3
                        style={{ textAlign: 'initial' }}
                        className="counter-count mb-1 text-dark"
                      >
                        {`${question.lawyer.first_name} ${question.lawyer.last_name}`}
                      </h3>

                      <p
                        style={{ textAlign: 'initial' }}
                        className="text-uppercase text-muted mb-0 f-14"
                      >
                        {diffDate(question.created_at) ? (
                          <span>
                            <img src={clock} alt="" /> {diffDate(question.created_at)}
                          </span>
                        ) : (
                          ''
                        )}
                      </p>
                      <p className="text-uppercase text-muted mb-0 f-14">
                        {question.question.slice(0, endingIndex)}
                        {question.question.length > endingIndex ? (
                          <a
                            onClick={() => setEndingIndex(question.question.length)}
                            title=""
                          >
                            عرض المزيد
                          </a>
                        ) : (
                          ''
                        )}
                      </p>
                    </div>
                  </div>
                  <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    placement="bottom-start"
                    transition
                    disablePortal
                    style={{
                      top: 110,
                      left: 30,
                      zIndex: '2000',
                    }}
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === 'bottom-start' ? 'left top' : 'left bottom',
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                              className="user_dropdown__content"
                              autoFocusItem={open}
                              id="composition-menu"
                              aria-labelledby="composition-button"
                              onKeyDown={handleListKeyDown}
                            >
                              <MenuItem onClick={handleClose}>
                                <Link
                                  to="#"
                                  onClick={() => {
                                    setOpenCreatePostPopup(!openCreatePostPopup);
                                    setOpenOption(!openOption);
                                  }}
                                >
                                  <svg
                                    className="params_center_svg"
                                    id="Group_23"
                                    data-name="Group 23"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="23.304"
                                    height="24.288"
                                    viewBox="0 0 23.304 24.288"
                                  >
                                    <g id="Group_22" data-name="Group 22">
                                      <path
                                        id="Path_86"
                                        data-name="Path 86"
                                        d="M26.636,19.431h.131L33.1,18.053a.623.623,0,0,0,.328-.164L44.163,7.156A4.136,4.136,0,0,0,45.377,4.2a4.267,4.267,0,0,0-1.214-2.987A4.135,4.135,0,0,0,41.209,0a4.037,4.037,0,0,0-2.954,1.247l-10.7,10.733a.591.591,0,0,0-.2.3l-1.378,6.335a.722.722,0,0,0,.2.624A.656.656,0,0,0,26.636,19.431ZM41.209,1.314a2.855,2.855,0,0,1,2.856,2.855q0,.016,0,.033a2.757,2.757,0,0,1-.821,2l-4.037-4.07A2.823,2.823,0,0,1,41.209,1.314ZM38.287,3.086l4.037,4.037L32.97,16.444l-4.037-4ZM28.408,13.753,31.657,17l-4.168.919Z"
                                        transform="translate(-24.83 0)"
                                        fill="#ca2946"
                                      ></path>
                                      <path
                                        id="Path_87"
                                        data-name="Path 87"
                                        d="M30.485,365.717H8.494a.656.656,0,0,0,0,1.313H30.485a.656.656,0,0,0,0-1.313Z"
                                        transform="translate(-7.838 -342.742)"
                                        fill="#ca2946"
                                      ></path>
                                    </g>
                                  </svg>
                                  تعديل
                                </Link>
                              </MenuItem>
                              <MenuItem onClick={handleClose}>
                                <Link
                                  to="#"
                                  onClick={() => {
                                    setOpenDeletePopup(!openDeletePopup);
                                    setOpenOption(!openOption);
                                  }}
                                >
                                  <svg
                                    className="params_center_svg"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20.906"
                                    height="25.74"
                                    viewBox="0 0 20.906 25.74"
                                  >
                                    <g id="delete" transform="translate(0 0)">
                                      <path
                                        id="Path_82"
                                        data-name="Path 82"
                                        d="M223,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,223,154.7Zm0,0"
                                        transform="translate(-208.991 -145.377)"
                                        fill="#ca2946"
                                      ></path>
                                      <path
                                        id="Path_83"
                                        data-name="Path 83"
                                        d="M105,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,105,154.7Zm0,0"
                                        transform="translate(-98.105 -145.377)"
                                        fill="#ca2946"
                                      ></path>
                                      <path
                                        id="Path_84"
                                        data-name="Path 84"
                                        d="M1.709,7.662V22.514a3.326,3.326,0,0,0,.884,2.294,2.969,2.969,0,0,0,2.154.931H16.152a2.968,2.968,0,0,0,2.154-.931,3.326,3.326,0,0,0,.884-2.294V7.662A2.3,2.3,0,0,0,18.6,3.133H15.513V2.38A2.368,2.368,0,0,0,13.126,0H7.773A2.368,2.368,0,0,0,5.386,2.38v.754H2.3a2.3,2.3,0,0,0-.591,4.528ZM16.152,24.533H4.747a1.909,1.909,0,0,1-1.832-2.019V7.715h15.07v14.8a1.909,1.909,0,0,1-1.832,2.019ZM6.592,2.38A1.161,1.161,0,0,1,7.773,1.2h5.353A1.161,1.161,0,0,1,14.308,2.38v.754H6.592ZM2.3,4.339H18.6a1.085,1.085,0,0,1,0,2.17H2.3a1.085,1.085,0,0,1,0-2.17Zm0,0"
                                        transform="translate(0.003 0.002)"
                                        fill="#ca2946"
                                      ></path>
                                      <path
                                        id="Path_85"
                                        data-name="Path 85"
                                        d="M164,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,164,154.7Zm0,0"
                                        transform="translate(-153.548 -145.377)"
                                        fill="#ca2946"
                                      ></path>
                                    </g>
                                  </svg>
                                  حذف
                                </Link>
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </div>
              </Col>
            </Row>
            <div className="job-status-bar">
              <ul className="like-com">
                <li onClick={() => likeQuestion(id)}>
                  <a
                    style={{
                      color:
                        question.question_likes.length > 0 &&
                        question.question_likes.find(
                          (el) => el.user_id === account.login_access.id,
                        )
                          ? 'rgb(189, 35, 60)'
                          : '',
                    }}
                  >
                    <i className="fas fa-heart"></i> اعجاب{' '}
                    {question.question_likes.length > 0
                      ? question.question_likes.length
                      : ''}
                  </a>
                </li>
                <li>
                  <a className="com">
                    <i className="fas fa-comment-alt"></i> تعليق{' '}
                    {question.responses.length > 0 ? question.responses.length : ''}
                  </a>
                </li>
              </ul>
            </div>
            <Row>
              {responses.length > 0
                ? responses.map((response) => {
                    return (
                      <Col key={response.id} lg={12} className="">
                        <div className="media">
                          <div
                            style={{
                              gap: '10px',
                              width: '240px',
                              display: 'flex',
                              alignItems: 'center',
                              height: '120px',
                              backgroundColor: 'white',
                            }}
                            className="count-box bg-soft-primary text-center"
                          >
                            <img
                              style={{ borderRadius: '50%', height: '66px' }}
                              src={
                                response.login_access.lawyer.thumbnail
                                  ? `${host}${response.login_access.lawyer.thumbnail}`
                                  : Logo
                              }
                              alt=""
                            />
                            <div>
                              <h3 className="counter-count mb-1 text-dark">
                                {response.login_access.lawyer
                                  ? `${response.login_access.lawyer.first_name} ${response.login_access.lawyer.last_name}`
                                  : `${response.login_access.secretary.first_name} ${response.login_access.secretary.last_name}`}
                              </h3>
                              <p className="text-uppercase text-muted mb-0 f-14">
                                {diffDate(response.created_at) ? (
                                  <span>
                                    <img src={clock} alt="" />{' '}
                                    {diffDate(response.created_at)}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </p>
                              <p className="text-uppercase text-muted mb-0 f-14">
                                {response.response}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    );
                  })
                : ''}
            </Row>
            {account.login_access.account_type === 'lawyer' ? (
              <div style={{ padding: '15px' }} className="postcodmsment">
                <div className="msg-field" style={{ height: '4rem', display: 'flex' }}>
                  <img
                    style={{ maxWidth: '5%' }}
                    src={account.photo ? `${host}${account.photo}` : Logo}
                    alt=""
                  />
                  {/*<div
                    //style={{ display: 'block' }}
                    className={
                      openEmoji
                        ? 'ed-options-emoji picker active2'
                        : 'ed-options-emoji picker'
                    }
                  >
                    {openEmoji ? (
                      <Picker
                        style={{ marginRight: '-164%', marginTop: '-363%' }}
                        onEmojiClick={onEmojiClick}
                      />
                    ) : (
                      ''
                    )}
                  </div>*/}
                  <Popper
                    open={openSmile}
                    anchorEl={anchorRefSmile.current}
                    role={undefined}
                    placement="bottom-start"
                    transition
                    disablePortal
                    style={{
                      zIndex: '2000',
                    }}
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === 'bottom-start' ? 'left top' : 'left bottom',
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                              className="user_dropdown__content"
                              autoFocusItem={openSmile}
                              id="composition-menu"
                              aria-labelledby="composition-button"
                              onKeyDown={handleListKeyDown}
                            >
                              <Picker
                                style={{ marginRight: '-164%', marginTop: '-363%' }}
                                onEmojiClick={onEmojiClick}
                              />
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                  <div style={{ width: '31%' }} className="lawsuit_form__sections">
                    <textarea
                      onChange={(e) => {
                        setText(e.target.value);
                        setOpenEmoji(false);
                      }}
                      onKeyPress={(event) => {
                        if (event.key === 'Enter') {
                          setOpenEmoji(false);
                          sendMessage(event);
                        } else null;
                      }}
                      id="inputPassword"
                      placeholder="تعليق"
                      value={text}
                    />
                  </div>
                  <div
                    style={{
                      cursor: 'pointer',
                      marginLeft: '1%',
                      marginTop: '1%',
                    }}
                    className="emoji-btn"
                    ref={anchorRefSmile}
                    onClick={() => {
                      setOpenSmile(!openSmile);
                    }}
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.82031 11.8477C7.53646 13.0508 8.59635 13.6523 10 13.6523C11.4036 13.6523 12.4635 13.0508 13.1797 11.8477H14.6836C14.3112 12.793 13.6953 13.5664 12.8359 14.168C12.0052 14.7409 11.0599 15.0273 10 15.0273C8.9401 15.0273 7.98047 14.7409 7.12109 14.168C6.29036 13.5664 5.6888 12.793 5.31641 11.8477H6.82031ZM4.80078 15.1992C6.26172 16.6315 7.99479 17.3477 10 17.3477C12.0052 17.3477 13.724 16.6315 15.1562 15.1992C16.6172 13.7383 17.3477 12.0052 17.3477 10C17.3477 7.99479 16.6172 6.27604 15.1562 4.84375C13.724 3.38281 12.0052 2.65234 10 2.65234C7.99479 2.65234 6.26172 3.38281 4.80078 4.84375C3.36849 6.27604 2.65234 7.99479 2.65234 10C2.65234 12.0052 3.36849 13.7383 4.80078 15.1992ZM3.51172 3.55469C5.31641 1.75 7.47917 0.847656 10 0.847656C12.5208 0.847656 14.6693 1.75 16.4453 3.55469C18.25 5.33073 19.1523 7.47917 19.1523 10C19.1523 12.5208 18.25 14.6836 16.4453 16.4883C14.6693 18.2643 12.5208 19.1523 10 19.1523C7.47917 19.1523 5.31641 18.2643 3.51172 16.4883C1.73568 14.6836 0.847656 12.5208 0.847656 10C0.847656 7.47917 1.73568 5.33073 3.51172 3.55469ZM5.78906 8.71094C5.53125 8.42448 5.40234 8.09505 5.40234 7.72266C5.40234 7.35026 5.53125 7.03516 5.78906 6.77734C6.07552 6.49089 6.40495 6.34766 6.77734 6.34766C7.14974 6.34766 7.46484 6.49089 7.72266 6.77734C8.00911 7.03516 8.15234 7.35026 8.15234 7.72266C8.15234 8.09505 8.00911 8.42448 7.72266 8.71094C7.46484 8.96875 7.14974 9.09766 6.77734 9.09766C6.40495 9.09766 6.07552 8.96875 5.78906 8.71094ZM12.2344 8.71094C11.9766 8.42448 11.8477 8.09505 11.8477 7.72266C11.8477 7.35026 11.9766 7.03516 12.2344 6.77734C12.5208 6.49089 12.8503 6.34766 13.2227 6.34766C13.5951 6.34766 13.9102 6.49089 14.168 6.77734C14.4544 7.03516 14.5977 7.35026 14.5977 7.72266C14.5977 8.09505 14.4544 8.42448 14.168 8.71094C13.9102 8.96875 13.5951 9.09766 13.2227 9.09766C12.8503 9.09766 12.5208 8.96875 12.2344 8.71094Z"
                        fill="#908E8E"
                      />
                    </svg>
                  </div>
                  <button
                    // disabled={text !== '' ? false : true}
                    onClick={() => sendMessage()}
                    className="jalaset_add_button"
                  >
                    ارسل
                  </button>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className={openCreatePostPopup ? 'popup active' : 'popup'}>
          <div className="popup__title">تعديل المنشور</div>
          <div style={{ display: 'block' }} className="lawsuit_form__sections">
            <textarea
              onChange={(e) => {
                setContent(e.target.value);
                setOpenSmileModal(false);
              }}
              style={{ width: '100%', height: '10rem' }}
              className=""
              name="facts"
              id="lawsuit_events"
              value={content}
            ></textarea>
          </div>

          <Popper
            open={openSmileModal}
            anchorEl={anchorRefSmileModal.current}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal
            style={{
              zIndex: '2000',
            }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      className="user_dropdown__content"
                      autoFocusItem={openSmileModal}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                    >
                      <Picker
                        style={{ marginRight: '-164%', marginTop: '-363%' }}
                        onEmojiClick={onEmojiClickModal}
                      />
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
          <div
            style={{ cursor: 'pointer' }}
            className="emoji-btn"
            ref={anchorRefSmileModal}
            onClick={() => {
              setOpenSmileModal(true);
            }}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.82031 11.8477C7.53646 13.0508 8.59635 13.6523 10 13.6523C11.4036 13.6523 12.4635 13.0508 13.1797 11.8477H14.6836C14.3112 12.793 13.6953 13.5664 12.8359 14.168C12.0052 14.7409 11.0599 15.0273 10 15.0273C8.9401 15.0273 7.98047 14.7409 7.12109 14.168C6.29036 13.5664 5.6888 12.793 5.31641 11.8477H6.82031ZM4.80078 15.1992C6.26172 16.6315 7.99479 17.3477 10 17.3477C12.0052 17.3477 13.724 16.6315 15.1562 15.1992C16.6172 13.7383 17.3477 12.0052 17.3477 10C17.3477 7.99479 16.6172 6.27604 15.1562 4.84375C13.724 3.38281 12.0052 2.65234 10 2.65234C7.99479 2.65234 6.26172 3.38281 4.80078 4.84375C3.36849 6.27604 2.65234 7.99479 2.65234 10C2.65234 12.0052 3.36849 13.7383 4.80078 15.1992ZM3.51172 3.55469C5.31641 1.75 7.47917 0.847656 10 0.847656C12.5208 0.847656 14.6693 1.75 16.4453 3.55469C18.25 5.33073 19.1523 7.47917 19.1523 10C19.1523 12.5208 18.25 14.6836 16.4453 16.4883C14.6693 18.2643 12.5208 19.1523 10 19.1523C7.47917 19.1523 5.31641 18.2643 3.51172 16.4883C1.73568 14.6836 0.847656 12.5208 0.847656 10C0.847656 7.47917 1.73568 5.33073 3.51172 3.55469ZM5.78906 8.71094C5.53125 8.42448 5.40234 8.09505 5.40234 7.72266C5.40234 7.35026 5.53125 7.03516 5.78906 6.77734C6.07552 6.49089 6.40495 6.34766 6.77734 6.34766C7.14974 6.34766 7.46484 6.49089 7.72266 6.77734C8.00911 7.03516 8.15234 7.35026 8.15234 7.72266C8.15234 8.09505 8.00911 8.42448 7.72266 8.71094C7.46484 8.96875 7.14974 9.09766 6.77734 9.09766C6.40495 9.09766 6.07552 8.96875 5.78906 8.71094ZM12.2344 8.71094C11.9766 8.42448 11.8477 8.09505 11.8477 7.72266C11.8477 7.35026 11.9766 7.03516 12.2344 6.77734C12.5208 6.49089 12.8503 6.34766 13.2227 6.34766C13.5951 6.34766 13.9102 6.49089 14.168 6.77734C14.4544 7.03516 14.5977 7.35026 14.5977 7.72266C14.5977 8.09505 14.4544 8.42448 14.168 8.71094C13.9102 8.96875 13.5951 9.09766 13.2227 9.09766C12.8503 9.09766 12.5208 8.96875 12.2344 8.71094Z"
                fill="#908E8E"
              />
            </svg>
          </div>
          <div className="popup__btn_grp">
            <button
              onClick={() => setOpenCreatePostPopup(!openCreatePostPopup)}
              className="cancel_popup"
            >
              <span>الغاء</span>
              <span>&lt;</span>
            </button>
            <button
              onClick={(e) => {
                updateQuestion(e);
                setOpenCreatePostPopup(!openCreatePostPopup);
              }}
              style={{ background: '#63B17D' }}
            >
              <span>تأكيد</span>
              <span className="trashSpan">
                <svg
                  className="tickSpan"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.125"
                  height="17.774"
                  viewBox="0 0 29.125 23.774"
                >
                  <path
                    id="Path_92"
                    data-name="Path 92"
                    d="M1325.354,167.5l8.34,9.625,17.8-19.417"
                    transform="translate(-1323.842 -156.357)"
                    fill="none"
                    stroke="#f9f9f9"
                    strokeWidth="4"
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>
        <div className={openDeletePopup ? 'popup active' : 'popup'}>
          <div className="popup__title">أنت بصدد الحذف</div>
          <div className="popup__btn_grp">
            <button
              onClick={() => setOpenDeletePopup(!openDeletePopup)}
              className="cancel_popup"
            >
              <span>الغاء</span>
              <span>&lt;</span>
            </button>
            <button
              style={{ background: 'rgb(189, 35, 60)' }}
              onClick={() => {
                deleteQuestion();
                setOpenDeletePopup(!openDeletePopup);
              }}
            >
              <span>تأكيد</span>
              <span className="trashSpan">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.906"
                  height="25.74"
                  viewBox="0 0 20.906 25.74"
                >
                  <g id="delete" transform="translate(0.003 0.002)">
                    <path
                      id="Path_82"
                      data-name="Path 82"
                      d="M223,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,223,154.7Zm0,0"
                      transform="translate(-208.995 -145.379)"
                      fill="#fff"
                    />
                    <path
                      id="Path_83"
                      data-name="Path 83"
                      d="M105,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,105,154.7Zm0,0"
                      transform="translate(-98.108 -145.379)"
                      fill="#fff"
                    />
                    <path
                      id="Path_84"
                      data-name="Path 84"
                      d="M1.709,7.662V22.514a3.326,3.326,0,0,0,.884,2.294,2.969,2.969,0,0,0,2.154.931H16.152a2.968,2.968,0,0,0,2.154-.931,3.326,3.326,0,0,0,.884-2.294V7.662A2.3,2.3,0,0,0,18.6,3.133H15.513V2.38A2.368,2.368,0,0,0,13.126,0H7.773A2.368,2.368,0,0,0,5.386,2.38v.754H2.3a2.3,2.3,0,0,0-.591,4.528ZM16.152,24.533H4.747a1.909,1.909,0,0,1-1.832-2.019V7.715h15.07v14.8a1.909,1.909,0,0,1-1.832,2.019ZM6.592,2.38A1.161,1.161,0,0,1,7.773,1.2h5.353A1.161,1.161,0,0,1,14.308,2.38v.754H6.592ZM2.3,4.339H18.6a1.085,1.085,0,0,1,0,2.17H2.3a1.085,1.085,0,0,1,0-2.17Zm0,0"
                      transform="translate(0 0)"
                      fill="#fff"
                    />
                    <path
                      id="Path_85"
                      data-name="Path 85"
                      d="M164,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,164,154.7Zm0,0"
                      transform="translate(-153.551 -145.379)"
                      fill="#fff"
                    />
                  </g>
                </svg>
              </span>
            </button>
          </div>
        </div>
        <div
          id="overlay"
          className={openDeletePopup || openCreatePostPopup ? 'active' : ''}
        ></div>
      </div>
    );
};

export default Question;
