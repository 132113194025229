import React, { useEffect, Fragment } from 'react';
//import React, { Component } from 'react';
import {
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Font,
} from '@react-pdf/renderer';
import { NumberToLetter } from 'convertir-nombre-lettre';
import moment from 'moment';
import Icon from '../../../assets/img/logo.png';
import IconS from '../../../assets/img/STEK4O.png';
import Roboto from '../../../assets/Almarai-Regular.ttf';
// Create styles

Font.register({
  family: 'Almarai',
  src: Roboto,
});

const newstyles = StyleSheet.create({
  page: {
    display: 'inline-grid',
    flexDirection: 'row',
    marginTop: 10,
  },
  PDFViewer: {
    width: '100%',
    //height: '550px',
    // fontFamily: 'Almarai',
  },
  section: {
    marginLeft: 10,
    padding: 10,
  },
  sectionNameDoctor: {
    marginLeft: 10,
    marginRight: 50,
    padding: 10,
  },
  section2: {
    marginLeft: 10,
    padding: 10,
  },
  hr: {
    BorderTop: '1px bold red',
  },
  cabinet: {
    display: 'flex',
    marginTop: 80,
    flexDirection: 'row',
    marginLeft: 20,
    marginRight: 60,
  },
  sectionpayment: {
    margin: 10,
    padding: 10,
    flexDirection: 'row',
  },
  detailpayment: {
    margin: 10,
    padding: 10,
    flexDirection: 'row',
  },
  detailpaymentText: {
    color: '#070707',
    fontWeight: 'normal',
    fontSize: 12,
    margin: 3,
    width: '45%',
    fontFamily: 'Almarai',
  },
  detailpaymentText2: {
    color: '#070707',
    fontWeight: 'normal',
    marginLeft: 10,
    padding: 10,
    fontFamily: 'Almarai',
  },
  text: {
    color: '#5446db',
    fontWeight: 'bolder',
    //  fontFamily: 'Almarai',
  },
  payment: {
    color: '#5446db',
    fontWeight: '500',
    marginLeft: '1%',
  },
  text1: {
    color: '#49494c',
    fontSize: 12,
    fontWeight: 'normal',
    margin: 3,
    width: 300,
    fontFamily: 'Almarai',
  },
  images: {
    marginLeft: 20,
    // padding: 10,
    width: 50,
    height: 50,
  },
  imagesS: {
    marginLeft: 80,
    // padding: 10,
    width: 180,
    height: 100,
  },
});
function FacturePdf(props) {
  const payment = props.location.state.payment;
  const account = props.location.state.account;
  document.title = `Facture n°: !{payment.number}`;
  useEffect(() => {
    props.changeActiveItemSideBar(100, `فاتورة عدد: ${payment.number}`);
  });

  return (
    <>
      <Fragment>
        <PDFViewer style={newstyles.PDFViewer}>
          <Document>
            {/*orientation="landscape"*/}
            <Page size="A4">
              <View style={newstyles.cabinet}>
                <View style={newstyles.sectionNameDoctor}>
                  <Image style={newstyles.images} src={Icon} />
                </View>
                <View style={{ ...newstyles.section, marginTop: 5, marginLeft: 300 }}>
                  <Text style={newstyles.detailpaymentText}>Nom:</Text>
                  <Text style={newstyles.detailpaymentText}>Tél :</Text>
                </View>
                <View style={{ ...newstyles.section2, marginTop: 5 }}>
                  <Text style={newstyles.text1}>
                    {`${account.first_name} ${account.last_name}`}
                  </Text>
                  <Text style={newstyles.text1}>{account.phone}</Text>
                </View>
              </View>
              <View
                style={{
                  borderBottomColor: 'black',
                  marginLeft: 50,
                  marginRight: 60,
                  borderBottomWidth: 1,
                }}
              />
              <View
                style={{
                  marginLeft: 50,
                  marginRight: 60,
                  marginTop: 5,
                }}
              >
                <Text
                  style={{
                    color: '#49494c',
                    fontSize: 10,
                    fontWeight: 'bolder',
                    display: 'block',
                    margin: 3,
                    textAlign: 'center',
                  }}
                ></Text>
              </View>
              <View
                style={{
                  borderBottomColor: 'black',
                  marginTop: 5,
                  marginLeft: 50,
                  marginRight: 60,
                  borderBottomWidth: 1,
                  borderWidth: 1,
                  borderRadius: 5,
                  height: 50,
                }}
              >
                <Text
                  style={{
                    color: '#070707',
                    fontWeight: '1000',
                    fontSize: 12,
                    padding: 12,
                    fontStyle: 'gras',
                    textAlign: 'center',
                    //fontFamily: 'Georgia, serif',
                    margin: 3,
                    // fontFamily: 'Almarai',
                  }}
                >
                  Facture n° : {payment.number}
                </Text>
              </View>
              <View
                style={{
                  marginLeft: 80,
                  marginTop: 20,
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Text
                  style={{
                    color: '#49494c',
                    fontSize: 12,
                    fontWeight: 'bolder',
                  }}
                >
                  Date Facture :
                </Text>
                <Text
                  style={{
                    color: '#070707',
                    fontWeight: '1000',
                    fontSize: 12,
                    fontStyle: 'gras',
                    //fontFamily: 'Georgia, serif',
                    marginRight: 30,
                    marginLeft: 10,
                  }}
                >
                  {moment(payment.date).format('YYYY/MM')}
                </Text>
              </View>
              <View
                style={{
                  borderBottomColor: 'black',
                  marginLeft: 50,
                  marginRight: 60,
                  borderBottomWidth: 1,
                  borderWidth: 1,
                  borderRadius: 5,
                  height: 150,
                  marginTop: 30,
                }}
              >
                <Text
                  style={{
                    color: '#070707',
                    fontWeight: '1000',
                    fontSize: 12,
                    padding: 12,
                    fontStyle: 'gras',
                    //fontFamily: 'Georgia, serif',
                    marginLeft: 20,
                    textDecoration: 'underline',
                  }}
                >
                  Détails
                </Text>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 80,
                    marginRight: 60,
                  }}
                >
                  <View style={newstyles.section2}>
                    <Text style={newstyles.text1}>Total HT</Text>
                    <Text style={newstyles.text1}>TVA ( 19% )</Text>
                    <Text style={newstyles.detailpaymentText}>Total TTC</Text>
                  </View>
                  <View style={newstyles.section2}>
                    <Text style={newstyles.text1}>:</Text>
                    <Text style={newstyles.text1}>:</Text>
                    <Text style={newstyles.text1}>:</Text>
                  </View>
                  <View style={newstyles.section}>
                    <Text
                      style={{
                        color: '#070707',
                        fontWeight: '1000',
                        fontSize: 12,
                        fontStyle: 'gras',
                        //fontFamily: 'Georgia, serif',
                        margin: 3,
                        marginRight: 250,
                        textAlign: 'right',
                        //textDecoration: 'underline',
                      }}
                    >
                      {parseFloat(payment.price - (payment.price * 19) / 100).toFixed(3)}
                    </Text>
                    <Text
                      style={{
                        color: '#070707',
                        fontWeight: '1000',
                        fontSize: 12,
                        fontStyle: 'gras',
                        //fontFamily: 'Georgia, serif',
                        margin: 3,
                        marginRight: 250,
                        textAlign: 'right',
                        textDecoration: 'underline',
                      }}
                    >
                      {parseFloat((payment.price * 19) / 100).toFixed(3)}
                    </Text>
                    <Text
                      style={{
                        color: '#070707',
                        fontWeight: '1000',
                        fontSize: 12,
                        fontStyle: 'gras',
                        //fontFamily: 'Georgia, serif',
                        margin: 3,
                        marginRight: 250,
                        textAlign: 'right',
                        // textDecoration: 'underline',
                      }}
                    >
                      {parseFloat(payment.price).toFixed(3)}
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 50,
                  marginRight: 60,
                  marginTop: 3,
                }}
              >
                <Text
                  style={{
                    color: '#49494c',
                    fontSize: 12,
                    fontWeight: 'bolder',
                    display: 'block',
                    margin: 3,
                    textAlign: 'center',
                  }}
                >
                  Arretée la présente facture à la somme de :
                </Text>
                <Text
                  style={{
                    color: '#49494c',
                    fontSize: 12,
                    fontWeight: 'bolder',
                    display: 'block',
                    margin: 3,
                    textAlign: 'center',
                  }}
                >
                  #{' '}
                  {NumberToLetter(
                    parseFloat(payment.price).toFixed(3).toString().replace('.', ''),
                  )
                    .replace('mille', 'Dinars')
                    .replace('millions', 'mille')}{' '}
                  #
                </Text>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: 30,
                  marginTop: 50,
                }}
              >
                <View style={newstyles.section2}>
                  <Text style={newstyles.detailpaymentText}>Société : TEK4OFFICE</Text>
                  <Text style={newstyles.detailpaymentText}>
                    Adresse : Cité Younnes Awana Raidh, Sousse, Tunisie
                  </Text>
                  <Text style={newstyles.detailpaymentText}>
                    RIB : TN 59 07 014 0085101102906 68
                  </Text>
                </View>
              </View>
              <View style={newstyles.sectionNameDoctor}>
                <Image style={newstyles.imagesS} src={IconS} />
              </View>
              <View
                style={{
                  height: '40px',
                  position: 'absolute',
                  left: 0,
                  bottom: 0,
                  width: '100%',
                  color: 'white',
                  textAlign: 'center',
                }}
              >
                <View
                  style={{
                    borderBottomColor: 'black',
                    marginLeft: 50,
                    marginRight: 60,
                    //marginTop: 350,
                    borderBottomWidth: 1,
                  }}
                />
                <View
                  style={
                    {
                      // bottom:0,
                    }
                  }
                >
                  <Text
                    style={{
                      top: 10,
                      color: '#070707',
                      fontWeight: '1000',
                      fontSize: 10,
                      fontStyle: 'gras',
                      //fontFamily: 'Georgia, serif',
                      margin: 3,
                    }}
                  >
                    {account.address_street} Tél/Fax: +216 {account.fix_number || '-'}{' '}
                    GSM: +216 {account.phone}
                  </Text>
                </View>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      </Fragment>
    </>
  );
}

export default FacturePdf;
