import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

//import images
import Img1 from '../assets/images/features/img-1.png';
import HomeUrl from '../assets/images/home-border.png';

export default class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pricing: [
        {
          id: 1,
          title: 'حساب محامي',
          description: 'Semper urna veal tempus pharetra elit habisse platea dictumst.',
          icon: 'mdi-account',
          titlePrimary: false,
          regularprice: '90 دينار / 3 شهور',
          saleprice: '290 دينار / سنة',
          pricingTag: false,
          child: [
            {
              btitle: '',
              title: '+ مستخدم',
              icon: 'mdi-checkbox-marked-circle text-success',
            },
            {
              btitle: '',
              title: 'مذكرات',
              icon: 'mdi-checkbox-marked-circle text-success',
            },
            {
              btitle: '',
              title: 'محاسبة',
              icon: 'mdi-checkbox-marked-circle text-success',
            },
            {
              btitle: '',
              title: 'الجلسات',
              icon: 'mdi-checkbox-marked-circle text-success',
            },
            {
              btitle: '',
              title: 'القضايا',
              icon: 'mdi-checkbox-marked-circle text-success',
            },
            {
              btitle: '',
              title: 'العقود',
              icon: 'mdi-checkbox-marked-circle text-success',
            },
            {
              btitle: '',
              title: 'تبادل التقارير',
              icon: 'mdi-checkbox-marked-circle text-success',
            },
            {
              btitle: '',
              title: 'ملفات الحرفاء',
              icon: 'mdi-checkbox-marked-circle text-success',
            },
            { btitle: '', title: 'المحادثات', icon: 'mdi-close-circle text-danger' },
            { btitle: '', title: 'المهام', icon: 'mdi-close-circle text-danger' },
            {
              btitle: '',
              title: 'إدارة المستخدمين',
              icon: 'mdi-close-circle text-danger',
            },
            {
              btitle: '',
              title: 'تطبيق الهاتف الجوال للمحامي',
              icon: 'mdi-checkbox-marked-circle text-success',
            },
            {
              btitle: '',
              title: 'تطبيق الهاتف الجوال للحريف',
              icon: 'mdi-close-circle text-danger',
            },
          ],
        },
        {
          id: 2,
          title: 'مكتب محاماة',
          titlePrimary: true,
          description: 'Semper urna veal tempus pharetra elit habisse platea dictumst.',
          icon: 'mdi-account-multiple text-primary',
          regularprice: '195 دينار / 3 شهور',
          saleprice: '690 دينار / سنة',
          pricingTag: true,
          child: [
            {
              btitle: '+ 5',
              title: ' مستخدمين',
              icon: 'mdi-checkbox-marked-circle text-success',
            },
            {
              btitle: '',
              title: 'مذكرات',
              icon: 'mdi-checkbox-marked-circle text-success',
            },
            {
              btitle: '',
              title: 'محاسبة',
              icon: 'mdi-checkbox-marked-circle text-success',
            },
            {
              btitle: '',
              title: 'الجلسات',
              icon: 'mdi-checkbox-marked-circle text-success',
            },
            {
              btitle: '',
              title: 'القضايا',
              icon: 'mdi-checkbox-marked-circle text-success',
            },
            {
              btitle: '',
              title: 'العقود',
              icon: 'mdi-checkbox-marked-circle text-success',
            },
            {
              btitle: '',
              title: 'تبادل التقارير',
              icon: 'mdi-checkbox-marked-circle text-success',
            },
            {
              btitle: '',
              title: 'ملفات الحرفاء',
              icon: 'mdi-checkbox-marked-circle text-success',
            },
            {
              btitle: '',
              title: 'المحادثات',
              icon: 'mdi-checkbox-marked-circle text-success',
            },
            {
              btitle: '',
              title: 'المهام',
              icon: 'mdi-checkbox-marked-circle text-success',
            },
            {
              btitle: '',
              title: 'إدارة المستخدمين',
              icon: 'mdi-checkbox-marked-circle text-success',
            },
            {
              btitle: '',
              title: 'تطبيق الهاتف الجوال للمحامي',
              icon: 'mdi-checkbox-marked-circle text-success',
            },
            {
              btitle: '',
              title: 'تطبيق الهاتف الجوال للحريف',
              icon: 'mdi-checkbox-marked-circle text-success',
            },
          ],
        },
        {
          id: 3,
          title: 'شركة',
          description: 'Semper urna veal tempus pharetra elit habisse platea dictumst.',
          titlePrimary: false,
          icon: 'mdi-account-multiple-plus',
          regularprice: '320 دينار / 3 شهور',
          saleprice: '1050 دينار / سنة',
          pricingTag: false,
          child: [
            {
              btitle: '+ 10',
              title: ' مستخدمين',
              icon: 'mdi-checkbox-marked-circle text-success',
            },
            {
              btitle: '',
              title: 'مذكرات',
              icon: 'mdi-checkbox-marked-circle text-success',
            },
            {
              btitle: '',
              title: 'محاسبة',
              icon: 'mdi-checkbox-marked-circle text-success',
            },
            {
              btitle: '',
              title: 'الجلسات',
              icon: 'mdi-checkbox-marked-circle text-success',
            },
            {
              btitle: '',
              title: 'القضايا',
              icon: 'mdi-checkbox-marked-circle text-success',
            },
            {
              btitle: '',
              title: 'العقود',
              icon: 'mdi-checkbox-marked-circle text-success',
            },
            {
              btitle: '',
              title: 'تبادل التقارير',
              icon: 'mdi-checkbox-marked-circle text-success',
            },
            {
              btitle: '',
              title: 'ملفات الحرفاء',
              icon: 'mdi-checkbox-marked-circle text-success',
            },
            {
              btitle: '',
              title: 'المحادثات',
              icon: 'mdi-checkbox-marked-circle text-success',
            },
            {
              btitle: '',
              title: 'المهام',
              icon: 'mdi-checkbox-marked-circle text-success',
            },
            {
              btitle: '',
              title: 'إدارة المستخدمين',
              icon: 'mdi-checkbox-marked-circle text-success',
            },
            {
              btitle: '',
              title: 'تطبيق الهاتف الجوال للمحامي',
              icon: 'mdi-checkbox-marked-circle text-success',
            },
            {
              btitle: '',
              title: 'تطبيق الهاتف الجوال للحريف',
              icon: 'mdi-checkbox-marked-circle text-success',
            },
          ],
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section bg-light bg-features">
          <Container>
            <Row className="align-items-center">
              <Col lg={5}>
                <div className="mt-4 home-img">
                  <div className="animation-2"></div>
                  <div className="animation-3"></div>
                  <img src={Img1} className="img-fluid" alt="" />
                </div>
              </Col>
              <Col
                style={{ paddingLeft: 0, paddingRight: '80px' }}
                lg={6}
                className="offset-lg-1"
              >
                <div className="mt-4">
                  <h2>لافوكاتو</h2>
                  <p style={{ fontSize: '13px' }} className="mt-4 text-muted">
                    "لافوكاتو" هو موقع واب و تطبيقة على الهواتف المحمولة (المحامي و
                    الحريف)، تساهم في رقمنة العمل للسادة المحامين. بها عدة محاور خاصة
                    بتنظيم الجلسات و ملفات الحرفاء و المواعيد و الدعاوي و الطعون و تبادل
                    التقارير و المحاسبة و مساحة خاصة بالرسائل مع الزملاء.
                    <br /> الغاية من هذه الفكرة هي تنظيم اجندة العمل و تلقي إشعارات عند
                    وصول تقرير و قبل مدة زمنية محددة من انطلاق الجلسة او الموعد .
                  </p>

                  <div className="mt-4">
                    <Link
                      style={{ color: 'white' }}
                      to="/register"
                      className="btn btn-primary btn-rounded"
                    >
                      اشتراك
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="section" id="pricing">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="title-box text-center">
                  <h3 className="title-heading mt-4">العروض </h3>
                  <p className="text-muted f-17 mt-3">
                    تجربة مجانية لمدة 30 يوم.
                    <br /> اختر العرض الذي يناسب احتياجاتك.
                  </p>
                  <img src={HomeUrl} height="15" className="mt-3" alt="" />
                </div>
              </Col>
            </Row>
            <Row className="mt-5 pt-4">
              {/* Render Pricing items */}
              {this.state.pricing.map((item, key) => (
                <Col lg="4" key={key}>
                  <div className="pricing-box mt-4">
                    {item.pricingTag && (
                      <div className="pricing-badge">
                        <span className="badge">عرض خاص</span>{' '}
                      </div>
                    )}

                    <i className={'h1 mdi ' + item.icon}></i>
                    {item.titlePrimary === true ? (
                      <h4 className="f-20 text-primary">{item.title}</h4>
                    ) : (
                      <h4 className="f-20">{item.title}</h4>
                    )}

                    <div className="mt-4 pt-2">
                      <p className="mb-2 f-18">الوظائف</p>
                      {item.child.map((linkItem, linkkey) => (
                        <p style={{ fontSize: '13px' }} className="mb-2" key={linkkey}>
                          <i className={'mdi ' + linkItem.icon + ' f-18 mr-2'}></i>
                          <b>{linkItem.btitle}</b> {linkItem.title}
                        </p>
                      ))}
                    </div>
                    {/*
                    <p className="mt-4 pt-2 text-muted">{item.description}</p>*/}
                    <div className="pricing-plan mt-4 pt-2">
                      <h4 className="text-muted">
                        <span className="plan pl-3 text-dark">{item.saleprice}</span>
                      </h4>
                      <p className="text-muted mb-0"> {item.regularprice}</p>
                      {/*
                      <p className="text-muted mb-0"> +19% TVA</p>
*/}
                    </div>
                    <div className="mt-4 pt-3">
                      <Link
                        style={{ color: 'white' }}
                        to="/register"
                        className="btn btn-primary btn-rounded"
                      >
                        اشتراك
                      </Link>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
