import React, { Component } from 'react';
import Message from './message';
import ScrollToBottom from 'react-scroll-to-bottom';

class Messages extends Component {
  constructor(props) {
    super(props);
    this.boxRef = React.createRef();
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  scrollToBottom = () => {
    this.boxRef.current.scrollTop = this.boxRef.current.scrollHeight;
  };

  componentDidUpdate = () => {
    this.scrollToBottom();
  };

  render() {
    const { messages, status, img, account, name, conversation_id } = this.props;
    return (
      <div ref={this.boxRef} className="msng_details_section__body">
        {messages && messages.length > 0
          ? messages.map((message, i) => (
              <div style={{ display: 'flex' }} key={i}>
                <Message
                  img={img}
                  getAcceptedRejectedMission={this.props.getAcceptedRejectedMission}
                  getConversation={this.props.getConversation}
                  destroyMessage={this.props.destroyMessage}
                  account={account}
                  message={message}
                  conversation_id={conversation_id}
                  name={name}
                  status={status}
                />
              </div>
            ))
          : ''}
      </div>
    );
  }
}
export default Messages;
