import { apiCall } from '../../../js/actions';
import { getStatusError } from '../../../utils/helpres';
import { toast } from 'react-toastify';
import ToastAction from '../../../components/ToastAction';
import React from 'react';

const getAllLawyers = (params) => async (dispatch) => {
  dispatch({ type: 'GET_LAWYERS_REQUESTING' });
  try {
    const { currentPage } = params || {
      currentPage: 1,
    };
    const { limit } = params || {
      limit: '',
    };
    const { search } = params || {
      search: '',
    };
    let url = `/api/lawyer/all?limit=${limit}&current_page=${currentPage}`;
    if (search && search !== '') url += `&search=${search}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_LAWYERS_SUCCESS',
      data: data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_LAWYERS_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};

const getConversationAccount = (params) => async (dispatch) => {
  dispatch({ type: 'GET_CONSULTATIONS_ACCOUNT_REQUESTING' });
  try {
    const { search } = params || {
      search: '',
    };
    let url = `/api/conversation`;

    if (search && search !== '') url += `?search=${search}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_CONSULTATIONS_ACCOUNT_SUCCESS',
      data: data.data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_CONSULTATIONS_ACCOUNT_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};
const countMessagesIsSeen = () => async (dispatch) => {
  dispatch({ type: 'GET_COUNT_MESSAGE_IS_SEEN_REQUESTING' });
  try {
    const url = `/api/message/count/is_seen`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_COUNT_MESSAGE_IS_SEEN_SUCCESS', data: data });
  } catch (err) {
    dispatch({
      type: 'GET_COUNT_MESSAGE_IS_SEEN_FAILURE',
      err: err.message,
      status: getStatusError(err),
    });
  }
};
const countMessagesIsSeenTo = (To) => async (dispatch) => {
  dispatch({ type: 'GET_COUNT_MESSAGE_IS_SEEN_REQUESTING' });
  try {
    const url = `/api/message/count/is_seen/to/${To}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_COUNT_MESSAGE_IS_SEEN_SUCCESS', data: data });
  } catch (err) {
    dispatch({
      type: 'GET_COUNT_MESSAGE_IS_SEEN_FAILURE',
      err: err.message,
      status: getStatusError(err),
    });
  }
};
const countMessagesIsSeenByConversation = (id) => async (dispatch) => {
  dispatch({ type: 'GET_COUNT_MESSAGE_IS_SEEN_BY_CONSULTATION_REQUESTING' });
  try {
    const url = `/api/message/is_seen/${id}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_COUNT_MESSAGE_IS_SEEN_SUCCESS', data: data });
  } catch (err) {
    dispatch({
      type: 'GET_COUNT_MESSAGE_IS_SEEN_FAILURE',
      err: err.message,
      status: getStatusError(err),
    });
  }
};
const submitConversationForm = (values) => async (dispatch) => {
  try {
    const url = `/api/conversation/create`;
    const { data } = await apiCall({
      method: 'post',
      url,
      data: values,
    });
    dispatch({ type: 'CREATE_NEW_CONVERSATION_SUCCESS', data: data });
  } catch (err) {
    dispatch({
      type: 'CREATE_NEW_CONVERSATION_FAILURE',
      err: err.message,
      status: getStatusError(err),
    });
  }
};
const submitMessageForm = (values) => async (dispatch) => {
  try {
    const url = `/api/message/create`;
    const { data } = await apiCall({
      method: 'post',
      url,
      data: {
        content: values.content,
        conversation_id: values.conversation_id,
        to: values.to,
      },
    });
    dispatch({ type: 'CREATE_NEW_MESSAGE_SUCCESS', data: data });
  } catch (err) {
    dispatch({
      type: 'CREATE_NEW_MESSAGE_FAILURE',
      err: err.message,
      status: getStatusError(err),
    });
  }
};
const getMessages = (id) => async (dispatch) => {
  dispatch({ type: 'GET_MESSAGE_REQUESTING' });
  try {
    let url = `/api/message/${id}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_MESSAGE_SUCCESS', data: data });
  } catch (err) {
    dispatch({
      type: 'GET_MESSAGE_FAILURE',
      err: err.message,
      status: getStatusError(err),
    });
  }
};
const messagesIsSeen = (id) => async (dispatch) => {
  dispatch({ type: 'GET_MESSAGE_IS_SEEN_REQUESTING' });
  try {
    const url = `/api/messages/isSeen/${id}`;
    const { data } = await apiCall({
      method: 'post',
      url,
    });
    dispatch({ type: 'GET_MESSAGE_IS_SEEN_SUCCESS', data: data });
  } catch (err) {
    dispatch({
      type: 'GET_MESSAGE_IS_SEEN_FAILURE',
      err: err.message,
      status: getStatusError(err),
    });
  }
};

const deleteConversation = (id) => async (dispatch) => {
  dispatch({ type: 'GET_DELETE_CONVERSATION_REQUESTING' });
  try {
    const url = `/api/conversation/${id}`;
    const { data } = await apiCall({
      method: 'delete',
      url,
    });
    if (data.type === 'success')
      toast(({ closeToast }) => <ToastAction type="success" message={data.message} />, {
        hideProgressBar: true,
      });
    else
      toast(({ closeToast }) => <ToastAction type="error" message={data.message} />, {
        hideProgressBar: true,
      });
    dispatch({ type: 'GET_DELETE_CONVERSATION_SUCCESS', data });
  } catch (err) {
    const { response } = err;
    toast(
      ({ closeToast }) => (
        <ToastAction type="error" message="Echec de suppression de la conversation" />
      ),
      {
        hideProgressBar: true,
      },
    );
    dispatch({
      type: 'GET_DELETE_CONVERSATION_FAILURE',
      err: err.message,
      status: getStatusError(err),
    });
  }
};

const changeSearchedStringLawyers = (params) => ({
  type: 'UPDATE_SEARCH_LAWYERS_PARAMS',
  params: params,
});
const changeParamsMessage = (params) => ({
  type: 'UPDATE_PARAMS_MESSAGES',
  paramsMessages: params,
});
const initialState = () => ({
  type: 'INITIAL_STATE',
});
export default {
  changeParamsMessage,
  initialState,
  changeSearchedStringLawyers,
  getAllLawyers,
  getConversationAccount,
  countMessagesIsSeen,
  countMessagesIsSeenByConversation,
  getMessages,
  messagesIsSeen,
  submitMessageForm,
  submitConversationForm,
  deleteConversation,
  countMessagesIsSeenTo,
};
