import React, { Component } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ar from 'date-fns/locale/ar-DZ';
import moment from 'moment'; // the locale you want
registerLocale('ar', ar);

class Calendar extends Component {
  render() {
    const { params, allActifRDV, filter } = this.props;
    return (
      <div className="calendar">
        <DatePicker
          dateFormat="YYYY-MM-DD"
          locale="ar"
          selected={params ? new Date(params.date) : new Date()}
          highlightDates={
            allActifRDV && allActifRDV.length > 0
              ? allActifRDV.map((rdv) => new Date(rdv))
              : []
          }
          onChange={(date) => {
            if (params)
              this.props.changeDate({
                ...params,
                date: moment(date).format('YYYY-MM-DD'),
              });
            if (filter)
              this.props.changeSearchedStringNotification({
                date: moment(date).format('YYYY-MM-DD'),
                currentPage: 1,
                limit: 5,
              });
          }}
          inline
        />
      </div>
    );
  }
}
export default Calendar;
