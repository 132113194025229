import React, { Component } from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import actions from '../store/actions';
import { connect } from 'react-redux';
import { DaysArabic } from '../../../utils/constants';
import moment from 'moment';
import DatePicker from 'react-datepicker';

const mapStateToProps = (state, ownProps) => {
  const { unavailability } = ownProps;
  let date_from;
  let date_to;
  let note;
  let id;
  if (unavailability) {
    date_from =
      unavailability && unavailability.date_from
        ? new Date(unavailability.date_from)
        : new Date();
    date_to =
      unavailability && unavailability.date_to
        ? new Date(unavailability.date_to)
        : new Date();
    id = unavailability && unavailability.id ? unavailability.id : null;
    note = unavailability && unavailability.note ? unavailability.note : null;
  }
  return {
    updateRdv: state.AgendaReducer.updateRdv,
    isLoadingAddRdv: state.AgendaReducer.isLoadingAddRdv,
    account: state.AppReducer.account,
    initialValues: {
      id: id || '',
      startDate: date_to || new Date(),
      endDate: date_from || new Date(),
      startHours: date_from || new Date(),
      endHours: date_from || new Date(),
      note: note || '',
    },
  };
};
const mapDispatchToProps = (dispatch) => ({
  submitUnavailabilityForm: async (values, params) => {
    await dispatch(actions.submitUnavailabilityForm(values));
    dispatch(actions.getAllUnavailability(params));
  },
});

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        ...props.initialValues,
        time: moment().format('HH:mm'),
      },
      hasError: false,
      hasErrorDate: false,
      errorDate: false,
      errorHours: false,
      anchor: false,
      left: false,
    };
  }

  submitForm(initialValues, params) {
    if (
      moment(moment(initialValues.startDate).format('YYYY-MM-DD')).isAfter(
        moment(initialValues.endDate).format('YYYY-MM-DD'),
      )
    ) {
      this.setState({
        errorHours: false,
        errorDate: true,
      });
    } else if (
      moment(moment(initialValues.startDate).format('YYYY-MM-DD')).isSame(
        moment(initialValues.endDate).format('YYYY-MM-DD'),
      ) &&
      moment(initialValues.startHours, 'h:mma').isSameOrAfter(
        moment(initialValues.endHours, 'h:mma'),
      )
    ) {
      this.setState({
        errorHours: true,
        errorDate: false,
      });
    } else if (
      !initialValues.startDate ||
      !initialValues.startHours ||
      !initialValues.endDate ||
      !initialValues.endHours
    ) {
      this.setState({
        ...initialValues,
        hasError: true,
      });
    } else {
      this.setState({
        errorHours: false,
        errorDate: false,
      });
      this.props.submitUnavailabilityForm(initialValues, params);
    }
  }
  toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    this.setState({
      left: open,
      anchor: 'left',
      hasError: false,
    });
  };
  render() {
    const { initialValues, hasError, errorDate, errorHours } = this.state;
    const { left, anchor, params } = this.props;
    return (
      <SwipeableDrawer
        style={{ width: '40rem' }}
        anchor="left"
        open={left}
        onClose={this.props.toggleDrawer(anchor, false)}
        onOpen={this.props.toggleDrawer(anchor, true)}
      >
        <div
          style={{
            position: 'initial',
            width: '70rem',
            display: 'flex',
          }}
          className="rv_newDate"
        >
          <form action="">
            <div className="rv_form__sections">
              <div className="rv_form__sections-left">
                <div className="rv_form__sections-left_field">
                  <div className="rv_form__label" htmlFor="date_selected">
                    التاريخ
                  </div>
                  <div className="rv_form__cal">
                    <DatePicker
                      selected={initialValues.startDate}
                      locale="ar"
                      onChange={(date) => {
                        this.setState({
                          initialValues: {
                            ...initialValues,
                            startDate: date,
                          },
                        });
                      }}
                      customInput={
                        <svg
                          style={{
                            cursor: 'pointer',
                          }}
                          id="calendar"
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                        >
                          <g id="Group_28" data-name="Group 28">
                            <g id="Group_27" data-name="Group 27">
                              <ellipse
                                id="Ellipse_20"
                                data-name="Ellipse 20"
                                cx="1"
                                cy="1.5"
                                rx="1"
                                ry="1.5"
                                transform="translate(22 11)"
                                fill="#ca2946"
                              />
                              <path
                                id="Path_89"
                                data-name="Path 89"
                                d="M25.312,2.344H23.789V1.172a1.172,1.172,0,0,0-2.344,0V2.344H16.113V1.172a1.172,1.172,0,0,0-2.344,0V2.344H8.5V1.172a1.172,1.172,0,1,0-2.344,0V2.344H4.688A4.693,4.693,0,0,0,0,7.031V25.312A4.693,4.693,0,0,0,4.688,30h8.965a1.172,1.172,0,0,0,0-2.344H4.688a2.346,2.346,0,0,1-2.344-2.344V7.031A2.346,2.346,0,0,1,4.688,4.688H6.152V5.859a1.172,1.172,0,0,0,2.344,0V4.688H13.77V5.859a1.172,1.172,0,0,0,2.344,0V4.688h5.332V5.859a1.172,1.172,0,0,0,2.344,0V4.688h1.523a2.346,2.346,0,0,1,2.344,2.344v6.68a1.172,1.172,0,0,0,2.344,0V7.031A4.693,4.693,0,0,0,25.312,2.344Z"
                                fill="#ca2946"
                              />
                              <path
                                id="Path_90"
                                data-name="Path 90"
                                d="M277.09,270a7.09,7.09,0,1,0,7.09,7.09A7.1,7.1,0,0,0,277.09,270Zm0,11.836a4.746,4.746,0,1,1,4.746-4.746A4.751,4.751,0,0,1,277.09,281.836Z"
                                transform="translate(-254.18 -254.18)"
                                fill="#ca2946"
                              />
                              <path
                                id="Path_91"
                                data-name="Path 91"
                                d="M373.871,332.4h-.527v-1.23a1.172,1.172,0,0,0-2.344,0v2.4a1.172,1.172,0,0,0,1.172,1.172h1.7a1.172,1.172,0,0,0,0-2.344Z"
                                transform="translate(-349.262 -310.664)"
                                fill="#ca2946"
                              />
                              <circle
                                id="Ellipse_21"
                                data-name="Ellipse 21"
                                cx="1.5"
                                cy="1.5"
                                r="1.5"
                                transform="translate(16 11)"
                                fill="#ca2946"
                              />
                              <circle
                                id="Ellipse_22"
                                data-name="Ellipse 22"
                                cx="1.5"
                                cy="1.5"
                                r="1.5"
                                transform="translate(11 16)"
                                fill="#ca2946"
                              />
                              <ellipse
                                id="Ellipse_23"
                                data-name="Ellipse 23"
                                cx="1"
                                cy="1.5"
                                rx="1"
                                ry="1.5"
                                transform="translate(6 11)"
                                fill="#ca2946"
                              />
                              <ellipse
                                id="Ellipse_24"
                                data-name="Ellipse 24"
                                cx="1"
                                cy="1.5"
                                rx="1"
                                ry="1.5"
                                transform="translate(6 16)"
                                fill="#ca2946"
                              />
                              <circle
                                id="Ellipse_25"
                                data-name="Ellipse 25"
                                cx="1"
                                cy="1"
                                r="1"
                                transform="translate(6 22)"
                                fill="#ca2946"
                              />
                              <ellipse
                                id="Ellipse_26"
                                data-name="Ellipse 26"
                                cx="1.5"
                                cy="1"
                                rx="1.5"
                                ry="1"
                                transform="translate(11 22)"
                                fill="#ca2946"
                              />
                              <circle
                                id="Ellipse_27"
                                data-name="Ellipse 27"
                                cx="1.5"
                                cy="1.5"
                                r="1.5"
                                transform="translate(11 11)"
                                fill="#ca2946"
                              />
                            </g>
                          </g>
                        </svg>
                      }
                    />
                  </div>
                  <div className="rv_form__date_selected">
                    <div className="rv_form__date_selected__day">
                      {DaysArabic[moment(initialValues.startDate).format('dddd')]}
                    </div>
                    <div className="rv_form__date_selected__day">
                      {moment(initialValues.startDate).format('DD/MM/YYYY')}
                    </div>
                  </div>
                </div>

                <div className="rv_form__sections-left_field">
                  <div className="rv_form__label" htmlFor="date_selected">
                    التوقيت
                  </div>
                  <div className="rv_form__t">
                    <DatePicker
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      timeFormat="HH:mm"
                      minTime={new Date(initialValues.startHours).setHours(8, 0, 0, 0)}
                      maxTime={new Date(initialValues.startHours).setHours(18, 0, 0, 0)}
                      selected={initialValues.startHours}
                      locale="ar"
                      onChange={(date) => {
                        this.setState({
                          initialValues: {
                            ...initialValues,
                            startHours: date,
                          },
                        });
                      }}
                      customInput={
                        <svg
                          className="rv_time_icon"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24.538"
                          height="24.538"
                          viewBox="0 0 24.538 24.538"
                        >
                          <g
                            id="clock_1_"
                            data-name="clock(1)"
                            transform="translate(0.5 0.5)"
                          >
                            <path
                              id="Path_103"
                              data-name="Path 103"
                              d="M11.769,0A11.769,11.769,0,1,0,23.538,11.769,11.782,11.782,0,0,0,11.769,0Zm0,22.067a10.3,10.3,0,1,1,10.3-10.3,10.309,10.309,0,0,1-10.3,10.3Z"
                              fill="#a8192b"
                              stroke="#a8192b"
                              strokeWidth="1"
                            />
                            <path
                              id="Path_104"
                              data-name="Path 104"
                              d="M209.265,83.118h-1.471v7.66l4.629,4.629,1.04-1.04-4.2-4.2Z"
                              transform="translate(-196.761 -78.705)"
                              fill="#a8192b"
                              stroke="#a8192b"
                              strokeWidth="1"
                            />
                          </g>
                        </svg>
                      }
                    />
                  </div>
                  <div className="rv_form__time_selected">
                    {moment(initialValues.startHours).format('HH:mm')}h
                  </div>
                </div>
                {errorDate ? (
                  <div className="rv_form__sections-left_field">
                    <div>
                      <svg
                        className="rv_danger_icon"
                        xmlns="http://www.w3.org/2000/svg"
                        width="27.077"
                        height="24.294"
                        viewBox="0 0 27.077 24.294"
                      >
                        <g id="alert" transform="translate(0 -26.315)">
                          <g
                            id="Group_39"
                            data-name="Group 39"
                            transform="translate(0 26.315)"
                          >
                            <g
                              id="Group_38"
                              data-name="Group 38"
                              transform="translate(0 0)"
                            >
                              <path
                                id="Path_105"
                                data-name="Path 105"
                                d="M26.645,45.833,16.3,27.907a3.184,3.184,0,0,0-5.515,0L.431,45.833a3.184,3.184,0,0,0,2.757,4.776h20.7a3.184,3.184,0,0,0,2.757-4.776Zm-1.374,2.391a1.581,1.581,0,0,1-1.384.8H3.189a1.6,1.6,0,0,1-1.384-2.4L12.155,28.7a1.6,1.6,0,0,1,2.767,0l10.35,17.926A1.582,1.582,0,0,1,25.272,48.224Z"
                                transform="translate(0 -26.315)"
                                fill="#ff8000"
                              />
                            </g>
                          </g>
                          <g
                            id="Group_41"
                            data-name="Group 41"
                            transform="translate(12.745 34.232)"
                          >
                            <g
                              id="Group_40"
                              data-name="Group 40"
                              transform="translate(0 0)"
                            >
                              <rect
                                id="Rectangle_145"
                                data-name="Rectangle 145"
                                width="1.586"
                                height="7.932"
                                fill="#ff8000"
                              />
                            </g>
                          </g>
                          <g
                            id="Group_43"
                            data-name="Group 43"
                            transform="translate(12.481 43.75)"
                          >
                            <g id="Group_42" data-name="Group 42">
                              <path
                                id="Path_106"
                                data-name="Path 106"
                                d="M237.06,355.99a1.058,1.058,0,1,0,1.058,1.058A1.059,1.059,0,0,0,237.06,355.99Z"
                                transform="translate(-236.002 -355.99)"
                                fill="#ff8000"
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div className="rv_err_msg">الرجاء تحديد تاريخ في المستقبل</div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className="rv_form__sections-left">
                <div className="rv_form__sections-left_field">
                  <div className="rv_form__label" htmlFor="date_selected">
                    التاريخ
                  </div>
                  <div className="rv_form__cal">
                    <DatePicker
                      selected={initialValues.endDate}
                      locale="ar"
                      onChange={(date) => {
                        this.setState({
                          initialValues: {
                            ...initialValues,
                            endDate: date,
                          },
                        });
                      }}
                      customInput={
                        <svg
                          style={{
                            cursor: 'pointer',
                          }}
                          id="calendar"
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                        >
                          <g id="Group_28" data-name="Group 28">
                            <g id="Group_27" data-name="Group 27">
                              <ellipse
                                id="Ellipse_20"
                                data-name="Ellipse 20"
                                cx="1"
                                cy="1.5"
                                rx="1"
                                ry="1.5"
                                transform="translate(22 11)"
                                fill="#ca2946"
                              />
                              <path
                                id="Path_89"
                                data-name="Path 89"
                                d="M25.312,2.344H23.789V1.172a1.172,1.172,0,0,0-2.344,0V2.344H16.113V1.172a1.172,1.172,0,0,0-2.344,0V2.344H8.5V1.172a1.172,1.172,0,1,0-2.344,0V2.344H4.688A4.693,4.693,0,0,0,0,7.031V25.312A4.693,4.693,0,0,0,4.688,30h8.965a1.172,1.172,0,0,0,0-2.344H4.688a2.346,2.346,0,0,1-2.344-2.344V7.031A2.346,2.346,0,0,1,4.688,4.688H6.152V5.859a1.172,1.172,0,0,0,2.344,0V4.688H13.77V5.859a1.172,1.172,0,0,0,2.344,0V4.688h5.332V5.859a1.172,1.172,0,0,0,2.344,0V4.688h1.523a2.346,2.346,0,0,1,2.344,2.344v6.68a1.172,1.172,0,0,0,2.344,0V7.031A4.693,4.693,0,0,0,25.312,2.344Z"
                                fill="#ca2946"
                              />
                              <path
                                id="Path_90"
                                data-name="Path 90"
                                d="M277.09,270a7.09,7.09,0,1,0,7.09,7.09A7.1,7.1,0,0,0,277.09,270Zm0,11.836a4.746,4.746,0,1,1,4.746-4.746A4.751,4.751,0,0,1,277.09,281.836Z"
                                transform="translate(-254.18 -254.18)"
                                fill="#ca2946"
                              />
                              <path
                                id="Path_91"
                                data-name="Path 91"
                                d="M373.871,332.4h-.527v-1.23a1.172,1.172,0,0,0-2.344,0v2.4a1.172,1.172,0,0,0,1.172,1.172h1.7a1.172,1.172,0,0,0,0-2.344Z"
                                transform="translate(-349.262 -310.664)"
                                fill="#ca2946"
                              />
                              <circle
                                id="Ellipse_21"
                                data-name="Ellipse 21"
                                cx="1.5"
                                cy="1.5"
                                r="1.5"
                                transform="translate(16 11)"
                                fill="#ca2946"
                              />
                              <circle
                                id="Ellipse_22"
                                data-name="Ellipse 22"
                                cx="1.5"
                                cy="1.5"
                                r="1.5"
                                transform="translate(11 16)"
                                fill="#ca2946"
                              />
                              <ellipse
                                id="Ellipse_23"
                                data-name="Ellipse 23"
                                cx="1"
                                cy="1.5"
                                rx="1"
                                ry="1.5"
                                transform="translate(6 11)"
                                fill="#ca2946"
                              />
                              <ellipse
                                id="Ellipse_24"
                                data-name="Ellipse 24"
                                cx="1"
                                cy="1.5"
                                rx="1"
                                ry="1.5"
                                transform="translate(6 16)"
                                fill="#ca2946"
                              />
                              <circle
                                id="Ellipse_25"
                                data-name="Ellipse 25"
                                cx="1"
                                cy="1"
                                r="1"
                                transform="translate(6 22)"
                                fill="#ca2946"
                              />
                              <ellipse
                                id="Ellipse_26"
                                data-name="Ellipse 26"
                                cx="1.5"
                                cy="1"
                                rx="1.5"
                                ry="1"
                                transform="translate(11 22)"
                                fill="#ca2946"
                              />
                              <circle
                                id="Ellipse_27"
                                data-name="Ellipse 27"
                                cx="1.5"
                                cy="1.5"
                                r="1.5"
                                transform="translate(11 11)"
                                fill="#ca2946"
                              />
                            </g>
                          </g>
                        </svg>
                      }
                    />
                  </div>
                  <div className="rv_form__date_selected">
                    <div className="rv_form__date_selected__day">
                      {DaysArabic[moment(initialValues.endDate).format('dddd')]}
                    </div>
                    <div className="rv_form__date_selected__day">
                      {moment(initialValues.endDate).format('DD/MM/YYYY')}
                    </div>
                  </div>
                </div>

                <div className="rv_form__sections-left_field">
                  <div className="rv_form__label" htmlFor="date_selected">
                    التوقيت
                  </div>
                  <div className="rv_form__t">
                    <DatePicker
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      timeFormat="HH:mm"
                      minTime={new Date(initialValues.endHours).setHours(8, 0, 0, 0)}
                      maxTime={new Date(initialValues.endHours).setHours(18, 0, 0, 0)}
                      selected={initialValues.endHours}
                      locale="ar"
                      onChange={(date) => {
                        this.setState({
                          initialValues: {
                            ...initialValues,
                            endHours: date,
                          },
                        });
                      }}
                      customInput={
                        <svg
                          className="rv_time_icon"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24.538"
                          height="24.538"
                          viewBox="0 0 24.538 24.538"
                        >
                          <g
                            id="clock_1_"
                            data-name="clock(1)"
                            transform="translate(0.5 0.5)"
                          >
                            <path
                              id="Path_103"
                              data-name="Path 103"
                              d="M11.769,0A11.769,11.769,0,1,0,23.538,11.769,11.782,11.782,0,0,0,11.769,0Zm0,22.067a10.3,10.3,0,1,1,10.3-10.3,10.309,10.309,0,0,1-10.3,10.3Z"
                              fill="#a8192b"
                              stroke="#a8192b"
                              strokeWidth="1"
                            />
                            <path
                              id="Path_104"
                              data-name="Path 104"
                              d="M209.265,83.118h-1.471v7.66l4.629,4.629,1.04-1.04-4.2-4.2Z"
                              transform="translate(-196.761 -78.705)"
                              fill="#a8192b"
                              stroke="#a8192b"
                              strokeWidth="1"
                            />
                          </g>
                        </svg>
                      }
                    />
                  </div>
                  <div className="rv_form__time_selected">
                    {moment(initialValues.endHours).format('HH:mm')}h
                  </div>
                </div>
                {errorHours ? (
                  <div className="rv_form__sections-left_field">
                    <div>
                      <svg
                        className="rv_danger_icon"
                        xmlns="http://www.w3.org/2000/svg"
                        width="27.077"
                        height="24.294"
                        viewBox="0 0 27.077 24.294"
                      >
                        <g id="alert" transform="translate(0 -26.315)">
                          <g
                            id="Group_39"
                            data-name="Group 39"
                            transform="translate(0 26.315)"
                          >
                            <g
                              id="Group_38"
                              data-name="Group 38"
                              transform="translate(0 0)"
                            >
                              <path
                                id="Path_105"
                                data-name="Path 105"
                                d="M26.645,45.833,16.3,27.907a3.184,3.184,0,0,0-5.515,0L.431,45.833a3.184,3.184,0,0,0,2.757,4.776h20.7a3.184,3.184,0,0,0,2.757-4.776Zm-1.374,2.391a1.581,1.581,0,0,1-1.384.8H3.189a1.6,1.6,0,0,1-1.384-2.4L12.155,28.7a1.6,1.6,0,0,1,2.767,0l10.35,17.926A1.582,1.582,0,0,1,25.272,48.224Z"
                                transform="translate(0 -26.315)"
                                fill="#ff8000"
                              />
                            </g>
                          </g>
                          <g
                            id="Group_41"
                            data-name="Group 41"
                            transform="translate(12.745 34.232)"
                          >
                            <g
                              id="Group_40"
                              data-name="Group 40"
                              transform="translate(0 0)"
                            >
                              <rect
                                id="Rectangle_145"
                                data-name="Rectangle 145"
                                width="1.586"
                                height="7.932"
                                fill="#ff8000"
                              />
                            </g>
                          </g>
                          <g
                            id="Group_43"
                            data-name="Group 43"
                            transform="translate(12.481 43.75)"
                          >
                            <g id="Group_42" data-name="Group 42">
                              <path
                                id="Path_106"
                                data-name="Path 106"
                                d="M237.06,355.99a1.058,1.058,0,1,0,1.058,1.058A1.059,1.059,0,0,0,237.06,355.99Z"
                                transform="translate(-236.002 -355.99)"
                                fill="#ff8000"
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div className="rv_err_msg">هذا التوقيت غير صحيح</div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            <br />
            <div className="appeals_form__sections-right">
              <div>
                <label htmlFor="verdict">ملاحظة</label>
                <textarea
                  onChange={(event) => {
                    this.setState({
                      initialValues: {
                        ...initialValues,
                        note: event.target.value,
                      },
                    });
                  }}
                  value={initialValues.note}
                  //className={hasError && !initialValues.note ? 'input-error' : ''}
                  name="note"
                  id="note"
                >
                  {initialValues.note}
                </textarea>
              </div>
            </div>
            <div className="rv_form__btn_grp">
              <button
                onClick={this.props.toggleDrawer(anchor, false)}
                type="button"
                className="rv_cancel_date greyed_out"
              >
                <svg
                  className="rv_ltSpan"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  xmlnsSvgjs="http://svgjs.com/svgjs"
                  version="1.1"
                  width="12"
                  height="12"
                  x="0"
                  y="0"
                  viewBox="0 0 492.004 492.004"
                  style={{ enableBackground: 'new 0 0 512 512' }}
                  xmlSpace="preserve"
                >
                  <g>
                    <g xmlns="http://www.w3.org/2000/svg">
                      <g>
                        <path
                          d="M382.678,226.804L163.73,7.86C158.666,2.792,151.906,0,144.698,0s-13.968,2.792-19.032,7.86l-16.124,16.12    c-10.492,10.504-10.492,27.576,0,38.064L293.398,245.9l-184.06,184.06c-5.064,5.068-7.86,11.824-7.86,19.028    c0,7.212,2.796,13.968,7.86,19.04l16.124,16.116c5.068,5.068,11.824,7.86,19.032,7.86s13.968-2.792,19.032-7.86L382.678,265    c5.076-5.084,7.864-11.872,7.848-19.088C390.542,238.668,387.754,231.884,382.678,226.804z"
                          fill="#2d2d2d"
                          data-original="#000000"
                          className=""
                        />
                      </g>
                    </g>
                    <g xmlns="http://www.w3.org/2000/svg"></g>
                    <g xmlns="http://www.w3.org/2000/svg"></g>
                    <g xmlns="http://www.w3.org/2000/svg"></g>
                    <g xmlns="http://www.w3.org/2000/svg"></g>
                    <g xmlns="http://www.w3.org/2000/svg"></g>
                    <g xmlns="http://www.w3.org/2000/svg"></g>
                    <g xmlns="http://www.w3.org/2000/svg"></g>
                    <g xmlns="http://www.w3.org/2000/svg"></g>
                    <g xmlns="http://www.w3.org/2000/svg"></g>
                    <g xmlns="http://www.w3.org/2000/svg"></g>
                    <g xmlns="http://www.w3.org/2000/svg"></g>
                    <g xmlns="http://www.w3.org/2000/svg"></g>
                    <g xmlns="http://www.w3.org/2000/svg"></g>
                    <g xmlns="http://www.w3.org/2000/svg"></g>
                    <g xmlns="http://www.w3.org/2000/svg"></g>
                  </g>
                </svg>
                الغاء
              </button>
              <button
                onClick={() => this.submitForm(initialValues, params)}
                type="button"
                className="rv_confirm_date"
              >
                <svg
                  className="tickSpan"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.125"
                  height="17.774"
                  viewBox="0 0 29.125 23.774"
                >
                  <path
                    id="Path_92"
                    data-name="Path 92"
                    d="M1325.354,167.5l8.34,9.625,17.8-19.417"
                    transform="translate(-1323.842 -156.357)"
                    fill="none"
                    stroke="#f9f9f9"
                    strokeWidth="4"
                  />
                </svg>
                تسجيل
              </button>
            </div>
          </form>
        </div>
      </SwipeableDrawer>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form);
