import React, { Component } from 'react';
import actions from '../../js/actions';
import actionsReport from './store/actions';
import { connect } from 'react-redux';
import Table from '../../components/Table';
import { Link } from 'react-router-dom';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { isHasPermission } from '../../utils/helpres';

const mapStateToProps = (state) => ({
  account: state.AppReducer.account,
  reports: state.AppReducer.reports,
  isLoadingReports: state.AppReducer.isLoadingReports,
  paramsReport: state.AppReducer.paramsReport,
  destroyReport: state.ReportsReducer.deleteReport,
});

const mapDispatchToProps = (dispatch) => ({
  getListReports: (paramsReport) => dispatch(actions.getListReports(paramsReport)),
  changeSearchedStringReports: async (paramsReport) => {
    await dispatch(actions.changeSearchedStringReports(paramsReport));
    dispatch(actions.getListReports(paramsReport));
  },
  deleteReport: async (paramsReport, id) => {
    await dispatch(actionsReport.deleteReport(id));
    dispatch(actions.getListReports(paramsReport));
  },
});

class Reports extends Component {
  constructor(props) {
    super(props);
    props.changeActiveItemSideBar(4, 'تبادل التقارير');
    document.title = 'تبادل التقارير';
  }
  componentDidMount() {
    this.props.changeSearchedStringReports({
      search: '',
      date:
        this.props.location.state && this.props.location.state.date
          ? this.props.location.state.date
          : '',
      currentPage: 1,
      limit: 5,
      order: 'DESC',
    });
  }
  render() {
    const { account, reports, destroyReport, isLoadingReports, paramsReport } =
      this.props;
    let isPermissionEdit = isHasPermission(
      account.login_access.permissions,
      'تعديل-تبادل-التقارير',
    );
    let isPermissionDelete = isHasPermission(
      account.login_access.permissions,
      'حذف-تبادل-التقارير',
    );
    let isPermissionAdd = isHasPermission(
      account.login_access.permissions,
      'إضافة-تبادل-التقارير',
    );
    return (
      <div className="reports_main">
        <div className="reports_middle">
          <div className="table_controls">
            {isPermissionAdd ? (
              <Link to="/add-report" className="reports_add_button">
                <svg
                  height="10"
                  viewBox="0 0 448 448"
                  width="10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m408 184h-136c-4.417969 0-8-3.582031-8-8v-136c0-22.089844-17.910156-40-40-40s-40 17.910156-40 40v136c0 4.417969-3.582031 8-8 8h-136c-22.089844 0-40 17.910156-40 40s17.910156 40 40 40h136c4.417969 0 8 3.582031 8 8v136c0 22.089844 17.910156 40 40 40s40-17.910156 40-40v-136c0-4.417969 3.582031-8 8-8h136c22.089844 0 40-17.910156 40-40s-17.910156-40-40-40zm0 0" />
                </svg>
                اضافة تقرير
              </Link>
            ) : (
              ''
            )}

            <div className="sort_grp">
              <div className="sort_grp__txt">ترتيب حسب</div>
              <div className="sort_grp__buttons">
                <svg
                  onClick={() =>
                    this.props.changeSearchedStringReports({
                      ...paramsReport,
                      order: paramsReport.order === 'DESC' ? 'ASC' : 'DESC',
                    })
                  }
                  xmlns="http://www.w3.org/2000/svg"
                  width="23.75"
                  height="28.587"
                  viewBox="0 0 23.75 28.587"
                >
                  <g id="Group_26" data-name="Group 26" transform="translate(-476 -146)">
                    <text
                      id="أ_ي"
                      data-name="أ        ي"
                      transform="translate(476 162)"
                      fill="#8e8989"
                      fontSize="11"
                      fontFamily="beINNormal, beIN Normal"
                    >
                      <tspan x="0" y="0" xmlSpace="preserve">
                        أ ي
                      </tspan>
                    </text>
                    <g id="down-arrow" transform="translate(486.833 147.92)">
                      <g id="Group_24" data-name="Group 24" transform="translate(0)">
                        <path
                          id="Path_88"
                          data-name="Path 88"
                          d="M144.61,19.444a1.042,1.042,0,0,0-1.473,0L139.5,23.1V1.042a1.042,1.042,0,0,0-2.083,0V23.1l-3.637-3.654a1.042,1.042,0,0,0-1.477,1.47l5.417,5.443h0a1.043,1.043,0,0,0,1.475,0h0l5.417-5.443A1.042,1.042,0,0,0,144.61,19.444Z"
                          transform="translate(-132)"
                          fill="#8e8989"
                        />
                      </g>
                    </g>
                  </g>
                </svg>

                <DatePicker
                  selected={new Date()}
                  locale="ar"
                  onChange={(date) =>
                    this.props.changeSearchedStringReports({
                      ...paramsReport,
                      date: moment(date).format('YYYY-MM-DD'),
                    })
                  }
                  customInput={
                    <svg
                      id="calendar"
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                    >
                      <g id="Group_28" data-name="Group 28">
                        <g id="Group_27" data-name="Group 27">
                          <ellipse
                            id="Ellipse_20"
                            data-name="Ellipse 20"
                            cx="1"
                            cy="1.5"
                            rx="1"
                            ry="1.5"
                            transform="translate(22 11)"
                            fill="#ca2946"
                          />
                          <path
                            id="Path_89"
                            data-name="Path 89"
                            d="M25.312,2.344H23.789V1.172a1.172,1.172,0,0,0-2.344,0V2.344H16.113V1.172a1.172,1.172,0,0,0-2.344,0V2.344H8.5V1.172a1.172,1.172,0,1,0-2.344,0V2.344H4.688A4.693,4.693,0,0,0,0,7.031V25.312A4.693,4.693,0,0,0,4.688,30h8.965a1.172,1.172,0,0,0,0-2.344H4.688a2.346,2.346,0,0,1-2.344-2.344V7.031A2.346,2.346,0,0,1,4.688,4.688H6.152V5.859a1.172,1.172,0,0,0,2.344,0V4.688H13.77V5.859a1.172,1.172,0,0,0,2.344,0V4.688h5.332V5.859a1.172,1.172,0,0,0,2.344,0V4.688h1.523a2.346,2.346,0,0,1,2.344,2.344v6.68a1.172,1.172,0,0,0,2.344,0V7.031A4.693,4.693,0,0,0,25.312,2.344Z"
                            fill="#ca2946"
                          />
                          <path
                            id="Path_90"
                            data-name="Path 90"
                            d="M277.09,270a7.09,7.09,0,1,0,7.09,7.09A7.1,7.1,0,0,0,277.09,270Zm0,11.836a4.746,4.746,0,1,1,4.746-4.746A4.751,4.751,0,0,1,277.09,281.836Z"
                            transform="translate(-254.18 -254.18)"
                            fill="#ca2946"
                          />
                          <path
                            id="Path_91"
                            data-name="Path 91"
                            d="M373.871,332.4h-.527v-1.23a1.172,1.172,0,0,0-2.344,0v2.4a1.172,1.172,0,0,0,1.172,1.172h1.7a1.172,1.172,0,0,0,0-2.344Z"
                            transform="translate(-349.262 -310.664)"
                            fill="#ca2946"
                          />
                          <circle
                            id="Ellipse_21"
                            data-name="Ellipse 21"
                            cx="1.5"
                            cy="1.5"
                            r="1.5"
                            transform="translate(16 11)"
                            fill="#ca2946"
                          />
                          <circle
                            id="Ellipse_22"
                            data-name="Ellipse 22"
                            cx="1.5"
                            cy="1.5"
                            r="1.5"
                            transform="translate(11 16)"
                            fill="#ca2946"
                          />
                          <ellipse
                            id="Ellipse_23"
                            data-name="Ellipse 23"
                            cx="1"
                            cy="1.5"
                            rx="1"
                            ry="1.5"
                            transform="translate(6 11)"
                            fill="#ca2946"
                          />
                          <ellipse
                            id="Ellipse_24"
                            data-name="Ellipse 24"
                            cx="1"
                            cy="1.5"
                            rx="1"
                            ry="1.5"
                            transform="translate(6 16)"
                            fill="#ca2946"
                          />
                          <circle
                            id="Ellipse_25"
                            data-name="Ellipse 25"
                            cx="1"
                            cy="1"
                            r="1"
                            transform="translate(6 22)"
                            fill="#ca2946"
                          />
                          <ellipse
                            id="Ellipse_26"
                            data-name="Ellipse 26"
                            cx="1.5"
                            cy="1"
                            rx="1.5"
                            ry="1"
                            transform="translate(11 22)"
                            fill="#ca2946"
                          />
                          <circle
                            id="Ellipse_27"
                            data-name="Ellipse 27"
                            cx="1.5"
                            cy="1.5"
                            r="1.5"
                            transform="translate(11 11)"
                            fill="#ca2946"
                          />
                        </g>
                      </g>
                    </svg>
                  }
                />
              </div>
            </div>
          </div>
          <Table
            class1="reports__judgements_table"
            class2="reports_table"
            withTitle={false}
            colSpan={7}
            dataTable="reports"
            messageDelete="أنت بصدد حذف التقرير"
            action={true}
            account={account}
            isLoading={isLoadingReports}
            params={paramsReport}
            isPermissionEdit={isPermissionEdit}
            isPermissionDelete={isPermissionDelete}
            isPermissionAdd={isPermissionAdd}
            changeSearchedString={this.props.changeSearchedStringReports}
            data={reports}
            // editPath="edit-report"
            deletePath={this.props.deleteReport}
            destroy={destroyReport}
            destroyYes={true}
            headerItems={['التقرير', 'الاستاذ', 'الآجال', 'تاريخ', 'الإعدادت']}
          />
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Reports);
