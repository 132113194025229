import React, { Component } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import ar from 'date-fns/locale/ar-DZ';
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import { courts, DaysArabic, host } from '../../../utils/constants';
import { connect } from 'react-redux';
import moment from 'moment';
import actions from '../store/actions';
import InputField from '../../../components/inputField';
import {
  firstNumberCin,
  generateBillNumber,
  isNumeric,
  lengthPhone,
} from '../../../utils/helpres';
import WordEditor from '../components/quill';

registerLocale('ar', ar);

const mapStateToProps = (state, ownProps) => {
  const { contract, lastBill } = ownProps;
  let date;
  let name;
  let type;
  let responsible_id;
  let second_party_name;
  let second_party_cin;
  let second_party_post;
  let second_party_address;
  let second_party_nationality;
  let product_type;
  let product_year;
  let product_engine;
  let product_engine_number;
  let type_contract_id;
  let type_contract_name;
  let customer_id;
  let customer_name;
  let file;
  let id;
  let percent;
  let file_name;
  let product_creator;
  let fees;
  let price;
  let text;
  if (contract) {
    date = contract && contract.date ? contract.date : new Date();
    file_name = contract && contract.file_name ? contract.file_name : null;
    product_creator =
      contract && contract.product_creator ? contract.product_creator : null;
    fees = contract && contract.fees ? contract.fees : null;
    file = contract && contract.file ? contract.file : null;
    product_engine_number =
      contract && contract.product_engine_number ? contract.product_engine_number : null;
    product_engine = contract && contract.product_engine ? contract.product_engine : null;
    product_year = contract && contract.product_year ? contract.product_year : null;
    id = contract && contract.id ? contract.id : null;
    product_type = contract && contract.product_type ? contract.product_type : '';
    second_party_nationality =
      contract && contract.second_party_nationality
        ? contract.second_party_nationality
        : '';
    second_party_address =
      contract && contract.second_party_address ? contract.second_party_address : '';
    second_party_post =
      contract && contract.second_party_post ? contract.second_party_post : '';
    second_party_cin =
      contract && contract.second_party_cin ? contract.second_party_cin : '';
    second_party_name =
      contract && contract.second_party_name ? contract.second_party_name : '';
    type = contract && contract.type ? contract.type : '';
    text = contract && contract.text ? contract.text : '';
    name = contract && contract.name ? contract.name : '';
    price = contract && contract.price ? contract.price : '';
    type_contract_id =
      contract && contract.type_contract ? contract.type_contract.id : '';
    type_contract_name =
      contract && contract.type_contract ? contract.type_contract.name : '';
    customer_id = contract && contract.customer ? contract.customer.id : '';
    responsible_id = contract && contract.responsible_id ? contract.responsible_id : '';
    customer_name = contract && contract.customer ? contract.customer.name : '';
  }
  return {
    addContract: state.ContractReducer.addContract,
    isLoadingAddContract: state.ContractReducer.isLoadingAddContract,
    account: state.AppReducer.account,
    initialValues: {
      name: name || '',
      second_party_name: second_party_name || '',
      second_party_cin: second_party_cin || '',
      id: id || '',
      responsible_id: responsible_id || null,
      date: date || new Date(),
      second_party_post: second_party_post || '',
      customer_id: customer_id || '',
      second_party_address: second_party_address || '',
      second_party_nationality: second_party_nationality || '',
      product_type: product_type || '',
      product_year: product_year || new Date(),
      type: type || '',
      product_engine: product_engine || '',
      product_engine_number: product_engine_number || '',
      file: file || '',
      customer_name: customer_name || '',
      type_contract_name: type_contract_name || '',
      type_contract_id: type_contract_id || '',
      fees: fees || 0,
      text: text || '',
      percent: percent || 0,
      file_name: file_name || '',
      product_creator: product_creator || '',
      price: price || 0,
      number:
        lastBill && lastBill.length > 0
          ? `${generateBillNumber(
              parseInt(lastBill[0].number.split('/')[0]),
              moment().format('YYYY'),
            )}`
          : `${generateBillNumber(0, moment().format('YYYY'))}`,
    },
  };
};
const mapDispatchToProps = (dispatch) => ({
  submitContractForm: (values) => dispatch(actions.submitContractForm(values)),
});
//deleteFiles
class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_id: null,
      openDeletePopup: false,
      hasError: false,
      hasErrorDate: false,
      initialValues: {
        ...props.initialValues,
      },
    };
    this.submitForm = this.submitForm.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.increase = this.increase.bind(this);
    this.handleActivePopupDeleteItem = this.handleActivePopupDeleteItem.bind(this);
  }
  handleActivePopupDeleteItem(id) {
    this.setState({
      selected_id: id,
      openDeletePopup: !this.state.openDeletePopup,
    });
  }
  deleteFile() {
    const { initialValues } = this.state;
    this.setState((prevState) => {
      this.setState({
        initialValues: {
          ...initialValues,
          file: null,
        },
      });
    });
  }
  increase() {
    const { initialValues } = this.state;
    const newPercent = initialValues.percent + 1;
    if (newPercent >= 100) {
      clearTimeout(this.tm);
      return;
    }
    this.setState({
      initialValues: {
        ...initialValues,
        percent: newPercent,
      },
    });
    this.tm = setTimeout(this.increase, 20);
  }
  onConfirm() {
    const { initialValues } = this.state;
    this.setState({
      initialValues: {
        ...initialValues,
        percent: -1,
      },
    });
  }
  submitForm(initialValues) {
    console.log(initialValues);
    if (
      !initialValues.customer_id ||
      !initialValues.type_contract_id ||
      !initialValues.date ||
      !initialValues.fees ||
      !initialValues.second_party_post ||
      !initialValues.second_party_address ||
      !initialValues.second_party_cin ||
      !initialValues.second_party_name ||
      (initialValues.second_party_cin && !isNumeric(initialValues.second_party_cin))
    ) {
      this.setState({
        ...initialValues,
        hasError: true,
      });
    } else if (
      initialValues.product_year &&
      moment().isBefore(moment(initialValues.product_year))
    ) {
      this.setState({
        ...initialValues,
        hasErrorDate: true,
      });
    } else {
      this.props.submitContractForm(initialValues);
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { addContract } = this.props;
    if (addContract !== prevProps.addContract && addContract && addContract.id) {
      this.props.goBack();
    }
  }
  render() {
    const { hasErrorDate, initialValues, selected_id, openDeletePopup, hasError } =
      this.state;
    const { lawyerTraineeShip, account, typeContracts, customersList } = this.props;
    return (
      <div>
        <div className="lawsuit_form__sections">
          {account && account.lawyer && account.lawyer.pack === 'beginner' ? (
            ''
          ) : lawyerTraineeShip && lawyerTraineeShip.length > 0 ? (
            <div>
              <label htmlFor="attr">يسند إلى</label>
              <select
                className={hasError && !initialValues.responsible_id ? 'input-error' : ''}
                onChange={(e) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      responsible_id:
                        e.target.value === 'يسند إلى' ? null : e.target.value,
                    },
                  });
                }}
                value={initialValues.responsible_id}
              >
                <option value={null}>يسند إلى</option>
                {lawyerTraineeShip.map((lawyerT) => {
                  return (
                    <option key={lawyerT.id} value={lawyerT.id}>
                      {`${lawyerT.first_name} ${lawyerT.last_name}`}
                    </option>
                  );
                })}
              </select>
            </div>
          ) : (
            ''
          )}

          <div>
            <label htmlFor="lawsuit_type">نوع العقد</label>
            <Autocomplete
              debug
              id="debug"
              options={typeContracts}
              getOptionLabel={(option) => option.title}
              style={{ marginBottom: '4%' }}
              onChange={(event, option) => {
                this.setState({
                  initialValues: {
                    ...initialValues,
                    type_contract_id: option != null ? option.id : '',
                    type_contract_name: option != null ? option.title : '',
                  },
                });
              }}
              defaultValue={{
                title: initialValues.type_contract_name,
              }}
              renderInput={(params) => (
                <TextField
                  error={!initialValues.type_contract_id && hasError}
                  name="type_contract_id"
                  style={{ border: 0 }}
                  {...params}
                  //label="نوع القضية"
                  //variant="outlined"
                />
              )}
            />
            <div className="rv_form__sections-left">
              <div
                style={{
                  alignItems: 'center',
                }}
                className="jalaset_form__sections-left"
              >
                <div
                  style={{
                    width: '9rem',
                    marginLeft: 0,
                  }}
                  className="jalaset_form__label"
                  htmlFor="date_selected"
                >
                  تاريخ العقد
                </div>
                <div className="jalaset_form__cal">
                  <DatePicker
                    selected={new Date(initialValues.date)}
                    locale="ar"
                    onChange={(date) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          date: date,
                        },
                      });
                    }}
                    customInput={
                      <svg
                        style={{
                          cursor: 'pointer',
                        }}
                        id="calendar"
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                      >
                        <g id="Group_28" data-name="Group 28">
                          <g id="Group_27" data-name="Group 27">
                            <ellipse
                              id="Ellipse_20"
                              data-name="Ellipse 20"
                              cx="1"
                              cy="1.5"
                              rx="1"
                              ry="1.5"
                              transform="translate(22 11)"
                              fill="#ca2946"
                            />
                            <path
                              id="Path_89"
                              data-name="Path 89"
                              d="M25.312,2.344H23.789V1.172a1.172,1.172,0,0,0-2.344,0V2.344H16.113V1.172a1.172,1.172,0,0,0-2.344,0V2.344H8.5V1.172a1.172,1.172,0,1,0-2.344,0V2.344H4.688A4.693,4.693,0,0,0,0,7.031V25.312A4.693,4.693,0,0,0,4.688,30h8.965a1.172,1.172,0,0,0,0-2.344H4.688a2.346,2.346,0,0,1-2.344-2.344V7.031A2.346,2.346,0,0,1,4.688,4.688H6.152V5.859a1.172,1.172,0,0,0,2.344,0V4.688H13.77V5.859a1.172,1.172,0,0,0,2.344,0V4.688h5.332V5.859a1.172,1.172,0,0,0,2.344,0V4.688h1.523a2.346,2.346,0,0,1,2.344,2.344v6.68a1.172,1.172,0,0,0,2.344,0V7.031A4.693,4.693,0,0,0,25.312,2.344Z"
                              fill="#ca2946"
                            />
                            <path
                              id="Path_90"
                              data-name="Path 90"
                              d="M277.09,270a7.09,7.09,0,1,0,7.09,7.09A7.1,7.1,0,0,0,277.09,270Zm0,11.836a4.746,4.746,0,1,1,4.746-4.746A4.751,4.751,0,0,1,277.09,281.836Z"
                              transform="translate(-254.18 -254.18)"
                              fill="#ca2946"
                            />
                            <path
                              id="Path_91"
                              data-name="Path 91"
                              d="M373.871,332.4h-.527v-1.23a1.172,1.172,0,0,0-2.344,0v2.4a1.172,1.172,0,0,0,1.172,1.172h1.7a1.172,1.172,0,0,0,0-2.344Z"
                              transform="translate(-349.262 -310.664)"
                              fill="#ca2946"
                            />
                            <circle
                              id="Ellipse_21"
                              data-name="Ellipse 21"
                              cx="1.5"
                              cy="1.5"
                              r="1.5"
                              transform="translate(16 11)"
                              fill="#ca2946"
                            />
                            <circle
                              id="Ellipse_22"
                              data-name="Ellipse 22"
                              cx="1.5"
                              cy="1.5"
                              r="1.5"
                              transform="translate(11 16)"
                              fill="#ca2946"
                            />
                            <ellipse
                              id="Ellipse_23"
                              data-name="Ellipse 23"
                              cx="1"
                              cy="1.5"
                              rx="1"
                              ry="1.5"
                              transform="translate(6 11)"
                              fill="#ca2946"
                            />
                            <ellipse
                              id="Ellipse_24"
                              data-name="Ellipse 24"
                              cx="1"
                              cy="1.5"
                              rx="1"
                              ry="1.5"
                              transform="translate(6 16)"
                              fill="#ca2946"
                            />
                            <circle
                              id="Ellipse_25"
                              data-name="Ellipse 25"
                              cx="1"
                              cy="1"
                              r="1"
                              transform="translate(6 22)"
                              fill="#ca2946"
                            />
                            <ellipse
                              id="Ellipse_26"
                              data-name="Ellipse 26"
                              cx="1.5"
                              cy="1"
                              rx="1.5"
                              ry="1"
                              transform="translate(11 22)"
                              fill="#ca2946"
                            />
                            <circle
                              id="Ellipse_27"
                              data-name="Ellipse 27"
                              cx="1.5"
                              cy="1.5"
                              r="1.5"
                              transform="translate(11 11)"
                              fill="#ca2946"
                            />
                          </g>
                        </g>
                      </svg>
                    }
                  />
                </div>

                <div className="jalaset_form__date_selected">
                  <div className="jalaset_form__date_selected__day">
                    {DaysArabic[moment(initialValues.date).format('dddd')]}
                  </div>
                  <div className="jalaset_form__date_selected__day">
                    {moment(initialValues.date).format('DD/MM/YYYY')}
                  </div>
                </div>
              </div>
              {/*{hasErrorDate ||
              (initialValues.date &&
                initialValues.date &&
                moment().isBefore(moment(initialValues.date))) ? (
                <div className="rv_form__sections-left_field">
                  <div>
                    <svg
                      className="rv_danger_icon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="27.077"
                      height="24.294"
                      viewBox="0 0 27.077 24.294"
                    >
                      <g id="alert" transform="translate(0 -26.315)">
                        <g
                          id="Group_39"
                          data-name="Group 39"
                          transform="translate(0 26.315)"
                        >
                          <g
                            id="Group_38"
                            data-name="Group 38"
                            transform="translate(0 0)"
                          >
                            <path
                              id="Path_105"
                              data-name="Path 105"
                              d="M26.645,45.833,16.3,27.907a3.184,3.184,0,0,0-5.515,0L.431,45.833a3.184,3.184,0,0,0,2.757,4.776h20.7a3.184,3.184,0,0,0,2.757-4.776Zm-1.374,2.391a1.581,1.581,0,0,1-1.384.8H3.189a1.6,1.6,0,0,1-1.384-2.4L12.155,28.7a1.6,1.6,0,0,1,2.767,0l10.35,17.926A1.582,1.582,0,0,1,25.272,48.224Z"
                              transform="translate(0 -26.315)"
                              fill="#ff8000"
                            />
                          </g>
                        </g>
                        <g
                          id="Group_41"
                          data-name="Group 41"
                          transform="translate(12.745 34.232)"
                        >
                          <g
                            id="Group_40"
                            data-name="Group 40"
                            transform="translate(0 0)"
                          >
                            <rect
                              id="Rectangle_145"
                              data-name="Rectangle 145"
                              width="1.586"
                              height="7.932"
                              fill="#ff8000"
                            />
                          </g>
                        </g>
                        <g
                          id="Group_43"
                          data-name="Group 43"
                          transform="translate(12.481 43.75)"
                        >
                          <g id="Group_42" data-name="Group 42">
                            <path
                              id="Path_106"
                              data-name="Path 106"
                              d="M237.06,355.99a1.058,1.058,0,1,0,1.058,1.058A1.059,1.059,0,0,0,237.06,355.99Z"
                              transform="translate(-236.002 -355.99)"
                              fill="#ff8000"
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                  <div className="rv_err_msg">الرجاء تحديد تاريخ في المستقبل</div>
                </div>
              ) : (
                ''
              )}*/}
            </div>
          </div>
          <div>
            <label htmlFor="lawsuit_type">الحريف</label>
            <Autocomplete
              debug
              id="debug"
              options={customersList}
              getOptionLabel={(option) => option.title}
              style={{ marginBottom: '4%' }}
              onChange={(event, option) => {
                this.setState({
                  initialValues: {
                    ...initialValues,
                    customer_id: option != null ? option.id : '',
                  },
                });
              }}
              defaultValue={{
                title: initialValues.customer_name,
              }}
              renderInput={(params) => (
                <TextField
                  error={!initialValues.customer_id && hasError}
                  name="customer_id"
                  style={{ border: 0 }}
                  {...params}
                  //label="المدعي"
                  //variant="outlined"
                />
              )}
            />
          </div>
          <div>
            <label>الطرف الثاني</label>
            <InputField
              value={initialValues.second_party_name}
              name="second_party_name"
              placeholder="الطرف الثاني"
              type="text"
              hasError={hasError}
              validInput="text"
              onChange={(event) => {
                this.setState({
                  initialValues: {
                    ...initialValues,
                    second_party_name: event.target.value,
                  },
                });
              }}
            />
            <InputField
              value={initialValues.second_party_cin}
              name="second_party_cin"
              placeholder="ب ت و"
              type="text"
              hasError={hasError}
              validInput="cin"
              onChange={(event) => {
                this.setState({
                  initialValues: {
                    ...initialValues,
                    second_party_cin: event.target.value,
                  },
                });
              }}
            />
            <InputField
              onKeyPress={this.keyPressed}
              value={initialValues.second_party_address}
              name="second_party_address"
              placeholder="المقر"
              type="text"
              hasError={hasError}
              validInput="text"
              onChange={(event) => {
                this.setState({
                  initialValues: {
                    ...initialValues,
                    second_party_address: event.target.value,
                  },
                });
              }}
            />
            <InputField
              value={initialValues.second_party_post}
              name="second_party_post"
              placeholder="المهنة"
              type="text"
              hasError={hasError}
              validInput="text"
              onChange={(event) => {
                this.setState({
                  initialValues: {
                    ...initialValues,
                    second_party_post: event.target.value,
                  },
                });
              }}
            />
          </div>

          {initialValues.type_contract_name === 'عقد بيع سيارة او شاحنة' ? (
            <>
              <span className="ls_seperator">العريضة</span>
              <div>
                <label htmlFor="attr">الصانع</label>
                <InputField
                  onKeyPress={() => this.submitForm(initialValues)}
                  value={initialValues.product_creator}
                  name="product_creator"
                  placeholder="الصانع"
                  type="text"
                  hasError={hasError}
                  validInput="text"
                  onChange={(event) => {
                    this.setState({
                      initialValues: {
                        ...initialValues,
                        product_creator: event.target.value,
                      },
                    });
                  }}
                />
              </div>
              <div>
                <label htmlFor="attr">النوع</label>
                <InputField
                  onKeyPress={() => this.submitForm(initialValues)}
                  value={initialValues.product_type}
                  name="product_type"
                  placeholder="النوع"
                  type="text"
                  hasError={hasError}
                  validInput="text"
                  onChange={(event) => {
                    this.setState({
                      initialValues: {
                        ...initialValues,
                        product_type: event.target.value,
                      },
                    });
                  }}
                />
              </div>
              <div>
                <label htmlFor="attr">الوقود</label>
                <InputField
                  onKeyPress={() => this.submitForm(initialValues)}
                  value={initialValues.product_engine}
                  name="product_engine"
                  placeholder="الوقود"
                  type="text"
                  hasError={hasError}
                  validInput="text"
                  onChange={(event) => {
                    this.setState({
                      initialValues: {
                        ...initialValues,
                        product_engine: event.target.value,
                      },
                    });
                  }}
                />
              </div>
              <div>
                <div className="rv_form__sections-left">
                  <div
                    style={{
                      alignItems: 'center',
                    }}
                    className="jalaset_form__sections-left"
                  >
                    <div
                      style={{
                        width: '9rem',
                      }}
                      className="jalaset_form__label"
                      htmlFor="date_selected"
                    >
                      تاريخ اول اذن بالجولان
                    </div>
                    <div className="jalaset_form__cal">
                      <DatePicker
                        selected={new Date(initialValues.product_year)}
                        locale="ar"
                        onChange={(date) => {
                          this.setState({
                            initialValues: {
                              ...initialValues,
                              product_year: date,
                            },
                          });
                        }}
                        customInput={
                          <svg
                            style={{
                              cursor: 'pointer',
                            }}
                            id="calendar"
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                          >
                            <g id="Group_28" data-name="Group 28">
                              <g id="Group_27" data-name="Group 27">
                                <ellipse
                                  id="Ellipse_20"
                                  data-name="Ellipse 20"
                                  cx="1"
                                  cy="1.5"
                                  rx="1"
                                  ry="1.5"
                                  transform="translate(22 11)"
                                  fill="#ca2946"
                                />
                                <path
                                  id="Path_89"
                                  data-name="Path 89"
                                  d="M25.312,2.344H23.789V1.172a1.172,1.172,0,0,0-2.344,0V2.344H16.113V1.172a1.172,1.172,0,0,0-2.344,0V2.344H8.5V1.172a1.172,1.172,0,1,0-2.344,0V2.344H4.688A4.693,4.693,0,0,0,0,7.031V25.312A4.693,4.693,0,0,0,4.688,30h8.965a1.172,1.172,0,0,0,0-2.344H4.688a2.346,2.346,0,0,1-2.344-2.344V7.031A2.346,2.346,0,0,1,4.688,4.688H6.152V5.859a1.172,1.172,0,0,0,2.344,0V4.688H13.77V5.859a1.172,1.172,0,0,0,2.344,0V4.688h5.332V5.859a1.172,1.172,0,0,0,2.344,0V4.688h1.523a2.346,2.346,0,0,1,2.344,2.344v6.68a1.172,1.172,0,0,0,2.344,0V7.031A4.693,4.693,0,0,0,25.312,2.344Z"
                                  fill="#ca2946"
                                />
                                <path
                                  id="Path_90"
                                  data-name="Path 90"
                                  d="M277.09,270a7.09,7.09,0,1,0,7.09,7.09A7.1,7.1,0,0,0,277.09,270Zm0,11.836a4.746,4.746,0,1,1,4.746-4.746A4.751,4.751,0,0,1,277.09,281.836Z"
                                  transform="translate(-254.18 -254.18)"
                                  fill="#ca2946"
                                />
                                <path
                                  id="Path_91"
                                  data-name="Path 91"
                                  d="M373.871,332.4h-.527v-1.23a1.172,1.172,0,0,0-2.344,0v2.4a1.172,1.172,0,0,0,1.172,1.172h1.7a1.172,1.172,0,0,0,0-2.344Z"
                                  transform="translate(-349.262 -310.664)"
                                  fill="#ca2946"
                                />
                                <circle
                                  id="Ellipse_21"
                                  data-name="Ellipse 21"
                                  cx="1.5"
                                  cy="1.5"
                                  r="1.5"
                                  transform="translate(16 11)"
                                  fill="#ca2946"
                                />
                                <circle
                                  id="Ellipse_22"
                                  data-name="Ellipse 22"
                                  cx="1.5"
                                  cy="1.5"
                                  r="1.5"
                                  transform="translate(11 16)"
                                  fill="#ca2946"
                                />
                                <ellipse
                                  id="Ellipse_23"
                                  data-name="Ellipse 23"
                                  cx="1"
                                  cy="1.5"
                                  rx="1"
                                  ry="1.5"
                                  transform="translate(6 11)"
                                  fill="#ca2946"
                                />
                                <ellipse
                                  id="Ellipse_24"
                                  data-name="Ellipse 24"
                                  cx="1"
                                  cy="1.5"
                                  rx="1"
                                  ry="1.5"
                                  transform="translate(6 16)"
                                  fill="#ca2946"
                                />
                                <circle
                                  id="Ellipse_25"
                                  data-name="Ellipse 25"
                                  cx="1"
                                  cy="1"
                                  r="1"
                                  transform="translate(6 22)"
                                  fill="#ca2946"
                                />
                                <ellipse
                                  id="Ellipse_26"
                                  data-name="Ellipse 26"
                                  cx="1.5"
                                  cy="1"
                                  rx="1.5"
                                  ry="1"
                                  transform="translate(11 22)"
                                  fill="#ca2946"
                                />
                                <circle
                                  id="Ellipse_27"
                                  data-name="Ellipse 27"
                                  cx="1.5"
                                  cy="1.5"
                                  r="1.5"
                                  transform="translate(11 11)"
                                  fill="#ca2946"
                                />
                              </g>
                            </g>
                          </svg>
                        }
                      />
                    </div>

                    <div className="jalaset_form__date_selected">
                      <div className="jalaset_form__date_selected__day">
                        {DaysArabic[moment(initialValues.product_year).format('dddd')]}
                      </div>
                      <div className="jalaset_form__date_selected__day">
                        {moment(initialValues.product_year).format('DD/MM/YYYY')}
                      </div>
                    </div>
                  </div>
                </div>
                {hasErrorDate ||
                (initialValues.product_year &&
                  initialValues.product_year &&
                  moment().isBefore(moment(initialValues.product_year))) ? (
                  <div className="rv_form__sections-left_field">
                    <div>
                      <svg
                        className="rv_danger_icon"
                        xmlns="http://www.w3.org/2000/svg"
                        width="27.077"
                        height="24.294"
                        viewBox="0 0 27.077 24.294"
                      >
                        <g id="alert" transform="translate(0 -26.315)">
                          <g
                            id="Group_39"
                            data-name="Group 39"
                            transform="translate(0 26.315)"
                          >
                            <g
                              id="Group_38"
                              data-name="Group 38"
                              transform="translate(0 0)"
                            >
                              <path
                                id="Path_105"
                                data-name="Path 105"
                                d="M26.645,45.833,16.3,27.907a3.184,3.184,0,0,0-5.515,0L.431,45.833a3.184,3.184,0,0,0,2.757,4.776h20.7a3.184,3.184,0,0,0,2.757-4.776Zm-1.374,2.391a1.581,1.581,0,0,1-1.384.8H3.189a1.6,1.6,0,0,1-1.384-2.4L12.155,28.7a1.6,1.6,0,0,1,2.767,0l10.35,17.926A1.582,1.582,0,0,1,25.272,48.224Z"
                                transform="translate(0 -26.315)"
                                fill="#ff8000"
                              />
                            </g>
                          </g>
                          <g
                            id="Group_41"
                            data-name="Group 41"
                            transform="translate(12.745 34.232)"
                          >
                            <g
                              id="Group_40"
                              data-name="Group 40"
                              transform="translate(0 0)"
                            >
                              <rect
                                id="Rectangle_145"
                                data-name="Rectangle 145"
                                width="1.586"
                                height="7.932"
                                fill="#ff8000"
                              />
                            </g>
                          </g>
                          <g
                            id="Group_43"
                            data-name="Group 43"
                            transform="translate(12.481 43.75)"
                          >
                            <g id="Group_42" data-name="Group 42">
                              <path
                                id="Path_106"
                                data-name="Path 106"
                                d="M237.06,355.99a1.058,1.058,0,1,0,1.058,1.058A1.059,1.059,0,0,0,237.06,355.99Z"
                                transform="translate(-236.002 -355.99)"
                                fill="#ff8000"
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div className="rv_err_msg">الرجاء تحديد تاريخ صحيح</div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div>
                <label htmlFor="attr">الثمن</label>
                <InputField
                  value={initialValues.price}
                  name="price"
                  placeholder="الثمن"
                  type="text"
                  hasError={hasError}
                  validInput="integer"
                  onChange={(event) => {
                    this.setState({
                      initialValues: {
                        ...initialValues,
                        price: event.target.value,
                      },
                    });
                  }}
                />
              </div>
            </>
          ) : (
            ''
          )}
          <div>
            <label htmlFor="attr">الثمن</label>
            <InputField
              value={initialValues.price}
              name="price"
              placeholder="الثمن"
              type="text"
              hasError={hasError}
              validInput="integer"
              onChange={(event) => {
                this.setState({
                  initialValues: {
                    ...initialValues,
                    price: event.target.value,
                  },
                });
              }}
            />
          </div>
          <span style={{ fontSize: '16px' }} className="ls_seperator">
            الأتعاب
          </span>
          <div>
            <label htmlFor="attr">الأتعاب</label>
            <InputField
              value={initialValues.fees}
              name="fees"
              placeholder="الأتعاب"
              type="text"
              hasError={hasError}
              validInput="integer"
              onChange={(event) => {
                this.setState({
                  initialValues: {
                    ...initialValues,
                    fees: event.target.value,
                  },
                });
              }}
            />
          </div>
        </div>
        <br />
        <WordEditor
          onChange={(text) => {
            this.setState({
              initialValues: {
                ...initialValues,
                text,
              },
            });
          }}
          initialValues={initialValues}
        />
        <div className="lawsuit_form__btn_grp">
          <button
            onClick={() => this.props.goBack()}
            type="button"
            className="lawsuit_cancel_session"
          >
            <svg
              className="lawsuit_ltSpan"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              xlinkHref="http://svgjs.com/svgjs"
              version="1.1"
              width="12"
              height="12"
              x="0"
              y="0"
              viewBox="0 0 492.004 492.004"
              style={{ enableBackground: 'new 0 0 512 512' }}
              xmlSpace="preserve"
            >
              <g>
                <g xmlns="http://www.w3.org/2000/svg">
                  <g>
                    <path
                      d="M382.678,226.804L163.73,7.86C158.666,2.792,151.906,0,144.698,0s-13.968,2.792-19.032,7.86l-16.124,16.12    c-10.492,10.504-10.492,27.576,0,38.064L293.398,245.9l-184.06,184.06c-5.064,5.068-7.86,11.824-7.86,19.028    c0,7.212,2.796,13.968,7.86,19.04l16.124,16.116c5.068,5.068,11.824,7.86,19.032,7.86s13.968-2.792,19.032-7.86L382.678,265    c5.076-5.084,7.864-11.872,7.848-19.088C390.542,238.668,387.754,231.884,382.678,226.804z"
                      fill="#2d2d2d"
                      data-original="#000000"
                      className=""
                    />
                  </g>
                </g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
              </g>
            </svg>
            الغاء
          </button>
          <button onClick={() => this.submitForm(initialValues)} type="button">
            <svg
              className="tickSpan"
              xmlns="http://www.w3.org/2000/svg"
              width="20.125"
              height="17.774"
              viewBox="0 0 29.125 23.774"
            >
              <path
                id="Path_92"
                data-name="Path 92"
                d="M1325.354,167.5l8.34,9.625,17.8-19.417"
                transform="translate(-1323.842 -156.357)"
                fill="none"
                stroke="#f9f9f9"
                strokeWidth="4"
              />
            </svg>
            تسجيل
          </button>
        </div>

        <div className={openDeletePopup ? 'popup active' : 'popup'}>
          <div className="popup__title">أنت بصدد حذف الملف</div>
          <div className="popup__btn_grp">
            <button
              onClick={() => this.handleActivePopupDeleteItem()}
              className="cancel_popup"
            >
              <span>الغاء</span>
              <span>&lt;</span>
            </button>
            <button
              onClick={() => {
                this.props.deleteFile();
              }}
            >
              <span>تأكيد</span>
              <span className="trashSpan">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.906"
                  height="25.74"
                  viewBox="0 0 20.906 25.74"
                >
                  <g id="delete" transform="translate(0.003 0.002)">
                    <path
                      id="Path_82"
                      data-name="Path 82"
                      d="M223,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,223,154.7Zm0,0"
                      transform="translate(-208.995 -145.379)"
                      fill="#fff"
                    />
                    <path
                      id="Path_83"
                      data-name="Path 83"
                      d="M105,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,105,154.7Zm0,0"
                      transform="translate(-98.108 -145.379)"
                      fill="#fff"
                    />
                    <path
                      id="Path_84"
                      data-name="Path 84"
                      d="M1.709,7.662V22.514a3.326,3.326,0,0,0,.884,2.294,2.969,2.969,0,0,0,2.154.931H16.152a2.968,2.968,0,0,0,2.154-.931,3.326,3.326,0,0,0,.884-2.294V7.662A2.3,2.3,0,0,0,18.6,3.133H15.513V2.38A2.368,2.368,0,0,0,13.126,0H7.773A2.368,2.368,0,0,0,5.386,2.38v.754H2.3a2.3,2.3,0,0,0-.591,4.528ZM16.152,24.533H4.747a1.909,1.909,0,0,1-1.832-2.019V7.715h15.07v14.8a1.909,1.909,0,0,1-1.832,2.019ZM6.592,2.38A1.161,1.161,0,0,1,7.773,1.2h5.353A1.161,1.161,0,0,1,14.308,2.38v.754H6.592ZM2.3,4.339H18.6a1.085,1.085,0,0,1,0,2.17H2.3a1.085,1.085,0,0,1,0-2.17Zm0,0"
                      transform="translate(0 0)"
                      fill="#fff"
                    />
                    <path
                      id="Path_85"
                      data-name="Path 85"
                      d="M164,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,164,154.7Zm0,0"
                      transform="translate(-153.551 -145.379)"
                      fill="#fff"
                    />
                  </g>
                </svg>
              </span>
            </button>
          </div>
        </div>
        <div id="overlay" className={openDeletePopup ? 'active' : ''}></div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Form);
