import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

class Footer_link extends Component {
  render() {
    return (
      // Footer Link start
      <Row>
        <Col lg={12}>
          <div className="text-center">
            <p style={{ fontSize: '13px' }} className="text-muted mb-0">
              {`لافوكاتو © ${new Date().getFullYear()}  . طورت بواسطة Tek4Office `}
              {/*{new Date().getFullYear()}  طورت بواسطة Tek4Office*/}
            </p>
          </div>
        </Col>
      </Row>
      //   Footer Link End
    );
  }
}

export default Footer_link;
