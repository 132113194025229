import React, { Component, useState } from 'react';
import Picker from 'emoji-picker-react';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import Popper from '@mui/material/Popper';

class Input extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideEmoji: false,
      fileAdd: false,
      file: null,
      chosenEmoji: null,
    };
    this.anchorRefSmile = React.createRef(null);
    this.onEmojiClick = this.onEmojiClick.bind(this);
    this.hideEmoji = this.hideEmoji.bind(this);
    this.handleListKeyDown = this.handleListKeyDown.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  handleClick(fileUploader) {
    this.refs[fileUploader].click();
  }
  onEmojiClick(event, emojiObject) {
    this.props.setMessage(this.props.message + emojiObject.emoji);
    this.setState({
      chosenEmoji: emojiObject,
    });
  }
  hideEmoji() {
    this.setState({
      hideEmoji: !this.state.hideEmoji,
    });
  }
  handleClose = (event) => {
    if (
      this.anchorRefSmile.current &&
      this.anchorRefSmile.current.contains(event.target)
    ) {
      return;
    }
    this.setState({
      hideEmoji: false,
    });
  };
  handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      this.setState({
        hideEmoji: false,
      });
    } else if (event.key === 'Escape') {
      this.setState({
        hideEmoji: false,
      });
    }
  };
  render() {
    const { message, status, setMessage, sendMessage } = this.props;
    const { hideEmoji, fileAdd, file } = this.state;
    return (
      <>
        {/*{hideEmoji ? (
          <Picker style={{ marginTop: '-32%' }} onEmojiClick={this.onEmojiClick} />
        ) : (
          ''
        )}*/}

        <div style={{ display: 'flex' }}>
          <input
            className="input"
            placeholder="انقر لإضافة رسالتك"
            value={message}
            onChange={(event) => {
              this.setState({
                fileAdd: false,
              });
              setMessage(event.target.value);
            }}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                this.setState({
                  hideEmoji: false,
                  fileAdd: false,
                });
                if (fileAdd) this.props.sendMessageFiles(file);
                else sendMessage(event);
              } else null;
            }}
          />
          <button
            onClick={(event) => {
              this.setState({
                hideEmoji: false,
                fileAdd: false,
              });
              if (fileAdd) this.props.sendMessageFiles(file);
              else sendMessage(event);
            }}
            style={{ /*top: hideEmoji ? '33rem' : '0.1rem',*/ fontFamily: 'beIN Normal' }}
            className="msg-field__btn"
          >
            ارسل
          </button>
          <div
            style={{ marginLeft: '16%', display: 'flex', alignItems: 'center' }}
            className="side-btns"
          >
            <div
              style={{ cursor: 'pointer' }}
              className="emoji-btn"
              onClick={() => {
                this.hideEmoji(hideEmoji);
              }}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.82031 11.8477C7.53646 13.0508 8.59635 13.6523 10 13.6523C11.4036 13.6523 12.4635 13.0508 13.1797 11.8477H14.6836C14.3112 12.793 13.6953 13.5664 12.8359 14.168C12.0052 14.7409 11.0599 15.0273 10 15.0273C8.9401 15.0273 7.98047 14.7409 7.12109 14.168C6.29036 13.5664 5.6888 12.793 5.31641 11.8477H6.82031ZM4.80078 15.1992C6.26172 16.6315 7.99479 17.3477 10 17.3477C12.0052 17.3477 13.724 16.6315 15.1562 15.1992C16.6172 13.7383 17.3477 12.0052 17.3477 10C17.3477 7.99479 16.6172 6.27604 15.1562 4.84375C13.724 3.38281 12.0052 2.65234 10 2.65234C7.99479 2.65234 6.26172 3.38281 4.80078 4.84375C3.36849 6.27604 2.65234 7.99479 2.65234 10C2.65234 12.0052 3.36849 13.7383 4.80078 15.1992ZM3.51172 3.55469C5.31641 1.75 7.47917 0.847656 10 0.847656C12.5208 0.847656 14.6693 1.75 16.4453 3.55469C18.25 5.33073 19.1523 7.47917 19.1523 10C19.1523 12.5208 18.25 14.6836 16.4453 16.4883C14.6693 18.2643 12.5208 19.1523 10 19.1523C7.47917 19.1523 5.31641 18.2643 3.51172 16.4883C1.73568 14.6836 0.847656 12.5208 0.847656 10C0.847656 7.47917 1.73568 5.33073 3.51172 3.55469ZM5.78906 8.71094C5.53125 8.42448 5.40234 8.09505 5.40234 7.72266C5.40234 7.35026 5.53125 7.03516 5.78906 6.77734C6.07552 6.49089 6.40495 6.34766 6.77734 6.34766C7.14974 6.34766 7.46484 6.49089 7.72266 6.77734C8.00911 7.03516 8.15234 7.35026 8.15234 7.72266C8.15234 8.09505 8.00911 8.42448 7.72266 8.71094C7.46484 8.96875 7.14974 9.09766 6.77734 9.09766C6.40495 9.09766 6.07552 8.96875 5.78906 8.71094ZM12.2344 8.71094C11.9766 8.42448 11.8477 8.09505 11.8477 7.72266C11.8477 7.35026 11.9766 7.03516 12.2344 6.77734C12.5208 6.49089 12.8503 6.34766 13.2227 6.34766C13.5951 6.34766 13.9102 6.49089 14.168 6.77734C14.4544 7.03516 14.5977 7.35026 14.5977 7.72266C14.5977 8.09505 14.4544 8.42448 14.168 8.71094C13.9102 8.96875 13.5951 9.09766 13.2227 9.09766C12.8503 9.09766 12.5208 8.96875 12.2344 8.71094Z"
                  fill="#908E8E"
                />
              </svg>
            </div>
            <input
              accept="image/png,.pdf, image/jpeg, image/png, image/gif"
              onChange={(e) => {
                this.setState({
                  fileAdd: true,
                  file: e.target.files[0],
                });
                setMessage(e.target.files[0].name);
                // this.props.sendMessageFiles(e, 'profil_cover');
              }}
              type="file"
              ref="fileUploader"
              name="profil_cover"
              hidden
            />
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => this.handleClick('fileUploader')}
              className="attach-btn"
            >
              <svg
                width="20"
                height="11"
                viewBox="0 0 20 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.30859 9.03906C1.33464 8.0651 0.847656 6.8763 0.847656 5.47266C0.847656 4.06901 1.33464 2.88021 2.30859 1.90625C3.28255 0.903646 4.47135 0.402344 5.875 0.402344H15.5C16.5026 0.402344 17.362 0.77474 18.0781 1.51953C18.7943 2.23568 19.1523 3.09505 19.1523 4.09766C19.1523 5.10026 18.7943 5.95964 18.0781 6.67578C17.362 7.39193 16.5026 7.75 15.5 7.75H7.72266C7.09245 7.75 6.54818 7.53516 6.08984 7.10547C5.63151 6.64714 5.40234 6.10286 5.40234 5.47266C5.40234 4.84245 5.63151 4.29818 6.08984 3.83984C6.54818 3.38151 7.09245 3.15234 7.72266 3.15234H14.5977V5H7.63672C7.37891 5 7.25 5.15755 7.25 5.47266C7.25 5.75911 7.37891 5.90234 7.63672 5.90234H15.5C15.987 5.90234 16.4167 5.73047 16.7891 5.38672C17.1615 5.01432 17.3477 4.58464 17.3477 4.09766C17.3477 3.61068 17.1615 3.18099 16.7891 2.80859C16.4167 2.4362 15.987 2.25 15.5 2.25H5.875C4.98698 2.25 4.22786 2.5651 3.59766 3.19531C2.96745 3.82552 2.65234 4.58464 2.65234 5.47266C2.65234 6.36068 2.96745 7.11979 3.59766 7.75C4.22786 8.35156 4.98698 8.65234 5.875 8.65234H14.5977V10.5H5.875C4.47135 10.5 3.28255 10.013 2.30859 9.03906Z"
                  fill="#908E8E"
                />
              </svg>
            </div>
          </div>
        </div>
        <Popper
          open={hideEmoji}
          anchorEl={this.anchorRefSmile.current}
          role={undefined}
          placement="left bottom"
          transition
          disablePortal
          style={{
            zIndex: '2000',
            top: '62%',
            left: '0rem',
          }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'left bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <MenuList
                    className="user_dropdown__content"
                    autoFocusItem={hideEmoji}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={this.handleListKeyDown}
                  >
                    <Picker
                      style={{ marginRight: '-164%', marginTop: '-363%' }}
                      onEmojiClick={this.onEmojiClick}
                    />
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    );
  }
}
export default Input;
