import React, { Component } from 'react';
import Form from './containers/form';
import actions from './store/actions';
import actionsApp from '../../js/actions';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';

const mapStateToProps = (state) => ({
  appeal: state.AppealReducer.appeal,
  isLoadingAppeal: state.AppealReducer.isLoadingAppeal,
  judgments: state.AppReducer.judgments,
  isLoadingJudgments: state.AppReducer.isLoadingJudgments,
  allSessions: state.AppReducer.allSessions,
  isLoadingAllSessions: state.AppReducer.isLoadingAllSessions,
});

const mapDispatchToProps = (dispatch) => ({
  getAppealById: (id) => dispatch(actions.getAppealById(id)),
  getListJudgment: () => dispatch(actionsApp.getListJudgment()),
  getAllSessions: () => dispatch(actionsApp.getAllSessions()),
});

class FormAppeal extends Component {
  constructor(props) {
    super(props);
    if (props.match.params && props.match.params.id) {
      props.changeActiveItemSideBar(5, 'تحديث طعن');
      document.title = 'تحديث طعن';
    } else {
      props.changeActiveItemSideBar(5, 'اضافة طعن');
      document.title = 'اضافة طعن';
    }
    props.getListJudgment();
    props.getAllSessions();
    props.getAppealById(props.match.params && props.match.params.id);
    this.goBack = this.goBack.bind(this);
  }
  goBack() {
    this.props.history.goBack();
  }
  render() {
    const { appeal, judgments, isLoadingAppeal, allSessions } = this.props;
    if (isLoadingAppeal) {
      return (
        <CircularProgress
          style={{
            textAlign: 'center',
            width: '30px',
            height: '30px',
            marginTop: '20%',
            marginRight: '50%',
          }}
        />
      );
    }

    return (
      <div className="appeals_main">
        <div className="appeals_newAppeal" style={{ display: 'flex' }}>
          <Form
            goBack={this.goBack}
            appeal={appeal}
            allSessions={allSessions}
            judgments={judgments}
          />
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(FormAppeal);
